export const USER_PROFILE_SETUP_REQUEST = '[USER]_PROFILE_SETUP_REQUEST'
export const USER_PROFILE_SETUP_RESPONSE = '[USER]_PROFILE_SETUP_RESPONSE'
export const USER_PROFILE_SETUP_FAILURE = '[USER]_PROFILE_SETUP_FAILURE'

export const USER_PROFILE_SETUP_CREATE_PROP_REQUEST = '[USER]_PROFILE_SETUP_CREATE_PROP_REQUEST'
export const USER_PROFILE_SETUP_CREATE_PROP_RESPONSE = '[USER]_PROFILE_SETUP_CREATE_PROP_RESPONSE'
export const USER_PROFILE_SETUP_CREATE_PROP_FAILURE = '[USER]_PROFILE_SETUP_CREATE_PROP_FAILURE'

export const USER_PROFILE_SETUP_UPDATE_REQUEST = '[USER]_PROFILE_SETUP_UPDATE_REQUEST'
export const USER_PROFILE_SETUP_UPDATE_RESPONSE = '[USER]_PROFILE_SETUP_UPDATE_RESPONSE'
export const USER_PROFILE_SETUP_UPDATE_FAILURE = '[USER]_PROFILE_SETUP_UPDATE_FAILURE'

export const USER_PROFILE_SETUP_DELETE_PROP_REQUEST = '[USER]_PROFILE_SETUP_DELETE_PROP_REQUEST'
export const USER_PROFILE_SETUP_DELETE_PROP_RESPONSE = '[USER]_PROFILE_SETUP_DELETE_PROP_RESPONSE'
export const USER_PROFILE_SETUP_DELETE_PROP_FAILURE = '[USER]_PROFILE_SETUP_DELETE_PROP_FAILURE'

export const USER_RESPONSE_MESSAGE_RESET = '[USER]_RESPONSE_MESSAGE_RESET'

