import * as actionTypes from './rent.actionTypes'
import * as actions from './rent.actions'
import { AirshopApi } from 'CraftApi.axios'
import { from, of, forkJoin, iif, defer } from 'rxjs'
import { mergeMap, takeUntil, catchError, map } from 'rxjs/operators'
import { ofType } from 'redux-observable'
import {
  err, errMessages, handleForkJoinResponses, passOnlySuccessForkJoinResponses
} from 'redux-store/utils/epics.utils'

export const fetchTemplatesPdfListEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.RENT_TEMPLATES_PDF_LIST_REQUEST),
    mergeMap(action => {
      const { draw, page, limit, search } = action.payload;
      return from(AirshopApi.get('rent/documentTemplate', {
        params: { draw, page, limit, search, order: 'date_created', sort: 'DESC'}
      }))
      .pipe(
          map(res => res.data.status === 'OK' ? res.data : err(errMessages.NOT_OK, res)),
          map(response => actions.rentTemplatesPdfListResponse({
            total: response.total, items: response.items,
          })),
          catchError(error => of(actions.rentTemplatesPdfListFailure(
            error
          ))),
          takeUntil(action$.ofType(
            actionTypes.RENT_TEMPLATES_PDF_LIST_RESPONSE ||
            actionTypes.RENT_TEMPLATES_PDF_LIST_FAILURE
          ))
      )
    })
  );


export const createTemplatePdfEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.RENT_CREATE_TEMPLATE_PDF_REQUEST),
    mergeMap(action => {
      const { newTemplatePdf } = action.payload;
      return from(AirshopApi.post('rent/documentTemplate', newTemplatePdf))
      .pipe(
          map(res => res.data.status === 'OK' ? res.data.data : err(errMessages.NOT_OK, res)),
          map(response => actions.rentCreateTemplatePdfResponse(
            response,
            actionTypes.RENT_CREATE_TEMPLATE_PDF_RESPONSE
          )),
          catchError(error => of(actions.rentCreateTemplatePdfFailure(
            error,
            actionTypes.RENT_CREATE_TEMPLATE_PDF_FAILURE
          ))),
          takeUntil(action$.ofType(
            actionTypes.RENT_CREATE_TEMPLATE_PDF_RESPONSE ||
            actionTypes.RENT_CREATE_TEMPLATE_PDF_FAILURE
          ))
      )
    })
  );

export const editTemplatePdfEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.RENT_EDIT_TEMPLATE_PDF_REQUEST),
    mergeMap(action => {
      const { templatePdfId, editedTemplatePdf } = action.payload;
      return from(AirshopApi.put(
        'rent/documentTemplate',
        editedTemplatePdf,
        { params: { documentTemplateId: templatePdfId }}
      ))
      .pipe(
          map(res => res.data.status === 'OK' ? res.data.data : err(errMessages.NOT_OK, res)),
          map(response => actions.rentEditTemplatePdfResponse(
            response,
            actionTypes.RENT_EDIT_TEMPLATE_PDF_RESPONSE
          )),
          catchError(error => of(actions.rentEditTemplatePdfFailure(
            error,
            actionTypes.RENT_EDIT_TEMPLATE_PDF_FAILURE
          ))),
          takeUntil(action$.ofType(
            actionTypes.RENT_EDIT_TEMPLATE_PDF_RESPONSE ||
            actionTypes.RENT_EDIT_TEMPLATE_PDF_FAILURE
          ))
      )
    })
  );

export const deleteTemplatePdfEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.RENT_DELETE_TEMPLATE_PDF_REQUEST),
    mergeMap(action => {
      const { templatePdfId } = action.payload;
      return from(AirshopApi.delete(
        'rent/documentTemplate',
        { params: { documentTemplateId: templatePdfId }}
      ))
      .pipe(
          map(res => res.data.status === 'OK' ? res.data.data : err(errMessages.NOT_OK, res)),
          map(response => actions.rentDeleteTemplatePdfResponse(
            response,
            actionTypes.RENT_DELETE_TEMPLATE_PDF_RESPONSE
          )),
          catchError(error => of(actions.rentDeleteTemplatePdfFailure(
            error,
            actionTypes.RENT_DELETE_TEMPLATE_PDF_FAILURE
          ))),
          takeUntil(action$.ofType(
            actionTypes.RENT_DELETE_TEMPLATE_PDF_RESPONSE ||
            actionTypes.RENT_DELETE_TEMPLATE_PDF_FAILURE
          ))
      )
    })
  );

export const fetchCustomersListEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.RENT_CUSTOMERS_LIST_REQUEST),
    mergeMap(action => {
      const { draw, page, limit, search } = action.payload;
      return from(AirshopApi.get('rent/customer/full', {
        params: { draw, page, limit, search, order: 'date_created', sort: 'DESC'}
      }))
      .pipe(
          map(res => res.data.status === 'OK' ? res.data : err(errMessages.NOT_OK, res)),
          map(response => actions.rentCustomersListResponse({
            total: response.total, items: response.items,
          })),
          catchError(error => of(actions.rentCustomersListFailure(
            error
          ))),
          takeUntil(action$.ofType(
            actionTypes.RENT_CUSTOMERS_LIST_RESPONSE ||
            actionTypes.RENT_CUSTOMERS_LIST_FAILURE
          ))
      )
    })
  );

export const createCustomerEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.RENT_CREATE_CUSTOMER_REQUEST),
    mergeMap(action => {
      const { newCustomer } = action.payload;
      return from(AirshopApi.post('rent/customer', newCustomer))
      .pipe(
          map(res => res.data.status === 'OK' ? res.data.data : err(errMessages.NOT_OK, res)),
          map(response => actions.rentCreateCustomerResponse(
            response,
            actionTypes.RENT_CREATE_CUSTOMER_RESPONSE
          )),
          catchError(error => of(actions.rentCreateCustomerFailure(
            error,
            actionTypes.RENT_CREATE_CUSTOMER_FAILURE
          ))),
          takeUntil(action$.ofType(
            actionTypes.RENT_CREATE_CUSTOMER_RESPONSE ||
            actionTypes.RENT_CREATE_CUSTOMER_FAILURE
          ))
      )
    })
  );

export const editCustomerEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.RENT_EDIT_CUSTOMER_REQUEST),
    mergeMap(action => {
      const { customerId, editedCustomer } = action.payload;
      return from(AirshopApi.put(
        'rent/customer',
        editedCustomer,
        { params: { customerId: customerId }}
      ))
      .pipe(
          map(res => res.data.status === 'OK' ? res.data.data : err(errMessages.NOT_OK, res)),
          map(response => actions.rentEditCustomerResponse(
            response,
            actionTypes.RENT_EDIT_CUSTOMER_RESPONSE
          )),
          catchError(error => of(actions.rentEditCustomerFailure(
            error,
            actionTypes.RENT_EDIT_CUSTOMER_FAILURE
          ))),
          takeUntil(action$.ofType(
            actionTypes.RENT_EDIT_CUSTOMER_RESPONSE ||
            actionTypes.RENT_EDIT_CUSTOMER_FAILURE
          ))
      )
    })
  );

export const fetchContractItemsByContractIdEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.RENT_CONTRACT_ITEMS_BY_CONTRACT_ID_REQUEST),
    mergeMap(action => {
      const { contractId } = action.payload;
      return from(AirshopApi.get('rent/contractItems/contractId', {
        params: { contractId, limit: 100, order: 'date_created', sort: 'DESC'}
      }))
      .pipe(
          map(res => res.data.status === 'OK' ? res.data.items : err(errMessages.NOT_OK, res)),
          map(response => actions.rentContractItemsByContractIdResponse({
            [contractId]: response
          })),
          catchError(error => of(actions.rentContractItemsByContractIdFailure(
            error
          ))),
          takeUntil(action$.ofType(
            actionTypes.RENT_CONTRACT_ITEMS_BY_CONTRACT_ID_RESPONSE ||
            actionTypes.RENT_CONTRACT_ITEMS_BY_CONTRACT_ID_FAILURE
          ))
      )
    })
  );

export const createMultipleContractItemsEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.RENT_CREATE_MULTIPLE_CONTRACT_ITEMS_REQUEST),
    mergeMap(action => {
      const { newContractItemsArr } = action.payload;
      return forkJoin(
        newContractItemsArr.map(newContractItem => AirshopApi.post(
          'rent/contractItems',
          newContractItem
        ))
      )
      .pipe(
          map(responses => handleForkJoinResponses(responses)),
          map(response => actions.rentCreateMultipleContractItemsResponse(
            response,
            actionTypes.RENT_CREATE_MULTIPLE_CONTRACT_ITEMS_RESPONSE
          )),
          catchError(error => of(actions.rentCreateMultipleContractItemsFailure(
            error,
            actionTypes.RENT_CREATE_MULTIPLE_CONTRACT_ITEMS_FAILURE
          ))),
          takeUntil(action$.ofType(
            actionTypes.RENT_CREATE_MULTIPLE_CONTRACT_ITEMS_RESPONSE ||
            actionTypes.RENT_CREATE_MULTIPLE_CONTRACT_ITEMS_FAILURE
          ))
      )
    })
  );

export const deleteContractItemEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.RENT_DELETE_CONTRACT_ITEM_REQUEST),
    mergeMap(action => {
      const { contractItemId } = action.payload;
      return from(AirshopApi.delete(
        'rent/contractItems',
        { params: { contractItemsId: contractItemId }}
      ))
      .pipe(
          map(res => res.data.status === 'OK' ? res.data.data : err(errMessages.NOT_OK, res)),
          map(response => actions.rentDeleteContractItemResponse(
            response,
            actionTypes.RENT_DELETE_CONTRACT_ITEM_RESPONSE
          )),
          catchError(error => of(actions.rentDeleteContractItemFailure(
            error,
            actionTypes.RENT_DELETE_CONTRACT_ITEM_FAILURE
          ))),
          takeUntil(action$.ofType(
            actionTypes.RENT_DELETE_CONTRACT_ITEM_RESPONSE ||
            actionTypes.RENT_DELETE_CONTRACT_ITEM_FAILURE
          ))
      )
    })
  );

export const createContractEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.RENT_CREATE_CONTRACT_REQUEST),
    mergeMap(action => {
      const { newContract } = action.payload;
      return from(AirshopApi.post(
        'rent/contract', newContract
      ))
      .pipe(
          map(res => res.data.status === 'OK' ? res.data.data : err(errMessages.NOT_OK, res)),
          map(response => actions.rentCreateContractResponse(
            response,
            actionTypes.RENT_CREATE_CONTRACT_RESPONSE
          )),
          catchError(error => of(actions.rentCreateContractFailure(
            error,
            actionTypes.RENT_CREATE_CONTRACT_FAILURE
          ))),
          takeUntil(action$.ofType(
            actionTypes.RENT_CREATE_CONTRACT_RESPONSE ||
            actionTypes.RENT_CREATE_CONTRACT_FAILURE
          ))
      )
    })
  );

export const editContractEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.RENT_EDIT_CONTRACT_REQUEST),
    mergeMap(action => {
      const { contractId, editedContract } = action.payload;
      return from(AirshopApi.put(
        'rent/contract',
        editedContract,
        { params: { contractId }}
      ))
      .pipe(
          map(res => res.data.status === 'OK' ? res.data.data : err(errMessages.NOT_OK, res)),
          map(response => actions.rentEditContractResponse(
            response,
            actionTypes.RENT_EDIT_CONTRACT_RESPONSE
          )),
          catchError(error => of(actions.rentEditContractFailure(
            error,
            actionTypes.RENT_EDIT_CONTRACT_FAILURE
          ))),
          takeUntil(action$.ofType(
            actionTypes.RENT_EDIT_CONTRACT_RESPONSE ||
            actionTypes.RENT_EDIT_CONTRACT_FAILURE
          ))
      )
    })
  );

export const deleteContractEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.RENT_DELETE_CONTRACT_REQUEST),
    mergeMap(action => {
      const { contractId } = action.payload;
      return from(AirshopApi.delete(
        'rent/contract',
        { params: { contractId }}
      ))
      .pipe(
          map(res => res.data.status === 'OK' ? res.data.data : err(errMessages.NOT_OK, res)),
          map(response => actions.rentDeleteContractItemResponse(
            response,
            actionTypes.RENT_DELETE_CONTRACT_RESPONSE
          )),
          catchError(error => of(actions.rentDeleteContractItemFailure(
            error,
            actionTypes.RENT_DELETE_CONTRACT_FAILURE
          ))),
          takeUntil(action$.ofType(
            actionTypes.RENT_DELETE_CONTRACT_RESPONSE ||
            actionTypes.RENT_DELETE_CONTRACT_FAILURE
          ))
      )
    })
  );

export const fetchContractsListEpic = (action$, state$) =>
  action$.pipe(
    ofType(actionTypes.RENT_CONTRACTS_LIST_REQUEST),
    mergeMap(action => {
      const { draw, page, limit, search, status, customerId, partnersId, fetchDeposits, fetchDocuments } = action.payload;
      const params = { draw, page, limit, search, status, order: 'date_created', sort: 'DESC'};
      const tableData = {};
      let customers
      let deposits
      let path;
      if (customerId) {
        path = 'rent/contract/customerId';
        params.customerId = customerId;
      } else if (partnersId) {
        path = 'rent/contract/partnersId';
        params.partnersId = partnersId;
      } else {
        path = 'rent/contract';
      }
      return from(AirshopApi.get(path, { params }))
      .pipe(
        map(response => {
          if (response.data.status === 'OK') {
            tableData.items = response.data.items;
            tableData.total = response.data.total;
            return response.data.items;
          } else {
            err(errMessages.NOT_OK, response);
          }
        }),
        // FETCH CONTRACTS CUSTOMERS
        mergeMap(items => iif(
          () => items.length,
          defer(() => forkJoin(items.map(item=> AirshopApi.get(
            `rent/customer/${item.customerId}`
            // If unusual customerId, 404, handle by ignoring it and passing only the successful ones
            ).catch(error => of(error)) ))),
          defer(() => of([]))
        )),
        mergeMap(items => iif(
          () => items.length,
          defer(() => of(passOnlySuccessForkJoinResponses(items, false))),
          defer(() => of([]))
        )),
        // FETCH CONTRACTS DEPOSITS
        mergeMap(customersResponses => {
          customers = { ...state$.value.rent.relatedCustomers };
          customersResponses.length && customersResponses.forEach(
            customerRes => (customers[customerRes.data.id] = customerRes.data)
          );
          const depositsIds = tableData.items
                                .filter(item => item.depositId)
                                .map(item => item.depositId);
          return iif(
            () => fetchDeposits && depositsIds.length,
            defer(() => forkJoin(depositsIds.map(depositId => AirshopApi.get(
              `rent/deposit/${depositId}`
              ).catch(error => of(error)) ))),
            defer(() => of([]))
          )
        }),
        mergeMap(items => iif(
          () => items.length,
          defer(() => of(passOnlySuccessForkJoinResponses(items, false))),
          defer(() => of([]))
        )),
        // FETCH CONTRACTS DOCUMENTS
        mergeMap(depositsResponses => {
          deposits = { ...state$.value.rent.relatedDeposits };
          depositsResponses.length && depositsResponses.forEach(
            depositRes => (deposits[depositRes.data.id] = depositRes.data)
          );
          const contractIds = tableData.items.map(item => item.id);
          return iif(
            () => fetchDocuments && contractIds.length,
            defer(() => forkJoin(contractIds.map(contractId => AirshopApi.get(
              'rent/contractDocument/contractId', { params: { contractId }}
              ).catch(error => of(error)) ))),
            defer(() => of([]))
          )
        }),
        mergeMap(items => iif(
          () => items.length,
          defer(() => of(passOnlySuccessForkJoinResponses(items, true))),
          defer(() => of([]))
        )),
        map(documentsResponses => {
          const documents = { ...state$.value.rent.relatedPdfDocuments };
          documentsResponses.length && documentsResponses.forEach(documentsRes => {
            const contractDocumentsArr = documentsRes.data.items
            contractDocumentsArr?.length && contractDocumentsArr.forEach(item => {
              if (!documents[documentsRes.config.params.contractId]) {
                documents[documentsRes.config.params.contractId] = {}
              }
              documents[documentsRes.config.params.contractId][item.type] = item
            })
          });
          return actions.rentContractsListResponse({
            tableData,
            customers,
            deposits,
            documents,
        })}),
        catchError(error => of(actions.rentContractsListFailure(
          error
        ))),
        takeUntil(action$.ofType(
          actionTypes.RENT_CONTRACTS_LIST_RESPONSE ||
          actionTypes.RENT_CONTRACTS_LIST_FAILURE
        ))
      )
    })
  );

export const fetchScanDocumentsListByContractIdEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.RENT_SCAN_DOCUMENTS_LIST_BY_CONTRACT_ID_REQUEST),
    mergeMap(action => {
      const { contractId } = action.payload;
      return from(AirshopApi.get('rent/scanDocument/contractId', {
        params: { contractId, limit: 10, order: 'date_created', sort: 'DESC'}
      }))
      .pipe(
          map(res => res.data.status === 'OK' ? res.data.items : err(errMessages.NOT_OK, res)),
          map(response => actions.rentScanDocumentsListByContractIdResponse({
            [contractId]: response
          })),
          catchError(error => of(actions.rentScanDocumentsListByContractIdFailure(
            error
          ))),
          takeUntil(action$.ofType(
            actionTypes.RENT_SCAN_DOCUMENTS_LIST_BY_CONTRACT_ID_RESPONSE ||
            actionTypes.RENT_SCAN_DOCUMENTS_LIST_BY_CONTRACT_ID_FAILURE
          ))
      )
    })
  );

export const createScanDocumentEpic = (action$, state$) =>
  action$.pipe(
    ofType(actionTypes.RENT_CREATE_SCAN_DOCUMENT_REQUEST),
    mergeMap(action => {
      const { newScanDocument, contractId, type } = action.payload;
      const partnerId = state$.value.auth.idm.id;
      return from(AirshopApi.post('rent/scanDocument/data', newScanDocument, {
        headers: { 'content-type': 'multipart/form-data' },
        params: { contractId, type, partnerId }
      }))
      .pipe(
          map(res => res.data.status === 'OK' ? res.data : err(errMessages.NOT_OK, res)),
          map(response => actions.rentCreateScanDocumentResponse(
            response
          )),
          catchError(error => of(actions.rentCreateScanDocumentFailure(
            error
          ))),
          takeUntil(action$.ofType(
            actionTypes.RENT_CREATE_SCAN_DOCUMENT_RESPONSE ||
            actionTypes.RENT_CREATE_SCAN_DOCUMENT_FAILURE
          ))
      )
    })
  );

export const deleteScanDocumentEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.RENT_DELETE_SCAN_DOCUMENT_REQUEST),
    mergeMap(action => {
      const { scanDocumentId } = action.payload;
      return from(AirshopApi.delete('rent/scanDocument',
        { params: { scanDocumentId }}
      ))
      .pipe(
          map(res => res.data.status === 'OK' ? res.data.data : err(errMessages.NOT_OK, res)),
          map(response => actions.rentDeleteScanDocumentResponse(
            response
          )),
          catchError(error => of(actions.rentDeleteScanDocumentFailure(
            error
          ))),
          takeUntil(action$.ofType(
            actionTypes.RENT_DELETE_SCAN_DOCUMENT_RESPONSE ||
            actionTypes.RENT_DELETE_SCAN_DOCUMENT_FAILURE
          ))
      )
    })
  );

export const fetchTargetCommodityForRentalListEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.RENT_TARGET_COMMODITY_FOR_RENTAL_LIST_REQUEST),
    mergeMap(action => {
      const { draw, page, limit, search } = action.payload;
      return from(AirshopApi.get('fc/targetCommodity/forRental', {
        params: { draw, page, limit, search, order: 'date_created', sort: 'DESC'}
      }))
      .pipe(
          map(res => res.data.status === 'OK' ? res.data : err(errMessages.NOT_OK, res)),
          map(response => actions.renttargetCommodityForRentalListResponse({
            total: response.total, items: response.items,
          })),
          catchError(error => of(actions.renttargetCommodityForRentalListFailure(
            error
          ))),
          takeUntil(action$.ofType(
            actionTypes.RENT_TARGET_COMMODITY_FOR_RENTAL_LIST_RESPONSE ||
            actionTypes.RENT_TARGET_COMMODITY_FOR_RENTAL_LIST_FAILURE
          ))
      )
    })
  );

export const fetchReservationsListEpic = (action$, state$) =>
  action$.pipe(
    ofType(actionTypes.RENT_RESERVATIONS_LIST_REQUEST),
    mergeMap(action => {
      const { draw, page, limit, search } = action.payload;
      const tableData = {};
      return from(AirshopApi.get('rent/reservation', {
        params: { draw, page, limit, search, sort: 'DESC' }
      }))
      .pipe(
        map(response => {
          if (response.data.status === 'OK') {
            tableData.items = response.data.items;
            tableData.total = response.data.total;
            return response.data.items;
          } else {
            err(errMessages.NOT_OK, response);
          }
        }),
        // FETCH RESERVATIONS CUSTOMERS
        mergeMap(items => iif(
          () => items.length,
          defer(() => forkJoin(items.map(item=> AirshopApi.get(
            `rent/customer/${item.customerId}`
            // If unusual customerId, 404, handle by ignoring it and passing only the successful ones
            ).catch(error => of(error)) ))),
          defer(() => of([]))
        )),
        mergeMap(items => iif(
          () => items.length,
          defer(() => of(passOnlySuccessForkJoinResponses(items, false))),
          defer(() => of([]))
        )),
        map(customersResponses => {
          const customersById = { ...state$.value.rent.relatedCustomers };
          customersResponses.length && customersResponses.forEach(
            customerRes => (customersById[customerRes.data.id] = customerRes.data)
          );
          return actions.rentReservationsListResponse({
            tableData,
            customers: customersById
        })}),
        catchError(error => of(actions.rentReservationsListFailure(
          error
        ))),
        takeUntil(action$.ofType(
          actionTypes.RENT_RESERVATIONS_LIST_RESPONSE ||
          actionTypes.RENT_RESERVATIONS_LIST_FAILURE
        ))
      )
    })
  );

export const createReservationEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.RENT_CREATE_RESERVATION_REQUEST),
    mergeMap(action => {
      const { newReservation } = action.payload;
      return from(AirshopApi.post(
        'rent/reservation', newReservation
      ))
      .pipe(
          map(res => res.data.status === 'OK' ? res.data.data : err(errMessages.NOT_OK, res)),
          map(response => actions.rentCreateReservationResponse(
            response,
            actionTypes.RENT_CREATE_RESERVATION_RESPONSE
          )),
          catchError(error => of(actions.rentCreateReservationFailure(
            error,
            actionTypes.RENT_CREATE_RESERVATION_FAILURE
          ))),
          takeUntil(action$.ofType(
            actionTypes.RENT_CREATE_RESERVATION_RESPONSE ||
            actionTypes.RENT_CREATE_RESERVATION_FAILURE
          ))
      )
    })
  );

export const editReservationEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.RENT_EDIT_RESERVATION_REQUEST),
    mergeMap(action => {
      const { reservationId, editedReservation } = action.payload;
      return from(AirshopApi.put(
        'rent/reservation',
        editedReservation,
        { params: { reservationId }}
      ))
      .pipe(
          map(res => res.data.status === 'OK' ? res.data.data : err(errMessages.NOT_OK, res)),
          map(response => actions.rentEditReservationResponse(
            response,
            actionTypes.RENT_EDIT_RESERVATION_RESPONSE
          )),
          catchError(error => of(actions.rentEditReservationFailure(
            error,
            actionTypes.RENT_EDIT_RESERVATION_FAILURE
          ))),
          takeUntil(action$.ofType(
            actionTypes.RENT_EDIT_RESERVATION_RESPONSE ||
            actionTypes.RENT_EDIT_RESERVATION_FAILURE
          ))
      )
    })
  );

export const fetchReservationItemsListByReservationIdEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.RENT_RESERVATION_ITEMS_LIST_BY_RESERVATION_ID_REQUEST),
    mergeMap(action => {
      const { reservationId } = action.payload;
      return from(AirshopApi.get('rent/reservationItems/reservationId', {
        params: { reservationId, limit: 100, order: 'date_created', sort: 'DESC'}
      }))
      .pipe(
          map(res => res.data.status === 'OK' ? res.data.items : err(errMessages.NOT_OK, res)),
          map(items => actions.rentReservationItemsListByReservationIdResponse({
            [reservationId]: items
          })),
          catchError(error => of(actions.rentReservationItemsListByReservationIdFailure(
            error
          ))),
          takeUntil(action$.ofType(
            actionTypes.RENT_RESERVATION_ITEMS_LIST_BY_RESERVATION_ID_RESPONSE ||
            actionTypes.RENT_RESERVATION_ITEMS_LIST_BY_RESERVATION_ID_FAILURE
          ))
      )
    })
  );

export const createReservationItemEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.RENT_CREATE_RESERVATION_ITEM_REQUEST),
    mergeMap(action => {
      const { newReservationItem } = action.payload;
      return from(AirshopApi.post(
        'rent/reservationItems', newReservationItem
      ))
      .pipe(
          map(res => res.data.status === 'OK' ? res.data.data : err(errMessages.NOT_OK, res)),
          map(response => actions.rentCreateReservationItemResponse(
            response,
            actionTypes.RENT_CREATE_RESERVATION_ITEM_RESPONSE
          )),
          catchError(error => of(actions.rentCreateReservationItemFailure(
            error,
            actionTypes.RENT_CREATE_RESERVATION_ITEM_FAILURE
          ))),
          takeUntil(action$.ofType(
            actionTypes.RENT_CREATE_RESERVATION_ITEM_RESPONSE ||
            actionTypes.RENT_CREATE_RESERVATION_ITEM_FAILURE
          ))
      )
    })
  );


export const deleteReservationItemEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.RENT_DELETE_RESERVATION_ITEM_REQUEST),
    mergeMap(action => {
      const { reservationItemsId } = action.payload;
      return from(AirshopApi.delete('rent/reservationItems',
        { params: { reservationItemsId }}
      ))
      .pipe(
          map(res => res.data.status === 'OK' ? res.data.data : err(errMessages.NOT_OK, res)),
          map(response => actions.rentDeleteReservationItemResponse(
            response,
            actionTypes.RENT_DELETE_RESERVATION_ITEM_RESPONSE
          )),
          catchError(error => of(actions.rentDeleteReservationItemFailure(
            error,
            actionTypes.RENT_DELETE_RESERVATION_ITEM_FAILURE
          ))),
          takeUntil(action$.ofType(
            actionTypes.RENT_DELETE_RESERVATION_ITEM_RESPONSE ||
            actionTypes.RENT_DELETE_RESERVATION_ITEM_FAILURE
          ))
      )
    })
  );

export const finalizeContractEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.RENT_FINALIZE_CONTRACT_REQUEST),
    mergeMap(action => {
      const { contractId } = action.payload;
      return from(AirshopApi.get('rent/contract/finalizeContract', {
        params: { contractId }
      }))
      .pipe(
          map(res => res.data.status === 'OK' ? res.data : err(errMessages.NOT_OK, res)),
          map(response => actions.rentFinalizeContractResponse(
            response,
            actionTypes.RENT_FINALIZE_CONTRACT_RESPONSE
          )),
          catchError(error => of(actions.rentFinalizeContractFailure(
            error,
            actionTypes.RENT_FINALIZE_CONTRACT_FAILURE
          ))),
          takeUntil(action$.ofType(
            actionTypes.RENT_FINALIZE_CONTRACT_RESPONSE ||
            actionTypes.RENT_FINALIZE_CONTRACT_FAILURE
          ))
      )
    })
  );

export const finalizeContractReturnEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.RENT_FINALIZE_RETURN_REQUEST),
    mergeMap(action => {
      const { contractId } = action.payload;
      return from(AirshopApi.get('rent/contract/createContractReturnProtocol', {
        params: { contractId }
      }))
      .pipe(
          map(res => res.data.status === 'OK' ? res.data : err(errMessages.NOT_OK, res)),
          map(response => actions.rentFinalizeReturnResponse(
            response,
            actionTypes.RENT_FINALIZE_RETURN_RESPONSE
          )),
          catchError(error => of(actions.rentFinalizeReturnFailure(
            error,
            actionTypes.RENT_FINALIZE_RETURN_FAILURE
          ))),
          takeUntil(action$.ofType(
            actionTypes.RENT_FINALIZE_RETURN_RESPONSE ||
            actionTypes.RENT_FINALIZE_RETURN_FAILURE
          ))
      )
    })
  );

export const deleteReservationEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.RENT_DELETE_RESERVATION_REQUEST),
    mergeMap(action => {
      const { reservationId } = action.payload;
      return from(AirshopApi.delete('rent/reservation',
        { params: { reservationId }}
      ))
      .pipe(
          map(res => res.data.status === 'OK' ? res.data.data : err(errMessages.NOT_OK, res)),
          map(response => actions.rentDeleteReservationResponse(
            response,
            actionTypes.RENT_DELETE_RESERVATION_RESPONSE
          )),
          catchError(error => of(actions.rentDeleteReservationFailure(
            error,
            actionTypes.RENT_DELETE_RESERVATION_FAILURE
          ))),
          takeUntil(action$.ofType(
            actionTypes.RENT_DELETE_RESERVATION_RESPONSE ||
            actionTypes.RENT_DELETE_RESERVATION_FAILURE
          ))
      )
    })
  );

export const fetchCustomerEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.RENT_CUSTOMER_REQUEST),
    mergeMap(action => {
      const { customerId } = action.payload;
      return from(AirshopApi.get(`rent/customer/${customerId}`))
      .pipe(
          map(res => res.status === 200 ? res.data : err(errMessages.NOT_200, res)),
          map(response => actions.rentCustomerResponse(response)),
          catchError(error => of(actions.rentCustomerFailure(
            error
          ))),
          takeUntil(action$.ofType(
            actionTypes.RENT_CUSTOMER_RESPONSE ||
            actionTypes.RENT_CUSTOMER_FAILURE
          ))
      )
    })
  );

export const generatePdfDocumentEpic = (action$, state$) =>
  action$.pipe(
    ofType(actionTypes.RENT_GENERATE_PDF_REQUEST),
    mergeMap(action => {
      const { type, itemId } = action.payload;
      const { sessionId, userUid } = state$.value.auth;

      return from(AirshopApi.get(
        `print/p/document?type=${type}&id=${itemId}&uid=${userUid}&ident=${sessionId}`,
        {responseType: 'blob'}))
      .pipe(
        map(response => response.status === 200 && response.data && response.data.type === 'text/xml' ?
                        response.data :
                        err(errMessages.DATA_NOT_GENERATED, response.data)
        ),
        map(response => actions.rentGeneratePdfDocumentResponse(
          response,
          actionTypes.RENT_GENERATE_PDF_RESPONSE
        )),
        catchError(error => of(actions.rentGeneratePdfDocumentFailure(
          error,
          actionTypes.RENT_GENERATE_PDF_FAILURE
        ))
        ),
        takeUntil(action$.ofType(
          actionTypes.RENT_GENERATE_PDF_RESPONSE ||
          actionTypes.RENT_GENERATE_PDF_FAILURE
        ))
      )
    })
  );


export const createDepositEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.RENT_CREATE_DEPOSIT_REQUEST),
    mergeMap(action => {
      const { newDeposit } = action.payload;
      return from(AirshopApi.post('rent/deposit', newDeposit))
      .pipe(
          map(res => res.data.status === 'OK' ? res.data.data : err(errMessages.NOT_OK, res)),
          map(response => actions.rentCreateDepositResponse(
            response,
            actionTypes.RENT_CREATE_DEPOSIT_RESPONSE
          )),
          catchError(error => of(actions.rentCreateDepositFailure(
            error,
            actionTypes.RENT_CREATE_DEPOSIT_FAILURE
          ))),
          takeUntil(action$.ofType(
            actionTypes.RENT_CREATE_DEPOSIT_RESPONSE ||
            actionTypes.RENT_CREATE_DEPOSIT_FAILURE
          ))
      )
    })
  );

export const editDepositEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.RENT_EDIT_DEPOSIT_REQUEST),
    mergeMap(action => {
      const { depositId, editedDeposit } = action.payload;
      return from(AirshopApi.put(
        'rent/deposit',
        editedDeposit,
        { params: { depositId }}
      ))
      .pipe(
          map(res => res.data.status === 'OK' ? res.data.data : err(errMessages.NOT_OK, res)),
          map(response => actions.rentEditDepositResponse(
            response,
            actionTypes.RENT_EDIT_DEPOSIT_RESPONSE
          )),
          catchError(error => of(actions.rentEditDepositFailure(
            error,
            actionTypes.RENT_EDIT_DEPOSIT_FAILURE
          ))),
          takeUntil(action$.ofType(
            actionTypes.RENT_EDIT_DEPOSIT_RESPONSE ||
            actionTypes.RENT_EDIT_DEPOSIT_FAILURE
          ))
      )
    })
  );

export const transformReservationToContractEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.RENT_TRANSFORM_RESERVATION_TO_CONTRACT_REQUEST),
    mergeMap(action => {
      const { reservationId, paymentType } = action.payload;
      return from(AirshopApi.get('rent/contract/transformReservation', {
        params: { reservationId, paymentType }
      }))
      .pipe(
          map(res => res.data.status === 'OK' ? res.data.data : err(errMessages.NOT_OK, res)),
          map(response => actions.rentTransformReservationToContractResponse(
            response,
            actionTypes.RENT_TRANSFORM_RESERVATION_TO_CONTRACT_RESPONSE
          )),
          catchError(error => of(actions.rentTransformReservationToContractFailure(
            error,
            actionTypes.RENT_TRANSFORM_RESERVATION_TO_CONTRACT_FAILURE
          ))),
          takeUntil(action$.ofType(
            actionTypes.RENT_TRANSFORM_RESERVATION_TO_CONTRACT_RESPONSE ||
            actionTypes.RENT_TRANSFORM_RESERVATION_TO_CONTRACT_FAILURE
          ))
      )
    })
  );

export const remindRentContractEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.RENT_REMIND_RENT_CONTRACT_REQUEST),
    mergeMap(action => {
      const { contractId } = action.payload;
      return from(AirshopApi.get('rent/contract/remind', {
        params: { contractId }
      }))
      .pipe(
          map(res => res.data.status === 'OK' ? res.data.data : err(errMessages.NOT_OK, res)),
          map(response => actions.rentRemindRentContractResponse(
            response,
            actionTypes.RENT_REMIND_RENT_CONTRACT_RESPONSE
          )),
          catchError(error => of(actions.rentRemindRentContractFailure(
            error,
            actionTypes.RENT_REMIND_RENT_CONTRACT_FAILURE
          ))),
          takeUntil(action$.ofType(
            actionTypes.RENT_REMIND_RENT_CONTRACT_RESPONSE ||
            actionTypes.RENT_REMIND_RENT_CONTRACT_FAILURE
          ))
      )
    })
  );

export const printRentDocumentEpic = (action$, state$) =>
  action$.pipe(
    ofType(actionTypes.RENT_PRINT_DOCUMENT_REQUEST),
    mergeMap(action => {
      const { type, itemId } = action.payload;
      const { sessionId, userUid } = state$.value.auth;

      return from(AirshopApi.get('print/p/printA4', {
        params: {
          type,
          id: itemId,
          uid: userUid,
          ident: sessionId,
         }
      }))
      .pipe(
          map(res => res.data.status === 'OK' ? res.data : err(errMessages.NOT_OK, res)),
          map(response => actions.rentPrintDocumentResponse(
            response,
            actionTypes.RENT_PRINT_DOCUMENT_RESPONSE
          )),
          catchError(error => of(actions.rentPrintDocumentFailure(
            error,
            actionTypes.RENT_PRINT_DOCUMENT_FAILURE
          ))),
          takeUntil(action$.ofType(
            actionTypes.RENT_PRINT_DOCUMENT_RESPONSE ||
            actionTypes.RENT_PRINT_DOCUMENT_FAILURE
          ))
      )
    })
  );

export const fetchDepositSumEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.RENT_DEPOSIT_SUM_REQUEST),
    mergeMap(action => {
      const { createdGte, createdLte, paymentType, status } = action.payload;
      return from(AirshopApi.get('rent/deposit/depositSum', {
        params: { createdGte, createdLte, paymentType, status }
      }))
      .pipe(
          map(res => res.data.status === 'OK' ? res.data.data : err(errMessages.NOT_OK, res)),
          map(response => actions.rentDepositSumResponse(
            response
          )),
          catchError(error => of(actions.rentDepositSumFailure(
            error
          ))),
          takeUntil(action$.ofType(
            actionTypes.RENT_DEPOSIT_SUM_RESPONSE ||
            actionTypes.RENT_DEPOSIT_SUM_FAILURE
          ))
      )
    })
  );

export const fetchContractDocumentsEpic = (action$, state$) =>
  action$.pipe(
    ofType(actionTypes.RENT_CONTRACT_DOCUMENTS_REQUEST),
    mergeMap(action => {
      const { contractId } = action.payload;
      return from(AirshopApi.get('rent/contractDocument/contractId', {
        params: { contractId }
      }))
      .pipe(
        map(res => res.data.status === 'OK' ? res.data.items : err(errMessages.NOT_OK, res)),
        map(contractDocumentsArr => {
          const documents = { ...state$.value.rent.relatedPdfDocuments };
          contractDocumentsArr?.length && contractDocumentsArr.forEach(item => {
            if (!documents[contractId]) {
              documents[contractId] = {}
            }
            documents[contractId][item.type] = item
          })
          return actions.rentContractDocumentsResponse(
            documents
          )}
        ),
        catchError(error => of(actions.rentContractDocumentsFailure(
          error
        ))),
        takeUntil(action$.ofType(
          actionTypes.RENT_CONTRACT_DOCUMENTS_RESPONSE ||
          actionTypes.RENT_CONTRACT_DOCUMENTS_FAILURE
        ))
      )
    })
  );

export const prepareContractFromContractEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.RENT_PREPARE_CONTRACT_FROM_CONTRACT_REQUEST),
    mergeMap(action => {
      const { contractId, depositId, paymentType, timestampFrom, timestampTo } = action.payload;
      return from(AirshopApi.get('rent/contract/prepareNewContract', {
        params: { contractId, depositId, paymentType, timestampFrom, timestampTo }
      }))
      .pipe(
          map(res => res.data.status === 'OK' ? res.data.data : err(errMessages.NOT_OK, res)),
          map(response => actions.rentPrepareContractFromContractResponse(
            response,
            actionTypes.RENT_PREPARE_CONTRACT_FROM_CONTRACT_RESPONSE
          )),
          catchError(error => of(actions.rentPrepareContractFromContractFailure(
            error,
            actionTypes.RENT_PREPARE_CONTRACT_FROM_CONTRACT_FAILURE
          ))),
          takeUntil(action$.ofType(
            actionTypes.RENT_PREPARE_CONTRACT_FROM_CONTRACT_RESPONSE ||
            actionTypes.RENT_PREPARE_CONTRACT_FROM_CONTRACT_FAILURE
          ))
      )
    })
  );

export const prepareReservationFromContractEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.RENT_PREPARE_RESERVATION_FROM_CONTRACT_REQUEST),
    mergeMap(action => {
      const { contractId, depositId, timestampFrom, timestampTo } = action.payload;
      return from(AirshopApi.get('rent/reservation/prepareReservationFromContract', {
        params: { contractId, depositId, timestampFrom, timestampTo }
      }))
      .pipe(
          map(res => res.data.status === 'OK' ? res.data.data : err(errMessages.NOT_OK, res)),
          map(response => actions.rentPrepareReservationFromContractResponse(
            response,
            actionTypes.RENT_PREPARE_RESERVATION_FROM_CONTRACT_RESPONSE
          )),
          catchError(error => of(actions.rentPrepareReservationFromContractFailure(
            error,
            actionTypes.RENT_PREPARE_RESERVATION_FROM_CONTRACT_FAILURE
          ))),
          takeUntil(action$.ofType(
            actionTypes.RENT_PREPARE_RESERVATION_FROM_CONTRACT_RESPONSE ||
            actionTypes.RENT_PREPARE_RESERVATION_FROM_CONTRACT_FAILURE
          ))
      )
    })
  );
