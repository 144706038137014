// eslint-disable-next-line no-unused-vars
import React, { useEffect } from 'react'
import { Store as store } from 'react-notifications-component'
import { notifications as notificationsObjects } from './utils/notifications'
import { getPropertyTypeName } from './utils/property-types-switch'
/*
 * This renderless component uses the library
 * react-notifications-components
 *
 * It is registered in src/containers/TheLayout.js
 *
 * Here are predefined notifications (those need only itemName and type) and
 * custom notification (those require a full notification store object - customNotificationObject)
 *
 * USAGE:
 * Define this component in the parent render method, control whether
 * the notification is shown with boolean'show' property (with state)
 *
*/
const CrudNotification = props => {

  const {
    // MANDATORY
    show, // boolean
    itemName='', // string
    type, // string
    resetNotification, // function

    // OPTIONAL
    duration,
    // see docs of the eact-notifications-component to construct the object
    customNotificationObject, // necessary for CUSTOM type
  } = props;

  /*
   * ON NOTIFICATION REMOVAL INFORMATION:
   * Every CRUD request is noted in redux state with res result message,
   * after notification is shown, the res result message must be reset in
   * redux state.
   *
   * Therefore, such a clearing function is provided in props, it is created
   * in every redux facade.
   *
   * This function is provided to the notification options object, it is
   * triggered on the notifications removal.
  */
  const getNotificationObject = (
    typeFromProps,
    propertyTypeFromSwitch,
    name,
    resetNotification,
  ) => {
    // Handle custom case
    if ((typeFromProps === 'CUSTOM') && customNotificationObject) {
      return {
        ...customNotificationObject,
        onRemoval: (id, removedBy) => {
          resetNotification();
        }
      };
    // Handle all other cases
    } else {
      const messageArr = [
        notificationsObjects[propertyTypeFromSwitch].message.mes1,
        name,
        notificationsObjects[propertyTypeFromSwitch].message.mes2,
      ];
      const titleArr = [notificationsObjects[propertyTypeFromSwitch].title, name];

      // Ternaries did not work for strings
      const constrcutedMessage = messageArr.filter(Boolean).join(' ');
      const constructedTitle = titleArr.filter(Boolean).join(' ');

      return {
        title: constructedTitle,
        message: constrcutedMessage,
        type: notificationsObjects[propertyTypeFromSwitch].notificationType,
        insert: 'top',
        container: 'top-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: duration ? duration : 3500,
          pauseOnHover: true,
          onScreen: true,
        },
        onRemoval: (id, removedBy) => {
          resetNotification();
        }
      }
    }
  }

  // Create the notification
  const notify = (itemName, type) => {
    const propertyTypeFromSwitch = getPropertyTypeName(type);

      // Custom case handled
      if (
        ((type === 'CUSTOM') && customNotificationObject) ||
        ((type !== 'CUSTOM') && !customNotificationObject)
        ) {
          const notificationObjectBasedOnType = getNotificationObject(
            type,
            propertyTypeFromSwitch,
            itemName ? itemName.toLowerCase() : '',
            resetNotification,
          );
          store.addNotification(notificationObjectBasedOnType);
      }
  }

  useEffect(() => {
  // Trigger the notification
  if (show && type && resetNotification) {
    notify(itemName, type.toUpperCase());
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  // Renderless component, parent renders
  return (null);
};

export default CrudNotification;
