import axios from 'axios';
import { emailUrl, airshopUrl, baseUrl, mailchimpDeliUrl, mailchimpBeerUrl } from './AppConfig';

// Api
export const CraftApi = axios.create();
CraftApi.defaults.baseURL = baseUrl();
CraftApi.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8';

// Api
export const AirshopApi = axios.create();
AirshopApi.defaults.baseURL = airshopUrl();
AirshopApi.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8';

// Email api
export const EmailApi = axios.create();
EmailApi.defaults.baseURL = emailUrl();
EmailApi.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8';

// Mailchimp deli api
export const MailchimpDeliApi = axios.create();
MailchimpDeliApi.defaults.baseURL = mailchimpDeliUrl();

// Mailchimp beer api
export const MailchimpBeerApi = axios.create();
MailchimpBeerApi.defaults.baseURL = mailchimpBeerUrl();

// Set up through auth redux
export const setToken = token => {
  CraftApi.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  AirshopApi.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  // EmailApi.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}

