import * as actionTypes from './collection-lists.actionTypes'

const initialState = {
  collectionListsPageData: null,
  collectionListsPageDraw: null,
  collectionListsPagePage: 0,
  collectionListsTotal: null,
  // Uses simulated number of rows, more in
  collectionListsPageLimit: 500,
  selectedCollectionListItems: {
    itemsPageData: null,
    itemsPageDraw: null,
    itemsPagePage: 0,
    itemsPageLimit: 20,
    itemsTotal: null,
  },
  addedBalikobotOrders: {
    addedBalikobotOrdersPageData: null,
    addedBalikobotOrdersPageDraw: null,
    addedBalikobotOrdersPagePage: 0,
    addedBalikobotOrdersPageLimit: 50,
    addedBalikobotOrdersTotal: null,
  },
  stores: null,
  shippers: null,
  generatedPdfDocument: null,
  resMessage: null,
  error: null,
  fetching: false,
}

const collectionListsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.COLLECTION_LISTS_RESPONSE_MESSAGE_RESET:
      return {
        ...state,
        resMessage: null,
      };
    case actionTypes.COLLECTION_LISTS_COLLECTION_LIST_STATUS_CHANGE_REQUEST:
    case actionTypes.COLLECTION_LISTS_STORES_LIST_REQUEST:
    case actionTypes.COLLECTION_LISTS_LIST_DETAIL_ITEM_DELETE_REQUEST:
    case actionTypes.COLLECTION_LISTS_SHIPPERS_LIST_REQUEST:
    case actionTypes.COLLECTION_LISTS_GENERATE_PDF_REQUEST:
    case actionTypes.COLLECTION_LISTS_PRINT_DOCUMENT_REQUEST:
      return {
        ...state,
        fetching: true,
      };
    case actionTypes.COLLECTION_LISTS_LIST_PAGE_REQUEST:
      return {
        ...state,
        collectionListsPageDraw: action.payload.draw,
        collectionListsPagePage: action.payload.page,
        collectionListsPageLimit: action.payload.limit,
        fetching: true,
      };
    case actionTypes.COLLECTION_LISTS_LIST_DETAIL_ITEMS_PAGE_REQUEST:
      return {
        ...state,
        selectedCollectionListItems: {
          ...state.selectedCollectionListItems,
          itemsPageDraw: action.payload.draw,
          itemsPagePage: action.payload.page,
          itemsPageLimit: action.payload.limit,
        },
        fetching: true,
      };
    case actionTypes.COLLECTION_LISTS_ADDED_BALIKOBOT_ORDERS_REQUEST:
      return {
        ...state,
        addedBalikobotOrders: {
          ...state.addedBalikobotOrders,
          addedBalikobotOrdersPageDraw: action.payload.draw,
          addedBalikobotOrdersPagePage: action.payload.page,
          addedBalikobotOrdersPageLimit: action.payload.limit,
        },
        fetching: true,
      };
    case actionTypes.COLLECTION_LISTS_LIST_PAGE_RESPONSE:
      return {
        ...state,
        collectionListsPageData: action.payload.items,
        collectionListsTotal: action.payload.total,
        fetching: false,
      };
    case actionTypes.COLLECTION_LISTS_LIST_DETAIL_ITEMS_PAGE_RESPONSE:
      return {
        ...state,
        selectedCollectionListItems: {
          ...state.selectedCollectionListItems,
          itemsPageData: action.payload.items,
          itemsTotal: action.payload.total,
        },
        fetching: false,
      };
    case actionTypes.COLLECTION_LISTS_ADDED_BALIKOBOT_ORDERS_RESPONSE:
      return {
        ...state,
        addedBalikobotOrders: {
          ...state.addedBalikobotOrders,
          addedBalikobotOrdersPageData: action.payload.items,
          addedBalikobotOrdersTotal: action.payload.total,
        },
        fetching: false,
      };
    case actionTypes.COLLECTION_LISTS_STORES_LIST_RESPONSE:
      return {
        ...state,
        stores: action.payload,
        fetching: false,
      };
    case actionTypes.COLLECTION_LISTS_COLLECTION_LIST_STATUS_CHANGE_RESPONSE:
    case actionTypes.COLLECTION_LISTS_LIST_DETAIL_ITEM_DELETE_RESPONSE:
    case actionTypes.COLLECTION_LISTS_PRINT_DOCUMENT_RESPONSE:
      return {
        ...state,
        resMessage: action.payload.resMessage,
        fetching: false,
      };
    case actionTypes.COLLECTION_LISTS_GENERATE_PDF_RESPONSE:
      return {
        ...state,
        generatedPdfDocument: action.payload.response,
        resMessage: action.payload.resMessage,
        fetching: false,
      };
    case actionTypes.COLLECTION_LISTS_RESET_GENERATED_PDF:
      return {
        ...state,
        generatedPdfDocument: null,
      };
    case actionTypes.COLLECTION_LISTS_SHIPPERS_LIST_RESPONSE:
      return {
        ...state,
        shippers: action.payload,
        fetching: false,
      };
    case actionTypes.COLLECTION_LISTS_LIST_PAGE_FAILURE:
    case actionTypes.COLLECTION_LISTS_LIST_DETAIL_ITEMS_PAGE_FAILURE:
    case actionTypes.COLLECTION_LISTS_STORES_LIST_FAILURE:
    case actionTypes.COLLECTION_LISTS_ADDED_BALIKOBOT_ORDERS_FAILURE:
    case actionTypes.COLLECTION_LISTS_SHIPPERS_LIST_FAILURE:
      return {
        ...state,
        fetching: false,
        error: {...action.payload}
      };
    case actionTypes.COLLECTION_LISTS_COLLECTION_LIST_STATUS_CHANGE_FAILURE:
    case actionTypes.COLLECTION_LISTS_LIST_DETAIL_ITEM_DELETE_FAILURE:
    case actionTypes.COLLECTION_LISTS_GENERATE_PDF_FAILURE:
    case actionTypes.COLLECTION_LISTS_PRINT_DOCUMENT_FAILURE:
      return {
        ...state,
        resMessage: action.payload.resMessage,
        fetching: false,
        error: {...action.payload}
      };
    default:
      return state;
  }
};

export default collectionListsReducer;
