import * as actionTypes from './mailchimp.actionTypes';

const initialState = {
  mailchimpPageData: null,
  mailchimpPageDraw: null,
  mailchimpPagePage: 0,
  mailchimpPageLimit: 50,
  mailchimpTotal: null,
  tags: null,
  resMessage: null,
  error: null,
  fetching: false,
}

const mailchimpReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.MAILCHIMP_RESPONSE_MESSAGE_RESET:
      return {
        ...state,
        resMessage: null,
      };
    case actionTypes.MAILCHIMP_TAGS_LIST_BY_SHOP_REQUEST:
    case actionTypes.MAILCHIMP_BULK_EMAILS_TO_EXISTING_TAG_BY_SHOP_REQUEST:
    case actionTypes.MAILCHIMP_BULK_EMAILS_TO_NEW_TAG_BY_SHOP_REQUEST:
      return {
        ...state,
        fetching: true,
      };
    case actionTypes.MAILCHIMP_ORDERS_LIST_REQUEST:
      return {
        ...state,
        mailchimpPageDraw: action.payload.list.draw,
        mailchimpPagePage: action.payload.list.page,
        mailchimpPageLimit: action.payload.list.limit,
        fetching: true,
      };
    case actionTypes.MAILCHIMP_ORDERS_LIST_RESPONSE:
      return {
        ...state,
        mailchimpPageData: action.payload.tableData.items,
        mailchimpTotal: action.payload.tableData.total,
        fetching: false,
      };
    case actionTypes.MAILCHIMP_TAGS_LIST_BY_SHOP_RESPONSE:
      return {
        ...state,
        tags: action.payload,
        fetching: false,
      };
    case actionTypes.MAILCHIMP_BULK_EMAILS_TO_EXISTING_TAG_BY_SHOP_RESPONSE:
    case actionTypes.MAILCHIMP_BULK_EMAILS_TO_NEW_TAG_BY_SHOP_RESPONSE:
      return {
        ...state,
        resMessage: action.payload.resMessage,
        fetching: false,
      };
    case actionTypes.MAILCHIMP_ORDERS_LIST_FAILURE:
    case actionTypes.MAILCHIMP_TAGS_LIST_BY_SHOP_FAILURE:
      return {
        ...state,
        error: {...action.payload},
        fetching: false,
      };
    case actionTypes.MAILCHIMP_BULK_EMAILS_TO_EXISTING_TAG_BY_SHOP_FAILURE:
    case actionTypes.MAILCHIMP_BULK_EMAILS_TO_NEW_TAG_BY_SHOP_FAILURE:
      return {
        ...state,
        resMessage: action.payload.resMessage,
        error: {...action.payload},
        fetching: false,
      };
    default:
      return state;
  }
};

export default mailchimpReducer;
