// Applied in redux epics
export const notificationTypes = {
  CREATE_SUCCESS: 'CREATE SUCCESS',
  CREATE_FAILURE: 'CREATE FAILURE',
  ACCESS_FAILURE: 'ACCESS FAILURE',
  EDIT_SUCCESS: 'EDIT SUCCESS',
  EDIT_FAILURE: 'EDIT FAILURE',
  DELETE_SUCCESS: 'DELETE SUCCESS',
  DELETE_FAILURE: 'DELETE FAILURE',
  RESET_FORM_SUCCESS: 'RESET FORM SUCCESS',
  RESET_FORM_FAILURE: 'RESET FORM FAILURE',
  SEND_M_SUCCESS: 'SEND M SUCCESS',
  SEND_F_SUCCESS: 'SEND F SUCCESS',
  SEND_M_FAILURE: 'SEND M FAILURE',
  SEND_F_FAILURE: 'SEND F FAILURE',
  CHANGE_PRODUCT_Q_SUCCESS: 'CHANGE PRODUCT Q SUCCESS',
  CHANGE_PRODUCT_Q_FAILURE: 'CHANGE PRODUCT Q FAILURE',
  SYNC_F_SUCCESS: 'SYNC F SUCCESS',
  SYNC_F_FAILURE: 'SYNC F FAILURE',
  DL_CREATE_SUCCESS: 'DL CREATE SUCCESS',
  DL_CREATE_FAILURE: 'DL CREATE FAILURE',
  DL_RECREATE_SUCCESS: 'DL RECREATE SUCCESS',
  DL_RECREATE_FAILURE: 'DL RECREATE FAILURE',
  CREATE_TRANSFER_SUCCESS: 'CREATE TRANSFER SUCCESS',
  CREATE_TRANSFER_FAILURE: 'CREATE TRANSFER FAILURE',
  TRANSFER_STATUS_CHANGE_SUCCESS: 'TRANSFER STATUS CHANGE SUCCESS',
  TRANSFER_STATUS_CHANGE_FAILURE: 'TRANSFER STATUS CHANGE FAILURE',
  TRANSFER_DETAIL_CREATE_ITEM_SUCCESS: 'TRANSFER DETAIL CREATE ITEM SUCCESS',
  TRANSFER_DETAIL_CREATE_ITEM_FAILURE: 'TRANSFER DETAIL CREATE ITEM FAILURE',
  TRANSFER_DETAIL_UPDATE_ITEM_QUANTITY_SUCCESS: 'TRANSFER DETAIL UPDATE ITEM QUANTITY SUCCESS',
  TRANSFER_DETAIL_UPDATE_ITEM_QUANTITY_FAILURE: 'TRANSFER DETAIL UPDATE ITEM QUANTITY FAILURE',
  TRANSFER_DETAIL_ITEM_FROM_ORDER_SUCCESS: 'TRANSFER DETAIL ITEM FROM ORDER SUCCESS',
  TRANSFER_DETAIL_ITEM_FROM_ORDER_FAILURE: 'TRANSFER DETAIL ITEM FROM ORDER FAILURE',
  CUSTOM: 'CUSTOM', // special type, requires customNotificationObject prop
};
