import * as actionTypes from './user-checks.actionTypes';

// Fetch user IP
export function userChecksUserIPCheckInitiateRequest() {
  return {
    type: actionTypes.USER_CHECKS_USER_IP_ADDRESS_CHECK_REQUEST,
  };
}

export function userChecksUserIPCheckInitiateResponse(
  userIPAddress, generalTypeIpAddresses, shopTypeIpAddresses
) {
  return {
    type: actionTypes.USER_CHECKS_USER_IP_ADDRESS_CHECK_RESPONSE,
    payload: { userIPAddress, generalTypeIpAddresses, shopTypeIpAddresses }
  };
}

export function userChecksUserIPCheckInitiateFailure(error) {
  return {
    type: actionTypes.USER_CHECKS_USER_IP_ADDRESS_CHECK_FAILURE,
    payload: error
  };
}
