/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import _ from 'lodash'
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom'
import { useIsFirstRender } from 'hooks/useIsFirstRender'
import { prefixes } from 'routes';

// RENDERLESS COMPONENT
const AppWideOrdersContextEffects = props => {

  const {
    // REDUX and CONSTANTS
    defaultOrdersState,
    stavObjednavky,
    redOrderStateFoldersCounts,
    // FUNCTIONS
    getOrdersStateRoute,
    getOrdersStatesObjects,
    onLoadSetProfileState,
    retrieveColorFromStavObejdnavkyItem,
    // STATE
    ordersStateOnLoad,
    ordersStatesItems,
    setOrdersStatesSidebarItems,
    ordersStateIdFromRoute,
    activeOrderState,
    setActiveOrderState,
    setOrdersStatesOptions,
    setOrdersStatesItems,
    setOrdersStatesIds,
    // REFS
    orderStatesListOptionsRef,
    stavObjednavkyRef,
    orderStatesListRef,
    orderStatesListIdsRef,
    // FACADES
    fetchStavObjednavky,
    fetchTransportationTypes,
    fetchTransportationTypesValidDraft,

    setOrdersListFilterToSessionStorage,
    sessionOrdersFiltersTypes,
  } = props;

  const currentUrl = useLocation();
  const history = useHistory();
  const isFirstRender = useIsFirstRender();

  useEffect(() => {
    onLoadSetProfileState();

    fetchStavObjednavky();
    fetchTransportationTypes();
    fetchTransportationTypesValidDraft();

    orderStatesListOptionsRef.current.push(
      {
        name: 'Všechny',
        value: null,
        category: 'general'
      },
      {
        name: 'Žádný stav',
        // matches backend
        value: 'NULL',
        category: 'general'
      }
    );
    setOrdersStatesOptions(orderStatesListOptionsRef.current);

  }, []);

  useEffect(() => {
    if (stavObjednavky) {
      handleStavObjednavky();
      // Originally set in AppWideOrdersContextContainer.js for orders states sidebar items in TheSidebar.js
      setOrdersStatesItems(orderStatesListOptionsRef.current);
      setOrdersStatesIds(orderStatesListIdsRef.current);
    }

  }, [stavObjednavky]);

  useEffect(() => {
    if (ordersStateOnLoad) {
      // REDIRECT to the preselected ORDERS STATE, only if coming to website
      if (currentUrl.pathname === prefixes.orders) {
        history.push(getOrdersStateRoute(ordersStateOnLoad));
      }
    } else {
      return;
    }

  }, [ordersStateOnLoad]);

  // FORM ORDERS STATES FOR SIDEBAR MENU
  useEffect(() => {
    if (ordersStatesItems && redOrderStateFoldersCounts) {
      const transformedItems = getOrdersStatesObjects();
      setOrdersStatesSidebarItems(transformedItems);
    }
  }, [ordersStatesItems, redOrderStateFoldersCounts]);

  // IF ORDERS STATE CHOSEN FROM SIDEBAR MENU or PROFILE SETUP PRESELECTED STATE,
  // SET THE ORDERS STATE ID FROM ROUTE
  useEffect(() => {
    if (ordersStateIdFromRoute && ordersStatesItems) {
      const matchedStateById = _.find(
        ordersStatesItems, { value: ordersStateIdFromRoute}
      );

      if (matchedStateById && (matchedStateById !== activeOrderState.value)) {
        // After this state is set, data refetches in TheLayout.js
        if (matchedStateById.value !== activeOrderState.value) {
          // Set active orders state, unless it already contains the given state
          setActiveOrderState(matchedStateById);
        }
        setOrdersListFilterToSessionStorage(sessionOrdersFiltersTypes['TABLE_PAGE'], 0, true);
      }
    }
  }, [ordersStateIdFromRoute, ordersStatesItems]);

  // IF 'VŠECHNY' ORDERS STATES FROM SIDEBAR SELECTED, WHICH ENDS WITH A ROUTE 'objednavky', SET THE DEFAULT STATE OBJECT
  useEffect(() => {
    // ordersStateIdFromRoute set to null in TheLayout.js
    if (!isFirstRender && !ordersStateIdFromRoute) {
      setActiveOrderState(defaultOrdersState);
      setOrdersListFilterToSessionStorage(sessionOrdersFiltersTypes['TABLE_PAGE'], 0, true);
    }
  }, [ordersStateIdFromRoute]);

  const handleStavObjednavky = () => {
    stavObjednavkyRef.current = _.sortBy(stavObjednavky, 'ord');

    for (const item of stavObjednavkyRef.current) {
      if (!item.hidden) {
        orderStatesListRef.current[item.abraId] = item.name;

        orderStatesListOptionsRef.current.push({
          name:  item.name,
          value: item.abraId,
          folderColor: item.appData ? retrieveColorFromStavObejdnavkyItem(item.appData) : null,
          showBadge: item.abraId === '4SQ0000101' ||
                     item.abraId === 'N900000101' ||
                     item.abraId === 'C900000101' ||
                     item.abraId === '3900000101' ||
                     item.abraId === '3SO5000101' ||
                     item.abraId === '4900000101' ||
                     item.abraId === '4SO5000101' ||
                     item.abraId === '5SJ6000101' ||
                     item.abraId === 'E900000101' ||
                     item.abraId === '3S17000101' ||
                     item.abraId === '7S17000101'
        });

        orderStatesListIdsRef.current[item.abraId] = item.id;
      }
    }
  }

  return (null)
}

export default React.memo(AppWideOrdersContextEffects)
