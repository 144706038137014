import { useDispatch } from 'react-redux'
import { useCallback } from 'react'

import * as actions from './store-cards.actions'
import { useStoreCardsSelectors } from './store-cards.selectors'

export function useStoreCardsFacade() {
  const dispatch = useDispatch();
  const selectors = useStoreCardsSelectors();

  const {
    selectResMessage: storeCardsResMessage,
    selectBottleTypesList: bottleTypesList,
    selectStoreCardsForFBFeedTableData: storeCardsForFBFeedTableData,

    selectModalListStoreCardsPageData: modalListStoreCardsPageData,
    selectModalListStoreCardsPageDraw: modalListStoreCardsPageDraw,
    selectModalListStoreCardsPagePage: modalListStoreCardsCurrentPage,
    selectModalListStoreCardsTotal: modalListStoreCardsTotal,
    selectModalListStoreCardsPageLimit: modalListStoreCardsLimit,

    selectSectionListStoreCardsPageData: sectionListStoreCardsPageData,
    selectSectionListStoreCardsPageDraw: sectionListStoreCardsPageDraw,
    selectSectionListStoreCardsPagePage: sectionListStoreCardsCurrentPage,
    selectSectionListStoreCardsTotal: sectionListStoreCardsTotal,
    selectSectionListStoreCardsPageLimit: sectionListStoreCardsLimit,
    selectSectionListStoreCardsRelatedEans: sectionListStoreCardsRelatedEans,
    selectSectionListStoreCardsRelatedBottleTypes: sectionListStoreCardsRelatedBottleTypes,
    selectSectionListEansHelperDraw: eansHelperDraw,
    selectSectionListBottleTypesHelperDraw: bottleTypesHelperDraw,

    selectProductInOrdersPageData: productInOrdersPageData,
    selectProductInOrdersPageDraw: productInOrdersPageDraw,
    selectProductInOrdersPagePage: productInOrdersPagePage,
    selectProductInOrdersPageLimit: productInOrdersPageLimit,
    selectProductInOrdersTotal: productInOrdersTotal,

    selectStoreCardsEditableCTPageData: storeCardsEditableCTPageData,
    selectStoreCardsEditableCTPageDraw: storeCardsEditableCTPageDraw,
    selectStoreCardsEditableCTPagePage: storeCardsEditableCTPagePage,
    selectStoreCardsEditableCTPageLimit: storeCardsEditableCTPageLimit,
    selectStoreCardsEditableCTTotal: storeCardsEditableCTTotal,
    selectStoreCardsEditableCTRelatedEans: storeCardsEditableCTRelatedEans,
    selectStoreCardsEditableCTRelatedBottleTypes: storeCardsEditableCTRelatedBottleTypes,
    selectStoreCardsEditableCTEansHelperDraw: storeCardsEditableCTEansHelperDraw,
    selectStoreCardsEditableCTBottleTypesHelperDraw: storeCardsEditableCTBottleTypesHelperDraw,
  } = selectors;

  const fetchModalListTableStoreCardsPageData = useCallback(
    (
      draw=1,
      page=0,
      limit=15,
      search=null,
    ) =>
      dispatch(actions.storeCardsListPageRequest(draw, page, limit, search)),
    [dispatch]
  );

  const fetchSectionListTableStoreCardsPageData = useCallback(
    (draw = 1, page = 0, limit = 50, search = null, nullQuantity = null, shop = null, sort = null, order = null) =>
      dispatch(
        actions.storeCardsSectionListPageRequest(
          draw,
          page,
          limit,
          search,
          nullQuantity,
          shop,
          sort,
          order,
        )
      ),
    [dispatch]
  );

  // Possible table source ids: 'section_table' or 'editable_consumption_tax'
  const fetchRelatedStoreCardsEans = useCallback(
    (storeCardsIdsArr, tableSoureId='section_table') =>
      dispatch(actions.storeCardsEansRequest(storeCardsIdsArr, tableSoureId)),
    [dispatch]
  );

  const bulkChangeStoreCardsExportNullQuantity = useCallback(
    (
      storeCardsIds, nullifyQuantity
    ) =>
      dispatch(actions.storeCardsBulkChangeExportNullQuantityRequest(storeCardsIds, nullifyQuantity)),
    [dispatch]
  );

  const resetStoreCardsResponseMessage = useCallback(
    () =>
      dispatch(actions.storeCardsResetResponseMessage()),
    [dispatch]
  );

  // Possible table source ids: 'section_table' or 'editable_consumption_tax'
  const fetchRelatedStoreCardsBottleTypes = useCallback(
    (bottleTypesIdsByStoreCardsIds, tableSoureId='section_table') =>
      dispatch(actions.storeCardsBottleTypesBySCRequest(bottleTypesIdsByStoreCardsIds, tableSoureId)),
    [dispatch]
  );

  const fetchProductInOrdersList = useCallback(
    (draw = 1, page = 0, limit, stavObjednavkyIds, search) =>
      dispatch(actions.storeCardsProductInOrdersListRequest(draw, page, limit | 50, stavObjednavkyIds, search)),
    [dispatch]
  );

  const fetchStoreCardsEditableConsumptionTaxListPageData = useCallback(
    (
      draw=1,
      page=0,
      limit=50,
      search=null,
    ) =>
      dispatch(actions.storeCardsEditableConsumptionTaxListPageRequest(draw, page, limit, search)),
    [dispatch]
  );

  const fetchBottleTypesList = useCallback(
    () =>
      dispatch(actions.storeCardsBottleTypesListRequest()),
    [dispatch]
  );

  const updateStoreCard = useCallback(
    (updatedStoreCard) =>
      dispatch(actions.storeCardsEditStoreCardRequest(updatedStoreCard)),
    [dispatch]
  );

  const deleteEan = useCallback(
    (storeCardId, ean, userUid, ident) =>
      dispatch(actions.storeCardsDeleteEanRequest(storeCardId, ean, userUid, ident)),
    [dispatch]
  );

  const updateMultipleStoreCards = useCallback(
    (updatedStoreCards) =>
      dispatch(actions.storeCardsEditMultipleStoreCardsRequest(updatedStoreCards)),
    [dispatch]
  );

  const fetchStoreCardsForFBFeed = useCallback(
    (shop) =>
      dispatch(actions.storeCardsForFBFeedRequest(shop)),
    [dispatch]
  );

  return {
    storeCardsResMessage,
    bottleTypesList,
    storeCardsForFBFeedTableData,

    modalListStoreCardsPageData,
    modalListStoreCardsPageDraw,
    modalListStoreCardsCurrentPage,
    modalListStoreCardsTotal,
    modalListStoreCardsLimit,

    sectionListStoreCardsPageData,
    sectionListStoreCardsPageDraw,
    sectionListStoreCardsCurrentPage,
    sectionListStoreCardsTotal,
    sectionListStoreCardsLimit,
    sectionListStoreCardsRelatedEans,
    sectionListStoreCardsRelatedBottleTypes,
    eansHelperDraw,
    bottleTypesHelperDraw,

    productInOrdersPageData,
    productInOrdersPageDraw,
    productInOrdersPagePage,
    productInOrdersPageLimit,
    productInOrdersTotal,

    storeCardsEditableCTPageData,
    storeCardsEditableCTPageDraw,
    storeCardsEditableCTPagePage,
    storeCardsEditableCTPageLimit,
    storeCardsEditableCTTotal,
    storeCardsEditableCTRelatedEans,
    storeCardsEditableCTRelatedBottleTypes,
    storeCardsEditableCTEansHelperDraw,
    storeCardsEditableCTBottleTypesHelperDraw,

    fetchModalListTableStoreCardsPageData,
    fetchSectionListTableStoreCardsPageData,
    fetchRelatedStoreCardsEans,
    bulkChangeStoreCardsExportNullQuantity,
    resetStoreCardsResponseMessage,
    fetchRelatedStoreCardsBottleTypes,
    fetchProductInOrdersList,
    fetchStoreCardsEditableConsumptionTaxListPageData,
    fetchBottleTypesList,
    updateStoreCard,
    updateMultipleStoreCards,
    deleteEan,
    fetchStoreCardsForFBFeed,
  }
}
