import React from 'react'
import { prefixes } from '../../routes'

const TransfersLayout = React.lazy(() => import('./pages/TransfersLayout/TransfersLayout'));
const SalesRequests = React.lazy(() => import('./pages/SalesRequests/SalesRequests'));

export const { transfers } = prefixes;
export const transferDetailPrefix = 'detail';
export const transferStatusPrefix = 'status';

const transfersRoutes = [
  { path: `${transfers}/`, exact: true, name: 'Převodky', component: TransfersLayout },
  { path: `${transfers}/${transferDetailPrefix}/:id`, exact: true, name: 'Detail převodky', component: TransfersLayout },
  { path: `${transfers}/${transferStatusPrefix}/:status`, exact: true, name: 'Převodky se statusem', component: TransfersLayout },
  { path: `${transfers}/nenalazene-zbozi`, exact: true, name: 'Nenalezené zboží', component: SalesRequests },
];

export default transfersRoutes;
