import React from 'react'
import { prefixes } from '../../routes'

const CollectionListsLayout = React.lazy(() => import('./pages/CollectionListsLayout/CollectionListsLayout'));
const AddedBalikobotOrdersList = React.lazy(() => import('./pages/AddedBalikobotOrdersList/AddedBalikobotOrdersList'));

export const { collectionLists } = prefixes;
export const collectionListDetailPrefix = 'detail';
export const collectionListTransPrefix = 'transport';

const collectionListsRoutes = [
  { path: `${collectionLists}/balikobot-orders`, exact: true, name: 'Otevřené objednávky', component: AddedBalikobotOrdersList },
  { path: `${collectionLists}/${collectionListTransPrefix}/:transportationId`, exact: true, name: 'Svozové listy', component: CollectionListsLayout },
  { path: `${collectionLists}/${collectionListDetailPrefix}/:id`, exact: true, name: 'Detail svozových listů', component: CollectionListsLayout },
];

export default collectionListsRoutes;
