import * as actionTypes from './layout.actionTypes';

export function layoutSetSidebarShow(sidebarShow) {
  return {
    type: actionTypes.LAYOUT_SIDEBAR_SHOW,
    payload: sidebarShow
  };
}

export function layoutSetAsideShow(asideShow) {
  return {
    type: actionTypes.LAYOUT_ASIDE_SHOW,
    payload: asideShow
  };
}

export function layoutSetDarkMode(darkMode) {
  return {
    type: actionTypes.LAYOUT_DARKMODE,
    payload: darkMode
  };
}

export function layoutSetNewBreadcrumb(crumbObject) {
  return {
    type: actionTypes.LAYOUT_SET_NEW_BREADCRUMB,
    payload: crumbObject
  };
}

export function layoutAppendBreadcrumb(crumbObject) {
  return {
    type: actionTypes.LAYOUT_APPEND_BREADCRUMB,
    payload: crumbObject
  };
}
