import { useSelector } from 'react-redux';

export function useLayoutSelectors() {
  const selectAsideShow = useSelector(state => state.layout.asideShow);
  const selectSidebarShow = useSelector(state => state.layout.sidebarShow);
  const selectDarkMode = useSelector(state => state.layout.darkMode);
  const selectBreadcrumb = useSelector(state => state.layout.breadcrumb);

  return {
    selectAsideShow,
    selectSidebarShow,
    selectDarkMode,
    selectBreadcrumb,
  }
}

