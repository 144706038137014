/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react'
import { Store as notificationStore } from 'react-notifications-component';
import { CSpinner }  from '@coreui/react'
import { useAdministrationFacade } from 'redux-store/administration/administration.facade'
import { useStoreCardsFacade } from 'redux-store/store-cards/store-cards.facade'
import { useCollectionListsFacade } from 'redux-store/collection-lists/collection-lists.facade'
import { useTransfersFacade } from 'redux-store/transfers/transfers.facade'
import { useOrdersFacade } from 'redux-store/orders/orders.facade'
import { useUserFacade } from 'redux-store/user/user.facade'
import { useAuthFacade } from 'redux-store/auth/auth.facade'
import { useRentFacade } from 'redux-store/rent/rent.facade'
import * as administrationActionTypes from 'redux-store/administration/administration.actionTypes'
import { useIsFirstRender } from 'hooks/useIsFirstRender'
import ContentContext from 'contexts/context-registrations/ContentContext'
import CrudNotification from 'libs/crud-notifications'
import * as ordersActionTypes from 'redux-store/orders/orders.actionTypes'
import * as transfersActionsTypes from 'redux-store/transfers/transfers.actionTypes'
import * as CLactionTypes from 'redux-store/collection-lists/collection-lists.actionTypes'
import * as rentActionTypes from 'redux-store/rent/rent.actionTypes'
import { openBlobPdf } from 'utils/pdf.functions'
import { useRouteState } from 'hooks/useRouteState'
import { useIsAdmin } from 'hooks/useIsAdmin'
import _ from 'lodash'

// Store cards are needed accross the content because the store cards list table is needed in other sections than Store cards section,
// it is needed also in Transfers section in transfer detail, when clicked
// on 'Přídat produkt', modal with store cards table pops up. The store cards take a long time to fetch, that's why they are being fetched on a site's load already.

// If a user is in the Store cards section, the table has a pageSize of 50, if it goes away
const TheContentContextContainer = props => {

  const collectionListsFacade = useCollectionListsFacade();
  const administrationFacade = useAdministrationFacade();
  const storeCardsFacade = useStoreCardsFacade();
  const transfersFacade = useTransfersFacade();
  const ordersFacade = useOrdersFacade();
  const userFacade = useUserFacade();
  const authFacade = useAuthFacade();
  const rentFacade = useRentFacade();

  const { route: contentRoute } = useRouteState();

  const isFirstRender = useIsFirstRender();
  const { isAdmin } = useIsAdmin();

  const { sessionId } = authFacade;

  const {
    modalListStoreCardsPageData,
    modalListStoreCardsPageDraw,
    modalListStoreCardsCurrentPage,
    modalListStoreCardsTotal,
    modalListStoreCardsLimit,



    fetchModalListTableStoreCardsPageData,

    } = storeCardsFacade;

  const {
    transfersResMessage,
    transferWays,
    transfersGeneratedPdfDocument,
    fetchTransferWays,
    transfersResetResponseMessage,

    resetTransfersGeneratedPdf,
  } = transfersFacade;

  const { profileSetup } = userFacade;

  const {
    ordersResMessage,
    ordersGeneratedPdfDocument,
    ordersResetResponseMessage,
    resetOrdersGeneratedPdf,
  } = ordersFacade;

  const {
    airshopNotifications,
    airshopNotificationsBackendErrors,
    fetchAirshopNotifications,
    fetchAirshopNotificationsBackendErrors,
    administrationResMessage,
  } = administrationFacade;

  const {
    collectionListsResMessage,
    collectionListGeneratedPdfDocument,
    resetCollectionListsResMessage,
    resetCollectionListGeneratedPdf,
  } = collectionListsFacade;

  const {
    rentResMessage,
    resetRentResponseMessage,
  } = rentFacade

  const { current: transferWaysByIdRef } = useRef({});

  // Modal table list params
  const [modalStoreCardsListSearch, setModalStoreCardsListSearch] = useState();

  // TRANSFER DETAIL ITEM MODAL LIST TABLE (related to transfers section)
  const [modalListStoreCardsTableData, setModalListStoreCardsTableData] = useState({
    page: modalListStoreCardsCurrentPage,
    pageData: modalListStoreCardsPageData,
    pageDraw: modalListStoreCardsPageDraw,
    total: modalListStoreCardsTotal,
    limit: modalListStoreCardsLimit,
    loadingData: false,
  });

  // AIRSHOP NOTIFICATION
  const airshopNotificationDefaultVal = {
    show: false,
    itemName: 'Notifikace',
    type: 'CUSTOM',
    customNotificationObject: null,
  };

  const airshopNotificationBackendErrorDefaultVal = {
    show: false,
    itemName: 'Oznamte backend error',
    type: 'CUSTOM',
    customNotificationObject: null,
  };

  // const airshopNotificationProgressDefaultVal = {
  //   show: false,
  //   itemName: 'Notifikace průběhu',
  //   type: 'CUSTOM',
  //   customNotificationObject: null,
  // };

  const documentProcessingNotificationDefaultVal = {
    show: false,
    itemName: 'Zpracování dokumnetů se připravuje',
    type: 'CUSTOM',
    customNotificationObject: null,
  };

  const [airshopNotifications_INFO, setAirshopNotifications_INFO] = useState(airshopNotificationDefaultVal);
  const [airshopNotifications_BACKEND_ERROR, setAirshopNotifications_BACKEND_ERROR] = useState(airshopNotificationBackendErrorDefaultVal);
  // const [airshopNotifications_PROGRESS, setAirshopNotifications_PROGRESS] = useState(airshopNotificationProgressDefaultVal);
  const [
    documentProcessingNotification, setDocumentProcessingNotification
  ] = useState(documentProcessingNotificationDefaultVal);

  useEffect(() => {
    // In transfers -> transfer detail -> add úroduct -> store cards shown
    // modal, it takes forever to load, so the the fetching happens here already
    if (
      !modalListStoreCardsPageData &&
      isFirstRender &&
      profileSetup &&
      !modalListStoreCardsTableData.loadingData
    ) {
      fetchModalListTableStoreCardsPageData();
      setModalListStoreCardsTableData({
        ...modalListStoreCardsTableData,
        loadingData: true,
      });
    }

    if (!transferWays) fetchTransferWays();

    isAdmin && constantBackendErrorsNotificationFetch();
  }, []);

  // IF TRANSFER WAYS WERE FETCHED, MAPPED TO A REF BY ID
  useEffect(() => {
    if (transferWays && _.isEmpty(transferWaysByIdRef)) {
      transferWays.forEach((item) => {
        transferWaysByIdRef[item.id] = item.name;
      });
    }
  }, [transferWays]);

  // FOR ADD TRANSFER DETAIL ITEM FROM STORE CARDS TABLE IN A MODAL
  useEffect(() => {
    if (modalListStoreCardsPageData) {
      setModalListStoreCardsTableData({
        ...modalListStoreCardsTableData,
        pageData: modalListStoreCardsPageData,
        limit: modalListStoreCardsLimit,
        pageDraw: modalListStoreCardsTableData.pageDraw + 1,
        total: modalListStoreCardsTotal,
        page: modalListStoreCardsCurrentPage,
        loadingData: false,
      });
    }
  }, [modalListStoreCardsPageData]);

  const constantBackendErrorsNotificationFetch = (duration=300000) => {
    setInterval(() => {
      fetchAirshopNotificationsBackendErrors(sessionId);
    }, duration);
  }

  // AIRSHOP NOTIFICATION HANDLING
  useEffect(() => {
    handleAirshopNotificationInfo();
  }, [airshopNotifications]);

  useEffect(() => {
    handleAirshopNotificationBackendError();
  }, [airshopNotificationsBackendErrors]);

  const handleAirshopNotificationInfo = () => {
    if (airshopNotifications &&
        (airshopNotifications.length > 0) &&
        (administrationResMessage === administrationActionTypes.ADMINISTRATION_AIRSHOP_NOTIFICATIONS_RESPONSE)
       ) {
          airshopNotifications.filter(item => item.status === 'INFO').forEach((item, i) => {
            setTimeout(() => {
              setAirshopNotifications_INFO({
                ...airshopNotificationDefaultVal,
                show: true,
                customNotificationObject: {
                  title: airshopNotifications_INFO.itemName,
                  message: item.body,
                  type: 'default',
                  insert: 'top',
                  width: 500,
                  container: 'bottom-center',
                  animationIn: ['animate__animated', 'animate__fadeIn', 'airshop-notification'],
                  animationOut: ['animate__animated', 'animate__fadeOut', 'airshop-notification'],
                  dismiss: {
                    duration: 3500,
                    pauseOnHover: true,
                    onScreen: true,
                  },
                }
              });
            }, i * 4500);
          });
        }
  }

  const handleAirshopNotificationBackendError = () => {
    if (airshopNotificationsBackendErrors &&
        (airshopNotificationsBackendErrors.length > 0) &&
        (administrationResMessage === administrationActionTypes.ADMINISTRATION_AIRSHOP_NOTIFICATIONS_BACKEND_ERROR_RESPONSE)
       ) {
        airshopNotificationsBackendErrors.forEach((item, i) => {
            setTimeout(() => {
              setAirshopNotifications_BACKEND_ERROR({
                ...airshopNotificationBackendErrorDefaultVal,
                show: true,
                customNotificationObject: {
                  id: 'backend-error-notif',
                  title: airshopNotifications_BACKEND_ERROR.itemName,
                  message: (
                      <div style={{display: 'inline-block', transform: 'translate(0, -8px)'}}>
                        {item.body}<br />
                        <em>Notifikaci můžete odstranit křížkem.</em>
                      </div>
                  ),
                  type: 'danger',
                  insert: 'top',
                  width: 500,
                  container: 'bottom-center',
                  animationIn: ['animate__animated', 'animate__fadeIn', 'airshop-notification'],
                  animationOut: ['animate__animated', 'animate__fadeOut', 'airshop-notification'],
                  dismiss: {
                    duration: 0,
                    pauseOnHover: true,
                    onScreen: false,
                    showIcon: true,
                    click: false,
                  },
                }
              });
            }, i * 4500);
          });
        }
  }

  const resetAirshopNotification = () => setAirshopNotifications_INFO(airshopNotificationDefaultVal);
  const resetAirshopNotification_BACKEDN_ERROR = () => setAirshopNotifications_BACKEND_ERROR(
    airshopNotificationBackendErrorDefaultVal
  );

  const triggerAirshopNotificationsFetch = (optionalDuration=3000) => {
    setTimeout(() => {
      fetchAirshopNotifications(sessionId);
    }, optionalDuration)
  }

  // actionType = 'print' | 'pdf'
  const triggerDocumentProcessingNotification = (actionType) => {
    let typeMessage = '';

    if (actionType === 'print') {
      typeMessage = 'tisk';
    }

    if (actionType === 'pdf') {
      typeMessage = 'generování PDF';
    }

    setDocumentProcessingNotification({
      ...documentProcessingNotification,
      show: true,
      customNotificationObject: {
        id: 'doc-process-notif',
        title: documentProcessingNotification.itemName,
        message: (
          <span style={{paddingBottom: '30px'}}>
            <CSpinner className="mr-3 pt-2 pb-2" />
            <div style={{display: 'inline-block', transform: 'translate(0, -8px)'}}>
              Vyčkejte prosím, dokumenty si připravují pro <strong>{typeMessage}</strong>.
            </div>
          </span>
        ),
        // message: 'Hello',
        type: 'warning',
        insert: 'bottom',
        container: 'bottom-center',
        width: 500,
        onRemoval: () => actionType === 'print' && triggerAirshopNotificationsFetch(),
        animationIn: ['animate__animated', 'animate__fadeIn', 'document-proccessing-notification'],
        animationOut: ['animate__animated', 'animate__fadeOut', 'document-proccessing-notification'],
        dismiss: {
          duration: 0,
          pauseOnHover: true,
          onScreen: true,
          click: false,
        },
      }
    });
  }

  const resetDocumentProcessingNotification = () => {
    // manually remove since its duration is infinite
    notificationStore.removeNotification('doc-process-notif')
    setDocumentProcessingNotification(documentProcessingNotificationDefaultVal);
  }

  const onChangeModalListStoreCardsTablePage = page => {
    parametrizedModalStoreCardsListFetch(page);
  }

  const parametrizedModalStoreCardsListFetch = (page=0) => {
    fetchModalListTableStoreCardsPageData(
      modalListStoreCardsTableData.pageDraw + 1,
      page,
      modalListStoreCardsTableData.limit,
      modalStoreCardsListSearch
    );
    setModalListStoreCardsTableData({
      ...modalListStoreCardsTableData,
      loadingData: true,
    });
  }

  const handleOrdersPrintFinished = () => {
    if (ordersResMessage) {
      if (
        ordersResMessage === ordersActionTypes.ORDERS_MASS_PRINT_DOCUMENTS_RESPONSE ||
        ordersResMessage === ordersActionTypes.ORDERS_MASS_PRINT_DOCUMENTS_FAILURE ||
        ordersResMessage === ordersActionTypes.ORDERS_PRINT_DOCUMENT_RESPONSE ||
        ordersResMessage === ordersActionTypes.ORDERS_PRINT_DOCUMENT_FAILURE ||
        ordersResMessage === ordersActionTypes.ORDERS_PRINT_BALIKOBOT_RESPONSE ||
        ordersResMessage === ordersActionTypes.ORDERS_PRINT_BALIKOBOT_FAILURE ||
        ordersResMessage === ordersActionTypes.ORDERS_PRINT_THERMAL80_RESPONSE ||
        ordersResMessage === ordersActionTypes.ORDERS_PRINT_THERMAL80_FAILURE
      ) {
        resetDocumentProcessingNotification();
        triggerAirshopNotificationsFetch();
        ordersResetResponseMessage();
      }
    }
  }

  const handleOrdersGeneratePdfFinished = () => {
    if (ordersResMessage) {
      if (
        (ordersResMessage === ordersActionTypes.ORDERS_GENERATE_PDF_RESPONSE || ordersResMessage === ordersActionTypes.ORDERS_MASS_GENERATE_PDF_RESPONSE) &&
        ordersGeneratedPdfDocument
      ) {
        resetDocumentProcessingNotification();
        setTimeout(() => {
          openBlobPdf(ordersGeneratedPdfDocument);
          ordersResetResponseMessage();
          resetOrdersGeneratedPdf();
        }, 1000);
      }

      if (
        ordersResMessage === ordersActionTypes.ORDERS_GENERATE_PDF_FAILURE ||
        ordersResMessage === ordersActionTypes.ORDERS_MASS_GENERATE_PDF_FAILURE
      ) {
        resetDocumentProcessingNotification();
        ordersResetResponseMessage();
      }
    }
  }

  const handleTransfersPrintFinished = () => {
    if (transfersResMessage) {
      if (
        transfersResMessage === transfersActionsTypes.TRANSFERS_PRINT_DOCUMENT_RESPONSE
      ) {
        resetDocumentProcessingNotification();
        triggerAirshopNotificationsFetch();
        transfersResetResponseMessage();
      }
    }
  }

  const handleCollectionListsPrintFinished = () => {
    if (collectionListsResMessage) {
      if (
        collectionListsResMessage === CLactionTypes.COLLECTION_LISTS_PRINT_DOCUMENT_RESPONSE
      ) {
        resetDocumentProcessingNotification();
        triggerAirshopNotificationsFetch();
        resetCollectionListsResMessage();
      }
    }
  }

  const handleRentPrintFinished = () => {
    if (rentResMessage) {
      if (
        rentResMessage === rentActionTypes.RENT_PRINT_DOCUMENT_RESPONSE
      ) {
        resetDocumentProcessingNotification();
        triggerAirshopNotificationsFetch();
        resetRentResponseMessage();
      }
    }
  }

  const handleCollectionListsRelatedAirshopNotificationsFetch = () => {
    if (collectionListsResMessage) {
      if (
        collectionListsResMessage === CLactionTypes.COLLECTION_LISTS_COLLECTION_LIST_STATUS_CHANGE_RESPONSE
      ) {
        triggerAirshopNotificationsFetch();
        resetCollectionListsResMessage();
      }
    }
  }

  const handleTransfersGeneratePdfFinished = () => {
    if (transfersResMessage) {
      if (
        (transfersResMessage === transfersActionsTypes.TRANSFERS_GENERATE_PDF_RESPONSE) &&
        transfersGeneratedPdfDocument
      ) {
        resetDocumentProcessingNotification();
        setTimeout(() => {
          openBlobPdf(transfersGeneratedPdfDocument);
          transfersResetResponseMessage();
          resetTransfersGeneratedPdf();
        }, 1000);
      }

      if (
        transfersResMessage === transfersActionsTypes.TRANSFERS_GENERATE_PDF_FAILURE
      ) {
        resetDocumentProcessingNotification();
        transfersResetResponseMessage();
      }
    }
  }

  const handleCollectionListsGeneratePdfFinished = () => {
    if (collectionListsResMessage) {
      if (
        (collectionListsResMessage === CLactionTypes.COLLECTION_LISTS_GENERATE_PDF_RESPONSE) &&
        collectionListGeneratedPdfDocument
      ) {
        resetDocumentProcessingNotification();
        setTimeout(() => {
          openBlobPdf(collectionListGeneratedPdfDocument);
          resetCollectionListsResMessage();
          resetCollectionListGeneratedPdf();
        }, 1000);
      }

      if (
        collectionListsResMessage === CLactionTypes.COLLECTION_LISTS_GENERATE_PDF_FAILURE
      ) {
        resetDocumentProcessingNotification();
        resetCollectionListsResMessage();
      }
    }
  }

  useEffect(() => {
    if (!isFirstRender) {
      parametrizedModalStoreCardsListFetch();
    }
  }, [modalStoreCardsListSearch]);

  // ON ORDERS PRINT FINISHED,
  // HIDE DOCUMENT PROCESSING NOTIFICATION AND TRIGGER AIRSHOP NOTIFICATIONS
  useEffect(() => {
    handleOrdersPrintFinished();
  }, [ordersResMessage]);
  // ... TRASNFERS
  useEffect(() => {
    handleTransfersPrintFinished();
  }, [transfersResMessage]);
  // ... COLLECTION LISTS
  useEffect(() => {
    handleCollectionListsPrintFinished();
  }, [collectionListsResMessage]);
  // ... RENT
  useEffect(() => {
    handleRentPrintFinished();
  }, [rentResMessage]);

  // ON ORDERS GENERATE PDF FINISHED
  // HIDE DOCUMENT PROCESSING NOTIFICATION
  useEffect(() => {
    handleOrdersGeneratePdfFinished();
  }, [ordersResMessage]);
  // ... TRASNFERS
  useEffect(() => {
    handleTransfersGeneratePdfFinished();
  }, [transfersResMessage]);
  // ... COLLECTION LISTS
  useEffect(() => {
    handleCollectionListsGeneratePdfFinished();
  }, [collectionListsResMessage]);

  // Airshop notifications
  useEffect(() => {
    handleCollectionListsRelatedAirshopNotificationsFetch();
  }, [collectionListsResMessage]);

  return (
    <>
      {/* Context that is provided in TheLayout.js */}
      <ContentContext.Provider
        value={{
          modalListStoreCardsTableData,
          setModalListStoreCardsTableData,
          onChangeModalListStoreCardsTablePage,
          transferWaysByIdRef,
          modalStoreCardsListSearch,
          setModalStoreCardsListSearch,
          triggerAirshopNotificationsFetch,
          triggerDocumentProcessingNotification,
          contentRoute,
        }}
      >
        { props.children }
      </ContentContext.Provider>

      {/* Airshop notifications */}
      <CrudNotification
        show={airshopNotifications_INFO.show}
        itemName={airshopNotifications_INFO.itemName}
        type={airshopNotifications_INFO.type}
        resetNotification={() => resetAirshopNotification()}
        customNotificationObject={airshopNotifications_INFO.customNotificationObject}
      />

      {/* Document processing notification */}
      <CrudNotification
        show={documentProcessingNotification.show}
        itemName={documentProcessingNotification.itemName}
        type={documentProcessingNotification.type}
        resetNotification={() => resetDocumentProcessingNotification()}
        customNotificationObject={documentProcessingNotification.customNotificationObject}
      />

      {/* Airshop backend error notifications */}
      <CrudNotification
        show={airshopNotifications_BACKEND_ERROR.show}
        itemName={airshopNotifications_BACKEND_ERROR.itemName}
        type={airshopNotifications_BACKEND_ERROR.type}
        resetNotification={() => resetAirshopNotification_BACKEDN_ERROR()}
        customNotificationObject={airshopNotifications_BACKEND_ERROR.customNotificationObject}
      />
    </>
  )
}

export default React.memo(TheContentContextContainer)
