import * as actionTypes from './administration.actionTypes'
import * as actions from './administration.actions'
import * as authActionsProps from 'redux-store/auth/auth.actions'
import { AirshopApi, EmailApi } from '../../CraftApi.axios'
import { from, of, concat } from 'rxjs'
import { mergeMap, takeUntil, catchError, map } from 'rxjs/operators'
import { ofType } from 'redux-observable'
import { err, errMessages } from 'redux-store/utils/epics.utils'

export const importFCFileByShopEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.ADMINISTRATION_IMPORT_FC_BY_SHOP_REQUEST),
    mergeMap(action => {
      const { file, shop } = action.payload;
      return from(AirshopApi.post(`fc/fcproducts/${shop}`, file, {
        headers: {
        'content-type': 'multipart/form-data'
      }}))
      .pipe(
          map(res => res.data.status === 'OK' ? res.data : err(errMessages.NOT_OK, res)),
          map(response => actions.administrationImportFastcentrikByShopResponse(
            response,
            actionTypes.ADMINISTRATION_IMPORT_FC_BY_SHOP_RESPONSE
          )),
          catchError(error => of(actions.administrationImportFastcentrikByShopFailure(
            error,
            actionTypes.ADMINISTRATION_IMPORT_FC_BY_SHOP_FAILURE
          ))
          ),
          takeUntil(action$.ofType(
            actionTypes.ADMINISTRATION_IMPORT_FC_BY_SHOP_RESPONSE ||
              actionTypes.ADMINISTRATION_IMPORT_FC_BY_SHOP_FAILURE
          ))
      )
    })
  );

export const syncFCDaysBackByShopEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.ADMINISTRATION_SYNC_FC_DAYS_BACK_BY_SHOP_REQUEST),
    mergeMap(action => {
      const { shop, daysBackNumber } = action.payload;
      return from(AirshopApi.get(`fc/fcOrdersSync/sync${shop}DaysBack`, {
        params: {
          daysBack: daysBackNumber
      }}))
      .pipe(
          map(res => res.data.status === 'OK' ? res.data : err(errMessages.NOT_OK, res)),
          map(response => actions.administrationSyncFCDaysBackByShopResponse(
            response,
            actionTypes.ADMINISTRATION_SYNC_FC_DAYS_BACK_BY_SHOP_RESPONSE
          )),
          catchError(error => of(actions.administrationSyncFCDaysBackByShopFailure(
            error,
            actionTypes.ADMINISTRATION_SYNC_FC_DAYS_BACK_BY_SHOP_FAILURE
          ))
          ),
          takeUntil(action$.ofType(
            actionTypes.ADMINISTRATION_SYNC_FC_DAYS_BACK_BY_SHOP_RESPONSE ||
              actionTypes.ADMINISTRATION_SYNC_FC_DAYS_BACK_BY_SHOP_FAILURE
          ))
      )
    })
  );

export const fetchPrintServersEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.ADMINISTRATION_PRINT_SERVERS_REQUEST),
    mergeMap(action => from(AirshopApi.get('print/printServers'))
      .pipe(
          map(res => res.data.status === 'OK' ? res.data.items : err(errMessages.NOT_OK, res)),
          map(response => actions.administrationPrintServersResponse(response)),
          catchError(error => of(actions.administrationPrintServersFailure(error))),
          takeUntil(action$.ofType(
            actionTypes.ADMINISTRATION_PRINT_SERVERS_RESPONSE ||
              actionTypes.ADMINISTRATION_PRINT_SERVERS_FAILURE
          ))
      )
    )
  );

export const fetchPrintersListEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.ADMINISTRATION_PRINTERS_LIST_REQUEST),
    mergeMap(action => {
      const { printServerId, page, draw, limit } = action.payload;

      return from(AirshopApi.get('print/printers/printServersId', {
        params: {
          printServersId: printServerId,
          page,
          draw,
          limit,
          order: 'date_created',
        }
      }))
      .pipe(
          map(res => res.data.status === 'OK' ? res.data : err(errMessages.NOT_OK, res)),
          map(response => {
            const activePrinters = response.items.filter(printer => printer.printQueue);
            const activePrintersTotal = activePrinters.length;
            return actions.administrationPrintersListResponse(
              activePrinters, activePrintersTotal
          )}),
          catchError(error => of(actions.administrationPrintersListFailure(error))),
          takeUntil(action$.ofType(
            actionTypes.ADMINISTRATION_PRINTERS_LIST_RESPONSE ||
              actionTypes.ADMINISTRATION_PRINTERS_LIST_FAILURE
          ))
      )
    })
  );

export const fetchPrinterProfileEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.ADMINISTRATION_PRINTER_PROFILE_REQUEST),
    mergeMap(action => {
      const { printerId } = action.payload;

      return from(AirshopApi.get('print/printerProfile/printersId', {
        params: {
          printersId: printerId,
        }
      }))
      .pipe(
          map(res => res.data.status === 'OK' ? res.data.items : err(errMessages.NOT_OK, res)),
          map(response => actions.administrationPrinterProfileResponse(
            printerId, !!response.length ? response[0] : {}
          )),
          catchError(error => of(actions.administrationPrinterProfileFailure(error))),
          takeUntil(action$.ofType(
            actionTypes.ADMINISTRATION_PRINTER_PROFILE_STOP
          ))
      )
    })
  );

export const updateUserThermalPrinterEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.ADMINISTRATION_UPDATE_USER_THERMAL_PRINTER_REQUEST),
    mergeMap(action => {
      const { printerId, userUid } = action.payload;
      return from(AirshopApi.get(`print/partners/updateThermalPrinter/${printerId}`, {
        params: { uid: userUid }
      }))
      .pipe(
          map(res => res.data.status === 'OK' ? res : err(errMessages.NOT_OK, res)),
          mergeMap(response => concat(
            of(actions.administrationUpdateUserThermalPrinterResponse(
              response,
              actionTypes.ADMINISTRATION_UPDATE_USER_THERMAL_PRINTER_RESPONSE
            )),
            of({type: authActionsProps.AUTH_IDM_REQUEST})
          )),
          catchError(error => of(actions.administrationUpdateUserThermalPrinterFailure(
            error,
            actionTypes.ADMINISTRATION_UPDATE_USER_THERMAL_PRINTER_FAILURE
          ))),
          takeUntil(action$.ofType(
            authActionsProps.AUTH_IDM_REQUEST ||
              actionTypes.ADMINISTRATION_UPDATE_USER_THERMAL_PRINTER_FAILURE
          ))
      )
    })
  );

export const updateUserA4PrinterEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.ADMINISTRATION_UPDATE_USER_A4_PRINTER_REQUEST),
    mergeMap(action => {
      const { printerId, userUid } = action.payload;
      return from(AirshopApi.get(`print/partners/updateA4Printer/${printerId}`, {
        params: { uid: userUid }
      }))
      .pipe(
          map(res => res.data.status === 'OK' ? res : err(errMessages.NOT_OK, res)),
          mergeMap(response => concat(
            of(actions.administrationUpdateUserA4PrinterResponse(
              response,
              actionTypes.ADMINISTRATION_UPDATE_USER_A4_PRINTER_RESPONSE
            )),
            of({type: authActionsProps.AUTH_IDM_REQUEST})
          )),
          catchError(error => of(actions.administrationUpdateUserA4PrinterFailure(
            error,
            actionTypes.ADMINISTRATION_UPDATE_USER_A4_PRINTER_FAILURE
          ))),
          takeUntil(action$.ofType(
            authActionsProps.AUTH_IDM_REQUEST ||
              actionTypes.ADMINISTRATION_UPDATE_USER_A4_PRINTER_FAILURE
          ))
      )
    })
  );

export const fetchAirshopNotificationsEpic = (action$) =>
  action$.pipe(
    ofType(actionTypes.ADMINISTRATION_AIRSHOP_NOTIFICATIONS_REQUEST),
    mergeMap((action) => from(AirshopApi.get('airshopNotifications/identUnread', {
      params: { ident: action.payload.ident }
    }))
      .pipe(
          map(res => res.data.status === 'OK' ? res.data.items : err(errMessages.NOT_OK, res)),
          map(notifications => actions.administrationAirshopNotificationsResponse(
            notifications,
            actionTypes.ADMINISTRATION_AIRSHOP_NOTIFICATIONS_RESPONSE
          )),
          catchError(error => of(actions.administrationAirshopNotificationsFailure(
            error,
            actionTypes.ADMINISTRATION_AIRSHOP_NOTIFICATIONS_FAILURE
          ))
          ),
          takeUntil(action$.ofType(
            actionTypes.ADMINISTRATION_AIRSHOP_NOTIFICATIONS_RESPONSE ||
              actionTypes.ADMINISTRATION_AIRSHOP_NOTIFICATIONS_FAILURE
          ))
      )
    )
  );

export const fetchIPAddressesListPageEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.ADMINISTRATION_IP_ADDRESSES_LIST_REQUEST),
    mergeMap(action => {
      const {
        draw,
        page,
        limit,
      } = action.payload;

      const optionalParams = {};
      Object.entries(action.payload).forEach(item => {
        const [key, value] = item;
        if (!(value === draw || value === page || value === limit)) {
          optionalParams[key] = value;
        }
      });

      return from(AirshopApi.get('accessList', {
        params: {
          draw,
          page,
          limit,
          order: 'date_created',
          sort: 'ASC',
          ...optionalParams,
        }
      }))
      .pipe(
          map(response => response.data.status === 'OK' ? response.data : err(errMessages.NOT_OK, response)),

          map(tablePageData => actions.administrationIPAddressesListPageResponse(
            {items: tablePageData.items, total: tablePageData.total}
          )),
          catchError(error => of(actions.administrationIPAddressesListPageFailure(error))
          ),
          takeUntil(action$.ofType(
            actionTypes.ADMINISTRATION_IP_ADDRESSES_LIST_RESPONSE ||
              actionTypes.ADMINISTRATION_IP_ADDRESSES_LIST_FAILURE
          ))
      )
    })
  );

export const createNewIPAddressEpic = (action$) =>
  action$.pipe(
    ofType(actionTypes.ADMINISTRATION_CREATE_IP_ADDRESS_REQUEST),
    mergeMap((action) => from(AirshopApi.post('accessList', action.payload))
      .pipe(
          map(res => res.data.status === 'OK' ? res.data : err(errMessages.NOT_OK, res)),
          map(response => actions.administrationCreateIPAddressResponse(
            response,
            actionTypes.ADMINISTRATION_CREATE_IP_ADDRESS_RESPONSE
          )),
          catchError(error => of(actions.administrationCreateIPAddressFailure(
            error,
            actionTypes.ADMINISTRATION_CREATE_IP_ADDRESS_FAILURE
          ))
          ),
          takeUntil(action$.ofType(
            actionTypes.ADMINISTRATION_CREATE_IP_ADDRESS_RESPONSE ||
              actionTypes.ADMINISTRATION_CREATE_IP_ADDRESS_FAILURE
          ))
      )
    )
  );

export const updateIPAddressEpic = (action$) =>
  action$.pipe(
    ofType(actionTypes.ADMINISTRATION_UPDATE_IP_ADDRESS_REQUEST),
    mergeMap((action) => {
      const { ipAddressId, updatedIPAddressObject, refetchList } = action.payload;
      return from(AirshopApi.put('accessList', updatedIPAddressObject, {
        params: { accessListId: ipAddressId }
      }))
      .pipe(
          map(res => res.data.status === 'OK' ? res.data : err(errMessages.NOT_OK, res)),
          map(response => actions.administrationUpdateIPAddressResponse(
            response,
            refetchList ?
            actionTypes.ADMINISTRATION_UPDATE_IP_ADDRESS_RESPONSE :
            actionTypes.ADMINISTRATION_UPDATE_IP_ADDRESS_RESPONSE_NR
          )),
          catchError(error => of(actions.administrationUpdateIPAddressFailure(
            error,
            actionTypes.ADMINISTRATION_UPDATE_IP_ADDRESS_FAILURE
          ))
          ),
          takeUntil(action$.ofType(
            actionTypes.ADMINISTRATION_UPDATE_IP_ADDRESS_RESPONSE ||
            actionTypes.ADMINISTRATION_UPDATE_IP_ADDRESS_FAILURE
          ))
      )
    })
  );

export const deleteIPAddressEpic = (action$) =>
  action$.pipe(
    ofType(actionTypes.ADMINISTRATION_DELETE_IP_ADDRESS_REQUEST),
    mergeMap((action) => {
      const { ipAddressId } = action.payload;
      return from(AirshopApi.delete('accessList', {
        params: { accessListId: ipAddressId }
      }))
      .pipe(
          map(res => res.data.status === 'OK' ? res.data : err(errMessages.NOT_OK, res)),
          map(response => actions.administrationDeleteIPAddresResponse(
            response,
            actionTypes.ADMINISTRATION_DELETE_IP_ADDRESS_RESPONSE
          )),
          catchError(error => of(actions.administrationDeleteIPAddresFailure(
            error,
            actionTypes.ADMINISTRATION_DELETE_IP_ADDRESS_FAILURE
          ))
          ),
          takeUntil(action$.ofType(
            actionTypes.ADMINISTRATION_DELETE_IP_ADDRESS_RESPONSE ||
            actionTypes.ADMINISTRATION_DELETE_IP_ADDRESS_FAILURE
          ))
      )
    })
  );

export const fetchPartnersListEpic = (action$) =>
  action$.pipe(
    ofType(actionTypes.ADMINISTRATION_PARTNERS_LIST_REQUEST),
    mergeMap((action) => from(AirshopApi.get('partners'))
      .pipe(
          // First getting the total because in the future many users may exist
          // and the first general fetch might not get all of them
          map(res => res.data.status === 'OK' ? res.data.total : err(errMessages.NOT_OK, res)),
          mergeMap(total => from(AirshopApi.get('partners', {
            params: { limit: total, order: 'id' }
          })).pipe(
            map(res => res.data.status === 'OK' ? res.data.items : err(errMessages.NOT_OK, res))
          )),
          map(response => actions.administrationPartnersListResponse(response)),
          catchError(error => of(actions.administrationPartnersListFailure(error))),
          takeUntil(action$.ofType(
            actionTypes.ADMINISTRATION_PARTNERS_LIST_RESPONSE ||
            actionTypes.ADMINISTRATION_PARTNERS_LIST_FAILURE
          ))
      )
    )
  );

export const fetchAbraCommandsListEpic = (action$) =>
  action$.pipe(
    ofType(actionTypes.ADMINISTRATION_ABRA_COMMANDS_LIST_REQUEST),
    mergeMap((action) => {
      const { draw, page, limit, search } = action.payload;
      return from(AirshopApi.get('abraCommands', { params: {
        draw,
        page,
        limit,
        search,
        order: 'date_created',
        sort: 'DESC',
       }}))
      .pipe(
          map(res => res.data.status === 'OK' ? res.data.items : err(errMessages.NOT_OK, res)),
          map((response) => actions.administrationAbraCommandsListResponse(response)),
          catchError(error => of(actions.administrationAbraCommandsListFailure(error))),
          takeUntil(action$.ofType(
            actionTypes.ADMINISTRATION_ABRA_COMMANDS_LIST_RESPONSE ||
            actionTypes.ADMINISTRATION_ABRA_COMMANDS_LIST_FAILURE
          ))
      )
    })
  );

export const updateUserThermalPrinterByAdminEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.ADMINISTRATION_UPDATE_USER_THERMAL_PRINTER_BY_ADMIN_REQUEST),
    mergeMap(action => {
      const { printerId, userUid } = action.payload;
      return from(AirshopApi.get(`print/partners/admin/updateThermalPrinter/${printerId}`, {
        params: { uid: userUid }
      }))
      .pipe(
          map(res => res.data.status === 'OK' ? res : err(errMessages.NOT_OK, res)),
          map(response => actions.administrationUpdateUserThermalPrinterByAdminResponse(
              response,
              actionTypes.ADMINISTRATION_UPDATE_USER_THERMAL_PRINTER_BY_ADMIN_RESPONSE
          )),
          catchError(error => of(actions.administrationUpdateUserThermalPrinterByAdminFailure(
            error,
            actionTypes.ADMINISTRATION_UPDATE_USER_THERMAL_PRINTER_BY_ADMIN_FAILURE
          ))),
          takeUntil(action$.ofType(
            actionTypes.ADMINISTRATION_UPDATE_USER_THERMAL_PRINTER_BY_ADMIN_RESPONSE ||
            actionTypes.ADMINISTRATION_UPDATE_USER_THERMAL_PRINTER_BY_ADMIN_FAILURE
          ))
      )
    })
  );

export const updateUserA4PrinterByAdminEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.ADMINISTRATION_UPDATE_USER_A4_PRINTER_BY_ADMIN_REQUEST),
    mergeMap(action => {
      const { printerId, userUid } = action.payload;
      return from(AirshopApi.get(`print/partners/admin/updateA4Printer/${printerId}`, {
        params: { uid: userUid }
      }))
      .pipe(
          map(res => res.data.status === 'OK' ? res : err(errMessages.NOT_OK, res)),
          map(response => actions.administrationUpdateUserA4PrinterByAdminResponse(
            response,
            actionTypes.ADMINISTRATION_UPDATE_USER_A4_PRINTER_BY_ADMIN_RESPONSE
          )),
          catchError(error => of(actions.administrationUpdateUserA4PrinterByAdminFailure(
            error,
            actionTypes.ADMINISTRATION_UPDATE_USER_A4_PRINTER_BY_ADMIN_FAILURE
          ))),
          takeUntil(action$.ofType(
            actionTypes.ADMINISTRATION_UPDATE_USER_A4_PRINTER_BY_ADMIN_RESPONSE ||
            actionTypes.ADMINISTRATION_UPDATE_USER_A4_PRINTER_BY_ADMIN_FAILURE
          ))
      )
    })
  );

export const fetchAirshopNotificationsBackendErrorEpic = (action$) =>
  action$.pipe(
    ofType(actionTypes.ADMINISTRATION_AIRSHOP_NOTIFICATIONS_BACKEND_ERROR_REQUEST),
    mergeMap((action) => from(AirshopApi.get('airshopNotifications/typeUnread', {
      params: { ident: action.payload.ident, type: 'BACKEND_ERROR' }
    }))
      .pipe(
          map(res => res.data.status === 'OK' ? res.data.items : err(errMessages.NOT_OK, res)),
          map(notifications => actions.administrationAirshopNotificationsBackendErrorResponse(
            notifications,
            actionTypes.ADMINISTRATION_AIRSHOP_NOTIFICATIONS_BACKEND_ERROR_RESPONSE
          )),
          catchError(error => of(actions.administrationAirshopNotificationsBackendErrorFailure(
            error,
            actionTypes.ADMINISTRATION_AIRSHOP_NOTIFICATIONS_BACKEND_ERROR_FAILURE
          ))
          ),
          takeUntil(action$.ofType(
            actionTypes.ADMINISTRATION_AIRSHOP_NOTIFICATIONS_BACKEND_ERROR_RESPONSE ||
            actionTypes.ADMINISTRATION_AIRSHOP_NOTIFICATIONS_BACKEND_ERROR_FAILURE
          ))
      )
    )
  );

  export const fetchTemplatesPageEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.ADMINISTRATION_TEMPLATES_REQUEST),
    mergeMap(action => {
      const { draw, search, limit, page, type, system } = action.payload;
      return from(EmailApi.get('templates', {
        params: { draw, search, limit, page, order: 'id', sort: 'ASC', type, system, }
      }))
      .pipe(
        map(response => response.data.status === 'OK' ? {
            items: response.data.items,
            total: response.data.total,
          } : err(errMessages.NOT_OK, response)
        ),
        map(tableData => actions.administrationTemplatesPageResponse(tableData)),
        catchError(error => of(actions.administrationTemplatesPageFailure(error))
        ),
        takeUntil(action$.ofType(
          actionTypes.ADMINISTRATION_TEMPLATES_RESPONSE ||
          actionTypes.ADMINISTRATION_TEMPLATES_FAILURE
        ))
      )
    })
  );

export const createNewTemplateEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.ADMINISTRATION_CREATE_TEMPLATE_REQUEST),
    mergeMap(action => from(EmailApi.post(
      `templates/${action.payload.systemUid}`,
      action.payload
    ))
      .pipe(
        map(response => response.status === 200 ? response.data : null),
        map(response => actions.administrationCreateTemplateResponse(
          response,
          actionTypes.ADMINISTRATION_CREATE_TEMPLATE_RESPONSE
        )),
        catchError(error => of(actions.administrationCreateTemplateFailure(
            error,
            actionTypes.ADMINISTRATION_CREATE_TEMPLATE_FAILURE
          ))
        ),
        takeUntil(action$.ofType(
          actionTypes.ADMINISTRATION_CREATE_TEMPLATE_RESPONSE ||
          actionTypes.ADMINISTRATION_CREATE_TEMPLATE_FAILURE
        ))
      )
    )
  );

export const editTemplateEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.ADMINISTRATION_EDIT_TEMPLATE_REQUEST),
    mergeMap(action => from(EmailApi.put(
      `templates/${action.payload.id}`,
      action.payload
    ))
      .pipe(
        map(response => response.status === 200 ? response.data : null),
        map(response => actions.administrationEditTemplateResponse(
          response,
          actionTypes.ADMINISTRATION_EDIT_TEMPLATE_RESPONSE
        )),
        catchError(error => of(actions.administrationEditTemplateFailure(
            error,
            actionTypes.ADMINISTRATION_EDIT_TEMPLATE_FAILURE
          ))
        ),
        takeUntil(action$.ofType(
          actionTypes.ADMINISTRATION_EDIT_TEMPLATE_RESPONSE ||
          actionTypes.ADMINISTRATION_EDIT_TEMPLATE_FAILURE
        ))
      )
    )
  );

export const deleteTemplateEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.ADMINISTRATION_DELETE_TEMPLATE_REQUEST),
    mergeMap(action => from(EmailApi.delete(`templates/${action.payload}`))
      .pipe(
        map(response => response.status === 200 ? response.data : null),
        map(response => actions.administrationDeleteTemplateResponse(
          response,
          actionTypes.ADMINISTRATION_DELETE_TEMPLATE_RESPONSE
        )),
        catchError(error => of(actions.administrationDeleteTemplateFailure(
            error,
            actionTypes.ADMINISTRATION_DELETE_TEMPLATE_FAILURE
          ))
        ),
        takeUntil(action$.ofType(
          actionTypes.ADMINISTRATION_DELETE_TEMPLATE_RESPONSE ||
          actionTypes.ADMINISTRATION_DELETE_TEMPLATE_FAILURE
        ))
      )
    )
  );

export const fetchSelectedTemplateEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.ADMINISTRATION_TEMPLATE_REQUEST),
    mergeMap(action => from(EmailApi.get(`templates/${action.payload}`))
      .pipe(
          map(response => response.status === 200 ? response.data : null),
          map(response => actions.administrationTemplateResponse(response)),
          catchError(error => of(actions.administrationTemplateFailure(error))
          ),
          takeUntil(action$.ofType(
            actionTypes.ADMINISTRATION_TEMPLATE_RESPONSE ||
            actionTypes.ADMINISTRATION_TEMPLATE_FAILURE
          ))
      )
    )
  );

export const sendTestEmailEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.ADMINISTRATION_SEND_TEST_EMAIL_REQUEST),
    mergeMap(action => {
      const { code, email } = action.payload;
      return from(EmailApi.get(`test/send/${code}/${email}`))
      .pipe(
          map(response => response.status === 200 ? response.data : null),
          map(response => actions.administrationSendTestEmailResponse(
            response,
            actionTypes.ADMINISTRATION_SEND_TEST_EMAIL_RESPONSE
          )),
          catchError(error => of(actions.administrationSendTestEmailFailure(
            error,
            actionTypes.ADMINISTRATION_SEND_TEST_EMAIL_FAILURE
          ))
          ),
          takeUntil(action$.ofType(
            actionTypes.ADMINISTRATION_SEND_TEST_EMAIL_RESPONSE ||
            actionTypes.ADMINISTRATION_SEND_TEST_EMAIL_FAILURE
          ))
      )
    })
  );

export const fetchTemplateSystemsEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.ADMINISTRATION_TEMPLATE_SYSTEMS_REQUEST),
    mergeMap(action => from(EmailApi.get('systems', {
      params: { limit: 500 }
    }))
      .pipe(
          map(res => res.data.status === 'OK' ? res.data.items : err(errMessages.NOT_OK, res)),
          map(response => actions.administrationTemplateSystemsResponse(response)),
          catchError(error => of(actions.administrationTemplateSystemsFailure(error))),
          takeUntil(action$.ofType(
            actionTypes.ADMINISTRATION_TEMPLATE_SYSTEMS_RESPONSE ||
            actionTypes.ADMINISTRATION_TEMPLATE_SYSTEMS_FAILURE
          ))
      )
    )
  );



