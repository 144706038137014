import React from 'react'
import classNames from 'classnames'
import {
  TheContent,
  TheSidebar,
  TheAside,
  TheHeader
} from './index'
import { EProfileSetupKeys } from 'models'

import { ReactNotifications } from 'react-notifications-component'

// CSS imports
import 'react-notifications-component/dist/theme.css'

// Context containers
import AppWideOrdersContextContainer from 'contexts/components/app-wide/AppWideOrdersContextContainer/AppWideOrdersContextContainer'
import TheContentContextContainer from 'contexts/components/containers/content/TheContentContextContainer'

// Facades
import { useUserFacade } from 'redux-store/user/user.facade'

const TheLayout = () => {
  const userFacade = useUserFacade();

  const { profileSetup } = userFacade;

  const determineDarkMode = (profileSetup) => {
    if (profileSetup && profileSetup.hasOwnProperty(EProfileSetupKeys.DARK_MODE)) {
      return profileSetup.darkMode;
    } else if (profileSetup && !profileSetup.hasOwnProperty(EProfileSetupKeys.DARK_MODE)) {
      return true;
    }
  }

  const classes = classNames(
    'c-app c-default-layout',
    determineDarkMode(profileSetup) && 'c-dark-theme'
  )

  return (
    profileSetup ? (
      <div className={classes}>
        <AppWideOrdersContextContainer>
          <ReactNotifications />
          <TheSidebar />
          <TheAside/>
          <div className="c-wrapper">
            <TheHeader determineDarkMode={determineDarkMode} />
            <div className="c-body">
              <TheContentContextContainer>
                <TheContent />
              </TheContentContextContainer>
            </div>
          </div>
        </AppWideOrdersContextContainer>
      </div>
    ) : null
  )
}

export default TheLayout
