import React from 'react';
import { CCardBody } from '@coreui/react';
import 'spinkit/spinkit.min.css';

const PageSpinner = props => {

  const { type, show } = props;

  const showLoader = show === undefined ? true : show;

  const renderSwitchSpinner = type => {
    switch (type) {
      case 'chase-dot':
      return <div className="sk-chase" style={{margin: '0 auto'}}>
        <div className="sk-chase-dot"></div>
        <div className="sk-chase-dot"></div>
        <div className="sk-chase-dot"></div>
        <div className="sk-chase-dot"></div>
        <div className="sk-chase-dot"></div>
        <div className="sk-chase-dot"></div>
      </div>

      case 'flow-dot':
      return <div className="sk-flow">
        <div className="sk-flow-dot"></div>
        <div className="sk-flow-dot"></div>
        <div className="sk-flow-dot"></div>
      </div>

      default:
      return <div className="sk-chase" style={{margin: '0 auto'}}>
        <div className="sk-chase-dot"></div>
        <div className="sk-chase-dot"></div>
        <div className="sk-chase-dot"></div>
        <div className="sk-chase-dot"></div>
        <div className="sk-chase-dot"></div>
        <div className="sk-chase-dot"></div>
      </div>
    }
  }

  return (
    <div>
    { showLoader ?
      <CCardBody
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center'
        }}
      >
        {renderSwitchSpinner(type)}
      </CCardBody>
    : null }
    </div>
  );
}

export default PageSpinner;
