export const COLLECTION_LISTS_LIST_PAGE_REQUEST = '[COLLECTION_LISTS]_LIST_PAGE_REQUEST'
export const COLLECTION_LISTS_LIST_PAGE_RESPONSE = '[COLLECTION_LISTS]_LIST_PAGE_RESPONSE'
export const COLLECTION_LISTS_LIST_PAGE_FAILURE = '[COLLECTION_LISTS]_LIST_PAGE_FAILURE'

export const COLLECTION_LISTS_LIST_DETAIL_ITEMS_PAGE_REQUEST = '[COLLECTION_LISTS]_LIST_DETAIL_ITEMS_PAGE_REQUEST'
export const COLLECTION_LISTS_LIST_DETAIL_ITEMS_PAGE_RESPONSE = '[COLLECTION_LISTS]_LIST_DETAIL_ITEMS_PAGE_RESPONSE'
export const COLLECTION_LISTS_LIST_DETAIL_ITEMS_PAGE_FAILURE = '[COLLECTION_LISTS]_LIST_DETAIL_ITEMS_PAGE_FAILURE'

export const COLLECTION_LISTS_COLLECTION_LIST_STATUS_CHANGE_REQUEST = '[COLLECTION_LISTS]_COLLECTION_LIST_STATUS_CHANGE_REQUEST'
export const COLLECTION_LISTS_COLLECTION_LIST_STATUS_CHANGE_RESPONSE = '[COLLECTION_LISTS]_COLLECTION_LIST_STATUS_CHANGE_RESPONSE'
export const COLLECTION_LISTS_COLLECTION_LIST_STATUS_CHANGE_FAILURE = '[COLLECTION_LISTS]_COLLECTION_LIST_STATUS_CHANGE_FAILURE'

export const COLLECTION_LISTS_STORES_LIST_REQUEST = '[COLLECTION_LISTS]_STORES_LIST_REQUEST'
export const COLLECTION_LISTS_STORES_LIST_RESPONSE = '[COLLECTION_LISTS]_STORES_LIST_RESPONSE'
export const COLLECTION_LISTS_STORES_LIST_FAILURE = '[COLLECTION_LISTS]_STORES_LIST_FAILURE'

export const COLLECTION_LISTS_LIST_DETAIL_ITEM_DELETE_REQUEST = '[COLLECTION_LISTS]_LIST_DETAIL_ITEM_DELETE_REQUEST'
export const COLLECTION_LISTS_LIST_DETAIL_ITEM_DELETE_RESPONSE = '[COLLECTION_LISTS]_LIST_DETAIL_ITEM_DELETE_RESPONSE'
export const COLLECTION_LISTS_LIST_DETAIL_ITEM_DELETE_FAILURE = '[COLLECTION_LISTS]_LIST_DETAIL_ITEM_DELETE_FAILURE'

export const COLLECTION_LISTS_ADDED_BALIKOBOT_ORDERS_REQUEST = '[COLLECTION_LISTS]_ADDED_BALIKOBOT_ORDERS_REQUEST'
export const COLLECTION_LISTS_ADDED_BALIKOBOT_ORDERS_RESPONSE = '[COLLECTION_LISTS]_ADDED_BALIKOBOT_ORDERS_RESPONSE'
export const COLLECTION_LISTS_ADDED_BALIKOBOT_ORDERS_FAILURE = '[COLLECTION_LISTS]_ADDED_BALIKOBOT_ORDERS_FAILURE'

export const COLLECTION_LISTS_SHIPPERS_LIST_REQUEST = '[COLLECTION_LISTS]_SHIPPERS_LIST_REQUEST'
export const COLLECTION_LISTS_SHIPPERS_LIST_RESPONSE = '[COLLECTION_LISTS]_SHIPPERS_LIST_RESPONSE'
export const COLLECTION_LISTS_SHIPPERS_LIST_FAILURE = '[COLLECTION_LISTS]_SHIPPERS_LIST_FAILURE'

export const COLLECTION_LISTS_RESPONSE_MESSAGE_RESET = '[COLLECTION_LISTS]_RESPONSE_MESSAGE_RESET'

export const COLLECTION_LISTS_PRINT_DOCUMENT_REQUEST = '[COLLECTION_LISTS]_PRINT_DOCUMENT_REQUEST'
export const COLLECTION_LISTS_PRINT_DOCUMENT_RESPONSE = '[COLLECTION_LISTS]_PRINT_DOCUMENT_RESPONSE'
export const COLLECTION_LISTS_PRINT_DOCUMENT_FAILURE = '[COLLECTION_LISTS]_PRINT_DOCUMENT_FAILURE'

export const COLLECTION_LISTS_GENERATE_PDF_REQUEST = '[COLLECTION_LISTS]_GENERATE_PDF_REQUEST'
export const COLLECTION_LISTS_GENERATE_PDF_RESPONSE = '[COLLECTION_LISTS]_GENERATE_PDF_RESPONSE'
export const COLLECTION_LISTS_GENERATE_PDF_FAILURE = '[COLLECTION_LISTS]_GENERATE_PDF_FAILURE'

export const COLLECTION_LISTS_RESET_GENERATED_PDF = '[COLLECTION_LISTS]_RESET_GENERATED_PDF'
