/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from 'react'
import {
  CCreateElement,
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CSidebarNavDivider,
  CSidebarNavTitle,
  CNavItem,
  CProgress,
  CSidebarMinimizer,
  CSidebarNavDropdown,
  CSidebarNavItem,
} from '@coreui/react'
import PageSpinner from 'generic/notifications/PageSpinner'
import { useLayoutFacade } from '../redux-store/layout/layout.facade'
import { useOrdersFacade } from '../redux-store/orders/orders.facade'
import { useTransfersFacade } from 'redux-store/transfers/transfers.facade'
import { environmentIsDev, environmentIsProd, environmentIsProdMaster } from '../AppConfig'
import AppWideOrdersContext from 'contexts/context-registrations/AppWideOrdersContext'
import { useLocation } from 'react-router-dom'
// sidebar nav config
import navigation, {
  emailsNavItems,
  transfersNavItems,
  storeCardsNavItems,
  administrationNavItems,
  mailchimpNavItems,
  collectionListsNavItems,
  ratingNavItems,
  kegsNavItems,
} from './_nav'

import { prefixes } from '../routes';
import { useUserRoles } from 'hooks/useUserRoles'

const TheSidebar = () => {
  const layoutFacade = useLayoutFacade();
  const ordersFacade = useOrdersFacade();
  const transfersFacade = useTransfersFacade();
  const { hasUserAccess } = useUserRoles();

  const location = useLocation();
  const isOrdersRoute = location.pathname.includes(prefixes.orders);
  const isEmailsRoute = location.pathname.includes(prefixes.emails);
  const isTransfersRoute = location.pathname.includes(prefixes.transfers);
  const isStoreCardsRoute = location.pathname.includes(prefixes.storeCards);
  const isAdministrationRoute = location.pathname.includes(prefixes.administration);
  const isMailchimpRoute = location.pathname.includes(prefixes.mailchimp);
  const isCollectionListsRoute = location.pathname.includes(prefixes.collectionLists);
  const isRatingRoute = location.pathname.includes(prefixes.rating);
  const isKegsRoute = location.pathname.includes(prefixes.kegs);

  const { sidebarShow: show } = layoutFacade;
  const { finishedTransfersBadgeCount: badgeCount } = transfersFacade;
  const { redOrderStateFoldersCounts } = ordersFacade;

  const appWideOrdersContext = useContext(AppWideOrdersContext);
  const { ordersStatesSidebarItems, activeOrderState } = appWideOrdersContext;

  const [transferStatusForTransferDetail, setTransferStatusForTransferDetail] = useState();
  const [collectionListTransTypeForCLDetail, setCollectionListTransTypeForCLDetail] = useState();
  const [orderStateIdForOrderDetail, setOrderStateIdForOrderDetail] = useState();

  const [formedOrdersSidebarItems, setFormedOrdersSidebarItems] = useState();

  const filterDeniedItemsBasedOnUserRoles = (navItems) => {
    return navItems.filter(item => {
      if (item.hasOwnProperty('roles')) {
        return hasUserAccess(item.roles);
      } else {
        return true;
      }
    });
  }

  const getNavItems = navItems => {
    let items;

    if (environmentIsProd && !environmentIsProdMaster) {
      items = navItems.filter(item => item.mode === 'production');
    } else if (environmentIsProdMaster) {
      items = navItems.filter(item => item.mode === 'production' && item.master);
    } else {
      items = navItems;
    }

    return filterDeniedItemsBasedOnUserRoles(items);
  }

  useEffect(() => {
    if (isTransfersRoute && location.state && location.state.status) {
      setTransferStatusForTransferDetail(location.state.status);
    } /* else if (isTransfersRoute && location.from && location.from === 'header') {
      setTransferStatusForTransferDetail('QUEUED');
    } */ else {
      setTransferStatusForTransferDetail();
    }

    if (isCollectionListsRoute && location.state && location.state.transportationType) {
      setCollectionListTransTypeForCLDetail(location.state.transportationType);
    } else {
      setCollectionListTransTypeForCLDetail();
    }

    if (isOrdersRoute) {
      if (location.state && location.state.orderStateId) {
        setOrderStateIdForOrderDetail(location.state.orderStateId);
      }
    }
  }, [location]);

  const addConditionalActiveClassToOrderStatesItems = (orderStatesItems) => {
    // There is weird handling of 'Všechny' case, if going from a state back to
    // 'Všechny', upon selecting an order, active class is lost
    return orderStatesItems.map(item => {
      // Default class if not title
      item.class = 'c-sidebar-nav-link';
      if (item._tag === 'CSidebarNavTitle') {
        delete item.class;
      }
      // If any item has already an active class, remove it
      if (item.class && item.class.includes('c-active')) {
        item.class = 'c-sidebar-nav-link';
      }

      // WARNING: Weirdly, class assignment always needed to bean an evaluation with a custom helper class,
      // it behaves very unpredictably and this was the only way it worked

      // Set the item as active based on the orderStateId from route params
      if (item.hasOwnProperty('ordersstateid') && activeOrderState && activeOrderState.value) {
        if (orderStateIdForOrderDetail && (activeOrderState.value === orderStateIdForOrderDetail)) {
          item.class = ((orderStateIdForOrderDetail === item.ordersstateid)) ? 'c-sidebar-nav-link c-active orders-sidebar-nav-helper1' : 'c-sidebar-nav-link orders-sidebar-nav-helper2';
        }
        if ((orderStateIdForOrderDetail !== activeOrderState.value) || !orderStateIdForOrderDetail) {
          item.class = ((activeOrderState.value === item.ordersstateid)) ?  'c-sidebar-nav-link c-active orders-sidebar-nav-helper3' : 'c-sidebar-nav-link orders-sidebar-nav-helper4';
        }
      }

      if ((item.name === 'Všechny') && !activeOrderState.value) {
        // Všechny, needed to be an evaluation
        item.class = (orderStateIdForOrderDetail === 'ALL') ? 'c-sidebar-nav-link c-active orders-sidebar-nav-helper5' : 'c-sidebar-nav-link c-active orders-sidebar-nav-helper6';
      }
      return item;
    });
  }

  useEffect(() => {
    if (ordersStatesSidebarItems) {
      setFormedOrdersSidebarItems(
        addConditionalActiveClassToOrderStatesItems(ordersStatesSidebarItems)
      );
    }
  }, [
    activeOrderState.value,
    ordersStatesSidebarItems,
    orderStateIdForOrderDetail,
  ]);

  return (
    <CSidebar
      show={show}
      unfoldable
      onShowChange={(val) => layoutFacade.setSidebarShow(val)}
      // onDragOver={event => event.preventDefault()}
      // onDragLeave={event => event.preventDefault()}
    >
      <CSidebarBrand className="d-md-down-none" to="/">
        { environmentIsProdMaster ? <img src="/img/logo.png" alt="" /> : <h4 className="pt-2">BETA DEVELOPMENT</h4>}
      </CSidebarBrand>
      <CSidebarNav>

        { ordersStatesSidebarItems && isOrdersRoute && redOrderStateFoldersCounts &&
          <CCreateElement
            // items={addConditionalActiveClassToOrderStatesItems(ordersStatesSidebarItems)}
            items={formedOrdersSidebarItems}
            components={{
              CSidebarNavDivider,
              CSidebarNavDropdown,
              CSidebarNavItem,
              CSidebarNavTitle
            }}
          />
        }

        { !ordersStatesSidebarItems && isOrdersRoute && (
          <PageSpinner />
        )}

        { storeCardsNavItems && isStoreCardsRoute &&
          <CCreateElement
            items={storeCardsNavItems}
            components={{
              CSidebarNavDivider,
              CSidebarNavDropdown,
              CSidebarNavItem,
              CSidebarNavTitle
            }}
          />
        }

        { emailsNavItems && isEmailsRoute &&
          <CCreateElement
            items={getNavItems(emailsNavItems)}
            components={{
              CSidebarNavDivider,
              CSidebarNavDropdown,
              CSidebarNavItem,
              CSidebarNavTitle
            }}
          />
        }

        { transfersNavItems && isTransfersRoute &&
          <CCreateElement
            items={getNavItems(transfersNavItems(transferStatusForTransferDetail, badgeCount))}
            components={{
              CSidebarNavDivider,
              CSidebarNavDropdown,
              CSidebarNavItem,
              CSidebarNavTitle
            }}
          />
        }

        { collectionListsNavItems && isCollectionListsRoute &&
          <CCreateElement
            items={getNavItems(collectionListsNavItems(collectionListTransTypeForCLDetail))}
            components={{
              CSidebarNavDivider,
              CSidebarNavDropdown,
              CSidebarNavItem,
              CSidebarNavTitle
            }}
          />
        }

        { mailchimpNavItems && isMailchimpRoute &&
          <CCreateElement
            items={getNavItems(mailchimpNavItems)}
            components={{
              CSidebarNavDivider,
              CSidebarNavDropdown,
              CSidebarNavItem,
              CSidebarNavTitle
            }}
          />
        }

        { ratingNavItems && isRatingRoute &&
          <CCreateElement
            items={getNavItems(ratingNavItems)}
            components={{
              CSidebarNavDivider,
              CSidebarNavDropdown,
              CSidebarNavItem,
              CSidebarNavTitle
            }}
          />
        }

        { kegsNavItems && isKegsRoute &&
          <CCreateElement
            items={getNavItems(kegsNavItems)}
            components={{
              CSidebarNavDivider,
              CSidebarNavDropdown,
              CSidebarNavItem,
              CSidebarNavTitle
            }}
          />
        }

        { administrationNavItems && isAdministrationRoute &&
          <CCreateElement
            items={getNavItems(administrationNavItems())}
            components={{
              CSidebarNavDivider,
              CSidebarNavDropdown,
              CSidebarNavItem,
              CSidebarNavTitle
            }}
          />
        }

        { navigation &&
          <CCreateElement
            items={getNavItems(navigation)}
            components={{
              CSidebarNavDivider,
              CSidebarNavDropdown,
              CSidebarNavItem,
              CSidebarNavTitle
            }}
          />
        }

        { environmentIsDev && !environmentIsProd && !environmentIsProdMaster ?
        <div>
          <CSidebarNavDivider />
          <CSidebarNavTitle>System Utilization</CSidebarNavTitle>
          <CNavItem className="px-3 d-compact-none c-d-minimized-none">
            <div className="text-uppercase mb-1"><small><b>CPU Usage</b></small></div>
            <CProgress size="xs" value={25} color="info" />
            <small className="text-muted">348 Processes. 1/4 Cores.</small>
          </CNavItem>
          <CNavItem className="px-3 d-compact-none c-d-minimized-none">
            <div className="text-uppercase mb-1"><small><b>Memory Usage</b></small></div>
            <CProgress size="xs" value={70} color="warning" />
            <small className="text-muted">11444GB/16384MB</small>
          </CNavItem>
          <CNavItem className="px-3 mb-3 d-compact-none c-d-minimized-none">
            <div className="text-uppercase mb-1"><small><b>SSD 1 Usage</b></small></div>
            <CProgress size="xs" value={95} color="danger" />
            <small className="text-muted">243GB/256GB</small>
          </CNavItem>
        </div>
        : null }

      </CSidebarNav>
      <CSidebarMinimizer className="c-d-md-down-none"/>
    </CSidebar>
  )
}

export default React.memo(TheSidebar)
