import React from 'react'
import { prefixes } from '../../routes'

const BeerList = React.lazy(() => import('./pages/BeerList/BeerList'));

export const { rating } = prefixes;

const ratingRoutes = [
  { path: `${rating}/beer-list`, exact: true, name: 'Beer List', component: BeerList },
];

export default ratingRoutes;
