import * as actionTypes from './targets.actionTypes';

const initialState = {
  commoditiesPageData: null,
  commoditiesPageDraw: null,
  commoditiesPagePage: 0,
  commoditiesPageLimit: 50,
  commoditiesTotal: null,
  commodityCategories: null,
  commodityTargetsPageData: null,
  commodityTargetsPageDraw: null,
  commodityTargetsPagePage: 0,
  commodityTargetsPageLimit: 50,
  commodityTargetsTotal: null,
  kegsListInStockPageData: null,
  kegsListInStockPageDraw: null,
  kegsListInStockPagePage: 0,
  kegsListInStockPageLimit: 50,
  kegsListInStockTotal: null,
  kegsListOutOfStockPageData: null,
  kegsListOutOfStockPageDraw: null,
  kegsListOutOfStockPagePage: 0,
  kegsListOutOfStockPageLimit: 50,
  kegsListOutOfStockTotal: null,
  targetCommodityListInStockPageData: null,
  targetCommodityListInStockPageDraw: null,
  targetCommodityListInStockPagePage: 0,
  targetCommodityListInStockPageLimit: 50,
  targetCommodityListInStockTotal: null,
  targetCommodityListOutOfStockPageData: null,
  targetCommodityListOutOfStockPageDraw: null,
  targetCommodityListOutOfStockPagePage: 0,
  targetCommodityListOutOfStockPageLimit: 50,
  targetCommodityListOutOfStockTotal: null,
  selectedTargetCommoditySettings: null,
  kegSizesByPlu: {},
  kegSizesById: {},
  kegSizes: null,
  targets: null,
  manufacturers: null,
  manufacturersById: {},
  targetCategories: null,
  tags: null,
  targetFeedPropertyList: null,
  targetCommodityPropertyList: {},
  relatedStoreCardsByPlu: {},
  relatedTargetCommodityTargetsByTCId: {},
  fetchedSingleCommodityTarget: null,
  fetchedTargetCommodityCategory: null,
  createdKeg: null,
  resMessage: null,
  error: null,
  fetching: false,
}

const targetsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.TARGETS_RESET_RES_MESSAGE:
      return {
        ...state,
        resMessage: null,
      };
    case actionTypes.TARGETS_COMMODITY_CATEGORIES_REQUEST:
    case actionTypes.TARGETS_CREATE_MULTIPLE_COMMODITY_TARGETS_REQUEST:
    case actionTypes.TARGETS_TARGETS_CATEGORIES_REQUEST:
    case actionTypes.TARGETS_TARGETS_REQUEST:
    case actionTypes.TARGETS_DELETE_MULTIPLE_COMMODITY_TARGETS_REQUEST:
    case actionTypes.TARGETS_CREATE_KEG_PRODUCT_REQUEST:
    case actionTypes.TARGETS_KEG_SIZES_BY_COMMODITY_REQUEST:
    case actionTypes.TARGETS_EDIT_COMMODITY_TARGET_REQUEST:
    case actionTypes.TARGETS_KEG_SIZES_REQUEST:
    case actionTypes.TARGETS_EDIT_MULTIPLE_TARGET_COMMODITY_TARGETS_REQUEST:
    case actionTypes.TARGETS_EDIT_TARGET_CATEGORY_REQUEST:
    case actionTypes.TARGETS_MANUFACTURERS_LIST_REQUEST:
    case actionTypes.TARGETS_TARGET_COMMODITY_TARGET_SETTINGS_REQUEST:
    case actionTypes.TARGETS_EDIT_TARGET_COMMODITY_TARGET_SETTINGS_REQUEST:
    case actionTypes.TARGETS_EDIT_MANUFACTURER_REQUEST:
    case actionTypes.TARGETS_TARGET_FEED_PROPERTY_LIST_REQUEST:
    case actionTypes.TARGETS_TARGET_COMMODITY_PROPERTY_LIST_REQUEST:
    case actionTypes.TARGETS_EDIT_TARGET_COMMODITY_PROPERTY_REQUEST:
    case actionTypes.TARGETS_CREATE_TARGET_COMMODITY_PROPERTY_REQUEST:
    case actionTypes.TARGETS_COMMODITY_TARGET_REQUEST:
    case actionTypes.TARGETS_TARGET_COMMODITY_CATEGORY_REQUEST:
    case actionTypes.TARGETS_EDIT_TARGET_COMMODITY_CATEGORY_REQUEST:
      return {
        ...state,
        fetching: true,
      };
    case actionTypes.TARGETS_COMMODITIES_LIST_REQUEST:
      return {
        ...state,
        commoditiesPageDraw: action.payload.draw,
        commoditiesPagePage: action.payload.page,
        commoditiesPageLimit: action.payload.limit,
        fetching: true,
      };
    case actionTypes.TARGETS_COMMODITY_TARGETS_LIST_REQUEST:
      return {
        ...state,
        commodityTargetsPagePage: action.payload.page,
        commodityTargetsPageLimit: action.payload.limit,
        fetching: true,
      };
    case actionTypes.TARGETS_KEGS_LIST_BOTH_STOCK_OPTIONS_REQUEST: {
      let newPartialState = {};
      if (action.payload.fetchOptions.fetchInStock) {
        newPartialState = {
          ...newPartialState,
          kegsListInStockPageDraw: action.payload.inStockParams.draw,
          kegsListInStockPagePage: action.payload.inStockParams.page,
          kegsListInStockPageLimit: action.payload.inStockParams.limit,
        };
      }
      if (action.payload.fetchOptions.fetchOutOfStock) {
        newPartialState = {
          ...newPartialState,
          kegsListOutOfStockPageDraw: action.payload.outOfStockParams.draw,
          kegsListOutOfStockPagePage: action.payload.outOfStockParams.page,
          kegsListOutOfStockPageLimit: action.payload.outOfStockParams.limit,
        };
      }
      return {
        ...state,
        ...newPartialState,
        fetching: true,
      }
    };
    case actionTypes.TARGETS_TARGET_COMMODITY_LIST_BOTH_STOCK_OPTIONS_REQUEST: {
      let newPartialState = {};
      if (action.payload.fetchOptions.fetchInStock) {
        newPartialState = {
          ...newPartialState,
          targetCommodityListInStockPageDraw: action.payload.inStockParams.draw,
          targetCommodityListInStockPagePage: action.payload.inStockParams.page,
          targetCommodityListInStockPageLimit: action.payload.inStockParams.limit,
        };
      }
      if (action.payload.fetchOptions.fetchOutOfStock) {
        newPartialState = {
          ...newPartialState,
          targetCommodityListOutOfStockPageDraw: action.payload.outOfStockParams.draw,
          targetCommodityListOutOfStockPagePage: action.payload.outOfStockParams.page,
          targetCommodityListOutOfStockPageLimit: action.payload.outOfStockParams.limit,
        };
      }
      return {
        ...state,
        ...newPartialState,
        fetching: true,
      }
    };
    case actionTypes.TARGETS_COMMODITIES_LIST_RESPONSE:
      return {
        ...state,
        commoditiesPageData: action.payload.tableData.items,
        commoditiesTotal: action.payload.tableData.total,
        relatedStoreCardsByPlu: action.payload.storeCards,
        fetching: false,
      };
    case actionTypes.TARGETS_COMMODITY_TARGETS_LIST_RESPONSE:
      return {
        ...state,
        commodityTargetsPageDraw: action.payload.tableData.draw,
        commodityTargetsPageData: action.payload.tableData.items,
        commodityTargetsTotal: action.payload.tableData.total,
        relatedStoreCardsByPlu: action.payload.storeCards,
        relatedTargetCommodityTargetsByTCId: action.payload.targetCommodityTargetsById,
        fetching: false,
      };
    case actionTypes.TARGETS_KEGS_LIST_BOTH_STOCK_OPTIONS_RESPONSE: {
      let newPartialState = {};
      if (action.payload.inStockList) {
        newPartialState = {
          ...newPartialState,
          kegsListInStockPageData: action.payload.inStockList.items,
          kegsListInStockTotal: action.payload.inStockList.total,
        };
      }
      if (action.payload.outOfStockList) {
        newPartialState = {
          ...newPartialState,
          kegsListOutOfStockPageData: action.payload.outOfStockList.items,
          kegsListOutOfStockTotal: action.payload.outOfStockList.total,
        };
      }
      return {
        ...state,
        ...newPartialState,
        fetching: false,
      }
    };
    case actionTypes.TARGETS_TARGET_COMMODITY_LIST_BOTH_STOCK_OPTIONS_RESPONSE: {
      let newPartialState = {};
      if (action.payload.inStockList) {
        newPartialState = {
          ...newPartialState,
          targetCommodityListInStockPageData: action.payload.inStockList.items,
          targetCommodityListInStockTotal: action.payload.inStockList.total,
        };
      }
      if (action.payload.outOfStockList) {
        newPartialState = {
          ...newPartialState,
          targetCommodityListOutOfStockPageData: action.payload.outOfStockList.items,
          targetCommodityListOutOfStockTotal: action.payload.outOfStockList.total,
        };
      }
      return {
        ...state,
        ...newPartialState,
        fetching: false,
      }
    };
    case actionTypes.TARGETS_COMMODITY_CATEGORIES_RESPONSE:
      return {
        ...state,
        commodityCategories: action.payload,
        fetching: false,
      };
    case actionTypes.TARGETS_TARGETS_CATEGORIES_RESPONSE:
      return {
        ...state,
        targetCategories: action.payload,
        fetching: false,
      };
    case actionTypes.TARGETS_KEG_SIZES_BY_COMMODITY_RESPONSE:
      return {
        ...state,
        kegSizesByPlu: action.payload,
        fetching: false,
      };
    case actionTypes.TARGETS_TARGETS_RESPONSE:
      return {
        ...state,
        targets: action.payload,
        fetching: false,
      };
    case actionTypes.TARGETS_KEG_SIZES_RESPONSE:
      return {
        ...state,
        kegSizes: action.payload.kegSizes,
        kegSizesById: action.payload.kegSizesById,
        fetching: false,
      };
    case actionTypes.TARGETS_MANUFACTURERS_LIST_RESPONSE:
      return {
        ...state,
        manufacturers: action.payload.manufacturers,
        manufacturersById: action.payload.manufacturersById,
        fetching: false,
      };
    case actionTypes.TARGETS_TARGET_FEED_PROPERTY_LIST_RESPONSE:
      return {
        ...state,
        targetFeedPropertyList: action.payload,
        fetching: false,
      };
    case actionTypes.TARGETS_TARGET_COMMODITY_PROPERTY_LIST_RESPONSE:
      return {
        ...state,
        targetCommodityPropertyList: action.payload,
        fetching: false,
      };
    case actionTypes.TARGETS_TARGET_COMMODITY_TARGET_SETTINGS_RESPONSE:
      return {
        ...state,
        selectedTargetCommoditySettings: action.payload,
        fetching: false,
      };
    case actionTypes.TARGETS_COMMODITY_TARGET_RESPONSE:
      return {
        ...state,
        fetchedSingleCommodityTarget: action.payload,
        fetching: false,
      };
    case actionTypes.TARGETS_COMMODITY_TARGET_RESET:
      return {
        ...state,
        fetchedSingleCommodityTarget: null,
      };
    case actionTypes.TARGETS_EDIT_TARGET_COMMODITY_TARGET_SETTINGS_RESPONSE:
      return {
        ...state,
        fetching: false,
      };
    case actionTypes.TARGETS_TARGET_COMMODITY_CATEGORY_RESPONSE:
      return {
        ...state,
        fetchedTargetCommodityCategory: action.payload,
        fetching: false,
      };
    case actionTypes.TARGETS_CREATE_KEG_PRODUCT_RESPONSE:
      return {
        ...state,
        createdKeg: action.payload.response,
        resMessage: action.payload.resMessage,
        fetching: false,
      };
    case actionTypes.TARGETS_CREATE_KEG_PRODUCT_RESET:
      return {
        ...state,
        createdKeg: null,
      };
    case actionTypes.TARGETS_CREATE_MULTIPLE_COMMODITY_TARGETS_RESPONSE:
    case actionTypes.TARGETS_DELETE_MULTIPLE_COMMODITY_TARGETS_RESPONSE:
    case actionTypes.TARGETS_EDIT_COMMODITY_TARGET_RESPONSE:
    case actionTypes.TARGETS_EDIT_MULTIPLE_TARGET_COMMODITY_TARGETS_RESPONSE:
    case actionTypes.TARGETS_EDIT_TARGET_CATEGORY_RESPONSE:
    case actionTypes.TARGETS_EDIT_MANUFACTURER_RESPONSE:
    case actionTypes.TARGETS_CREATE_TARGET_COMMODITY_PROPERTY_RESPONSE:
    case actionTypes.TARGETS_EDIT_TARGET_COMMODITY_PROPERTY_RESPONSE:
    case actionTypes.TARGETS_EDIT_TARGET_COMMODITY_CATEGORY_RESPONSE:
      return {
        ...state,
        resMessage: action.payload.resMessage,
        fetching: false,
      };
    case actionTypes.TARGETS_CREATE_MULTIPLE_COMMODITY_TARGETS_FAILURE:
    case actionTypes.TARGETS_DELETE_MULTIPLE_COMMODITY_TARGETS_FAILURE:
    case actionTypes.TARGETS_CREATE_KEG_PRODUCT_FAILURE:
    case actionTypes.TARGETS_EDIT_COMMODITY_TARGET_FAILURE:
    case actionTypes.TARGETS_EDIT_MULTIPLE_TARGET_COMMODITY_TARGETS_FAILURE:
    case actionTypes.TARGETS_EDIT_TARGET_CATEGORY_FAILURE:
    case actionTypes.TARGETS_EDIT_MANUFACTURER_FAILURE:
    case actionTypes.TARGETS_CREATE_TARGET_COMMODITY_PROPERTY_FAILURE:
    case actionTypes.TARGETS_EDIT_TARGET_COMMODITY_PROPERTY_FAILURE:
    case actionTypes.TARGETS_EDIT_TARGET_COMMODITY_CATEGORY_FAILURE:
      return {
        ...state,
        resMessage: action.payload.resMessage,
        error: {...action.payload},
        fetching: false,
      };
    case actionTypes.TARGETS_COMMODITIES_LIST_FAILURE:
    case actionTypes.TARGETS_COMMODITY_CATEGORIES_FAILURE:
    case actionTypes.TARGETS_COMMODITY_TARGETS_LIST_FAILURE:
    case actionTypes.TARGETS_TARGETS_FAILURE:
    case actionTypes.TARGETS_TARGETS_CATEGORIES_FAILURE:
    case actionTypes.TARGETS_KEG_SIZES_BY_COMMODITY_FAILURE:
    case actionTypes.TARGETS_KEG_SIZES_FAILURE:
    case actionTypes.TARGETS_KEGS_LIST_BOTH_STOCK_OPTIONS_FAILURE:
    case actionTypes.TARGETS_TARGET_COMMODITY_LIST_BOTH_STOCK_OPTIONS_FAILURE:
    case actionTypes.TARGETS_MANUFACTURERS_LIST_FAILURE:
    case actionTypes.TARGETS_TARGET_COMMODITY_TARGET_SETTINGS_FAILURE:
    case actionTypes.TARGETS_EDIT_TARGET_COMMODITY_TARGET_SETTINGS_FAILURE:
    case actionTypes.TARGETS_TARGET_FEED_PROPERTY_LIST_FAILURE:
    case actionTypes.TARGETS_TARGET_COMMODITY_PROPERTY_LIST_FAILURE:
    case actionTypes.TARGETS_COMMODITY_TARGET_FAILURE:
    case actionTypes.TARGETS_TARGET_COMMODITY_CATEGORY_FAILURE:
      return {
        ...state,
        error: {...action.payload},
        fetching: false,
      };
    default:
      return state;
  }
};

export default targetsReducer;
