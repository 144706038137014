import { AirshopApi, MailchimpDeliApi, MailchimpBeerApi } from 'CraftApi.axios'
import { from, of } from 'rxjs'
import { map, concatMap, delay, switchMap, combineAll } from 'rxjs/operators'
import { err, errMessages } from 'redux-store/utils/epics.utils'
import { environmentIsDev } from 'AppConfig'
import _ from 'lodash'

export const getApiValsByShop = shop => {
  let api;
  let listId;

  if (shop === 'delikatesy') {
    api = MailchimpDeliApi;
    listId = 'c537d9b33a';
  }

  if (shop === 'beershop') {
    api = MailchimpBeerApi;
    // listId = '83cc6bfd68'; // REAL audience for production ==> careful!
    // listId = '9b84e95733'; // TEST audience for development
    listId = environmentIsDev ? '9b84e95733' : '83cc6bfd68';
  }

  return { api, listId };
}

export const getOrdersFetchReq = (params, draw, limit=50, page=0) => {
  return AirshopApi.get('orders', {
    params: {
      draw,
      limit,
      order: 'date_created',
      page: page * limit,
      sort: 'DESC',
      ...params,
    }
  })
}

export const $hasStreamErrored = () => {
  return map(response => {
    let hasErrored = false;

    // Handle possible errors
    if (response) {
      // Response is array -> from rorkJoin, more than 1 batch
      if (response.length) {
        response.forEach(singleRes => {
          if (singleRes.status !== 200) { hasErrored = true; }
        });
      // Response is single res -> from first batch res, only 1 batch
      } else {
        if (response.status !== 200) { hasErrored = true; }
      }
    }

    // Send response to stream, unless errored
    if (hasErrored) {
      return err(errMessages.NOT_200, response)
    } else {
      return response;
    }
  })
}

export const getMailchimpEmailObjects = (proccessedEmails) => {
  return proccessedEmails.map(batch => batch.map(email => ({
    'email_address': email,
    'email_type': 'html',
    'status': 'subscribed',
  })))
}

export const $fetchAllEmailsByParams = (params, draw, proccessedEmails) => {
  return switchMap(ordersTotal => {
    let pagesCount = Math.floor(ordersTotal/500);
    if ((ordersTotal % 500) !== 0) pagesCount++;

    const pages = [...Array(pagesCount).keys()];

    return from(pages)
      .pipe(
        concatMap(page => of(getOrdersFetchReq(params, draw + page + 1, 500, page)).pipe(delay(200))),
        combineAll(),
        map(responses => {
          // cannot use proccessedEmails with map directly, because it creates new array
          // and does not mutate original stream array
          let helperArr = [];
          helperArr = responses.map(
            res => res.data.items.map(order => order.orderEmail)
          );
          // Getting rid of duplicates and null values emails
          helperArr = helperArr.map(batch => _.uniq(_.filter(batch, email => !!email)));
          // mutating the original array with proccessed emails
          helperArr.forEach((val, i) => proccessedEmails[i] = val);
          return getMailchimpEmailObjects(proccessedEmails);
        })
      )
  })
}

export const $subscribeBatchedEmails = (api, listId) => {
  return switchMap(batchedMailchimpEmailObjects => from(batchedMailchimpEmailObjects)
  .pipe(
    concatMap(mailchimpObjects => of(api.post(`lists/${listId}`, {
        'members': mailchimpObjects,
        'update_existing': false
      })).pipe(
        delay(350)
      )
    ),
    combineAll(),
  ))
}

export const $tagBatchedEmails = (api, listId, segmentId, batchedEmailsArr) => {
  return from(batchedEmailsArr)
    .pipe(
      concatMap(
      batchEmailsArr => of(api.post(
        `lists/${listId}/segments/${segmentId}`,
        { 'members_to_add': batchEmailsArr }
      )).pipe(
        delay(batchedEmailsArr.length > 10 ? 1000 : 500)
      )
    ),
    combineAll()
  )
}

// emailsBatch       -> cannot be passed directly, only reference to arr becuase at time of execution,
//                      for sendAllMode TRUE arr[0] is undefined, assigned later in the request conditionally
// sendAllMode TRUE  -> only first batch is being sent
// sendAllMode FALSE -> all the emails are sent because they manually selected emails are max 50 and unbatched
export const $tagBatchAndCreateNewTag = (api, listId, newTag, emailsBatch, sendAllMode=true) => {
  return switchMap(subscribeForkJoinRes => from(
    api.post(`lists/${listId}/segments`, {
      'name': newTag,
      'static_segment': sendAllMode ? emailsBatch[0] : emailsBatch,
    })
  ))
}

export const $tagOtherPotentionalBatchesExceptFirst = (api, listId, proccessedEmails) => {
  return switchMap(firstBatchAddedAndNetTagCreatedRes => (
    // Outer ternary
    (firstBatchAddedAndNetTagCreatedRes.status === 200) &&
     firstBatchAddedAndNetTagCreatedRes.data.id) ? (
        // Inner ternary
        (proccessedEmails.length > 1) ?
          // If not all batches tagged, tag the others apart from the first one
          $tagBatchedEmails(
            api, listId, firstBatchAddedAndNetTagCreatedRes.data.id, _.tail(proccessedEmails)
          )
        // If the first batch was the only one, return its tag response, no more tagging wil be executed, inner ternary
        : of(firstBatchAddedAndNetTagCreatedRes)
      )
      // Tagging first batch not successful, outer ternary
      : err(errMessages.NOT_200, firstBatchAddedAndNetTagCreatedRes)
  )
}

export const $tagAllBatches = (api, listId, tagId, proccessedEmails) => {
  return switchMap(subscribeForkJoinRes =>
    // If all single responses have 200 status
    _.every(subscribeForkJoinRes, ['status', 200]) ? (
          // If not all batches tagged, tag the others apart from the first one
          $tagBatchedEmails(
            api, listId, tagId, proccessedEmails
          )
      )
      // Emails were not subscribed successfully
      : err(errMessages.NOT_200, subscribeForkJoinRes)
  )
}
