import { useDispatch } from 'react-redux'
import { useCallback } from 'react'

import * as actions from './rent.actions'
import { useRentSelectors } from './rent.selectors'

export function useRentFacade() {
  const dispatch = useDispatch();
  const selectors = useRentSelectors();

  const {
    selectResMessage: rentResMessage,
    selectContractItemsByContractId: contractItemsByContractId,
    selectScanDocumentsByContractId: scanDocumentsByContractId,
    selectSingleCustomer: singleCustomer,
    selectSingleContract: singleContract,
    selectReservationItemsByReservationId: reservationItemsByReservationId,
    selectCreatedReservation: createdReservation,
    selectRelatedCustomers: relatedCustomers,
    selectGeneratedPdfDocument: generatedRentPdfDocument,
    selectRelatedDeposits: relatedDeposits,
    selectCreatedDeposit: createdDeposit,
    selectRelatedPdfDocuments: relatedRentPdfDocuments,
    selectDepositSum: depositSum,

    selectTemplatePdfsListPageData: templatePdfsListPageData,
    selectTemplatePdfsListPageDraw: templatePdfsListPageDraw,
    selectTemplatePdfsListPagePage: templatePdfsListPagePage,
    selectTemplatePdfsListTotal: templatePdfsListTotal,
    selectTemplatePdfsListPageLimit: templatePdfsListPageLimit,

    selectCustomersListPageData: customersListPageData,
    selectCustomersListPageDraw: customersListPageDraw,
    selectCustomersListPagePage: customersListPagePage,
    selectCustomersListTotal: customersListTotal,
    selectCustomersListPageLimit: customersListPageLimit,

    selectContractsListPageData: contractsListPageData,
    selectContractsListPageDraw: contractsListPageDraw,
    selectContractsListPagePage: contractsListPagePage,
    selectContractsListTotal: contractsListTotal,
    selectContractsListPageLimit: contractsListPageLimit,

    selectTargetCommodityForRentalListPageData: targetCommodityForRentalListPageData,
    selectTargetCommodityForRentalListPageDraw: targetCommodityForRentalListPageDraw,
    selectTargetCommodityForRentalListPagePage: targetCommodityForRentalListPagePage,
    selectTargetCommodityForRentalListTotal: targetCommodityForRentalListTotal,
    selectTargetCommodityForRentalListPageLimit: targetCommodityForRentalListPageLimit,

    selectReservationsListPageData: reservationsListPageData,
    selectReservationsListPageDraw: reservationsListPageDraw,
    selectReservationsListPagePage: reservationsListPagePage,
    selectReservationsListTotal: reservationsListTotal,
    selectReservationsListPageLimit: reservationsListPageLimit,
  } = selectors;

  const resetRentResponseMessage = useCallback(
    () =>
      dispatch(actions.rentResetResponseMesage()),
    [dispatch]
  );

  const fetchTemplatesPdfList = useCallback(
    (draw=1, page=0, limit=50, search=null) =>
      dispatch(actions.rentTemplatesPdfListRequest(
        draw, page, limit, search
      )),
    [dispatch]
  );

  const createTemplatePdf = useCallback(
    (newTemplatePdf) =>
      dispatch(actions.rentCreateTemplatePdfRequest(newTemplatePdf)),
    [dispatch]
  );

  const editTemplatePdf = useCallback(
    (templatePdfId, editedTemplatePdf) =>
      dispatch(actions.rentEditTemplatePdfRequest(templatePdfId, editedTemplatePdf)),
    [dispatch]
  );

  const deleteTemplatePdf = useCallback(
    (templatePdfId) =>
      dispatch(actions.rentDeleteTemplatePdfRequest(templatePdfId)),
    [dispatch]
  );

  const fetchCustomersList = useCallback(
    (draw=1, page=0, limit=50, search=null) =>
      dispatch(actions.rentCustomersListRequest(
        draw, page, limit, search
      )),
    [dispatch]
  );

  const createRentingCustomer = useCallback(
    (newCustomer) =>
      dispatch(actions.rentCreateCustomerRequest(newCustomer)),
    [dispatch]
  );

  const updateRentingCustomer = useCallback(
    (customerId, editedCustomer) =>
      dispatch(actions.rentEditCustomerRequest(customerId, editedCustomer)),
    [dispatch]
  );

  const fetchContractItemsByContractId = useCallback(
    (contractId) =>
      dispatch(actions.rentContractItemsByContractIdRequest(contractId)),
    [dispatch]
  );

  const createMultipleContractItems = useCallback(
    (newContractItemsArr) =>
      dispatch(actions.rentCreateMultipleContractItemsRequest(newContractItemsArr)),
    [dispatch]
  );

  const deleteContractItem = useCallback(
    (contractItemId) =>
      dispatch(actions.rentDeleteContractItemRequest(contractItemId)),
    [dispatch]
  );

  const createContract = useCallback(
    (newContract) =>
      dispatch(actions.rentCreateContractRequest(newContract)),
    [dispatch]
  );

  const editContract = useCallback(
    (contractId, editedContract) =>
      dispatch(actions.rentEditContractRequest(contractId, editedContract)),
    [dispatch]
  );

  const deleteContract = useCallback(
    (contractId) =>
      dispatch(actions.rentDeleteContractRequest(contractId)),
    [dispatch]
  );

  const fetchContractsList = useCallback(
    (draw=1, page=0, limit=50, search=null, status='ALL', customerId=null, partnersId=null, fetchDeposits=true, fetchDocuments=true) =>
      dispatch(actions.rentContractsListRequest(
        draw, page, limit, search, status, customerId, partnersId, fetchDeposits, fetchDocuments
      )),
    [dispatch]
  );

  const fetchScanDocumentsByContractId = useCallback(
    (contractId) =>
      dispatch(actions.rentScanDocumentsListByContractIdRequest(contractId)),
    [dispatch]
  );

  const createScanDocument = useCallback(
    (newScanDocument, contractId, type) =>
      dispatch(actions.rentCreateScanDocumentRequest(newScanDocument, contractId, type)),
    [dispatch]
  );

  const deleteScanDocument = useCallback(
    (scanDocumentId) =>
      dispatch(actions.rentDeleteScanDocumentRequest(scanDocumentId)),
    [dispatch]
  );

  const fetchTargetCommodityForRental = useCallback(
    (draw=1, page=0, limit=50, search=null) =>
      dispatch(actions.renttargetCommodityForRentalListRequest(
        draw, page, limit, search,
      )),
    [dispatch]
  );

  const resetCustomer = useCallback(
    () =>
      dispatch(actions.rentCreateCustomerReset()),
    [dispatch]
  );

  const resetContract = useCallback(
    () =>
      dispatch(actions.rentCreateContractReset()),
    [dispatch]
  );

  const fetchReservationsList = useCallback(
    (draw=1, page=0, limit=50, search=null) =>
      dispatch(actions.rentReservationsListRequest(
        draw, page, limit, search,
      )),
    [dispatch]
  );

  const createReservation = useCallback(
    (newReservation) =>
      dispatch(actions.rentCreateReservationRequest(newReservation)),
    [dispatch]
  );

  const editReservation = useCallback(
    (reservationId, editedReservation) =>
      dispatch(actions.rentEditReservationRequest(reservationId, editedReservation)),
    [dispatch]
  );

  const fetchReservationItemsListByReservationId = useCallback(
    (reservationId) =>
      dispatch(actions.rentReservationItemsListByReservationIdRequest(
        reservationId
      )),
    [dispatch]
  );

  const createReservationItem = useCallback(
    (newReservationItem) =>
      dispatch(actions.rentCreateReservationItemRequest(newReservationItem)),
    [dispatch]
  );

  const deleteReservationItem = useCallback(
    (reservationItemsId) =>
      dispatch(actions.rentDeleteReservationItemRequest(reservationItemsId)),
    [dispatch]
  );

  const resetCreatedReservation = useCallback(
    () =>
      dispatch(actions.rentCreateReservationReset()),
    [dispatch]
  );

  const finalizeContract = useCallback(
    (contractId) =>
      dispatch(actions.rentFinalizeContractRequest(contractId)),
    [dispatch]
  );

  const deleteReservation = useCallback(
    (reservationId) =>
      dispatch(actions.rentDeleteReservationRequest(reservationId)),
    [dispatch]
  );

  const fetchCustomer = useCallback(
    (customerId) =>
      dispatch(actions.rentCustomerRequest(customerId)),
    [dispatch]
  );

  const generateRentTypePdfDocument = useCallback(
    (type, itemId) =>
      dispatch(actions.rentGeneratePdfDocumentRequest(type, itemId)),
    [dispatch]
  );

  const resetGeneratedRentTypePdfDocument = useCallback(
    () =>
      dispatch(actions.rentGeneratePdfDocumentReset()),
    [dispatch]
  );

  const createDeposit = useCallback(
    (newDeposit) =>
      dispatch(actions.rentCreateDepositRequest(newDeposit)),
    [dispatch]
  );

  const updateDeposit = useCallback(
    (depositId, editedDeposit) =>
      dispatch(actions.rentEditDepositRequest(depositId, editedDeposit)),
    [dispatch]
  );

  const transformReservationToContract = useCallback(
    (reservationId, paymentType) =>
      dispatch(actions.rentTransformReservationToContractRequest(
        reservationId, paymentType
      )),
    [dispatch]
  );

  const remindRentContract = useCallback(
    (contractId) =>
      dispatch(actions.rentRemindRentContractRequest(contractId)),
    [dispatch]
  );

  const printRentDocument = useCallback(
    (type, itemId) =>
      dispatch(actions.rentPrintDocumentRequest(type, itemId)),
    [dispatch]
  );

  const fetchDepositSum = useCallback(
    (createdGte, createdLte, paymentType, status) =>
      dispatch(actions.rentDepositSumRequest(
        createdGte, createdLte, paymentType, status
      )),
    [dispatch]
  );

  const resetCreatedDeposit = useCallback(
    () =>
      dispatch(actions.rentCreateDepositReset()),
    [dispatch]
  );

  const finalizeContractReturn = useCallback(
    (contractId) =>
      dispatch(actions.rentFinalizeReturnRequest(contractId)),
    [dispatch]
  );

  const fetchContractDocuments = useCallback(
    (contractId) =>
      dispatch(actions.rentContractDocumentsRequest(contractId)),
    [dispatch]
  );

  const prepareContractFromContract = useCallback(
    (contractId, depositId, paymentType, timestampFrom, timestampTo) =>
      dispatch(actions.rentPrepareContractFromContractRequest(
        contractId, depositId, paymentType, timestampFrom, timestampTo
      )),
    [dispatch]
  );

  const prepareReservationFromContract = useCallback(
    (contractId, depositId, timestampFrom, timestampTo) =>
      dispatch(actions.rentPrepareReservationFromContractRequest(
        contractId, depositId, timestampFrom, timestampTo
      )),
    [dispatch]
  );

  return {
    rentResMessage,
    contractItemsByContractId,
    scanDocumentsByContractId,
    singleCustomer,
    singleContract,
    reservationItemsByReservationId,
    createdReservation,
    relatedCustomers,
    generatedRentPdfDocument,
    relatedDeposits,
    createdDeposit,
    relatedRentPdfDocuments,
    depositSum,

    templatePdfsListPageData,
    templatePdfsListPageDraw,
    templatePdfsListPagePage,
    templatePdfsListTotal,
    templatePdfsListPageLimit,

    customersListPageData,
    customersListPageDraw,
    customersListPagePage,
    customersListTotal,
    customersListPageLimit,

    contractsListPageData,
    contractsListPageDraw,
    contractsListPagePage,
    contractsListTotal,
    contractsListPageLimit,

    targetCommodityForRentalListPageData,
    targetCommodityForRentalListPageDraw,
    targetCommodityForRentalListPagePage,
    targetCommodityForRentalListTotal,
    targetCommodityForRentalListPageLimit,

    reservationsListPageData,
    reservationsListPageDraw,
    reservationsListPagePage,
    reservationsListTotal,
    reservationsListPageLimit,

    fetchTemplatesPdfList,
    resetRentResponseMessage,
    createTemplatePdf,
    editTemplatePdf,
    deleteTemplatePdf,
    fetchCustomersList,
    createRentingCustomer,
    updateRentingCustomer,
    fetchContractItemsByContractId,
    createMultipleContractItems,
    deleteContractItem,
    createContract,
    editContract,
    deleteContract,
    fetchContractsList,
    fetchScanDocumentsByContractId,
    createScanDocument,
    deleteScanDocument,
    fetchTargetCommodityForRental,
    resetContract,
    resetCustomer,
    fetchReservationsList,
    createReservation,
    editReservation,
    fetchReservationItemsListByReservationId,
    createReservationItem,
    deleteReservationItem,
    resetCreatedReservation,
    finalizeContract,
    deleteReservation,
    fetchCustomer,
    generateRentTypePdfDocument,
    resetGeneratedRentTypePdfDocument,
    createDeposit,
    updateDeposit,
    transformReservationToContract,
    remindRentContract,
    printRentDocument,
    fetchDepositSum,
    resetCreatedDeposit,
    finalizeContractReturn,
    fetchContractDocuments,
    prepareContractFromContract,
    prepareReservationFromContract,
  }

}
