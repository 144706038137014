import * as actionTypes from './layout.actionTypes';

const initialState = {
  sidebarShow: 'responsive',
  asideShow: false,
  // darkMode: true,
  // type defined in layouts.facade.js in formCrumbObject function
  breadcrumb: null,
};

const layoutReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LAYOUT_SIDEBAR_SHOW:
      return {
        ...state,
        sidebarShow: action.payload,
      };
    case actionTypes.LAYOUT_ASIDE_SHOW:
      return {
        ...state,
        asideShow: action.payload,
      };
    // case actionTypes.LAYOUT_DARKMODE:
    //   return {
    //     ...state,
    //     darkMode: action.payload,
    //   };
    case actionTypes.LAYOUT_SET_NEW_BREADCRUMB:
      return {
        ...state,
        breadcrumb: action.payload,
      };
    case actionTypes.LAYOUT_APPEND_BREADCRUMB: {
      const updatedBreadcrumb = [...state.breadcrumb, ...action.payload];
      return {
        ...state,
        breadcrumb: updatedBreadcrumb,
      }
    };
    default:
      return state;
  }
};

export default layoutReducer;
