// Modal list in transfers
export const STORE_CARDS_LIST_REQUEST = '[STORE_CARDS]_LIST_MODAL_REQUEST'
export const STORE_CARDS_LIST_RESPONSE = '[STORE_CARDS]_LIST_MODAL_RESPONSE'
export const STORE_CARDS_LIST_FAILURE= '[STORE_CARDS]_LIST_MODAL_FAILURE'

// Section list in store cards
export const STORE_CARDS_SECTION_LIST_REQUEST = '[STORE_CARDS]_SECTION_LIST_REQUEST'
export const STORE_CARDS_SECTION_LIST_RESPONSE = '[STORE_CARDS]_SECTION_LIST_RESPONSE'
export const STORE_CARDS_SECTION_LIST_FAILURE = '[STORE_CARDS]_SECTION_LIST_FAILURE'

// Fetch multiple eans for array of store cards ids
export const STORE_CARDS_EANS_REQUEST = '[STORE_CARDS]_EANS_REQUEST'
export const STORE_CARDS_EANS_RESPONSE = '[STORE_CARDS]_EANS_RESPONSE'
export const STORE_CARDS_EANS_FAILURE = '[STORE_CARDS]_EANS_FAILURE'

export const STORE_CARDS_BULK_CHANGE_EXPORT_NULL_QUANTITY_REQUEST = '[STORE_CARDS]_BULK_CHANGE_EXPORT_NULL_QUANTITY_REQUEST'
export const STORE_CARDS_BULK_CHANGE_EXPORT_NULL_QUANTITY_RESPONSE = '[STORE_CARDS]_BULK_CHANGE_EXPORT_NULL_QUANTITY_RESPONSE'
export const STORE_CARDS_BULK_CHANGE_EXPORT_NULL_QUANTITY_FAILURE = '[STORE_CARDS]_BULK_CHANGE_EXPORT_NULL_QUANTITY_FAILURE'

export const STORE_CARDS_RESPONSE_MESSAGE_RESET = '[STORE_CARDS]_RESPONSE_MESSAGE_RESET'

export const STORE_CARDS_BOTTLE_TYPES_BY_SC_REQUEST = '[STORE_CARDS]_BOTTLE_TYPES_BY_SC_REQUEST'
export const STORE_CARDS_BOTTLE_TYPES_BY_SC_RESPONSE = '[STORE_CARDS]_BOTTLE_TYPES_BY_SC_RESPONSE'
export const STORE_CARDS_BOTTLE_TYPES_BY_SC_FAILURE = '[STORE_CARDS]_BOTTLE_TYPES_BY_SC_FAILURE'

export const STORE_CARDS_PRODUCT_IN_ORDERS_LIST_REQUEST = '[STORE_CARDS]_PRODUCT_IN_ORDERS_LIST_REQUEST'
export const STORE_CARDS_PRODUCT_IN_ORDERS_LIST_RESPONSE = '[STORE_CARDS]_PRODUCT_IN_ORDERS_LIST_RESPONSE'
export const STORE_CARDS_PRODUCT_IN_ORDERS_LIST_FAILURE = '[STORE_CARDS]_PRODUCT_IN_ORDERS_LIST_FAILURE'

export const STORE_CARDS_EDITABLE_CONSUMPTION_TAX_LIST_REQUEST = '[STORE_CARDS]_EDITABLE_CONSUMPTION_TAX_LIST_REQUEST'
export const STORE_CARDS_EDITABLE_CONSUMPTION_TAX_LIST_RESPONSE = '[STORE_CARDS]_EDITABLE_CONSUMPTION_TAX_LIST_RESPONSE'
export const STORE_CARDS_EDITABLE_CONSUMPTION_TAX_LIST_FAILURE = '[STORE_CARDS]_EDITABLE_CONSUMPTION_TAX_LIST_FAILURE'

export const STORE_CARDS_BOTTLE_TYPE_LIST_REQUEST = '[STORE_CARDS]_BOTTLE_TYPE_LIST_REQUEST'
export const STORE_CARDS_BOTTLE_TYPE_LIST_RESPONSE = '[STORE_CARDS]_BOTTLE_TYPE_LIST_RESPONSE'
export const STORE_CARDS_BOTTLE_TYPE_LIST_FAILURE = '[STORE_CARDS]_BOTTLE_TYPE_LIST_FAILURE'

export const STORE_CARDS_EDIT_STORE_CARD_REQUEST = '[STORE_CARDS]_EDIT_STORE_CARD_REQUEST'
export const STORE_CARDS_EDIT_STORE_CARD_RESPONSE = '[STORE_CARDS]_EDIT_STORE_CARD_RESPONSE'
export const STORE_CARDS_EDIT_STORE_CARD_FAILURE = '[STORE_CARDS]_EDIT_STORE_CARD_FAILURE'

export const STORE_CARDS_DELETE_EAN_REQUEST = '[STORE_CARDS]_DELETE_EAN_REQUEST'
export const STORE_CARDS_DELETE_EAN_RESPONSE = '[STORE_CARDS]_DELETE_EAN_RESPONSE'
export const STORE_CARDS_DELETE_EAN_FAILURE = '[STORE_CARDS]_DELETE_EAN_FAILURE'

export const STORE_CARDS_EDIT_MULTIPLE_STORE_CARDS_REQUEST = '[STORE_CARDS]_EDIT_MULTIPLE_STORE_CARDS_REQUEST'
export const STORE_CARDS_EDIT_MULTIPLE_STORE_CARDS_RESPONSE = '[STORE_CARDS]_EDIT_MULTIPLE_STORE_CARDS_RESPONSE'
export const STORE_CARDS_EDIT_MULTIPLE_STORE_CARDS_FAILURE = '[STORE_CARDS]_EDIT_MULTIPLE_STORE_CARDS_FAILURE'

export const STORE_CARDS_FB_FEEDS_LIST_REQUEST = '[STORE_CARDS]_FB_FEEDS_LIST_REQUEST';
export const STORE_CARDS_FB_FEEDS_LIST_RESPONSE = '[STORE_CARDS]_FB_FEEDS_LIST_RESPONSE';
export const STORE_CARDS_FB_FEEDS_LIST_FAILURE = '[STORE_CARDS]_FB_FEEDS_LIST_FAILURE';

