import { createStore, combineReducers, applyMiddleware } from 'redux';
import { combineEpics, createEpicMiddleware } from 'redux-observable';
import { composeWithDevTools } from 'redux-devtools-extension';

// Reducers
import rent from './rent/rent.reducer'
import auth from './auth/auth.reducer'
import user from './user/user.reducer'
import layout from './layout/layout.reducer'
import orders from './orders/orders.reducer'
import emails from './emails/emails.reducer'
import rating from './rating/rating.reducer'
import targets from './targets/targets.reducer'
import transfers from './transfers/transfers.reducer'
import mailchimp from './mailchimp/mailchimp.reducer'
import storeCards from './store-cards/store-cards.reducer'
import userChecks from './user-cheks/user-checks.reducer'
import administration from './administration/administration.reducer'
import collectionLists from './collection-lists/collection-lists.reducer'

// Epics
import * as collectionListsEpics from './collection-lists/collection-lists.epics'
import * as administrationEpics from './administration/administration.epics'
import * as storeCardsEpics from './store-cards/store-cards.epics'
import * as userChecksEpics from './user-cheks/user-checks.epics'
import * as transfersEpics from './transfers/transfers.epics'
import * as mailchimpEpics from './mailchimp/mailchimp.epics'
import * as targetsEpics from './targets/targets.epics'
import * as emailsEpics from './emails/emails.epics'
import * as ordersEpics from './orders/orders.epics'
import * as ratingEpics from './rating/rating.epics'
import * as userEpics from './user/user.epics'
import * as authEpics from './auth/auth.epics'
import * as rentEpics from './rent/rent.epics'

const epicMiddleware = createEpicMiddleware();
export const rootEpic = combineEpics(
  authEpics.fetchAuthIdmEpic,

  ordersEpics.fetchOrdersPageEpic,
  ordersEpics.fetchTransportationTypesEpic,
  ordersEpics.fetchStavObjednavkyEpic,
  ordersEpics.fetchOrderDetailProductsPageEpic,
  ordersEpics.fetchSelectedOrderEpic,
  ordersEpics.updateStavObjednavkyEpic,
  ordersEpics.fetchOrderFirmEpic,
  ordersEpics.fetchOrdersProductsForPdfListEpic,
  ordersEpics.sendOrderToStavSms,
  ordersEpics.sendOrderToStavEmail,
  ordersEpics.fetchOrderPartnerMessagesEpic,
  ordersEpics.updateOrderItemsQuantityEpic,
  ordersEpics.fetchAddressEpic,
  ordersEpics.fetchOrderPartnerEpic,
  ordersEpics.deleteOrderProductEpic,
  ordersEpics.createOrderPartnerMessageEpic,
  ordersEpics.syncOrderWithAbraEpic,
  ordersEpics.createOrderDeliveryListEpic,
  ordersEpics.recreateOrderDeliveryListEpic,
  ordersEpics.fetchOrderDeliveryListEpic,
  ordersEpics.createOrderItemEpic,
  ordersEpics.checkOrderSyncStatusesEpic,
  ordersEpics.syncWithAbraWhileInAbraEpic,
  ordersEpics.printDocumentEpic,
  ordersEpics.printBalikobotEpic,
  ordersEpics.printThermal80Epic,
  ordersEpics.massPrintDocumentsEpic,
  ordersEpics.generatePdfDocumentEpic,
  ordersEpics.massGeneratePdfDocumentEpic,
  ordersEpics.updateBulkOrdersStavObjednavkyEpic,
  ordersEpics.createCollectionListItemFromOrderEpic,
  ordersEpics.fetchCollectionListQueuedEpic,
  ordersEpics.createBalikobotOrderEntryEpic,
  ordersEpics.fetchBalikobotOrderEntryEpic,
  ordersEpics.updateBalikobotOrderEntryEpic,
  ordersEpics.generateBalikobotTagEpic,
  ordersEpics.dropOrderFromBalikobotEpic,
  ordersEpics.createCLItemsFromMultipleOrdersEpic,
  ordersEpics.fetchOrderDetailStatusLogsEpic,
  ordersEpics.fetchBalikobotOrderBranchEpic,
  ordersEpics.fetchBranchesByShipperIdEpic,
  ordersEpics.fetchTransportationTypesValidDraftEpic,
  ordersEpics.fetchServiceTypesByShipperIdEpic,
  ordersEpics.fetchOrdersCountByEmailEpic,
  ordersEpics.fetchOrderAbraCommandEpic,
  ordersEpics.createSubscriptionEpic,
  ordersEpics.fetchSubscriptionsListEpic,
  ordersEpics.fetchOrderSubscriptionsListEpic,
  ordersEpics.createSubscriptionWithUpdateEpic,
  ordersEpics.fetchMultipleBalikobotOrderEntryEpic,
  ordersEpics.fetchOrdersStatesOrdersCountsEpic,
  ordersEpics.fetchSourceSubscriptionsByIndexEpic,
  ordersEpics.createMultipleSubscriptionWithUpdateEpic,
  ordersEpics.deleteOrderSubscriptionEpic,
  ordersEpics.fetchSelectedOrderAddressesEpic,
  ordersEpics.setSelectedOrderAddressEpic,
  ordersEpics.editOrderSubscriptionEpic,

  userEpics.fetchUserProfileSetupEpic,
  userEpics.createUserProfileSetupPropEpic,
  userEpics.updateUserProfileSetupEpic,
  userEpics.deleteUserProfileSetupPropEpic,

  transfersEpics.fetchTransferWaysEpic,
  transfersEpics.fetchTransfersPageEpic,
  transfersEpics.fetchSelectedTransferItemsPageEpic,
  transfersEpics.createNewTransferEpic,
  transfersEpics.changeTransferStatusEpic,
  transfersEpics.createNewTransferDetailItemEpic,
  transfersEpics.updateTransferDetailItemQuantityEpic,
  transfersEpics.createTransferDetailItemFromOrderEpic,
  transfersEpics.processTransferDetailItemEpic,
  transfersEpics.deleteTransferDetailItemEpic,
  transfersEpics.deleteTransferEpic,
  transfersEpics.updateTransferEpic,
  transfersEpics.updateTransferNoteEpic,
  transfersEpics.updateTransferItemNoteEpic,
  transfersEpics.printDocumentEpic,
  transfersEpics.generatePdfDocumentEpic,
  transfersEpics.fetchSalesRequestsEpic,
  transfersEpics.fetchSalesRequestsItemsEpic,
  transfersEpics.fetchSalesRequestsItemsQueuedAndCheckedEpic,
  transfersEpics.deleteSalesRequestsItemEpic,
  transfersEpics.updateSalesRequestsItemEpic,
  transfersEpics.transferDetailItemChangeTransferQueueEpic,

  storeCardsEpics.fetchStoreCardsModalListPageEpic,
  storeCardsEpics.fetchStoreCardsSectionListPageEpic,
  storeCardsEpics.fetchRelatedStoreCardsEansEpic,
  storeCardsEpics.changeBulkExportQuantityNullEpic,
  storeCardsEpics.fetchRelatedStoreCardsBottleTypesEpic,
  storeCardsEpics.fetchProductInOrdersListEpic,
  storeCardsEpics.fetchStoreCardsConsumptionTaxListPageEpic,
  storeCardsEpics.fetchBottleTypesListEpic,
  storeCardsEpics.editStoreCardEpic,
  storeCardsEpics.deleteEanEpic,
  storeCardsEpics.editMultipleStoreCardsEpic,
  storeCardsEpics.fetchStoreCardsForFBFeedEpic,

  administrationEpics.importFCFileByShopEpic,
  administrationEpics.syncFCDaysBackByShopEpic,
  administrationEpics.fetchPrintServersEpic,
  administrationEpics.fetchPrintersListEpic,
  administrationEpics.fetchPrinterProfileEpic,
  administrationEpics.updateUserThermalPrinterEpic,
  administrationEpics.updateUserA4PrinterEpic,
  administrationEpics.fetchAirshopNotificationsEpic,
  administrationEpics.fetchIPAddressesListPageEpic,
  administrationEpics.createNewIPAddressEpic,
  administrationEpics.updateIPAddressEpic,
  administrationEpics.deleteIPAddressEpic,
  administrationEpics.fetchPartnersListEpic,
  administrationEpics.fetchAbraCommandsListEpic,
  administrationEpics.updateUserThermalPrinterByAdminEpic,
  administrationEpics.updateUserA4PrinterByAdminEpic,
  administrationEpics.fetchAirshopNotificationsBackendErrorEpic,
  administrationEpics.fetchTemplatesPageEpic,
  administrationEpics.createNewTemplateEpic,
  administrationEpics.editTemplateEpic,
  administrationEpics.deleteTemplateEpic,
  administrationEpics.fetchSelectedTemplateEpic,
  administrationEpics.sendTestEmailEpic,
  administrationEpics.fetchTemplateSystemsEpic,

  mailchimpEpics.fetchOrdersPageEpic,
  mailchimpEpics.fetchTagsListEpic,
  mailchimpEpics.bulkEmailsToExistingTagEpic,
  mailchimpEpics.bulkEmailsToNewTagEpic,

  userChecksEpics.fetchUserIPCheckDataEpic,

  collectionListsEpics.fetchCollectionListsListPageEpic,
  collectionListsEpics.fetchCollectionListsListDetailItemsPageEpic,
  collectionListsEpics.changeCollectionListStatusEpic,
  collectionListsEpics.fetchStoresListEpic,
  collectionListsEpics.deleteCollectionListDetailItemEpic,
  collectionListsEpics.fetchAddedBalikobotOrdersEpic,
  collectionListsEpics.fetchShippersListEpic,
  collectionListsEpics.printDocumentEpic,
  collectionListsEpics.generatePdfDocumentEpic,

  ratingEpics.fetchBeersWithWebsitesEpic,
  ratingEpics.fetchWebsitesListEpic,
  ratingEpics.fetchBeerByBeerIdEpic,
  ratingEpics.updateBeerByBeerIdEpic,
  ratingEpics.deleteBeerByBeerIdEpic,
  ratingEpics.toggleActiveBeerByBeerIdEpic,
  ratingEpics.fetchBeerWithWebsitesByBeerIdEpic,
  ratingEpics.createBeerWithWebsitesEpic,
  ratingEpics.updateBeerWithWebsitesByIdEpic,
  ratingEpics.deleteBeerWithWebsitesByIdEpic,
  ratingEpics.multipleBeerWithWebsitesRequestsEpic,

  targetsEpics.fetchCommoditiesListEpic,
  targetsEpics.fetchCommodityCategoriesEpic,
  targetsEpics.createMultipleCommodityTargetsEpic,
  targetsEpics.fetchCommodityTargetsListEpic,
  targetsEpics.fetchTargetCategoriesEpic,
  targetsEpics.fetchTargetsEpic,
  targetsEpics.deleteMultipleCommodityTargetsEpic,
  targetsEpics.createKegProductEpic,
  targetsEpics.fetchKegSizesByCommodityEpic,
  targetsEpics.updateCommodityTargetEpic,
  targetsEpics.fetchKegSizesEpic,
  targetsEpics.fetchKegsListBothStockOptionsEpic,
  targetsEpics.updateMultipleTargetCommodityTargetsEpic,
  targetsEpics.fetchTargetCommodityListBothStockOptionsEpic,
  targetsEpics.editTargetCategoryEpic,
  targetsEpics.fetchManufacturersListEpic,
  targetsEpics.fetchTargetCommoditySettingsEpic,
  targetsEpics.updateTargetCommoditySettingsEpic,
  targetsEpics.updateManufacturerEpic,
  targetsEpics.fetchTargetFeedPropertyListEpic,
  targetsEpics.createMultipleTargetCommodityPropertiesEpic,
  targetsEpics.editMultipleTargetCommodityPropertiesEpic,
  targetsEpics.fetchTargetCommodityPropertyListEpic,
  targetsEpics.fetchCommodityTargetEpic,
  targetsEpics.fetchTargetCommodityCategoryEpic,
  targetsEpics.editTargetCommodityCategoryEpic,

  rentEpics.fetchTemplatesPdfListEpic,
  rentEpics.createTemplatePdfEpic,
  rentEpics.editTemplatePdfEpic,
  rentEpics.deleteTemplatePdfEpic,
  rentEpics.fetchCustomersListEpic,
  rentEpics.createCustomerEpic,
  rentEpics.editCustomerEpic,
  rentEpics.fetchContractItemsByContractIdEpic,
  rentEpics.createMultipleContractItemsEpic,
  rentEpics.deleteContractItemEpic,
  rentEpics.createContractEpic,
  rentEpics.editContractEpic,
  rentEpics.deleteContractEpic,
  rentEpics.fetchContractsListEpic,
  rentEpics.fetchScanDocumentsListByContractIdEpic,
  rentEpics.createScanDocumentEpic,
  rentEpics.deleteScanDocumentEpic,
  rentEpics.fetchTargetCommodityForRentalListEpic,
  rentEpics.fetchReservationsListEpic,
  rentEpics.createReservationEpic,
  rentEpics.editReservationEpic,
  rentEpics.fetchReservationItemsListByReservationIdEpic,
  rentEpics.createReservationItemEpic,
  rentEpics.deleteReservationItemEpic,
  rentEpics.finalizeContractEpic,
  rentEpics.deleteReservationEpic,
  rentEpics.fetchCustomerEpic,
  rentEpics.generatePdfDocumentEpic,
  rentEpics.createDepositEpic,
  rentEpics.editDepositEpic,
  rentEpics.transformReservationToContractEpic,
  rentEpics.remindRentContractEpic,
  rentEpics.printRentDocumentEpic,
  rentEpics.fetchDepositSumEpic,
  rentEpics.finalizeContractReturnEpic,
  rentEpics.fetchContractDocumentsEpic,
  rentEpics.prepareContractFromContractEpic,
  rentEpics.prepareReservationFromContractEpic,

  emailsEpics.fetchReceivedEmails,
  emailsEpics.fetchSentEmails,
);

const rootReducer = combineReducers({
  auth,
  layout,
  orders,
  user,
  transfers,
  emails,
  storeCards,
  administration,
  mailchimp,
  userChecks,
  collectionLists,
  rating,
  targets,
  rent,
});

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(epicMiddleware))
);
epicMiddleware.run(rootEpic);

export const storeState = store.getState();

const epicMiddlewareForTestPurposes = createEpicMiddleware();
export const storeFactoryForTestPurposes = (initialState) => {
  const testStore = createStore(
    rootReducer,
    initialState,
    composeWithDevTools(applyMiddleware(epicMiddlewareForTestPurposes))
  );
  epicMiddlewareForTestPurposes.run(rootEpic);
  return testStore;
}

export default store;
