import { useSelector } from 'react-redux'

export function useCollectionListsSelectors() {
  const selectResMessage = useSelector(state => state.collectionLists.resMessage);
  const selectStores = useSelector(state => state.collectionLists.stores);
  const selectShippers = useSelector(state => state.collectionLists.shippers);
  const selectGeneratedPdfDocument = useSelector(state => state.collectionLists.generatedPdfDocument);

  const selectCollectionListsPageData  = useSelector(state => state.collectionLists.collectionListsPageData);
  const selectCollectionListsPageDraw  = useSelector(state => state.collectionLists.collectionListsPageDraw);
  const selectCollectionListsPagePage  = useSelector(state => state.collectionLists.collectionListsPagePage);
  const selectCollectionListsTotal     = useSelector(state => state.collectionLists.collectionListsTotal);
  const selectCollectionListsPageLimit = useSelector(state => state.collectionLists.collectionListsPageLimit);

  const selectItemsPageData  = useSelector(state => state.collectionLists.selectedCollectionListItems.itemsPageData);
  const selectItemsPageDraw  = useSelector(state => state.collectionLists.selectedCollectionListItems.itemsPageDraw);
  const selectItemsPagePage  = useSelector(state => state.collectionLists.selectedCollectionListItems.itemsPagePage);
  const selectItemsPageLimit = useSelector(state => state.collectionLists.selectedCollectionListItems.itemsPageLimit);
  const selectItemsTotal     = useSelector(state => state.collectionLists.selectedCollectionListItems.itemsTotal);

  const selectAddedBalikobotOrdersPageData  = useSelector(state => state.collectionLists.addedBalikobotOrders.addedBalikobotOrdersPageData);
  const selectAddedBalikobotOrdersPageDraw  = useSelector(state => state.collectionLists.addedBalikobotOrders.addedBalikobotOrdersPageDraw);
  const selectAddedBalikobotOrdersPagePage  = useSelector(state => state.collectionLists.addedBalikobotOrders.addedBalikobotOrdersPagePage);
  const selectAddedBalikobotOrdersPageLimit = useSelector(state => state.collectionLists.addedBalikobotOrders.addedBalikobotOrdersPageLimit);
  const selectAddedBalikobotOrdersTotal     = useSelector(state => state.collectionLists.addedBalikobotOrders.addedBalikobotOrdersTotal);

  return {
    selectResMessage,
    selectStores,
    selectShippers,
    selectGeneratedPdfDocument,

    selectCollectionListsPageData,
    selectCollectionListsPageDraw,
    selectCollectionListsPagePage,
    selectCollectionListsTotal,
    selectCollectionListsPageLimit,

    selectItemsPageData,
    selectItemsPageDraw,
    selectItemsPagePage,
    selectItemsPageLimit,
    selectItemsTotal,

    selectAddedBalikobotOrdersPageData,
    selectAddedBalikobotOrdersPageDraw,
    selectAddedBalikobotOrdersPagePage,
    selectAddedBalikobotOrdersPageLimit,
    selectAddedBalikobotOrdersTotal,
  }
}

