import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { useIsAdmin } from 'hooks/useIsAdmin'

const AdminRoute = (props) => {
  const { isAdmin } = useIsAdmin();
  return isAdmin ? <Route {...props} /> : <Redirect to="/nepovoleny-pristup" />
}

export default AdminRoute
