export const notifications = {
  createItemSuccess: {
    title: 'Create new', /* item */
    notificationType: 'success',
    message: {
      mes1: 'New', /* item */
      mes2: 'was successfully created!'
    }
  },
  createItemFailure: {
    title: 'Create new', /* item */
    notificationType: 'danger',
    message: {
      mes1: 'Error! New',
      mes2: 'was not created or other problems have occurred! Try again...'
    }
  },
  readItemFailure: {
    title: 'Access denied for this', /* item */
    notificationType: 'info',
    message: {
      mes1: 'Warning! You don\'t have the permission to access this' /* item */
    }
  },
  editItemSuccess: {
    title: 'Edit this', /* item */
    notificationType: 'success',
    message: {
      mes1: 'Your edits for this', /* item */
      mes2: 'were successfully saved!'
    }
  },
  editItemFailure: {
    title: 'Edit this', /* item */
    notificationType: 'danger',
    message: {
      mes1: 'Error! Your edits for this', /* item */
      mes2: 'were not saved or other problems have occurred! Try again...'
    }
  },
  deleteItemSuccess: {
    title: 'Vymazat', /* item */
    notificationType: 'success',
    message: {
      mes1: 'Daný ', /* item */
      mes2: 'byl úspěšně smazán!'
    }
  },
  deleteItemFailure: {
    title: 'Vymazat', /* item */
    notificationType: 'danger',
    message: {
      mes1: 'Error! Tento ', /* item */
      mes2: 'se nepodařilo smazat, pokuste se akci opakovat...'
    }
  },
  resetFormSuccess: {
    title: 'Reset form in this', /* item */
    notificationType: 'success',
    message: {
      mes1: 'The form in this', /* item */
      mes2: 'was reset successfully.',
    },
  },
  resetFormFailure: {
    title: 'Reset form in this', /* item */
    notificationType: 'danger',
    message: {
      mes1: 'Error! The form in this', /* item */
      mes2: 'could not be reset or other problems have occurred! Try again...',
    }
  },
  sendMasculineItemSuccess: {
    title: 'Odeslat', /* item */
    notificationType: 'success',
    message: {
      mes1: 'Váš', /* item */
      mes2: 'byl úspěšně odeslán!'
    }
  },
  sendMasculineItemFailure: {
    title: 'Odeslat', /* item */
    notificationType: 'danger',
    message: {
      mes1: 'Error! Váš', /* item */
      mes2: 'nemohl být odeslán nebo nastaly jiné problémy! Zkuste akci opakovat...'
    }
  },
  sendFeminineItemSuccess: {
    title: 'Odesílaná', /* item */
    notificationType: 'success',
    message: {
      mes1: 'Vaše', /* item */
      mes2: 'byla úspěšně odeslána!'
    }
  },
  sendFeminineItemFailure: {
    title: 'Odeslat', /* item */
    notificationType: 'danger',
    message: {
      mes1: 'Error! Vaše', /* item */
      mes2: 'nemohla být odeslána nebo nastaly jiné problémy! Zkuste akci opakovat...'
    }
  },
  changeProductQuantitySuccess: {
    title: 'Změna', /* item */
    notificationType: 'success',
    message: {
      mes1: 'Změna', /* item */
      mes2: 'u produktu byla úspěšně provedena!'
    }
  },
  changeProductQuantityFailure: {
    title: 'Změna', /* item */
    notificationType: 'danger',
    message: {
      mes1: 'Error! Při změně', /* item */
      mes2: 'nastala chyba!'
    }
  },
  syncFeminineSuccess: {
    title: 'Synchronizace ', /* item */
    notificationType: 'success',
    message: {
      mes1: 'Synchronizace ', /* item */
      mes2: 'byla úspěšně provedena!'
    }
  },
  syncFeminineFailure: {
    title: 'Synchronizace ', /* item */
    notificationType: 'danger',
    message: {
      mes1: 'Error! Při synchronizaci ', /* item */
      mes2: 'nastala chyba!'
    }
  },
  deliveryListCreateSuccess: {
    title: 'Vytvořit delivery list',
    notificationType: 'success',
    message: {
      mes1: 'Požadavek na vytvoření delivery listu byl přijat!',
    }
  },
  deliveryListCreateFailure: {
    title: 'Vytvořit delivery list',
    notificationType: 'danger',
    message: {
      mes1: 'Error! Při odesílání požadavku nastala chyba!',
    }
  },
  createTransferSuccess: {
    title: 'Vytvořit novou převodku',
    notificationType: 'success',
    message: {
      mes1: 'Požadavek na vytvoření nové převodky byl úspěšně zpracován!',
    }
  },
  createTransferFailure: {
    title: 'Vytvořit novou převodku',
    notificationType: 'danger',
    message: {
      mes1: 'Error! Při vytváření nové převodky nastala chyba!',
    }
  },
  transferStatusChangeSuccess: {
    title: 'Změna statusu převodky',
    notificationType: 'success',
    message: {
      mes1: 'Požadavek na změnu statusu převodky byl úspěšně zpracován!',
    }
  },
  transferStatusChangeFailure: {
    title: 'Změna statusu převodky',
    notificationType: 'danger',
    message: {
      mes1: 'Error! Při změně statusu převodky nastala chyba!',
    }
  },
  transferDetailCreateItemSuccess: {
    title: 'Nová položka v převodce',
    notificationType: 'success',
    message: {
      mes1: 'Nová položka byla do převodky úspěšně přidána!',
    }
  },
  transferDetailCreateItemFailure: {
    title: 'Nová položka v převodce',
    notificationType: 'danger',
    message: {
      mes1: 'Error! Při ukládání položky do převodky nastala chyba!',
    }
  },
  transferDetailUpdateItemQuantitySuccess: {
    title: 'Změna množství v položce',
    notificationType: 'success',
    message: {
      mes1: 'Změna množství položky převodky byla úspěšně provedena!',
    }
  },
  transferDetailUpdateItemQuantityFailure: {
    title: 'Změna množství v položce',
    notificationType: 'danger',
    message: {
      mes1: 'Error! Při změně množství nastala chyba!',
    }
  },
  changesSentSuccess: {
    title: 'Změny',
    notificationType: 'success',
    message: {
      mes1: 'Změny úspěšně odeslány!',
    }
  },
  changesSentFailure: {
    title: 'Změny',
    notificationType: 'danger',
    message: {
      mes1: 'Error! Při odesílání změn došlo k chybě!',
    }
  },
  FCImportSuccess: {
    title: 'Nahrání souboru',
    notificationType: 'success',
    message: {
      mes1: 'Soubor byl úspěšně odeslán!',
    }
  },
  FCImportFailure: {
    title: 'Nahrání souboru',
    notificationType: 'danger',
    message: {
      mes1: 'Error! Při odesílání souboru nastala chyba...',
    }
  },
  FCSyncByDaysBackSuccess: {
    title: 'Zpětná sychronizace',
    notificationType: 'success',
    message: {
      mes1: 'Synchronizace proběhla úspěšně!',
    }
  },
  FCSyncByDaysBackFailure: {
    title: 'Zpětná sychronizace',
    notificationType: 'danger',
    message: {
      mes1: 'Error! Nastala chyba při synchronizaci...',
    }
  },
  changeOrderStateSuccess: {
    title: 'Změna stavu objednávky',
    notificationType: 'success',
    message: {
      mes1: 'Změna stavu proběhla úspěšně!',
    }
  },
  changeOrderStateFailure: {
    title: 'Změna stavu objednávky',
    notificationType: 'danger',
    message: {
      mes1: 'Error! Při změně stavu objednávky nastala chyba...',
    }
  },
  generalSyncSuccess: {
    title: 'Sychronizace',
    notificationType: 'success',
    message: {
      mes1: 'Synchronizace proběhla úspěšně!',
    }
  },
  generalSyncFailure: {
    title: 'Sychronizace',
    notificationType: 'danger',
    message: {
      mes1: 'Error! Nastala chyba při synchronizaci...',
    }
  },
  mailchimpSuccess: {
    title: 'Mailchimp',
    notificationType: 'success',
    message: {
      mes1: 'Export emailů proběhl úspěšně!',
    }
  },
  mailchimpFailure: {
    title: 'Mailchimp',
    notificationType: 'danger',
    message: {
      mes1: 'Error! Nastala chyba při exportování emailů...',
    }
  },
  updatePrinterSuccess: {
    title: 'Nastavení tiskárny',
    notificationType: 'success',
    message: {
      mes1: 'Požadavek na nastavení tiskárny byl úspěšně odeslán!',
    }
  },
  updatePrinterFailure: {
    title: 'Nastavení tiskárny',
    notificationType: 'danger',
    message: {
      mes1: 'Error! Při nastavování tiskárny nastala chyba...',
    }
  },
  orderDetailCreateItemSuccess: {
    title: 'Nová položka v objednávce',
    notificationType: 'success',
    message: {
      mes1: 'Nová položka byla do objednávky úspěšně přidána!',
    }
  },
  orderDetailCreateItemFailure: {
    title: 'Nová položka v objednávce',
    notificationType: 'danger',
    message: {
      mes1: 'Error! Při ukládání položky do objednávky nastala chyba!',
    }
  },
  bulkOrdersStateChangeSuccess: {
    title: 'Hromadná změna stavu objednávek',
    notificationType: 'success',
    message: {
      mes1: 'Vybraným objednávkám byl úspěšně změněn stav!',
    }
  },
  bulkOrdersStateChangeFailure: {
    title: 'Hromadná změna stavu objednávek',
    notificationType: 'danger',
    message: {
      mes1: 'Error! Při změně stavu u vybraných objednávek nastala chyba!',
    }
  },
  generatePdfFailure: {
    title: 'Generování PDF',
    notificationType: 'danger',
    message: {
      mes1: 'Error! Při generování PDF nastala chyba. Více informací naleznete v konzoli prohlížeče.',
    }
  },
  storeCardEditSuccess: {
    title: 'Změna ve skladové kartě',
    notificationType: 'success',
    message: {
      mes1: 'Požadavek na změnu ve skladové kartě úspěšně odeslán!',
    }
  },
  storeCardEditFailure: {
    title: 'Změna ve skladové kartě',
    notificationType: 'danger',
    message: {
      mes1: 'Error! Při odesílání požadavku na změnu skladové karty nastala chyba...',
    }
  },
  deleteEanSuccess: {
    title: 'Odstranit EAN',
    notificationType: 'success',
    message: {
      mes1: 'EAN úspěšně odstraněn!',
    }
  },
  deleteEanFailure: {
    title: 'Odstranit EAN',
    notificationType: 'danger',
    message: {
      mes1: 'Error! EAN se zřejmě nepodařilo odstranit...',
    }
  },
  multipleStoreCardsEditSuccess: {
    title: 'Změny ve skladových kartách',
    notificationType: 'success',
    message: {
      mes1: 'Požadavky na změny ve skladových kartách úspěšně odeslány!',
    }
  },
  multipleStoreCardsEditFailure: {
    title: 'Změny ve skladových kartách',
    notificationType: 'danger',
    message: {
      mes1: 'Error! Při odesílání požadavků na změny skladových karet nastala chyba...',
    }
  },
  updateIPAddressSuccess: {
    title: 'Změna záznamu IP adresy',
    notificationType: 'success',
    message: {
      mes1: 'Záznam IP adresy byl úspěšně změněn!',
    }
  },
  updateIPAddressFailure: {
    title: 'Změna záznamu IP adresy',
    notificationType: 'danger',
    message: {
      mes1: 'Error! Při odesílání požadavku na změnu záznamu IP adresy nastala chyba...',
    }
  },
  changeCollectionListStatusSuccess: {
    title: 'Změna statusu svozového listu',
    notificationType: 'success',
    message: {
      mes1: 'Status svozového listu byl úspěšně změněn!',
    }
  },
  changeCollectionListStatusFailure: {
    title: 'Změna statusu svozového listu',
    notificationType: 'danger',
    message: {
      mes1: 'Error! Při změně statusu nastala chyba...',
    }
  },
  deleteCollectionListDetailItemSuccess: {
    title: 'Vymazání záznamu objednávky',
    notificationType: 'success',
    message: {
      mes1: 'Objednávka byla odebrána ze svozového listu!',
    }
  },
  deleteCollectionListDetailItemFailure: {
    title: 'Vymazání záznamu objednávky',
    notificationType: 'danger',
    message: {
      mes1: 'Error! Při odebírání objednávky ze svozového listu nastala chyba...',
    }
  },
  createCLItemFromOrderSuccess: {
    title: 'Vytvoření položek ve svozovém listu',
    notificationType: 'success',
    message: {
      mes1: 'Položky byly úspěšně přidány do vybraného svozového listu!',
    }
  },
  createCLItemFromOrderFailure: {
    title: 'Vytvoření položek ve svozovém listu',
    notificationType: 'danger',
    message: {
      mes1: 'Error! Při vytváření položek svozového listu nastala chyba, refreshujte stránku a pokuste se akci provést znovu...',
    }
  },
  updateBalikobotOrderEntrySuccess: {
    title: 'Balíkobot štítek',
    notificationType: 'success',
    message: {
      mes1: 'Štítek byl úspěšně předvyplněn!',
    }
  },
  updateBalikobotOrderEntryFailure: {
    title: 'Balíkobot štítek',
    notificationType: 'danger',
    message: {
      mes1: 'Error! Štítek nebyl úspěšně předvyplněn a nastala chyba...',
    }
  },
  generateBalikobotOrderEntrySuccess: {
    title: 'Balíkobot štítek',
    notificationType: 'success',
    message: {
      mes1: 'Štítek byl úspěšně vygenerován!',
    }
  },
  generateBalikobotOrderEntryFailure: {
    title: 'Balíkobot štítek',
    notificationType: 'danger',
    message: {
      mes1: 'Error! Při generování štítku nastala chyba...',
    }
  },
  changeBeerActiveSuccess: {
    title: 'Změna stavu piva',
    notificationType: 'success',
    message: {
      mes1: 'Stav piva byl úspěšně změněn!',
    }
  },
  changeBeerActiveFailure: {
    title: 'Změna stavu piva',
    notificationType: 'danger',
    message: {
      mes1: 'Error! Při změně stavu piva nastala chyba...',
    }
  },
  changeBeerDetailSuccess: {
    title: 'Změna detailu piva',
    notificationType: 'success',
    message: {
      mes1: 'Změny u piva byly úspěšně provedeny!',
    }
  },
  changeBeerDetailFailure: {
    title: 'Změna detailu piva',
    notificationType: 'danger',
    message: {
      mes1: 'Error! Při změnách u piva nastala chyba...',
    }
  },
  deleteBeerSuccess: {
    title: 'Smazání piva',
    notificationType: 'success',
    message: {
      mes1: 'Pivo bylo úspěšně smazáno!',
    }
  },
  deleteBeerFailure: {
    title: 'Smazání piva',
    notificationType: 'danger',
    message: {
      mes1: 'Error! Při mazání piva nastala chyba...',
    }
  },
  addCommodityToTargetSuccess: {
    title: 'Přidání do targetu',
    notificationType: 'success',
    message: {
      mes1: 'Produkty byly úspěšně přidány do vybraného targetu!',
    }
  },
  addCommodityToTargetFailure: {
    title: 'Přidání do targetu',
    notificationType: 'danger',
    message: {
      mes1: 'Error! Při přidávání produktu do vybraného targetu nastala chyba...',
    }
  },
  deleteCommodityTargetsSuccess: {
    title: 'Vymazání produktů z targetu',
    notificationType: 'success',
    message: {
      mes1: 'Produkty byly úspěšně odebrány z vybraného targetu!',
    }
  },
  deleteCommodityTargetsFailure: {
    title: 'Vymazání produktů z targetu',
    notificationType: 'danger',
    message: {
      mes1: 'Error! Při odebírání produktů z vybraného targetu nastala chyba...',
    }
  },
  createKegFromProductSuccess: {
    title: 'Vytvoření sudu z produktu',
    notificationType: 'success',
    message: {
      mes1: 'Sud byl z daného produktu úspěšně vytvořen!',
    }
  },
  createKegFromProductFailure: {
    title: 'Vytvoření sudu z produktu',
    notificationType: 'danger',
    message: {
      mes1: 'Error! Při vytváření sudu z daného produktu nastala chyba...',
    }
  },
  editCommodityTargetSuccess: {
    title: 'Editace produktu',
    notificationType: 'success',
    message: {
      mes1: 'Změny v produktu byly úspěšně uloženy!',
    }
  },
  editCommodityTargetFailure: {
    title: 'Editace produktu',
    notificationType: 'danger',
    message: {
      mes1: 'Error! Změny v produktu zřejmě nebyly uloženy!',
    }
  },
  selectedItemsUpdatedtSuccess: {
    title: 'Editace položek',
    notificationType: 'success',
    message: {
      mes1: 'Změny v položkách byly úspěšně uloženy!',
    }
  },
  selectedItemsUpdatedtFailure: {
    title: 'Editace položek',
    notificationType: 'danger',
    message: {
      mes1: 'Error! Při ukládání změn v položkách nastala chyba...',
    }
  },
  editTargetCategorySuccess: {
    title: 'Editace kategorie',
    notificationType: 'success',
    message: {
      mes1: 'Změny v kategorii byly úspěšně uloženy!',
    }
  },
  editTargetCategoryFailure: {
    title: 'Editace kategorie',
    notificationType: 'danger',
    message: {
      mes1: 'Error! Při ukládání změn v kategorii nastala chyba...',
    }
  },
  editManufacturerSuccess: {
    title: 'Editace výrobce',
    notificationType: 'success',
    message: {
      mes1: 'Změny ve výrobci byly úspěšně uloženy!',
    }
  },
  editManufacturerFailure: {
    title: 'Editace výrobce',
    notificationType: 'danger',
    message: {
      mes1: 'Error! Při ukládání změn ve výrobci nastala chyba...',
    }
  },
  generateRentingContractSuccess: {
    title: 'Generování smlouvy o zapůjčení',
    notificationType: 'success',
    message: {
      mes1: 'Smlouva o zapůjčení byla úspěšně vytvořena!',
    }
  },
  generateRentingContractFailure: {
    title: 'Generování smlouvy o zapůjčení',
    notificationType: 'danger',
    message: {
      mes1: 'Error! Při vytváření smlouvy nastala chyba...',
    }
  },
  editTargetCommodityCategorySuccess: {
    title: 'Změna kategorie u produktu',
    notificationType: 'success',
    message: {
      mes1: 'Kategorie u produktu byla úspěšně změněna!',
    }
  },
  editTargetCommodityCategoryFailure: {
    title: 'Změna kategorie u produktu',
    notificationType: 'danger',
    message: {
      mes1: 'Error! Při změně kategorie u produktu nastala chyba...',
    }
  },
  editRentingContractSuccess: {
    title: 'Změna zápůjčky',
    notificationType: 'success',
    message: {
      mes1: 'Zápůjčka byla úspěšně změněna!',
    }
  },
  editRentingContractFailure: {
    title: 'Změna zápůjčky',
    notificationType: 'danger',
    message: {
      mes1: 'Error! Při změně zápůjčky nastala chyba...',
    }
  },
  editRentingCustomerSuccess: {
    title: 'Změna zákazníka',
    notificationType: 'success',
    message: {
      mes1: 'Zákazník byl úspěšně změněn!',
    }
  },
  editRentingCustomerFailure: {
    title: 'Změna zákazníka',
    notificationType: 'danger',
    message: {
      mes1: 'Error! Při změně zákazníka nastala chyba...',
    }
  },
  createRentingDepositSuccess: {
    title: 'Záloha',
    notificationType: 'success',
    message: {
      mes1: 'Záloha byla úspěšně vytvořena!',
    }
  },
  createRentingDepositFailure: {
    title: 'Záloha',
    notificationType: 'danger',
    message: {
      mes1: 'Error! Při vytváření zálohy nastala chyba...',
    }
  },
  editRentingDepositSuccess: {
    title: 'Změna zálohy',
    notificationType: 'success',
    message: {
      mes1: 'Záloha byla úspěšně změněna!',
    }
  },
  editRentingDepositFailure: {
    title: 'Změna zálohy',
    notificationType: 'danger',
    message: {
      mes1: 'Error! Při změně zálohy nastala chyba...',
    }
  },
  deleteRentingContractSuccess: {
    title: 'Odstranění zápůjčky',
    notificationType: 'success',
    message: {
      mes1: 'Zápůjčka byla úspěšně odstraněna!',
    }
  },
  deleteRentingContractFailure: {
    title: 'Odstranění zápůjčky',
    notificationType: 'danger',
    message: {
      mes1: 'Error! Při odstraňování zápůjčky nastala chyba...',
    }
  },
  editRentinReservationSuccess: {
    title: 'Změna rezervace',
    notificationType: 'success',
    message: {
      mes1: 'Rezervace byla úspěšně změněna!',
    }
  },
  editRentinReservationFailure: {
    title: 'Změna rezervace',
    notificationType: 'danger',
    message: {
      mes1: 'Error! Při změně rezervace nastala chyba...',
    }
  },
  remindRentContractSuccess: {
    title: 'Připomenutí zápůjčky zákazníkovi',
    notificationType: 'success',
    message: {
      mes1: 'SMS a email s připomenutím byly zákazníkovi odeslány!',
    }
  },
  remindRentContractFailure: {
    title: 'Připomenutí zápůjčky zákazníkovi',
    notificationType: 'danger',
    message: {
      mes1: 'Error! Při odesílání SMS a emailu s připomenutím zákazníkovi nastala chyba...',
    }
  },
  sentEmailSuccess: {
    title: 'Odeslání emailu',
    notificationType: 'success',
    message: {
      mes1: 'Email byl úspěšně odeslán!',
    }
  },
  sentEmailFailure: {
    title: 'Odeslání emailu',
    notificationType: 'danger',
    message: {
      mes1: 'Error! Při odesílání emailu nastala chyba...',
    }
  },
  editTemplateSuccess: {
    title: 'Změna šablony',
    notificationType: 'success',
    message: {
      mes1: 'Šablona byla úspěšně upravena!',
    }
  },
  editTemplateFailure: {
    title: 'Změna šablony',
    notificationType: 'danger',
    message: {
      mes1: 'Error! Při úpravě šablony nastala chyba...',
    }
  },
  subscriptionOrderSuccess: {
    title: 'Předplatné',
    notificationType: 'success',
    message: {
      mes1: 'Objednávky předplatného byly úspěšně vytvořeny!',
    }
  },
  subscriptionOrderFailure: {
    title: 'Předplatné',
    notificationType: 'danger',
    message: {
      mes1: 'Error! Při vytváření objednávek předplatného nastala chyba...',
    }
  },
  deleteSubscriptionOrderSuccess: {
    title: 'Předplatné',
    notificationType: 'success',
    message: {
      mes1: 'Objednávka předplatného byla úspěšně vymazána!',
    }
  },
  deleteSubscriptionOrderFailure: {
    title: 'Předplatné',
    notificationType: 'danger',
    message: {
      mes1: 'Error! Při mazání objednávky předplatného nastala chyba...',
    }
  },
  setOrderAddressSuccess: {
    title: 'Objednávka',
    notificationType: 'success',
    message: {
      mes1: 'Adresa objednávky byla úspěšně nastavena!',
    }
  },
  setOrderAddressFailure: {
    title: 'Objednávka',
    notificationType: 'danger',
    message: {
      mes1: 'Error! Při nastavování adresy objednávky nastala chyba...',
    }
  },
  editSubscriptionOrderSuccess: {
    title: 'Předplatné',
    notificationType: 'success',
    message: {
      mes1: 'Objednávka předplatného byla úspěšně změněna!',
    }
  },
  editSubscriptionOrderFailure: {
    title: 'Předplatné',
    notificationType: 'danger',
    message: {
      mes1: 'Error! Při změně objednávky předplatného nastala chyba...',
    }
  },
};
