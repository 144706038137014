import { useSelector } from 'react-redux'

export function useAuthSelectors() {
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated)
  const token = useSelector(state => state.auth.token)
  const sessionId = useSelector(state => state.auth.sessionId)
  const userId = useSelector(state => state.auth.userId)
  const userUid = useSelector(state => state.auth.userUid)
  const fullName = useSelector(state => state.auth.fullName)
  const nameShortFirst = useSelector(state => state.auth.nameShortFirst)
  const idm = useSelector(state => state.auth.idm)
  const resMessage = useSelector(state => state.auth.resMessage)

  return {
    isAuthenticated,
    token,
    sessionId,
    userId,
    userUid,
    fullName,
    nameShortFirst,
    idm,
    resMessage,
  }
}
