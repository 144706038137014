import { useDispatch } from 'react-redux'
import { useCallback } from 'react'

import * as actions from './transfers.actions'
import { useTransfersSelectors } from './transfers.selectors'

export function useTransfersFacade() {
  const dispatch = useDispatch();
  const selectors = useTransfersSelectors();

  const {
    selectTransferWays: transferWays,
    selectResMessage: transfersResMessage,
    selectTransfersPageData: transfersPageData,
    selectTransfersPageDraw: transfersPageDraw,
    selectTransfersTotal: transfersTotal,
    selectTransfersPagePage: transfersCurrentPage,
    selectGeneratedPdfDocument: transfersGeneratedPdfDocument,
    selectSalesRequests: salesRequests,
    selectRelatedSRIStoreCards: relatedSRIStoreCards,

    selectFinishedTransfersBadgeCount: finishedTransfersBadgeCount,

    selectSelectedTransfer: selectedTransfer,
    selectItemsPageData: itemsPageData,
    selectItemsPageDraw: itemsPageDraw,
    selectItemsPagePage: itemsPagePage,
    selectItemsPageLimit: itemsPageLimit,
    selectItemsPageTotal: itemsPageTotal,
    selectRelatedStoreCards: transferRelatedStoreCards,
    selectNewCreatedTransferItem: newCreatedTransferItem,

    selectSalesRequestsItemsPageData: salesRequestsItemsPageData,
    selectSalesRequestsItemsPageDraw: salesRequestsItemsPageDraw,
    selectSalesRequestsItemsPagePage: salesRequestsItemsPagePage,
    selectSalesRequestsItemsPageLimit: salesRequestsItemsPageLimit,
    selectSalesRequestsItemsTotal: salesRequestsItemsTotal,

    selectSalesRequestsItemsQueuedPageData: salesRequestsItemsQueuedPageData,
    selectSalesRequestsItemsQueuedPageDraw: salesRequestsItemsQueuedPageDraw,
    selectSalesRequestsItemsQueuedPagePage: salesRequestsItemsQueuedPagePage,
    selectSalesRequestsItemsQueuedPageLimit: salesRequestsItemsQueuedPageLimit,
    selectSalesRequestsItemsQueuedTotal: salesRequestsItemsQueuedTotal,
    selectSalesRequestsItemsCheckedPageData: salesRequestsItemsCheckedPageData,
    selectSalesRequestsItemsCheckedPageDraw: salesRequestsItemsCheckedPageDraw,
    selectSalesRequestsItemsCheckedPagePage: salesRequestsItemsCheckedPagePage,
    selectSalesRequestsItemsCheckedPageLimit: salesRequestsItemsCheckedPageLimit,
    selectSalesRequestsItemsCheckedTotal: salesRequestsItemsCheckedTotal,
  } = selectors;

  const fetchTransferWays = useCallback(
    () =>
      dispatch(actions.transfersTransferWaysRequest()),
    [dispatch]
  );

  const transfersResetResponseMessage = useCallback(
    () =>
      dispatch(actions.transfersResponseMessageReset()),
    [dispatch]
  );

  const fetchTransfersPageData = useCallback(
    (
      draw=1,
      page=0,
      transferWayId=null,
      search=null,
      status=null,
    ) =>
      dispatch(actions.trasnfersListPageRequest(draw, page, transferWayId, search, status)),
    [dispatch]
  );

  const fetchSelectedTransferItems = useCallback(
    (
      transferQueueId,
      draw=1,
      page=0,
      mask=null,
      filterEanPlu=null,
      limit=20,
    ) =>
      dispatch(actions.transferDetailItemsListPageRequest(
        transferQueueId, draw, page, mask, filterEanPlu, limit
      )),
    [dispatch]
  );

  const createNewTransfer = useCallback(
    (
      partnerUid,
      transferWayId,
      ident,
      // Partner SYSTEM as default, set in TransfersLayout
      carrierPartnerUid,
      dateDue=Date.now()
    ) =>
      dispatch(actions.createNewTransferRequest(
        partnerUid, transferWayId, ident, carrierPartnerUid, dateDue
      )),
    [dispatch]
  );

  const changeTransferStatus = useCallback(
    (
      transferId,
      newStatus,
      ident,
      // SYSTEM as default
      partnerUsername='SYSTEM',
    ) =>
      dispatch(actions.changeTransferStatusRequest(
        transferId, newStatus, ident, partnerUsername
      )),
    [dispatch]
  );

  const createTransferDetailItem = useCallback(
    (
      transferQueueId,
      // only one of plu or ean is needed
      plu=null,
      ean=null,
      // quantity is set in action by default,
      // it is supposed to be zero, quantity is later updated
      // in the upcoming request
    ) =>
      dispatch(actions.transferDetailCreateItemRequest(
        transferQueueId, plu, ean,
      )),
    [dispatch]
  );

  const updateTransferDetailItemQuantity = useCallback(
    (transferQueueItemsId, quantity) =>
      dispatch(
        actions.transferDetailUpdateItemQuantityRequest(
          transferQueueItemsId,
          quantity
        )
      ),
    [dispatch]
  );

  const nullifyNewCreatedTransferDetailItem = useCallback(
    () =>
      dispatch(actions.nullifyNewCreatedTransferItem()),
    [dispatch]
  );

  const createTransferDetailItemFromOrder = useCallback(
    (orderItemsId, transferWayId, quantity) =>
      dispatch(
        actions.transferDetailCreateItemFromOrderRequest(
          orderItemsId,
          transferWayId,
          quantity
        )
      ),
    [dispatch]
  );

  const processTransferDetailItem = useCallback(
    transferQueueItemsId =>
      dispatch(
        actions.processTransferDetailItemRequest(
          transferQueueItemsId
        )
      ),
    [dispatch]
  );

  const deleteTransferDetailItem = useCallback(
    transferQueueItemsId =>
      dispatch(actions.transferDetailItemDeleteRequest(
        transferQueueItemsId
      )),
    [dispatch]
  );

  const deleteTransfer = useCallback(
    transferQueueId =>
      dispatch(actions.transferDeleteRequest(
        transferQueueId
      )),
    [dispatch]
  );

  const updateTransfer = useCallback(
    updatedTransfer =>
      dispatch(actions.transferUpdateRequest(
        updatedTransfer
      )),
    [dispatch]
  );

  const updateTransferNote = useCallback(
    (transferQueueId, note) =>
      dispatch(actions.transferUpdateNoteRequest(
        transferQueueId, note
      )),
    [dispatch]
  );

  const updateTransferItemNote = useCallback(
    (transferQueueItemsId, note) =>
      dispatch(actions.transferItemUpdateNoteRequest(
        transferQueueItemsId, note
      )),
    [dispatch]
  );

  const printTransferDocument = useCallback(
    (type, itemId, userUid, ident) =>
      dispatch(actions.transfersPrintDocumentRequest(type, itemId, userUid, ident)),
    [dispatch]
  );

  const generateTransfersTypePdfDocument = useCallback(
    (type, itemId, userUid, ident) =>
      dispatch(actions.transfersGeneratePdfDocumentRequest(type, itemId, userUid, ident)),
    [dispatch]
  );

  const resetTransfersGeneratedPdf = useCallback(
    () =>
      dispatch(actions.transfersResetGeneratedPdf()),
    [dispatch]
  );

  const fetchSalesRequests = useCallback(
    (draw=1, page=0, limit=100, search=null) =>
      dispatch(actions.transfersSalesRequestsRequest(draw, page, limit, search)),
    [dispatch]
  );

  const fetchSalesRequestsItems = useCallback(
    (draw=1, page=0, limit=100, search=null, salesRequestId, storeId=null) =>
      dispatch(actions.transfersSalesRequestsItemsRequest(
        draw, page, limit, search, salesRequestId, storeId
      )),
    [dispatch]
  );

  const fetchSalesRequestsItemsQueuedAndChecked = useCallback(
    /* params template:

      draw,
      page,
      limit,
      search,
      status,
      salesRequestId,
      storeId,
    */
      (
        queuedTableParams, checkedTableParams,
        fetchOptions={ fetchQueuedList: true, fetchCheckedList: true }
      ) =>
        dispatch(actions.transfersSalesRequestsItemsQueuedAndCheckedRequest(
          queuedTableParams, checkedTableParams, fetchOptions
        )),
      [dispatch]
    );

  const deleteSalesRequestsItem = useCallback(
    (salesRequestsItemId) =>
      dispatch(actions.transfersDeleteSalesRequestsItemRequest(salesRequestsItemId)),
    [dispatch]
  );

  const updateSalesRequestsItem = useCallback(
    (salesRequestsItemId, updatedSRI) =>
      dispatch(actions.transfersUpdateSalesRequestsItemRequest(
        salesRequestsItemId, updatedSRI
      )),
    [dispatch]
  );

  const changeTransferQueueOfTransferDetailItem = useCallback(
    (transferQueueItemsId, transferQueueId) =>
      dispatch(actions.transferDetailItemChangeTransferQueueRequest(
        transferQueueItemsId, transferQueueId
      )),
    [dispatch]
  );

  return {
    transferWays,
    transfersResMessage,
    transfersPageData,
    transfersPageDraw,
    transfersTotal,
    transfersCurrentPage,
    transfersGeneratedPdfDocument,
    salesRequests,
    relatedSRIStoreCards,

    selectedTransfer,
    itemsPageData,
    itemsPageDraw,
    itemsPagePage,
    itemsPageLimit,
    itemsPageTotal,
    transferRelatedStoreCards,
    newCreatedTransferItem,

    salesRequestsItemsPageData,
    salesRequestsItemsPageDraw,
    salesRequestsItemsPagePage,
    salesRequestsItemsPageLimit,
    salesRequestsItemsTotal,

    salesRequestsItemsQueuedPageData,
    salesRequestsItemsQueuedPageDraw,
    salesRequestsItemsQueuedPagePage,
    salesRequestsItemsQueuedPageLimit,
    salesRequestsItemsQueuedTotal,
    salesRequestsItemsCheckedPageData,
    salesRequestsItemsCheckedPageDraw,
    salesRequestsItemsCheckedPagePage,
    salesRequestsItemsCheckedPageLimit,
    salesRequestsItemsCheckedTotal,

    finishedTransfersBadgeCount,

    fetchTransferWays,
    transfersResetResponseMessage,
    fetchTransfersPageData,
    fetchSelectedTransferItems,
    createNewTransfer,
    changeTransferStatus,
    createTransferDetailItem,
    updateTransferDetailItemQuantity,
    nullifyNewCreatedTransferDetailItem,
    createTransferDetailItemFromOrder,
    processTransferDetailItem,
    deleteTransferDetailItem,
    deleteTransfer,
    updateTransfer,
    updateTransferNote,
    updateTransferItemNote,
    printTransferDocument,
    generateTransfersTypePdfDocument,
    resetTransfersGeneratedPdf,
    fetchSalesRequests,
    fetchSalesRequestsItems,
    fetchSalesRequestsItemsQueuedAndChecked,
    deleteSalesRequestsItem,
    updateSalesRequestsItem,
    changeTransferQueueOfTransferDetailItem,
  }
}
