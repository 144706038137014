import * as actionTypes from './store-cards.actionTypes'
import * as actions from './store-cards.actions'
import { AirshopApi } from '../../CraftApi.axios'
import { from, of, forkJoin } from 'rxjs'
import { mergeMap, takeUntil, catchError, map } from 'rxjs/operators'
import { ofType } from 'redux-observable'
import _ from 'lodash'
import { err, errMessages } from 'redux-store/utils/epics.utils'

export const fetchStoreCardsModalListPageEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.STORE_CARDS_LIST_REQUEST),
    mergeMap(action => {
      const {
        draw,
        page,
        limit,
      } = action.payload;

      const optionalParams = {};
      Object.entries(action.payload).forEach(item => {
        const [key, value] = item;
        if (!(value === draw || value === page || value === limit)) {
          optionalParams[key] = value;
        }
      });

      return from(AirshopApi.get('storeCards', {
        params: {
          draw,
          page: page * limit,
          limit,
          order: 'code',
          sort: 'ASC',
          ...optionalParams,
        }
      }))
      .pipe(
          map(response => response.data.status === 'OK' ? response.data : err(errMessages.NOT_OK, response)),

          map(tablePageData => actions.storeCardsListPageResponse({items: tablePageData.items, total: tablePageData.total})),
          catchError(error => of(actions.storeCardsListPageFailure(error))
          ),
          takeUntil(action$.ofType(
            actionTypes.STORE_CARDS_LIST_RESPONSE ||
              actionTypes.STORE_CARDS_LIST_FAILURE
          ))
      )
    })
  );

export const fetchStoreCardsSectionListPageEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.STORE_CARDS_SECTION_LIST_REQUEST),
    mergeMap(action => {
      const {
        draw,
        page,
        limit,
        sort,
        order,
      } = action.payload;

      const optionalParams = {};
      Object.entries(action.payload).forEach(item => {
        const [key, value] = item;
        if (!(value === draw || value === page || value === limit)) {
          optionalParams[key] = value;
        }
      });

      return from(AirshopApi.get('storeCards', {
        params: {
          draw,
          page: page * limit,
          limit,
          sort: sort ? sort : 'ASC',
          ...optionalParams,
          order: order ? order : 'name',
        }
      }))
      .pipe(
          map(response => response.data.status === 'OK' ? response.data : err(errMessages.NOT_OK, response)),

          map(tablePageData => actions.storeCardsSectionListPageResponse({items: tablePageData.items, total: tablePageData.total})),
          catchError(error => of(actions.storeCardsSectionListPageFailure(error))
          ),
          takeUntil(action$.ofType(
            actionTypes.STORE_CARDS_SECTION_LIST_RESPONSE ||
              actionTypes.STORE_CARDS_SECTION_LIST_FAILURE
          ))
      )
    })
  );

export const fetchRelatedStoreCardsEansEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.STORE_CARDS_EANS_REQUEST),
    mergeMap(action => {
      const { storeCardsIdsArr, tableSoureId } = action.payload;
      if (storeCardsIdsArr && storeCardsIdsArr.length > 0) {
        return forkJoin(
          storeCardsIdsArr.map(id => AirshopApi.get('storeCardEans/sStoreCardsId', {
            params: {
              sStoreCardsId: id,
            }
          }))
        )
        .pipe(
            map(response => {
              let eans = {};
              response.forEach(item => {
                if (item.data.status === 'OK' && !!item.data.items.length) {
                  // to make eans property, map it by first item's storeCardsId
                  // since all the items have the same storeCardsId
                  eans[item.data.items[0].storeCardsId] = item.data.items;
                }
              });
              return eans;
            }),
            map(eans => actions.storeCardsEansResponse(eans, tableSoureId)),
            catchError(error => of(actions.storeCardsEansFailure(error))
            ),
            takeUntil(action$.ofType(
              actionTypes.STORE_CARDS_EANS_RESPONSE ||
                actionTypes.STORE_CARDS_EANS_FAILURE
            ))
        )
      } else {
        // if no store cards sent in request call, sent empty eans res back
        return of([]).pipe(map(emptyArr => actions.storeCardsEansResponse(emptyArr, tableSoureId)))
      }
    })
  );

export const changeBulkExportQuantityNullEpic = (action$) =>
  action$.pipe(
    ofType(actionTypes.STORE_CARDS_BULK_CHANGE_EXPORT_NULL_QUANTITY_REQUEST),
    mergeMap((action) => {
      const { storeCardsIds, nullifyQuantity } = action.payload;
      return forkJoin(storeCardsIds.map(storeCardId =>
        AirshopApi.get('storeCards/changeXExportNullQuantity', {
          params: {
            id: storeCardId,
            nullQuantity: nullifyQuantity,
          },
        }))
      ).pipe(
        map((response) =>
          actions.storeCardsBulkChangeExportNullQuantityResponse(
            response,
            actionTypes.STORE_CARDS_BULK_CHANGE_EXPORT_NULL_QUANTITY_RESPONSE
          )
        ),
        catchError((error) =>
          of(
            actions.storeCardsBulkChangeExportNullQuantityFailure(
              error,
              actionTypes.STORE_CARDS_BULK_CHANGE_EXPORT_NULL_QUANTITY_FAILURE
            )
          )
        ),
        takeUntil(
          action$.ofType(
            actionTypes.STORE_CARDS_BULK_CHANGE_EXPORT_NULL_QUANTITY_RESPONSE ||
              actionTypes.STORE_CARDS_BULK_CHANGE_EXPORT_NULL_QUANTITY_FAILURE
          )
        )
      )
    })
  );

export const fetchRelatedStoreCardsBottleTypesEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.STORE_CARDS_BOTTLE_TYPES_BY_SC_REQUEST),
    mergeMap(action => {
      const { bottleTypesIdsByStoreCardsIds, tableSoureId } = action.payload;
      let uniqueBottleTypesArr = _.uniq(Object.values(bottleTypesIdsByStoreCardsIds));
      // 0 type means it is not a bottle, therefore, do not fetch it
      uniqueBottleTypesArr = uniqueBottleTypesArr.filter(item => !!item);
      if (!!uniqueBottleTypesArr.length) {
        // CASE WITH SOEM BOTTLE TYPES
        return forkJoin(
          uniqueBottleTypesArr.map(id => AirshopApi.get(`bottleType/${id}`))
        )
        .pipe(
            map(response => {
              let bottleTypesByStoreCardIds = {};
              let bottleTypesById = {};
              response.forEach(item => {
                if (item.status === 200) {
                  bottleTypesById[item.data.id] = item.data;
                }
              });
              for (const [storeCardIdAsKey, bottleTypeId] of Object.entries(bottleTypesIdsByStoreCardsIds)) {
                bottleTypesByStoreCardIds[storeCardIdAsKey] = bottleTypesById[bottleTypeId];
              };

              return bottleTypesByStoreCardIds;
            }),
            map(bottleTypesByStoreCardIds => actions.storeCardsBottleTypesBySCResponse(bottleTypesByStoreCardIds, tableSoureId)),
            catchError(error => of(actions.storeCardsBottleTypesBySCFailure(error))
            ),
            takeUntil(action$.ofType(
              actionTypes.STORE_CARDS_BOTTLE_TYPES_BY_SC_RESPONSE ||
                actionTypes.STORE_CARDS_BOTTLE_TYPES_BY_SC_FAILURE
            ))
        )
      } else {
        // CASE THERE IS NO BOTTLE TYPE
        return of(null).pipe(
          map(() => actions.storeCardsBottleTypesBySCResponse({}, tableSoureId)),
          takeUntil(action$.ofType(actionTypes.STORE_CARDS_BOTTLE_TYPES_BY_SC_RESPONSE))
        )
      }
    })
  );

export const fetchProductInOrdersListEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.STORE_CARDS_PRODUCT_IN_ORDERS_LIST_REQUEST),
    mergeMap(action => {
      const { draw, page, limit, stavObjednavkyIds, search } = action.payload;

      return from(AirshopApi.get('storeCards/orderedByStavObjednavkyIds', {
        params: {
          page: page * limit,
          draw,
          limit,
          stavObjednavkyIds,
          search,
          sort: 'DESC',
          order: 'date_created',
        }
      }))
      .pipe(
          map(res => res.data.status === 'OK' ? res.data : err(errMessages.NOT_OK, res)),
          map(tablePageData => actions.storeCardsProductInOrdersListResponse({
            items: tablePageData.items, total: tablePageData.total
          })),
          catchError(error => of(actions.storeCardsProductInOrdersListFailure(error))),
          takeUntil(action$.ofType(
            actionTypes.STORE_CARDS_PRODUCT_IN_ORDERS_LIST_RESPONSE ||
              actionTypes.STORE_CARDS_PRODUCT_IN_ORDERS_LIST_FAILURE
          ))
      )
    })
  );

export const fetchStoreCardsConsumptionTaxListPageEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.STORE_CARDS_EDITABLE_CONSUMPTION_TAX_LIST_REQUEST),
    mergeMap(action => {
      const {
        draw,
        page,
        limit,
      } = action.payload;

      const optionalParams = {};
      Object.entries(action.payload).forEach(item => {
        const [key, value] = item;
        if (!(value === draw || value === page || value === limit)) {
          optionalParams[key] = value;
        }
      });

      return from(AirshopApi.get('storeCards', {
        params: {
          draw,
          page,
          limit,
          order: 'code',
          sort: 'ASC',
          ...optionalParams,
        }
      }))
      .pipe(
          map(response => response.data.status === 'OK' ? response.data : err(errMessages.NOT_OK, response)),

          map(tablePageData => actions.storeCardsEditableConsumptionTaxListPageResponse(
            {items: tablePageData.items, total: tablePageData.total}
          )),
          catchError(error => of(actions.storeCardsEditableConsumptionTaxListPageFailure(error))
          ),
          takeUntil(action$.ofType(
            actionTypes.STORE_CARDS_EDITABLE_CONSUMPTION_TAX_LIST_RESPONSE ||
              actionTypes.STORE_CARDS_EDITABLE_CONSUMPTION_TAX_LIST_FAILURE
          ))
      )
    })
  );

export const fetchBottleTypesListEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.STORE_CARDS_BOTTLE_TYPE_LIST_REQUEST),
    mergeMap(action => from(AirshopApi.get('bottleType'))
      .pipe(
          map(response => response.data.status = 'OK' ? response.data.items : err(errMessages.NOT_OK, response)),
          map(bottleTypesList => {
            const bottleTypesByIds = [];
            bottleTypesList.forEach(item => bottleTypesByIds[item.id] = { id: item.id, name: item.name});
            return actions.storeCardsBottleTypesListResponse(
              bottleTypesByIds
          )}),
          catchError(error => of(actions.storeCardsBottleTypesListFailure(
            error
          ))
          ),
          takeUntil(action$.ofType(
            actionTypes.STORE_CARDS_BOTTLE_TYPE_LIST_RESPONSE ||
              actionTypes.STORE_CARDS_BOTTLE_TYPE_LIST_FAILURE
          ))
      )
    )
  );

export const editStoreCardEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.STORE_CARDS_EDIT_STORE_CARD_REQUEST),
    mergeMap(action => from(AirshopApi.put('storeCards/update', action.payload, {
      params: { storeCardsId: action.payload.id }
    }))
      .pipe(
          map(response => response.data.status = 'OK' ? response : err(errMessages.NOT_OK, response)),
          map(response => actions.storeCardsEditStoreCarResponse(
            response,
            actionTypes.STORE_CARDS_EDIT_STORE_CARD_RESPONSE
          )),
          catchError(error => of(actions.storeCardsEditStoreCarFailure(
            error,
            actionTypes.STORE_CARDS_EDIT_STORE_CARD_FAILURE
          ))),
          takeUntil(action$.ofType(
            actionTypes.STORE_CARDS_EDIT_STORE_CARD_RESPONSE ||
              actionTypes.STORE_CARDS_EDIT_STORE_CARD_FAILURE
          ))
      )
    )
  );

export const deleteEanEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.STORE_CARDS_DELETE_EAN_REQUEST),
    mergeMap(action => {
      const { storeCardId, ean, userUid, ident } = action.payload;

      return from(AirshopApi.get('storeCards/deleteEan', {
        params: {
          storeCardsId: storeCardId,
          ean,
          ident,
          uid: userUid,
        }
    }))
      .pipe(
          map(response => response.data.status = 'OK' ? response : err(errMessages.NOT_OK, response)),
          map(response => actions.storeCardsDeleteEanResponse(
            response,
            actionTypes.STORE_CARDS_DELETE_EAN_RESPONSE
          )),
          catchError(error => of(actions.storeCardsDeleteEanFailure(
            error,
            actionTypes.STORE_CARDS_DELETE_EAN_FAILURE
          ))),
          takeUntil(action$.ofType(
            actionTypes.STORE_CARDS_DELETE_EAN_RESPONSE ||
              actionTypes.STORE_CARDS_DELETE_EAN_FAILURE
          ))
      )
    })
  );

export const editMultipleStoreCardsEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.STORE_CARDS_EDIT_MULTIPLE_STORE_CARDS_REQUEST),
    mergeMap(action => {
      const storeCards = action.payload;
      return forkJoin(
        storeCards.map(storeCard => AirshopApi.put('storeCards/update', storeCard, {
          params: {
            storeCardsId: storeCard.id,
          }
        }))
      )
      .pipe(
          map(responses => {
            let hasErrored = false;
            responses.forEach(res => {
              if (res.data.status !== 'OK') { hasErrored = true; }
            });

            if (hasErrored) {
              return err(errMessages.NOT_OK, responses)
            } else {
              return responses;
            }
          }),
          map(response => actions.storeCardsEditMultipleStoreCardsResponse(
            response,
            actionTypes.STORE_CARDS_EDIT_MULTIPLE_STORE_CARDS_RESPONSE
          )),
          catchError(error => of(actions.storeCardsEditMultipleStoreCardsFailure(
            error,
            actionTypes.STORE_CARDS_EDIT_MULTIPLE_STORE_CARDS_FAILURE
          ))),
          takeUntil(action$.ofType(
            actionTypes.STORE_CARDS_EDIT_MULTIPLE_STORE_CARDS_RESPONSE ||
              actionTypes.STORE_CARDS_EDIT_MULTIPLE_STORE_CARDS_FAILURE
          ))
      )
    })
  );

export const fetchStoreCardsForFBFeedEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.STORE_CARDS_FB_FEEDS_LIST_REQUEST),
    mergeMap(action => {
      const { shop } = action.payload;
      return from(AirshopApi.get('fixer', {
        params: {
          draw: 1,
          page: 0,
          limit: 200,
          shop,
          order: 'date_createdat',
          sort: 'DESC',
        }
    }))
      .pipe(
          map(response => response.data.status = 'OK' ? response.data.items : err(errMessages.NOT_OK, response)),
          map(response => actions.storeCardsForFBFeedResponse(
            response,
          )),
          catchError(error => of(actions.storeCardsForFBFeedFailure(
            error,
          ))),
          takeUntil(action$.ofType(
            actionTypes.STORE_CARDS_FB_FEEDS_LIST_RESPONSE ||
              actionTypes.STORE_CARDS_FB_FEEDS_LIST_FAILURE
          ))
      )
    })
  );
