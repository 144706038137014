import * as actionTypes from './rating.actionTypes'

export function ratingResMessageReset() {
  return {
    type: actionTypes.RATING_RESPONSE_MESSAGE_RESET,
  };
}

// Websites with beers list page fetching
export function ratingBeersWithWebsitesPageRequest(
  draw,
  page,
  limit,
  sort,
  search,
) {
  return {
    type: actionTypes.RATING_BEERS_WITH_WEBSITES_REQUEST,
    payload: { draw, page, limit, sort, search }
  };
}

export function ratingBeersWithWebsitesPageResponse(
  response
) {
  return {
    type: actionTypes.RATING_BEERS_WITH_WEBSITES_RESPONSE,
    payload: response
  };
}

export function ratingBeersWithWebsitesPageFailure(
  error
) {
  return {
    type: actionTypes.RATING_BEERS_WITH_WEBSITES_FAILURE,
    payload: error
  };
}

export function ratingWebsitesListRequest() {
  return { type: actionTypes.RATING_WEBSITES_LIST_REQUEST }
}

export function ratingWebsitesListResponse(response) {
  return {
    type: actionTypes.RATING_WEBSITES_LIST_RESPONSE,
    payload: response
  }
}

export function ratingWebsitesListFailure(error) {
  return {
    type: actionTypes.RATING_WEBSITES_LIST_FAILURE,
    payload: error
  }
}

export function ratingBeerByIdRequest(beerId) {
  return {
    type: actionTypes.RATING_BEER_BY_ID_REQUEST,
    payload: beerId
  }
}

export function ratingBeerByIdResponse(response) {
  return {
    type: actionTypes.RATING_BEER_BY_ID_RESPONSE,
    payload: response
  }
}

export function ratingBeerByIdFailure(error) {
  return {
    type: actionTypes.RATING_BEER_BY_ID_FAILURE,
    payload: error
  }
}

export function ratingUpdateBeerByIdRequest(beerId, updatedBeer) {
  return {
    type: actionTypes.RATING_UPDATE_BEER_BY_ID_REQUEST,
    payload: { beerId, updatedBeer }
  }
}

export function ratingUpdateBeerByIdResponse(response) {
  return {
    type: actionTypes.RATING_UPDATE_BEER_BY_ID_RESPONSE,
    payload: response
  }
}

export function ratingUpdateBeerByIdFailure(error) {
  return {
    type: actionTypes.RATING_UPDATE_BEER_BY_ID_FAILURE,
    payload: error
  }
}

export function ratingDeleteBeerByIdRequest(beerId) {
  return {
    type: actionTypes.RATING_DELETE_BEER_BY_ID_REQUEST,
    payload: beerId
  }
}

export function ratingDeleteBeerByIdResponse(response) {
  return {
    type: actionTypes.RATING_DELETE_BEER_BY_ID_RESPONSE,
    payload: response
  }
}

export function ratingDeleteBeerByIdFailure(error) {
  return {
    type: actionTypes.RATING_DELETE_BEER_BY_ID_FAILURE,
    payload: error
  }
}

export function ratingToggleActiveBeerByIdRequest(beerId) {
  return {
    type: actionTypes.RATING_TOGGLE_ACTIVE_BEER_BY_ID_REQUEST,
    payload: beerId
  }
}

export function ratingToggleActiveBeerByIdResponse(response, resMessage) {
  return {
    type: actionTypes.RATING_TOGGLE_ACTIVE_BEER_BY_ID_RESPONSE,
    payload: { response, resMessage }
  }
}

export function ratingToggleActiveBeerByIdFailure(error, resMessage) {
  return {
    type: actionTypes.RATING_TOGGLE_ACTIVE_BEER_BY_ID_FAILURE,
    payload: { error, resMessage }
  }
}


export function ratingBeerWithWebsitesByBeerIdRequest(beerId) {
  return {
    type: actionTypes.RATING_BEER_WITH_WEBSITES_BY_BEER_ID_REQUEST,
    payload: beerId
  }
}

export function ratingBeerWithWebsitesByBeerIdResponse(response) {
  return {
    type: actionTypes.RATING_BEER_WITH_WEBSITES_BY_BEER_ID_RESPONSE,
    payload: response
  }
}

export function ratingBeerWithWebsitesByBeerIdFailure(error) {
  return {
    type: actionTypes.RATING_BEER_WITH_WEBSITES_BY_BEER_ID_FAILURE,
    payload: error
  }
}

export function ratingCreateBeerWithWebsitesRequest(newBeerWithWebsites) {
  return {
    type: actionTypes.RATING_CREATE_BEER_WITH_WEBSITES_REQUEST,
    payload: newBeerWithWebsites
  }
}

export function ratingCreateBeerWithWebsitesResponse(response) {
  return {
    type: actionTypes.RATING_CREATE_BEER_WITH_WEBSITES_RESPONSE,
    payload: response
  }
}

export function ratingCreateBeerWithWebsitesFailure(error) {
  return {
    type: actionTypes.RATING_CREATE_BEER_WITH_WEBSITES_FAILURE,
    payload: error
  }
}

export function ratingUpdateBeerWithWebsitesByIdRequest(
  beerWithWebsitesId, updatedBeerWithWebsites
) {
  return {
    type: actionTypes.RATING_UPDATE_BEER_WITH_WEBSITES_BY_ID_REQUEST,
    payload: { beerWithWebsitesId, updatedBeerWithWebsites }
  }
}

export function ratingUpdateBeerWithWebsitesByIdResponse(response) {
  return {
    type: actionTypes.RATING_UPDATE_BEER_WITH_WEBSITES_BY_ID_RESPONSE,
    payload: response
  }
}

export function ratingUpdateBeerWithWebsitesByIdFailure(error) {
  return {
    type: actionTypes.RATING_UPDATE_BEER_WITH_WEBSITES_BY_ID_FAILURE,
    payload: error
  }
}

export function ratingDeleteBeerWithWebsitesByIdRequest(
  beerWithWebsitesId
) {
  return {
    type: actionTypes.RATING_DELETE_BEER_WITH_WEBSITES_BY_ID_REQUEST,
    payload: beerWithWebsitesId
  }
}

export function ratingDeleteBeerWithWebsitesByIdResponse(
  response
) {
  return {
    type: actionTypes.RATING_DELETE_BEER_WITH_WEBSITES_BY_ID_RESPONSE,
    payload: response
  }
}

export function ratingDeleteBeerWithWebsitesByIdFailure(
  error
) {
  return {
    type: actionTypes.RATING_DELETE_BEER_WITH_WEBSITES_BY_ID_FAILURE,
    payload: error
  }
}

export function ratingBeerWithWebsitesMultipleRequestsRequest(
  POST_newWebsites, PUT_editedExistingWebsites, DELETE_existingWebsitesIdsArr, PUT_editedBeer
) {
  return {
    type: actionTypes.RATING_BEER_WITH_WEBSITES_MULTI_REQUESTS_REQUEST,
    payload: {
      POST_newWebsites, PUT_editedExistingWebsites, DELETE_existingWebsitesIdsArr, PUT_editedBeer
    }
  }
}

export function ratingBeerWithWebsitesMultipleRequestsResponse(
  response, resMessage
) {
  return {
    type: actionTypes.RATING_BEER_WITH_WEBSITES_MULTI_REQUESTS_RESPONSE,
    payload: { response, resMessage }
  }
}

export function ratingBeerWithWebsitesMultipleRequestsFailure(
  error, resMessage
) {
  return {
    type: actionTypes.RATING_BEER_WITH_WEBSITES_MULTI_REQUESTS_FAILURE,
    payload: { error, resMessage }
  }
}

