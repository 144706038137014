import { useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'

export const useRouteState = () => {
  const location = useLocation()

  const routeRef = useRef({
    to: null,
    from: null,
  });

  const route = routeRef.current;

  useEffect(()=> {
    routeRef.current = {
      to: location.pathname,
      from: routeRef.current.to
    };
  }, [location]);

  return { route }
}

