import React from 'react'
import {
  Menu,
  Item,
  Separator,
  Submenu,
} from 'react-contexify'
import 'react-contexify/dist/ReactContexify.css'

import { IContextMenuProps, IContextMenuItem } from 'models'

const ContextMenu = ({ id, menuItems }: IContextMenuProps) => {

  // use contextMenu.show in the parent to trigger the menu,
  // example FROM AppWideOrdersContextContainer:
  //
  // contextMenu.show({
  //   id: ORDERS_STATES_CONTEXT_MENU_ID.current,
  //   event: event,
  // });

  const itemClickHandler = (event: any, props: any, item: IContextMenuItem) => {
    if (item.onClick) {
      item.onClick(event, props, item);
    } else {
      return;
    }
  }

  const Icon = ({icon, style={}}: {icon: string, style?: Object}) => (
    <span className="material-icons pr-2" style={{opacity: 0.5, ...style}}>
      {icon}
    </span>
  )

  const ItemComponent = ({item, i}: {item: IContextMenuItem, i: number}) => (
    <Item
      // event and props set in contextMenu in parent component
      onClick={({event, props=null}) => itemClickHandler(event, props, item)}
      disabled={item.disabled}
      key={i}
    >
      {item.icon && <Icon icon={item.icon} />}
      {item.label}
    </Item>
  )
  
  const getSubmenuChildren = (item: IContextMenuItem) => {
    let children: IContextMenuItem[] = []
    if (typeof item?.children === 'function') {
      children = item.children()
    } else if (typeof item?.children === 'object') {
      children = item.children
    }
    return children?.length && children?.map((subItem: IContextMenuItem, j: number) => (
    <ItemComponent item={subItem} i={j}/>
  ))
  }

  return (
    <Menu id={id}>
      {menuItems.map((item, i) =>
        item.type === 'item' ? (
          <ItemComponent item={item} i={i}/>
        ) : item.type === 'separator' ? (
          <Separator />
        ) : item.type === 'submenu' ? (
          <Submenu
            // label as item otherwise there is no icon
            label={<ItemComponent item={item} i={i} />}
            key={i}
            arrow={<Icon icon={'chevron_right'} style={{transform: 'translate(10px, 4px)'}} />}
          >
            {getSubmenuChildren(item)}
          </Submenu>
        ) : null
      )}
    </Menu>
  );
}

export default React.memo(ContextMenu)
