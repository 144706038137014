export const batchBySize = (batchSize, fullArr) => {
  const arr = [];
  for (let i = 0; i < fullArr.length; i += batchSize) {
    let batch = fullArr.slice(i, i + batchSize);
    arr.push(batch);
  }
  return arr;
}

export const errMessages = {
  'DEFAULT': 'ERROR',
  'NOT_OK': 'Error! Response status not \'OK\'',
  'NOT_200': 'Error! Response status not 200',
  'DATA_NOT_GENERATED': 'Error! During data generation, an error has occurred!',
  'STREAM_CONDITIONAL': 'Error! The stream is conditional and has not met any available conditions!'
};

// Custom throw Error, error object is not used because it expects only string as argument,
// possible solution with JSON.stringify and then JSON.parse in reducer, only some errors
// are just string; therefore it would error
// this is the cleanest solution without having to refactor current code in all reducers and epics
export function err( message=null, erroredResponse=null) {
  console.log(message, erroredResponse);
  // eslint-disable-next-line no-throw-literal
  throw {
    message: message ? message : errMessages.DEFAULT,
    ...(erroredResponse && {erroredResponse}),
  };
}

export const isResponseBlobSuccess = async blob => {
  if (blob instanceof Blob) {
    if (blob.type && blob.type === 'application/json') { // TRUE or FALSE
      const blobText = await blob.text();
      const json = await JSON.parse(blobText);
      return json.status !== 'ERROR';
    } else {
      // Not errored json type => TRUE (message error is always json)
      return blob;
    }
  } else {
    // Not a blob => FALSE
    return false;
  }
}

export const handleForkJoinResponses = (responses) => {
    let hasErrored = false;
    responses.forEach(res => {
      if (res.data.status !== 'OK') { hasErrored = true; }
    });

    if (hasErrored) {
      return err(errMessages.NOT_OK, responses)
    } else {
      return responses;
    }
  }

export const passOnlySuccessForkJoinResponses = (
  responses, isAirshopCustomResponse=true
) => {
    const arr = [];
    responses.forEach(res => {
      if (isAirshopCustomResponse) {
        if (res.data && res.data.status === 'OK') {
          arr.push(res)
        }
      } else {
        if (res.data && res.status === 200) {
          arr.push(res)
        }
      }
    });
    return arr;
  }
