import React from 'react'
import { Helmet } from 'react-helmet'

// Browser document title - head attribute title
const DraftTitle = ({ children }) => {
  return (
    <Helmet>
      <title>{ children }</title>
    </Helmet>
  )
}

export default DraftTitle
