import React, { useState, useRef } from 'react'

import {
  orders as ordersPrefix,
  ordersStatePrefix
} from 'page-containers/orders/orders.routes'
import ContextMenu from 'generic/components/ContextMenu'
import { contextMenu } from 'react-contexify'

import { useAuthFacade } from 'redux-store/auth/auth.facade'
import { useUserFacade } from 'redux-store/user/user.facade'
import { useOrdersFacade } from 'redux-store/orders/orders.facade'

import AppWideOrdersContext from 'contexts/context-registrations/AppWideOrdersContext'
import AppWideOrdersContextEffects from './components/AppWideOrdersContextEffects'

import {
  sessionOrdersFiltersTypes,
  setOrdersListFilterToSessionStorage,
  getOrdersListFilterFromSessionStorage
} from 'page-containers/orders/utils/session-storage.utils'

// Hooks
import { useProfileSetup } from 'hooks/useProfileSetup'

import '@fortawesome/fontawesome-free/css/all.css'

/*
 * Logic concerning the route (current location) is handled in children
 * components because at this level of this component, the routes are not handled yet,
 * yet this level is necessary to be able to distrubute data to sidebar from the content
 * (that actually handles the routes)
 */
const AppWideOrdersContextContainer = props => {

  const ORDERS_STATES_CONTEXT_MENU_ID = useRef('orders-states-context-menu');

  const authFacade = useAuthFacade();
  const ordersFacade = useOrdersFacade();
  const userFacade = useUserFacade();

  const {
    sessionId,
  } = authFacade;

  const {
    ordersPageData,
    ordersPageDraw,
    ordersTotal,
    ordersCurrentPage,
    stavObjednavky,
    redOrderStateFoldersCounts,
    fetchOrdersPageData,
    updateOrderOrdersState,
    fetchStavObjednavky,
    fetchTransportationTypes,
    fetchTransportationTypesValidDraft,
  } = ordersFacade;

  const { profileSetup } = userFacade;
  const { setPreselectedOrdersState } = useProfileSetup();

  // It is set in OrdersLayout.js
  const [ordersStateIdFromRoute, setOrdersStateIdFromRoute] = useState();
  // They are set in OrdersList.js
  const [ordersStatesItems, setOrdersStatesItems] = useState();
  // They transformed and used in TheSidebar.js
  const [ordersStatesSidebarItems, setOrdersStatesSidebarItems] = useState();
    // They are set in OrdersList.js
  const [ordersStatesIds, setOrdersStatesIds] = useState();

  const [ordersStatesOptions, setOrdersStatesOptions] = useState();
  // For options in OrdersList.js and sidebar items
  const orderStatesListOptionsRef = useRef([]);

  const stavObjednavkyRef = useRef();
  const orderStatesListRef = useRef({});
  const orderStatesListIdsRef = useRef({});

  // ORDERS TABLE
  const [ordersTableData, setOrdersTableData] = useState(() => {
    const page = getOrdersTablePageDefaultValue();
    if (!page) { setInitialOrdersTablePageToSCOnly(); }
    return ({
      page,
      pageData: ordersPageData,
      pageDraw: ordersPageDraw,
      total: ordersTotal,
      loadingData: false,
    });
  });

  const defaultOrdersState = {
    name: 'Všechny',
    value: null,
    category: 'general'
  };

  const defaultTransportationTypeState = {
    name: 'Všechny',
    value: null,
  };

  // Matching of dragging row over a sidebar oders state happens
  // with these classes
  const dragAndDropClassIdentifiers = {
    drop: 'droppable',
    item: 'orders-state-item',
  }

  const getActiveOrdersStateDefaultValue = () => {
    if (getOrdersListFilterFromSessionStorage(sessionOrdersFiltersTypes['ORDERS_STATE_TYPE'])) {
      return JSON.parse(
        getOrdersListFilterFromSessionStorage(sessionOrdersFiltersTypes['ORDERS_STATE_TYPE'])
      );
    } else {
      return defaultOrdersState;
    }
  }

  const getActiveTransportationStateDefaultValue = () => {
    if (getOrdersListFilterFromSessionStorage(sessionOrdersFiltersTypes['DELIVERY_TYPE'])) {
      return JSON.parse(
        getOrdersListFilterFromSessionStorage(sessionOrdersFiltersTypes['DELIVERY_TYPE'])
      );
    } else {
      return defaultTransportationTypeState;
    }
  }

  function getOrdersTablePageDefaultValue() {
    if (getOrdersListFilterFromSessionStorage(sessionOrdersFiltersTypes['TABLE_PAGE'])) {
      return +getOrdersListFilterFromSessionStorage(sessionOrdersFiltersTypes['TABLE_PAGE']);
    } else {
      return 0;
    }
  }

  function setInitialOrdersTablePageToSCOnly() {
    setOrdersListFilterToSessionStorage(sessionOrdersFiltersTypes['TABLE_PAGE'], 0, true);
  }

  // ORDERS FILTERS, defaulting with session storage values
  // state set functions also handle session storage sets
  const [shopType, setShopTypeOnly] = useState(
    getOrdersListFilterFromSessionStorage(sessionOrdersFiltersTypes['SHOP_TYPE'])
  );
  const [search, setSearchOnly] = useState(
    getOrdersListFilterFromSessionStorage(sessionOrdersFiltersTypes['SEARCH_TYPE'])
  );
  const [activeOrderState, setActiveOrderStateOnly] = useState(
    getActiveOrdersStateDefaultValue()
  );
  // When searching, it reset the active orders state to 'Všechny', when search is cleared,
  // they want the previous active order state to be reset, this helper stores the state when searching
  // and is reset when the active order state is reset
  const helperOrderStateBeforeSearchRef = useRef()
  const [activeTransportationType, setActiveTransportationTypeOnly] = useState(
    getActiveTransportationStateDefaultValue()
  );
  const [subOrdersCount, setSubOrdersCountOnly] = useState(
    getOrdersListFilterFromSessionStorage(sessionOrdersFiltersTypes['SUB_ORDERS_COUNT'])
  );
  // orders state that is loaded when the user loads the website
  const [ordersStateOnLoad, setOrdersStateOnLoad] = useState();

  const setActiveOrderState = (stateObject) => {
    setActiveOrderStateOnly(stateObject);
    setOrdersListFilterToSessionStorage(sessionOrdersFiltersTypes['ORDERS_STATE_TYPE'], JSON.stringify(stateObject));
  }

  const setActiveTransportationType = (transportationObject) => {
    setActiveTransportationTypeOnly(transportationObject);
    setOrdersListFilterToSessionStorage(sessionOrdersFiltersTypes['DELIVERY_TYPE'], JSON.stringify(transportationObject));
  }

  const setSearch = (term=null) => {
    setSearchOnly(term);

    const page = getOrdersTablePageDefaultValue();
    // Page reset in the session storage
    if (page) {
      setOrdersListFilterToSessionStorage(sessionOrdersFiltersTypes['TABLE_PAGE'], 0, true);
    }

    // Page reset in the table state
    if (ordersTableData.page) {
      setOrdersTableData({
        ...ordersTableData,
        page: 0,
      });
    }

    if (term) {
      setOrdersListFilterToSessionStorage(sessionOrdersFiltersTypes['SEARCH_TYPE'], term);
      // This horrible logic is explained above with the ref helper initialization
      if (helperOrderStateBeforeSearchRef.current) {
        // If repeated search term (2nd, 3rd, ... search session), do not set yet
        // activeOrderState is 'Všechny' object by now
        // The previous orders state is stored in the helper through the else if below
        return
      }
      else if (activeOrderState) {
        // First search
        helperOrderStateBeforeSearchRef.current = {...activeOrderState}
      }
    } else {
      setOrdersListFilterToSessionStorage(sessionOrdersFiltersTypes['SEARCH_TYPE']);
      // If search is being cleared (reset) and the helper was used to restore the previous orders state,
      // reset the orders state and the helper
      if (helperOrderStateBeforeSearchRef.current) {
        setActiveOrderState({...helperOrderStateBeforeSearchRef.current})
        helperOrderStateBeforeSearchRef.current = null
      }
    }
  }

  const setShopType = (shop=null) => {

    setShopTypeOnly(shop);
    if (shop) {
      setOrdersListFilterToSessionStorage(sessionOrdersFiltersTypes['SHOP_TYPE'], shop);
    } else {
      setOrdersListFilterToSessionStorage(sessionOrdersFiltersTypes['SHOP_TYPE']);
    }
  }

  const setSubOrdersCount = (subOrdersCount=null) => {
    const numVal = +subOrdersCount // null - 0, 1...6 str - as nums
    setSubOrdersCountOnly(numVal);
    if (numVal) {
      setOrdersListFilterToSessionStorage(sessionOrdersFiltersTypes['SUB_ORDERS_COUNT'], subOrdersCount);
    } else {
      setOrdersListFilterToSessionStorage(sessionOrdersFiltersTypes['SUB_ORDERS_COUNT']);
    }
  }

  const removerHoverWhileDragClass = event => {
    event.preventDefault();
    const hoverlessStyle = event.currentTarget.className.replace(' hover-while-drag', '');;
    event.currentTarget.className = hoverlessStyle;
  }

  const onDragEnterSidebarItem = event => {
    const originalStyle = event.currentTarget.className;
    event.currentTarget.className = originalStyle + ' hover-while-drag';
  }

  const getFolderColorClass = color => {
    if (color) {
      switch (color) {
        case '#6699cc':
          return 'blue';
        case '#ff9933':
          return 'orange';
        case '#f7f9f9':
          return 'white';
        case '#ff6666':
          return 'red';
        default:
          return 'base';
      }
    } else {
      return 'white';
    }
  }

  const retrieveColorFromStavObejdnavkyItem = appDataProp => {
    return JSON.parse(appDataProp).backgroundColor;
  }

  const getOrdersStatesObjects = () => {
    const sidebarItems = ordersStatesItems.map(ordersState => ({
      _tag: 'CSidebarNavItem',
      name: truncateItemName(ordersState.name),
      // Case null (represents all states)
      to: getOrdersStateRoute(ordersState.value),
      // icon: 'cil-folder',
      fontIcon: 'fas fa-folder state-icon ' + getFolderColorClass(ordersState.folderColor),
      mode: 'production',
      master: 'true',
      className: dragAndDropClassIdentifiers.item
                 + ' '
                 + dragAndDropClassIdentifiers.drop,
      ordersstateid: ordersState.value ? ordersState.value : 'all',
      onContextMenu: (event) => showsSidebarContextMenu(event, ordersState.value),
      // // Begin to hover over sidebar item while dragging
      // onDragEnter: onDragEnterSidebarItem,
      // // Continuing hovering
      // onDragOver: (event) => event.preventDefault(),
      // // Leave without drop
      // onDragLeave: removerHoverWhileDragClass,
      // // Drop without leave
      // onDrop: removerHoverWhileDragClass,
      // Conditionally add badge prop for red folders, if non-zero
      ...(ordersState.showBadge && redOrderStateFoldersCounts && !!redOrderStateFoldersCounts[ordersState.value] && {
        badge: {
          text: redOrderStateFoldersCounts[ordersState.value],
          color: 'danger',
        }
      }),
    }));

    sidebarItems.unshift({
      _tag: 'CSidebarNavTitle',
      _children: ['Stavy objednávek'],
      mode: 'production',
      master: 'true',
    });

    return sidebarItems;
  }

  const getOrdersStateRoute = (ordersStateId) => {
    if (ordersStateId) {
      return `${ordersPrefix}/${ordersStatePrefix}/${ordersStateId}`;
    } else {
      return `${ordersPrefix}`;
    }
  }

  const truncateItemName = str => {
    const length = str.length;
    if (length > 33) {
      // return str.substring(0, 25) + '...' + str.substring(length - 8, length);
      // return str;
      return str;
    } else {
      return str;
    }
  }

  const isOrdersStatesSidebarItem = (droppedTarget, identifiers) => {
    // the link's parent contains the necessary class identifiers
    const parentClassList = droppedTarget && droppedTarget.parentElement
          ? droppedTarget.parentElement.classList : null;

    const isSidebarOrdersStateItem = parentClassList
          && parentClassList.contains(identifiers.drop)
          && parentClassList.contains(identifiers.item);

    return isSidebarOrdersStateItem;
  }

  const retrieveSidebarOrdersStateItemAttribute = (element, attr) => {
    if (element) {
      return element.getAttribute(attr);
    } else {
      return null;
    }
  }

  const changeOrderOrdersStateByDragging = (orderId, dropTargetElement) => {
    const isSidebarOrdersStateItem = isOrdersStatesSidebarItem(
      dropTargetElement,
      dragAndDropClassIdentifiers
    );

    if (ordersStatesIds && isSidebarOrdersStateItem) {
      const abraId = retrieveSidebarOrdersStateItemAttribute(
        dropTargetElement,
        'ordersstateid'
      );

      const ordersStateId = ordersStatesIds[abraId];

      updateOrderOrdersState(orderId, ordersStateId, sessionId);
      parametrizedOrdersFetch(ordersCurrentPage);
    }
  }

  const fleshOrderStateSidebarItem = dropTargetElement => {
    const isSidebarOrdersStateItem = isOrdersStatesSidebarItem(
      dropTargetElement,
      dragAndDropClassIdentifiers
    );

    if (isSidebarOrdersStateItem) {
      const originalStyle = dropTargetElement.className;
      dropTargetElement.className = originalStyle + ' on-drop-order-state';
      setTimeout(() => {
        dropTargetElement.className = originalStyle;
      }, 2500);
    }
  }

  const focusIfOrderDraggedOverSidebarItem = dropTargetElement => {
    const isSidebarOrdersStateItem = isOrdersStatesSidebarItem(
      dropTargetElement,
      dragAndDropClassIdentifiers
    );

    if (isSidebarOrdersStateItem) {
      dropTargetElement.focus();
    }
  }

  // General function used in effects for fetching orders items after setting selects or searches in table
  const parametrizedOrdersFetch = (page=0) => {
    // Page is influenced by pass parameter and by session storage saved page,
    // used mainly when returning from a route other that 'objednavky'
    let finalPage = page;
    if (!page && ordersTableData.pageData) {
      finalPage = +getOrdersTablePageDefaultValue() / 50;
    }

    fetchOrdersPageData(
      ordersPageDraw + 1,
      finalPage,
      activeOrderState.value,
      activeTransportationType.value,
      shopType,
      search,
    );

    setOrdersTableData({
      ...ordersTableData,
      loadingData: true
    });
  }

  const showsSidebarContextMenu = (event, ordersStateId) => {
    contextMenu.show({
      id: ORDERS_STATES_CONTEXT_MENU_ID.current,
      event: event,
      props: { ordersStateId }
    });
  }

  const contextMenuItem = [{
    type: 'item',
    label: 'Nastavit jako můj stav objednávek',
    disabled: false,
    // event, props, item FROM ContextMenu.tsx
    onClick: (event, props, item) => setPreselectedOrdersState(
                                       props.ordersStateId, profileSetup
                                     ),
  }];

  const onLoadSetProfileState = () => {
    if (profileSetup && profileSetup.preselectedOrdersState) {
      // If orders state in session storage, it takes precedence
      if (getOrdersListFilterFromSessionStorage(sessionOrdersFiltersTypes['ORDERS_STATE_TYPE'])) {
        const stateObject = JSON.parse(
          getOrdersListFilterFromSessionStorage(sessionOrdersFiltersTypes['ORDERS_STATE_TYPE'])
        );
        setOrdersStateOnLoad(stateObject.value);
      // otherwise, profile set order states is set
      } else {
        if (profileSetup.preselectedOrdersState === 'Všechny') {
          setOrdersStateOnLoad();
        } else {
          setOrdersStateOnLoad(profileSetup.preselectedOrdersState);
        }
      }
    }
  }

  return (
    <>
      <AppWideOrdersContext.Provider
        value={{
          ordersStateIdFromRoute,
          setOrdersStateIdFromRoute,
          ordersStatesSidebarItems,
          ordersStatesItems,
          setOrdersStatesItems,
          defaultOrdersState,
          activeOrderState,
          setActiveOrderState,
          activeTransportationType,
          setActiveTransportationType,
          setOrdersStatesIds,
          changeOrderOrdersStateByDragging,
          focusIfOrderDraggedOverSidebarItem,
          fleshOrderStateSidebarItem,
          dragAndDropClassIdentifiers,
          setOrdersTableData,
          ordersTableData,
          shopType,
          setShopType,
          search,
          setSearch,
          subOrdersCount,
          setSubOrdersCount,
          ordersStateOnLoad,
          parametrizedOrdersFetch,
          orderStatesListOptionsRef,
          ordersStatesOptions,
          setOrdersStatesOptions,
          stavObjednavkyRef,
          orderStatesListRef,
          orderStatesListIdsRef,
          getOrdersStateRoute,
          helperOrderStateBeforeSearchRef,
        }}
      >
        { props.children }
      </AppWideOrdersContext.Provider>

      {/* Effect from this component moved into their renderless component */}
      <AppWideOrdersContextEffects
        profileSetup={profileSetup}
        defaultOrdersState={defaultOrdersState}
        getOrdersStateRoute={getOrdersStateRoute}
        getOrdersStatesObjects={getOrdersStatesObjects}
        ordersStateOnLoad={ordersStateOnLoad}
        setOrdersStateOnLoad={setOrdersStateOnLoad}
        ordersStatesItems={ordersStatesItems}
        setOrdersStatesSidebarItems={setOrdersStatesSidebarItems}
        ordersStateIdFromRoute={ordersStateIdFromRoute}
        activeOrderState={activeOrderState}
        setActiveOrderState={setActiveOrderState}
        orderStatesListOptionsRef={orderStatesListOptionsRef}
        ordersStatesOptions={ordersStatesOptions}
        setOrdersStatesOptions={setOrdersStatesOptions}
        setOrdersStatesItems={setOrdersStatesItems}
        stavObjednavky={stavObjednavky}
        fetchStavObjednavky={fetchStavObjednavky}
        stavObjednavkyRef={stavObjednavkyRef}
        orderStatesListRef={orderStatesListRef}
        orderStatesListIdsRef={orderStatesListIdsRef}
        ordersStatesIds={ordersStatesIds}
        setOrdersStatesIds={setOrdersStatesIds}
        onLoadSetProfileState={onLoadSetProfileState}
        retrieveColorFromStavObejdnavkyItem={retrieveColorFromStavObejdnavkyItem}
        redOrderStateFoldersCounts={redOrderStateFoldersCounts}
        setOrdersListFilterToSessionStorage={setOrdersListFilterToSessionStorage}
        sessionOrdersFiltersTypes={sessionOrdersFiltersTypes}
        fetchTransportationTypes={fetchTransportationTypes}
        fetchTransportationTypesValidDraft={fetchTransportationTypesValidDraft}
      />

      {/* Shows up on right click on sidebar orders state item */}
      <ContextMenu
        id={ORDERS_STATES_CONTEXT_MENU_ID.current}
        menuItems={contextMenuItem}
      />
    </>
  )
}

export default React.memo(AppWideOrdersContextContainer)
