export const TARGETS_COMMODITIES_LIST_REQUEST = '[TARGETS]_COMMODITIES_LIST_REQUEST'
export const TARGETS_COMMODITIES_LIST_RESPONSE = '[TARGETS]_COMMODITIES_LIST_RESPONSE'
export const TARGETS_COMMODITIES_LIST_FAILURE = '[TARGETS]_COMMODITIES_LIST_FAILURE'

export const TARGETS_COMMODITY_CATEGORIES_REQUEST = '[TARGETS]_COMMODITY_CATEGORIES_REQUEST'
export const TARGETS_COMMODITY_CATEGORIES_RESPONSE = '[TARGETS]_COMMODITY_CATEGORIES_RESPONSE'
export const TARGETS_COMMODITY_CATEGORIES_FAILURE = '[TARGETS]_COMMODITY_CATEGORIES_FAILURE'

export const TARGETS_CREATE_MULTIPLE_COMMODITY_TARGETS_REQUEST = '[TARGETS]_CREATE_MULTIPLE_COMMODITY_TARGETS_REQUEST'
export const TARGETS_CREATE_MULTIPLE_COMMODITY_TARGETS_RESPONSE = '[TARGETS]_CREATE_MULTIPLE_COMMODITY_TARGETS_RESPONSE'
export const TARGETS_CREATE_MULTIPLE_COMMODITY_TARGETS_FAILURE = '[TARGETS]_CREATE_MULTIPLE_COMMODITY_TARGETS_FAILURE'

export const TARGETS_COMMODITY_TARGETS_LIST_REQUEST = '[TARGETS]_COMMODITY_TARGETS_LIST_REQUEST'
export const TARGETS_COMMODITY_TARGETS_LIST_RESPONSE = '[TARGETS]_COMMODITY_TARGETS_LIST_RESPONSE'
export const TARGETS_COMMODITY_TARGETS_LIST_FAILURE = '[TARGETS]_COMMODITY_TARGETS_LIST_FAILURE'

export const TARGETS_TARGETS_REQUEST = '[TARGETS]_TARGETS_REQUEST'
export const TARGETS_TARGETS_RESPONSE = '[TARGETS]_TARGETS_RESPONSE'
export const TARGETS_TARGETS_FAILURE = '[TARGETS]_TARGETS_FAILURE'

export const TARGETS_TARGETS_CATEGORIES_REQUEST = '[TARGETS]_TARGETS_CATEGORIES_REQUEST'
export const TARGETS_TARGETS_CATEGORIES_RESPONSE = '[TARGETS]_TARGETS_CATEGORIES_RESPONSE'
export const TARGETS_TARGETS_CATEGORIES_FAILURE = '[TARGETS]_TARGETS_CATEGORIES_FAILURE'

export const TARGETS_DELETE_MULTIPLE_COMMODITY_TARGETS_REQUEST = '[TARGETS]_DELETE_MULTIPLE_COMMODITY_TARGETS_REQUEST'
export const TARGETS_DELETE_MULTIPLE_COMMODITY_TARGETS_RESPONSE = '[TARGETS]_DELETE_MULTIPLE_COMMODITY_TARGETS_RESPONSE'
export const TARGETS_DELETE_MULTIPLE_COMMODITY_TARGETS_FAILURE = '[TARGETS]_DELETE_MULTIPLE_COMMODITY_TARGETS_FAILURE'

export const TARGETS_CREATE_KEG_PRODUCT_REQUEST = '[TARGETS]_CREATE_KEG_PRODUCT_REQUEST'
export const TARGETS_CREATE_KEG_PRODUCT_RESPONSE = '[TARGETS]_CREATE_KEG_PRODUCT_RESPONSE'
export const TARGETS_CREATE_KEG_PRODUCT_FAILURE = '[TARGETS]_CREATE_KEG_PRODUCT_FAILURE'
export const TARGETS_CREATE_KEG_PRODUCT_RESET = '[TARGETS]_CREATE_KEG_PRODUCT_RESET'

export const TARGETS_KEG_SIZES_BY_COMMODITY_REQUEST = '[TARGETS]_KEG_SIZES_BY_COMMODITY_REQUEST'
export const TARGETS_KEG_SIZES_BY_COMMODITY_RESPONSE = '[TARGETS]_KEG_SIZES_BY_COMMODITY_RESPONSE'
export const TARGETS_KEG_SIZES_BY_COMMODITY_FAILURE = '[TARGETS]_KEG_SIZES_BY_COMMODITY_FAILURE'

export const TARGETS_KEG_SIZES_REQUEST = '[TARGETS]_KEG_SIZES_REQUEST'
export const TARGETS_KEG_SIZES_RESPONSE = '[TARGETS]_KEG_SIZES_RESPONSE'
export const TARGETS_KEG_SIZES_FAILURE = '[TARGETS]_KEG_SIZES_FAILURE'

export const TARGETS_KEGS_LIST_BOTH_STOCK_OPTIONS_REQUEST = '[TARGETS]_KEGS_LIST_BOTH_STOCK_OPTIONS_REQUEST'
export const TARGETS_KEGS_LIST_BOTH_STOCK_OPTIONS_RESPONSE = '[TARGETS]_KEGS_LIST_BOTH_STOCK_OPTIONS_RESPONSE'
export const TARGETS_KEGS_LIST_BOTH_STOCK_OPTIONS_FAILURE = '[TARGETS]_KEGS_LIST_BOTH_STOCK_OPTIONS_FAILURE'

export const TARGETS_TARGET_COMMODITY_LIST_BOTH_STOCK_OPTIONS_REQUEST = '[TARGETS]_TARGET_COMMODITY_LIST_BOTH_STOCK_OPTIONS_REQUEST'
export const TARGETS_TARGET_COMMODITY_LIST_BOTH_STOCK_OPTIONS_RESPONSE = '[TARGETS]_TARGET_COMMODITY_LIST_BOTH_STOCK_OPTIONS_RESPONSE'
export const TARGETS_TARGET_COMMODITY_LIST_BOTH_STOCK_OPTIONS_FAILURE = '[TARGETS]_TARGET_COMMODITY_LIST_BOTH_STOCK_OPTIONS_FAILURE'

export const TARGETS_EDIT_COMMODITY_TARGET_REQUEST = '[TARGETS]_EDIT_COMMODITY_TARGET_REQUEST'
export const TARGETS_EDIT_COMMODITY_TARGET_RESPONSE = '[TARGETS]_EDIT_COMMODITY_TARGET_RESPONSE'
export const TARGETS_EDIT_COMMODITY_TARGET_FAILURE = '[TARGETS]_EDIT_COMMODITY_TARGET_FAILURE'

export const TARGETS_EDIT_MULTIPLE_TARGET_COMMODITY_TARGETS_REQUEST = '[TARGETS]_EDIT_MULTIPLE_TARGET_COMMODITY_TARGETS_REQUEST'
export const TARGETS_EDIT_MULTIPLE_TARGET_COMMODITY_TARGETS_RESPONSE = '[TARGETS]_EDIT_MULTIPLE_TARGET_COMMODITY_TARGETS_RESPONSE'
export const TARGETS_EDIT_MULTIPLE_TARGET_COMMODITY_TARGETS_FAILURE = '[TARGETS]_EDIT_MULTIPLE_TARGET_COMMODITY_TARGETS_FAILURE'

export const TARGETS_EDIT_TARGET_CATEGORY_REQUEST = '[TARGETS]_EDIT_TARGET_CATEGORY_REQUEST'
export const TARGETS_EDIT_TARGET_CATEGORY_RESPONSE = '[TARGETS]_EDIT_TARGET_CATEGORY_RESPONSE'
export const TARGETS_EDIT_TARGET_CATEGORY_FAILURE = '[TARGETS]_EDIT_TARGET_CATEGORY_FAILURE'

export const TARGETS_MANUFACTURERS_LIST_REQUEST = '[TARGETS]_MANUFACTURERS_LIST_REQUEST'
export const TARGETS_MANUFACTURERS_LIST_RESPONSE = '[TARGETS]_MANUFACTURERS_LIST_RESPONSE'
export const TARGETS_MANUFACTURERS_LIST_FAILURE = '[TARGETS]_MANUFACTURERS_LIST_FAILURE'

export const TARGETS_TARGET_COMMODITY_TARGET_SETTINGS_REQUEST = '[TARGETS]_TARGET_COMMODITY_TARGET_SETTINGS_REQUEST'
export const TARGETS_TARGET_COMMODITY_TARGET_SETTINGS_RESPONSE = '[TARGETS]_TARGET_COMMODITY_TARGET_SETTINGS_RESPONSE'
export const TARGETS_TARGET_COMMODITY_TARGET_SETTINGS_FAILURE = '[TARGETS]_TARGET_COMMODITY_TARGET_SETTINGS_FAILURE'

export const TARGETS_EDIT_TARGET_COMMODITY_TARGET_SETTINGS_REQUEST = '[TARGETS]_EDIT_TARGET_COMMODITY_TARGET_SETTINGS_REQUEST'
export const TARGETS_EDIT_TARGET_COMMODITY_TARGET_SETTINGS_RESPONSE = '[TARGETS]_EDIT_TARGET_COMMODITY_TARGET_SETTINGS_RESPONSE'
export const TARGETS_EDIT_TARGET_COMMODITY_TARGET_SETTINGS_FAILURE = '[TARGETS]_EDIT_TARGET_COMMODITY_TARGET_SETTINGS_FAILURE'

export const TARGETS_EDIT_MANUFACTURER_REQUEST = '[TARGETS]_EDIT_MANUFACTURER_REQUEST'
export const TARGETS_EDIT_MANUFACTURER_RESPONSE = '[TARGETS]_EDIT_MANUFACTURER_RESPONSE'
export const TARGETS_EDIT_MANUFACTURER_FAILURE = '[TARGETS]_EDIT_MANUFACTURER_FAILURE'

export const TARGETS_TARGET_FEED_PROPERTY_LIST_REQUEST = '[TARGETS]_TARGET_FEED_PROPERTY_LIST_REQUEST'
export const TARGETS_TARGET_FEED_PROPERTY_LIST_RESPONSE = '[TARGETS]_TARGET_FEED_PROPERTY_LIST_RESPONSE'
export const TARGETS_TARGET_FEED_PROPERTY_LIST_FAILURE = '[TARGETS]_TARGET_FEED_PROPERTY_LIST_FAILURE'

export const TARGETS_TARGET_COMMODITY_PROPERTY_LIST_REQUEST = '[TARGETS]_TARGET_COMMODITY_PROPERTY_LIST_REQUEST'
export const TARGETS_TARGET_COMMODITY_PROPERTY_LIST_RESPONSE = '[TARGETS]_TARGET_COMMODITY_PROPERTY_LIST_RESPONSE'
export const TARGETS_TARGET_COMMODITY_PROPERTY_LIST_FAILURE = '[TARGETS]_TARGET_COMMODITY_PROPERTY_LIST_FAILURE'

export const TARGETS_CREATE_TARGET_COMMODITY_PROPERTY_REQUEST = '[TARGETS]_CREATE_TARGET_COMMODITY_PROPERTY_REQUEST'
export const TARGETS_CREATE_TARGET_COMMODITY_PROPERTY_RESPONSE = '[TARGETS]_CREATE_TARGET_COMMODITY_PROPERTY_RESPONSE'
export const TARGETS_CREATE_TARGET_COMMODITY_PROPERTY_FAILURE = '[TARGETS]_CREATE_TARGET_COMMODITY_PROPERTY_FAILURE'

export const TARGETS_EDIT_TARGET_COMMODITY_PROPERTY_REQUEST = '[TARGETS]_EDIT_TARGET_COMMODITY_PROPERTY_REQUEST'
export const TARGETS_EDIT_TARGET_COMMODITY_PROPERTY_RESPONSE = '[TARGETS]_EDIT_TARGET_COMMODITY_PROPERTY_RESPONSE'
export const TARGETS_EDIT_TARGET_COMMODITY_PROPERTY_FAILURE = '[TARGETS]_EDIT_TARGET_COMMODITY_PROPERTY_FAILURE'

export const TARGETS_COMMODITY_TARGET_REQUEST = '[TARGETS]_COMMODITY_TARGET_REQUEST'
export const TARGETS_COMMODITY_TARGET_RESPONSE = '[TARGETS]_COMMODITY_TARGET_RESPONSE'
export const TARGETS_COMMODITY_TARGET_FAILURE = '[TARGETS]_COMMODITY_TARGET_FAILURE'
export const TARGETS_COMMODITY_TARGET_RESET = '[TARGETS]_COMMODITY_TARGET_RESET'

export const TARGETS_TARGET_COMMODITY_CATEGORY_REQUEST = '[TARGETS]_TARGET_COMMODITY_CATEGORY_REQUEST'
export const TARGETS_TARGET_COMMODITY_CATEGORY_RESPONSE = '[TARGETS]_TARGET_COMMODITY_CATEGORY_RESPONSE'
export const TARGETS_TARGET_COMMODITY_CATEGORY_FAILURE = '[TARGETS]_TARGET_COMMODITY_CATEGORY_FAILURE'

export const TARGETS_EDIT_TARGET_COMMODITY_CATEGORY_REQUEST = '[TARGETS]_EDIT_TARGET_COMMODITY_CATEGORY_REQUEST'
export const TARGETS_EDIT_TARGET_COMMODITY_CATEGORY_RESPONSE = '[TARGETS]_EDIT_TARGET_COMMODITY_CATEGORY_RESPONSE'
export const TARGETS_EDIT_TARGET_COMMODITY_CATEGORY_FAILURE = '[TARGETS]_EDIT_TARGET_COMMODITY_CATEGORY_FAILURE'

export const TARGETS_RESET_RES_MESSAGE = '[TARGETS]_RESET_RES_MESSAGE'
