import * as actionTypes from './transfers.actionTypes';

// Transfer ways fetching
export function transfersTransferWaysRequest() {
  return {
    type: actionTypes.TRANSFERS_TRANSFER_WAYS_REQUEST
  };
}

export function transfersTransferWaysResponse(response) {
  return {
    type: actionTypes.TRANSFERS_TRANSFER_WAYS_RESPONSE,
    payload: response
  };
}

export function transfersTransferWaysFailure(error) {
  return {
    type: actionTypes.TRANSFERS_TRANSFER_WAYS_FAILURE,
    payload: error
  };
}

// Reset response message
export function transfersResponseMessageReset() {
  return {
    type: actionTypes.TRANSFERS_RESPONSE_MESSAGE_RESET
  };
}

// Transfers list page fetching
export function trasnfersListPageRequest(
  draw,
  page,
  transferWayId,
  search,
  status,
) {
  return {
    type: actionTypes.TRANSFERS_TRANSFERS_LIST_REQUEST,
    payload: { draw, page, transferWayId, search, status }
  };
}

export function trasnfersListPageResponse(response, badgeTotal) {
  return {
    type: actionTypes.TRANSFERS_TRANSFERS_LIST_RESPONSE,
    payload: { response, badgeTotal }
  };
}

export function trasnfersListPageFailure(error) {
  return {
    type: actionTypes.TRANSFERS_TRANSFERS_LIST_RESPONSE,
    payload: error
  };
}

// Selected transfer items fetching
export function transferDetailItemsListPageRequest(
  transferQueueId,
  draw,
  page,
  mask,
  filterEanPlu,
  limit,
) {
  return {
    type: actionTypes.TRANSFERS_TRANSFER_DETAIL_ITEMS_REQUEST,
    payload: { draw, page, transferQueueId, mask, filterEanPlu, limit }
  };
}

export function transferDetailItemsListPageResponse(response) {
  return {
    type: actionTypes.TRANSFERS_TRANSFER_DETAIL_ITEMS_RESPONSE,
    payload: response
  };
}

export function transferDetailItemsListPageFailure(error) {
  return {
    type: actionTypes.TRANSFERS_TRANSFER_DETAIL_ITEMS_FAILURE,
    payload: error
  };
}

// Creating a new transfer
export function createNewTransferRequest(
  partnerUid,
  transferWayId,
  ident,
  // Partner SYSTEM as default
  carrierPartnerUid='1271148f91a2e04ef857405634b07b15',
  dateDue=Date.now()
) {
  return {
    type: actionTypes.TRANSFERS_CREATE_TRANSFER_REQUEST,
    payload: { partnerUid, carrierPartnerUid, ident, transferWayId, dateDue }
  };
}

export function createNewTransferResponse(response, resMessage) {
  return {
    type: actionTypes.TRANSFERS_CREATE_TRANSFER_RESPONSE,
    payload: { response, resMessage }
  };
}

export function createNewTransferFailure(error, resMessage) {
  return {
    type: actionTypes.TRANSFERS_CREATE_TRANSFER_FAILURE,
    payload: { error, resMessage }
  };
}

// Changing the status of a transfer
export function changeTransferStatusRequest(
  transferId,
  newStatus,
  ident,
  // SYSTEM as default
  partnerUsername,
) {
  return {
    type: actionTypes.TRANSFERS_TRANSFER_CHANGE_STATUS_REQUEST,
    payload: { transferId, newStatus, ident, partnerUsername }
  };
}

export function changeTransferStatusResponse(response, resMessage) {
  return {
    type: actionTypes.TRANSFERS_TRANSFER_CHANGE_STATUS_RESPONSE,
    payload: { response, resMessage }
  };
}

export function changeTransferStatusFailure(error, resMessage) {
  return {
    type: actionTypes.TRANSFERS_TRANSFER_CHANGE_STATUS_FAILURE,
    payload: { error, resMessage }
  };
}

// Creating transfer detail item
export function transferDetailCreateItemRequest(
  transferQueueId,
  // only one of plu or ean is needed
  plu,
  ean,
  quantity=0,
) {
  return {
    type: actionTypes.TRANSFERS_TRANSFER_DETAIL_CREATE_ITEM_REQUEST,
    payload: { transferQueueId, plu, ean, quantity }
  };
}

export function transferDetailCreateItemResponse(response, resMessage) {
  return {
    type: actionTypes.TRANSFERS_TRANSFER_DETAIL_CREATE_ITEM_RESPONSE,
    payload: { response, resMessage }
  };
}

export function transferDetailCreateItemFailure(error, resMessage) {
  return {
    type: actionTypes.TRANSFERS_TRANSFER_DETAIL_CREATE_ITEM_FAILURE,
    payload: { error, resMessage }
  };
}

// Updating transfer item quantity
export function transferDetailUpdateItemQuantityRequest(
  transferQueueItemsId,
  quantity
) {
  return {
    type: actionTypes.TRANSFERS_TRANSFER_DETAIL_ITEM_UPDATE_QUANTITY_REQUEST,
    payload: { transferQueueItemsId, quantity },
  };
}

export function transferDetailUpdateItemQuantityResponse(
  response,
  resMessage
) {
  return {
    type: actionTypes.TRANSFERS_TRANSFER_DETAIL_ITEM_UPDATE_QUANTITY_RESPONSE,
    payload: { response, resMessage },
  };
}

export function transferDetailUpdateItemQuantityFailure(
  error,
  resMessage
) {
  return {
    type: actionTypes.TRANSFERS_TRANSFER_DETAIL_ITEM_UPDATE_QUANTITY_FAILURE,
    payload: { error, resMessage },
  };
}

// Nullify
export function nullifyNewCreatedTransferItem() {
  return {
    type: actionTypes.TRANSFERS_NULLIFY_NEW_CREATED_DETAIL_ITEM
  };
}

// Create transfer detail item from order
export function transferDetailCreateItemFromOrderRequest(
  orderItemsId,
  transferWayId,
  quantity,
) {
  return {
    type: actionTypes.TRANSFERS_CREATE_TRANSFER_DETAIL_ITEM_FROM_ORDER_REQUEST,
    payload: { orderItemsId, transferWayId, quantity },
  };
}

export function transferDetailCreateItemFromOrderResponse(
  response, resMessage
) {
  return {
    type: actionTypes.TRANSFERS_CREATE_TRANSFER_DETAIL_ITEM_FROM_ORDER_RESPONSE,
    payload: { response, resMessage },
  };
}

export function transferDetailCreateItemFromOrderFailure(
  error, resMessage
) {
  return {
    type: actionTypes.TRANSFERS_CREATE_TRANSFER_DETAIL_ITEM_FROM_ORDER_FAILURE,
    payload: { error, resMessage },
  };
}

// Process transfer detail item
export function processTransferDetailItemRequest(
  transferQueueItemsId
) {
  return {
    type: actionTypes.TRANSFERS_TRANSFER_DETAIL_ITEM_PROCESSING_REQUEST,
    payload: { transferQueueItemsId },
  };
}

export function processTransferDetailItemResponse(
  response,
  resMessage
) {
  return {
    type: actionTypes.TRANSFERS_TRANSFER_DETAIL_ITEM_PROCESSING_RESPONSE,
    payload: { response, resMessage },
  };
}

export function processTransferDetailItemFailure(
  error,
  resMessage
) {
  return {
    type: actionTypes.TRANSFERS_TRANSFER_DETAIL_ITEM_UPDATE_QUANTITY_FAILURE,
    payload: { error, resMessage },
  };
}

// Delete transfer detail item
export function transferDetailItemDeleteRequest(
transferQueueItemsId
) {
  return {
    type: actionTypes.TRANSFERS_TRANSFER_DETAIL_ITEM_DELETE_REQUEST,
    payload: transferQueueItemsId,
  };
}

export function transferDetailItemDeleteResponse(
  response,
  resMessage
) {
  return {
    type: actionTypes.TRANSFERS_TRANSFER_DETAIL_ITEM_DELETE_RESPONSE,
    payload: { response, resMessage },
  };
}

export function transferDetailItemDeleteFailure(
  error,
  resMessage
) {
  return {
    type: actionTypes.TRANSFERS_TRANSFER_DETAIL_ITEM_DELETE_FAILURE,
    payload: { error, resMessage },
  };
}

// Transfer detail item change transfer queue
export function transferDetailItemChangeTransferQueueRequest(
  transferQueueItemsId,
  transferQueueId
) {
  return {
    type: actionTypes.TRANSFERS_TRANSFER_DETAIL_ITEM_CHANGE_TRANSFER_QUEUE_REQUEST,
    payload: { transferQueueItemsId, transferQueueId },
  };
}

export function transferDetailItemChangeTransferQueueResponse(
  response,
  resMessage
) {
  return {
    type: actionTypes.TRANSFERS_TRANSFER_DETAIL_ITEM_CHANGE_TRANSFER_QUEUE_RESPONSE,
    payload: { response, resMessage },
  };
}

export function transferDetailItemChangeTransferQueueFailure(
  error,
  resMessage
) {
  return {
    type: actionTypes.TRANSFERS_TRANSFER_DETAIL_ITEM_CHANGE_TRANSFER_QUEUE_FAILURE,
    payload: { error, resMessage },
  };
}

// Delete transfer
export function transferDeleteRequest(
  transferQueueId
) {
  return {
    type: actionTypes.TRANSFERS_TRANSFER_DELETE_REQUEST,
    payload: transferQueueId,
  };
}

export function transferDeleteResponse(
  response,
  resMessage
) {
  return {
    type: actionTypes.TRANSFERS_TRANSFER_DELETE_RESPONSE,
    payload: { response, resMessage },
  };
}

export function transferDeleteFailure(
  error,
  resMessage
) {
  return {
    type: actionTypes.TRANSFERS_TRANSFER_DELETE_FAILURE,
    payload: { error, resMessage },
  };
}

// Update transfer
export function transferUpdateRequest(
  updatedTransfer
) {
  return {
    type: actionTypes.TRANSFERS_TRANSFER_UPDATE_REQUEST,
    payload: updatedTransfer,
  };
}

export function transferUpdateResponse(
  response,
  resMessage
) {
  return {
    type: actionTypes.TRANSFERS_TRANSFER_UPDATE_RESPONSE,
    payload: { response, resMessage }
  };
}

export function transferUpdateFailure(
  error,
  resMessage
) {
  return {
    type: actionTypes.TRANSFERS_TRANSFER_UPDATE_FAILURE,
    payload: { error, resMessage }
  };
}

// Update transfer note
export function transferUpdateNoteRequest(
  transferQueueId,
  note
) {
  return {
    type: actionTypes.TRANSFERS_TRANSFER_UPDATE_NOTE_REQUEST,
    payload: { transferQueueId, note },
  };
}

export function transferUpdateNoteResponse(
  response,
  resMessage
) {
  return {
    type: actionTypes.TRANSFERS_TRANSFER_UPDATE_NOTE_RESPONSE,
    payload: { response, resMessage }
  };
}

export function transferUpdateNoteFailure(
  error,
  resMessage
) {
  return {
    type: actionTypes.TRANSFERS_TRANSFER_UPDATE_NOTE_FAILURE,
    payload: { error, resMessage }
  };
}

// Update transfer item note
export function transferItemUpdateNoteRequest(
  transferQueueItemsId,
  note
) {
  return {
    type: actionTypes.TRANSFERS_TRANSFER_ITEM_UPDATE_NOTE_REQUEST,
    payload: { transferQueueItemsId, note },
  };
}

export function transferItemUpdateNoteResponse(
  response,
  resMessage
) {
  return {
    type: actionTypes.TRANSFERS_TRANSFER_ITEM_UPDATE_NOTE_RESPONSE,
    payload: { response, resMessage },
  };
}

export function transferItemUpdateNoteFailure(
  error,
  resMessage
) {
  return {
    type: actionTypes.TRANSFERS_TRANSFER_ITEM_UPDATE_NOTE_FAILURE,
    payload: { error, resMessage },
  };
}

// Print document
export function transfersPrintDocumentRequest(type, itemId, userUid, ident) {
  return {
    type: actionTypes.TRANSFERS_PRINT_DOCUMENT_REQUEST,
    payload: { type, itemId, userUid, ident }
  };
}

export function transfersPrintDocumentResponse(response, resMessage) {
  return {
    type: actionTypes.TRANSFERS_PRINT_DOCUMENT_RESPONSE,
    payload: { response, resMessage }
  };
}

export function transfersPrintDocumentFailure(error, resMessage) {
  return {
    type: actionTypes.TRANSFERS_PRINT_DOCUMENT_FAILURE,
    payload: { error, resMessage }
  };
}

// Generate PDF request
export function transfersGeneratePdfDocumentRequest(type, itemId, userUid, ident) {
  return {
    type: actionTypes.TRANSFERS_GENERATE_PDF_REQUEST,
    payload: { type, itemId, userUid, ident }
  };
}

export function transfersGeneratePdfDocumentResponse(response, resMessage) {
  return {
    type: actionTypes.TRANSFERS_GENERATE_PDF_RESPONSE,
    payload: { response, resMessage }
  };
}

export function transfersGeneratePdfDocumentFailure(error, resMessage) {
  return {
    type: actionTypes.TRANSFERS_GENERATE_PDF_FAILURE,
    payload: { error, resMessage }
  };
}

// Reset generated PDF
export function transfersResetGeneratedPdf() {
  return {
    type: actionTypes.TRANSFERS_RESET_GENERATED_PDF,
  };
}

// Sales requests
export function transfersSalesRequestsRequest(draw, page, limit, search) {
  return {
    type: actionTypes.TRANSFERS_SALES_REQUESTS_REQUEST,
    payload: { draw, page, limit, search }
  };
}

export function transfersSalesRequestsResponse(response) {
  return {
    type: actionTypes.TRANSFERS_SALES_REQUESTS_RESPONSE,
    payload: response
  };
}

export function transfersSalesRequestsFailure(error) {
  return {
    type: actionTypes.TRANSFERS_SALES_REQUESTS_FAILURE,
    payload: error
  };
}

// Sales requests items
export function transfersSalesRequestsItemsRequest(
  draw, page, limit, search, salesRequestId, storeId
) {
  return {
    type: actionTypes.TRANSFERS_SALES_REQUESTS_ITEMS_REQUEST,
    payload: { salesRequestId, draw, page, limit, search, storeId }
  };
}

export function transfersSalesRequestsItemsResponse(
  response
) {
  return {
    type: actionTypes.TRANSFERS_SALES_REQUESTS_ITEMS_RESPONSE,
    payload: response
  };
}

export function transfersSalesRequestsItemsFailure(
  error
) {
  return {
    type: actionTypes.TRANSFERS_SALES_REQUESTS_ITEMS_FAILURE,
    payload: error
  };
}

// Delete sales request item
export function transfersDeleteSalesRequestsItemRequest(
  salesRequestsItemId
) {
  return {
    type: actionTypes.TRANSFERS_DELETE_SALES_REQUESTS_ITEM_REQUEST,
    payload: { salesRequestsItemId }
  };
}

export function transfersDeleteSalesRequestsItemResponse(
  response, resMessage
) {
  return {
    type: actionTypes.TRANSFERS_DELETE_SALES_REQUESTS_ITEM_RESPONSE,
    payload: { response, resMessage }
  };
}

export function transfersDeleteSalesRequestsItemFailure(
  error, resMessage
) {
  return {
    type: actionTypes.TRANSFERS_DELETE_SALES_REQUESTS_ITEM_FAILURE,
    payload: { error, resMessage }
  };
}

// Sales requests items for two tables based on the status
export function transfersSalesRequestsItemsQueuedAndCheckedRequest(
  queuedTableParams, checkedTableParams, fetchOptions
) {
  return {
    type: actionTypes.TRANSFERS_SALES_REQUESTS_ITEMS_QUEUED_AND_CHECKED_REQUEST,
    payload: { queuedTableParams, checkedTableParams, fetchOptions }
  };
}

export function transfersSalesRequestsItemsQueuedAndCheckedResponse(
  queuedList, checkedList, storeCardsById
) {
  return {
    type: actionTypes.TRANSFERS_SALES_REQUESTS_ITEMS_QUEUED_AND_CHECKED_RESPONSE,
    payload: { queuedList, checkedList, storeCardsById }
  };
}

export function transfersSalesRequestsItemsQueuedAndCheckedFailure(
  error
) {
  return {
    type: actionTypes.TRANSFERS_SALES_REQUESTS_ITEMS_QUEUED_AND_CHECKED_FAILURE,
    payload: error
  };
}

// Update sales requests item
export function transfersUpdateSalesRequestsItemRequest(
  salesRequestsItemId, updatedSRI
) {
  return {
    type: actionTypes.TRANSFERS_UPDATE_SALES_REQUESTS_ITEM_REQUEST,
    payload: { salesRequestsItemId, updatedSRI }
  };
}

export function transfersUpdateSalesRequestsItemResponse(
  response, resMessage
) {
  return {
    type: actionTypes.TRANSFERS_UPDATE_SALES_REQUESTS_ITEM_RESPONSE,
    payload: { response, resMessage }
  };
}

export function transfersUpdateSalesRequestsItemFailure(
  error, resMessage
) {
  return {
    type: actionTypes.TRANSFERS_UPDATE_SALES_REQUESTS_ITEM_FAILURE,
    payload: { error, resMessage }
  };
}

