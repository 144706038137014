import * as actionTypes from './administration.actionTypes';


// Reset response message
export function administrationResponseMessageReset() {
  return {
    type: actionTypes.ADMINISTRATION_RESPONSE_MESSAGE_RESET
  };
}

// Import fastcentrik by shop
export function administrationImportFastcentrikByShopRequest(file, shop) {
  return {
    type: actionTypes.ADMINISTRATION_IMPORT_FC_BY_SHOP_REQUEST,
    payload: { file, shop }
  };
}

export function administrationImportFastcentrikByShopResponse(response, resMessage) {
  return {
    type: actionTypes.ADMINISTRATION_IMPORT_FC_BY_SHOP_RESPONSE,
    payload: { response, resMessage }
  };
}

export function administrationImportFastcentrikByShopFailure(error, resMessage) {
  return {
    type: actionTypes.ADMINISTRATION_IMPORT_FC_BY_SHOP_FAILURE,
    payload: { error, resMessage }
  };
}

// Sync FC orders by shop and number of days back
export function administrationSyncFCDaysBackByShopRequest(shop, daysBackNumber) {
  return {
    type: actionTypes.ADMINISTRATION_SYNC_FC_DAYS_BACK_BY_SHOP_REQUEST,
    payload: { shop, daysBackNumber }
  };
}

export function administrationSyncFCDaysBackByShopResponse(response, resMessage) {
  return {
    type: actionTypes.ADMINISTRATION_SYNC_FC_DAYS_BACK_BY_SHOP_RESPONSE,
    payload: { response, resMessage }
  };
}

export function administrationSyncFCDaysBackByShopFailure(error, resMessage) {
  return {
    type: actionTypes.ADMINISTRATION_SYNC_FC_DAYS_BACK_BY_SHOP_FAILURE,
    payload: { error, resMessage }
  };
}

// Fetch print servers
export function administrationPrintServersRequest() {
  return {
    type: actionTypes.ADMINISTRATION_PRINT_SERVERS_REQUEST,
  };
}

export function administrationPrintServersResponse(response) {
  return {
    type: actionTypes.ADMINISTRATION_PRINT_SERVERS_RESPONSE,
    payload: response,
  };
}

export function administrationPrintServersFailure(error) {
  return {
    type: actionTypes.ADMINISTRATION_PRINT_SERVERS_FAILURE,
    payload: error,
  };
}

// Fetch printer list
export function administrationPrintersListRequest(printServerId, page, draw, limit) {
  return {
    type: actionTypes.ADMINISTRATION_PRINTERS_LIST_REQUEST,
    payload: { printServerId, page, draw, limit }
  };
}

export function administrationPrintersListResponse(list, total) {
  return {
    type: actionTypes.ADMINISTRATION_PRINTERS_LIST_RESPONSE,
    payload: { list, total }
  };
}

export function administrationPrintersListFailure(error) {
  return {
    type: actionTypes.ADMINISTRATION_PRINTERS_LIST_FAILURE,
    payload: error
  };
}

// Fetch print profile
export function administrationPrinterProfileRequest(printerId) {
  return {
    type: actionTypes.ADMINISTRATION_PRINTER_PROFILE_REQUEST,
    payload: { printerId }
  };
}

export function administrationPrinterProfileResponse(printerId, response) {
  return {
    type: actionTypes.ADMINISTRATION_PRINTER_PROFILE_RESPONSE,
    payload: { printerId, response }
  };
}

export function administrationPrinterProfileFailure(error) {
  return {
    type: actionTypes.ADMINISTRATION_PRINTER_PROFILE_FAILURE,
    payload: error
  };
}

export function administrationPrinterProfileStop() {
  return {
    type: actionTypes.ADMINISTRATION_PRINTER_PROFILE_STOP,
  };
}

// Update user's thermal printer
export function administrationUpdateUserThermalPrinterRequest(printerId, userUid) {
  return {
    type: actionTypes.ADMINISTRATION_UPDATE_USER_THERMAL_PRINTER_REQUEST,
    payload: { printerId, userUid }
  };
}

export function administrationUpdateUserThermalPrinterResponse(response, resMessage) {
  return {
    type: actionTypes.ADMINISTRATION_UPDATE_USER_THERMAL_PRINTER_RESPONSE,
    payload: { response, resMessage }
  };
}

export function administrationUpdateUserThermalPrinterFailure(error, resMessage) {
  return {
    type: actionTypes.ADMINISTRATION_UPDATE_USER_THERMAL_PRINTER_FAILURE,
    payload: { error, resMessage }
  };
}

// Update user's A4 printer
export function administrationUpdateUserA4PrinterRequest(printerId, userUid) {
  return {
    type: actionTypes.ADMINISTRATION_UPDATE_USER_A4_PRINTER_REQUEST,
    payload: { printerId, userUid }
  };
}

export function administrationUpdateUserA4PrinterResponse(response, resMessage) {
  return {
    type: actionTypes.ADMINISTRATION_UPDATE_USER_A4_PRINTER_RESPONSE,
    payload: { response, resMessage }
  };
}

export function administrationUpdateUserA4PrinterFailure(error, resMessage) {
  return {
    type: actionTypes.ADMINISTRATION_UPDATE_USER_A4_PRINTER_FAILURE,
    payload: { error, resMessage }
  };
}

// Airshop notifications
export function administrationAirshopNotificationsRequest(ident) {
  return {
    type: actionTypes.ADMINISTRATION_AIRSHOP_NOTIFICATIONS_REQUEST,
    payload: { ident }
  };
}

export function administrationAirshopNotificationsResponse(response, resMessage) {
  return {
    type: actionTypes.ADMINISTRATION_AIRSHOP_NOTIFICATIONS_RESPONSE,
    payload: { response, resMessage }
  };
}

export function administrationAirshopNotificationsFailure(error, resMessage) {
  return {
    type: actionTypes.ADMINISTRATION_AIRSHOP_NOTIFICATIONS_FAILURE,
    payload: { error, resMessage }
  };
}

// IP addresses
export function administrationIPAddressesListPageRequest(
  draw,
  page,
  limit,
  search,
) {
  return {
    type: actionTypes.ADMINISTRATION_IP_ADDRESSES_LIST_REQUEST,
    payload: { draw, page, limit, search }
  };
}

export function administrationIPAddressesListPageResponse(response) {
  return {
    type: actionTypes.ADMINISTRATION_IP_ADDRESSES_LIST_RESPONSE,
    payload: response
  };
}

export function administrationIPAddressesListPageFailure(error) {
  return {
    type: actionTypes.ADMINISTRATION_IP_ADDRESSES_LIST_FAILURE,
    payload: error
  };
}

// Create IP address
export function administrationCreateIPAddressRequest(newIPAddressObject) {
  return {
    type: actionTypes.ADMINISTRATION_CREATE_IP_ADDRESS_REQUEST,
    payload: newIPAddressObject
  };
}

export function administrationCreateIPAddressResponse(response, resMessage) {
  return {
    type: actionTypes.ADMINISTRATION_CREATE_IP_ADDRESS_RESPONSE,
    payload: { response, resMessage }
  };
}

export function administrationCreateIPAddressFailure(error, resMessage) {
  return {
    type: actionTypes.ADMINISTRATION_CREATE_IP_ADDRESS_FAILURE,
    payload: { error, resMessage }
  };
}

// Update IP address
export function administrationUpdateIPAddresRequest(ipAddressId, updatedIPAddressObject, refetchList) {
  return {
    type: actionTypes.ADMINISTRATION_UPDATE_IP_ADDRESS_REQUEST,
    payload: { ipAddressId, updatedIPAddressObject, refetchList }
  };
}

export function administrationUpdateIPAddressResponse(response, resMessage) {
  return {
    type: actionTypes.ADMINISTRATION_UPDATE_IP_ADDRESS_RESPONSE,
    payload: { response, resMessage }
  };
}

export function administrationUpdateIPAddressFailure(error, resMessage) {
  return {
    type: actionTypes.ADMINISTRATION_UPDATE_IP_ADDRESS_FAILURE,
    payload: { error, resMessage }
  };
}

// Delete IP address
export function administrationDeleteIPAddresRequest(ipAddressId) {
  return {
    type: actionTypes.ADMINISTRATION_DELETE_IP_ADDRESS_REQUEST,
    payload: { ipAddressId }
  };
}

export function administrationDeleteIPAddresResponse(response, resMessage) {
  return {
    type: actionTypes.ADMINISTRATION_DELETE_IP_ADDRESS_RESPONSE,
    payload: { response, resMessage }
  };
}

export function administrationDeleteIPAddresFailure(error) {
  return {
    type: actionTypes.ADMINISTRATION_DELETE_IP_ADDRESS_FAILURE,
    payload: error
  };
}

// Fetch app users (partners)
export function administrationPartnersListRequest() {
  return {
    type: actionTypes.ADMINISTRATION_PARTNERS_LIST_REQUEST,
  };
}

export function administrationPartnersListResponse(response) {
  return {
    type: actionTypes.ADMINISTRATION_PARTNERS_LIST_RESPONSE,
    payload: response,
  };
}

export function administrationPartnersListFailure(error) {
  return {
    type: actionTypes.ADMINISTRATION_PARTNERS_LIST_FAILURE,
    payload: error,
  };
}

// Abra commands list
export function administrationAbraCommandsListRequest(draw, page, limit, search, commandType) {
  return {
    type: actionTypes.ADMINISTRATION_ABRA_COMMANDS_LIST_REQUEST,
    payload: { draw, page, limit, search, commandType },
  };
}

export function administrationAbraCommandsListResponse(response) {
  return {
    type: actionTypes.ADMINISTRATION_ABRA_COMMANDS_LIST_RESPONSE,
    payload: response,
  };
}

export function administrationAbraCommandsListFailure(error) {
  return {
    type: actionTypes.ADMINISTRATION_ABRA_COMMANDS_LIST_FAILURE,
    payload: error,
  };
}

// Update user's thermal printer
export function administrationUpdateUserThermalPrinterByAdminRequest(printerId, userUid) {
  return {
    type: actionTypes.ADMINISTRATION_UPDATE_USER_THERMAL_PRINTER_BY_ADMIN_REQUEST,
    payload: { printerId, userUid }
  };
}

export function administrationUpdateUserThermalPrinterByAdminResponse(response, resMessage) {
  return {
    type: actionTypes.ADMINISTRATION_UPDATE_USER_THERMAL_PRINTER_BY_ADMIN_RESPONSE,
    payload: { response, resMessage }
  };
}

export function administrationUpdateUserThermalPrinterByAdminFailure(error, resMessage) {
  return {
    type: actionTypes.ADMINISTRATION_UPDATE_USER_THERMAL_PRINTER_BY_ADMIN_FAILURE,
    payload: { error, resMessage }
  };
}

// Update user's A4 printer
export function administrationUpdateUserA4PrinterByAdminRequest(printerId, userUid) {
  return {
    type: actionTypes.ADMINISTRATION_UPDATE_USER_A4_PRINTER_BY_ADMIN_REQUEST,
    payload: { printerId, userUid }
  };
}

export function administrationUpdateUserA4PrinterByAdminResponse(response, resMessage) {
  return {
    type: actionTypes.ADMINISTRATION_UPDATE_USER_A4_PRINTER_BY_ADMIN_RESPONSE,
    payload: { response, resMessage }
  };
}

export function administrationUpdateUserA4PrinterByAdminFailure(error, resMessage) {
  return {
    type: actionTypes.ADMINISTRATION_UPDATE_USER_A4_PRINTER_BY_ADMIN_FAILURE,
    payload: { error, resMessage }
  };
}

// Airshop backend error notifications
export function administrationAirshopNotificationsBackendErrorRequest(ident) {
  return {
    type: actionTypes.ADMINISTRATION_AIRSHOP_NOTIFICATIONS_BACKEND_ERROR_REQUEST,
    payload: { ident }
  };
}

export function administrationAirshopNotificationsBackendErrorResponse(response, resMessage) {
  return {
    type: actionTypes.ADMINISTRATION_AIRSHOP_NOTIFICATIONS_BACKEND_ERROR_RESPONSE,
    payload: { response, resMessage }
  };
}

export function administrationAirshopNotificationsBackendErrorFailure(error, resMessage) {
  return {
    type: actionTypes.ADMINISTRATION_AIRSHOP_NOTIFICATIONS_BACKEND_ERROR_FAILURE,
    payload: { error, resMessage }
  };
}

// Fetching a table page of templates
export function administrationTemplatesPageRequest(draw, page, limit, search, type, system) {
  return {
    type: actionTypes.ADMINISTRATION_TEMPLATES_REQUEST,
    payload: { draw, page, limit, search, type, system }
  };
}

export function administrationTemplatesPageResponse(response) {
  return {
    type: actionTypes.ADMINISTRATION_TEMPLATES_RESPONSE,
    payload: response
  };
}

export function administrationTemplatesPageFailure(error) {
  return {
    type: actionTypes.ADMINISTRATION_TEMPLATES_FAILURE,
    payload: error
  };
}

// Fetching a template
export function  administrationTemplateRequest(templateId) {
  return {
    type: actionTypes.ADMINISTRATION_TEMPLATE_REQUEST,
    payload: templateId
  };
}

export function  administrationTemplateResponse(templateData) {
  return {
    type: actionTypes.ADMINISTRATION_TEMPLATE_RESPONSE,
    payload: templateData
  };
}

export function  administrationTemplateFailure(error) {
  return {
    type: actionTypes.ADMINISTRATION_TEMPLATE_FAILURE,
    payload: error
  };
}

export function  administrationTemplateReset() {
  return {
    type: actionTypes.ADMINISTRATION_TEMPLATE_RESET,
  };
}

// Creating new template
export function  administrationCreateTemplateRequest(templateData) {
  return {
    type: actionTypes.ADMINISTRATION_CREATE_TEMPLATE_REQUEST,
    payload: templateData
  };
}

export function  administrationCreateTemplateResponse(response, resMessage) {
  return {
    type: actionTypes.ADMINISTRATION_CREATE_TEMPLATE_RESPONSE,
    payload: { response, resMessage }
  };
}

export function  administrationCreateTemplateFailure(error, resMessage) {
  return {
    type: actionTypes.ADMINISTRATION_CREATE_TEMPLATE_FAILURE,
    payload: { error, resMessage }
  };
}

// Editing new template
export function  administrationEditTemplateRequest(templateData) {
  return {
    type: actionTypes.ADMINISTRATION_EDIT_TEMPLATE_REQUEST,
    payload: templateData
  };
}

export function  administrationEditTemplateResponse(response, resMessage) {
  return {
    type: actionTypes.ADMINISTRATION_EDIT_TEMPLATE_RESPONSE,
    payload: { response, resMessage }
  };
}

export function  administrationEditTemplateFailure(error, resMessage) {
  return {
    type: actionTypes.ADMINISTRATION_EDIT_TEMPLATE_FAILURE,
    payload: { error, resMessage }
  };
}

// Deleting template
export function  administrationDeleteTemplateRequest(templateId) {
  return {
    type: actionTypes.ADMINISTRATION_DELETE_TEMPLATE_REQUEST,
    payload: templateId
  };
}

export function  administrationDeleteTemplateResponse(response) {
  return {
    type: actionTypes.ADMINISTRATION_DELETE_TEMPLATE_RESPONSE,
    payload: response
  };
}

export function  administrationDeleteTemplateFailure(error) {
  return {
    type: actionTypes.ADMINISTRATION_DELETE_TEMPLATE_FAILURE,
    payload: error
  };
}

// Send test email
export function administrationSendTestEmailRequest(code, email) {
  return {
    type: actionTypes.ADMINISTRATION_SEND_TEST_EMAIL_REQUEST,
    payload: { code, email }
  };
}

export function administrationSendTestEmailResponse(response, resMessage) {
  return {
    type: actionTypes.ADMINISTRATION_SEND_TEST_EMAIL_RESPONSE,
    payload: { response, resMessage }
  };
}

export function administrationSendTestEmailFailure(error, resMessage) {
  return {
    type: actionTypes.ADMINISTRATION_SEND_TEST_EMAIL_FAILURE,
    payload: { error, resMessage }
  }
}

// Fetching a list of template system items
export function administrationTemplateSystemsRequest() {
  return {
    type: actionTypes.ADMINISTRATION_TEMPLATE_SYSTEMS_REQUEST,
  };
}

export function administrationTemplateSystemsResponse(response) {
  return {
    type: actionTypes.ADMINISTRATION_TEMPLATE_SYSTEMS_RESPONSE,
    payload: response
  };
}

export function administrationTemplateSystemsFailure(error) {
  return {
    type: actionTypes.ADMINISTRATION_TEMPLATE_SYSTEMS_FAILURE,
    payload: error
  };
}
