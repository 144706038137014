import * as actionTypes from './emails.actionTypes'
import { EmailApi } from '../../CraftApi.axios'
import { from, of } from 'rxjs'
import { mergeMap, takeUntil, catchError, map } from 'rxjs/operators'
import { ofType } from 'redux-observable'

  export const fetchSentEmails = action$ =>
  action$.pipe(
    ofType(actionTypes.EMAILS_SENT_EMAILS_REQUEST),
    mergeMap(action => {
      const draw = action.payload.draw;
      const page = action.payload.page;
      return from(EmailApi.get('emails/admin/all', {
        params: {
          draw,
          page: 0,
        }
      }))
      .pipe(
          map(response => response.data.status === 'OK' ? response.data.total : null),
          mergeMap(totalItems => from(EmailApi.get('emails/admin/all', {
              params: {
                draw,
                limit: 20,
                order: 'dateSent',
                page: page * 20,
                sort: 'ASC',
              }
            }
          ))
            .pipe(
              map(response => response.data.status === 'OK' ? {
                items: response.data.items,
                total: response.data.total,
              } : null)
            )),
          map(tablePageData => {
            return {
              type: actionTypes.EMAILS_SENT_EMAILS_RESPONSE,
              payload: tablePageData,
            }
          }),
          catchError(error => of({
              type: actionTypes.EMAILS_SENT_EMAILS_FAILURE,
              payload: error,
            })
          ),
          takeUntil(action$.ofType(
            actionTypes.EMAILS_SENT_EMAILS_RESPONSE ||
              actionTypes.EMAILS_SENT_EMAILS_FAILURE
          ))
      )
    })
  );

export const fetchReceivedEmails = action$ =>
  action$.pipe(
    ofType(actionTypes.EMAILS_RECEIVED_EMAILS_REQUEST),
    mergeMap(action => {
      const draw = action.payload.draw;
      const page = action.payload.page;
      return from(EmailApi.get('emailIn/systemId/4', {
        params: {
          draw,
          page: 0,
        }
      }))
      .pipe(
          map(response => response.data.status === 'OK' ? response.data.total : null),
          mergeMap(totalItems => from(EmailApi.get('emailIn/systemId/4', {
              params: {
                draw,
                limit: 20,
                order: 'dateCreated',
                page: page * 20,
                sort: 'ASC',
              }
            }
          ))
            .pipe(
              map(response => response.data.status === 'OK' ? {
                items: response.data.items,
                total: response.data.total,
              } : null)
            )),
          map(tablePageData => {
            return {
              type: actionTypes.EMAILS_RECEIVED_EMAILS_RESPONSE,
              payload: tablePageData,
            }
          }),
          catchError(error => of({
              type: actionTypes.EMAILS_RECEIVED_EMAILS_FAILURE,
              payload: error,
            })
          ),
          takeUntil(action$.ofType(
            actionTypes.EMAILS_RECEIVED_EMAILS_RESPONSE ||
              actionTypes.EMAILS_RECEIVED_EMAILS_FAILURE
          ))
      )
    })
  );
