import * as actionTypes from './user-checks.actionTypes';

const initialState = {
  userIPAddress: null,
  shopTypeIpAddresses: null,
  generalTypeIpAddresses: null,
  resMessage: null,
  error: null,
  fetching: false,
}

const userChecksReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.USER_CHECKS_USER_IP_ADDRESS_CHECK_REQUEST:
      return {
        ...state,
        fetching: true,
      };
    case actionTypes.USER_CHECKS_USER_IP_ADDRESS_CHECK_RESPONSE:
      return {
        ...state,
        userIPAddress: action.payload.userIPAddress,
        shopTypeIpAddresses: action.payload.shopTypeIpAddresses,
        generalTypeIpAddresses: action.payload.generalTypeIpAddresses,
        fetching: false,
      };
    case actionTypes.USER_CHECKS_USER_IP_ADDRESS_CHECK_FAILURE:
      return {
        ...state,
        fetching: false,
        error: {...action.payload.error},
      };
    case actionTypes.USER_CHECKS_RESPONSE_MESSAGE_RESET:
      return {
        ...state,
        resMessage: null,
      };
    default:
      return state;
  }
};

export default userChecksReducer;
