import * as actionTypes from './mailchimp.actionTypes'
import * as actions from './mailchimp.actions'
import { from, of } from 'rxjs'
import { mergeMap, takeUntil, catchError, map } from 'rxjs/operators'
import { ofType } from 'redux-observable'
import { err, errMessages } from 'redux-store/utils/epics.utils'

import {
  getOrdersFetchReq,
  getApiValsByShop,
  getMailchimpEmailObjects,
  $hasStreamErrored,
  $subscribeBatchedEmails,
  $fetchAllEmailsByParams,
  $tagBatchAndCreateNewTag,
  $tagOtherPotentionalBatchesExceptFirst,
  $tagAllBatches,
} from './utils/mailchimp-streams.utils'

export const fetchOrdersPageEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.MAILCHIMP_ORDERS_LIST_REQUEST),
    mergeMap(action => {
      const {
        draw,
        page,
        limit,
      } = action.payload.list;
      return from(getOrdersFetchReq(action.payload.params, draw, limit, page))
      .pipe(
          map(response => response.data.status === 'OK' ? {
                items: response.data.items,
                total: response.data.total,
              } : err(errMessages.NOT_OK, response)
            ),
          map(tableData => actions.mailchimpOrdersListResponse(tableData)),
          catchError(error => of(actions.mailchimpOrdersListFailure(error))),
          takeUntil(action$.ofType(
            actionTypes.MAILCHIMP_ORDERS_LIST_RESPONSE ||
              actionTypes.MAILCHIMP_ORDERS_LIST_FAILURE
          ))
      )
    })
  );

export const fetchTagsListEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.MAILCHIMP_TAGS_LIST_BY_SHOP_REQUEST),
    mergeMap(action => {
      const { shop } = action.payload;
      const { api, listId } = getApiValsByShop(shop);

      return from(api.get(`lists/${listId}/segments`, {
        params: { count: 1000 } // 1000 is max
      }))
      .pipe(
          map(response => response.status === 200 ? response.data.segments : err(errMessages.NOT_200, response)),
          map(tags => actions.mailchimpTagsListByShopResponse(tags)),
          catchError(error => of(actions.mailchimpTagsListByShopFailure(error))),
          takeUntil(action$.ofType(
            actionTypes.MAILCHIMP_TAGS_LIST_BY_SHOP_RESPONSE ||
              actionTypes.MAILCHIMP_TAGS_LIST_BY_SHOP_FAILURE
          ))
      )
    })
  );

// ==== BULK TAF EMAILS TO EXISITING TAG =============================================
// ===================================================================================
export const bulkEmailsToNewTagEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.MAILCHIMP_BULK_EMAILS_TO_NEW_TAG_BY_SHOP_REQUEST),
    mergeMap(action => {
      const {
        shop, newTag, sendAllMode, selectedEmails, ordersParams
      } = action.payload;
      const draw = ordersParams && ordersParams.list ? ordersParams.list.draw : null;
      const { api, listId } = getApiValsByShop(shop);
      let proccessedEmails = [];

      if (sendAllMode && ordersParams) {
        return bulkEmailsTo_NEW_TAG_SEND_ALL_MODE_branch(
          action$, ordersParams, draw, api, listId, newTag, proccessedEmails
        );
      } else if (!sendAllMode && selectedEmails && selectedEmails.length) {
        proccessedEmails = [...selectedEmails];
        return bulkEmailsTo_NEW_TAG_SEND_SELECTED_MODE_branch(
          action$, api, listId, newTag, proccessedEmails
        )
      } else {
        return bulkEmailsTo_NEW_TAG_CONDITION_NOT_MET_branch(action$);
      }
    })
  );

  const bulkEmailsTo_NEW_TAG_SEND_ALL_MODE_branch = (astionsStream, ordersParams, draw, api, listId, newTag, proccessedEmails) => {
    return from(getOrdersFetchReq(ordersParams.params, draw))
    .pipe(
      map(response => response.data.status === 'OK' ? response.data.total : err(errMessages.NOT_OK, response)),
      $fetchAllEmailsByParams(ordersParams.params, draw, proccessedEmails),
      $subscribeBatchedEmails(api, listId),
      $tagBatchAndCreateNewTag(api, listId, newTag, proccessedEmails, true),
      $tagOtherPotentionalBatchesExceptFirst(api, listId, proccessedEmails),
      $hasStreamErrored(),
      ...getBulkEmailsToNewTagStreamEndingArr(astionsStream)
    )
  }

  const bulkEmailsTo_NEW_TAG_SEND_SELECTED_MODE_branch = (astionsStream, api, listId, newTag, proccessedEmails) => {
    return of(proccessedEmails)
      .pipe(
        map(emails => getMailchimpEmailObjects([emails])), // pass emails as a batch
        $subscribeBatchedEmails(api, listId),
        $tagBatchAndCreateNewTag(api, listId, newTag, proccessedEmails, false),
        $hasStreamErrored(),
        ...getBulkEmailsToNewTagStreamEndingArr(astionsStream)
      )
  }

  const bulkEmailsTo_NEW_TAG_CONDITION_NOT_MET_branch = astionsStream => {
    return of(undefined).pipe(
      map(val => err(errMessages.STREAM_CONDITIONAL)),
      ...getBulkEmailsToNewTagStreamEndingArr(astionsStream)
    )
  }

  const getBulkEmailsToNewTagStreamEndingArr = actionsStream => [
    map(response => actions.mailchimpBulkEmailsToNewTagByShopResponse(
      response,
      actionTypes.MAILCHIMP_BULK_EMAILS_TO_NEW_TAG_BY_SHOP_RESPONSE
    )),
    catchError(error => of(actions.mailchimpBulkEmailsToNewTagByShopFailure(
      error,
      actionTypes.MAILCHIMP_BULK_EMAILS_TO_NEW_TAG_BY_SHOP_FAILURE
    ))
    ),
    takeUntil(actionsStream.ofType(
      actionTypes.MAILCHIMP_BULK_EMAILS_TO_NEW_TAG_BY_SHOP_RESPONSE ||
        actionTypes.MAILCHIMP_BULK_EMAILS_TO_NEW_TAG_BY_SHOP_FAILURE
    ))
  ];

// ==== BULK TAF EMAILS TO NEW TAG ===================================================
// ===================================================================================
export const bulkEmailsToExistingTagEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.MAILCHIMP_BULK_EMAILS_TO_EXISTING_TAG_BY_SHOP_REQUEST),
    mergeMap(action => {
      const {
        shop, tagId, sendAllMode, selectedEmails, ordersParams
      } = action.payload;
      const draw = ordersParams && ordersParams.list ? ordersParams.list.draw : null;
      const { api, listId } = getApiValsByShop(shop);
      let proccessedEmails = [];

      if (sendAllMode && ordersParams) {
        return bulkEmailsTo_EXISTING_TAG_SEND_ALL_MODE_branch(
          action$, ordersParams, draw, api, listId, tagId, proccessedEmails
        );
      } else if (!sendAllMode && selectedEmails && selectedEmails.length) {
        proccessedEmails = [...selectedEmails];
        return bulkEmailsTo_EXISTING_TAG_SEND_SELECTED_MODE_branch(
          action$, api, listId, tagId, proccessedEmails
        )
      } else {
        return bulkEmailsTo_EXISTING_TAG_CONDITION_NOT_MET_branch(action$);
      }
    })
  );

const bulkEmailsTo_EXISTING_TAG_CONDITION_NOT_MET_branch = astionsStream => {
  return of(undefined).pipe(
    map(val => err(errMessages.STREAM_CONDITIONAL)),
    ...getBulkEmailsToExistingTagStreamEndingArr(astionsStream)
  )
}

const getBulkEmailsToExistingTagStreamEndingArr = (actionsStream) => [
  map(response => actions.mailchimpBulkEmailsToExistingTagByShopResponse(
    response,
    actionTypes.MAILCHIMP_BULK_EMAILS_TO_EXISTING_TAG_BY_SHOP_RESPONSE
  )),
  catchError(error => of(actions.mailchimpBulkEmailsToExistingTagByShopFailure(
    error,
    actionTypes.MAILCHIMP_BULK_EMAILS_TO_EXISTING_TAG_BY_SHOP_FAILURE
  ))
  ),
  takeUntil(actionsStream.ofType(
    actionTypes.MAILCHIMP_BULK_EMAILS_TO_EXISTING_TAG_BY_SHOP_RESPONSE ||
      actionTypes.MAILCHIMP_BULK_EMAILS_TO_EXISTING_TAG_BY_SHOP_FAILURE
  ))
];

const bulkEmailsTo_EXISTING_TAG_SEND_ALL_MODE_branch = (astionsStream, ordersParams, draw, api, listId, tagId, proccessedEmails) => {
  return from(getOrdersFetchReq(ordersParams.params, draw))
  .pipe(
    map(response => response.data.status === 'OK' ? response.data.total : err(errMessages.NOT_OK, response)),
    $fetchAllEmailsByParams(ordersParams.params, draw, proccessedEmails),
    $subscribeBatchedEmails(api, listId),
    $tagAllBatches(api, listId, tagId, proccessedEmails),
    $hasStreamErrored(),
    ...getBulkEmailsToExistingTagStreamEndingArr(astionsStream)
  )
}

const bulkEmailsTo_EXISTING_TAG_SEND_SELECTED_MODE_branch = (astionsStream, api, listId, tagId, proccessedEmails) => {
  return of(proccessedEmails)
    .pipe(
      map(emails => getMailchimpEmailObjects([emails])), // pass emails as a batch
      $subscribeBatchedEmails(api, listId),
      $tagAllBatches(api, listId, tagId, [proccessedEmails]), // pass emails as a batch
      $hasStreamErrored(),
      ...getBulkEmailsToExistingTagStreamEndingArr(astionsStream)
    )
}



