import { useSelector } from 'react-redux'

export function useTransfersSelectors() {
  const selectTransferWays = useSelector(state => state.transfers.transferWays);
  const selectResMessage = useSelector(state => state.transfers.resMessage);
  const selectFinishedTransfersBadgeCount = useSelector(state => state.transfers.finishedTransfersBadgeCount);
  const selectGeneratedPdfDocument = useSelector(state => state.transfers.generatedPdfDocument);
  const selectSalesRequests = useSelector(state => state.transfers.salesRequests);
  const selectRelatedSRIStoreCards = useSelector(state => state.transfers.relatedSRIStoreCards);

  // Transfers list
  const selectTransfersPageData = useSelector(state => state.transfers.transfersPageData);
  const selectTransfersTotal = useSelector(state => state.transfers.total);
  const selectTransfersPageDraw = useSelector(state => state.transfers.transfersPageDraw);
  const selectTransfersPagePage = useSelector(state => state.transfers.transfersPagePage);

  // Transfer detail
  const selectSelectedTransfer = useSelector(state => state.transfers.selectedTransfer);

  // Trasnfer detail items
  const selectItemsPageData = useSelector(state => state.transfers.selectedTransferItems.itemsPageData);
  const selectItemsPageDraw = useSelector(state => state.transfers.selectedTransferItems.itemsPageDraw);
  const selectItemsPagePage = useSelector(state => state.transfers.selectedTransferItems.itemsPagePage);
  const selectItemsPageLimit = useSelector(state => state.transfers.selectedTransferItems.itemsPageLimit);
  const selectItemsPageTotal = useSelector(state => state.transfers.selectedTransferItems.itemsPageTotal);
  const selectRelatedStoreCards = useSelector(state => state.transfers.selectedTransferItems.relatedStoreCards);
  const selectNewCreatedTransferItem = useSelector(state => state.transfers.selectedTransferItems.newCreatedTransferItem);

  // Sales requests items
  const selectSalesRequestsItemsPageData = useSelector(state => state.transfers.salesRequestsItemsPageData);
  const selectSalesRequestsItemsPageDraw = useSelector(state => state.transfers.salesRequestsItemsPageDraw);
  const selectSalesRequestsItemsPagePage = useSelector(state => state.transfers.salesRequestsItemsPagePage);
  const selectSalesRequestsItemsPageLimit = useSelector(state => state.transfers.salesRequestsItemsPageLimit);
  const selectSalesRequestsItemsTotal = useSelector(state => state.transfers.salesRequestsItemsTotal);

  // Wueued and checked sales requits items lists
  const selectSalesRequestsItemsQueuedPageData = useSelector(state => state.transfers.salesRequestsItemsQueuedPageData);
  const selectSalesRequestsItemsQueuedPageDraw = useSelector(state => state.transfers.salesRequestsItemsQueuedPageDraw);
  const selectSalesRequestsItemsQueuedPagePage = useSelector(state => state.transfers.salesRequestsItemsQueuedPagePage);
  const selectSalesRequestsItemsQueuedPageLimit = useSelector(state => state.transfers.salesRequestsItemsQueuedPageLimit);
  const selectSalesRequestsItemsQueuedTotal = useSelector(state => state.transfers.salesRequestsItemsQueuedTotal);
  const selectSalesRequestsItemsCheckedPageData = useSelector(state => state.transfers.salesRequestsItemsCheckedPageData);
  const selectSalesRequestsItemsCheckedPageDraw = useSelector(state => state.transfers.salesRequestsItemsCheckedPageDraw);
  const selectSalesRequestsItemsCheckedPagePage = useSelector(state => state.transfers.salesRequestsItemsCheckedPagePage);
  const selectSalesRequestsItemsCheckedPageLimit = useSelector(state => state.transfers.salesRequestsItemsCheckedPageLimit);
  const selectSalesRequestsItemsCheckedTotal = useSelector(state => state.transfers.salesRequestsItemsCheckedTotal);

  return {
    selectSalesRequestsItemsQueuedPageData,
    selectSalesRequestsItemsQueuedPageDraw,
    selectSalesRequestsItemsQueuedPagePage,
    selectSalesRequestsItemsQueuedPageLimit,
    selectSalesRequestsItemsQueuedTotal,
    selectSalesRequestsItemsCheckedPageData,
    selectSalesRequestsItemsCheckedPageDraw,
    selectSalesRequestsItemsCheckedPagePage,
    selectSalesRequestsItemsCheckedPageLimit,
    selectSalesRequestsItemsCheckedTotal,
    selectTransferWays,
    selectResMessage,
    selectTransfersPageData,
    selectTransfersTotal,
    selectTransfersPageDraw,
    selectTransfersPagePage,
    selectSelectedTransfer,
    selectItemsPageData,
    selectItemsPageDraw,
    selectItemsPagePage,
    selectItemsPageLimit,
    selectItemsPageTotal,
    selectRelatedStoreCards,
    selectNewCreatedTransferItem,
    selectFinishedTransfersBadgeCount,
    selectGeneratedPdfDocument,
    selectSalesRequests,
    selectSalesRequestsItemsPageData,
    selectSalesRequestsItemsPageDraw,
    selectSalesRequestsItemsPagePage,
    selectSalesRequestsItemsPageLimit,
    selectSalesRequestsItemsTotal,
    selectRelatedSRIStoreCards,
  }
}
