import React from 'react'
import { prefixes } from '../../routes'
import { Route } from 'react-router-dom'
import { AdminRoute } from 'generic/routing'

const AdministrationImportXML = React.lazy(() => import('./pages/AdministrationImportXML'));
const AdministrationImportDaysBack = React.lazy(() => import('./pages/AdministrationImportDaysBack'));
const AdministrationPersonalSettings = React.lazy(() => import('./pages/AdministrationPersonalSettings'));
const AdministrationPrintersList = React.lazy(() => import('./pages/AdministrationPrintersList'));
const AdministrationIPsList = React.lazy(() => import('./pages/AdministrationIPsList/AdministrationIPsList'));
const AdministrationUsersList = React.lazy(() => import('./pages/AdministrationUsersList/AdministrationUsersList'));
const AdministrationAbraCommandsList = React.lazy(() => import('./pages/AdministrationAbraCommandsList'));
const AdministrationPrintersListForAdmins = React.lazy(() => import('./pages/AdministrationPrintersListForAdmins'));
const TemplatesEmail = React.lazy(() => import('./pages/TemplatesEmail/TemplatesEmail'));
const TemplatesSMS = React.lazy(() => import('./pages/TemplatesSMS/TemplatesSMS'));

export const { administration } = prefixes;

// Used also in useProfileSetup.ts hooks
export const administrationPrintersPath = `${administration}/tiskarny`;

const administrationRoutes = [
  { path: `${administration}/import-xml`, exact: true, name: 'XML import produktu', component: AdministrationImportXML, routeType: AdminRoute },
  { path: `${administration}/import-zpetne`, exact: true, name: 'Import zpětně', component: AdministrationImportDaysBack, routeType: AdminRoute },
  { path: `${administration}/uzivatelske-nastaveni`, exact: true, name: 'Uživatelské nastavení', component: AdministrationPersonalSettings, routeType: Route },
  { path: administrationPrintersPath, exact: true, name: 'Nastavit si tiskárny', component: AdministrationPrintersList, routeType: Route },
  { path: `${administration}/ip-adresy`, exact: true, name: 'IP adresy', component: AdministrationIPsList, routeType: AdminRoute },
  { path: `${administration}/uzivatele`, exact: true, name: 'Uživatelé', component: AdministrationUsersList, routeType: AdminRoute },
  { path: `${administration}/prikazy-abry`, exact: true, name: 'Příkazy Abry', component: AdministrationAbraCommandsList, routeType: AdminRoute },
  { path: `${administration}/tiskarny-admin`, exact: true, name: 'Nastavit tiskárny uživatelům', component: AdministrationPrintersListForAdmins, routeType: AdminRoute },
  { path: `${administration}/email-sablony`, exact: true, name: 'Eamil šablony Editor', component: TemplatesEmail, routeType: AdminRoute },
  { path: `${administration}/email-sablony-ostry`, exact: true, name: 'Eamil šablony', component: TemplatesEmail, routeType: AdminRoute },
  { path: `${administration}/sms-sablony`, exact: true, name: 'SMS šablony', component: TemplatesSMS, routeType: AdminRoute },
];

export default administrationRoutes;
