import * as actionTypes from './rating.actionTypes'

const initialState = {
  beersWithWebsitesPageData: null,
  beersWithWebsitesPageDraw: null,
  beersWithWebsitesPagePage: 0,
  beersWithWebsitesTotal: null,
  beersWithWebsitesPageLimit: 50,
  websitesList: null,
  editedBeer: null,
  editedBeerWithWebsitesByBeerId: null,
  resMessage: null,
  error: null,
  fetching: false,
}

const ratingReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.RATING_RESPONSE_MESSAGE_RESET:
      return {
        ...state,
        resMessage: null,
      };
    case actionTypes.RATING_WEBSITES_LIST_REQUEST:
    case actionTypes.RATING_BEER_BY_ID_REQUEST:
    case actionTypes.RATING_UPDATE_BEER_BY_ID_REQUEST:
    case actionTypes.RATING_DELETE_BEER_BY_ID_REQUEST:
    case actionTypes.RATING_TOGGLE_ACTIVE_BEER_BY_ID_REQUEST:
    case actionTypes.RATING_BEER_WITH_WEBSITES_BY_BEER_ID_REQUEST:
    case actionTypes.RATING_CREATE_BEER_WITH_WEBSITES_REQUEST:
    case actionTypes.RATING_UPDATE_BEER_WITH_WEBSITES_BY_ID_REQUEST:
    case actionTypes.RATING_DELETE_BEER_WITH_WEBSITES_BY_ID_REQUEST:
    case actionTypes.RATING_BEER_WITH_WEBSITES_MULTI_REQUESTS_REQUEST:
      return {
        ...state,
        fetching: true,
      };
    case actionTypes.RATING_BEERS_WITH_WEBSITES_REQUEST:
      return {
        ...state,
        beersWithWebsitesPageDraw: action.payload.draw,
        beersWithWebsitesPagePage: action.payload.page,
        beersWithWebsitesPageLimit: action.payload.limit,
        fetching: true,
      };
    case actionTypes.RATING_BEERS_WITH_WEBSITES_RESPONSE:
      return {
        ...state,
        beersWithWebsitesPageData: action.payload.items,
        beersWithWebsitesTotal: action.payload.total,
        fetching: false,
      };
    case actionTypes.RATING_WEBSITES_LIST_RESPONSE:
      return {
        ...state,
        websitesList: action.payload,
        fetching: false,
      };
    case actionTypes.RATING_BEER_BY_ID_RESPONSE:
      return {
        ...state,
        editedBeer: action.payload,
        fetching: false,
      };
    case actionTypes.RATING_BEER_WITH_WEBSITES_BY_BEER_ID_RESPONSE:
      return {
        ...state,
        editedBeerWithWebsitesByBeerId: action.payload,
        fetching: false,
      };
    case actionTypes.RATING_UPDATE_BEER_BY_ID_RESPONSE:
    case actionTypes.RATING_DELETE_BEER_BY_ID_RESPONSE:
    case actionTypes.RATING_CREATE_BEER_WITH_WEBSITES_RESPONSE:
    case actionTypes.RATING_UPDATE_BEER_WITH_WEBSITES_BY_ID_RESPONSE:
    case actionTypes.RATING_DELETE_BEER_WITH_WEBSITES_BY_ID_RESPONSE:
      return {
        ...state,
        fetching: false,
      };
    case actionTypes.RATING_TOGGLE_ACTIVE_BEER_BY_ID_RESPONSE:
    case actionTypes.RATING_BEER_WITH_WEBSITES_MULTI_REQUESTS_RESPONSE:
      return {
        ...state,
        resMessage: action.payload.resMessage,
        fetching: false,
      };
    case actionTypes.RATING_BEERS_WITH_WEBSITES_FAILURE:
    case actionTypes.RATING_WEBSITES_LIST_FAILURE:
    case actionTypes.RATING_BEER_BY_ID_FAILURE:
    case actionTypes.RATING_UPDATE_BEER_BY_ID_FAILURE:
    case actionTypes.RATING_DELETE_BEER_BY_ID_FAILURE:
    case actionTypes.RATING_BEER_WITH_WEBSITES_BY_BEER_ID_FAILURE:
    case actionTypes.RATING_CREATE_BEER_WITH_WEBSITES_FAILURE:
    case actionTypes.RATING_UPDATE_BEER_WITH_WEBSITES_BY_ID_FAILURE:
    case actionTypes.RATING_DELETE_BEER_WITH_WEBSITES_BY_ID_FAILURE:
      return {
        ...state,
        fetching: false,
        error: {...action.payload}
      };
    case actionTypes.RATING_TOGGLE_ACTIVE_BEER_BY_ID_FAILURE:
    case actionTypes.RATING_BEER_WITH_WEBSITES_MULTI_REQUESTS_FAILURE:
      return {
        ...state,
        resMessage: action.payload.resMessage,
        fetching: false,
        error: {...action.payload}
      };
    default:
      return state;
  }
};

export default ratingReducer;
