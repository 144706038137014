import React from 'react'
import { prefixes } from '../../routes'

const OrdersLayout = React.lazy(() => import('./pages/OrdersLayout/OrdersLayout'));

export const { orders } = prefixes;
export const ordersStatePrefix = 'stav';
export const orderDetailPrefix = 'detail';

const orderRoutes = [
  { path: `${orders}`, exact: true, name: 'Objednávky', component: OrdersLayout },
  { path: `${orders}/${orderDetailPrefix}/:id`, exact: true, name: 'Detail objednávky', component: OrdersLayout },
  { path: `${orders}/${ordersStatePrefix}/:id`, exact: true, name: 'Objednávky', component: OrdersLayout },

];

export default orderRoutes;
