import { notificationTypes } from './legacy/native-notification-types'
import * as storeCardsTypes from 'redux-store/store-cards/store-cards.actionTypes'
import * as administrationTypes from 'redux-store/administration/administration.actionTypes'
import * as ordersTypes from 'redux-store/orders/orders.actionTypes'
import * as mailchimpTypes from 'redux-store/mailchimp/mailchimp.actionTypes'
import * as transfersTypes from 'redux-store/transfers/transfers.actionTypes'
import * as collectionListsTypes from 'redux-store/collection-lists/collection-lists.actionTypes'
import * as ratingTypes from 'redux-store/rating/rating.actionTypes'
import * as targetsActionsTypes from 'redux-store/targets/targets.actionTypes'
import * as rentActionsTypes from 'redux-store/rent/rent.actionTypes'

  // Get the right predefined notification object based on the prop type
export const getPropertyTypeName = type => {
  switch (type) {
    case notificationTypes.CREATE_SUCCESS:
      return 'createItemSuccess';
    case notificationTypes.CREATE_FAILURE:
      return 'createItemFailure';
    case notificationTypes.READ_FAILURE:
      return 'readItemFailure';
    case notificationTypes.EDIT_SUCCESS:
      return 'editItemSuccess';
    case notificationTypes.EDIT_FAILURE:
      return 'editItemFailure';
    case notificationTypes.DELETE_SUCCESS:
      return 'deleteItemSuccess';
    case notificationTypes.DELETE_FAILURE:
      return 'deleteItemFailure';
    case notificationTypes.RESET_FORM_SUCCESS:
      return 'resetFormSuccess';
    case notificationTypes.RESET_FORM_FAILURE:
      return 'resetFormFailure';
    case notificationTypes.SEND_M_SUCCESS:
      return 'sendMasculineItemSuccess';
    case notificationTypes.SEND_M_FAILURE:
      return 'sendMasculineItemFailure';
    case notificationTypes.SEND_F_SUCCESS:
      return 'sendFeminineItemSuccess';
    case notificationTypes.SEND_F_FAILURE:
      return 'sendFeminineItemFailure';
    case notificationTypes.CHANGE_PRODUCT_Q_SUCCESS:
      return 'changeProductQuantitySuccess';
    case notificationTypes.CHANGE_PRODUCT_Q_FAILURE:
      return 'changeProductQuantityFailure';
    case notificationTypes.SYNC_F_SUCCESS:
      return 'syncFeminineSuccess';
    case notificationTypes.SYNC_F_FAILURE:
      return 'syncFeminineFailure';
    case notificationTypes.DL_CREATE_SUCCESS:
      return 'deliveryListCreateSuccess';
    case notificationTypes.DL_CREATE_FAILURE:
      return 'deliveryListCreateFailure';
    case notificationTypes.DL_RECREATE_SUCCESS:
      return 'deliveryListCreateSuccess';
    case notificationTypes.DL_RECREATE_FAILURE:
      return 'deliveryListCreateFailure';
    case notificationTypes.CREATE_TRANSFER_SUCCESS:
      return 'createTransferSuccess';
    case notificationTypes.CREATE_TRANSFER_FAILURE:
      return 'createTransferFailure';
    case notificationTypes.TRANSFER_STATUS_CHANGE_SUCCESS:
      return 'transferStatusChangeSuccess';
    case notificationTypes.TRANSFER_STATUS_CHANGE_FAILURE:
      return 'transferStatusChangeFailure';
    case notificationTypes.TRANSFER_DETAIL_CREATE_ITEM_SUCCESS:
      return 'transferDetailCreateItemSuccess';
    case notificationTypes.TRANSFER_DETAIL_CREATE_ITEM_FAILURE:
      return 'transferDetailCreateItemFailure';
    case notificationTypes.TRANSFER_DETAIL_UPDATE_ITEM_QUANTITY_SUCCESS:
      return 'transferDetailUpdateItemQuantitySuccess';
    case notificationTypes.TRANSFER_DETAIL_UPDATE_ITEM_QUANTITY_FAILURE:
      return 'transferDetailUpdateItemQuantityFailure';
    case notificationTypes.TRANSFER_DETAIL_ITEM_FROM_ORDER_SUCCESS:
      return 'transferDetailCreateItemSuccess';
    case notificationTypes.TRANSFER_DETAIL_ITEM_FROM_ORDER_FAILURE:
      return 'transferDetailCreateItemFailure';
    case storeCardsTypes.STORE_CARDS_BULK_CHANGE_EXPORT_NULL_QUANTITY_RESPONSE:
      return 'changesSentSuccess';
    case storeCardsTypes.STORE_CARDS_BULK_CHANGE_EXPORT_NULL_QUANTITY_FAILURE:
      return 'changesSentFailure';
    case administrationTypes.ADMINISTRATION_IMPORT_FC_BY_SHOP_RESPONSE:
      return 'FCImportSuccess';
    case administrationTypes.ADMINISTRATION_IMPORT_FC_BY_SHOP_FAILURE:
      return 'FCImportFailure';
    case administrationTypes.ADMINISTRATION_SYNC_FC_DAYS_BACK_BY_SHOP_RESPONSE:
      return 'FCSyncByDaysBackSuccess';
    case administrationTypes.ADMINISTRATION_SYNC_FC_DAYS_BACK_BY_SHOP_FAILURE:
      return 'FCSyncByDaysBackFailure';
    case ordersTypes.ORDERS_UPDATE_STAV_OBJEDNAVKY_RESPONSE:
      return 'changeOrderStateSuccess';
    case ordersTypes.ORDERS_UPDATE_STAV_OBJEDNAVKY_FAILURE:
      return 'changeOrderStateFailure';
    case ordersTypes.ORDERS_SYNC_ABRA_WHILE_IN_ABRA_RESPONSE:
      return 'generalSyncSuccess';
    case ordersTypes.ORDERS_SYNC_ABRA_WHILE_IN_ABRA_FAILURE:
      return 'generalSyncFailure';
    case mailchimpTypes.MAILCHIMP_BULK_EMAILS_TO_NEW_TAG_BY_SHOP_RESPONSE:
    case mailchimpTypes.MAILCHIMP_BULK_EMAILS_TO_EXISTING_TAG_BY_SHOP_RESPONSE:
      return 'mailchimpSuccess';
    case mailchimpTypes.MAILCHIMP_BULK_EMAILS_TO_NEW_TAG_BY_SHOP_FAILURE:
    case mailchimpTypes.MAILCHIMP_BULK_EMAILS_TO_EXISTING_TAG_BY_SHOP_FAILURE:
      return 'mailchimpFailure';
    case administrationTypes.ADMINISTRATION_UPDATE_USER_THERMAL_PRINTER_RESPONSE:
    case administrationTypes.ADMINISTRATION_UPDATE_USER_A4_PRINTER_RESPONSE:
    case administrationTypes.ADMINISTRATION_UPDATE_USER_THERMAL_PRINTER_BY_ADMIN_RESPONSE:
    case administrationTypes.ADMINISTRATION_UPDATE_USER_A4_PRINTER_BY_ADMIN_RESPONSE:
      return 'updatePrinterSuccess';
    case administrationTypes.ADMINISTRATION_UPDATE_USER_THERMAL_PRINTER_FAILURE:
    case administrationTypes.ADMINISTRATION_UPDATE_USER_A4_PRINTER_FAILURE:
    case administrationTypes.ADMINISTRATION_UPDATE_USER_THERMAL_PRINTER_BY_ADMIN_FAILURE:
    case administrationTypes.ADMINISTRATION_UPDATE_USER_A4_PRINTER_BY_ADMIN_FAILURE:
      return 'updatePrinterFailure';
    case ordersTypes.ORDERS_ORDER_CREATE_ITEM_RESPONSE:
      return 'orderDetailCreateItemSuccess';
    case ordersTypes.ORDERS_ORDER_CREATE_ITEM_FAILURE:
      return 'orderDetailCreateItemFailure';
    case ordersTypes.ORDERS_UPDATE_BULK_ORDERS_STAV_OBJEDNAVKY_RESPONSE:
      return 'bulkOrdersStateChangeSuccess';
    case ordersTypes.ORDERS_UPDATE_BULK_ORDERS_STAV_OBJEDNAVKY_FAILURE:
      return 'bulkOrdersStateChangeFailure';
    case ordersTypes.ORDERS_MASS_GENERATE_PDF_FAILURE:
    case ordersTypes.ORDERS_GENERATE_PDF_FAILURE:
    case transfersTypes.TRANSFERS_GENERATE_PDF_FAILURE:
      return 'generatePdfFailure';
    case storeCardsTypes.STORE_CARDS_EDIT_STORE_CARD_RESPONSE:
      return 'storeCardEditSuccess';
    case storeCardsTypes.STORE_CARDS_EDIT_STORE_CARD_FAILURE:
      return 'storeCardEditFailure';
    case storeCardsTypes.STORE_CARDS_DELETE_EAN_RESPONSE:
      return 'deleteEanSuccess';
    case storeCardsTypes.STORE_CARDS_DELETE_EAN_FAILURE:
      return 'deleteEanFailure';
    case storeCardsTypes.STORE_CARDS_EDIT_MULTIPLE_STORE_CARDS_RESPONSE:
      return 'multipleStoreCardsEditSuccess';
    case storeCardsTypes.STORE_CARDS_EDIT_MULTIPLE_STORE_CARDS_FAILURE:
      return 'multipleStoreCardsEditFailure';
    case administrationTypes.ADMINISTRATION_UPDATE_IP_ADDRESS_RESPONSE:
      return 'updateIPAddressSuccess';
    case administrationTypes.ADMINISTRATION_UPDATE_IP_ADDRESS_FAILURE:
      return 'updateIPAddressFailure';
    case collectionListsTypes.COLLECTION_LISTS_COLLECTION_LIST_STATUS_CHANGE_RESPONSE:
      return 'changeCollectionListStatusSuccess';
    case collectionListsTypes.COLLECTION_LISTS_COLLECTION_LIST_STATUS_CHANGE_FAILURE:
      return 'changeCollectionListStatusFailure';
    case collectionListsTypes.COLLECTION_LISTS_LIST_DETAIL_ITEM_DELETE_RESPONSE:
      return 'deleteCollectionListDetailItemSuccess';
    case collectionListsTypes.COLLECTION_LISTS_LIST_DETAIL_ITEM_DELETE_FAILURE:
      return 'deleteCollectionListDetailItemFailure';
    case ordersTypes.ORDERS_CREATE_CLITEM_FROM_ORDER_RESPONSE:
    case ordersTypes.ORDERS_CREATE_CLITEMS_FROM_MULTIPLE_ORDERS_RESPONSE:
      return 'createCLItemFromOrderSuccess';
    case ordersTypes.ORDERS_CREATE_CLITEM_FROM_ORDER_FAILURE:
    case ordersTypes.ORDERS_CREATE_CLITEMS_FROM_MULTIPLE_ORDERS_FAILURE:
      return 'createCLItemFromOrderFailure';
    case ordersTypes.ORDERS_CREATE_BALIKOBOT_ORDER_ENTRY_RESPONSE:
    case ordersTypes.ORDERS_UPDATE_BALIKOBOT_ORDER_ENTRY_RESPONSE:
      return 'updateBalikobotOrderEntrySuccess';
    case ordersTypes.ORDERS_CREATE_BALIKOBOT_ORDER_ENTRY_FAILURE:
    case ordersTypes.ORDERS_UPDATE_BALIKOBOT_ORDER_ENTRY_FAILURE:
      return 'updateBalikobotOrderEntryFailure';
    case ordersTypes.ORDERS_GENERATE_BALIKOBOT_TAG_RESPONSE:
      return 'generateBalikobotOrderEntrySuccess';
    case ordersTypes.ORDERS_GENERATE_BALIKOBOT_TAG_FAILURE:
      return 'generateBalikobotOrderEntryFailure';
    case ordersTypes.ORDERS_CREATE_ORDER_SUBSCRIPTION_RESPONSE:
    case ordersTypes.ORDERS_CREATE_ORDER_SUBSCRIPTION_RESPONSE + '_SUBORDER':
    case ordersTypes.ORDERS_MULTIPLE_CREATE_ORDER_SUBSCRIPTION_RESPONSE:
      return 'subscriptionOrderSuccess';
    case ordersTypes.ORDERS_CREATE_ORDER_SUBSCRIPTION_FAILURE:
    case ordersTypes.ORDERS_MULTIPLE_CREATE_ORDER_SUBSCRIPTION_FAILURE:
      return 'subscriptionOrderFailure';
    case ordersTypes.ORDERS_DELETE_ORDER_SUBSCRIPTION_RESPONSE:
      return 'deleteSubscriptionOrderSuccess';
    case ordersTypes.ORDERS_DELETE_ORDER_SUBSCRIPTION_FAILURE:
      return 'deleteSubscriptionOrderFailure';

    case ordersTypes.ORDERS_SET_SELECTED_ORDER_ADDRESS_RESPONSE:
      return 'setOrderAddressSuccess';
    case ordersTypes.ORDERS_SET_SELECTED_ORDER_ADDRESS_FAILURE:
      return 'setOrderAddressFailure';

    case ordersTypes.ORDERS_EDIT_ORDER_SUBSCRIPTION_RESPONSE:
      return 'editSubscriptionOrderSuccess';
    case ordersTypes.ORDERS_EDIT_ORDER_SUBSCRIPTION_FAILURE:
      return 'editSubscriptionOrderFailure';

    case ratingTypes.RATING_TOGGLE_ACTIVE_BEER_BY_ID_RESPONSE:
      return 'changeBeerActiveSuccess';
    case ratingTypes.RATING_TOGGLE_ACTIVE_BEER_BY_ID_FAILURE:
      return 'changeBeerActiveFailure';
    case ratingTypes.RATING_BEER_WITH_WEBSITES_MULTI_REQUESTS_RESPONSE:
      return 'changeBeerDetailSuccess';
    case ratingTypes.RATING_BEER_WITH_WEBSITES_MULTI_REQUESTS_FAILURE:
      return 'changeBeerDetailFailure';
    case ratingTypes.RATING_DELETE_BEER_BY_ID_RESPONSE:
      return 'deleteBeerSuccess';
    case ratingTypes.RATING_DELETE_BEER_BY_ID_FAILURE:
      return 'deleteBeerFailure';
    case targetsActionsTypes.TARGETS_CREATE_MULTIPLE_COMMODITY_TARGETS_RESPONSE:
      return 'addCommodityToTargetSuccess';
    case targetsActionsTypes.TARGETS_CREATE_MULTIPLE_COMMODITY_TARGETS_FAILURE:
      return 'addCommodityToTargetFailure';
    case targetsActionsTypes.TARGETS_DELETE_MULTIPLE_COMMODITY_TARGETS_RESPONSE:
      return 'deleteCommodityTargetsSuccess';
    case targetsActionsTypes.TARGETS_DELETE_MULTIPLE_COMMODITY_TARGETS_FAILURE:
      return 'deleteCommodityTargetsFailure';
    case targetsActionsTypes.TARGETS_CREATE_KEG_PRODUCT_RESPONSE:
      return 'createKegFromProductSuccess';
    case targetsActionsTypes.TARGETS_CREATE_KEG_PRODUCT_FAILURE:
      return 'createKegFromProductFailure';
    case targetsActionsTypes.TARGETS_EDIT_COMMODITY_TARGET_RESPONSE:
    case targetsActionsTypes.TARGETS_CREATE_TARGET_COMMODITY_PROPERTY_RESPONSE:
    case targetsActionsTypes.TARGETS_EDIT_TARGET_COMMODITY_PROPERTY_RESPONSE:
      return 'editCommodityTargetSuccess';
    case targetsActionsTypes.TARGETS_EDIT_COMMODITY_TARGET_FAILURE:
    case targetsActionsTypes.TARGETS_CREATE_TARGET_COMMODITY_PROPERTY_FAILURE:
    case targetsActionsTypes.TARGETS_EDIT_TARGET_COMMODITY_PROPERTY_FAILURE:
      return 'editCommodityTargetFailure';
    case targetsActionsTypes.TARGETS_EDIT_MULTIPLE_TARGET_COMMODITY_TARGETS_RESPONSE:
      return 'selectedItemsUpdatedtSuccess';
    case targetsActionsTypes.TARGETS_EDIT_MULTIPLE_TARGET_COMMODITY_TARGETS_FAILURE:
      return 'selectedItemsUpdatedtFailure';
    case targetsActionsTypes.TARGETS_EDIT_TARGET_CATEGORY_RESPONSE:
      return 'editTargetCategorySuccess';
    case targetsActionsTypes.TARGETS_EDIT_TARGET_CATEGORY_FAILURE:
      return 'editTargetCategoryFailure';
    case targetsActionsTypes.TARGETS_EDIT_MANUFACTURER_RESPONSE:
      return 'editManufacturerSuccess';
    case targetsActionsTypes.TARGETS_EDIT_MANUFACTURER_FAILURE:
      return 'editManufacturerFailure';
    case rentActionsTypes.RENT_FINALIZE_CONTRACT_RESPONSE:
      return 'generateRentingContractSuccess';
    case rentActionsTypes.RENT_FINALIZE_CONTRACT_FAILURE:
      return 'generateRentingContractFailure';
    case targetsActionsTypes.TARGETS_EDIT_TARGET_COMMODITY_CATEGORY_RESPONSE:
      return 'editTargetCommodityCategorySuccess';
    case targetsActionsTypes.TARGETS_EDIT_TARGET_COMMODITY_CATEGORY_FAILURE:
      return 'editTargetCommodityCategoryFailure';
    case rentActionsTypes.RENT_EDIT_CONTRACT_RESPONSE:
      return 'editRentingContractSuccess';
    case rentActionsTypes.RENT_EDIT_CONTRACT_FAILURE:
      return 'editRentingContractFailure';
    case rentActionsTypes.RENT_EDIT_CUSTOMER_RESPONSE:
      return 'editRentingCustomerSuccess';
    case rentActionsTypes.RENT_EDIT_CUSTOMER_FAILURE:
      return 'editRentingCustomerFailure';
    case rentActionsTypes.RENT_CREATE_DEPOSIT_RESPONSE:
      return 'createRentingDepositSuccess';
    case rentActionsTypes.RENT_CREATE_DEPOSIT_FAILURE:
      return 'createRentingDepositFailure';
    case rentActionsTypes.RENT_EDIT_DEPOSIT_RESPONSE:
      return 'editRentingDepositSuccess';
    case rentActionsTypes.RENT_EDIT_DEPOSIT_FAILURE:
      return 'editRentingDepositFailure';
    case rentActionsTypes.RENT_DELETE_CONTRACT_RESPONSE:
      return 'deleteRentingContractSuccess';
    case rentActionsTypes.RENT_DELETE_CONTRACT_FAILURE:
      return 'deleteRentingContractFailure';
    case rentActionsTypes.RENT_EDIT_RESERVATION_RESPONSE:
      return 'editRentinReservationSuccess';
    case rentActionsTypes.RENT_EDIT_RESERVATION_FAILURE:
      return 'editRentinReservationFailure';
    case rentActionsTypes.RENT_REMIND_RENT_CONTRACT_RESPONSE:
      return 'remindRentContractSuccess';
    case rentActionsTypes.RENT_REMIND_RENT_CONTRACT_FAILURE:
      return 'remindRentContractFailure';
    case administrationTypes.ADMINISTRATION_SEND_TEST_EMAIL_RESPONSE:
      return 'sentEmailSuccess';
    case administrationTypes.ADMINISTRATION_SEND_TEST_EMAIL_FAILURE:
      return 'sentEmailFailure';
    case administrationTypes.ADMINISTRATION_EDIT_TEMPLATE_RESPONSE:
      return 'editTemplateSuccess';
    case administrationTypes.ADMINISTRATION_EDIT_TEMPLATE_FAILURE:
      return 'editTemplateFailure';
    case notificationTypes.CUSTOM:
      return null;
    default:
      return null;
  }
}
