import { useEffect, useState, useRef } from 'react'
import { useUserChecksFacade } from 'redux-store/user-cheks/user-checks.facade'
import { useAuthFacade } from 'redux-store/auth/auth.facade'
import ipRangeCheck from 'ip-range-check'
import { useHistory } from 'react-router-dom'

export const useIPCheck = () => {

  const {
    userIPAddress,
    generalTypeIpAddresses,
    shopTypeIpAddresses,
   } = useUserChecksFacade();

  const { idm } = useAuthFacade();

  const [userIpWhitelisted, setUserIpWhitelisted] = useState(null);
  let { current: isIpAddressWhitelisted } = useRef();

  const handleShopTypeIpAddressesComparison = () => {
    const { roleName } = idm;
    // 1st check - SHOP TYPE & ALLOWED -OR- USER role ADMIN
    const isAdmin = roleName.split(',').includes('admin')
    // All these addresses have 32 mask -> static, looking only for an exact match
    const masklessGeneralTypeIpAddresses = shopTypeIpAddresses
                                             .map(ipAddress => ipAddress.split('/')[0]);
    isIpAddressWhitelisted = masklessGeneralTypeIpAddresses.includes(userIPAddress) || isAdmin;

    if (isIpAddressWhitelisted) setUserIpWhitelisted(true);
  }

  const handleGeneralTypeIpAddressesComparison = () => {
    const { roleName } = idm;
    // 2nd check - GENERAL TYPE & ALLOWED & IPFILTER (USER ROLE)
    // These IP addresses may not be static (non-32), necessary to check the ranges
    if (roleName.split(',').includes('ipfilter')) {
      isIpAddressWhitelisted = generalTypeIpAddresses.some(
        ipAddressWithMask => ipRangeCheck(userIPAddress, ipAddressWithMask)
      );

      if (isIpAddressWhitelisted) setUserIpWhitelisted(true);
    }
  }

  useEffect(() => {
    if (userIPAddress) {
      handleShopTypeIpAddressesComparison();
      if (isIpAddressWhitelisted) return;
      handleGeneralTypeIpAddressesComparison();
      if (isIpAddressWhitelisted) return;
      // If handling above has not assigned to true val
      setUserIpWhitelisted(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userIPAddress]);


  return { userIpWhitelisted }

}

export const useHandleRedirectOnIpAddressNotAllowed = (userIpWhitelisted, location) => {
  const history = useHistory();

  const handleIpCheckResult = () => {
    if (typeof userIpWhitelisted === 'boolean' && !userIpWhitelisted) {
      if (location && location.pathname) {
        history.push('/ip-nepovolena')
      }
    }
  }

  useEffect(() => {
    handleIpCheckResult();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userIpWhitelisted, location]);
}
