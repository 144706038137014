export const AUTH_KEYCLOACK = '[AUTH]_KEYCLOACK';
export const AUTH_IDM_REQUEST = '[AUTH]_IDM_REQUEST'
export const AUTH_IDM_RESPONSE = '[AUTH]_IDM_RESPONSE'
export const AUTH_IDM_FAILURE = '[AUTH]_IDM_FAILURE'
export const AUTH_RESPONSE_MESSAGE_RESET = '[AUTH]_RESPONSE_MESSAGE_RESET'

export function storeKeycloakAuth(authObject) {
  return {
    type: AUTH_KEYCLOACK,
    payload: authObject
  };
}

export function authIdmRequest() {
  return {
    type: AUTH_IDM_REQUEST
  };
}

export function authResetResponseMessage() {
  return {
    type: AUTH_RESPONSE_MESSAGE_RESET
  };
}
