export const TRANSFERS_TRANSFER_WAYS_REQUEST = '[TRANSFERS]_TRANSFER_WAYS_REQUEST'
export const TRANSFERS_TRANSFER_WAYS_RESPONSE = '[TRANSFERS]_TRANSFER_WAYS_RESPONSE'
export const TRANSFERS_TRANSFER_WAYS_FAILURE = '[TRANSFERS]_TRANSFER_WAYS_FAILURE'

export const TRANSFERS_RESPONSE_MESSAGE_RESET = '[TRANSFERS]_RESPONSE_MESSAGE_RESET'

export const TRANSFERS_TRANSFERS_LIST_REQUEST = '[TRANSFERS]_TRANSFERS_LIST_REQUEST'
export const TRANSFERS_TRANSFERS_LIST_RESPONSE = '[TRANSFERS]_TRANSFERS_LIST_RESPONSE'
export const TRANSFERS_TRANSFERS_LIST_FAILURE = '[TRANSFERS]_TRANSFERS_LIST_FAILURE'

export const TRANSFERS_TRANSFER_DETAIL_ITEMS_REQUEST = '[TRANSFERS]_TRANSFER_DETAIL_ITEMS_REQUEST'
export const TRANSFERS_TRANSFER_DETAIL_ITEMS_RESPONSE = '[TRANSFERS]_TRANSFER_DETAIL_ITEMS_RESPONSE'
export const TRANSFERS_TRANSFER_DETAIL_ITEMS_FAILURE = '[TRANSFERS]_TRANSFER_DETAIL_ITEMS_FAILURE'

export const TRANSFERS_CREATE_TRANSFER_REQUEST = '[TRANSFERS]_CREATE_TRANSFER_REQUEST'
export const TRANSFERS_CREATE_TRANSFER_RESPONSE = '[TRANSFERS]_CREATE_TRANSFER_RESPONSE'
export const TRANSFERS_CREATE_TRANSFER_FAILURE = '[TRANSFERS]_CREATE_TRANSFER_FAILURE'

export const TRANSFERS_TRANSFER_CHANGE_STATUS_REQUEST = '[TRANSFERS]_TRANSFER_CHANGE_STATUS_REQUEST'
export const TRANSFERS_TRANSFER_CHANGE_STATUS_RESPONSE = '[TRANSFERS]_TRANSFER_CHANGE_STATUS_RESPONSE'
export const TRANSFERS_TRANSFER_CHANGE_STATUS_FAILURE = '[TRANSFERS]_TRANSFER_CHANGE_STATUS_FAILURE'

export const TRANSFERS_TRANSFER_DETAIL_CREATE_ITEM_REQUEST = '[TRANSFERS]_TRANSFER_DETAIL_CREATE_ITEM_REQUEST'
export const TRANSFERS_TRANSFER_DETAIL_CREATE_ITEM_RESPONSE = '[TRANSFERS]_TRANSFER_DETAIL_CREATE_ITEM_RESPONSE'
export const TRANSFERS_TRANSFER_DETAIL_CREATE_ITEM_FAILURE = '[TRANSFERS]_TRANSFER_DETAIL_CREATE_ITEM_FAILURE'

export const TRANSFERS_TRANSFER_DETAIL_ITEM_UPDATE_QUANTITY_REQUEST = '[TRANSFERS]_TRANSFER_DETAIL_ITEM_UPDATE_QUANTITY_REQUEST'
export const TRANSFERS_TRANSFER_DETAIL_ITEM_UPDATE_QUANTITY_RESPONSE = '[TRANSFERS]_TRANSFER_DETAIL_ITEM_UPDATE_QUANTITY_RESPONSE'
export const TRANSFERS_TRANSFER_DETAIL_ITEM_UPDATE_QUANTITY_FAILURE = '[TRANSFERS]_TRANSFER_DETAIL_ITEM_UPDATE_QUANTITY_FAILURE'

export const TRANSFERS_NULLIFY_NEW_CREATED_DETAIL_ITEM = 'TRANSFERS_NULLIFY_NEW_CREATED_DETAIL_ITEM'

export const TRANSFERS_CREATE_TRANSFER_DETAIL_ITEM_FROM_ORDER_REQUEST = '[TRANSFERS]_CREATE_TRANSFER_DETAIL_ITEM_FROM_ORDER_REQUEST'
export const TRANSFERS_CREATE_TRANSFER_DETAIL_ITEM_FROM_ORDER_RESPONSE = '[TRANSFERS]_CREATE_TRANSFER_DETAIL_ITEM_FROM_ORDER_RESPONSE'
export const TRANSFERS_CREATE_TRANSFER_DETAIL_ITEM_FROM_ORDER_FAILURE = '[TRANSFERS]_CREATE_TRANSFER_DETAIL_ITEM_FROM_ORDER_FAILURE'

export const TRANSFERS_TRANSFER_DETAIL_ITEM_PROCESSING_REQUEST = '[TRANSFERS]_TRANSFER_DETAIL_ITEM_PROCESSING_REQUEST'
export const TRANSFERS_TRANSFER_DETAIL_ITEM_PROCESSING_RESPONSE = '[TRANSFERS]_TRANSFER_DETAIL_ITEM_PROCESSING_RESPONSE'
export const TRANSFERS_TRANSFER_DETAIL_ITEM_PROCESSING_FAILURE = '[TRANSFERS]_TRANSFER_DETAIL_ITEM_PROCESSING_FAILURE'

export const TRANSFERS_TRANSFER_DETAIL_ITEM_DELETE_REQUEST = '[TRANSFERS]_TRANSFER_DETAIL_ITEM_DELETE_REQUEST'
export const TRANSFERS_TRANSFER_DETAIL_ITEM_DELETE_RESPONSE = '[TRANSFERS]_TRANSFER_DETAIL_ITEM_DELETE_RESPONSE'
export const TRANSFERS_TRANSFER_DETAIL_ITEM_DELETE_FAILURE = '[TRANSFERS]_TRANSFER_DETAIL_ITEM_DELETE_FAILURE'

export const TRANSFERS_TRANSFER_DETAIL_ITEM_CHANGE_TRANSFER_QUEUE_REQUEST = '[TRANSFERS]_TRANSFER_DETAIL_ITEM_CHANGE_TRANSFER_QUEUE_REQUEST'
export const TRANSFERS_TRANSFER_DETAIL_ITEM_CHANGE_TRANSFER_QUEUE_RESPONSE = '[TRANSFERS]_TRANSFER_DETAIL_ITEM_CHANGE_TRANSFER_QUEUE_RESPONSE'
export const TRANSFERS_TRANSFER_DETAIL_ITEM_CHANGE_TRANSFER_QUEUE_FAILURE = '[TRANSFERS]_TRANSFER_DETAIL_ITEM_CHANGE_TRANSFER_QUEUE_FAILURE'

export const TRANSFERS_TRANSFER_DELETE_REQUEST = '[TRANSFERS]_TRANSFER_DELETE_REQUEST'
export const TRANSFERS_TRANSFER_DELETE_RESPONSE = '[TRANSFERS]_TRANSFER_DELETE_RESPONSE'
export const TRANSFERS_TRANSFER_DELETE_FAILURE = '[TRANSFERS]_TRANSFER_DELETE_FAILURE'

export const TRANSFERS_TRANSFER_UPDATE_REQUEST = '[TRANSFERS]_TRANSFER_UPDATE_REQUEST'
export const TRANSFERS_TRANSFER_UPDATE_RESPONSE = '[TRANSFERS]_TRANSFER_UPDATE_RESPONSE'
export const TRANSFERS_TRANSFER_UPDATE_FAILURE = '[TRANSFERS]_TRANSFER_UPDATE_FAILURE'

export const TRANSFERS_TRANSFER_UPDATE_NOTE_REQUEST = '[TRANSFERS]_TRANSFER_UPDATE_NOTE_REQUEST'
export const TRANSFERS_TRANSFER_UPDATE_NOTE_RESPONSE = '[TRANSFERS]_TRANSFER_UPDATE_NOTE_RESPONSE'
export const TRANSFERS_TRANSFER_UPDATE_NOTE_FAILURE = '[TRANSFERS]_TRANSFER_UPDATE_NOTE_FAILURE'

export const TRANSFERS_TRANSFER_ITEM_UPDATE_NOTE_REQUEST = '[TRANSFERS]_TRANSFER_ITEM_UPDATE_NOTE_REQUEST'
export const TRANSFERS_TRANSFER_ITEM_UPDATE_NOTE_RESPONSE = '[TRANSFERS]_TRANSFER_ITEM_UPDATE_NOTE_RESPONSE'
export const TRANSFERS_TRANSFER_ITEM_UPDATE_NOTE_FAILURE = '[TRANSFERS]_TRANSFER_ITEM_UPDATE_NOTE_FAILURE'

export const TRANSFERS_PRINT_DOCUMENT_REQUEST = '[TRANSFERS]_PRINT_DOCUMENT_REQUEST'
export const TRANSFERS_PRINT_DOCUMENT_RESPONSE = '[TRANSFERS]_PRINT_DOCUMENT_RESPONSE'
export const TRANSFERS_PRINT_DOCUMENT_FAILURE = '[TRANSFERS]_PRINT_DOCUMENT_FAILURE'

export const TRANSFERS_GENERATE_PDF_REQUEST = '[TRANSFERS]_GENERATE_PDF_REQUEST'
export const TRANSFERS_GENERATE_PDF_RESPONSE = '[TRANSFERS]_GENERATE_PDF_RESPONSE'
export const TRANSFERS_GENERATE_PDF_FAILURE = '[TRANSFERS]_GENERATE_PDF_FAILURE'

export const TRANSFERS_RESET_GENERATED_PDF = '[TRANSFERS]_RESET_GENERATED_PDF'

export const TRANSFERS_SALES_REQUESTS_REQUEST = '[TRANSFERS]_SALES_REQUESTS_REQUEST'
export const TRANSFERS_SALES_REQUESTS_RESPONSE = '[TRANSFERS]_SALES_REQUESTS_RESPONSE'
export const TRANSFERS_SALES_REQUESTS_FAILURE = '[TRANSFERS]_SALES_REQUESTS_FAILURE'

export const TRANSFERS_SALES_REQUESTS_ITEMS_REQUEST = '[TRANSFERS]_SALES_REQUESTS_ITEMS_REQUEST'
export const TRANSFERS_SALES_REQUESTS_ITEMS_RESPONSE = '[TRANSFERS]_SALES_REQUESTS_ITEMS_RESPONSE'
export const TRANSFERS_SALES_REQUESTS_ITEMS_FAILURE = '[TRANSFERS]_SALES_REQUESTS_ITEMS_FAILURE'

export const TRANSFERS_DELETE_SALES_REQUESTS_ITEM_REQUEST = '[TRANSFERS]_DELETE_SALES_REQUESTS_ITEM_REQUEST'
export const TRANSFERS_DELETE_SALES_REQUESTS_ITEM_RESPONSE = '[TRANSFERS]_DELETE_SALES_REQUESTS_ITEM_RESPONSE'
export const TRANSFERS_DELETE_SALES_REQUESTS_ITEM_FAILURE = '[TRANSFERS]_DELETE_SALES_REQUESTS_ITEM_FAILURE'

export const TRANSFERS_SALES_REQUESTS_ITEMS_QUEUED_AND_CHECKED_REQUEST = '[TRANSFERS]_SALES_REQUESTS_ITEMS_QUEUED_AND_CHECKED_REQUEST'
export const TRANSFERS_SALES_REQUESTS_ITEMS_QUEUED_AND_CHECKED_RESPONSE = '[TRANSFERS]_SALES_REQUESTS_ITEMS_QUEUED_AND_CHECKED_RESPONSE'
export const TRANSFERS_SALES_REQUESTS_ITEMS_QUEUED_AND_CHECKED_FAILURE = '[TRANSFERS]_SALES_REQUESTS_ITEMS_QUEUED_AND_CHECKED_FAILURE'

export const TRANSFERS_UPDATE_SALES_REQUESTS_ITEM_REQUEST = '[TRANSFERS]_UPDATE_SALES_REQUESTS_ITEM_REQUEST'
export const TRANSFERS_UPDATE_SALES_REQUESTS_ITEM_RESPONSE = '[TRANSFERS]_UPDATE_SALES_REQUESTS_ITEM_RESPONSE'
export const TRANSFERS_UPDATE_SALES_REQUESTS_ITEM_FAILURE = '[TRANSFERS]_UPDATE_SALES_REQUESTS_ITEM_FAILURE'
