import { useSelector } from 'react-redux'

export function useAdministrationSelectors() {
  const selectResMessage           = useSelector(state => state.administration.resMessage);
  const selectPrintServers         = useSelector(state => state.administration.printServers);
  const selectPrinterProfiles      = useSelector(state => state.administration.printerProfiles);
  const selectAirshopNotifications = useSelector(state => state.administration.airshopNotifications);
  const selectAppUsers             = useSelector(state => state.administration.appUsers);
  const selectSelectedTemplate     = useSelector(state => state.administration.selectedTemplate);
  const selectTemplateSystems      = useSelector(state => state.administration.templateSystems);

  const selectAirshopNotificationsBackendErrors = useSelector(state => state.administration.airshopNotificationsBackendErrors);

  const selectPrintersPageData  = useSelector(state => state.administration.printersList.printersPageData);
  const selectPrintersPageDraw  = useSelector(state => state.administration.printersList.printersPageDraw);
  const selectPrintersPagePage  = useSelector(state => state.administration.printersList.printersPagePage);
  const selectPrintersPageLimit = useSelector(state => state.administration.printersList.printersPageLimit);
  const selectPrintersTotal     = useSelector(state => state.administration.printersList.printersTotal);

  const selectIPsPageData  = useSelector(state => state.administration.ipAddressesList.IPsPageData);
  const selectIPsPageDraw  = useSelector(state => state.administration.ipAddressesList.IPsPageDraw);
  const selectIPsPagePage  = useSelector(state => state.administration.ipAddressesList.IPsPagePage);
  const selectIPsPageLimit = useSelector(state => state.administration.ipAddressesList.IPsPageLimit);
  const selectIPsTotal     = useSelector(state => state.administration.ipAddressesList.IPsTotal);

  const selectTemplatesData  = useSelector(state => state.administration.templatesData);
  const selectTemplatesDraw  = useSelector(state => state.administration.templatesDraw);
  const selectTemplatesPage  = useSelector(state => state.administration.templatesPage);
  const selectTemplatesLimit = useSelector(state => state.administration.templatesLimit);
  const selectTemplatesTotal     = useSelector(state => state.administration.templatesTotal);

  const selectAbraCommandsPageData  = useSelector(state => state.administration.abraCommandsList.abraCommandsPageData);
  const selectAbraCommandsPageDraw  = useSelector(state => state.administration.abraCommandsList.abraCommandsPageDraw);

  return {
    selectTemplatesData,
    selectTemplatesDraw,
    selectTemplatesPage,
    selectTemplatesLimit,
    selectTemplatesTotal,

    selectResMessage,
    selectPrintServers,
    selectPrinterProfiles,
    selectAirshopNotifications,
    selectAppUsers,
    selectAirshopNotificationsBackendErrors,
    selectSelectedTemplate,
    selectTemplateSystems,

    selectPrintersPageData,
    selectPrintersPageDraw,
    selectPrintersPagePage,
    selectPrintersPageLimit,
    selectPrintersTotal,

    selectIPsPageData,
    selectIPsPageDraw,
    selectIPsPagePage,
    selectIPsPageLimit,
    selectIPsTotal,

    selectAbraCommandsPageData,
    selectAbraCommandsPageDraw,
  }
}
