import * as actionTypes from './mailchimp.actionTypes';

// Reset response message
export function mailchimpResponseMessageReset() {
  return {
    type: actionTypes.MAILCHIMP_RESPONSE_MESSAGE_RESET,
  };
}


// Orders list
// options visible in facade
export function mailchimpOrdersListRequest(options) {
  return {
    type: actionTypes.MAILCHIMP_ORDERS_LIST_REQUEST,
    payload: { params: options.params, list: options.list },
  };
}

export function mailchimpOrdersListResponse(tableData) {
  return {
    type: actionTypes.MAILCHIMP_ORDERS_LIST_RESPONSE,
    payload: { tableData },
  };
}

export function mailchimpOrdersListFailure(error) {
  return {
    type: actionTypes.MAILCHIMP_ORDERS_LIST_FAILURE,
    payload: error,
  };
}

// Tags list
export function mailchimpTagsListByShopRequest(shop) {
  return {
    type: actionTypes.MAILCHIMP_TAGS_LIST_BY_SHOP_REQUEST,
    payload: { shop },
  }
}

export function mailchimpTagsListByShopResponse(response) {
  return {
    type: actionTypes.MAILCHIMP_TAGS_LIST_BY_SHOP_RESPONSE,
    payload: response,
  }
}

export function mailchimpTagsListByShopFailure(error) {
  return {
    type: actionTypes.MAILCHIMP_TAGS_LIST_BY_SHOP_FAILURE,
    payload: error,
  }
}

// Bulk emails to existing tag
export function mailchimpBulkEmailsToExistingTagByShopRequest(shop, tagId, sendAllMode, selectedEmails, ordersParams) {
  return {
    type: actionTypes.MAILCHIMP_BULK_EMAILS_TO_EXISTING_TAG_BY_SHOP_REQUEST,
    payload: { shop, tagId, sendAllMode, selectedEmails, ordersParams },
  }
}

export function mailchimpBulkEmailsToExistingTagByShopResponse(response, resMessage) {
  return {
    type: actionTypes.MAILCHIMP_BULK_EMAILS_TO_EXISTING_TAG_BY_SHOP_RESPONSE,
    payload: { response,  resMessage },
  }
}

export function mailchimpBulkEmailsToExistingTagByShopFailure(error, resMessage) {
  return {
    type: actionTypes.MAILCHIMP_BULK_EMAILS_TO_EXISTING_TAG_BY_SHOP_FAILURE,
    payload: { error, resMessage },
  }
}

// Bulk emails to new tag
export function mailchimpBulkEmailsToNewTagByShopRequest(shop, newTag, sendAllMode, selectedEmails, ordersParams) {
  return {
    type: actionTypes.MAILCHIMP_BULK_EMAILS_TO_NEW_TAG_BY_SHOP_REQUEST,
    payload: { shop, newTag, sendAllMode, selectedEmails, ordersParams },
  }
}

export function mailchimpBulkEmailsToNewTagByShopResponse(response, resMessage) {
  return {
    type: actionTypes.MAILCHIMP_BULK_EMAILS_TO_NEW_TAG_BY_SHOP_RESPONSE,
    payload: { response, resMessage },
  }
}

export function mailchimpBulkEmailsToNewTagByShopFailure(error, resMessage) {
  return {
    type: actionTypes.MAILCHIMP_BULK_EMAILS_TO_NEW_TAG_BY_SHOP_FAILURE,
    payload: { error, resMessage },
  }
}
