import { useDispatch } from 'react-redux'
import { useCallback } from 'react'

import * as actions from './collection-lists.actions'
import { useCollectionListsSelectors } from './collection-lists.selectors'

export function useCollectionListsFacade() {
  const dispatch = useDispatch();
  const selectors = useCollectionListsSelectors();

  const {
    selectResMessage: collectionListsResMessage,
    selectStores: stores,
    selectShippers: shippers,
    selectGeneratedPdfDocument: collectionListGeneratedPdfDocument,

    selectCollectionListsPageData: collectionListsPageData,
    selectCollectionListsPageDraw: collectionListsPageDraw,
    selectCollectionListsPagePage: collectionListsPagePage,
    selectCollectionListsTotal: collectionListsTotal,
    selectCollectionListsPageLimit: collectionListsPageLimit,

    selectItemsPageData: itemsPageData,
    selectItemsPageDraw: itemsPageDraw,
    selectItemsPagePage: itemsPagePage,
    selectItemsPageLimit: itemsPageLimit,
    selectItemsTotal: itemsTotal,

    selectAddedBalikobotOrdersPageData: addedBalikobotOrdersPageData,
    selectAddedBalikobotOrdersPageDraw: addedBalikobotOrdersPageDraw,
    selectAddedBalikobotOrdersPagePage: addedBalikobotOrdersPagePage,
    selectAddedBalikobotOrdersPageLimit: addedBalikobotOrdersPageLimit,
    selectAddedBalikobotOrdersTotal: addedBalikobotOrdersTotal,

  } = selectors;

  const fetchCollectionListsPageData = useCallback(
    (
      draw=1, page=0, transportationTypesId=null, limit=50, search=null
    ) =>
      dispatch(actions.collectionListsListPageRequest(
        draw, page, transportationTypesId, limit, search
      )),
    [dispatch]
  );

  const fetchCollectionListsListDetailItemsPageData = useCallback(
    (
      collectionListId, draw=1, page=0, limit=50, search=null
    ) =>
      dispatch(actions.collectionListsListDetailItemsPageRequest(
        collectionListId, draw, page, limit, search
      )),
    [dispatch]
  );

  const changeCollectionListStatus = useCallback(
    (
      collectionListId, newStatus, sessionId
    ) =>
      dispatch(actions.collectionListsCollectionListStatusChangeRequest(
        collectionListId, newStatus, sessionId
      )),
    [dispatch]
  );

  const fetchStores = useCallback(
    () =>
      dispatch(actions.collectionListsStoresListRequest()),
    [dispatch]
  );

  const resetCollectionListsResMessage = useCallback(
    () =>
      dispatch(actions.collectionListsResMessageReset()),
    [dispatch]
  );

  const deleteCollectionListDetailItem = useCallback(
    (collectionListItemId) =>
      dispatch(actions.collectionListsCollectionListItemDeleteRequest(collectionListItemId)),
    [dispatch]
  );

  const fetchAddedBalikobotOrdersPageData = useCallback(
    (
      draw=1, page=0, limit=50, shipperId=null, storeId=null, search=null
    ) =>
      dispatch(actions.collectionListsAddedBalikobotOrdersRequest(
        draw, page, limit, shipperId, storeId, search
      )),
    [dispatch]
  );

  const fetchShippers = useCallback(
    () =>
      dispatch(actions.collectionListsShippersListRequest()),
    [dispatch]
  );

  const printCollectionListDocument = useCallback(
    (type, itemId, userUid, ident) =>
      dispatch(actions.collectionListsPrintDocumentRequest(type, itemId, userUid, ident)),
    [dispatch]
  );

  const generateCollectionListTypePdfDocument = useCallback(
    (type, itemId, userUid, ident) =>
      dispatch(actions.collectionListsGeneratePdfDocumentRequest(type, itemId, userUid, ident)),
    [dispatch]
  );

  const resetCollectionListGeneratedPdf = useCallback(
    () =>
      dispatch(actions.collectionListsResetGeneratedPdf()),
    [dispatch]
  );

  return {
    collectionListsResMessage,
    stores,
    shippers,
    collectionListGeneratedPdfDocument,

    collectionListsPageData,
    collectionListsPageDraw,
    collectionListsPagePage,
    collectionListsTotal,
    collectionListsPageLimit,

    itemsPageData,
    itemsPageDraw,
    itemsPagePage,
    itemsPageLimit,
    itemsTotal,

    addedBalikobotOrdersPageData,
    addedBalikobotOrdersPageDraw,
    addedBalikobotOrdersPagePage,
    addedBalikobotOrdersPageLimit,
    addedBalikobotOrdersTotal,

    fetchCollectionListsPageData,
    fetchCollectionListsListDetailItemsPageData,
    resetCollectionListsResMessage,
    changeCollectionListStatus,
    fetchStores,
    deleteCollectionListDetailItem,
    fetchAddedBalikobotOrdersPageData,
    fetchShippers,
    printCollectionListDocument,
    generateCollectionListTypePdfDocument,
    resetCollectionListGeneratedPdf,
  }
}
