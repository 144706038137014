// Local development only
export const environmentIsDev = process.env.NODE_ENV === 'development';
// Alfa branch (beta test data)
export const environmentIsProd = process.env.NODE_ENV === 'production';
// Master branch (real data), in prod:master, NODE_ENV is production as well
export const environmentIsProdMaster = process.env.REACT_APP_CUSTOM_ENV === 'productionMaster';

export const environmentIsDevMaster = process.env.REACT_APP_CUSTOM_ENV === 'devMaster';

const devBaseUrl = 'https://backendcrw.beershop.cz/api/rest';
const prodBaseUrl = 'https://backendcrw.beershop.cz/api/rest';
const prodMasterBaseUrl = 'https://backendcrw.beershop.cz/api/rest';
const devMasterBaseUrl = 'https://backendcrw.beershop.cz/api/rest';

const devAirshopUrl = 'https://airshop.beershop.cz';
const prodAirshopUrl = 'https://airshop.beershop.cz';
const prodMasterAirshopUrl = 'https://airshop.beershop.cz';
const devMasterAirshopUrl = 'https://airshop.beershop.cz';

const devEmailUrl = 'https://email.airtoy.cz/api/rest';
const prodEmailUrl = 'https://email.airtoy.cz/api/rest';
const prodMasterEmailUrl = 'https://email.airtoy.cz/api/rest';
const devMasterEmailUrl = 'https://email.airtoy.cz/api/rest';

const devMailchimpDeliUrl = 'https://mailchimp.craftdeli.cz/3.0';
const prodMailchimpDeliUrl = 'https://mailchimp.craftdeli.cz/3.0';
const prodMasterMailchimpDeliUrl = 'https://mailchimp.craftdeli.cz/3.0';
const devMasterMailchimpDeliUrl = 'https://mailchimp.craftdeli.cz/3.0';

const devMailchimpBeerUrl = 'https://mailchimp.beershop.cz/3.0';
const prodMailchimpBeerUrl = 'https://mailchimp.beershop.cz/3.0';
const prodMasterMailchimpBeerUrl = 'https://mailchimp.beershop.cz/3.0';
const devMasterMailchimpBeerUrl = 'https://mailchimp.beershop.cz/3.0';

const devImgUrl = 'https://betaimg1.love.cz';
const prodImgUrl = 'https://img.love.cz';
const prodMasterImgUrl = 'https://img.love.cz';
const devMasterImgUrl = 'https://img.love.cz';

export const baseUrl = () => {
  if (environmentIsDev && !environmentIsProdMaster && !environmentIsDevMaster) {
    return devBaseUrl;
  } else if (environmentIsProd
    && !environmentIsProdMaster
    && !environmentIsDevMaster) {
    return prodBaseUrl;
  } else if (environmentIsProdMaster) {
    return prodMasterBaseUrl;
  } else if (environmentIsDevMaster) {
    return devMasterBaseUrl;
  }
}

export const imgUrl = () => {
  if (environmentIsDev && !environmentIsProdMaster && !environmentIsDevMaster) {
    return devImgUrl;
  } else if (environmentIsProd
    && !environmentIsProdMaster
    && !environmentIsDevMaster) {
    return prodImgUrl;
  } else if (environmentIsProdMaster) {
    return prodMasterImgUrl;
  } else if (environmentIsDevMaster) {
    return devMasterImgUrl;
  }
}

export const emailUrl = () => {
  if (environmentIsDev && !environmentIsProdMaster && !environmentIsDevMaster) {
    return devEmailUrl;
  } else if (environmentIsProd
    && !environmentIsProdMaster
    && !environmentIsDevMaster) {
    return prodEmailUrl;
  } else if (environmentIsProdMaster) {
    return prodMasterEmailUrl;
  } else if (environmentIsDevMaster) {
    return devMasterEmailUrl;
  }
}

export const mailchimpDeliUrl = () => {
  if (environmentIsDev && !environmentIsProdMaster && !environmentIsDevMaster) {
    return devMailchimpDeliUrl;
  } else if (environmentIsProd
    && !environmentIsProdMaster
    && !environmentIsDevMaster) {
    return prodMailchimpDeliUrl;
  } else if (environmentIsProdMaster) {
    return prodMasterMailchimpDeliUrl;
  } else if (environmentIsDevMaster) {
    return devMasterMailchimpDeliUrl;
  }
}

export const mailchimpBeerUrl = () => {
  if (environmentIsDev && !environmentIsProdMaster && !environmentIsDevMaster) {
    return devMailchimpBeerUrl;
  } else if (environmentIsProd
    && !environmentIsProdMaster
    && !environmentIsDevMaster) {
    return prodMailchimpBeerUrl;
  } else if (environmentIsProdMaster) {
    return prodMasterMailchimpBeerUrl;
  } else if (environmentIsDevMaster) {
    return devMasterMailchimpBeerUrl;
  }
}

export const airshopUrl = () => {
  if (environmentIsDev && !environmentIsProdMaster && !environmentIsDevMaster) {
    return devAirshopUrl;
  } else if (environmentIsProd
    && !environmentIsProdMaster
    && !environmentIsDevMaster) {
    return prodAirshopUrl;
  } else if (environmentIsProdMaster) {
    return prodMasterAirshopUrl;
  } else if (environmentIsDevMaster) {
    return devMasterAirshopUrl;
  }
}
