import * as actionTypes from './administration.actionTypes';

const initialState = {
  printServers: null,
  printersList: {
    printersPageData: null,
    printersPageDraw: null,
    printersPagePage: 0,
    printersPageLimit: 20,
    printersTotal: null,
  },
  ipAddressesList: {
    IPsPageData: null,
    IPsPageDraw: null,
    IPsPagePage: 0,
    IPsPageLimit: 20,
    IPsTotal: null,
  },
  abraCommandsList: {
    // pagr always 0, total not needed
    abraCommandsPageData: null,
    abraCommandsPageDraw: null,
  },
  templatesData: null,
  templatesDraw: null,
  templatesPage: 0,
  templatesLimit: 50,
  templatesTotal: null,
  selectedTemplate: null,
  airshopNotifications: null,
  airshopNotificationsBackendErrors: null,
  printerProfiles: null,
  templateSystems: null,
  appUsers: null,
  resMessage: null,
  error: null,
  fetching: false,
}

const transfersReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADMINISTRATION_IMPORT_FC_BY_SHOP_REQUEST:
    case actionTypes.ADMINISTRATION_SYNC_FC_DAYS_BACK_BY_SHOP_REQUEST:
    case actionTypes.ADMINISTRATION_PRINT_SERVERS_REQUEST:
    case actionTypes.ADMINISTRATION_PRINTER_PROFILE_REQUEST:
    case actionTypes.ADMINISTRATION_UPDATE_USER_THERMAL_PRINTER_REQUEST:
    case actionTypes.ADMINISTRATION_UPDATE_USER_A4_PRINTER_REQUEST:
    case actionTypes.ADMINISTRATION_AIRSHOP_NOTIFICATIONS_REQUEST:
    case actionTypes.ADMINISTRATION_CREATE_IP_ADDRESS_REQUEST:
    case actionTypes.ADMINISTRATION_UPDATE_IP_ADDRESS_REQUEST:
    case actionTypes.ADMINISTRATION_DELETE_IP_ADDRESS_REQUEST:
    case actionTypes.ADMINISTRATION_PARTNERS_LIST_REQUEST:
    case actionTypes.ADMINISTRATION_UPDATE_USER_A4_PRINTER_BY_ADMIN_REQUEST:
    case actionTypes.ADMINISTRATION_UPDATE_USER_THERMAL_PRINTER_BY_ADMIN_REQUEST:
    case actionTypes.ADMINISTRATION_AIRSHOP_NOTIFICATIONS_BACKEND_ERROR_REQUEST:
    case actionTypes.ADMINISTRATION_TEMPLATE_REQUEST:
    case actionTypes.ADMINISTRATION_CREATE_TEMPLATE_REQUEST:
    case actionTypes.ADMINISTRATION_EDIT_TEMPLATE_REQUEST:
    case actionTypes.ADMINISTRATION_DELETE_TEMPLATE_REQUEST:
    case actionTypes.ADMINISTRATION_SEND_TEST_EMAIL_REQUEST:
    case actionTypes.ADMINISTRATION_TEMPLATE_SYSTEMS_REQUEST:
      return {
        ...state,
        fetching: true,
      };
    case actionTypes.ADMINISTRATION_PRINTERS_LIST_REQUEST:
      return {
        ...state,
        printersList: {
          ...state.printersList,
          printersPageDraw: action.payload.draw,
          printersPagePage: action.payload.page,
          printersPageLimit: action.payload.limit,
        },
        fetching: true,
      };
    case actionTypes.ADMINISTRATION_TEMPLATES_REQUEST:
      return {
        ...state,
        templatesDraw: action.payload.draw,
        templatesPage: action.payload.page,
        templatesLimit: action.payload.limit,
        fetching: true,
      };
    case actionTypes.ADMINISTRATION_IP_ADDRESSES_LIST_REQUEST:
      return {
        ...state,
        ipAddressesList: {
          ...state.ipAddressesList,
          IPsPageDraw: action.payload.draw,
          IPsPagePage: action.payload.page,
          IPsPageLimit: action.payload.limit,
        },
        fetching: true,
      };
    case actionTypes.ADMINISTRATION_ABRA_COMMANDS_LIST_REQUEST:
      return {
        ...state,
        abraCommandsList: {
          ...state.abraCommandsList,
          abraCommandsPageDraw: action.payload.draw,
        },
        fetching: true,
      };
    case actionTypes.ADMINISTRATION_AIRSHOP_NOTIFICATIONS_RESPONSE:
      return {
        ...state,
        resMessage: action.payload.resMessage,
        airshopNotifications: action.payload.response,
        fetching: false,
      };
    case actionTypes.ADMINISTRATION_TEMPLATES_RESPONSE:
      return {
        ...state,
        templatesData: action.payload.items,
        templatesTotal: action.payload.total,
        fetching: false,
      };
    case actionTypes.ADMINISTRATION_TEMPLATE_RESPONSE:
      return {
        ...state,
        selectedTemplate: action.payload,
        fetching: false,
      };
    case actionTypes.ADMINISTRATION_AIRSHOP_NOTIFICATIONS_BACKEND_ERROR_RESPONSE:
      return {
        ...state,
        resMessage: action.payload.resMessage,
        airshopNotificationsBackendErrors: action.payload.response,
        fetching: false,
      };
    case actionTypes.ADMINISTRATION_TEMPLATE_SYSTEMS_RESPONSE:
      return {
        ...state,
        templateSystems: action.payload,
        fetching: false,
      };
    case actionTypes.ADMINISTRATION_IMPORT_FC_BY_SHOP_RESPONSE:
    case actionTypes.ADMINISTRATION_SYNC_FC_DAYS_BACK_BY_SHOP_RESPONSE:
    case actionTypes.ADMINISTRATION_UPDATE_USER_THERMAL_PRINTER_RESPONSE:
    case actionTypes.ADMINISTRATION_UPDATE_USER_A4_PRINTER_RESPONSE:
    case actionTypes.ADMINISTRATION_CREATE_IP_ADDRESS_RESPONSE:
    case actionTypes.ADMINISTRATION_UPDATE_IP_ADDRESS_RESPONSE:
    case actionTypes.ADMINISTRATION_DELETE_IP_ADDRESS_RESPONSE:
    case actionTypes.ADMINISTRATION_UPDATE_USER_THERMAL_PRINTER_BY_ADMIN_RESPONSE:
    case actionTypes.ADMINISTRATION_UPDATE_USER_A4_PRINTER_BY_ADMIN_RESPONSE:
    case actionTypes.ADMINISTRATION_CREATE_TEMPLATE_RESPONSE:
    case actionTypes.ADMINISTRATION_EDIT_TEMPLATE_RESPONSE:
    case actionTypes.ADMINISTRATION_DELETE_TEMPLATE_RESPONSE:
    case actionTypes.ADMINISTRATION_SEND_TEST_EMAIL_RESPONSE:
      return {
        ...state,
        resMessage: action.payload.resMessage,
        fetching: false,
      };
    case actionTypes.ADMINISTRATION_PRINT_SERVERS_RESPONSE:
      return {
        ...state,
        printServers: action.payload,
        fetching: false,
      };
    case actionTypes.ADMINISTRATION_PRINTER_PROFILE_RESPONSE:
      return {
        ...state,
        printerProfiles: {
          ...state.printerProfiles,
          [action.payload.printerId]: action.payload.response,
        },
        fetching: false,
      };
    case actionTypes.ADMINISTRATION_PRINTERS_LIST_RESPONSE:
      return {
        ...state,
        printersList: {
          ...state.printersList,
          printersPageData: action.payload.list,
          printersTotal: action.payload.total,
        },
        fetching: false,
      };
    case actionTypes.ADMINISTRATION_IP_ADDRESSES_LIST_RESPONSE:
      return {
        ...state,
        ipAddressesList: {
          ...state.ipAddressesList,
          IPsPageData: action.payload.items,
          IPsTotal: action.payload.total,
        },
        fetching: false,
      };
    case actionTypes.ADMINISTRATION_ABRA_COMMANDS_LIST_RESPONSE:
      return {
        ...state,
        abraCommandsList: {
          ...state.abraCommandsList,
          abraCommandsPageData: action.payload,
        },
        fetching: false,
      };
    case actionTypes.ADMINISTRATION_PARTNERS_LIST_RESPONSE:
    return {
      ...state,
      appUsers: action.payload,
      fetching: false,
    };
    case actionTypes.ADMINISTRATION_IMPORT_FC_BY_SHOP_FAILURE:
    case actionTypes.ADMINISTRATION_SYNC_FC_DAYS_BACK_BY_SHOP_FAILURE:
    case actionTypes.ADMINISTRATION_UPDATE_USER_THERMAL_PRINTER_FAILURE:
    case actionTypes.ADMINISTRATION_UPDATE_USER_A4_PRINTER_FAILURE:
    case actionTypes.ADMINISTRATION_AIRSHOP_NOTIFICATIONS_FAILURE:
    case actionTypes.ADMINISTRATION_CREATE_IP_ADDRESS_FAILURE:
    case actionTypes.ADMINISTRATION_UPDATE_IP_ADDRESS_FAILURE:
    case actionTypes.ADMINISTRATION_ABRA_COMMANDS_LIST_FAILURE:
    case actionTypes.ADMINISTRATION_UPDATE_USER_A4_PRINTER_BY_ADMIN_FAILURE:
    case actionTypes.ADMINISTRATION_UPDATE_USER_THERMAL_PRINTER_BY_ADMIN_FAILURE:
    case actionTypes.ADMINISTRATION_AIRSHOP_NOTIFICATIONS_BACKEND_ERROR_FAILURE:
    case actionTypes.ADMINISTRATION_CREATE_TEMPLATE_FAILURE:
    case actionTypes.ADMINISTRATION_EDIT_TEMPLATE_FAILURE:
    case actionTypes.ADMINISTRATION_DELETE_TEMPLATE_FAILURE:
    case actionTypes.ADMINISTRATION_SEND_TEST_EMAIL_FAILURE:
      return {
        ...state,
        resMessage: action.payload.resMessage,
        fetching: false,
        error: {...action.payload.error},
      };
    case actionTypes.ADMINISTRATION_PRINT_SERVERS_FAILURE:
    case actionTypes.ADMINISTRATION_PRINTERS_LIST_FAILURE:
    case actionTypes.ADMINISTRATION_PRINTER_PROFILE_FAILURE:
    case actionTypes.ADMINISTRATION_IP_ADDRESSES_LIST_FAILURE:
    case actionTypes.ADMINISTRATION_DELETE_IP_ADDRESS_FAILURE:
    case actionTypes.ADMINISTRATION_PARTNERS_LIST_FAILURE:
    case actionTypes.ADMINISTRATION_TEMPLATES_FAILURE:
    case actionTypes.ADMINISTRATION_TEMPLATE_FAILURE:
    case actionTypes.ADMINISTRATION_TEMPLATE_SYSTEMS_FAILURE:
      return {
        ...state,
        fetching: false,
        error: {...action.payload.error},
      };
    case actionTypes.ADMINISTRATION_RESPONSE_MESSAGE_RESET:
      return {
        ...state,
        resMessage: null,
      };
    case actionTypes.ADMINISTRATION_TEMPLATE_RESET:
      return {
        ...state,
        selectedTemplate: null,
      };
    case actionTypes.ADMINISTRATION_PRINTER_PROFILE_STOP:
      return {
        ...state,
        fetching: false,
      };
    default:
      return state;
  }
};

export default transfersReducer;
