import * as actionTypes from './targets.actionTypes';

// Reset response message
export function targetsResponseMessageReset() {
  return {
    type: actionTypes.TARGETS_RESET_RES_MESSAGE,
  };
}

// Commodities list
export function targetsCommoditiesListRequest(
  draw,
  page,
  limit,
  categoryId,
  search,
  source,
  disabled,
) {
  return {
    type: actionTypes.TARGETS_COMMODITIES_LIST_REQUEST,
    payload: {
      draw,
      page,
      limit,
      categoryId,
      search,
      source,
      disabled,
    },
  };
}

export function targetsCommoditiesListResponse(response) {
  return {
    type: actionTypes.TARGETS_COMMODITIES_LIST_RESPONSE,
    payload: response,
  };
}

export function targetsCommoditiesListFailure(error) {
  return {
    type: actionTypes.TARGETS_COMMODITIES_LIST_FAILURE,
    payload: error,
  };
}

// Commodity categories
export function targetsCommodityCategoriesRequest() {
  return {
    type: actionTypes.TARGETS_COMMODITY_CATEGORIES_REQUEST,
  };
}

export function targetsCommodityCategoriesResponse(response) {
  return {
    type: actionTypes.TARGETS_COMMODITY_CATEGORIES_RESPONSE,
    payload: response,
  };
}

export function targetsCommodityCategoriesFailure(error) {
  return {
    type: actionTypes.TARGETS_COMMODITY_CATEGORIES_FAILURE,
    payload: error,
  };
}

// Commodities targets
export function targetsCreateMultipleCommodityTargetsRequest(targetId, commoditiesIdsArr, targetCategoryId) {
  return {
    type: actionTypes.TARGETS_CREATE_MULTIPLE_COMMODITY_TARGETS_REQUEST,
    payload: { targetId, commoditiesIdsArr, targetCategoryId }
  };
}

export function targetsCreateMultipleCommodityTargetsResponse(response, resMessage) {
  return {
    type: actionTypes.TARGETS_CREATE_MULTIPLE_COMMODITY_TARGETS_RESPONSE,
    payload: { response, resMessage }
  };
}

export function targetsCreateMultipleCommodityTargetsFailure(error, resMessage) {
  return {
    type: actionTypes.TARGETS_CREATE_MULTIPLE_COMMODITY_TARGETS_FAILURE,
    payload: { error, resMessage }
  };
}

// CommodityTargets list
export function targetsCommodityTargetsListRequest(
  targetId,
  draw,
  page,
  limit,
  search,
  order,
  categoryId,
  disabled,
  onOrder,
  fetchStoreCards,
  fetchTargetCommodityTargets,
) {
  return {
    type: actionTypes.TARGETS_COMMODITY_TARGETS_LIST_REQUEST,
    payload: {
      targetId,
      draw,
      page,
      limit,
      search,
      order,
      categoryId,
      disabled,
      onOrder,
      fetchStoreCards,
      fetchTargetCommodityTargets,
    },
  };
}

export function targetsCommodityTargetsListResponse(response) {
  return {
    type: actionTypes.TARGETS_COMMODITY_TARGETS_LIST_RESPONSE,
    payload: response,
  };
}

export function targetsCommodityTargetsListFailure(error) {
  return {
    type: actionTypes.TARGETS_COMMODITY_TARGETS_LIST_FAILURE,
    payload: error,
  };
}

// Targets
export function targetsTargetsRequest() {
  return {
    type: actionTypes.TARGETS_TARGETS_REQUEST,
  };
}

export function targetsTargetsResponse(response) {
  return {
    type: actionTypes.TARGETS_TARGETS_RESPONSE,
    payload: response,
  };
}

export function targetsTargetsFailure(error) {
  return {
    type: actionTypes.TARGETS_TARGETS_FAILURE,
    payload: error,
  };
}

// Target categories
export function targetsTargetCategoriesRequest(targetId, search) {
  return {
    type: actionTypes.TARGETS_TARGETS_CATEGORIES_REQUEST,
    payload: { targetId, search }
  };
}

export function targetsTargetCategoriesResponse(response) {
  return {
    type: actionTypes.TARGETS_TARGETS_CATEGORIES_RESPONSE,
    payload: response,
  };
}

export function targetsTargetCategoriesFailure(error) {
  return {
    type: actionTypes.TARGETS_TARGETS_CATEGORIES_FAILURE,
    payload: error,
  };
}

// Delete commodity target
export function targetsDeleteMultipleCommodityTargetsRequest(targetId, targetCommoditiesIdsArr) {
  return {
    type: actionTypes.TARGETS_DELETE_MULTIPLE_COMMODITY_TARGETS_REQUEST,
    payload: { targetId, targetCommoditiesIdsArr },
  };
}

export function targetsDeleteMultipleCommodityTargetsResponse(response, resMessage) {
  return {
    type: actionTypes.TARGETS_DELETE_MULTIPLE_COMMODITY_TARGETS_RESPONSE,
    payload: { response, resMessage },
  };
}

export function targetsDeleteMultipleCommodityTargetsFailure(error, resMessage) {
  return {
    type: actionTypes.TARGETS_DELETE_MULTIPLE_COMMODITY_TARGETS_FAILURE,
    payload: { error, resMessage },
  };
}

// Create keg product
export function targetsCreateKegProductRequest(targetId, commodityId, commodityKegSizeIdsArr) {
  return {
    type: actionTypes.TARGETS_CREATE_KEG_PRODUCT_REQUEST,
    payload: { targetId, commodityId, commodityKegSizeIdsArr },
  };
}

export function targetsCreateKegProductResponse(response, resMessage) {
  return {
    type: actionTypes.TARGETS_CREATE_KEG_PRODUCT_RESPONSE,
    payload: { response, resMessage },
  };
}

export function targetsCreateKegProductFailure(error, resMessage) {
  return {
    type: actionTypes.TARGETS_CREATE_KEG_PRODUCT_FAILURE,
    payload: { error, resMessage },
  };
}

export function targetsCreateKegProductReset() {
  return {
    type: actionTypes.TARGETS_CREATE_KEG_PRODUCT_RESET,
  };
}

// Keg size by commodity plu
export function targetsKegSizesByCommodityRequest(commodityPlu) {
  return {
    type: actionTypes.TARGETS_KEG_SIZES_BY_COMMODITY_REQUEST,
    payload: { commodityPlu },
  };
}

export function targetsKegSizesByCommodityResponse(response) {
  return {
    type: actionTypes.TARGETS_KEG_SIZES_BY_COMMODITY_RESPONSE,
    payload: response,
  };
}

export function targetsKegSizesByCommodityFailure(error) {
  return {
    type: actionTypes.TARGETS_KEG_SIZES_BY_COMMODITY_FAILURE,
    payload: error,
  };
}

// Edit commodity target
export function targetsEditCommodityTargetRequest(targetCommodityId, updatedCommodityTarget) {
  return {
    type: actionTypes.TARGETS_EDIT_COMMODITY_TARGET_REQUEST,
    payload: { targetCommodityId, updatedCommodityTarget },
  };
}

export function targetsEditCommodityTargetResponse(response, resMessage) {
  return {
    type: actionTypes.TARGETS_EDIT_COMMODITY_TARGET_RESPONSE,
    payload: { response, resMessage },
  };
}

export function targetsEditCommodityTargetFailure(error, resMessage) {
  return {
    type: actionTypes.TARGETS_EDIT_COMMODITY_TARGET_FAILURE,
    payload: { error, resMessage },
  };
}

// Keg sizes
export function targetsKegSizesRequest() {
  return {
    type: actionTypes.TARGETS_KEG_SIZES_REQUEST,
  };
}

export function targetsKegSizesResponse(response) {
  return {
    type: actionTypes.TARGETS_KEG_SIZES_RESPONSE,
    payload: response
  };
}

export function targetsKegSizesFailure(error) {
  return {
    type: actionTypes.TARGETS_KEG_SIZES_FAILURE,
    payload: error
  };
}

// Kegs list for both stock (in/out) options
export function targetsKegsListBothStockOptionsRequest(
  inStockParams, outOfStockParams, fetchOptions
) {
  return {
    type: actionTypes.TARGETS_KEGS_LIST_BOTH_STOCK_OPTIONS_REQUEST,
    payload: { inStockParams, outOfStockParams, fetchOptions }
  };
}

export function targetsKegsListBothStockOptionsResponse(inStockList, outOfStockList) {
  return {
    type: actionTypes.TARGETS_KEGS_LIST_BOTH_STOCK_OPTIONS_RESPONSE,
    payload: { inStockList, outOfStockList }
  };
}

export function targetsKegsListBothStockOptionsFailure(error) {
  return {
    type: actionTypes.TARGETS_KEGS_LIST_BOTH_STOCK_OPTIONS_FAILURE,
    payload: error
  };
}

// Edit multiple target commodity targets
export function targetsEditMultipleTargetCommodityTargetsRequest(updatedTarComTargetsArr, updatedTarComsArr, updateTarComsAsWell) {
  return {
    type: actionTypes.TARGETS_EDIT_MULTIPLE_TARGET_COMMODITY_TARGETS_REQUEST,
    payload: { updatedTarComTargetsArr, updatedTarComsArr, updateTarComsAsWell }
  };
}

export function targetsEditMultipleTargetCommodityTargetsResponse(response, resMessage) {
  return {
    type: actionTypes.TARGETS_EDIT_MULTIPLE_TARGET_COMMODITY_TARGETS_RESPONSE,
    payload: { response, resMessage }
  };
}

export function targetsEditMultipleTargetCommodityTargetsFailure(error, resMessage) {
  return {
    type: actionTypes.TARGETS_EDIT_MULTIPLE_TARGET_COMMODITY_TARGETS_FAILURE,
    payload: { error, resMessage }
  };
}

// TargetCommodity list for both stock (in/out) options
export function targetstargetCommodityListBothStockOptionsRequest(
  targetId, inStockParams, outOfStockParams, fetchOptions
) {
  return {
    type: actionTypes.TARGETS_TARGET_COMMODITY_LIST_BOTH_STOCK_OPTIONS_REQUEST,
    payload: { targetId, inStockParams, outOfStockParams, fetchOptions }
  };
}

export function targetstargetCommodityListBothStockOptionsResponse(
  inStockList, outOfStockList
) {
  return {
    type: actionTypes.TARGETS_TARGET_COMMODITY_LIST_BOTH_STOCK_OPTIONS_RESPONSE,
    payload: { inStockList, outOfStockList }
  };
}

export function targetstargetCommodityListBothStockOptionsFailure(error) {
  return {
    type: actionTypes.TARGETS_TARGET_COMMODITY_LIST_BOTH_STOCK_OPTIONS_FAILURE,
    payload: error
  };
}

// Edit category
export function targetsEditTargetCategoryRequest(
  targetCategoryId, updatedTargetCategory
) {
  return {
    type: actionTypes.TARGETS_EDIT_TARGET_CATEGORY_REQUEST,
    payload: { targetCategoryId, updatedTargetCategory }
  };
}

export function targetsEditTargetCategoryResponse(
  response, resMessage
) {
  return {
    type: actionTypes.TARGETS_EDIT_TARGET_CATEGORY_RESPONSE,
    payload: { response, resMessage }
  };
}

export function targetsEditTargetCategoryFailure(
  error, resMessage
) {
  return {
    type: actionTypes.TARGETS_EDIT_TARGET_CATEGORY_FAILURE,
    payload: { error, resMessage }
  };
}

// Manufacturers
export function targetsManufacturersListRequest(
  search, limit, page, order, sort
) {
  return {
    type: actionTypes.TARGETS_MANUFACTURERS_LIST_REQUEST,
    payload: { search, limit, page, order, sort }
  };
}

export function targetsManufacturersListResponse(
  response
) {
  return {
    type: actionTypes.TARGETS_MANUFACTURERS_LIST_RESPONSE,
    payload: response
  };
}

export function targetsManufacturersListFailure(
  error
) {
  return {
    type: actionTypes.TARGETS_MANUFACTURERS_LIST_FAILURE,
    payload: error
  };
}

// Target commodity settings
export function targetsTargetCommoditySettingsRequest(
  targetCommodityId
) {
  return {
    type: actionTypes.TARGETS_TARGET_COMMODITY_TARGET_SETTINGS_REQUEST,
    payload: { targetCommodityId }
  };
}

export function targetsTargetCommoditySettingsResponse(
  response
) {
  return {
    type: actionTypes.TARGETS_TARGET_COMMODITY_TARGET_SETTINGS_RESPONSE,
    payload: response
  };
}

export function targetsTargetCommoditySettingsFailure(
  error
) {
  return {
    type: actionTypes.TARGETS_TARGET_COMMODITY_TARGET_SETTINGS_FAILURE,
    payload: error
  };
}

// Edit target commodity settings
export function targetsEditTargetCommoditySettingsRequest(
  targetCommodityTargetId, updatedTargetCommodityTarget
) {
  return {
    type: actionTypes.TARGETS_EDIT_TARGET_COMMODITY_TARGET_SETTINGS_REQUEST,
    payload: { targetCommodityTargetId, updatedTargetCommodityTarget }
  };
}

export function targetsEditTargetCommoditySettingsResponse(
  response
) {
  return {
    type: actionTypes.TARGETS_EDIT_TARGET_COMMODITY_TARGET_SETTINGS_RESPONSE,
    payload: response
  };
}

export function targetsEditTargetCommoditySettingsFailure(
  error
) {
  return {
    type: actionTypes.TARGETS_EDIT_TARGET_COMMODITY_TARGET_SETTINGS_FAILURE,
    payload: error
  };
}

// Edit manufacturer
export function targetsEditManufacturerRequest(
  manufacturerId, updatedManufacturer
) {
  return {
    type: actionTypes.TARGETS_EDIT_MANUFACTURER_REQUEST,
    payload: { manufacturerId, updatedManufacturer }
  };
}

export function targetsEditManufacturerResponse(
  response, resMessage
) {
  return {
    type: actionTypes.TARGETS_EDIT_MANUFACTURER_RESPONSE,
    payload: { response, resMessage }
  };
}

export function targetsEditManufacturerFailure(
  error, resMessage
) {
  return {
    type: actionTypes.TARGETS_EDIT_COMMODITY_TARGET_FAILURE,
    payload: { error, resMessage }
  };
}

// Target feed property
export function targetsTargetFeedProperyListRequest(
  targetId, targetCategoryId
) {
  return {
    type: actionTypes.TARGETS_TARGET_FEED_PROPERTY_LIST_REQUEST,
    payload: { targetId, targetCategoryId }
  };
}

export function targetsTargetFeedProperyListResponse(
  response
) {
  return {
    type: actionTypes.TARGETS_TARGET_FEED_PROPERTY_LIST_RESPONSE,
    payload: response
  };
}

export function targetsTargetFeedProperyListFailure(
  error
) {
  return {
    type: actionTypes.TARGETS_TARGET_FEED_PROPERTY_LIST_FAILURE,
    payload: error
  };
}

// Target commodity property
export function targetsTargetCommodityProperyListRequest(
  targetCommodityId
) {
  return {
    type: actionTypes.TARGETS_TARGET_COMMODITY_PROPERTY_LIST_REQUEST,
    payload: { targetCommodityId }
  };
}

export function targetsTargetCommodityProperyListResponse(
  response
) {
  return {
    type: actionTypes.TARGETS_TARGET_COMMODITY_PROPERTY_LIST_RESPONSE,
    payload: response
  };
}

export function targetsTargetCommodityProperyListFailure(
  error
) {
  return {
    type: actionTypes.TARGETS_TARGET_COMMODITY_PROPERTY_LIST_FAILURE,
    payload: error
  };
}

// Create target commodity property
export function targetsCreateTargetCommodityProperyRequest(
  newTargetCommodityPropertiesArr
) {
  return {
    type: actionTypes.TARGETS_CREATE_TARGET_COMMODITY_PROPERTY_REQUEST,
    payload: { newTargetCommodityPropertiesArr }
  };
}

export function targetsCreateTargetCommodityProperyResponse(
  response, resMessage
) {
  return {
    type: actionTypes.TARGETS_CREATE_TARGET_COMMODITY_PROPERTY_RESPONSE,
    payload: { response, resMessage }
  };
}

export function targetsCreateTargetCommodityProperyFailure(
  error, resMessage
) {
  return {
    type: actionTypes.TARGETS_CREATE_TARGET_COMMODITY_PROPERTY_FAILURE,
    payload: { error, resMessage }
  };
}

// Edit target commodity property
export function targetsEditTargetCommodityProperyRequest(
  editedTargetCommodityPropertiesArr
) {
  return {
    type: actionTypes.TARGETS_EDIT_TARGET_COMMODITY_PROPERTY_REQUEST,
    payload: { editedTargetCommodityPropertiesArr }
  };
}

export function targetsEditTargetCommodityProperyResponse(
  response, resMessage
) {
  return {
    type: actionTypes.TARGETS_EDIT_TARGET_COMMODITY_PROPERTY_RESPONSE,
    payload: { response, resMessage }
  };
}

export function targetsEditTargetCommodityProperyFailure(
  error, resMessage
) {
  return {
    type: actionTypes.TARGETS_EDIT_TARGET_COMMODITY_PROPERTY_FAILURE,
    payload: { error, resMessage }
  };
}

// Single targetCommodity
export function targetsTargetCommodityRequest(
  targetCommodityId
) {
  return {
    type: actionTypes.TARGETS_COMMODITY_TARGET_REQUEST,
    payload: { targetCommodityId }
  };
}

export function targetsTargetCommodityResponse(
  response
) {
  return {
    type: actionTypes.TARGETS_COMMODITY_TARGET_RESPONSE,
    payload: response
  };
}

export function targetsTargetCommodityFailure(
  error
) {
  return {
    type: actionTypes.TARGETS_COMMODITY_TARGET_FAILURE,
    payload: error
  };
}

export function targetsTargetCommodityReset() {
  return {
    type: actionTypes.TARGETS_COMMODITY_TARGET_RESET,
  };
}

// Target commdity category
export function targetsTargetCommodityCategoryRequest(
  targetCommodityId
) {
  return {
    type: actionTypes.TARGETS_TARGET_COMMODITY_CATEGORY_REQUEST,
    payload: { targetCommodityId }
  };
}

export function targetsTargetCommodityCategoryResponse(
  response
) {
  return {
    type: actionTypes.TARGETS_TARGET_COMMODITY_CATEGORY_RESPONSE,
    payload: response
  };
}

export function targetsTargetCommodityCategoryFailure(
  error
) {
  return {
    type: actionTypes.TARGETS_TARGET_COMMODITY_CATEGORY_FAILURE,
    payload: error
  };
}

// Edit target commdity category
export function targetsEditTargetCommodityCategoryRequest(
  targetCommodityCategoryId, targetCommodityCategory
) {
  return {
    type: actionTypes.TARGETS_EDIT_TARGET_COMMODITY_CATEGORY_REQUEST,
    payload: { targetCommodityCategoryId, targetCommodityCategory }
  };
}

export function targetsEditTargetCommodityCategoryResponse(
  response, resMessage
) {
  return {
    type: actionTypes.TARGETS_EDIT_TARGET_COMMODITY_CATEGORY_RESPONSE,
    payload: { response, resMessage }
  };
}

export function targetsEditTargetCommodityCategoryFailure(
  error, resMessage
) {
  return {
    type: actionTypes.TARGETS_EDIT_TARGET_COMMODITY_CATEGORY_FAILURE,
    payload: { error, resMessage }
  };
}
