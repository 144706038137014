import * as actionTypes from './collection-lists.actionTypes'
import * as actions from './collection-lists.actions'
import { AirshopApi } from '../../CraftApi.axios'
import { from, of } from 'rxjs'
import { mergeMap, takeUntil, catchError, map, concatMap, toArray } from 'rxjs/operators'
import { ofType } from 'redux-observable'
import { err, errMessages } from 'redux-store/utils/epics.utils'
import moment from 'moment'

export const fetchCollectionListsListPageEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.COLLECTION_LISTS_LIST_PAGE_REQUEST),
    mergeMap(action => {
      const { transportationTypesId, page } = action.payload;
      // SIMULATED PAGINATION
      // For collection lists, the pages represent the months from this current month
      // 0 - this month, 1 - previous month, 2 - month before previous month
      const month = moment().subtract(page, 'months');
      const dateDueGte = month.startOf('month').valueOf();
      const dateDueLte = month.endOf('month').valueOf();

      return from(AirshopApi.get('balikobot/collectionList/findAllSpecial', {
        params: {
          ...action.payload,
          transportationTypesId: transportationTypesId ? transportationTypesId : -1,
          // Always fetch the first page, the page in table represents the month, not page of these items
          page: 0,
          dateDueGte,
          dateDueLte,
          limit: 500,
          order: 'date_created',
          sort: 'DESC',
        }
      }))
      .pipe(
          map(res => res.data.status === 'OK' ? res.data : err(errMessages.NOT_OK, res)),
          map(response => actions.collectionListsListPageResponse(
            { items: response.items, total: response.total }
          )),
          catchError(error => of(actions.collectionListsListPageFailure(error))),
          takeUntil(action$.ofType(
            actionTypes.COLLECTION_LISTS_LIST_PAGE_RESPONSE ||
            actionTypes.COLLECTION_LISTS_LIST_PAGE_FAILURE
          ))
      )
    })
  );

export const fetchCollectionListsListDetailItemsPageEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.COLLECTION_LISTS_LIST_DETAIL_ITEMS_PAGE_REQUEST),
    mergeMap(action => {
      let total;
      return from(AirshopApi.get('balikobot/collectionListItem/collectionListId', {
        params: { ...action.payload }
      }))
      .pipe(
          map(res => res.data.status === 'OK' ? res.data : err(errMessages.NOT_OK, res)),
          mergeMap(response => {
            total = response.total;
            return from(response.items);
          }),
          // Fetching order to balikobot order item
          concatMap(item => from(AirshopApi.get('orderStatusLog/ordersIdSystem',
            { params: { id: item.orderId, system: 'BALIKOBOT' } }
          ))
           .pipe(
            map(res => res.data.status === 'OK' ? res.data.items : err(errMessages.NOT_OK, res)),
            map(orderStatusLogs => {
              item.orderStatusLogs = orderStatusLogs;
              return item;
            })
          )),
          toArray(),
          map(items => actions.collectionListsListDetailItemsPageResponse(
            { items, total }
          )),
          catchError(error => of(actions.collectionListsListDetailItemsPageFailure(error))),
          takeUntil(action$.ofType(
            actionTypes.COLLECTION_LISTS_LIST_DETAIL_ITEMS_PAGE_RESPONSE ||
            actionTypes.COLLECTION_LISTS_LIST_DETAIL_ITEMS_PAGE_FAILURE
          ))
      )
    })
  );

  export const changeCollectionListStatusEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.COLLECTION_LISTS_COLLECTION_LIST_STATUS_CHANGE_REQUEST),
    mergeMap(action => {
      const { collectionListId, newStatus, sessionId } = action.payload;

      return from(AirshopApi.get('balikobot/collectionList/changeStatus', {
        params: {
          ident: sessionId,
          id: collectionListId,
          status: newStatus,
        }
      }))
      .pipe(
          map(res => res.data.status === 'OK' ? res.data : err(errMessages.NOT_OK, res)),
          map(response => actions.collectionListsCollectionListStatusChangeResponse(
            response,
            actionTypes.COLLECTION_LISTS_COLLECTION_LIST_STATUS_CHANGE_RESPONSE
          )),
          catchError(error => of(actions.collectionListsCollectionListStatusChangeFailure(
            error,
            actionTypes.COLLECTION_LISTS_COLLECTION_LIST_STATUS_CHANGE_FAILURE
          ))),
          takeUntil(action$.ofType(
            actionTypes.COLLECTION_LISTS_COLLECTION_LIST_STATUS_CHANGE_RESPONSE ||
            actionTypes.COLLECTION_LISTS_COLLECTION_LIST_STATUS_CHANGE_FAILURE
          ))
      )
    })
  );

export const fetchStoresListEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.COLLECTION_LISTS_STORES_LIST_REQUEST),
    mergeMap(action => from(AirshopApi.get('stores'))
      .pipe(
          map(res => res.data.status === 'OK' ? res.data.items : err(errMessages.NOT_OK, res)),
          map(response => actions.collectionListsStoresListResponse(response)),
          catchError(error => of(actions.collectionListsStoresListFailure(error))),
          takeUntil(action$.ofType(
            actionTypes.COLLECTION_LISTS_STORES_LIST_RESPONSE ||
            actionTypes.COLLECTION_LISTS_STORES_LIST_FAILURE
          ))
      )
    )
  );

export const deleteCollectionListDetailItemEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.COLLECTION_LISTS_LIST_DETAIL_ITEM_DELETE_REQUEST),
    mergeMap(action => {
      const { collectionListItemId } = action.payload;
      return from(AirshopApi.delete('balikobot/collectionListItem', {
      params: { collectionListItemId }
    }))
      .pipe(
          map(res => res.data.status === 'OK' ? res.data : err(errMessages.NOT_OK, res)),
          map(response => actions.collectionListsCollectionListItemDeleteResponse(
            response,
            actionTypes.COLLECTION_LISTS_LIST_DETAIL_ITEM_DELETE_RESPONSE
          )),
          catchError(error => of(actions.collectionListsCollectionListItemDeleteFailure(
            error,
            actionTypes.COLLECTION_LISTS_LIST_DETAIL_ITEM_DELETE_FAILURE
          ))),
          takeUntil(action$.ofType(
            actionTypes.COLLECTION_LISTS_LIST_DETAIL_ITEM_DELETE_RESPONSE ||
            actionTypes.COLLECTION_LISTS_LIST_DETAIL_ITEM_DELETE_FAILURE
          ))
      )
    })
  );

export const fetchAddedBalikobotOrdersEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.COLLECTION_LISTS_ADDED_BALIKOBOT_ORDERS_REQUEST),
    mergeMap(action => {
      const { draw, page, limit, shipperId, storeId, search } = action.payload;
      let total;
      return from(AirshopApi.get('balikobot/balikobotOrder/status', {
      params: { draw, page: page * 50, limit, search, shipperId, storeId, status: 'ADDED' }
    }))
      .pipe(
          map(res => res.data.status === 'OK' ? res.data : err(errMessages.NOT_OK, res)),
          mergeMap(response => {
            total = response.total;
            return from(response.items);
          }),
          // Fetching order to balikobot order item
          concatMap(item => from(AirshopApi.get(`orders/${item.orderId}`))
           .pipe(
            map(res => res.status === 200 ? res.data : err(errMessages.NOT_200, res)),
            map(order => {
              item.order = order;
              return item;
            })
          )),
          toArray(),
          map(balikobotOrders => actions.collectionListsAddedBalikobotOrdersResponse(
            { items: balikobotOrders, total }
          )),
          catchError(error => of(actions.collectionListsAddedBalikobotOrdersFailure(
            error,
          ))),
          takeUntil(action$.ofType(
            actionTypes.COLLECTION_LISTS_ADDED_BALIKOBOT_ORDERS_RESPONSE ||
            actionTypes.COLLECTION_LISTS_ADDED_BALIKOBOT_ORDERS_FAILURE
          ))
      )
    })
  );

export const fetchShippersListEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.COLLECTION_LISTS_SHIPPERS_LIST_REQUEST),
    mergeMap(action => from(AirshopApi.get('balikobot/shipper', {
        params: {
          limit: 999,
        }
      }))
      .pipe(
          map(res => res.data.status === 'OK' ? res.data.items : err(errMessages.NOT_OK, res)),
          map(response => actions.collectionListsShippersListResponse(response)),
          catchError(error => of(actions.collectionListsShippersListFailure(error))),
          takeUntil(action$.ofType(
            actionTypes.COLLECTION_LISTS_SHIPPERS_LIST_RESPONSE ||
            actionTypes.COLLECTION_LISTS_SHIPPERS_LIST_FAILURE
          ))
      )
    )
  );

export const printDocumentEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.COLLECTION_LISTS_PRINT_DOCUMENT_REQUEST),
    mergeMap(action => {
      const { type, itemId, userUid, ident } = action.payload;

      return from(AirshopApi.get('print/p/printA4', {
        params: {
          type,
          id: itemId,
          uid: userUid,
          ident,
        }
      }))
      .pipe(
          map(response => response.data.status === 'OK' ? response : err(errMessages.NOT_OK, response)),
          map(response => actions.collectionListsPrintDocumentResponse(
            response,
            actionTypes.COLLECTION_LISTS_PRINT_DOCUMENT_RESPONSE
          )),
          catchError(error => of(actions.collectionListsPrintDocumentFailure(
            error,
            actionTypes.COLLECTION_LISTS_PRINT_DOCUMENT_FAILURE
          ))
          ),
          takeUntil(action$.ofType(
            actionTypes.COLLECTION_LISTS_PRINT_DOCUMENT_RESPONSE ||
              actionTypes.COLLECTION_LISTS_PRINT_DOCUMENT_FAILURE
          ))
      )
    })
  );

export const generatePdfDocumentEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.COLLECTION_LISTS_GENERATE_PDF_REQUEST),
    mergeMap(action => {
      const { type, itemId, userUid, ident } = action.payload;

      return from(AirshopApi.get(
        `print/p/document?type=${type}&id=${itemId}&uid=${userUid}&ident=${ident}`,
        {responseType: 'blob'}))
      .pipe(
        map(response => response.status === 200 && response.data && response.data.type === 'text/xml' ?
                        response.data :
                        err(errMessages.DATA_NOT_GENERATED, response.data)
        ),
        map(response => actions.collectionListsGeneratePdfDocumentResponse(
          response,
          actionTypes.COLLECTION_LISTS_GENERATE_PDF_RESPONSE
        )),
        catchError(error => of(actions.collectionListsGeneratePdfDocumentFailure(
          error,
          actionTypes.COLLECTION_LISTS_GENERATE_PDF_FAILURE
        ))
        ),
        takeUntil(action$.ofType(
          actionTypes.COLLECTION_LISTS_GENERATE_PDF_RESPONSE ||
            actionTypes.COLLECTION_LISTS_GENERATE_PDF_FAILURE
        ))
      )
    })
  );
