export const ORDERS_LIST_REQUEST = '[ORDERS]_LIST_REQUEST'
export const ORDERS_LIST_RESPONSE = '[ORDERS]_LIST_RESPONSE'
export const ORDERS_LIST_FAILURE = '[ORDERS]_LIST_FAILURE'

export const ORDERS_TRANSPORTATION_TYPES_REQUEST = '[ORDERS]_TRANSPORTATION_TYPES_REQUEST'
export const ORDERS_TRANSPORTATION_TYPES_RESPONSE = '[ORDERS]_TRANSPORTATION_TYPES_RESPONSE'
export const ORDERS_TRANSPORTATION_TYPES_FAILURE = '[ORDERS]_TRANSPORTATION_TYPES_FAILURE'

// Czech language kept for the code to match the previous AngularJS version
export const ORDERS_STAV_OBJEDNAVKY_REQUEST = '[ORDERS]_STAV_OBJEDNAVKY_REQUEST'
export const ORDERS_STAV_OBJEDNAVKY_RESPONSE = '[ORDERS]_STAV_OBJEDNAVKY_RESPONSE'
export const ORDERS_STAV_OBJEDNAVKY_FAILURE = '[ORDERS]_STAV_OBJEDNAVKY_FAILURE'

export const ORDERS_ORDER_DETAIL_PRODUCTS_REQUEST = '[ORDERS]_ORDER_DETAIL_PRODUCTS_REQUEST'
export const ORDERS_ORDER_DETAIL_PRODUCTS_RESPONSE = '[ORDERS]_ORDER_DETAIL_PRODUCTS_RESPONSE'
export const ORDERS_ORDER_DETAIL_PRODUCTS_FAILURE = '[ORDERS]_ORDER_DETAIL_PRODUCTS_FAILURE'

export const ORDERS_SELECTED_ORDER_REQUEST = '[ORDERS]_SELECTED_ORDER_REQUEST'
export const ORDERS_SELECTED_ORDER_RESPONSE = '[ORDERS]_SELECTED_ORDER_RESPONSE'
export const ORDERS_SELECTED_ORDER_FAILURE = '[ORDERS]_SELECTED_ORDER_FAILURE'

export const ORDERS_UPDATE_STAV_OBJEDNAVKY_REQUEST = '[ORDERS]_UPDATE_STAV_OBJEDNAVKY_REQUEST'
export const ORDERS_UPDATE_STAV_OBJEDNAVKY_RESPONSE = '[ORDERS]_UPDATE_STAV_OBJEDNAVKY_RESPONSE'
export const ORDERS_UPDATE_STAV_OBJEDNAVKY_FAILURE = '[ORDERS]_UPDATE_STAV_OBJEDNAVKY_FAILURE'

export const ORDERS_FIRM_REQUEST = '[ORDERS]_FIRM_REQUEST'
export const ORDERS_FIRM_RESPONSE = '[ORDERS]_FIRM_RESPONSE'
export const ORDERS_FIRM_FAILURE = '[ORDERS]_FIRM_FAILURE'
export const ORDERS_FIRM_RESET = '[ORDERS]_FIRM_RESET'

export const ORDERS_ORDER_DETAIL_PRODUCTS_FOR_PDF_REQUEST = '[ORDERS]_ORDER_DETAIL_PRODUCTS_FOR_PDF_REQUEST'
export const ORDERS_ORDER_DETAIL_PRODUCTS_FOR_PDF_RESPONSE = '[ORDERS]_ORDER_DETAIL_PRODUCTS_FOR_PDF_RESPONSE'
export const ORDERS_ORDER_DETAIL_PRODUCTS_FOR_PDF_FAILURE = '[ORDERS]_ORDER_DETAIL_PRODUCTS_FOR_PDF_FAILURE'
export const ORDERS_ORDER_DETAIL_PRODUCTS_FOR_PDF_RESET = '[ORDERS]_ORDER_DETAIL_PRODUCTS_FOR_PDF_RESET'

export const ORDERS_ORDER_TO_STAV_SEND_SMS_REQUEST = '[ORDERS]_ORDER_TO_STAV_SEND_SMS_REQUEST'
export const ORDERS_ORDER_TO_STAV_SEND_SMS_RESPONSE = '[ORDERS]_ORDER_TO_STAV_SEND_SMS_RESPONSE'
export const ORDERS_ORDER_TO_STAV_SEND_SMS_FAILURE = '[ORDERS]_ORDER_TO_STAV_SEND_SMS_FAILURE'

export const ORDERS_ORDER_TO_STAV_SEND_EMAIL_REQUEST = '[ORDERS]_ORDER_TO_STAV_SEND_EMAIL_REQUEST'
export const ORDERS_ORDER_TO_STAV_SEND_EMAIL_RESPONSE = '[ORDERS]_ORDER_TO_STAV_SEND_EMAIL_RESPONSE'
export const ORDERS_ORDER_TO_STAV_SEND_EMAIL_FAILURE = '[ORDERS]_ORDER_TO_STAV_SEND_EMAIL_FAILURE'

export const ORDERS_RESPONSE_MESSAGE_RESET = '[ORDERS]_RESPONSE_MESSAGE_RESET'

export const ORDERS_PARTNER_MESSAGES_REQUEST = '[ORDERS]_PARTNER_MESSAGES_REQUEST'
export const ORDERS_PARTNER_MESSAGES_RESPONSE = '[ORDERS]_PARTNER_MESSAGES_RESPONSE'
export const ORDERS_PARTNER_MESSAGES_FAILURE = '[ORDERS]_PARTNER_MESSAGES_FAILURE'

export const ORDERS_ORDER_ITEM_UPDATE_QUANTITY_REQUEST = '[ORDERS]_ORDER_ITEM_UPDATE_QUANTITY_REQUEST'
export const ORDERS_ORDER_ITEM_UPDATE_QUANTITY_RESPONSE = '[ORDERS]_ORDER_ITEM_UPDATE_QUANTITY_RESPONSE'
export const ORDERS_ORDER_ITEM_UPDATE_QUANTITY_FAILURE = '[ORDERS]_ORDER_ITEM_UPDATE_QUANTITY_FAILURE'

export const ORDERS_ADDRESS_REQUEST = '[ORDERS]_ADDRESS_REQUEST'
export const ORDERS_ADDRESS_RESPONSE = '[ORDERS]_ADDRESS_RESPONSE'
export const ORDERS_ADDRESS_FAILURE = '[ORDERS]_ADDRESS_FAILURE'

export const ORDERS_PARTNER_REQUEST = '[ORDERS]_PARTNER_REQUEST'
export const ORDERS_PARTNER_RESPONSE = '[ORDERS]_PARTNER_RESPONSE'
export const ORDERS_PARTNER_FAILURE = '[ORDERS]_PARTNER_FAILURE'
export const ORDERS_PARTNER_CLOSE_STREAM = '[ORDERS]_PARTNER_CLOSE_STREAM'

export const ORDERS_ORDER_ITEM_DELETE_REQUEST = 'ORDERS_ORDER_ITEM_DELETE_REQUEST'
export const ORDERS_ORDER_ITEM_DELETE_RESPONSE = 'ORDERS_ORDER_ITEM_DELETE_RESPONSE'
export const ORDERS_ORDER_ITEM_DELETE_FAILURE = 'ORDERS_ORDER_ITEM_DELETE_FAILURE'

export const ORDERS_NEW_PARTNER_MESSAGE_REQUEST = '[ORDERS]_NEW_PARTNER_MESSAGE_REQUEST'
export const ORDERS_NEW_PARTNER_MESSAGE_RESPONSE = '[ORDERS]_NEW_PARTNER_MESSAGE_RESPONSE'
export const ORDERS_NEW_PARTNER_MESSAGE_FAILURE = '[ORDERS]_NEW_PARTNER_MESSAGE_FAILURE'

export const ORDERS_ORDER_SYNC_ABRA_REQUEST = '[ORDERS]_ORDER_SYNC_ABRA_REQUEST'
export const ORDERS_ORDER_SYNC_ABRA_RESPONSE = '[ORDERS]_ORDER_SYNC_ABRA_RESPONSE'
export const ORDERS_ORDER_SYNC_ABRA_FAILURE = '[ORDERS]_ORDER_SYNC_ABRA_FAILURE'

export const ORDERS_CREATE_DELIVERY_LIST_REQUEST = '[ORDERS]_CREATE_DELIVERY_LIST_REQUEST'
export const ORDERS_CREATE_DELIVERY_LIST_RESPONSE = '[ORDERS]_CREATE_DELIVERY_LIST_RESPONSE'
export const ORDERS_CREATE_DELIVERY_LIST_FAILURE = '[ORDERS]_CREATE_DELIVERY_LIST_FAILURE'

export const ORDERS_ORDER_DELIVERY_LIST_REQUEST = '[ORDERS]_ORDER_DELIVERY_LIST_REQUEST'
export const ORDERS_ORDER_DELIVERY_LIST_RESPONSE = '[ORDERS]_ORDER_DELIVERY_LIST_RESPONSE'
export const ORDERS_ORDER_DELIVERY_LIST_FAILURE = '[ORDERS]_ORDER_DELIVERY_LIST_FAILURE'

export const ORDERS_RECREATE_DELIVERY_LIST_REQUEST = '[ORDERS]_RECREATE_DELIVERY_LIST_REQUEST'
export const ORDERS_RECREATE_DELIVERY_LIST_RESPONSE = '[ORDERS]_RECREATE_DELIVERY_LIST_RESPONSE'
export const ORDERS_RECREATE_DELIVERY_LIST_FAILURE = '[ORDERS]_RECREATE_DELIVERY_LIST_FAILURE'

export const ORDERS_ORDER_CREATE_ITEM_REQUEST = '[ORDERS]_ORDER_CREATE_ITEM_REQUEST'
export const ORDERS_ORDER_CREATE_ITEM_RESPONSE = '[ORDERS]_ORDER_CREATE_ITEM_RESPONSE'
export const ORDERS_ORDER_CREATE_ITEM_FAILURE = '[ORDERS]_ORDER_CREATE_ITEM_FAILURE'

export const ORDERS_ORDER_SYNC_STATUSES_CHECK_REQUEST = '[ORDERS]_ORDER_SYNC_STATUSES_CHECK_REQUEST'
export const ORDERS_ORDER_SYNC_STATUSES_CHECK_RESPONSE = '[ORDERS]_ORDER_SYNC_STATUSES_CHECK_RESPONSE'
export const ORDERS_ORDER_SYNC_STATUSES_CHECK_FAILURE = '[ORDERS]_ORDER_SYNC_STATUSES_CHECK_FAILURE'

export const ORDERS_SYNC_ABRA_WHILE_IN_ABRA_REQUEST = '[ORDERS]_SYNC_ABRA_WHILE_IN_ABRA_REQUEST'
export const ORDERS_SYNC_ABRA_WHILE_IN_ABRA_RESPONSE = '[ORDERS]_SYNC_ABRA_WHILE_IN_ABRA_RESPONSE'
export const ORDERS_SYNC_ABRA_WHILE_IN_ABRA_FAILURE = '[ORDERS]_SYNC_ABRA_WHILE_IN_ABRA_FAILURE'

export const ORDERS_PRINT_DOCUMENT_REQUEST = '[ORDERS]_PRINT_DOCUMENT_REQUEST'
export const ORDERS_PRINT_DOCUMENT_RESPONSE = '[ORDERS]_PRINT_DOCUMENT_RESPONSE'
export const ORDERS_PRINT_DOCUMENT_FAILURE = '[ORDERS]_PRINT_DOCUMENT_FAILURE'

export const ORDERS_PRINT_BALIKOBOT_REQUEST = '[ORDERS]_PRINT_BALIKOBOT_REQUEST'
export const ORDERS_PRINT_BALIKOBOT_RESPONSE = '[ORDERS]_PRINT_BALIKOBO_RESPONSE'
export const ORDERS_PRINT_BALIKOBOT_FAILURE = '[ORDERS]_PRINT_BALIKOBOT_FAILURE'

export const ORDERS_PRINT_THERMAL80_REQUEST = '[ORDERS]_PRINT_THERMAL80_REQUEST'
export const ORDERS_PRINT_THERMAL80_RESPONSE = '[ORDERS]_PRINT_THERMAL80_RESPONSE'
export const ORDERS_PRINT_THERMAL80_FAILURE = '[ORDERS]_PRINT_THERMAL80_FAILURE'

export const ORDERS_MASS_PRINT_DOCUMENTS_REQUEST = '[ORDERS]_MASS_PRINT_DOCUMENTS_REQUEST'
export const ORDERS_MASS_PRINT_DOCUMENTS_RESPONSE = '[ORDERS]_MASS_PRINT_DOCUMENTS_RESPONSE'
export const ORDERS_MASS_PRINT_DOCUMENTS_FAILURE = '[ORDERS]_MASS_PRINT_DOCUMENTS_FAILURE'

export const ORDERS_GENERATE_PDF_REQUEST = '[ORDERS]_GENERATE_PDF_REQUEST'
export const ORDERS_GENERATE_PDF_RESPONSE = '[ORDERS]_GENERATE_PDF_RESPONSE'
export const ORDERS_GENERATE_PDF_FAILURE = '[ORDERS]_GENERATE_PDF_FAILURE'

export const ORDERS_RESET_GENERATED_PDF = '[ORDERS]_RESET_GENERATED_PDF'

export const ORDERS_MASS_GENERATE_PDF_REQUEST = '[ORDERS]_MASS_GENERATE_PDF_REQUEST'
export const ORDERS_MASS_GENERATE_PDF_RESPONSE = '[ORDERS]_MASS_GENERATE_PDF_RESPONSE'
export const ORDERS_MASS_GENERATE_PDF_FAILURE = '[ORDERS]_MASS_GENERATE_PDF_FAILURE'

export const ORDERS_UPDATE_BULK_ORDERS_STAV_OBJEDNAVKY_REQUEST = '[ORDERS]_UPDATE_BULK_ORDERS_STAV_OBJEDNAVKY_REQUEST'
export const ORDERS_UPDATE_BULK_ORDERS_STAV_OBJEDNAVKY_RESPONSE = '[ORDERS]_UPDATE_BULK_ORDERS_STAV_OBJEDNAVKY_RESPONSE'
export const ORDERS_UPDATE_BULK_ORDERS_STAV_OBJEDNAVKY_FAILURE = '[ORDERS]_UPDATE_BULK_ORDERS_STAV_OBJEDNAVKY_FAILURE'

export const ORDERS_CREATE_CLITEM_FROM_ORDER_REQUEST = '[ORDERS]_CREATE_CLITEM_FROM_ORDER_REQUEST'
export const ORDERS_CREATE_CLITEM_FROM_ORDER_RESPONSE = '[ORDERS]_CREATE_CLITEM_FROM_ORDER_RESPONSE'
export const ORDERS_CREATE_CLITEM_FROM_ORDER_FAILURE = '[ORDERS]_CREATE_CLITEM_FROM_ORDER_FAILURE'

export const ORDERS_COLLECTION_LIST_QUEUED_REQUEST = '[ORDERS]_COLLECTION_LIST_QUEUED_REQUEST'
export const ORDERS_COLLECTION_LIST_QUEUED_RESPONSE = '[ORDERS]_COLLECTION_LIST_QUEUED_RESPONSE'
export const ORDERS_COLLECTION_LIST_QUEUED_FAILURE = '[ORDERS]_COLLECTION_LIST_QUEUED_FAILURE'

export const ORDERS_CREATE_BALIKOBOT_ORDER_ENTRY_REQUEST = '[ORDERS]_CREATE_BALIKOBOT_ORDER_ENTRY_REQUEST'
export const ORDERS_CREATE_BALIKOBOT_ORDER_ENTRY_RESPONSE = '[ORDERS]_CREATE_BALIKOBOT_ORDER_ENTRY_RESPONSE'
export const ORDERS_CREATE_BALIKOBOT_ORDER_ENTRY_FAILURE = '[ORDERS]_CREATE_BALIKOBOT_ORDER_ENTRY_FAILURE'

export const ORDERS_BALIKOBOT_ORDER_ENTRY_REQUEST = '[ORDERS]_BALIKOBOT_ORDER_ENTRY_REQUEST'
export const ORDERS_BALIKOBOT_ORDER_ENTRY_RESPONSE = '[ORDERS]_BALIKOBOT_ORDER_ENTRY_RESPONSE'
export const ORDERS_BALIKOBOT_ORDER_ENTRY_FAILURE = '[ORDERS]_BALIKOBOT_ORDER_ENTRY_FAILURE'

export const ORDERS_MULTIPLE_BALIKOBOT_ORDER_ENTRY_REQUEST = '[ORDERS]_MULTIPLE_BALIKOBOT_ORDER_ENTRY_REQUEST'
export const ORDERS_MULTIPLE_BALIKOBOT_ORDER_ENTRY_RESPONSE = '[ORDERS]_MULTIPLE_BALIKOBOT_ORDER_ENTRY_RESPONSE'
export const ORDERS_MULTIPLE_BALIKOBOT_ORDER_ENTRY_FAILURE = '[ORDERS]_MULTIPLE_BALIKOBOT_ORDER_ENTRY_FAILURE'

export const ORDERS_UPDATE_BALIKOBOT_ORDER_ENTRY_REQUEST = '[ORDERS]_UPDATE_BALIKOBOT_ORDER_ENTRY_REQUEST'
export const ORDERS_UPDATE_BALIKOBOT_ORDER_ENTRY_RESPONSE = '[ORDERS]_UPDATE_BALIKOBOT_ORDER_ENTRY_RESPONSE'
export const ORDERS_UPDATE_BALIKOBOT_ORDER_ENTRY_FAILURE = '[ORDERS]_UPDATE_BALIKOBOT_ORDER_ENTRY_FAILURE'

export const ORDERS_GENERATE_BALIKOBOT_TAG_REQUEST = '[ORDERS]_GENERATE_BALIKOBOT_TAG_REQUEST'
export const ORDERS_GENERATE_BALIKOBOT_TAG_RESPONSE = '[ORDERS]_GENERATE_BALIKOBOT_TAG_RESPONSE'
export const ORDERS_GENERATE_BALIKOBOT_TAG_FAILURE = '[ORDERS]_GENERATE_BALIKOBOT_TAG_FAILURE'

export const ORDERS_DROP_ORDER_FROM_BALIKOBOT_REQUEST = '[ORDERS]_DROP_ORDER_FROM_BALIKOBOT_REQUEST'
export const ORDERS_DROP_ORDER_FROM_BALIKOBOT_RESPONSE = '[ORDERS]_DROP_ORDER_FROM_BALIKOBOT_RESPONSE'
export const ORDERS_DROP_ORDER_FROM_BALIKOBOT_FAILURE = '[ORDERS]_DROP_ORDER_FROM_BALIKOBOT_FAILURE'

export const ORDERS_CREATE_CLITEMS_FROM_MULTIPLE_ORDERS_REQUEST = '[ORDERS]_CREATE_CLITEMS_FROM_MULTIPLE_ORDERS_REQUEST'
export const ORDERS_CREATE_CLITEMS_FROM_MULTIPLE_ORDERS_RESPONSE = '[ORDERS]_CREATE_CLITEMS_FROM_MULTIPLE_ORDERS_RESPONSE'
export const ORDERS_CREATE_CLITEMS_FROM_MULTIPLE_ORDERS_FAILURE = '[ORDERS]_CREATE_CLITEMS_FROM_MULTIPLE_ORDERS_FAILURE'

export const ORDERS_ORDER_DETAIL_STATUS_LOGS_REQUEST = '[ORDERS]_ORDER_DETAIL_STATUS_LOGS_REQUEST'
export const ORDERS_ORDER_DETAIL_STATUS_LOGS_RESPONSE = '[ORDERS]_ORDER_DETAIL_STATUS_LOGS_RESPONSE'
export const ORDERS_ORDER_DETAIL_STATUS_LOGS_FAILURE = '[ORDERS]_ORDER_DETAIL_STATUS_LOGS_FAILURE'

export const ORDERS_BALIKOBOT_ORDER_ENTRY_RESET = '[ORDERS]_BALIKOBOT_ORDER_ENTRY_RESET'

export const ORDERS_BALIKOBOT_ORDER_BRANCH_REQUEST = '[ORDERS]_BALIKOBOT_ORDER_BRANCH_REQUEST'
export const ORDERS_BALIKOBOT_ORDER_BRANCH_RESPONSE = '[ORDERS]_BALIKOBOT_ORDER_BRANCH_RESPONSE'
export const ORDERS_BALIKOBOT_ORDER_BRANCH_FAILURE = '[ORDERS]_BALIKOBOT_ORDER_BRANCH_FAILURE'

export const ORDERS_BALIKOBOT_ORDER_BRANCH_RESET = '[ORDERS]_BALIKOBOT_ORDER_BRANCH_RESET'

export const ORDERS_BRANCHES_BY_SHIPPER_ID_REQUEST = '[ORDERS]_BRANCHES_BY_SHIPPER_ID_REQUEST'
export const ORDERS_BRANCHES_BY_SHIPPER_ID_RESPONSE = '[ORDERS]_BRANCHES_BY_SHIPPER_ID_RESPONSE'
export const ORDERS_BRANCHES_BY_SHIPPER_ID_FAILURE = '[ORDERS]_BRANCHES_BY_SHIPPER_ID_FAILURE'
export const ORDERS_BRANCHES_BY_SHIPPER_ID_RESET = '[ORDERS]_BRANCHES_BY_SHIPPER_ID_RESET'

export const ORDERS_TRANSPORTATION_TYPES_DRAFT_VALID_REQUEST = '[ORDERS]_TRANSPORTATION_TYPES_DRAFT_VALID_REQUEST'
export const ORDERS_TRANSPORTATION_TYPES_DRAFT_VALID_RESPONSE = '[ORDERS]_TRANSPORTATION_TYPES_DRAFT_VALID_RESPONSE'
export const ORDERS_TRANSPORTATION_TYPES_DRAFT_VALID_FAILURE = '[ORDERS]_TRANSPORTATION_TYPES_DRAFT_VALID_FAILURE'

export const ORDERS_SERVICE_TYPES_BY_SHIPPER_ID_REQUEST = '[ORDERS]_SERVICE_TYPES_BY_SHIPPER_ID_REQUEST'
export const ORDERS_SERVICE_TYPES_BY_SHIPPER_ID_RESPONSE = '[ORDERS]_SERVICE_TYPES_BY_SHIPPER_ID_RESPONSE'
export const ORDERS_SERVICE_TYPES_BY_SHIPPER_ID_FAILURE = '[ORDERS]_SERVICE_TYPES_BY_SHIPPER_ID_FAILURE'
export const ORDERS_SERVICE_TYPES_BY_SHIPPER_ID_RESET = '[ORDERS]_SERVICE_TYPES_BY_SHIPPER_ID_RESET'

export const ORDERS_ORDERS_COUNT_BY_EMAIL_REQUEST = '[ORDERS]_ORDERS_COUNT_BY_EMAIL_REQUEST'
export const ORDERS_ORDERS_COUNT_BY_EMAIL_RESPONSE = '[ORDERS]_ORDERS_COUNT_BY_EMAIL_RESPONSE'
export const ORDERS_ORDERS_COUNT_BY_EMAIL_FAILURE = '[ORDERS]_ORDERS_COUNT_BY_EMAIL_FAILURE'

export const ORDERS_ORDER_ABRA_COMMAND_REQUEST = '[ORDERS]_ORDER_ABRA_COMMAND_REQUEST'
export const ORDERS_ORDER_ABRA_COMMAND_RESPONSE = '[ORDERS]_ORDER_ABRA_COMMAND_RESPONSE'
export const ORDERS_ORDER_ABRA_COMMAND_FAILURE = '[ORDERS]_ORDER_ABRA_COMMAND_FAILURE'

export const ORDERS_CREATE_ORDER_SUBSCRIPTION_REQUEST = '[ORDERS]_CREATE_ORDER_SUBSCRIPTION_REQUEST'
export const ORDERS_CREATE_ORDER_SUBSCRIPTION_RESPONSE = '[ORDERS]_CREATE_ORDER_SUBSCRIPTION_RESPONSE'
export const ORDERS_CREATE_ORDER_SUBSCRIPTION_FAILURE = '[ORDERS]_CREATE_ORDER_SUBSCRIPTION_FAILURE'
export const ORDERS_CREATE_ORDER_SUBSCRIPTION_WITH_UPDATE_REQUEST = '[ORDERS]_CREATE_ORDER_SUBSCRIPTION_WITH_UPDATE_REQUEST'

// export const ORDERS_CREATE_ORDER_SUBSCRIPTION_REQUEST = '[ORDERS]_CREATE_ORDER_SUBSCRIPTION_REQUEST'
// export const ORDERS_CREATE_ORDER_SUBSCRIPTION_RESPONSE = '[ORDERS]_CREATE_ORDER_SUBSCRIPTION_RESPONSE'
// export const ORDERS_CREATE_ORDER_SUBSCRIPTION_FAILURE = '[ORDERS]_CREATE_ORDER_SUBSCRIPTION_FAILURE'

export const ORDERS_SUBSCRIPTIONS_LIST_REQUEST = 'ORDERS_SUBSCRIPTIONS_LIST_REQUEST'
export const ORDERS_SUBSCRIPTIONS_LIST_RESPONSE = 'ORDERS_SUBSCRIPTIONS_LIST_RESPONSE'
export const ORDERS_SUBSCRIPTIONS_LIST_FAILURE = 'ORDERS_SUBSCRIPTIONS_LIST_FAILURE'

export const ORDERS_ORDER_DETAIL_SUBSCRIPTIONS_LIST_REQUEST = 'ORDERS_ORDER_DETAIL_SUBSCRIPTIONS_LIST_REQUEST'
export const ORDERS_ORDER_DETAIL_SUBSCRIPTIONS_LIST_RESPONSE = 'ORDERS_ORDER_DETAIL_SUBSCRIPTIONS_LIST_RESPONSE'
export const ORDERS_ORDER_DETAIL_SUBSCRIPTIONS_LIST_FAILURE = 'ORDERS_ORDER_DETAIL_SUBSCRIPTIONS_LIST_FAILURE'
export const ORDERS_ORDER_DETAIL_SUBSCRIPTIONS_LIST_RESET = 'ORDERS_ORDER_DETAIL_SUBSCRIPTIONS_LIST_RESET'

export const ORDERS_ORDERS_COUNTS_REQUEST = '[ORDERS]_ORDERS_COUNTS_REQUEST'
export const ORDERS_ORDERS_COUNTS_RESPONSE = '[ORDERS]_ORDERS_COUNTS_RESPONSE'
export const ORDERS_ORDERS_COUNTS_FAILURE = '[ORDERS]_ORDERS_COUNTS_FAILURE'

export const ORDERS_SOURCE_SUBSCRIPTIONS_BY_INDEX_REQUEST = '[ORDERS]_SOURCE_SUBSCRIPTIONS_BY_INDEX_REQUEST'
export const ORDERS_SOURCE_SUBSCRIPTIONS_BY_INDEX_RESPONSE = '[ORDERS]_SOURCE_SUBSCRIPTIONS_BY_INDEX_RESPONSE'
export const ORDERS_SOURCE_SUBSCRIPTIONS_BY_INDEX_FAILURE = '[ORDERS]_SOURCE_SUBSCRIPTIONS_BY_INDEX_FAILURE'

export const ORDERS_MULTIPLE_CREATE_ORDER_SUBSCRIPTION_REQUEST = '[ORDERS]_MULTIPLE_CREATE_ORDER_SUBSCRIPTION_REQUEST'
export const ORDERS_MULTIPLE_CREATE_ORDER_SUBSCRIPTION_RESPONSE = '[ORDERS]_MULTIPLE_CREATE_ORDER_SUBSCRIPTION_RESPONSE'
export const ORDERS_MULTIPLE_CREATE_ORDER_SUBSCRIPTION_FAILURE = '[ORDERS]_MULTIPLE_CREATE_ORDER_SUBSCRIPTION_FAILURE'

export const ORDERS_DELETE_ORDER_SUBSCRIPTION_REQUEST = '[ORDERS]_DELETE_ORDER_SUBSCRIPTION_REQUEST'
export const ORDERS_DELETE_ORDER_SUBSCRIPTION_RESPONSE = '[ORDERS]_DELETE_ORDER_SUBSCRIPTION_RESPONSE'
export const ORDERS_DELETE_ORDER_SUBSCRIPTION_FAILURE = '[ORDERS]_DELETE_ORDER_SUBSCRIPTION_FAILURE'

export const ORDERS_EDIT_ORDER_SUBSCRIPTION_REQUEST = '[ORDERS]_EDIT_ORDER_SUBSCRIPTION_REQUEST'
export const ORDERS_EDIT_ORDER_SUBSCRIPTION_RESPONSE = '[ORDERS]_EDIT_ORDER_SUBSCRIPTION_RESPONSE'
export const ORDERS_EDIT_ORDER_SUBSCRIPTION_FAILURE = '[ORDERS]_EDIT_ORDER_SUBSCRIPTION_FAILURE'

export const ORDERS_GET_SELECTED_ORDER_ADDRESSES_REQUEST = '[ORDERS]_GET_SELECTED_ORDER_ADDRESSES_REQUEST'
export const ORDERS_GET_SELECTED_ORDER_ADDRESSES_RESPONSE = '[ORDERS]_GET_SELECTED_ORDER_ADDRESSES_RESPONSE'
export const ORDERS_GET_SELECTED_ORDER_ADDRESSES_FAILURE = '[ORDERS]_GET_SELECTED_ORDER_ADDRESSES_FAILURE'

export const ORDERS_SET_SELECTED_ORDER_ADDRESS_REQUEST = '[ORDERS]_SET_SELECTED_ORDER_ADDRESS_REQUEST'
export const ORDERS_SET_SELECTED_ORDER_ADDRESS_RESPONSE = '[ORDERS]_SET_SELECTED_ORDER_ADDRESS_RESPONSE'
export const ORDERS_SET_SELECTED_ORDER_ADDRESS_FAILURE = '[ORDERS]_SET_SELECTED_ORDER_ADDRESS_FAILURE'
