export const RENT_TEMPLATES_PDF_LIST_REQUEST = '[RENT]_TEMPLATES_PDF_LIST_REQUEST'
export const RENT_TEMPLATES_PDF_LIST_RESPONSE = '[RENT]_TEMPLATES_PDF_LIST_RRESPONSE'
export const RENT_TEMPLATES_PDF_LIST_FAILURE = '[RENT]_TEMPLATES_PDF_LIST_FAILURE'

export const RENT_CREATE_TEMPLATE_PDF_REQUEST = '[RENT]_CREATE_TEMPLATE_PDF_REQUEST'
export const RENT_CREATE_TEMPLATE_PDF_RESPONSE = '[RENT]_CREATE_TEMPLATE_PDF_RRESPONSE'
export const RENT_CREATE_TEMPLATE_PDF_FAILURE = '[RENT]_CREATE_TEMPLATE_PDF_FAILURE'

export const RENT_EDIT_TEMPLATE_PDF_REQUEST = '[RENT]_EDIT_TEMPLATE_PDF_REQUEST'
export const RENT_EDIT_TEMPLATE_PDF_RESPONSE = '[RENT]_EDIT_TEMPLATE_PDF_RRESPONSE'
export const RENT_EDIT_TEMPLATE_PDF_FAILURE = '[RENT]_EDIT_TEMPLATE_PDF_FAILURE'

export const RENT_DELETE_TEMPLATE_PDF_REQUEST = '[RENT]_DELETE_TEMPLATE_PDF_REQUEST'
export const RENT_DELETE_TEMPLATE_PDF_RESPONSE = '[RENT]_DELETE_TEMPLATE_PDF_RRESPONSE'
export const RENT_DELETE_TEMPLATE_PDF_FAILURE = '[RENT]_DELETE_TEMPLATE_PDF_FAILURE'

export const RENT_CUSTOMERS_LIST_REQUEST = '[RENT]_CUSTOMERS_LIST_REQUEST'
export const RENT_CUSTOMERS_LIST_RESPONSE = '[RENT]_CUSTOMERS_LIST_RESPONSE'
export const RENT_CUSTOMERS_LIST_FAILURE = '[RENT]_CUSTOMERS_LIST_FAILURE'

export const RENT_CREATE_CUSTOMER_REQUEST = '[RENT]_CREATE_CUSTOMER_REQUEST'
export const RENT_CREATE_CUSTOMER_RESPONSE = '[RENT]_CREATE_CUSTOMER_RESPONSE'
export const RENT_CREATE_CUSTOMER_FAILURE = '[RENT]_CREATE_CUSTOMER_FAILURE'
export const RENT_CREATE_CUSTOMER_RESET = '[RENT]_CREATE_CUSTOMER_RESET'

export const RENT_EDIT_CUSTOMER_REQUEST = '[RENT]_EDIT_CUSTOMER_REQUEST'
export const RENT_EDIT_CUSTOMER_RESPONSE = '[RENT]_EDIT_CUSTOMER_RESPONSE'
export const RENT_EDIT_CUSTOMER_FAILURE = '[RENT]_EDIT_CUSTOMER_FAILURE'

export const RENT_CONTRACT_ITEMS_BY_CONTRACT_ID_REQUEST = '[RENT]_CONTRACT_ITEMS_BY_CONTRACT_ID_REQUEST'
export const RENT_CONTRACT_ITEMS_BY_CONTRACT_ID_RESPONSE = '[RENT]_CONTRACT_ITEMS_BY_CONTRACT_ID_RESPONSE'
export const RENT_CONTRACT_ITEMS_BY_CONTRACT_ID_FAILURE = '[RENT]_CONTRACT_ITEMS_BY_CONTRACT_ID_FAILURE'

export const RENT_CREATE_MULTIPLE_CONTRACT_ITEMS_REQUEST = '[RENT]_CREATE_MULTIPLE_CONTRACT_ITEMS_REQUEST'
export const RENT_CREATE_MULTIPLE_CONTRACT_ITEMS_RESPONSE = '[RENT]_CREATE_MULTIPLE_CONTRACT_ITEMS_RESPONSE'
export const RENT_CREATE_MULTIPLE_CONTRACT_ITEMS_FAILURE = '[RENT]_CREATE_MULTIPLE_CONTRACT_ITEMS_FAILURE'

export const RENT_DELETE_CONTRACT_ITEM_REQUEST = '[RENT]_DELETE_CONTRACT_ITEM_REQUEST'
export const RENT_DELETE_CONTRACT_ITEM_RESPONSE = '[RENT]_DELETE_CONTRACT_ITEM_RESPONSE'
export const RENT_DELETE_CONTRACT_ITEM_FAILURE = '[RENT]_DELETE_CONTRACT_ITEM_FAILURE'

export const RENT_CREATE_CONTRACT_REQUEST = '[RENT]_CREATE_CONTRACT_REQUEST'
export const RENT_CREATE_CONTRACT_RESPONSE = '[RENT]_CREATE_CONTRACT_RESPONSE'
export const RENT_CREATE_CONTRACT_FAILURE = '[RENT]_CREATE_CONTRACT_FAILURE'
export const RENT_CREATE_CONTRACT_RESET = '[RENT]_CREATE_CONTRACT_RESET'

export const RENT_EDIT_CONTRACT_REQUEST = '[RENT]_EDIT_CONTRACT_REQUEST'
export const RENT_EDIT_CONTRACT_RESPONSE = '[RENT]_EDIT_CONTRACT_RESPONSE'
export const RENT_EDIT_CONTRACT_FAILURE = '[RENT]_EDIT_CONTRACT_FAILURE'

export const RENT_DELETE_CONTRACT_REQUEST = '[RENT]_DELETE_CONTRACT_REQUEST'
export const RENT_DELETE_CONTRACT_RESPONSE = '[RENT]_DELETE_CONTRACT_RESPONSE'
export const RENT_DELETE_CONTRACT_FAILURE = '[RENT]_DELETE_CONTRACT_FAILURE'

export const RENT_CONTRACTS_LIST_REQUEST = '[RENT]_CONTRACTS_LIST_REQUEST'
export const RENT_CONTRACTS_LIST_RESPONSE = '[RENT]_CONTRACTS_LIST_RESPONSE'
export const RENT_CONTRACTS_LIST_FAILURE = '[RENT]_CONTRACTS_LIST_FAILURE'

export const RENT_SCAN_DOCUMENTS_LIST_BY_CONTRACT_ID_REQUEST = '[RENT]_SCAN_DOCUMENTS_LIST_BY_CONTRACT_ID_REQUEST'
export const RENT_SCAN_DOCUMENTS_LIST_BY_CONTRACT_ID_RESPONSE = '[RENT]_SCAN_DOCUMENTS_LIST_BY_CONTRACT_ID_RESPONSE'
export const RENT_SCAN_DOCUMENTS_LIST_BY_CONTRACT_ID_FAILURE = '[RENT]_SCAN_DOCUMENTS_LIST_BY_CONTRACT_ID_FAILURE'

export const RENT_CREATE_SCAN_DOCUMENT_REQUEST = '[RENT]_CREATE_SCAN_DOCUMENT_REQUEST'
export const RENT_CREATE_SCAN_DOCUMENT_RESPONSE = '[RENT]_CREATE_SCAN_DOCUMENT_RESPONSE'
export const RENT_CREATE_SCAN_DOCUMENT_FAILURE = '[RENT]_CREATE_SCAN_DOCUMENT_FAILURE'

export const RENT_DELETE_SCAN_DOCUMENT_REQUEST = '[RENT]_DELETE_SCAN_DOCUMENT_REQUEST'
export const RENT_DELETE_SCAN_DOCUMENT_RESPONSE = '[RENT]_DELETE_SCAN_DOCUMENT_RESPONSE'
export const RENT_DELETE_SCAN_DOCUMENT_FAILURE = '[RENT]_DELETE_SCAN_DOCUMENT_FAILURE'

export const RENT_TARGET_COMMODITY_FOR_RENTAL_LIST_REQUEST = '[RENT]_TARGET_COMMODITY_FOR_RENTAL_LIST_REQUEST'
export const RENT_TARGET_COMMODITY_FOR_RENTAL_LIST_RESPONSE = '[RENT]_TARGET_COMMODITY_FOR_RENTAL_LIST_RESPONSE'
export const RENT_TARGET_COMMODITY_FOR_RENTAL_LIST_FAILURE = '[RENT]_TARGET_COMMODITY_FOR_RENTAL_LIST_FAILURE'

export const RENT_RESERVATIONS_LIST_REQUEST = '[RENT]_RESERVATIONS_LIST_REQUEST'
export const RENT_RESERVATIONS_LIST_RESPONSE = '[RENT]_RESERVATIONS_LIST_RESPONSE'
export const RENT_RESERVATIONS_LIST_FAILURE = '[RENT]_RESERVATIONS_LIST_FAILURE'

export const RENT_CREATE_RESERVATION_REQUEST = '[RENT]_CREATE_RESERVATION_REQUEST'
export const RENT_CREATE_RESERVATION_RESPONSE = '[RENT]_CREATE_RESERVATION_RESPONSE'
export const RENT_CREATE_RESERVATION_FAILURE = '[RENT]_CREATE_RESERVATION_FAILURE'
export const RENT_CREATE_RESERVATION_RESET = '[RENT]_CREATE_RESERVATION_RESET'

export const RENT_EDIT_RESERVATION_REQUEST = '[RENT]_EDIT_RESERVATION_REQUEST'
export const RENT_EDIT_RESERVATION_RESPONSE = '[RENT]_EDIT_RESERVATION_RESPONSE'
export const RENT_EDIT_RESERVATION_FAILURE = '[RENT]_EDIT_RESERVATION_FAILURE'

export const RENT_DELETE_RESERVATION_REQUEST = '[RENT]_DELETE_RESERVATION_REQUEST'
export const RENT_DELETE_RESERVATION_RESPONSE = '[RENT]_DELETE_RESERVATION_RESPONSE'
export const RENT_DELETE_RESERVATION_FAILURE = '[RENT]_DELETE_RESERVATION_FAILURE'

export const RENT_RESERVATION_ITEMS_LIST_BY_RESERVATION_ID_REQUEST = '[RENT]_RESERVATION_ITEMS_LIST_BY_RESERVATION_ID_REQUEST'
export const RENT_RESERVATION_ITEMS_LIST_BY_RESERVATION_ID_RESPONSE = '[RENT]_RESERVATION_ITEMS_LIST_BY_RESERVATION_ID_RESPONSE'
export const RENT_RESERVATION_ITEMS_LIST_BY_RESERVATION_ID_FAILURE = '[RENT]_RESERVATION_ITEMS_LIST_BY_RESERVATION_ID_FAILURE'

export const RENT_CREATE_RESERVATION_ITEM_REQUEST = '[RENT]_CREATE_RESERVATION_ITEM_REQUEST'
export const RENT_CREATE_RESERVATION_ITEM_RESPONSE = '[RENT]_CREATE_RESERVATION_ITEM_RESPONSE'
export const RENT_CREATE_RESERVATION_ITEM_FAILURE = '[RENT]_CREATE_RESERVATION_ITEM_FAILURE'

export const RENT_DELETE_RESERVATION_ITEM_REQUEST = '[RENT]_DELETE_RESERVATION_ITEM_REQUEST'
export const RENT_DELETE_RESERVATION_ITEM_RESPONSE = '[RENT]_DELETE_RESERVATION_ITEM_RESPONSE'
export const RENT_DELETE_RESERVATION_ITEM_FAILURE = '[RENT]_DELETE_RESERVATION_ITEM_FAILURE'

export const RENT_FINALIZE_CONTRACT_REQUEST = '[RENT]_FINALIZE_CONTRACT_REQUEST'
export const RENT_FINALIZE_CONTRACT_RESPONSE = '[RENT]_FINALIZE_CONTRACT_RESPONSE'
export const RENT_FINALIZE_CONTRACT_FAILURE = '[RENT]_FINALIZE_CONTRACT_FAILURE'

export const RENT_CUSTOMER_REQUEST = '[RENT]_CUSTOMER_REQUEST'
export const RENT_CUSTOMER_RESPONSE = '[RENT]_CUSTOMER_RESPONSE'
export const RENT_CUSTOMER_FAILURE = '[RENT]_CUSTOMER_FAILURE'

export const RENT_GENERATE_PDF_REQUEST = '[RENT]_GENERATE_PDF_REQUEST'
export const RENT_GENERATE_PDF_RESPONSE = '[RENT]_GENERATE_PDF_RESPONSE'
export const RENT_GENERATE_PDF_FAILURE = '[RENT]_GENERATE_PDF_FAILURE'
export const RENT_GENERATE_PDF_RESET = '[RENT]_GENERATE_PDF_RESET'

export const RENT_CREATE_DEPOSIT_REQUEST = '[RENT]_CREATE_DEPOSIT_REQUEST'
export const RENT_CREATE_DEPOSIT_RESPONSE = '[RENT]_CREATE_DEPOSIT_RESPONSE'
export const RENT_CREATE_DEPOSIT_FAILURE = '[RENT]_CREATE_DEPOSIT_FAILURE'
export const RENT_CREATE_DEPOSIT_RESET = '[RENT]_CREATE_DEPOSIT_RESET'

export const RENT_EDIT_DEPOSIT_REQUEST = '[RENT]_EDIT_DEPOSIT_REQUEST'
export const RENT_EDIT_DEPOSIT_RESPONSE = '[RENT]_EDIT_DEPOSIT_RESPONSE'
export const RENT_EDIT_DEPOSIT_FAILURE = '[RENT]_EDIT_DEPOSIT_FAILURE'

export const RENT_TRANSFORM_RESERVATION_TO_CONTRACT_REQUEST = '[RENT]_TRANSFORM_RESERVATION_TO_CONTRACT_REQUEST'
export const RENT_TRANSFORM_RESERVATION_TO_CONTRACT_RESPONSE = '[RENT]_TRANSFORM_RESERVATION_TO_CONTRACT_RESPONSE'
export const RENT_TRANSFORM_RESERVATION_TO_CONTRACT_FAILURE = '[RENT]_TRANSFORM_RESERVATION_TO_CONTRACT_FAILURE'

export const RENT_REMIND_RENT_CONTRACT_REQUEST = '[RENT]_REMIND_RENT_CONTRACT_REQUEST'
export const RENT_REMIND_RENT_CONTRACT_RESPONSE = '[RENT]_REMIND_RENT_CONTRACT_RESPONSE'
export const RENT_REMIND_RENT_CONTRACT_FAILURE = '[RENT]_REMIND_RENT_CONTRACT_FAILURE'

export const RENT_PRINT_DOCUMENT_REQUEST = '[RENT]_PRINT_DOCUMENT_REQUEST'
export const RENT_PRINT_DOCUMENT_RESPONSE = '[RENT]_PRINT_DOCUMENT_RESPONSE'
export const RENT_PRINT_DOCUMENT_FAILURE = '[RENT]_PRINT_DOCUMENT_FAILURE'

export const RENT_DEPOSIT_SUM_REQUEST = '[RENT]_DEPOSIT_SUM_REQUEST'
export const RENT_DEPOSIT_SUM_RESPONSE = '[RENT]_DEPOSIT_SUM_RESPONSE'
export const RENT_DEPOSIT_SUM_FAILURE  = '[RENT]_DEPOSIT_SUM_FAILURE'

export const RENT_FINALIZE_RETURN_REQUEST = '[RENT_]FINALIZE_RETURN_REQUEST'
export const RENT_FINALIZE_RETURN_RESPONSE = '[RENT_]FINALIZE_RETURN_RESPONSE'
export const RENT_FINALIZE_RETURN_FAILURE = '[RENT_]FINALIZE_RETURN_FAILURE'

export const RENT_CONTRACT_DOCUMENTS_REQUEST = '[RENT]_CONTRACT_DOCUMENTS_REQUEST'
export const RENT_CONTRACT_DOCUMENTS_RESPONSE = '[RENT]_CONTRACT_DOCUMENTS_RESPONSE'
export const RENT_CONTRACT_DOCUMENTS_FAILURE = '[RENT]_CONTRACT_DOCUMENTS_FAILURE'

export const RENT_PREPARE_CONTRACT_FROM_CONTRACT_REQUEST = '[RENT]_PREPARE_CONTRACT_FROM_CONTRACT_REQUEST'
export const RENT_PREPARE_CONTRACT_FROM_CONTRACT_RESPONSE = '[RENT]_PREPARE_CONTRACT_FROM_CONTRACT_RESPONSE'
export const RENT_PREPARE_CONTRACT_FROM_CONTRACT_FAILURE = '[RENT]_PREPARE_CONTRACT_FROM_CONTRACT_FAILURE'

export const RENT_PREPARE_RESERVATION_FROM_CONTRACT_REQUEST = '[RENT]_PREPARE_RESERVATION_FROM_CONTRACT_REQUEST'
export const RENT_PREPARE_RESERVATION_FROM_CONTRACT_RESPONSE = '[RENT]_PREPARE_RESERVATION_FROM_CONTRACT_RESPONSE'
export const RENT_PREPARE_RESERVATION_FROM_CONTRACT_FAILURE = '[RENT]_PREPARE_RESERVATION_FROM_CONTRACT_FAILURE'

export const RENT_RESET_RES_MESSAGE = '[RENT]_RESET_RES_MESSAGE'
