import * as actionTypes from './store-cards.actionTypes'

// Fetching store-cards list page for modal in transfers
export function storeCardsListPageRequest(
  draw,
  page,
  limit,
  search,
) {
  return {
    type: actionTypes.STORE_CARDS_LIST_REQUEST,
    payload: { draw, page, limit, search }
  };
}

export function storeCardsListPageResponse(response) {
  return {
    type: actionTypes.STORE_CARDS_LIST_RESPONSE,
    payload: response
  };
}

export function storeCardsListPageFailure(error) {
  return {
    type: actionTypes.STORE_CARDS_LIST_FAILURE,
    payload: error
  };
}

// Fetching store-cards list page for ections in store cards
export function storeCardsSectionListPageRequest(
  draw,
  page,
  limit,
  search,
  nullQuantity,
  shop,
  sort,
  order,
) {
  return {
    type: actionTypes.STORE_CARDS_SECTION_LIST_REQUEST,
    payload: { draw, page, limit, search, nullQuantity, shop, sort, order, }
  };
}

export function storeCardsSectionListPageResponse(response) {
  return {
    type: actionTypes.STORE_CARDS_SECTION_LIST_RESPONSE,
    payload: response
  };
}

export function storeCardsSectionListPageFailure(error) {
  return {
    type: actionTypes.STORE_CARDS_SECTION_LIST_FAILURE,
    payload: error
  };
}

// Fetch multiple eans for array of store cards ids
export function storeCardsEansRequest(storeCardsIdsArr, tableSoureId) {
  return {
    type: actionTypes.STORE_CARDS_EANS_REQUEST,
    payload: { storeCardsIdsArr, tableSoureId }
  };
}

export function storeCardsEansResponse(response, tableSoureId) {
  return {
    type: actionTypes.STORE_CARDS_EANS_RESPONSE,
    payload: { response, tableSoureId }
  };
}

export function storeCardsEansFailure(error) {
  return {
    type: actionTypes.STORE_CARDS_EANS_FAILURE,
    payload: error
  };
}

// Bulk change of export null quantity
export function storeCardsBulkChangeExportNullQuantityRequest(storeCardsIds, nullifyQuantity) {
  return {
    type: actionTypes.STORE_CARDS_BULK_CHANGE_EXPORT_NULL_QUANTITY_REQUEST,
    payload: { storeCardsIds, nullifyQuantity }
  };
}

export function storeCardsBulkChangeExportNullQuantityResponse(response, resMessage) {
  return {
    type: actionTypes.STORE_CARDS_BULK_CHANGE_EXPORT_NULL_QUANTITY_RESPONSE,
    payload: { response, resMessage }
  };
}

export function storeCardsBulkChangeExportNullQuantityFailure(error, resMessage) {
  return {
    type: actionTypes.STORE_CARDS_BULK_CHANGE_EXPORT_NULL_QUANTITY_FAILURE,
    payload: { error, resMessage }
  };
}

// Reset response message
export function storeCardsResetResponseMessage() {
  return {
    type: actionTypes.STORE_CARDS_RESPONSE_MESSAGE_RESET,
  };
}

// Fetch bottle types for store cards
export function storeCardsBottleTypesBySCRequest(bottleTypesIdsByStoreCardsIds, tableSoureId) {
  return {
    type: actionTypes.STORE_CARDS_BOTTLE_TYPES_BY_SC_REQUEST,
    payload: { bottleTypesIdsByStoreCardsIds, tableSoureId }
  };
}

export function storeCardsBottleTypesBySCResponse(response, tableSoureId) {
  return {
    type: actionTypes.STORE_CARDS_BOTTLE_TYPES_BY_SC_RESPONSE,
    payload: { response, tableSoureId }
  };
}

export function storeCardsBottleTypesBySCFailure(error) {
  return {
    type: actionTypes.STORE_CARDS_BOTTLE_TYPES_BY_SC_FAILURE,
    payload: error
  };
}

// Fetching store-cards list based on products in orders
export function storeCardsProductInOrdersListRequest(
  draw,
  page,
  limit,
  stavObjednavkyIds,
  search,
) {
  return {
    type: actionTypes.STORE_CARDS_PRODUCT_IN_ORDERS_LIST_REQUEST,
    payload: { draw, page, limit, stavObjednavkyIds, search }
  };
}

export function storeCardsProductInOrdersListResponse(response) {
  return {
    type: actionTypes.STORE_CARDS_PRODUCT_IN_ORDERS_LIST_RESPONSE,
    payload: response
  };
}

export function storeCardsProductInOrdersListFailure(error) {
  return {
    type: actionTypes.STORE_CARDS_PRODUCT_IN_ORDERS_LIST_FAILURE,
    payload: error
  };
}

// Editable consumption tax list
export function storeCardsEditableConsumptionTaxListPageRequest(
  draw,
  page,
  limit,
  search,
) {
  return {
    type: actionTypes.STORE_CARDS_EDITABLE_CONSUMPTION_TAX_LIST_REQUEST,
    payload: { draw, page, limit, search }
  };
}

export function storeCardsEditableConsumptionTaxListPageResponse(
  response
) {
  return {
    type: actionTypes.STORE_CARDS_EDITABLE_CONSUMPTION_TAX_LIST_RESPONSE,
    payload: response
  };
}

export function storeCardsEditableConsumptionTaxListPageFailure(
  error
) {
  return {
    type: actionTypes.STORE_CARDS_EDITABLE_CONSUMPTION_TAX_LIST_FAILURE,
    payload: error
  };
}

// Bottle types list
export function storeCardsBottleTypesListRequest() {
  return {
    type: actionTypes.STORE_CARDS_BOTTLE_TYPE_LIST_REQUEST,
  };
}

export function storeCardsBottleTypesListResponse(response) {
  return {
    type: actionTypes.STORE_CARDS_BOTTLE_TYPE_LIST_RESPONSE,
    payload: response,
  };
}

export function storeCardsBottleTypesListFailure(error) {
  return {
    type: actionTypes.STORE_CARDS_BOTTLE_TYPE_LIST_FAILURE,
    payload: error,
  };
}

// Edit store card
export function storeCardsEditStoreCardRequest(updatedStoreCard) {
  return {
    type: actionTypes.STORE_CARDS_EDIT_STORE_CARD_REQUEST,
    payload: updatedStoreCard,
  };
}

export function storeCardsEditStoreCarResponse(response, resMessage) {
  return {
    type: actionTypes.STORE_CARDS_EDIT_STORE_CARD_RESPONSE,
    payload: { response, resMessage },
  };
}

export function storeCardsEditStoreCarFailure(error, resMessage) {
  return {
    type: actionTypes.STORE_CARDS_EDIT_STORE_CARD_RESPONSE,
    payload: { error, resMessage },
  };
}

// Delete ean
export function storeCardsDeleteEanRequest(storeCardId, ean, userUid, ident) {
  return {
    type: actionTypes.STORE_CARDS_DELETE_EAN_REQUEST,
    payload: { storeCardId, ean, userUid, ident },
  };
}

export function storeCardsDeleteEanResponse(response, resMessage) {
  return {
    type: actionTypes.STORE_CARDS_DELETE_EAN_RESPONSE,
    payload: { response, resMessage },
  };
}

export function storeCardsDeleteEanFailure(error, resMessage) {
  return {
    type: actionTypes.STORE_CARDS_DELETE_EAN_FAILURE,
    payload: { error, resMessage },
  };
}

// Edit multiple store cards
export function storeCardsEditMultipleStoreCardsRequest(updatedStoreCards) {
  return {
    type: actionTypes.STORE_CARDS_EDIT_MULTIPLE_STORE_CARDS_REQUEST,
    payload: updatedStoreCards,
  };
}

export function storeCardsEditMultipleStoreCardsResponse(response, resMessage) {
  return {
    type: actionTypes.STORE_CARDS_EDIT_MULTIPLE_STORE_CARDS_RESPONSE,
    payload: { response, resMessage },
  };
}

export function storeCardsEditMultipleStoreCardsFailure(error, resMessage) {
  return {
    type: actionTypes.STORE_CARDS_EDIT_MULTIPLE_STORE_CARDS_FAILURE,
    payload: { error, resMessage },
  };
}

// Store cards for Facebook feeds
export function storeCardsForFBFeedRequest(shop) {
  return {
    type: actionTypes.STORE_CARDS_FB_FEEDS_LIST_REQUEST,
    payload: { shop },
  };
}

export function storeCardsForFBFeedResponse(storeCards) {
  return {
    type: actionTypes.STORE_CARDS_FB_FEEDS_LIST_RESPONSE,
    payload: storeCards,
  };
}

export function storeCardsForFBFeedFailure(error) {
  return {
    type: actionTypes.STORE_CARDS_FB_FEEDS_LIST_FAILURE,
    payload: error,
  };
}
