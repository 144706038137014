import * as actionTypes from './targets.actionTypes'
import * as actions from './targets.actions'
import _ from 'lodash'
import { from, of, forkJoin, iif, defer } from 'rxjs'
import { mergeMap, takeUntil, catchError, map } from 'rxjs/operators'
import { AirshopApi } from '../../CraftApi.axios'
import { ofType } from 'redux-observable'
import {
  err, errMessages, handleForkJoinResponses, passOnlySuccessForkJoinResponses
} from 'redux-store/utils/epics.utils'
import { endpoints } from './utils/target.endpoints'
import { environmentIsDev } from 'AppConfig'

export const fetchCommoditiesListEpic = (action$, state$) =>
  action$.pipe(
    ofType(actionTypes.TARGETS_COMMODITIES_LIST_REQUEST),
    mergeMap(action => {
      const {
        draw,
        page,
        limit,
        categoryId,
        search,
        source,
        disabled,
      } = action.payload;
      const params = { draw, page, limit, search, source, disabled, order: 'name' };
      if (+categoryId) (params.categoryId = categoryId);
      const tableData = {};
      return from(AirshopApi.get(`fc/commodity${+categoryId ? '/categoryId' : ''}`, { params }))
      .pipe(
        map(response => {
          if (response.data.status === 'OK') {
            tableData.items = response.data.items;
            tableData.total = response.data.total;
            return response.data.items;
          } else {
            err(errMessages.NOT_OK, response);
          }
        }),
        mergeMap(items => iif(
          () => items.length,
          defer(() => forkJoin(items.map(item=> AirshopApi.get(
            `storeCards/plu/${item.plu}`
            // If unusual plu, 404, handle by ignoring it and passing only the successful ones
            ).catch(error => of(error)) ))),
          defer(() => of([]))
        )),
        mergeMap(items => iif(
          () => items.length,
          defer(() => of(passOnlySuccessForkJoinResponses(items))),
          defer(() => of([]))
        )),
        map(storeCardsResponses => {
          const storeCardsByPlu = { ...state$.value.targets.relatedStoreCardsByPlu };
          storeCardsResponses.length && storeCardsResponses.forEach(
            storeCardRes => (storeCardsByPlu[storeCardRes.data.data.plu] = storeCardRes.data.data)
          );
          return actions.targetsCommoditiesListResponse({
            tableData,
            storeCards: storeCardsByPlu
        })}),
        catchError(error => of(actions.targetsCommoditiesListFailure(error))),
        takeUntil(action$.ofType(
          actionTypes.TARGETS_COMMODITIES_LIST_RESPONSE ||
          actionTypes.TARGETS_COMMODITIES_LIST_FAILURE
        ))
      )
    })
  );

export const fetchCommodityCategoriesEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.TARGETS_COMMODITY_CATEGORIES_REQUEST),
    mergeMap(action => from(AirshopApi.get('fc/category'))
      .pipe(
          map(response => response.data.status === 'OK' ? response.data.items : err(errMessages.NOT_OK, response)
            ),
          map(items => actions.targetsCommodityCategoriesResponse(
            items.filter(item => item.enabled)
          )),
          catchError(error => of(actions.targetsCommodityCategoriesFailure(error))),
          takeUntil(action$.ofType(
            actionTypes.TARGETS_COMMODITY_CATEGORIES_RESPONSE ||
            actionTypes.TARGETS_COMMODITY_CATEGORIES_FAILURE
          ))
      )
    )
  );

export const createMultipleCommodityTargetsEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.TARGETS_CREATE_MULTIPLE_COMMODITY_TARGETS_REQUEST),
    mergeMap(action => {
      const { targetId, commoditiesIdsArr, targetCategoryId } = action.payload;
      return forkJoin(
        commoditiesIdsArr.map(commodityId => AirshopApi.get(
          endpoints.commodityToTarget,
          { params: { commodityId, targetId, targetCategoryId }}
        ))
      )
      .pipe(
          map(responses => handleForkJoinResponses(responses)),
          map(response => actions.targetsCreateMultipleCommodityTargetsResponse(
            response,
            actionTypes.TARGETS_CREATE_MULTIPLE_COMMODITY_TARGETS_RESPONSE
          )),
          catchError(error => of(actions.targetsCreateMultipleCommodityTargetsFailure(
            error,
            actionTypes.TARGETS_CREATE_MULTIPLE_COMMODITY_TARGETS_FAILURE
          ))),
          takeUntil(action$.ofType(
            actionTypes.TARGETS_CREATE_MULTIPLE_COMMODITY_TARGETS_RESPONSE ||
            actionTypes.TARGETS_CREATE_MULTIPLE_COMMODITY_TARGETS_FAILURE
          ))
      )
    })
  );

export const fetchCommodityTargetsListEpic = (action$, state$) =>
  action$.pipe(
    ofType(actionTypes.TARGETS_COMMODITY_TARGETS_LIST_REQUEST),
    mergeMap(action => {
      const {
        targetId,
        draw,
        page,
        limit,
        search,
        order,
        categoryId,
        disabled,
        onOrder,
        fetchStoreCards,
        fetchTargetCommodityTargets,
      } = action.payload;
      let path = 'fc/targetCommodity/findAllByTarget';
      let params = { draw, page, limit, search, disabled, onOrder, order, targetId };
      if (order && (order === 'commodity_keg_size_id')) {
        // Sort by keg size id and then by name within the keg size id
        params.order = 'commodity_keg_size_id,name'
      }
      if (categoryId) {
        params.categoryId = categoryId;
      }
      const tableData = {};
      let fetchedRelatedStoreCards;
      return from(AirshopApi.get(path, { params }))
      .pipe(
          map(response => {
            if (response.data.status === 'OK') {
              tableData.items = response.data.items;
              tableData.total = response.data.total;
              tableData.draw = draw;
              return response.data.items;
            } else {
              err(errMessages.NOT_OK, response);
            }
          }),
          mergeMap(items => iif(
            () => items.length && fetchStoreCards,
            defer(() => {
              const reqsArr = items.filter(item => !!item.plu).map(item=> AirshopApi.get(`storeCards/plu/${item.plu}`))
              return forkJoin(reqsArr)
            }),
            defer(() => of([]))
          )),
          mergeMap(responses => iif(
            () => responses.length,
            defer(() => of(passOnlySuccessForkJoinResponses(responses))),
            defer(() => of([]))
          )),
          mergeMap(storeCards => {
            fetchedRelatedStoreCards = storeCards.length ?
            passOnlySuccessForkJoinResponses(storeCards) : storeCards;
            return iif(
              () => tableData.items.length && fetchTargetCommodityTargets,
              defer(() => getTargetCommodityIdsForkJoin(
                tableData.items.map(targetCommodity => targetCommodity.id)
              )),
              defer(() => of([]))
            )
          }),
          map(targetCommodityTargets => {
            const successfulTCTs = targetCommodityTargets.length
              ? passOnlySuccessForkJoinResponses(targetCommodityTargets)
              : targetCommodityTargets;

            const storeCardsByPlu = { ...state$.value.targets.relatedStoreCardsByPlu };
            fetchedRelatedStoreCards.length && fetchedRelatedStoreCards.forEach(
              storeCardRes => (storeCardsByPlu[storeCardRes.data.data.plu] = storeCardRes.data.data)
            );
            const targetCommodityTargetsById = { ...state$.value.targets.relatedTargetCommodityTargetsByTCId };
            successfulTCTs.length && successfulTCTs.forEach(
              targetCommodityTargetRes => (
                targetCommodityTargetsById[
                  targetCommodityTargetRes.data.items[0].targetCommodityId
                ] = targetCommodityTargetRes.data.items[0]
              )
            );
            return actions.targetsCommodityTargetsListResponse({
              tableData,
              storeCards: storeCardsByPlu,
              targetCommodityTargetsById,
          })}),
          catchError(error => of(actions.targetsCommodityTargetsListFailure(error))),
          takeUntil(action$.ofType(
            actionTypes.TARGETS_COMMODITY_TARGETS_LIST_RESPONSE ||
            actionTypes.TARGETS_COMMODITY_TARGETS_LIST_FAILURE
          ))
      )
    })
  );

export const fetchTargetCategoriesEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.TARGETS_TARGETS_CATEGORIES_REQUEST),
    mergeMap(action => {
      const { targetId, search } = action.payload;
      return from(AirshopApi.get('fc/targetCategory/targetId', { params: {
      targetId, search, limit: 500
    }}))
      .pipe(
          map(response => response.data.status === 'OK' ? response.data.items : err(errMessages.NOT_OK, response)
            ),
          map(items => actions.targetsTargetCategoriesResponse(items)),
          catchError(error => of(actions.targetsTargetCategoriesFailure(error))),
          takeUntil(action$.ofType(
            actionTypes.TARGETS_TARGETS_CATEGORIES_RESPONSE ||
            actionTypes.TARGETS_TARGETS_CATEGORIES_FAILURE
          ))
      )
    })
  );

export const fetchTargetsEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.TARGETS_TARGETS_REQUEST),
    mergeMap(action => from(AirshopApi.get('fc/target'))
      .pipe(
          map(response => response.data.status === 'OK' ? response.data.items : err(errMessages.NOT_OK, response)
            ),
          map(items => {
            const targetsById = {};
            items.forEach(target => (targetsById[target.id] = target));
            return actions.targetsTargetsResponse(targetsById);
          }),
          catchError(error => of(actions.targetsTargetsFailure(error))),
          takeUntil(action$.ofType(
            actionTypes.TARGETS_TARGETS_RESPONSE ||
            actionTypes.TARGETS_TARGETS_FAILURE
          ))
      )
    )
  );

export const deleteMultipleCommodityTargetsEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.TARGETS_DELETE_MULTIPLE_COMMODITY_TARGETS_REQUEST),
    mergeMap(action => {
      const { targetId, targetCommoditiesIdsArr } = action.payload;
      return forkJoin(
        targetCommoditiesIdsArr.map(targetCommodityId => AirshopApi.delete(
          'fc/targetCommodityTarget/byCommodityTarget',
          { params: { targetId, targetCommodityId }}
        ))
      )
      .pipe(
          map(responses => {
            let hasErrored = false;
            responses.forEach(res => {
              if (res.data.status !== 'OK') { hasErrored = true; }
            });

            if (hasErrored) {
              return err(errMessages.NOT_OK, responses)
            } else {
              return responses;
            }
          }),
          map(response => actions.targetsDeleteMultipleCommodityTargetsResponse(
            response,
            actionTypes.TARGETS_DELETE_MULTIPLE_COMMODITY_TARGETS_RESPONSE
          )),
          catchError(error => of(actions.targetsDeleteMultipleCommodityTargetsFailure(
            error,
            actionTypes.TARGETS_DELETE_MULTIPLE_COMMODITY_TARGETS_FAILURE
          ))),
          takeUntil(action$.ofType(
            actionTypes.TARGETS_DELETE_MULTIPLE_COMMODITY_TARGETS_RESPONSE ||
            actionTypes.TARGETS_DELETE_MULTIPLE_COMMODITY_TARGETS_FAILURE
          ))
      )
    })
  );

export const createKegProductEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.TARGETS_CREATE_KEG_PRODUCT_REQUEST),
    mergeMap(action => {
      const { targetId, commodityId, commodityKegSizeIdsArr } = action.payload;
      return forkJoin(commodityKegSizeIdsArr.map(commodityKegSizeId => AirshopApi.get(
        'fc/targetCommodity/transformCommodityToTargetKeg',
        { params: {
          commodityId,
          targetId,
          commodityKegSizeId,
          // By default, SUDY category
          targetCategoryId: 2
        }
      })))
      .pipe(
          map(responses => handleForkJoinResponses(responses)),
          map(response => {
            // As of now, will be only one as we went from multiple to single
            const createdKeg = response[0]?.data?.data;
            return actions.targetsCreateKegProductResponse(
            createdKeg,
            actionTypes.TARGETS_CREATE_KEG_PRODUCT_RESPONSE
          )}),
          catchError(error => of(actions.targetsCreateKegProductFailure(
            error,
            actionTypes.TARGETS_CREATE_KEG_PRODUCT_FAILURE
          ))),
          takeUntil(action$.ofType(
            actionTypes.TARGETS_CREATE_KEG_PRODUCT_RESPONSE ||
            actionTypes.TARGETS_CREATE_KEG_PRODUCT_FAILURE
          ))
      )
    })
  );

export const fetchKegSizesByCommodityEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.TARGETS_KEG_SIZES_BY_COMMODITY_REQUEST),
    mergeMap(action => {
      const { commodityPlu } = action.payload;
      return from(AirshopApi.get('fc/commodityKegSize/plu', { params: { plu: commodityPlu }}))
      .pipe(
          map(response => response.data.status === 'OK' ? response.data.items : err(errMessages.NOT_OK, response)
            ),
          map(response => actions.targetsKegSizesByCommodityResponse({
            [commodityPlu]: response
          })),
          catchError(error => of(actions.targetsKegSizesByCommodityFailure(error))),
          takeUntil(action$.ofType(
            actionTypes.TARGETS_KEG_SIZES_BY_COMMODITY_RESPONSE ||
            actionTypes.TARGETS_KEG_SIZES_BY_COMMODITY_FAILURE
          ))
      )
    })
  );

export const updateCommodityTargetEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.TARGETS_EDIT_COMMODITY_TARGET_REQUEST),
    mergeMap(action => {
      const { targetCommodityId, updatedCommodityTarget } = action.payload;
      return from(AirshopApi.put('fc/targetCommodity',
        updatedCommodityTarget,
        { params: { targetCommodityId }}
      ))
      .pipe(
          map(response => response.data.status === 'OK' ? response.data : err(errMessages.NOT_OK, response)
            ),
          map(response => actions.targetsEditCommodityTargetResponse(
            response,
            actionTypes.TARGETS_EDIT_COMMODITY_TARGET_RESPONSE
          )),
          catchError(error => of(actions.targetsEditCommodityTargetFailure(
            error,
            actionTypes.TARGETS_EDIT_COMMODITY_TARGET_FAILURE
          ))),
          takeUntil(action$.ofType(
            actionTypes.TARGETS_EDIT_COMMODITY_TARGET_RESPONSE ||
            actionTypes.TARGETS_EDIT_COMMODITY_TARGET_FAILURE
          ))
      )
    })
  );

export const fetchKegSizesEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.TARGETS_KEG_SIZES_REQUEST),
    mergeMap(action => from(AirshopApi.get('fc/commodityKegSize'))
      .pipe(
          map(response => response.data.status === 'OK' ? response.data.items : err(errMessages.NOT_OK, response)
            ),
          map(response => actions.targetsKegSizesResponse({
            kegSizes: response,
            kegSizesById: _.keyBy(response, 'id')
          })),
          catchError(error => of(actions.targetsKegSizesFailure(error))),
          takeUntil(action$.ofType(
            actionTypes.TARGETS_KEG_SIZES_RESPONSE ||
            actionTypes.TARGETS_KEG_SIZES_FAILURE
          ))
      )
    )
  );

const prepateTargetCommodityListBothStockOptionsReqs = (
  targetId, fetchInStock, fetchOutOfStock, inStockParams, outOfStockParams, reqs
) => {
  let inStockPath
  if (targetId && !inStockParams.categoryId) {
    inStockPath = 'fc/targetCommodity/findAllByTarget';
  }
  // targetCategory is already mapped to a specific targetId
  if (targetId && inStockParams.categoryId) {
    inStockPath = 'fc/targetCommodity/categoryId';
  }
  if (fetchInStock) {
    reqs.push(
      AirshopApi.get(inStockPath, { params: { ...inStockParams, targetId }}),
    )
  }
  if (fetchOutOfStock) {
    reqs.push(
      AirshopApi.get('fc/targetCommodity/findAllByTarget', { params: { ...outOfStockParams, targetId, order: 'name', sort: 'ASC' }}),
    )
  }
}

const gatherCommodityIds = (responses, targetCommodityIds) => {
  responses.forEach(res => {
    if (res.data.items.length) {
      res.data.items.forEach(item => {
        targetCommodityIds.push(item.id);
      })
    }
  });
}

const handleTargetCommoditiesResponses = (
  inStockHasItems, outOfStockHasItems, targetCommodityTargetsResponses,
  targetCommodityTargetsByTargetCommodityId, inStockList, outOfStockList
) => {
  if (inStockHasItems || outOfStockHasItems) {
    targetCommodityTargetsResponses.forEach(res => {
      const item = res.data.items[0];
      targetCommodityTargetsByTargetCommodityId[item.targetCommodityId] = item;
    })
  }

  if (inStockHasItems) {
    inStockList.items.forEach(item => {
      item['targetCommodityTarget'] = {...targetCommodityTargetsByTargetCommodityId[item.id]};
    })
  }

  if (outOfStockHasItems) {
    outOfStockList.items.forEach(item => {
      item['targetCommodityTarget'] = {...targetCommodityTargetsByTargetCommodityId[item.id]};
    })
  }
}

const getTargetCommodityIdsForkJoin = (targetCommodityIds) => {
  if (targetCommodityIds.length) {
    return forkJoin(targetCommodityIds.map(id => (
      AirshopApi.get('fc/targetCommodityTarget/targetCommodityId', { params: { targetCommodityId: id }})
    )))
  } else {
    const bypassResponsesCheckerResponse = { data: { status: 'OK'}};
    return of([bypassResponsesCheckerResponse])
  }
}

export const fetchKegsListBothStockOptionsEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.TARGETS_KEGS_LIST_BOTH_STOCK_OPTIONS_REQUEST),
    mergeMap(action => {
      const { inStockParams, outOfStockParams, fetchOptions } = action.payload;
      const { fetchInStock, fetchOutOfStock } = fetchOptions;
      const indexes = {
        inStock: fetchInStock ? 0 : -1,
        outOfStock: fetchOutOfStock ? (fetchInStock ? 1 : 0) : -1,
      }
      const reqs = [];
      let inStockList;
      let outOfStockList;
      prepateTargetCommodityListBothStockOptionsReqs(
        4, fetchInStock, fetchOutOfStock,
        inStockParams, outOfStockParams, reqs
      )
      return forkJoin(reqs)
      .pipe(
        map(responses => handleForkJoinResponses(responses)),
        mergeMap(responses => {
          const targetCommodityIds = [];
          gatherCommodityIds(responses, targetCommodityIds);

          inStockList = indexes.inStock >= 0 ? {
            items: responses[indexes.inStock].data.items,
            total: responses[indexes.inStock].data.total} : null;

          outOfStockList = indexes.outOfStock >= 0 ? {
            items: responses[indexes.outOfStock].data.items,
            total: responses[indexes.outOfStock].data.total} : null;

          return getTargetCommodityIdsForkJoin(targetCommodityIds)
        }),
        map(responses => handleForkJoinResponses(responses)),
        map((targetCommodityTargetsResponses) => {
          const targetCommodityTargetsByTargetCommodityId = {};
          const inStockHasItems = fetchInStock && inStockList && inStockList.items.length;
          const outOfStockHasItems = fetchOutOfStock && outOfStockList && outOfStockList.items.length;
          handleTargetCommoditiesResponses(
            inStockHasItems, outOfStockHasItems, targetCommodityTargetsResponses,
            targetCommodityTargetsByTargetCommodityId, inStockList, outOfStockList
          )
          return actions.targetsKegsListBothStockOptionsResponse(inStockList, outOfStockList);
        }),
        catchError(error => of(actions.targetsKegsListBothStockOptionsFailure(error))),
        takeUntil(action$.ofType(
          actionTypes.TARGETS_KEGS_LIST_BOTH_STOCK_OPTIONS_RESPONSE ||
          actionTypes.TARGETS_KEGS_LIST_BOTH_STOCK_OPTIONS_FAILURE
        ))
      )
    })
  );


export const fetchTargetCommodityListBothStockOptionsEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.TARGETS_TARGET_COMMODITY_LIST_BOTH_STOCK_OPTIONS_REQUEST),
    mergeMap(action => {
      const {
        targetId, inStockParams, outOfStockParams, fetchOptions
      } = action.payload;
      const { fetchInStock, fetchOutOfStock } = fetchOptions;
      const indexes = {
        inStock: fetchInStock ? 0 : -1,
        outOfStock: fetchOutOfStock ? (fetchInStock ? 1 : 0) : -1,
      }
      const reqs = [];
      let inStockList;
      let outOfStockList;
      prepateTargetCommodityListBothStockOptionsReqs(
        targetId, fetchInStock, fetchOutOfStock,
        inStockParams, outOfStockParams, reqs
      )
      return forkJoin(reqs)
      .pipe(
        map(responses => handleForkJoinResponses(responses)),
        mergeMap(responses => {
          const targetCommodityIds = [];
          gatherCommodityIds(responses, targetCommodityIds);

          inStockList = indexes.inStock >= 0 ? {
            items: responses[indexes.inStock].data.items,
            total: responses[indexes.inStock].data.total} : null;

          outOfStockList = indexes.outOfStock >= 0 ? {
            items: responses[indexes.outOfStock].data.items,
            total: responses[indexes.outOfStock].data.total} : null;

          return getTargetCommodityIdsForkJoin(targetCommodityIds)
        }),
        map(responses => handleForkJoinResponses(responses)),
        map((targetCommodityTargetsResponses) => {
          const targetCommodityTargetsByTargetCommodityId = {};
          const inStockHasItems = fetchInStock && inStockList && inStockList.items.length;
          const outOfStockHasItems = fetchOutOfStock && outOfStockList && outOfStockList.items.length;
          handleTargetCommoditiesResponses(
            inStockHasItems, outOfStockHasItems, targetCommodityTargetsResponses,
            targetCommodityTargetsByTargetCommodityId, inStockList, outOfStockList
          )
          return actions.targetstargetCommodityListBothStockOptionsResponse(
            inStockList, outOfStockList
          );
        }),
        catchError(error => of(
          actions.targetstargetCommodityListBothStockOptionsFailure(error)
        )),
        takeUntil(action$.ofType(
          actionTypes.TARGETS_TARGET_COMMODITY_LIST_BOTH_STOCK_OPTIONS_RESPONSE ||
          actionTypes.TARGETS_TARGET_COMMODITY_LIST_BOTH_STOCK_OPTIONS_FAILURE
        ))
      )
    })
  );

export const updateMultipleTargetCommodityTargetsEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.TARGETS_EDIT_MULTIPLE_TARGET_COMMODITY_TARGETS_REQUEST),
    mergeMap(action => {
      const { updatedTarComTargetsArr, updatedTarComsArr, updateTarComsAsWell } = action.payload;
      const updateTarComs = updateTarComsAsWell && (updatedTarComTargetsArr.length === updatedTarComsArr.length);
      const reqs = [];
      updatedTarComTargetsArr.forEach(tarComTarget => reqs.push(AirshopApi.put(
        endpoints.targetCommodityTarget,
        tarComTarget,
        { params: { targetCommodityTargetId: tarComTarget.id }}
      )))

      updateTarComs && updatedTarComsArr.forEach(tarCom => reqs.push(
        AirshopApi.put('fc/targetCommodity',
          tarCom,
          { params: { targetCommodityId: tarCom.id }}
        )
      ))
      return forkJoin(...reqs)
      .pipe(
          map(responses => handleForkJoinResponses(responses)),
          map(response => actions.targetsEditMultipleTargetCommodityTargetsResponse(
            response,
            actionTypes.TARGETS_EDIT_MULTIPLE_TARGET_COMMODITY_TARGETS_RESPONSE
          )),
          catchError(error => of(actions.targetsEditMultipleTargetCommodityTargetsFailure(
            error,
            actionTypes.TARGETS_EDIT_MULTIPLE_TARGET_COMMODITY_TARGETS_FAILURE
          ))),
          takeUntil(action$.ofType(
            actionTypes.TARGETS_EDIT_MULTIPLE_TARGET_COMMODITY_TARGETS_RESPONSE ||
            actionTypes.TARGETS_EDIT_MULTIPLE_TARGET_COMMODITY_TARGETS_FAILURE
          ))
      )
    })
  );

export const editTargetCategoryEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.TARGETS_EDIT_TARGET_CATEGORY_REQUEST),
    mergeMap(action => {
      const { targetCategoryId, updatedTargetCategory } = action.payload;
      return from(AirshopApi.put(
        'fc/targetCategory',
        updatedTargetCategory,
        { params: { targetCategoryId }},
      ))
      .pipe(
          map(response => response.data.status === 'OK' ? response.data.data : err(errMessages.NOT_OK, response)
            ),
          map(response => actions.targetsEditTargetCategoryResponse(
            response,
            actionTypes.TARGETS_EDIT_TARGET_CATEGORY_RESPONSE
          )),
          catchError(error => of(actions.targetsEditTargetCategoryFailure(
            error,
            actionTypes.TARGETS_EDIT_TARGET_CATEGORY_FAILURE
          ))),
          takeUntil(action$.ofType(
            actionTypes.TARGETS_EDIT_TARGET_CATEGORY_RESPONSE ||
            actionTypes.TARGETS_EDIT_TARGET_CATEGORY_FAILURE
          ))
      )
    })
  );

export const fetchManufacturersListEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.TARGETS_MANUFACTURERS_LIST_REQUEST),
    mergeMap(action => {
      const { search, limit, page, order, sort } = action.payload;
      return from(AirshopApi.get('fc/manufacturer', {
      params : {
        search, limit, page, order, sort
      }
    }))
      .pipe(
          map(response => response.data.status === 'OK' ? response.data.items : err(errMessages.NOT_OK, response)
            ),
          map(response => {
            const manufacturersById = {};
            response.length && response.forEach(item => {
              manufacturersById[item.id] = item;
            })
            return actions.targetsManufacturersListResponse({
            manufacturers: response, manufacturersById,
          })}),
          catchError(error => of(actions.targetsManufacturersListFailure(error))),
          takeUntil(action$.ofType(
            actionTypes.TARGETS_MANUFACTURERS_LIST_RESPONSE ||
            actionTypes.TARGETS_MANUFACTURERS_LIST_FAILURE
          ))
      )
    })
  );

export const fetchTargetCommoditySettingsEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.TARGETS_TARGET_COMMODITY_TARGET_SETTINGS_REQUEST),
    mergeMap(action => {
      const { targetCommodityId } = action.payload;
      return from(AirshopApi.get('fc/targetCommodityTarget/targetCommodityId', {
        params : { targetCommodityId }
    }))
      .pipe(
          map(response => (response.data.status === 'OK') && response.data.items[0] ? response.data.items[0] : err(errMessages.NOT_OK, response)
            ),
          map(response => actions.targetsTargetCommoditySettingsResponse(
            response
          )),
          catchError(error => of(actions.targetsTargetCommoditySettingsFailure(error))),
          takeUntil(action$.ofType(
            actionTypes.TARGETS_TARGET_COMMODITY_TARGET_SETTINGS_RESPONSE ||
            actionTypes.TARGETS_TARGET_COMMODITY_TARGET_SETTINGS_FAILURE
          ))
      )
    })
  );

export const updateTargetCommoditySettingsEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.TARGETS_EDIT_TARGET_COMMODITY_TARGET_SETTINGS_REQUEST),
    mergeMap(action => {
      const { targetCommodityTargetId, updatedTargetCommodityTarget } = action.payload;
      return from(AirshopApi.put(
        'fc/targetCommodityTarget',
        updatedTargetCommodityTarget,
      { params : { targetCommodityTargetId } }
    ))
      .pipe(
          map(response => response.data.status === 'OK' ? response.data.data : err(errMessages.NOT_OK, response)
            ),
          map(response => actions.targetsEditTargetCommoditySettingsResponse(
            response
          )),
          catchError(error => of(actions.targetsEditTargetCommoditySettingsFailure(error))),
          takeUntil(action$.ofType(
            actionTypes.TARGETS_EDIT_TARGET_COMMODITY_TARGET_SETTINGS_RESPONSE ||
            actionTypes.TARGETS_EDIT_TARGET_COMMODITY_TARGET_SETTINGS_FAILURE
          ))
      )
    })
  );

export const updateManufacturerEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.TARGETS_EDIT_MANUFACTURER_REQUEST),
    mergeMap(action => {
      const { manufacturerId, updatedManufacturer } = action.payload;
      return from(AirshopApi.put(
        'fc/manufacturer',
        updatedManufacturer,
      { params : { manufacturerId } }
    ))
      .pipe(
          map(response => response.data.status === 'OK' ? response.data.data : err(errMessages.NOT_OK, response)
            ),
          map(response => actions.targetsEditManufacturerResponse(
            response,
            actionTypes.TARGETS_EDIT_MANUFACTURER_RESPONSE
          )),
          catchError(error => of(actions.targetsEditManufacturerFailure(
            error,
            actionTypes.TARGETS_EDIT_MANUFACTURER_FAILURE
          ))),
          takeUntil(action$.ofType(
            actionTypes.TARGETS_EDIT_MANUFACTURER_RESPONSE ||
            actionTypes.TARGETS_EDIT_MANUFACTURER_FAILURE
          ))
      )
    })
  );

export const fetchTargetFeedPropertyListEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.TARGETS_TARGET_FEED_PROPERTY_LIST_REQUEST),
    mergeMap(action => {
      const { targetId, targetCategoryId } = action.payload;
      let path;
      let params;
      if (targetId) {
        path = 'fc/targetFeedProperty/targetId';
        params = { targetId };
      } else if (targetCategoryId) {
        path = 'fc/targetFeedProperty/targetCategoryId';
        params = { targetCategoryId };
      }
      return from(AirshopApi.get(path,
      { params }
    ))
      .pipe(
          map(response => response.data.status === 'OK' ? response.data.items : err(errMessages.NOT_OK, response)
            ),
          map(response => actions.targetsTargetFeedProperyListResponse(
            response,
            actionTypes.TARGETS_TARGET_FEED_PROPERTY_LIST_RESPONSE
          )),
          catchError(error => of(actions.targetsTargetFeedProperyListFailure(
            error,
            actionTypes.TARGETS_TARGET_FEED_PROPERTY_LIST_FAILURE
          ))),
          takeUntil(action$.ofType(
            actionTypes.TARGETS_TARGET_FEED_PROPERTY_LIST_RESPONSE ||
            actionTypes.TARGETS_TARGET_FEED_PROPERTY_LIST_FAILURE
          ))
      )
    })
  );

export const fetchTargetCommodityPropertyListEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.TARGETS_TARGET_COMMODITY_PROPERTY_LIST_REQUEST),
    mergeMap(action => {
      const { targetCommodityId } = action.payload;
      return from(AirshopApi.get('fc/targetCommodityProperty/targetCommodityId', {
        params: { targetCommodityId, limit: 500 }
      }))
      .pipe(
          map(response => response.data.status === 'OK' ? response.data.items : err(errMessages.NOT_OK, response)),
          map(response => actions.targetsTargetCommodityProperyListResponse(
            {[targetCommodityId]: response}
          )),
          catchError(error => of(actions.targetsTargetCommodityProperyListFailure(error))),
          takeUntil(action$.ofType(
            actionTypes.TARGETS_TARGET_COMMODITY_PROPERTY_LIST_RESPONSE ||
            actionTypes.TARGETS_TARGET_COMMODITY_PROPERTY_LIST_FAILURE
          ))
      )
    })
  );

export const createMultipleTargetCommodityPropertiesEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.TARGETS_CREATE_TARGET_COMMODITY_PROPERTY_REQUEST),
    mergeMap(action => {
      const { newTargetCommodityPropertiesArr: items } = action.payload;
      return forkJoin(items.map(item => AirshopApi.post(
        'fc/targetCommodityProperty', item
      )))
      .pipe(
        map(responses => handleForkJoinResponses(responses)),
        map(response => actions.targetsCreateTargetCommodityProperyResponse(
          response,
          actionTypes.TARGETS_CREATE_TARGET_COMMODITY_PROPERTY_RESPONSE
        )),
        catchError(error => of(actions.targetsCreateTargetCommodityProperyFailure(
          error,
          actionTypes.TARGETS_CREATE_TARGET_COMMODITY_PROPERTY_FAILURE
        ))),
        takeUntil(action$.ofType(
          actionTypes.TARGETS_CREATE_TARGET_COMMODITY_PROPERTY_RESPONSE ||
          actionTypes.TARGETS_CREATE_TARGET_COMMODITY_PROPERTY_FAILURE
        ))
      )
    })
  );

export const editMultipleTargetCommodityPropertiesEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.TARGETS_EDIT_TARGET_COMMODITY_PROPERTY_REQUEST),
    mergeMap(action => {
      const { editedTargetCommodityPropertiesArr: items } = action.payload;
      return forkJoin(items.map(item => AirshopApi.put(
        'fc/targetCommodityProperty', item, {
          params: { targetCommodityPropertyId: item.id }
        }
      )))
      .pipe(
        map(responses => handleForkJoinResponses(responses)),
        map(response => actions.targetsEditTargetCommodityProperyResponse(
          response,
          actionTypes.TARGETS_EDIT_TARGET_COMMODITY_PROPERTY_RESPONSE
        )),
        catchError(error => of(actions.targetsEditTargetCommodityProperyFailure(
          error,
          actionTypes.TARGETS_EDIT_TARGET_COMMODITY_PROPERTY_FAILURE
        ))),
        takeUntil(action$.ofType(
          actionTypes.TARGETS_EDIT_TARGET_COMMODITY_PROPERTY_RESPONSE ||
          actionTypes.TARGETS_EDIT_TARGET_COMMODITY_PROPERTY_FAILURE
        ))
      )
    })
  );

export const fetchCommodityTargetEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.TARGETS_COMMODITY_TARGET_REQUEST),
    mergeMap(action => {
      const { targetCommodityId } = action.payload;
      return from(AirshopApi.get('fc/targetCommodity/id', { params: {
        id: targetCommodityId
    }}))
      .pipe(
          map(response => response.data.status === 'OK' ? response.data.data : err(errMessages.NOT_OK, response)
            ),
          map(response => actions.targetsTargetCommodityResponse(response)),
          catchError(error => of(actions.targetsTargetCommodityFailure(error))),
          takeUntil(action$.ofType(
            actionTypes.TARGETS_COMMODITY_TARGET_RESPONSE ||
            actionTypes.TARGETS_COMMODITY_TARGET_FAILURE
          ))
      )
    })
  );

export const fetchTargetCommodityCategoryEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.TARGETS_TARGET_COMMODITY_CATEGORY_REQUEST),
    mergeMap(action => {
      const { targetCommodityId } = action.payload;
      return from(AirshopApi.get('fc/targetCommodityCategory/targetCommodityId', { params: {
        targetCommodityId
    }}))
      .pipe(
          map(response => response.data.status === 'OK' ? response.data.items : err(errMessages.NOT_OK, response)
            ),
          map(response => actions.targetsTargetCommodityCategoryResponse(response[0] ? response[0] : {})),
          catchError(error => of(actions.targetsTargetCommodityCategoryFailure(error))),
          takeUntil(action$.ofType(
            actionTypes.TARGETS_TARGET_COMMODITY_CATEGORY_RESPONSE ||
            actionTypes.TARGETS_TARGET_COMMODITY_CATEGORY_FAILURE
          ))
      )
    })
  );

export const editTargetCommodityCategoryEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.TARGETS_EDIT_TARGET_COMMODITY_CATEGORY_REQUEST),
    mergeMap(action => {
      const { targetCommodityCategoryId, targetCommodityCategory } = action.payload;
      return from(AirshopApi.put('fc/targetCommodityCategory',
      targetCommodityCategory,
      { params: {
        targetCommodityCategoryId
    }}))
      .pipe(
          map(response => response.data.status === 'OK' ? response.data.data : err(errMessages.NOT_OK, response)
            ),
          map(response => actions.targetsEditTargetCommodityCategoryResponse(
            response,
            actionTypes.TARGETS_EDIT_TARGET_COMMODITY_CATEGORY_RESPONSE
          )),
          catchError(error => of(actions.targetsEditTargetCommodityCategoryFailure(
            error,
            actionTypes.TARGETS_EDIT_TARGET_COMMODITY_CATEGORY_FAILURE
          ))),
          takeUntil(action$.ofType(
            actionTypes.TARGETS_EDIT_TARGET_COMMODITY_CATEGORY_RESPONSE ||
            actionTypes.TARGETS_EDIT_TARGET_COMMODITY_CATEGORY_FAILURE
          ))
      )
    })
  );

