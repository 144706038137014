import React, { useContext, useState, useEffect } from 'react'
import {
  CHeader,
  CToggler,
  CHeaderBrand,
  CHeaderNav,
  CHeaderNavItem,
  CSubheader,
  CCreateElement,
  CBreadcrumb,
  CBreadcrumbItem,
  CLink,
} from '@coreui/react'
import { HeaderLink } from 'generic/routing'
import CIcon from '@coreui/icons-react'
import { EProfileSetupKeys } from 'models'
import { useLayoutFacade } from 'redux-store/layout/layout.facade';
import { useUserFacade } from 'redux-store/user/user.facade';
import { useLocation } from 'react-router-dom';
import { environmentIsProdMaster } from 'AppConfig'

// routes config
import { prefixes } from '../routes';
import { ordersStatePrefix } from 'page-containers/orders/orders.routes'
// import usersRoutes from '../page-containers/users/users.routes';

import AppContext from '../contexts/context-registrations/AppContext'
import AppWideOrdersContext from 'contexts/context-registrations/AppWideOrdersContext'

// Hooks
import { useProfileSetup } from 'hooks/useProfileSetup'

const TheHeader = ({ determineDarkMode }) => {
  const location = useLocation();

  const isEmailsRoute = location.pathname.includes(prefixes.emails);

  // Facades
  const layoutFacade = useLayoutFacade();
  const userFacade = useUserFacade();

  // Context
  const appContext = useContext(AppContext);
  const appWideOrdersContext = useContext(AppWideOrdersContext);

  const { sidebarShow, breadcrumb } = layoutFacade;
  const { profileSetup } = userFacade;
  const { activeOrderState } = appWideOrdersContext;

  // Hooks
  const { toggleProfileSetupDarkTheme } = useProfileSetup();

  const emailsLabel = {
    allEmails: 'Emaily',
    sentEmails: 'Odchozí emaily',
    receivedEmails: 'Příchozí emaily',
  }

  const [emailsDropdownLabel, setEmailsDropdownLabel] = useState(emailsLabel.allEmails);
  const [activeHeaderLink, setActiveHeaderLink] = useState()

  const determineToggledDarkMode = (profileSetup) => {
    if (profileSetup && profileSetup.hasOwnProperty(EProfileSetupKeys.DARK_MODE)) {
      return (!profileSetup.darkMode).toString();
    } else {
      return 'false';
    }
  }

  const toggleDarkTheme = () => {
    toggleProfileSetupDarkTheme(
      determineToggledDarkMode(profileSetup),
      profileSetup
    );
  }

  const toggleSidebar = () => {
    const val = [true, 'responsive'].includes(sidebarShow) ? false : 'responsive'
    layoutFacade.setSidebarShow(val)
  }

  const toggleSidebarMobile = () => {
    const val = [false, 'responsive'].includes(sidebarShow) ? true : 'responsive'
    layoutFacade.setSidebarShow(val)
  }

  const logout = () => {
    if (appContext.authFunctions) {
      return appContext.authFunctions.logout();
    }
  }

  useEffect(() => {
    // If the route is not related to emails, set it back to default value in case it
    // is not set to it already (usually after visiting emails pages)
    if (!isEmailsRoute && (emailsDropdownLabel !== emailsLabel.allEmails)) {
      setEmailsDropdownLabel(emailsLabel.allEmails);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEmailsRoute]);

  const InlineHeaderLink = ({children, activeKey, to }) => (
    <HeaderLink to={to} setActive={setActiveHeaderLink} activeKey={activeKey} currentActive={activeHeaderLink}>{children}</HeaderLink>
  )

  return (
    <CHeader withSubheader>

      <CToggler
        inHeader
        className="ml-md-3 d-lg-none"
        onClick={toggleSidebarMobile}
      />
      <CToggler
        inHeader
        className="ml-3 d-md-down-none"
        onClick={toggleSidebar}
      />

      <CHeaderBrand className="mx-auto d-lg-none" to="/">
        <img src="/img/logo.png" alt="" />
      </CHeaderBrand>

      <CHeaderNav className="d-md-down-none mr-auto">
        <InlineHeaderLink to={activeOrderState && activeOrderState.value ? `/objednavky/${ordersStatePrefix}/${activeOrderState.value}` : '/objednavky'} activeKey={1}>
          Objednávky
        </InlineHeaderLink>
        <InlineHeaderLink to="/skladove-karty" activeKey={2}>Skladové karty</InlineHeaderLink>
        <InlineHeaderLink to={{pathname: '/prevodky/status/QUEUED', breadcrumb: 'Převodky otevřené', state: {status: 'QUEUED'}}} activeKey={3} >Převodky</InlineHeaderLink>
        <InlineHeaderLink to={{pathname: '/svozove-listy/balikobot-orders', breadcrumb: 'Přidané objednávky'}} activeKey={4}>Svozové listy</InlineHeaderLink>
        <InlineHeaderLink to={{pathname: '/kegs/zakladani-produktu', breadcrumb: 'Zakládání produktu'}} activeKey={5}>Sudy</InlineHeaderLink>
        <InlineHeaderLink restrictedToAdmins={true} to={{pathname: '/mailchimp/shop/beershop', breadcrumb: 'Beershop'}} activeKey={6}>Mailchimp</InlineHeaderLink>
        <InlineHeaderLink restrictedToAdmins={true} to={{pathname: '/rating/beer-list', breadcrumb: 'Piva s www'}} activeKey={7}>Rating</InlineHeaderLink>
        <InlineHeaderLink to={{pathname: '/administrace/uzivatelske-nastaveni', breadcrumb: 'Uživatelské nastavení'}} activeKey={8}>Administrace</InlineHeaderLink>
        {/* <InlineHeaderLink to={{pathname: '/emaily/prichozi', breadcrumb: 'Příchozí emaily'}} activeKey={9}>Emaily</InlineHeaderLink> */}
      </CHeaderNav >

      <CHeaderNav className="px-3">
        <CToggler
          inHeader
          className="ml-3 d-md-down-none"
          onClick={toggleDarkTheme}
          title={'Nastavit ' + (determineDarkMode() ? 'světlý' : 'tmavý') + ' motiv'}
        >
          <CIcon name="cil-moon" className="c-d-dark-none" alt="" />
          <CIcon name="cil-sun" className="c-d-default-none" alt="" />
        </CToggler>

        { appContext.authFunctions ?
          <CHeaderNavItem className="px-3" >
            <CIcon
              data-test="logout"
              name="cil-account-logout"
              alt="Logout"
              onClick={() => logout()}
              style={{'cursor': 'pointer'}}
            />
          </CHeaderNavItem> : null
        }
      </CHeaderNav>

      <CSubheader className="px-3 justify-content-between">
        <CBreadcrumb className="border-0 c-subheader-nav m-0 px-0 px-md-3">

        { breadcrumb ?
          <CCreateElement
            items={breadcrumb}
            components={{CBreadcrumbItem, CLink}}
          />
        : null }

        </CBreadcrumb>


          <div className="d-md-down-none mfe-2 c-subheader-nav">
          </div>
      </CSubheader>
    </CHeader>
  )
}

export default TheHeader
