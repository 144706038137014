import * as actionTypes from './user-checks.actionTypes'
import * as actions from './user-checks.actions'
import { AirshopApi } from '../../CraftApi.axios'
import { from, of } from 'rxjs'
import { mergeMap, takeUntil, catchError, map } from 'rxjs/operators'
import { ofType } from 'redux-observable'
import { err, errMessages } from 'redux-store/utils/epics.utils'
import axios from 'axios';

export const fetchUserIPCheckDataEpic = (action$) =>
  action$.pipe(
    ofType(actionTypes.USER_CHECKS_USER_IP_ADDRESS_CHECK_REQUEST),
    mergeMap((action) => from(axios.get('https://geolocation-db.com/json/'))
      .pipe(
          map(res => res.status === 200 ? res.data.IPv4 : null),
          mergeMap(userIPAddress => from(AirshopApi.get('accessList'))
          .pipe(
            map(res => res.data.status === 'OK' ? [userIPAddress, res.data.total] : err(errMessages.NOT_OK, res))
          )),
          mergeMap(([userIPAddress, total]) => from(AirshopApi.get('accessList', {
            params: { limit: total }
          }))
          .pipe(
            map(res => res.data.status === 'OK' ? [userIPAddress, res.data.items] : err(errMessages.NOT_OK, res))
          )),
          map(([userIPAddress, ipAddresses]) => $getAllowedIPAddressesByType(userIPAddress, ipAddresses)),
          map(([userIPAddress, generalTypeIpAddresses, shopTypeIpAddresses]) => actions
            .userChecksUserIPCheckInitiateResponse(
              userIPAddress, generalTypeIpAddresses, shopTypeIpAddresses
          )),
          catchError(error => of(actions.userChecksUserIPCheckInitiateFailure(error))),
          takeUntil(action$.ofType(
            actionTypes.USER_CHECKS_USER_IP_ADDRESS_CHECK_RESPONSE ||
              actionTypes.USER_CHECKS_USER_IP_ADDRESS_CHECK_FAILURE
          ))
      )
    )
  );

  const $getAllowedIPAddressesByType = (userIPAddress, ipAddresses) => {
    const allowedIpAddressItems = ipAddresses
      .filter(ipAddressItem => ipAddressItem.allowIpAddress);

    const generalTypeIpAddresses  = allowedIpAddressItems
      .filter(ipAddressItem => ipAddressItem.type === 'GENERAL')
      .map(ipAddress        => ipAddress.allowIpAddress);

    const shopTypeIpAddresses     = allowedIpAddressItems
      .filter(ipAddressItem => ipAddressItem.type === 'SHOP')
      .map(ipAddress        => ipAddress.allowIpAddress);

    return [userIPAddress, generalTypeIpAddresses, shopTypeIpAddresses];
  }
