import * as actionTypes from './store-cards.actionTypes';

const initialState = {
  sectionListTable: {
    storeCardsPageData: null,
    storeCardsPageDraw: null,
    storeCardsPagePage: 0,
    storeCardsPageLimit: null,
    storeCardsRelatedEans: null,
    storeCardsRelatedBottleTypes: null,
    // used for knowing when new page data, new eans and new bottle types were fetched
    // so the new table could be set at the right time
    eansHelperDraw: -1,
    bottleTypesHelperDraw: -1,
    total: null,
  },
  modalListTable: {
    storeCardsPageData: null,
    storeCardsPageDraw: null,
    storeCardsPagePage: 0,
    storeCardsPageLimit: null,
    total: null,
  },
  productInOrdersListTable: {
    productInOrdersPageData: null,
    productInOrdersPageDraw: null,
    productInOrdersPagePage: 0,
    productInOrdersPageLimit: null,
    total: null,
  },
  storeCardsEditableConsumptionTaxListTable: {
    storeCardsEditableCTPageData: null,
    storeCardsEditableCTPageDraw: null,
    storeCardsEditableCTPagePage: 0,
    storeCardsEditableCTPageLimit: null,
    storeCardsEditableCTRelatedEans: null,
    storeCardsEditableCTRelatedBottleTypes: null,
    // used for knowing when new page data, new eans and new bottle types were fetched
    // so the new table could be set at the right time
    storeCardsEditableCTEansHelperDraw: -1,
    storeCardsEditableCTBottleTypesHelperDraw: -1,
    total: null,
  },
  storeCardsForFBFeedTableData: null,
  bottleTypesList: null,
  resMessage: null,
  error: null,
  fetching: false,
}

const storeCardsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.STORE_CARDS_EANS_REQUEST:
    case actionTypes.STORE_CARDS_BULK_CHANGE_EXPORT_NULL_QUANTITY_REQUEST:
    case actionTypes.STORE_CARDS_BOTTLE_TYPES_BY_SC_REQUEST:
    case actionTypes.STORE_CARDS_BOTTLE_TYPE_LIST_REQUEST:
    case actionTypes.STORE_CARDS_DELETE_EAN_REQUEST:
    case actionTypes.STORE_CARDS_EDIT_STORE_CARD_REQUEST:
    case actionTypes.STORE_CARDS_EDIT_MULTIPLE_STORE_CARDS_REQUEST:
    case actionTypes.STORE_CARDS_FB_FEEDS_LIST_REQUEST:
      return {
        ...state,
        fetching: true,
      };
    case actionTypes.STORE_CARDS_LIST_REQUEST:
      return {
        ...state,
        modalListTable: {
          ...state.modalListTable,
          storeCardsPageDraw: action.payload.draw,
          storeCardsPagePage: action.payload.page,
          storeCardsPageLimit: action.payload.limit,
        },
        fetching: true,
      };
    case actionTypes.STORE_CARDS_PRODUCT_IN_ORDERS_LIST_REQUEST:
      return {
        ...state,
        productInOrdersListTable: {
          ...state.productInOrdersListTable,
          productInOrdersPageDraw: action.payload.draw,
          productInOrdersPagePage: action.payload.page,
          productInOrdersPageLimit: action.payload.limit,
        },
        fetching: true,
      };
    case actionTypes.STORE_CARDS_EDITABLE_CONSUMPTION_TAX_LIST_REQUEST:
      return {
        ...state,
        storeCardsEditableConsumptionTaxListTable: {
          ...state.storeCardsEditableConsumptionTaxListTable,
          storeCardsEditableCTPageDraw: action.payload.draw,
          storeCardsEditableCTPagePage: action.payload.page,
          storeCardsEditableCTPageLimit: action.payload.limit,
        },
        fetching: true,
      };
    case actionTypes.STORE_CARDS_SECTION_LIST_REQUEST:
      return {
        ...state,
        sectionListTable: {
          ...state.sectionListTable,
          storeCardsPageDraw: action.payload.draw,
          storeCardsPagePage: action.payload.page,
          storeCardsPageLimit: action.payload.limit,
        },
        fetching: true,
      };
    case actionTypes.STORE_CARDS_BULK_CHANGE_EXPORT_NULL_QUANTITY_RESPONSE:
    case actionTypes.STORE_CARDS_EDIT_STORE_CARD_RESPONSE:
    case actionTypes.STORE_CARDS_EDIT_MULTIPLE_STORE_CARDS_RESPONSE:
    case actionTypes.STORE_CARDS_DELETE_EAN_RESPONSE:
        return {
          ...state,
          resMessage: action.payload.resMessage,
          fetching: false,
        };
    case actionTypes.STORE_CARDS_LIST_RESPONSE:
      return {
        ...state,
        modalListTable: {
          ...state.modalListTable,
          storeCardsPageData: action.payload.items,
          total: action.payload.total,
        },
        fetching: false,
      };
    case actionTypes.STORE_CARDS_PRODUCT_IN_ORDERS_LIST_RESPONSE:
      return {
        ...state,
        productInOrdersListTable: {
          ...state.productInOrdersListTable,
          productInOrdersPageData: action.payload.items,
          total: action.payload.total,
        },
        fetching: false,
      };
    case actionTypes.STORE_CARDS_FB_FEEDS_LIST_RESPONSE:
      return {
        ...state,
        storeCardsForFBFeedTableData: action.payload,
        fetching: false,
      };
    case actionTypes.STORE_CARDS_EDITABLE_CONSUMPTION_TAX_LIST_RESPONSE:
      return {
        ...state,
        storeCardsEditableConsumptionTaxListTable: {
          ...state.storeCardsEditableConsumptionTaxListTable,
          storeCardsEditableCTPageData: action.payload.items,
          total: action.payload.total,
        },
        fetching: false,
      };
    case actionTypes.STORE_CARDS_BOTTLE_TYPE_LIST_RESPONSE:
      return {
        ...state,
        bottleTypesList: action.payload,
        fetching: false,
      };
    case actionTypes.STORE_CARDS_SECTION_LIST_RESPONSE:
      return {
        ...state,
        sectionListTable: {
          ...state.sectionListTable,
          storeCardsPageData: action.payload.items,
          total: action.payload.total,
        },
        fetching: false,
      };
    // Handled for store cards section table or store cards editable consumption tax table according to provided id
    case actionTypes.STORE_CARDS_EANS_RESPONSE: {
      const { tableSoureId } = action.payload;
      switch (tableSoureId) {
        case 'section_table':
          return {
            ...state,
            sectionListTable: {
              ...state.sectionListTable,
              storeCardsRelatedEans: action.payload.response,
              eansHelperDraw: state.sectionListTable.eansHelperDraw + 1,
            },
            fetching: false,
          };
        case 'editable_consumption_tax':
          return {
            ...state,
            storeCardsEditableConsumptionTaxListTable: {
              ...state.storeCardsEditableConsumptionTaxListTable,
              storeCardsEditableCTRelatedEans: action.payload.response,
              storeCardsEditableCTEansHelperDraw: state.storeCardsEditableConsumptionTaxListTable.storeCardsEditableCTEansHelperDraw + 1,
            },
            fetching: false,
          };
        // Should not happen, just to be safe
        default:
          return {
            ...state,
            fetching: false,
          };
      }
    };
    // Handled for store cards section table or store cards editable consumption tax table according to provided id
    case actionTypes.STORE_CARDS_BOTTLE_TYPES_BY_SC_RESPONSE: {
      const { tableSoureId } = action.payload;
      switch (tableSoureId) {
        case 'section_table':
          return {
            ...state,
            sectionListTable: {
              ...state.sectionListTable,
              storeCardsRelatedBottleTypes: action.payload.response,
              bottleTypesHelperDraw: state.sectionListTable.bottleTypesHelperDraw + 1,
            },
            fetching: false,
          };
        case 'editable_consumption_tax':
          return {
            ...state,
            storeCardsEditableConsumptionTaxListTable: {
              ...state.storeCardsEditableConsumptionTaxListTable,
              storeCardsEditableCTRelatedBottleTypes: action.payload.response,
              storeCardsEditableCTBottleTypesHelperDraw: state.storeCardsEditableConsumptionTaxListTable.storeCardsEditableCTBottleTypesHelperDraw + 1,
            },
            fetching: false,
          };
        // Should not happen, just to be safe
        default:
          return {
            ...state,
            fetching: false,
          };;
      }
    };
    case actionTypes.STORE_CARDS_LIST_FAILURE:
    case actionTypes.STORE_CARDS_SECTION_LIST_FAILURE:
    case actionTypes.STORE_CARDS_EANS_FAILURE:
    case actionTypes.STORE_CARDS_BOTTLE_TYPES_BY_SC_FAILURE:
    case actionTypes.STORE_CARDS_PRODUCT_IN_ORDERS_LIST_FAILURE:
    case actionTypes.STORE_CARDS_EDITABLE_CONSUMPTION_TAX_LIST_FAILURE:
    case actionTypes.STORE_CARDS_BOTTLE_TYPE_LIST_FAILURE:
    case actionTypes.STORE_CARDS_FB_FEEDS_LIST_FAILURE:
      return {
        ...state,
        fetching: false,
        error: {...action.payload}
      };
    case actionTypes.STORE_CARDS_BULK_CHANGE_EXPORT_NULL_QUANTITY_FAILURE:
    case actionTypes.STORE_CARDS_EDIT_STORE_CARD_FAILURE:
    case actionTypes.STORE_CARDS_EDIT_MULTIPLE_STORE_CARDS_FAILURE:
    case actionTypes.STORE_CARDS_DELETE_EAN_FAILURE:
      return {
        ...state,
        resMessage: action.payload.resMessage,
        fetching: false,
        error: {...action.payload.error},
      };
    case actionTypes.STORE_CARDS_RESPONSE_MESSAGE_RESET:
      return {
        ...state,
        resMessage: null,
      };
    default:
      return state;
  }
};

export default storeCardsReducer;
