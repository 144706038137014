import { useDispatch } from 'react-redux'
import { useCallback } from 'react'
import {
  IPSKeyValObject,
  IPSFullProp,
} from 'models'

import * as actions from './user.actions'
import { useUserSelectors } from './user.selectors'

export function useUserFacade() {
  const dispatch = useDispatch();
  const selectors = useUserSelectors();

  // Getters with selectors
  const {
    selectProfileSetup: profileSetup,
    selectProfileSetupFull: profileSetupFull,
    selectResMessage: userResMessage,
    selectUserOrdersStateDeliveryCombinations: userOrdersStateDeliveryCombinations,
  } = selectors;

  // Setters and fetching with reducer
  const fetchUserProfileSetup = useCallback(
    () =>
      dispatch(actions.userProfileSetupRequest()),
    [dispatch]
  );

  const createUserProfileProp = useCallback(
    ({ key, value }: IPSKeyValObject) =>
      dispatch(actions.userProfileSetupCreatePropRequest({key, value})),
    [dispatch]
  );

  const updateUserProfileProp = useCallback(
    (updatedFullProp: IPSFullProp) =>
      dispatch(actions.userProfileSetupUpdateRequest(updatedFullProp)),
    [dispatch]
  );

  const deleteUserProfileProp = useCallback(
    (propId: number) =>
      dispatch(actions.userProfileSetupDeletePropRequest(propId)),
    [dispatch]
  );

  const resetUserResponseMessage = useCallback(
    () =>
      dispatch(actions.userResponseMessageReset()),
    [dispatch]
  );
  return {
    profileSetup,
    profileSetupFull,
    userResMessage,
    fetchUserProfileSetup,
    createUserProfileProp,
    updateUserProfileProp,
    deleteUserProfileProp,
    resetUserResponseMessage,
    userOrdersStateDeliveryCombinations,
  }

}
