import { useSelector } from 'react-redux'

export function useStoreCardsSelectors() {
  const selectResMessage = useSelector(state => state.storeCards.resMessage);
  const selectBottleTypesList = useSelector(state => state.storeCards.bottleTypesList);
  const selectStoreCardsForFBFeedTableData = useSelector(state => state.storeCards.storeCardsForFBFeedTableData);

  // Store cards list in a modal in transfers
  const selectModalListStoreCardsPageData = useSelector(state => state.storeCards.modalListTable.storeCardsPageData);
  const selectModalListStoreCardsTotal = useSelector(state => state.storeCards.modalListTable.total);
  const selectModalListStoreCardsPageDraw = useSelector(state => state.storeCards.modalListTable.storeCardsPageDraw);
  const selectModalListStoreCardsPagePage = useSelector(state => state.storeCards.modalListTable.storeCardsPagePage);
  const selectModalListStoreCardsPageLimit = useSelector(state => state.storeCards.modalListTable.storeCardsPageLimit);

  // Store cards list in its own section
  const selectSectionListStoreCardsPageData = useSelector(state => state.storeCards.sectionListTable.storeCardsPageData);
  const selectSectionListStoreCardsTotal = useSelector(state => state.storeCards.sectionListTable.total);
  const selectSectionListStoreCardsPageDraw = useSelector(state => state.storeCards.sectionListTable.storeCardsPageDraw);
  const selectSectionListStoreCardsPagePage = useSelector(state => state.storeCards.sectionListTable.storeCardsPagePage);
  const selectSectionListStoreCardsPageLimit = useSelector(state => state.storeCards.sectionListTable.storeCardsPageLimit);
  const selectSectionListStoreCardsRelatedEans = useSelector(state => state.storeCards.sectionListTable.storeCardsRelatedEans);
  const selectSectionListStoreCardsRelatedBottleTypes = useSelector(state => state.storeCards.sectionListTable.storeCardsRelatedBottleTypes);
  const selectSectionListEansHelperDraw = useSelector(state => state.storeCards.sectionListTable.eansHelperDraw);
  const selectSectionListBottleTypesHelperDraw = useSelector(state => state.storeCards.sectionListTable.bottleTypesHelperDraw);

  // Product in orders list
  const selectProductInOrdersPageData = useSelector(state => state.storeCards.productInOrdersListTable.productInOrdersPageData);
  const selectProductInOrdersPageDraw = useSelector(state => state.storeCards.productInOrdersListTable.productInOrdersPageDraw);
  const selectProductInOrdersPagePage = useSelector(state => state.storeCards.productInOrdersListTable.productInOrdersPagePage);
  const selectProductInOrdersPageLimit = useSelector(state => state.storeCards.productInOrdersListTable.productInOrdersPageLimit);
  const selectProductInOrdersTotal = useSelector(state => state.storeCards.productInOrdersListTable.total);

  // Store cards editable consumption tax list

  const selectStoreCardsEditableCTPageData = useSelector(state => state.storeCards.storeCardsEditableConsumptionTaxListTable.storeCardsEditableCTPageData);
  const selectStoreCardsEditableCTPageDraw = useSelector(state => state.storeCards.storeCardsEditableConsumptionTaxListTable.storeCardsEditableCTPageDraw);
  const selectStoreCardsEditableCTPagePage = useSelector(state => state.storeCards.storeCardsEditableConsumptionTaxListTable.storeCardsEditableCTPagePage);
  const selectStoreCardsEditableCTPageLimit = useSelector(state => state.storeCards.storeCardsEditableConsumptionTaxListTable.storeCardsEditableCTPageLimit);
  const selectStoreCardsEditableCTTotal = useSelector(state => state.storeCards.storeCardsEditableConsumptionTaxListTable.total);
  const selectStoreCardsEditableCTRelatedEans = useSelector(state => state.storeCards.storeCardsEditableConsumptionTaxListTable.storeCardsEditableCTRelatedEans);
  const selectStoreCardsEditableCTRelatedBottleTypes = useSelector(state => state.storeCards.storeCardsEditableConsumptionTaxListTable.storeCardsEditableCTRelatedBottleTypes);
  const selectStoreCardsEditableCTEansHelperDraw = useSelector(state => state.storeCards.storeCardsEditableConsumptionTaxListTable.storeCardsEditableCTEansHelperDraw);
  const selectStoreCardsEditableCTBottleTypesHelperDraw = useSelector(state => state.storeCards.storeCardsEditableConsumptionTaxListTable.storeCardsEditableCTBottleTypesHelperDraw);

  return {
    selectResMessage,
    selectBottleTypesList,
    selectStoreCardsForFBFeedTableData,

    selectModalListStoreCardsPageData,
    selectModalListStoreCardsTotal,
    selectModalListStoreCardsPageDraw,
    selectModalListStoreCardsPagePage,
    selectModalListStoreCardsPageLimit,

    selectSectionListStoreCardsPageData,
    selectSectionListStoreCardsTotal,
    selectSectionListStoreCardsPageDraw,
    selectSectionListStoreCardsPagePage,
    selectSectionListStoreCardsPageLimit,
    selectSectionListStoreCardsRelatedEans,
    selectSectionListStoreCardsRelatedBottleTypes,
    selectSectionListEansHelperDraw,
    selectSectionListBottleTypesHelperDraw,

    selectProductInOrdersPageData,
    selectProductInOrdersPageDraw,
    selectProductInOrdersPagePage,
    selectProductInOrdersPageLimit,
    selectProductInOrdersTotal,

    selectStoreCardsEditableCTPageData,
    selectStoreCardsEditableCTPageDraw,
    selectStoreCardsEditableCTPagePage,
    selectStoreCardsEditableCTPageLimit,
    selectStoreCardsEditableCTTotal,
    selectStoreCardsEditableCTRelatedEans,
    selectStoreCardsEditableCTRelatedBottleTypes,
    selectStoreCardsEditableCTEansHelperDraw,
    selectStoreCardsEditableCTBottleTypesHelperDraw,
  }
}
