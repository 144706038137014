import { useDispatch } from 'react-redux';
import { useCallback } from 'react';

import * as actions from './layout.actions';
import { useLayoutSelectors } from './layout.selectors';

export function useLayoutFacade() {
  const dispatch = useDispatch();
  const selectors = useLayoutSelectors();

  // Getters with selectors
  const sidebarShow = selectors.selectSidebarShow;
  const asideShow = selectors.selectAsideShow;
  // const darkMode = selectors.selectDarkMode;
  const breadcrumb = selectors.selectBreadcrumb;

  // Setters with reducer
  const setSidebarShow = useCallback(
    sidebarShowVal =>
      dispatch(actions.layoutSetSidebarShow(sidebarShowVal)),
    [dispatch]
  );

  const setAsideShow = useCallback(
    asideShowVal =>
      dispatch(actions.layoutSetAsideShow(asideShowVal)),
    [dispatch]
  );

  // const setDarkMode = useCallback(
  //   darkModeVal =>
  //     dispatch(actions.layoutSetDarkMode(darkModeVal)),
  //   [dispatch]
  // );

  const setBreadcrumb = useCallback(
    breadcrumbObject =>
      dispatch(actions.layoutSetNewBreadcrumb(breadcrumbObject)),
    [dispatch]
  );

  const appendToBreadcrumb = useCallback(
    breadcrumbObject =>
      dispatch(actions.layoutAppendBreadcrumb(breadcrumbObject)),
    [dispatch]
  );

  const formCrumbObject = (name, to=null, isActive=false) => {
    if (!isActive) {
      return {
        _tag: 'CBreadcrumbItem',
        active: isActive,
        _children: [{
          _tag: 'CLink',
          to,
          _children: [name],
        }],
      }
    } else {
      return {
        _tag: 'CBreadcrumbItem',
        active: isActive,
        _children: [name],
      }
    }
  };

  return {
    sidebarShow,
    asideShow,
    // darkMode,
    breadcrumb,
    setSidebarShow,
    setAsideShow,
    // setDarkMode,
    setBreadcrumb,
    appendToBreadcrumb,
    formCrumbObject,
  }

}
