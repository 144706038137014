import React from 'react'
import { prefixes } from '../../routes'

const MailchimpLayout = React.lazy(() => import('./pages/MailchimpLayout/MailchimpLayout'));

export const { mailchimp } = prefixes;
export const mailchimpShopPrefix = 'shop';

const mailchimpRoutes = [
  { path: `${mailchimp}/${mailchimpShopPrefix}/:shop`, exact: true, name: 'Mailchimp', component: MailchimpLayout },
];

export default mailchimpRoutes;
