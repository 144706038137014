import * as actionTypes from './rent.actionTypes'

const initialState = {
  templatePdfsListPageData: null,
  templatePdfsListPageDraw: null,
  templatePdfsListPagePage: 0,
  templatePdfsListTotal: null,
  templatePdfsListPageLimit: 50,
  customersListPageData: null,
  customersListPageDraw: null,
  customersListPagePage: 0,
  customersListTotal: null,
  customersListPageLimit: 50,
  contractsListPageData: null,
  contractsListPageDraw: null,
  contractsListPagePage: 0,
  contractsListTotal: null,
  contractsListPageLimit: 50,
  targetCommodityForRentalListPageData: null,
  targetCommodityForRentalListPageDraw: null,
  targetCommodityForRentalListPagePage: 0,
  targetCommodityForRentalListTotal: null,
  targetCommodityForRentalListPageLimit: 20,
  reservationsListPageData: null,
  reservationsListPageDraw: null,
  reservationsListPagePage: 0,
  reservationsListTotal: null,
  reservationsListPageLimit: 20,
  contractItemsByContractId: {},
  scanDocumentsByContractId: {},
  reservationItemsByReservationId: {},
  singleCustomer: null,
  singleContract: null,
  createdDeposit: null,
  createdReservation: null,
  relatedCustomers: {},
  relatedDeposits: {},
  relatedPdfDocuments: {},
  generatedPdfDocument: null,
  depositSum: null,
  resMessage: null,
  error: null,
  fetching: false,
}

const rentReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.RENT_RESET_RES_MESSAGE:
      return {
        ...state,
        resMessage: null,
      };
    case actionTypes.RENT_CREATE_TEMPLATE_PDF_REQUEST:
    case actionTypes.RENT_EDIT_TEMPLATE_PDF_REQUEST:
    case actionTypes.RENT_DELETE_TEMPLATE_PDF_REQUEST:
    case actionTypes.RENT_CREATE_CUSTOMER_REQUEST:
    case actionTypes.RENT_EDIT_CUSTOMER_REQUEST:
    case actionTypes.RENT_CONTRACT_ITEMS_BY_CONTRACT_ID_REQUEST:
    case actionTypes.RENT_CREATE_MULTIPLE_CONTRACT_ITEMS_REQUEST:
    case actionTypes.RENT_DELETE_CONTRACT_ITEM_REQUEST:
    case actionTypes.RENT_CREATE_CONTRACT_REQUEST:
    case actionTypes.RENT_EDIT_CONTRACT_REQUEST:
    case actionTypes.RENT_DELETE_CONTRACT_REQUEST:
    case actionTypes.RENT_SCAN_DOCUMENTS_LIST_BY_CONTRACT_ID_REQUEST:
    case actionTypes.RENT_CREATE_SCAN_DOCUMENT_REQUEST:
    case actionTypes.RENT_DELETE_SCAN_DOCUMENT_REQUEST:
    case actionTypes.RENT_CREATE_RESERVATION_REQUEST:
    case actionTypes.RENT_EDIT_RESERVATION_REQUEST:
    case actionTypes.RENT_RESERVATION_ITEMS_LIST_BY_RESERVATION_ID_REQUEST:
    case actionTypes.RENT_CREATE_RESERVATION_ITEM_REQUEST:
    case actionTypes.RENT_DELETE_RESERVATION_ITEM_REQUEST:
    case actionTypes.RENT_FINALIZE_CONTRACT_REQUEST:
    case actionTypes.RENT_DELETE_RESERVATION_REQUEST:
    case actionTypes.RENT_CUSTOMER_REQUEST:
    case actionTypes.RENT_GENERATE_PDF_REQUEST:
    case actionTypes.RENT_CREATE_DEPOSIT_REQUEST:
    case actionTypes.RENT_EDIT_DEPOSIT_REQUEST:
    case actionTypes.RENT_TRANSFORM_RESERVATION_TO_CONTRACT_REQUEST:
    case actionTypes.RENT_REMIND_RENT_CONTRACT_REQUEST:
    case actionTypes.RENT_PRINT_DOCUMENT_REQUEST:
    case actionTypes.RENT_DEPOSIT_SUM_REQUEST:
    case actionTypes.RENT_FINALIZE_RETURN_REQUEST:
    case actionTypes.RENT_CONTRACT_DOCUMENTS_REQUEST:
    case actionTypes.RENT_PREPARE_CONTRACT_FROM_CONTRACT_REQUEST:
    case actionTypes.RENT_PREPARE_RESERVATION_FROM_CONTRACT_REQUEST:
      return {
        ...state,
        fetching: true,
      };
    case actionTypes.RENT_TEMPLATES_PDF_LIST_REQUEST:
      return {
        ...state,
        templatePdfsListPageDraw: action.payload.draw,
        templatePdfsListPagePage: action.payload.page,
        templatePdfsListPageLimit: action.payload.limit,
        fetching: true,
      };
    case actionTypes.RENT_CUSTOMERS_LIST_REQUEST:
      return {
        ...state,
        customersListPageDraw: action.payload.draw,
        customersListPagePage: action.payload.page,
        customersListPageLimit: action.payload.limit,
        fetching: true,
      };
    case actionTypes.RENT_CONTRACTS_LIST_REQUEST:
      return {
        ...state,
        contractsListPageDraw: action.payload.draw,
        contractsListPagePage: action.payload.page,
        contractsListPageLimit: action.payload.limit,
        fetching: true,
      };
    case actionTypes.RENT_RESERVATIONS_LIST_REQUEST:
      return {
        ...state,
        reservationsListPageDraw: action.payload.draw,
        reservationsListPagePage: action.payload.page,
        reservationsListPageLimit: action.payload.limit,
        fetching: true,
      };
    case actionTypes.RENT_TARGET_COMMODITY_FOR_RENTAL_LIST_REQUEST:
      return {
        ...state,
        targetCommodityForRentalListPageDraw: action.payload.draw,
        targetCommodityForRentalListPagePage: action.payload.page,
        targetCommodityForRentalListPageLimit: action.payload.limit,
        fetching: false,
      };
    case actionTypes.RENT_TEMPLATES_PDF_LIST_RESPONSE:
      return {
        ...state,
        templatePdfsListPageData: action.payload.items,
        templatePdfsListTotal: action.payload.total,
        fetching: false,
      };
    case actionTypes.RENT_CONTRACT_DOCUMENTS_RESPONSE:
      return {
        ...state,
        relatedPdfDocuments: action.payload,
        fetching: false,
      };
    case actionTypes.RENT_CUSTOMERS_LIST_RESPONSE:
      return {
        ...state,
        customersListPageData: action.payload.items,
        customersListTotal: action.payload.total,
        fetching: false,
      };
    case actionTypes.RENT_CONTRACTS_LIST_RESPONSE:
      return {
        ...state,
        contractsListPageData: action.payload.tableData.items,
        contractsListTotal: action.payload.tableData.total,
        relatedCustomers: action.payload.customers,
        relatedDeposits: action.payload.deposits,
        relatedPdfDocuments: action.payload.documents,
        fetching: false,
      };
    case actionTypes.RENT_TARGET_COMMODITY_FOR_RENTAL_LIST_RESPONSE:
      return {
        ...state,
        targetCommodityForRentalListPageData: action.payload.items,
        targetCommodityForRentalListTotal: action.payload.total,
        fetching: false,
      };
    case actionTypes.RENT_RESERVATIONS_LIST_RESPONSE:
      return {
        ...state,
        reservationsListPageData: action.payload.tableData.items,
        reservationsListTotal: action.payload.tableData.total,
        relatedCustomers: action.payload.customers,
        fetching: false,
      };
    case actionTypes.RENT_CONTRACT_ITEMS_BY_CONTRACT_ID_RESPONSE:
      return {
        ...state,
        contractItemsByContractId: action.payload,
        fetching: false,
      };
    case actionTypes.RENT_RESERVATION_ITEMS_LIST_BY_RESERVATION_ID_RESPONSE:
      return {
        ...state,
        reservationItemsByReservationId: action.payload,
        fetching: false,
      };
    case actionTypes.RENT_SCAN_DOCUMENTS_LIST_BY_CONTRACT_ID_RESPONSE:
      return {
        ...state,
        scanDocumentsByContractId: action.payload,
        fetching: false,
      };
    case actionTypes.RENT_CREATE_DEPOSIT_RESPONSE:
      return {
        ...state,
        resMessage: action.payload.resMessage,
        createdDeposit: action.payload.response,
        fetching: false,
      };
    case actionTypes.RENT_CREATE_DEPOSIT_RESET:
      return {
        ...state,
        createdDeposit: null,
      };
    case actionTypes.RENT_DEPOSIT_SUM_RESPONSE:
      return {
        ...state,
        depositSum: action.payload,
        fetching: false,
      };
    case actionTypes.RENT_CREATE_SCAN_DOCUMENT_RESPONSE:
    case actionTypes.RENT_DELETE_SCAN_DOCUMENT_RESPONSE:
      return {
        ...state,
        fetching: false,
      };
    case actionTypes.RENT_CREATE_TEMPLATE_PDF_RESPONSE:
    case actionTypes.RENT_EDIT_TEMPLATE_PDF_RESPONSE:
    case actionTypes.RENT_DELETE_TEMPLATE_PDF_RESPONSE:
    case actionTypes.RENT_EDIT_CUSTOMER_RESPONSE:
    case actionTypes.RENT_CREATE_MULTIPLE_CONTRACT_ITEMS_RESPONSE:
    case actionTypes.RENT_DELETE_CONTRACT_ITEM_RESPONSE:
    case actionTypes.RENT_DELETE_CONTRACT_RESPONSE:
    case actionTypes.RENT_EDIT_RESERVATION_RESPONSE:
    case actionTypes.RENT_CREATE_RESERVATION_ITEM_RESPONSE:
    case actionTypes.RENT_DELETE_RESERVATION_ITEM_RESPONSE:
    case actionTypes.RENT_FINALIZE_CONTRACT_RESPONSE:
    case actionTypes.RENT_FINALIZE_RETURN_RESPONSE:
    case actionTypes.RENT_DELETE_RESERVATION_RESPONSE:
    case actionTypes.RENT_EDIT_DEPOSIT_RESPONSE:
    case actionTypes.RENT_REMIND_RENT_CONTRACT_RESPONSE:
    case actionTypes.RENT_PRINT_DOCUMENT_RESPONSE:
      return {
        ...state,
        resMessage: action.payload.resMessage,
        fetching: false,
      };
    case actionTypes.RENT_GENERATE_PDF_RESPONSE:
      return {
        ...state,
        generatedPdfDocument: action.payload.response,
        resMessage: action.payload.resMessage,
        fetching: false,
      };
    case actionTypes.RENT_GENERATE_PDF_RESET:
      return {
        ...state,
        generatedPdfDocument: null,
      };
    case actionTypes.RENT_CREATE_RESERVATION_RESPONSE:
    case actionTypes.RENT_PREPARE_RESERVATION_FROM_CONTRACT_RESPONSE:
      return {
        ...state,
        createdReservation: action.payload.response,
        resMessage: action.payload.resMessage,
        fetching: false,
      };
    case actionTypes.RENT_CREATE_CUSTOMER_RESPONSE:
      return {
        ...state,
        resMessage: action.payload.resMessage,
        singleCustomer: action.payload.response,
        fetching: false,
      };
    case actionTypes.RENT_CUSTOMER_RESPONSE:
      return {
        ...state,
        singleCustomer: action.payload,
        fetching: false,
      };
    case actionTypes.RENT_CREATE_CUSTOMER_RESET:
      return {
        ...state,
        singleCustomer: null,
      };
    case actionTypes.RENT_EDIT_CONTRACT_RESPONSE:
    case actionTypes.RENT_CREATE_CONTRACT_RESPONSE:
    case actionTypes.RENT_TRANSFORM_RESERVATION_TO_CONTRACT_RESPONSE:
    case actionTypes.RENT_PREPARE_CONTRACT_FROM_CONTRACT_RESPONSE:
      return {
        ...state,
        resMessage: action.payload.resMessage,
        singleContract: action.payload.response,
        fetching: false,
      };
    case actionTypes.RENT_CREATE_CONTRACT_RESET:
      return {
        ...state,
        singleContract: null,
      };
    case actionTypes.RENT_CREATE_RESERVATION_RESET:
      return {
        ...state,
        createdReservation: null,
      };
    case actionTypes.RENT_TEMPLATES_PDF_LIST_FAILURE:
    case actionTypes.RENT_CONTRACTS_LIST_FAILURE:
    case actionTypes.RENT_CONTRACT_ITEMS_BY_CONTRACT_ID_FAILURE:
    case actionTypes.RENT_CUSTOMERS_LIST_FAILURE:
    case actionTypes.RENT_SCAN_DOCUMENTS_LIST_BY_CONTRACT_ID_FAILURE:
    case actionTypes.RENT_CREATE_SCAN_DOCUMENT_FAILURE:
    case actionTypes.RENT_DELETE_SCAN_DOCUMENT_FAILURE:
    case actionTypes.RENT_TARGET_COMMODITY_FOR_RENTAL_LIST_FAILURE:
    case actionTypes.RENT_RESERVATIONS_LIST_FAILURE:
    case actionTypes.RENT_RESERVATION_ITEMS_LIST_BY_RESERVATION_ID_FAILURE:
    case actionTypes.RENT_CUSTOMER_FAILURE:
    case actionTypes.RENT_CONTRACT_DOCUMENTS_FAILURE:
      return {
        ...state,
        fetching: false,
        error: {...action.payload}
      };
    case actionTypes.RENT_CREATE_TEMPLATE_PDF_FAILURE:
    case actionTypes.RENT_EDIT_TEMPLATE_PDF_FAILURE:
    case actionTypes.RENT_DELETE_TEMPLATE_PDF_FAILURE:
    case actionTypes.RENT_CREATE_CUSTOMER_FAILURE:
    case actionTypes.RENT_EDIT_CUSTOMER_FAILURE:
    case actionTypes.RENT_CREATE_MULTIPLE_CONTRACT_ITEMS_FAILURE:
    case actionTypes.RENT_DELETE_CONTRACT_ITEM_FAILURE:
    case actionTypes.RENT_CREATE_CONTRACT_FAILURE:
    case actionTypes.RENT_EDIT_CONTRACT_FAILURE:
    case actionTypes.RENT_DELETE_CONTRACT_FAILURE:
    case actionTypes.RENT_CREATE_RESERVATION_FAILURE:
    case actionTypes.RENT_EDIT_RESERVATION_FAILURE:
    case actionTypes.RENT_CREATE_RESERVATION_ITEM_FAILURE:
    case actionTypes.RENT_DELETE_RESERVATION_ITEM_FAILURE:
    case actionTypes.RENT_FINALIZE_CONTRACT_FAILURE:
    case actionTypes.RENT_FINALIZE_RETURN_FAILURE:
    case actionTypes.RENT_DELETE_RESERVATION_FAILURE:
    case actionTypes.RENT_GENERATE_PDF_FAILURE:
    case actionTypes.RENT_CREATE_DEPOSIT_FAILURE:
    case actionTypes.RENT_EDIT_DEPOSIT_FAILURE:
    case actionTypes.RENT_TRANSFORM_RESERVATION_TO_CONTRACT_FAILURE:
    case actionTypes.RENT_REMIND_RENT_CONTRACT_FAILURE:
    case actionTypes.RENT_PRINT_DOCUMENT_FAILURE:
    case actionTypes.RENT_PREPARE_CONTRACT_FROM_CONTRACT_FAILURE:
    case actionTypes.RENT_PREPARE_RESERVATION_FROM_CONTRACT_FAILURE:
      return {
        ...state,
        resMessage: action.payload.resMessage,
        fetching: false,
        error: {...action.payload}
      };
    default:
      return state;
  }
};

export default rentReducer;
