export const ADMINISTRATION_RESPONSE_MESSAGE_RESET = '[ADMINISTRATION]_RESPONSE_MESSAGE_RESET'

export const ADMINISTRATION_IMPORT_FC_BY_SHOP_REQUEST = '[ADMINISTRATION]_IMPORT_FC_BY_SHOP_REQUEST'
export const ADMINISTRATION_IMPORT_FC_BY_SHOP_RESPONSE = '[ADMINISTRATION]_IMPORT_FC_BY_SHOP_RESPONSE'
export const ADMINISTRATION_IMPORT_FC_BY_SHOP_FAILURE = '[ADMINISTRATION]_IMPORT_FC_BY_SHOP_FAILURE'

export const ADMINISTRATION_SYNC_FC_DAYS_BACK_BY_SHOP_REQUEST = '[ADMINISTRATION]_SYNC_FC_DAYS_BACK_BY_SHOP_REQUEST'
export const ADMINISTRATION_SYNC_FC_DAYS_BACK_BY_SHOP_RESPONSE = '[ADMINISTRATION]_SYNC_FC_DAYS_BACK_BY_SHOP_RESPONSE'
export const ADMINISTRATION_SYNC_FC_DAYS_BACK_BY_SHOP_FAILURE = '[ADMINISTRATION]_SYNC_FC_DAYS_BACK_BY_SHOP_FAILURE'

export const ADMINISTRATION_PRINT_SERVERS_REQUEST = '[ADMINISTRATION]_PRINT_SERVERS_REQUEST'
export const ADMINISTRATION_PRINT_SERVERS_RESPONSE = '[ADMINISTRATION]_PRINT_SERVERS_RESPONSE'
export const ADMINISTRATION_PRINT_SERVERS_FAILURE = '[ADMINISTRATION]_PRINT_SERVERS_FAILURE'

export const ADMINISTRATION_PRINTERS_LIST_REQUEST = '[ADMINISTRATION]_PRINTERS_LIST_REQUEST'
export const ADMINISTRATION_PRINTERS_LIST_RESPONSE = '[ADMINISTRATION]_PRINTERS_LIST_RESPONSE'
export const ADMINISTRATION_PRINTERS_LIST_FAILURE = '[ADMINISTRATION]_PRINTERS_LIST_FAILURE'

export const ADMINISTRATION_PRINTER_PROFILE_REQUEST = '[ADMINISTRATION]_PRINTER_PROFILE_REQUEST'
export const ADMINISTRATION_PRINTER_PROFILE_RESPONSE = '[ADMINISTRATION]_PRINTER_PROFILE_RESPONSE'
export const ADMINISTRATION_PRINTER_PROFILE_FAILURE = '[ADMINISTRATION]_PRINTER_PROFILE_FAILURE'
export const ADMINISTRATION_PRINTER_PROFILE_STOP = '[ADMINISTRATION]_PRINTER_PROFILE_STOP'


export const ADMINISTRATION_UPDATE_USER_THERMAL_PRINTER_REQUEST = '[ADMINISTRATION]_UPDATE_USER_THERMAL_PRINTER_REQUEST'
export const ADMINISTRATION_UPDATE_USER_THERMAL_PRINTER_RESPONSE = '[ADMINISTRATION]_UPDATE_USER_THERMAL_PRINTER_RESPONSE'
export const ADMINISTRATION_UPDATE_USER_THERMAL_PRINTER_FAILURE = '[ADMINISTRATION]_UPDATE_USER_THERMAL_PRINTER_FAILURE'

export const ADMINISTRATION_UPDATE_USER_A4_PRINTER_REQUEST = 'ADMINISTRATION_UPDATE_USER_A4_PRINTER_REQUEST'
export const ADMINISTRATION_UPDATE_USER_A4_PRINTER_RESPONSE = 'ADMINISTRATION_UPDATE_USER_A4_PRINTER_RESPONSE'
export const ADMINISTRATION_UPDATE_USER_A4_PRINTER_FAILURE = 'ADMINISTRATION_UPDATE_USER_A4_PRINTER_FAILURE'

export const ADMINISTRATION_AIRSHOP_NOTIFICATIONS_REQUEST = '[ADMINISTRATION]_AIRSHOP_NOTIFICATIONS_REQUEST'
export const ADMINISTRATION_AIRSHOP_NOTIFICATIONS_RESPONSE = '[ADMINISTRATION]_AIRSHOP_NOTIFICATIONS_RESPONSE'
export const ADMINISTRATION_AIRSHOP_NOTIFICATIONS_FAILURE = '[ADMINISTRATION]_AIRSHOP_NOTIFICATIONS_FAILURE'

export const ADMINISTRATION_IP_ADDRESSES_LIST_REQUEST = '[ADMINISTRATION]_IP_ADDRESSES_LIST_REQUEST'
export const ADMINISTRATION_IP_ADDRESSES_LIST_RESPONSE = '[ADMINISTRATION]_IP_ADDRESSES_LIST_RESPONSE'
export const ADMINISTRATION_IP_ADDRESSES_LIST_FAILURE = '[ADMINISTRATION]_IP_ADDRESSES_LIST_FAILURE'

export const ADMINISTRATION_CREATE_IP_ADDRESS_REQUEST = '[ADMINISTRATION]_CREATE_IP_ADDRESS_REQUEST'
export const ADMINISTRATION_CREATE_IP_ADDRESS_RESPONSE = '[ADMINISTRATION]_CREATE_IP_ADDRESS_RESPONSE'
export const ADMINISTRATION_CREATE_IP_ADDRESS_FAILURE = '[ADMINISTRATION]_CREATE_IP_ADDRESS_FAILURE'

export const ADMINISTRATION_UPDATE_IP_ADDRESS_REQUEST = '[ADMINISTRATION]_UPDATE_IP_ADDRESS_REQUEST'
export const ADMINISTRATION_UPDATE_IP_ADDRESS_RESPONSE = '[ADMINISTRATION]_UPDATE_IP_ADDRESS_RESPONSE'
export const ADMINISTRATION_UPDATE_IP_ADDRESS_RESPONSE_NR = '[ADMINISTRATION]_UPDATE_IP_ADDRESS_RESPONSE_NO_LIST_REFETCH'
export const ADMINISTRATION_UPDATE_IP_ADDRESS_FAILURE = '[ADMINISTRATION]_UPDATE_IP_ADDRESS_FAILURE'

export const ADMINISTRATION_DELETE_IP_ADDRESS_REQUEST = '[ADMINISTRATION]_DELETE_IP_ADDRESS_REQUEST'
export const ADMINISTRATION_DELETE_IP_ADDRESS_RESPONSE = '[ADMINISTRATION]_DELETE_IP_ADDRESS_RESPONSE'
export const ADMINISTRATION_DELETE_IP_ADDRESS_FAILURE = '[ADMINISTRATION]_DELETE_IP_ADDRESS_FAILURE'

export const ADMINISTRATION_PARTNERS_LIST_REQUEST = '[ADMINISTRATION]_PARTNERS_LIST_REQUEST'
export const ADMINISTRATION_PARTNERS_LIST_RESPONSE = '[ADMINISTRATION]_PARTNERS_LIST_RESPONSE'
export const ADMINISTRATION_PARTNERS_LIST_FAILURE = '[ADMINISTRATION]_PARTNERS_LIST_FAILURE'

export const ADMINISTRATION_USER_IP_ADDRESS_CHECK_REQUEST = '[ADMINISTRATION]_USER_IP_ADDRESS_CHECK_REQUEST'
export const ADMINISTRATION_USER_IP_ADDRESS_CHECK_RESPONSE = '[ADMINISTRATION]_USER_IP_ADDRESS_CHECK_RESPONSE'
export const ADMINISTRATION_USER_IP_ADDRESS_CHECK_FAILURE = '[ADMINISTRATION]_USER_IP_ADDRESS_CHECK_FAILURE'

export const ADMINISTRATION_ABRA_COMMANDS_LIST_REQUEST = '[ADMINISTRATION]_ABRA_COMMANDS_LIST_REQUEST'
export const ADMINISTRATION_ABRA_COMMANDS_LIST_RESPONSE = '[ADMINISTRATION]_ABRA_COMMANDS_LIST_RESPONSE'
export const ADMINISTRATION_ABRA_COMMANDS_LIST_FAILURE = '[ADMINISTRATION]_ABRA_COMMANDS_LIST_FAILURE'

export const ADMINISTRATION_UPDATE_USER_THERMAL_PRINTER_BY_ADMIN_REQUEST = '[ADMINISTRATION]_UPDATE_USER_THERMAL_PRINTER_BY_ADMIN_REQUEST'
export const ADMINISTRATION_UPDATE_USER_THERMAL_PRINTER_BY_ADMIN_RESPONSE = '[ADMINISTRATION]_UPDATE_USER_THERMAL_PRINTER_BY_ADMIN_RESPONSE'
export const ADMINISTRATION_UPDATE_USER_THERMAL_PRINTER_BY_ADMIN_FAILURE = '[ADMINISTRATION]_UPDATE_USER_THERMAL_PRINTER_BY_ADMIN_FAILURE'

export const ADMINISTRATION_UPDATE_USER_A4_PRINTER_BY_ADMIN_REQUEST = 'ADMINISTRATION_UPDATE_USER_A4_PRINTER_BY_ADMIN_REQUEST'
export const ADMINISTRATION_UPDATE_USER_A4_PRINTER_BY_ADMIN_RESPONSE = 'ADMINISTRATION_UPDATE_USER_A4_PRINTER_BY_ADMIN_RESPONSE'
export const ADMINISTRATION_UPDATE_USER_A4_PRINTER_BY_ADMIN_FAILURE = 'ADMINISTRATION_UPDATE_USER_A4_PRINTER_BY_ADMIN_FAILURE'

export const ADMINISTRATION_AIRSHOP_NOTIFICATIONS_BACKEND_ERROR_REQUEST = '[ADMINISTRATION]_AIRSHOP_NOTIFICATIONS_BACKEND_ERROR_REQUEST'
export const ADMINISTRATION_AIRSHOP_NOTIFICATIONS_BACKEND_ERROR_RESPONSE = '[ADMINISTRATION]_AIRSHOP_NOTIFICATIONS_BACKEND_ERROR_RESPONSE'
export const ADMINISTRATION_AIRSHOP_NOTIFICATIONS_BACKEND_ERROR_FAILURE = '[ADMINISTRATION]_AIRSHOP_NOTIFICATIONS_BACKEND_ERROR_FAILURE'

export const ADMINISTRATION_TEMPLATES_REQUEST = '[ADMINISTRATION]_TEMPLATES_REQUEST'
export const ADMINISTRATION_TEMPLATES_RESPONSE = '[ADMINISTRATION]_TEMPLATES_RESPONSE'
export const ADMINISTRATION_TEMPLATES_FAILURE = '[ADMINISTRATION]_TEMPLATES_FAILURE'

export const ADMINISTRATION_TEMPLATE_REQUEST = '[ADMINISTRATION]_TEMPLATE_REQUEST'
export const ADMINISTRATION_TEMPLATE_RESPONSE = '[ADMINISTRATION]_TEMPLATE_RESPONSE'
export const ADMINISTRATION_TEMPLATE_FAILURE = '[ADMINISTRATION]_TEMPLATE_FAILURE'
export const ADMINISTRATION_TEMPLATE_RESET = '[ADMINISTRATION]_TEMPLATE_RESET'

export const ADMINISTRATION_CREATE_TEMPLATE_REQUEST = '[ADMINISTRATION]_CREATE_TEMPLATE_REQUEST'
export const ADMINISTRATION_CREATE_TEMPLATE_RESPONSE = '[ADMINISTRATION]_CREATE_TEMPLATE_RESPONSE'
export const ADMINISTRATION_CREATE_TEMPLATE_FAILURE = '[ADMINISTRATION]_CREATE_TEMPLATE_FAILURE'

export const ADMINISTRATION_EDIT_TEMPLATE_REQUEST = '[ADMINISTRATION]_EDIT_TEMPLATE_REQUEST'
export const ADMINISTRATION_EDIT_TEMPLATE_RESPONSE = '[ADMINISTRATION]_EDIT_TEMPLATE_RESPONSE'
export const ADMINISTRATION_EDIT_TEMPLATE_FAILURE = '[ADMINISTRATION]_EDIT_TEMPLATE_FAILURE'

export const ADMINISTRATION_DELETE_TEMPLATE_REQUEST = '[ADMINISTRATION]_DELETE_TEMPLATE_REQUEST'
export const ADMINISTRATION_DELETE_TEMPLATE_RESPONSE = '[ADMINISTRATION]_DELETE_TEMPLATE_RESPONSE'
export const ADMINISTRATION_DELETE_TEMPLATE_FAILURE = '[ADMINISTRATION]_DELETE_TEMPLATE_FAILURE'

export const ADMINISTRATION_SEND_TEST_EMAIL_REQUEST = '[ADMINISTRATION]_SEND_TEST_EMAIL_REQUEST'
export const ADMINISTRATION_SEND_TEST_EMAIL_RESPONSE = '[ADMINISTRATION]_SEND_TEST_EMAIL_RESPONSE'
export const ADMINISTRATION_SEND_TEST_EMAIL_FAILURE = '[ADMINISTRATION]_SEND_TEST_EMAIL_FAILURE'

export const ADMINISTRATION_TEMPLATE_SYSTEMS_REQUEST = '[ADMINISTRATION]_TEMPLATE_SYSTEMS_REQUEST'
export const ADMINISTRATION_TEMPLATE_SYSTEMS_RESPONSE = '[ADMINISTRATION]_TEMPLATE_SYSTEMS_RESPONSE'
export const ADMINISTRATION_TEMPLATE_SYSTEMS_FAILURE = '[ADMINISTRATION]_TEMPLATE_SYSTEMS_FAILURE'
