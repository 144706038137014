import { useAuthFacade } from 'redux-store/auth/auth.facade'
// Checks, whether user has all the roles provided to the hook
// Accepts single role as string or single/multiple roles as array of strings e.g. 'user' / ['user', 'admin']
export const useUserRoles = (roleInput) => {
  const { idm } = useAuthFacade();

  const hasUserAccess = (roleInput) => {
    let input;

    if (typeof roleInput === 'string') {
      input = [roleInput];
    } else if (Array.isArray(roleInput)) {
      input = roleInput;
    }

    if (!input) {
      return false;
    }


    if (idm) {
      let containsNecessaryRoles = true;
      const { roleName } = idm;
      input.forEach(role => {
        if (idm && roleName && !(roleName.split(',').includes(role))) {
          containsNecessaryRoles = false;
        }
      });
      // Could be true
      return containsNecessaryRoles;
    }
    // Never true
    return false
  }

  return { hasUserAccess }

}
