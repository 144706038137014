import { AnyAction } from 'redux'
import { IUserState } from 'models'
import * as actionTypes from './user.actionTypes'

const initialState: IUserState = {
  profileSetup: null,
  profileSetupFull: null,
  userOrdersStateDeliveryCombinations: [],
  resMessage: null,
  error: null,
  fetching: false,
};

const userReducer = (state: IUserState = initialState, action: AnyAction) => {
  switch (action.type) {
    case actionTypes.USER_PROFILE_SETUP_REQUEST:
    case actionTypes.USER_PROFILE_SETUP_CREATE_PROP_REQUEST:
    case actionTypes.USER_PROFILE_SETUP_UPDATE_REQUEST:
    case actionTypes.USER_PROFILE_SETUP_DELETE_PROP_REQUEST:
      return {
        ...state,
        fetching: true,
      };
    case actionTypes.USER_PROFILE_SETUP_RESPONSE:
      return {
        ...state,
        profileSetup: action.payload.profileSetup,
        profileSetupFull: action.payload.profileSetupFull,
        userOrdersStateDeliveryCombinations: action.payload.userOrdersStateDeliveryCombinations,
        fetching: false,
      };
    case actionTypes.USER_PROFILE_SETUP_CREATE_PROP_RESPONSE:
    case actionTypes.USER_PROFILE_SETUP_DELETE_PROP_RESPONSE:
      return {
        ...state,
        fetching: false,
      };
    case actionTypes.USER_PROFILE_SETUP_UPDATE_RESPONSE:
      return {
        ...state,
        resMessage: action.payload.resMessage,
        fetching: false,
      };
    case actionTypes.USER_PROFILE_SETUP_FAILURE:
    case actionTypes.USER_PROFILE_SETUP_CREATE_PROP_FAILURE:
    case actionTypes.USER_PROFILE_SETUP_UPDATE_FAILURE:
    case actionTypes.USER_PROFILE_SETUP_DELETE_PROP_FAILURE:
      return {
        ...state,
        error: {...action.payload},
        fetching: false,
      };
    case actionTypes.USER_RESPONSE_MESSAGE_RESET:
      return {
        ...state,
        resMessage: null,
      };
    default:
      return state;
  }
};

export default userReducer;
