import { useSelector } from 'react-redux';

export function useOrdersSelectors() {
  const selectOrdersPageData = useSelector(state => state.orders.ordersPageData);
  const selectOrdersTotal = useSelector(state => state.orders.total);
  const selectOrdersPageDraw = useSelector(state => state.orders.ordersPageDraw);
  const selectSelectedOrder = useSelector(state => state.orders.selectedOrder);
  const selectSelectedOrderFirm = useSelector(state => state.orders.selectedOrderFirm);
  const selectResMessage = useSelector(state => state.orders.resMessage);
  const selectSelectedOrderPartnerMessages = useSelector(state => state.orders.selectedOrderPartnerMessages);
  const selectSelectedOrderAddresses = useSelector(state => state.orders.selectedOrderAddresses);
  const selectSelectedOrderDeliveryList = useSelector(state => state.orders.selectedOrderDeliveryList);
  const selectRedOrderStateFoldersCounts = useSelector(state => state.orders.redOrderStateFoldersCounts);
  const selectSelectedOrderBalikobotStatusLogs = useSelector(state => state.orders.selectedOrderBalikobotStatusLogs);
  const selectSelectedOrderAbraCommands =  useSelector(state => state.orders.selectedOrderAbraCommands);
  const selectSelectedOrderAbraCommandsErrors = useSelector(state => state.orders.selectedOrderAbraCommandsErrors);
  const selectSelectedOrderSubscriptions = useSelector(state => state.orders.selectedOrderSubscriptions);

  const selectOrderDetailProductsPageData = useSelector(state => state.orders.selectedOrderProducts.productsPageData);
  const selectOrderDetailProductsPageDraw = useSelector(state => state.orders.selectedOrderProducts.productsPageDraw);
  const selectOrderDetailProductsPagePage = useSelector(state => state.orders.selectedOrderProducts.productsPagePage);
  const selectOrderDetailProductsTotal = useSelector(state => state.orders.selectedOrderProducts.total);
  const selectOrderDetailRelatedStoreCards = useSelector(state => state.orders.selectedOrderProducts.relatedStoreCards);

  const selectTransportationTypes = useSelector(state => state.orders.transportationTypes);
  const selectTransportationTypesDraftValid = useSelector(state => state.orders.transportationTypesDraftValid);
  const selectStavObjednavky = useSelector(state => state.orders.stavObjednavky);
  const selectOrdersProductsPdfList = useSelector(state => state.orders.pdfList.ordersProductsPdfList);
  const selectOrdersFirmsPdfList = useSelector(state => state.orders.pdfList.ordersFirmsPdfList);
  const selectOrdersStoreCardsList = useSelector(state => state.orders.pdfList.ordersStoreCardsPdfList);
  const selectPartners = useSelector(state => state.orders.partners);
  const selectSelectedOrderSyncStatuses = useSelector(state => state.orders.selectedOrderSyncStatuses);
  const selectGeneratedPdfDocument = useSelector(state => state.orders.generatedPdfDocument);
  const selectQueuedCollectionLists = useSelector(state => state.orders.queuedCollectionLists);
  const selectBalikobotOrderEntry = useSelector(state => state.orders.balikobotOrderEntry);
  const selectBalikobotOrderBranch = useSelector(state => state.orders.balikobotOrderBranch);
  const selectBranchesByShipperId = useSelector(state => state.orders.branchesByShipperId);
  const selectServiceTypesByShipperId = useSelector(state => state.orders.serviceTypesByShipperId);
  const selectOrdersCountByEmail = useSelector(state => state.orders.ordersCountByEmail);
  const selectSubscriptions = useSelector(state => state.orders.subscriptions);
  const selectSourceSubscriptionsByIndex = useSelector(state => state.orders.sourceSubscriptionsByIndex);
  const selectSelectedOrderAddresses_NEW_VERSION = useSelector(state => state.orders.selectedOrderAddresses_NEW_VERSION);

  return {
    selectOrdersPageData,
    selectOrdersTotal,
    selectOrdersPageDraw,
    selectSelectedOrder,
    selectSelectedOrderFirm,
    selectSelectedOrderDeliveryList,
    selectSelectedOrderPartnerMessages,
    selectSelectedOrderAddresses,
    selectSelectedOrderAbraCommands,
    selectTransportationTypes,
    selectTransportationTypesDraftValid,
    selectStavObjednavky,
    selectOrderDetailProductsPageData,
    selectOrderDetailProductsPageDraw,
    selectOrderDetailProductsPagePage,
    selectOrderDetailProductsTotal,
    selectOrderDetailRelatedStoreCards,
    selectOrdersProductsPdfList,
    selectOrdersFirmsPdfList,
    selectOrdersStoreCardsList,
    selectResMessage,
    selectPartners,
    selectSelectedOrderSyncStatuses,
    selectGeneratedPdfDocument,
    selectRedOrderStateFoldersCounts,
    selectQueuedCollectionLists,
    selectBalikobotOrderEntry,
    selectBalikobotOrderBranch,
    selectBranchesByShipperId,
    selectSelectedOrderBalikobotStatusLogs,
    selectServiceTypesByShipperId,
    selectOrdersCountByEmail,
    selectSelectedOrderAbraCommandsErrors,
    selectSubscriptions,
    selectSelectedOrderSubscriptions,
    selectSourceSubscriptionsByIndex,
    selectSelectedOrderAddresses_NEW_VERSION,
  }
}
