import * as actionTypes from './user.actionTypes'
import * as actions from 'redux-store/user/user.actions'
import { AnyAction } from 'redux'
import { AirshopApi } from '../../CraftApi.axios'
import { from, of, concat } from 'rxjs'
import { mergeMap, takeUntil, catchError, map } from 'rxjs/operators'
import { ofType } from 'redux-observable'
import { IProfileSetupHandledResponse, EProfileSetupKeys } from 'models'
import { err, errMessages } from 'redux-store/utils/epics.utils'

export const fetchUserProfileSetupEpic = (action$: AnyAction, state$: any) =>
  action$.pipe(
    ofType(actionTypes.USER_PROFILE_SETUP_REQUEST),
    mergeMap(action => from(AirshopApi.get('partnerProfileSetup/partnersId', {
      params: { partnersId: state$.value.user.userId }
    }))
      .pipe(
          map(res => res.data.status === 'OK' ?
            handleFetchProfileSetupResponse(res.data.items)
          : err(errMessages.NOT_OK, res)),
          map(profileSetup => actions.userProfileSetupResponse(profileSetup)),
          catchError(error => of(actions.userProfileSetupFailure(error))),
          takeUntil(action$.ofType(
            actionTypes.USER_PROFILE_SETUP_RESPONSE ||
              actionTypes.USER_PROFILE_SETUP_FAILURE
          ))
      )
    )
  );

const handleFetchProfileSetupResponse = (responseItems: any[]) => {
  const handledResponse: IProfileSetupHandledResponse = {
    profileSetupFull: responseItems,
    profileSetup: {},
    userOrdersStateDeliveryCombinations: [],
  };

  responseItems.forEach((item: any) => {
    let val;

    if (item.key === EProfileSetupKeys.ORDERS_STATE_AND_DELIVERY_COMBINATION_OPTION) {
      val = JSON.parse(item.value)
      val.propUid = item.uid
      val.propId = item.id
      handledResponse.userOrdersStateDeliveryCombinations.push(val)
    } else {
      if (item.value === 'true') {
        val = true;
      } else if (item.value === 'false') {
        val = false;
      } else {
        val = item.value;
      }
      handledResponse.profileSetup[item.key] = val;
    }
  });

  return handledResponse;
}

export const createUserProfileSetupPropEpic = (action$: AnyAction) =>
  action$.pipe(
    ofType(actionTypes.USER_PROFILE_SETUP_CREATE_PROP_REQUEST),
    mergeMap((action: AnyAction) => from(AirshopApi.post('partnerProfileSetup', action.payload))
      .pipe(
          map(res => res.data.status === 'OK' ? res.data : err(errMessages.NOT_OK, res)),
          mergeMap(response => concat(
            of(actions.userProfileSetupCreatePropResponse(response)),
            of({type: actionTypes.USER_PROFILE_SETUP_REQUEST})
          )),
          catchError(error => of(actions.userProfileSetupCreatePropFailure(error))),
          takeUntil(action$.ofType(
            actionTypes.USER_PROFILE_SETUP_REQUEST ||
              actionTypes.USER_PROFILE_SETUP_CREATE_PROP_FAILURE
          ))
      )
    )
  );

export const updateUserProfileSetupEpic = (action$: AnyAction) =>
  action$.pipe(
    ofType(actionTypes.USER_PROFILE_SETUP_UPDATE_REQUEST),
    mergeMap((action: AnyAction) => from(AirshopApi.put('partnerProfileSetup', action.payload, {
      params: {
        partnerProfileSetupId: action.payload.id
      }
    }))
      .pipe(
          map(res => res.data.status === 'OK' ? res.data : err(errMessages.NOT_OK, res)),
          mergeMap(response => concat(
            of(actions.userProfileSetupUpdateResponse(
              response,
              actionTypes.USER_PROFILE_SETUP_UPDATE_RESPONSE
            )),
            of({type: actionTypes.USER_RESPONSE_MESSAGE_RESET}),
            of({type: actionTypes.USER_PROFILE_SETUP_REQUEST})
          )),
          catchError(error => of(actions.userProfileSetupUpdateFailure(error))),
          takeUntil(action$.ofType(
            actionTypes.USER_PROFILE_SETUP_REQUEST ||
              actionTypes.USER_PROFILE_SETUP_UPDATE_FAILURE
          ))
      )
    )
  );

export const deleteUserProfileSetupPropEpic = (action$: AnyAction) =>
  action$.pipe(
    ofType(actionTypes.USER_PROFILE_SETUP_DELETE_PROP_REQUEST),
    mergeMap((action: AnyAction) => from(AirshopApi.delete('partnerProfileSetup', {
      params: {
        partnerProfileSetupId: action.payload
      }
    }))
      .pipe(
          map(res => res.data.status === 'OK' ? res.data : err(errMessages.NOT_OK, res)),
          mergeMap(response => concat(
            of(actions.userProfileSetupDeletePropResponse(response)),
            of({type: actionTypes.USER_RESPONSE_MESSAGE_RESET}),
            of({type: actionTypes.USER_PROFILE_SETUP_REQUEST})
          )),
          catchError(error => of(actions.userProfileSetupDeletePropFailure(error))),
          takeUntil(action$.ofType(
            actionTypes.USER_PROFILE_SETUP_DELETE_PROP_REQUEST ||
              actionTypes.USER_PROFILE_SETUP_DELETE_PROP_FAILURE
          ))
      )
    )
  );
