export const MAILCHIMP_RESPONSE_MESSAGE_RESET = '[MAILCHIMP]_RESPONSE_MESSAGE_RESET'

export const MAILCHIMP_ORDERS_LIST_REQUEST = '[MAILCHIMP]_ORDERS_LIST_REQUEST'
export const MAILCHIMP_ORDERS_LIST_RESPONSE = '[MAILCHIMP]_ORDERS_LIST_RESPONSE'
export const MAILCHIMP_ORDERS_LIST_FAILURE = '[MAILCHIMP]_ORDERS_LIST_FAILURE'

export const MAILCHIMP_TAGS_LIST_BY_SHOP_REQUEST = '[MAILCHIMP]_TAGS_LIST_BY_SHOP_REQUEST'
export const MAILCHIMP_TAGS_LIST_BY_SHOP_RESPONSE = '[MAILCHIMP]_TAGS_LIST_BY_SHOP_RESPONSE'
export const MAILCHIMP_TAGS_LIST_BY_SHOP_FAILURE = '[MAILCHIMP]_TAGS_LIST_BY_SHOP_FAILURE'

export const MAILCHIMP_BULK_EMAILS_TO_EXISTING_TAG_BY_SHOP_REQUEST = '[MAILCHIMP]_BULK_EMAILS_TO_EXISTING_TAG_BY_SHOP_REQUEST'
export const MAILCHIMP_BULK_EMAILS_TO_EXISTING_TAG_BY_SHOP_RESPONSE = '[MAILCHIMP]_BULK_EMAILS_TO_EXISTING_TAG_BY_SHOP_RESPONSE'
export const MAILCHIMP_BULK_EMAILS_TO_EXISTING_TAG_BY_SHOP_FAILURE = '[MAILCHIMP]_BULK_EMAILS_TO_EXISTING_TAG_BY_SHOP_FAILURE'

export const MAILCHIMP_BULK_EMAILS_TO_NEW_TAG_BY_SHOP_REQUEST = '[MAILCHIMP]_BULK_EMAILS_TO_NEW_TAG_BY_SHOP_REQUEST'
export const MAILCHIMP_BULK_EMAILS_TO_NEW_TAG_BY_SHOP_RESPONSE = '[MAILCHIMP]_BULK_EMAILS_TO_NEW_TAG_BY_SHOP_RESPONSE'
export const MAILCHIMP_BULK_EMAILS_TO_NEW_TAG_BY_SHOP_FAILURE = '[MAILCHIMP]_BULK_EMAILS_TO_NEW_TAG_BY_SHOP_FAILURE'
