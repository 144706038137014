import React from 'react'
import { CHeaderNavLink, CHeaderNavItem } from '@coreui/react'
import { useIsAdmin } from 'hooks/useIsAdmin'

const HeaderLink = ({restrictedToAdmins=false, currentActive, setActive, activeKey, ...rest}) => {
  const { isAdmin } = useIsAdmin();

  return ((restrictedToAdmins && isAdmin) || !restrictedToAdmins) ? (
    <CHeaderNavItem className="px-3" onClick={() => setActive(activeKey)}>
      <CHeaderNavLink {...rest} className={currentActive === activeKey ? 'active' : ''}>
        {rest.children}
      </CHeaderNavLink>
    </CHeaderNavItem>
  ) : null
}

export default HeaderLink
