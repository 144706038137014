import { useSelector } from 'react-redux'

export function useUserChecksSelectors() {
  const selectUserIPAddress          = useSelector(state => state.userChecks.userIPAddress);
  const selectShopTypeIpAddresses    = useSelector(state => state.userChecks.shopTypeIpAddresses);
  const selectGeneralTypeIpAddresses = useSelector(state => state.userChecks.generalTypeIpAddresses);

  return {
    selectUserIPAddress,
    selectShopTypeIpAddresses,
    selectGeneralTypeIpAddresses,
  }
}
