import * as actionTypes from './transfers.actionTypes';

const initialState = {
  transfersPageData: null,
  transfersPageDraw: null,
  transfersPagePage: 0,
  selectedTransferItems: {
    itemsPageData: null,
    itemsPageDraw: null,
    itemsPagePage: 0,
    itemsPageLimit: 20,
    itemsPageTotal: null,
    relatedStoreCards: null,
    newCreatedTransferItem: null,
  },
  total: null,
  transferWays: null,
  finishedTransfersBadgeCount: 0,
  generatedPdfDocument: null,
  salesRequests: null,
  salesRequestsItemsPageData: null,
  salesRequestsItemsPageDraw: null,
  salesRequestsItemsPagePage: 0,
  salesRequestsItemsPageLimit: 50,
  salesRequestsItemsTotal: null,
  relatedSRIStoreCards: {},
  salesRequestsItemsQueuedPageData: null,
  salesRequestsItemsQueuedPageDraw: null,
  salesRequestsItemsQueuedPagePage: 0,
  salesRequestsItemsQueuedPageLimit: 50,
  salesRequestsItemsQueuedTotal: null,
  salesRequestsItemsCheckedPageData: null,
  salesRequestsItemsCheckedPageDraw: null,
  salesRequestsItemsCheckedPagePage: 0,
  salesRequestsItemsCheckedPageLimit: 50,
  salesRequestsItemsCheckedTotal: null,
  resMessage: null,
  error: null,
  fetching: false,
}

const transfersReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.TRANSFERS_TRANSFER_WAYS_REQUEST:
    case actionTypes.TRANSFERS_CREATE_TRANSFER_REQUEST:
    case actionTypes.TRANSFERS_TRANSFER_CHANGE_STATUS_REQUEST:
    case actionTypes.TRANSFERS_TRANSFER_DETAIL_CREATE_ITEM_REQUEST:
    case actionTypes.TRANSFERS_TRANSFER_DETAIL_ITEM_UPDATE_QUANTITY_REQUEST:
    case actionTypes.TRANSFERS_CREATE_TRANSFER_DETAIL_ITEM_FROM_ORDER_REQUEST:
    case actionTypes.TRANSFERS_TRANSFER_DETAIL_ITEM_PROCESSING_REQUEST:
    case actionTypes.TRANSFERS_TRANSFER_DETAIL_ITEM_DELETE_REQUEST:
    case actionTypes.TRANSFERS_TRANSFER_DELETE_REQUEST:
    case actionTypes.TRANSFERS_TRANSFER_UPDATE_REQUEST:
    case actionTypes.TRANSFERS_TRANSFER_UPDATE_NOTE_REQUEST:
    case actionTypes.TRANSFERS_TRANSFER_ITEM_UPDATE_NOTE_REQUEST:
    case actionTypes.TRANSFERS_PRINT_DOCUMENT_REQUEST:
    case actionTypes.TRANSFERS_GENERATE_PDF_REQUEST:
    case actionTypes.TRANSFERS_SALES_REQUESTS_REQUEST:
    case actionTypes.TRANSFERS_DELETE_SALES_REQUESTS_ITEM_REQUEST:
    case actionTypes.TRANSFERS_UPDATE_SALES_REQUESTS_ITEM_REQUEST:
    case actionTypes.TRANSFERS_TRANSFER_DETAIL_ITEM_CHANGE_TRANSFER_QUEUE_REQUEST:
      return {
        ...state,
        fetching: true,
      };
    case actionTypes.TRANSFERS_SALES_REQUESTS_ITEMS_REQUEST:
      return {
        ...state,
        salesRequestsItemsPageDraw: action.payload.draw,
        salesRequestsItemsPagePage: action.payload.page,
        fetching: true,
      };
    case actionTypes.TRANSFERS_TRANSFERS_LIST_REQUEST:
      return {
        ...state,
        transfersPageDraw: action.payload.draw,
        transfersPagePage: action.payload.page,
        fetching: true,
      };
    case actionTypes.TRANSFERS_TRANSFER_DETAIL_ITEMS_REQUEST:
      return {
        ...state,
        selectedTransferItems: {
          ...state.selectedTransferItems,
          itemsPagePage: action.payload.page,
          itemsPageDraw: action.payload.draw,
          itemsPageLimit: action.payload.limit,
        },
        fetching: true,
      };
    case actionTypes.TRANSFERS_SALES_REQUESTS_ITEMS_QUEUED_AND_CHECKED_REQUEST: {
      let newPartialState = {};
      if (action.payload.fetchOptions.fetchQueuedList) {
        newPartialState = {
          ...newPartialState,
          salesRequestsItemsQueuedPageDraw: action.payload.queuedTableParams.draw,
          salesRequestsItemsQueuedPagePage: action.payload.queuedTableParams.page,
          salesRequestsItemsQueuedPageLimit: action.payload.queuedTableParams.limit,
        };
      }
      if (action.payload.fetchOptions.fetchCheckedList) {
        newPartialState = {
          ...newPartialState,
          salesRequestsItemsCheckedPageDraw: action.payload.checkedTableParams.draw,
          salesRequestsItemsCheckedPagePage: action.payload.checkedTableParams.page,
          salesRequestsItemsCheckedPageLimit: action.payload.checkedTableParams.limit,
        };
      }
      return {
        ...state,
        ...newPartialState,
        resMessage: null,
        fetching: true,
      }
    };
    case actionTypes.TRANSFERS_TRANSFER_WAYS_RESPONSE:
      return {
        ...state,
        transferWays: action.payload,
        fetching: false,
      };
    case actionTypes.TRANSFERS_TRANSFERS_LIST_RESPONSE:
      return {
        ...state,
        transfersPageData: action.payload.response.items,
        total: action.payload.response.total,
        finishedTransfersBadgeCount: action.payload.badgeTotal,
        fetching: false,
      };

    case actionTypes.TRANSFERS_TRANSFER_DETAIL_ITEMS_RESPONSE:
      return {
        ...state,
        selectedTransferItems: {
          ...state.selectedTransferItems,
          itemsPageData: action.payload.items,
          itemsPageTotal: action.payload.total,
          relatedStoreCards: action.payload.storeCards,
        },
        // many times, items are edited and use res message,
        // on refetch, reset the message
        resMessage: null,
        fetching: false,
      };
    case actionTypes.TRANSFERS_SALES_REQUESTS_ITEMS_RESPONSE:
      return {
        ...state,
        salesRequestsItemsPageData: action.payload.tableData.items,
        salesRequestsItemsTotal: action.payload.tableData.total,
        relatedSRIStoreCards: action.payload.storeCards,
        fetching: false,
      };
    case actionTypes.TRANSFERS_CREATE_TRANSFER_RESPONSE:
    case actionTypes.TRANSFERS_TRANSFER_CHANGE_STATUS_RESPONSE:
    case actionTypes.TRANSFERS_TRANSFER_DETAIL_ITEM_UPDATE_QUANTITY_RESPONSE:
    case actionTypes.TRANSFERS_TRANSFER_DETAIL_ITEM_PROCESSING_RESPONSE:
    case actionTypes.TRANSFERS_TRANSFER_DETAIL_ITEM_DELETE_RESPONSE:
    case actionTypes.TRANSFERS_TRANSFER_DELETE_RESPONSE:
    case actionTypes.TRANSFERS_TRANSFER_UPDATE_RESPONSE:
    case actionTypes.TRANSFERS_TRANSFER_UPDATE_NOTE_RESPONSE:
    case actionTypes.TRANSFERS_TRANSFER_ITEM_UPDATE_NOTE_RESPONSE:
    case actionTypes.TRANSFERS_PRINT_DOCUMENT_RESPONSE:
    case actionTypes.TRANSFERS_DELETE_SALES_REQUESTS_ITEM_RESPONSE:
    case actionTypes.TRANSFERS_UPDATE_SALES_REQUESTS_ITEM_RESPONSE:
    case actionTypes.TRANSFERS_TRANSFER_DETAIL_ITEM_CHANGE_TRANSFER_QUEUE_RESPONSE:
      return {
        ...state,
        resMessage: action.payload.resMessage,
        fetching: false,
      };
    case actionTypes.TRANSFERS_GENERATE_PDF_RESPONSE:
      return {
        ...state,
        generatedPdfDocument: action.payload.response,
        resMessage: action.payload.resMessage,
        fetching: false,
      };
    case actionTypes.TRANSFERS_SALES_REQUESTS_RESPONSE:
      return {
        ...state,
        salesRequests: action.payload,
        fetching: false,
      };
    case actionTypes.TRANSFERS_TRANSFER_DETAIL_CREATE_ITEM_RESPONSE:
    case actionTypes.TRANSFERS_CREATE_TRANSFER_DETAIL_ITEM_FROM_ORDER_RESPONSE:
      return {
        ...state,
        resMessage: action.payload.resMessage,
        selectedTransferItems: {
          ...state.selectedTransferItems,
          newCreatedTransferItem: action.payload.response,
        },
        fetching: false,
      };
    case actionTypes.TRANSFERS_SALES_REQUESTS_ITEMS_QUEUED_AND_CHECKED_RESPONSE: {
      let newPartialState = {};
      if (action.payload.queuedList) {
        newPartialState = {
          ...newPartialState,
          salesRequestsItemsQueuedPageData: action.payload.queuedList.items,
          salesRequestsItemsQueuedTotal: action.payload.queuedList.total,
        };
      }
      if (action.payload.checkedList) {
        newPartialState = {
          ...newPartialState,
          salesRequestsItemsCheckedPageData: action.payload.checkedList.items,
          salesRequestsItemsCheckedTotal: action.payload.checkedList.total,
        };
      }
      return {
        ...state,
        ...newPartialState,
        relatedSRIStoreCards: action.payload.storeCardsById,
        fetching: false,
      }
    };
    case actionTypes.TRANSFERS_RESET_GENERATED_PDF:
      return {
        ...state,
        generatedPdfDocument: null
      };
    case actionTypes.TRANSFERS_CREATE_TRANSFER_FAILURE:
    case actionTypes.TRANSFERS_TRANSFER_CHANGE_STATUS_FAILURE:
    case actionTypes.TRANSFERS_TRANSFER_DETAIL_CREATE_ITEM_FAILURE:
    case actionTypes.TRANSFERS_TRANSFER_DETAIL_ITEM_UPDATE_QUANTITY_FAILURE:
    case actionTypes.TRANSFERS_CREATE_TRANSFER_DETAIL_ITEM_FROM_ORDER_FAILURE:
    case actionTypes.TRANSFERS_TRANSFER_DETAIL_ITEM_PROCESSING_FAILURE:
    case actionTypes.TRANSFERS_TRANSFER_DETAIL_ITEM_DELETE_FAILURE:
    case actionTypes.TRANSFERS_TRANSFER_DELETE_FAILURE:
    case actionTypes.TRANSFERS_TRANSFER_UPDATE_FAILURE:
    case actionTypes.TRANSFERS_TRANSFER_UPDATE_NOTE_FAILURE:
    case actionTypes.TRANSFERS_TRANSFER_ITEM_UPDATE_NOTE_FAILURE:
    case actionTypes.TRANSFERS_PRINT_DOCUMENT_FAILURE:
    case actionTypes.TRANSFERS_GENERATE_PDF_FAILURE:
    case actionTypes.TRANSFERS_DELETE_SALES_REQUESTS_ITEM_FAILURE:
    case actionTypes.TRANSFERS_UPDATE_SALES_REQUESTS_ITEM_FAILURE:
    case actionTypes.TRANSFERS_TRANSFER_DETAIL_ITEM_CHANGE_TRANSFER_QUEUE_FAILURE:
      return {
        ...state,
        resMessage: action.payload.resMessage,
        fetching: false,
        error: {...action.payload.error},
      };
    case actionTypes.TRANSFERS_TRANSFER_WAYS_FAILURE:
    case actionTypes.TRANSFERS_TRANSFERS_LIST_FAILURE:
    case actionTypes.TRANSFERS_TRANSFER_DETAIL_ITEMS_FAILURE:
    case actionTypes.TRANSFERS_SALES_REQUESTS_FAILURE:
    case actionTypes.TRANSFERS_SALES_REQUESTS_ITEMS_FAILURE:
    case actionTypes.TRANSFERS_SALES_REQUESTS_ITEMS_QUEUED_AND_CHECKED_FAILURE:
      return {
        ...state,
        fetching: false,
        error: {...action.payload}
      };
    case actionTypes.TRANSFERS_NULLIFY_NEW_CREATED_DETAIL_ITEM:
      return {
        ...state,
        selectedTransferItems: {
          ...state.selectedTransferItems,
          newCreatedTransferItem: null,
        },
      };
    case actionTypes.TRANSFERS_RESPONSE_MESSAGE_RESET:
      return {
        ...state,
        resMessage: null,
      };
    default:
      return state;
  }
};

export default transfersReducer;
