import { notificationTypes } from './legacy/native-notification-types'
import * as storeCardsTypes from 'redux-store/store-cards/store-cards.actionTypes'
import * as administrationTypes from 'redux-store/administration/administration.actionTypes'
import * as ordersTypes from 'redux-store/orders/orders.actionTypes'
import * as mailchimpTypes from 'redux-store/mailchimp/mailchimp.actionTypes'
import * as transfersTypes from 'redux-store/transfers/transfers.actionTypes'
import * as collectionListsTypes from 'redux-store/collection-lists/collection-lists.actionTypes'
import * as ratingTypes from 'redux-store/rating/rating.actionTypes'
import * as targetsActionsTypes from 'redux-store/targets/targets.actionTypes'
import * as rentActionsTypes from 'redux-store/rent/rent.actionTypes'

// Applied in section container, where notification are supposed to be
/*
 * In the container, where this component is used, create notification state
 * such as this:
 *
  const [notification, setNotification] = useState({
    show: false,
    itemName: 'Tag',
    type: null,
  });
 *
 * then create useEffect as well,
 * - resMessage => redux state property containing http response result
 *   (set in epics, stored in reducer)
 * - for notifications not related to http request (reset form),
 *   setup component property with state
 *
  useEffect(() => {
    formNotificationSwitch(resMessage, notification, setNotification);
  }, [resMessage]); // or [statefulProperty]
 *
 *
 */
export const formNotificationSwitch = (
  resMessage,
  notification,
  setNotification,
) => {
  switch (resMessage) {
    case notificationTypes.CREATE_SUCCESS:
      setNotification({
        ...notification,
        show: true,
        type: notificationTypes.CREATE_SUCCESS,
      });
      break;
    case notificationTypes.CREATE_FAILURE:
      setNotification({
        ...notification,
        show: true,
        type: notificationTypes.CREATE_FAILURE,
      });
      break;
    case notificationTypes.ACCESS_FAILURE:
      setNotification({
        ...notification,
        show: true,
        type: notificationTypes.ACCESS_FAILURE,
      });
      break;
    case notificationTypes.EDIT_SUCCESS:
      setNotification({
        ...notification,
        show: true,
        type: notificationTypes.EDIT_SUCCESS,
      });
      break;
    case notificationTypes.EDIT_FAILURE:
      setNotification({
        ...notification,
        show: true,
        type: notificationTypes.EDIT_FAILURE,
      });
      break;
    case notificationTypes.DELETE_SUCCESS:
      setNotification({
        ...notification,
        show: true,
        type: notificationTypes.DELETE_SUCCESS,
      });
      break;
    case notificationTypes.DELETE_FAILURE:
      setNotification({
        ...notification,
        show: true,
        type: notificationTypes.DELETE_FAILURE,
      });
      break;
    case notificationTypes.RESET_FORM_SUCCESS:
      setNotification({
        ...notification,
        show: true,
        type: notificationTypes.RESET_FORM_SUCCESS,
      });
      break;
    case notificationTypes.RESET_FORM_FAILURE:
      setNotification({
        ...notification,
        show: true,
        type: notificationTypes.RESET_FORM_FAILURE,
      });
      break;
    case notificationTypes.SEND_M_SUCCESS:
      setNotification({
        ...notification,
        show: true,
        type: notificationTypes.SEND_M_SUCCESS,
      });
      break;
    case notificationTypes.SEND_M_FAILURE:
      setNotification({
        ...notification,
        show: true,
        type: notificationTypes.SEND_M_FAILURE,
      });
      break;
    case notificationTypes.SEND_F_SUCCESS:
      setNotification({
        ...notification,
        show: true,
        type: notificationTypes.SEND_F_SUCCESS,
      });
      break;
    case notificationTypes.SEND_F_FAILURE:
      setNotification({
        ...notification,
        show: true,
        type: notificationTypes.SEND_F_FAILURE,
      });
      break;
    case notificationTypes.CHANGE_PRODUCT_Q_SUCCESS:
      setNotification({
        ...notification,
        show: true,
        type: notificationTypes.CHANGE_PRODUCT_Q_SUCCESS,
      });
      break;
    case notificationTypes.CHANGE_PRODUCT_Q_FAILURE:
      setNotification({
        ...notification,
        show: true,
        type: notificationTypes.CHANGE_PRODUCT_Q_FAILURE,
      });
      break;
    case notificationTypes.SYNC_F_SUCCESS:
      setNotification({
        ...notification,
        show: true,
        type: notificationTypes.SYNC_F_SUCCESS,
      });
      break;
    case notificationTypes.SYNC_F_FAILURE:
      setNotification({
        ...notification,
        show: true,
        type: notificationTypes.SYNC_F_FAILURE,
      });
      break;
    case notificationTypes.DL_CREATE_SUCCESS:
      setNotification({
        ...notification,
        show: true,
        type: notificationTypes.DL_CREATE_SUCCESS,
      });
      break;
    case notificationTypes.DL_CREATE_FAILURE:
      setNotification({
        ...notification,
        show: false,
        type: notificationTypes.DL_CREATE_FAILURE,
      });
      break;
    case notificationTypes.DL_RECREATE_SUCCESS:
      setNotification({
        ...notification,
        show: true,
        type: notificationTypes.DL_RECREATE_SUCCESS,
      });
      break;
    case notificationTypes.DL_RECREATE_FAILURE:
      setNotification({
        ...notification,
        show: true,
        type: notificationTypes.DL_RECREATE_FAILURE,
      });
      break;
    case notificationTypes.CREATE_TRANSFER_SUCCESS:
      setNotification({
        ...notification,
        show: true,
        type: notificationTypes.CREATE_TRANSFER_SUCCESS,
      });
      break;
    case notificationTypes.CREATE_TRANSFER_FAILURE:
      setNotification({
        ...notification,
        show: true,
        type: notificationTypes.CREATE_TRANSFER_FAILURE,
      });
      break;
    case notificationTypes.TRANSFER_STATUS_CHANGE_SUCCESS:
      setNotification({
        ...notification,
        show: true,
        type: notificationTypes.TRANSFER_STATUS_CHANGE_SUCCESS,
      });
      break;
    case notificationTypes.TRANSFER_STATUS_CHANGE_FAILURE:
      setNotification({
        ...notification,
        show: true,
        type: notificationTypes.TRANSFER_STATUS_CHANGE_FAILURE,
      });
      break;
    case notificationTypes.TRANSFER_DETAIL_CREATE_ITEM_SUCCESS:
      setNotification({
        ...notification,
        show: false, // not supposed to be seen but kept because resMessage is used for logic
        type: notificationTypes.TRANSFER_DETAIL_CREATE_ITEM_SUCCESS,
      });
      break;
    case notificationTypes.TRANSFER_DETAIL_CREATE_ITEM_FAILURE:
      setNotification({
        ...notification,
        show: false, // not supposed to be seen but kept because resMessage is used for logic
        type: notificationTypes.TRANSFER_DETAIL_CREATE_ITEM_FAILURE,
      });
      break;
    case notificationTypes.TRANSFER_DETAIL_UPDATE_ITEM_QUANTITY_SUCCESS:
      setNotification({
        ...notification,
        show: false, // not supposed to be seen but kept because resMessage is used for logic
        type: notificationTypes.TRANSFER_DETAIL_UPDATE_ITEM_QUANTITY_SUCCESS,
      });
      break;
    case notificationTypes.TRANSFER_DETAIL_UPDATE_ITEM_QUANTITY_FAILURE:
      setNotification({
        ...notification,
        show: false, // not supposed to be seen but kept because resMessage is used for logic
        type: notificationTypes.TRANSFER_DETAIL_UPDATE_ITEM_QUANTITY_FAILURE,
      });
      break;
    case notificationTypes.TRANSFER_DETAIL_ITEM_FROM_ORDER_SUCCESS:
      setNotification({
        ...notification,
        show: true,
        type: notificationTypes.TRANSFER_DETAIL_ITEM_FROM_ORDER_SUCCESS,
      });
      break;
    case notificationTypes.TRANSFER_DETAIL_ITEM_FROM_ORDER_FAILURE:
      setNotification({
        ...notification,
        show: true,
        type: notificationTypes.TRANSFER_DETAIL_ITEM_FROM_ORDER_FAILURE,
      });
      break;
    case storeCardsTypes.STORE_CARDS_BULK_CHANGE_EXPORT_NULL_QUANTITY_RESPONSE:
      setNotification({
        ...notification,
        show: true,
        type: storeCardsTypes.STORE_CARDS_BULK_CHANGE_EXPORT_NULL_QUANTITY_RESPONSE,
      });
      break;
    case storeCardsTypes.STORE_CARDS_BULK_CHANGE_EXPORT_NULL_QUANTITY_FAILURE:
      setNotification({
        ...notification,
        show: true,
        type: storeCardsTypes.STORE_CARDS_BULK_CHANGE_EXPORT_NULL_QUANTITY_FAILURE,
      });
      break;
    case administrationTypes.ADMINISTRATION_IMPORT_FC_BY_SHOP_RESPONSE:
      setNotification({
        ...notification,
        show: true,
        type: administrationTypes.ADMINISTRATION_IMPORT_FC_BY_SHOP_RESPONSE,
      });
      break;
    case administrationTypes.ADMINISTRATION_IMPORT_FC_BY_SHOP_FAILURE:
      setNotification({
        ...notification,
        show: true,
        type: administrationTypes.ADMINISTRATION_IMPORT_FC_BY_SHOP_FAILURE,
      });
      break;
    case administrationTypes.ADMINISTRATION_SYNC_FC_DAYS_BACK_BY_SHOP_RESPONSE:
      setNotification({
        ...notification,
        show: true,
        type: administrationTypes.ADMINISTRATION_SYNC_FC_DAYS_BACK_BY_SHOP_RESPONSE,
      });
      break;
    case administrationTypes.ADMINISTRATION_SYNC_FC_DAYS_BACK_BY_SHOP_FAILURE:
      setNotification({
        ...notification,
        show: true,
        type: administrationTypes.ADMINISTRATION_SYNC_FC_DAYS_BACK_BY_SHOP_FAILURE,
      });
      break;
    case ordersTypes.ORDERS_UPDATE_STAV_OBJEDNAVKY_RESPONSE:
      setNotification({
        ...notification,
        show: true,
        type: ordersTypes.ORDERS_UPDATE_STAV_OBJEDNAVKY_RESPONSE,
      });
      break;
    case ordersTypes.ORDERS_UPDATE_STAV_OBJEDNAVKY_FAILURE:
      setNotification({
        ...notification,
        show: true,
        type: ordersTypes.ORDERS_UPDATE_STAV_OBJEDNAVKY_FAILURE,
      });
      break;
    case ordersTypes.ORDERS_SYNC_ABRA_WHILE_IN_ABRA_RESPONSE:
      setNotification({
        ...notification,
        show: true,
        type: ordersTypes.ORDERS_SYNC_ABRA_WHILE_IN_ABRA_RESPONSE,
      });
      break;
    case ordersTypes.ORDERS_SYNC_ABRA_WHILE_IN_ABRA_FAILURE:
      setNotification({
        ...notification,
        show: true,
        type: ordersTypes.ORDERS_SYNC_ABRA_WHILE_IN_ABRA_FAILURE,
      });
      break;
    case mailchimpTypes.MAILCHIMP_BULK_EMAILS_TO_EXISTING_TAG_BY_SHOP_RESPONSE:
      setNotification({
        ...notification,
        show: true,
        type: mailchimpTypes.MAILCHIMP_BULK_EMAILS_TO_EXISTING_TAG_BY_SHOP_RESPONSE,
      });
      break;
    case mailchimpTypes.MAILCHIMP_BULK_EMAILS_TO_EXISTING_TAG_BY_SHOP_FAILURE:
      setNotification({
        ...notification,
        show: true,
        type: mailchimpTypes.MAILCHIMP_BULK_EMAILS_TO_EXISTING_TAG_BY_SHOP_FAILURE,
      });
      break;
    case mailchimpTypes.MAILCHIMP_BULK_EMAILS_TO_NEW_TAG_BY_SHOP_RESPONSE:
      setNotification({
        ...notification,
        show: true,
        type: mailchimpTypes.MAILCHIMP_BULK_EMAILS_TO_NEW_TAG_BY_SHOP_RESPONSE,
      });
      break;
    case mailchimpTypes.MAILCHIMP_BULK_EMAILS_TO_NEW_TAG_BY_SHOP_FAILURE:
      setNotification({
        ...notification,
        show: true,
        type: mailchimpTypes.MAILCHIMP_BULK_EMAILS_TO_NEW_TAG_BY_SHOP_FAILURE,
      });
      break;
    case administrationTypes.ADMINISTRATION_UPDATE_USER_THERMAL_PRINTER_RESPONSE:
      setNotification({
        ...notification,
        show: true,
        type: administrationTypes.ADMINISTRATION_UPDATE_USER_THERMAL_PRINTER_RESPONSE,
      });
      break;
    case administrationTypes.ADMINISTRATION_UPDATE_USER_A4_PRINTER_RESPONSE:
      setNotification({
        ...notification,
        show: true,
        type: administrationTypes.ADMINISTRATION_UPDATE_USER_A4_PRINTER_RESPONSE,
      });
      break;
    case administrationTypes.ADMINISTRATION_UPDATE_USER_THERMAL_PRINTER_FAILURE:
      setNotification({
        ...notification,
        show: true,
        type: administrationTypes.ADMINISTRATION_UPDATE_USER_THERMAL_PRINTER_FAILURE,
      });
      break;
    case administrationTypes.ADMINISTRATION_UPDATE_USER_A4_PRINTER_FAILURE:
      setNotification({
        ...notification,
        show: true,
        type: administrationTypes.ADMINISTRATION_UPDATE_USER_A4_PRINTER_FAILURE,
      });
      break;
    case administrationTypes.ADMINISTRATION_UPDATE_USER_A4_PRINTER_BY_ADMIN_RESPONSE:
      setNotification({
        ...notification,
        show: true,
        type: administrationTypes.ADMINISTRATION_UPDATE_USER_A4_PRINTER_BY_ADMIN_RESPONSE,
      });
      break;
    case administrationTypes.ADMINISTRATION_UPDATE_USER_THERMAL_PRINTER_BY_ADMIN_RESPONSE:
      setNotification({
        ...notification,
        show: true,
        type: administrationTypes.ADMINISTRATION_UPDATE_USER_THERMAL_PRINTER_BY_ADMIN_RESPONSE,
      });
      break;
    case administrationTypes.ADMINISTRATION_UPDATE_USER_A4_PRINTER_BY_ADMIN_FAILURE:
      setNotification({
        ...notification,
        show: true,
        type: administrationTypes.ADMINISTRATION_UPDATE_USER_A4_PRINTER_BY_ADMIN_FAILURE,
      });
      break;
    case administrationTypes.ADMINISTRATION_UPDATE_USER_THERMAL_PRINTER_BY_ADMIN_FAILURE:
      setNotification({
        ...notification,
        show: true,
        type: administrationTypes.ADMINISTRATION_UPDATE_USER_THERMAL_PRINTER_BY_ADMIN_FAILURE,
      });
      break;
    case ordersTypes.ORDERS_ORDER_CREATE_ITEM_RESPONSE:
      setNotification({
        ...notification,
        show: true,
        type: ordersTypes.ORDERS_ORDER_CREATE_ITEM_RESPONSE,
      });
      break;
    case ordersTypes.ORDERS_ORDER_CREATE_ITEM_FAILURE:
      setNotification({
        ...notification,
        show: true,
        type: ordersTypes.ORDERS_ORDER_CREATE_ITEM_FAILURE,
      });
      break;
    case ordersTypes.ORDERS_UPDATE_BULK_ORDERS_STAV_OBJEDNAVKY_RESPONSE:
      setNotification({
        ...notification,
        show: true,
        type: ordersTypes.ORDERS_UPDATE_BULK_ORDERS_STAV_OBJEDNAVKY_RESPONSE,
      });
      break;
    case ordersTypes.ORDERS_UPDATE_BULK_ORDERS_STAV_OBJEDNAVKY_FAILURE:
      setNotification({
        ...notification,
        show: true,
        type: ordersTypes.ORDERS_UPDATE_BULK_ORDERS_STAV_OBJEDNAVKY_FAILURE,
      });
      break;
    case ordersTypes.ORDERS_GENERATE_PDF_FAILURE:
      setNotification({
        ...notification,
        show: true,
        type: ordersTypes.ORDERS_GENERATE_PDF_FAILURE,
      });
      break;
    case ordersTypes.ORDERS_MASS_GENERATE_PDF_FAILURE:
      setNotification({
        ...notification,
        show: true,
        type: ordersTypes.ORDERS_MASS_GENERATE_PDF_FAILURE,
      });
      break;
    case transfersTypes.TRANSFERS_GENERATE_PDF_FAILURE:
      setNotification({
        ...notification,
        show: true,
        type: transfersTypes.TRANSFERS_GENERATE_PDF_FAILURE,
      });
      break;
    case storeCardsTypes.STORE_CARDS_EDIT_STORE_CARD_RESPONSE:
      setNotification({
        ...notification,
        show: true,
        type: storeCardsTypes.STORE_CARDS_EDIT_STORE_CARD_RESPONSE,
      });
      break;
    case storeCardsTypes.STORE_CARDS_EDIT_STORE_CARD_FAILURE:
      setNotification({
        ...notification,
        show: true,
        type: storeCardsTypes.STORE_CARDS_EDIT_STORE_CARD_FAILURE,
      });
      break;
    case storeCardsTypes.STORE_CARDS_DELETE_EAN_RESPONSE:
      setNotification({
        ...notification,
        show: true,
        type: storeCardsTypes.STORE_CARDS_DELETE_EAN_RESPONSE,
      });
      break;
    case storeCardsTypes.STORE_CARDS_DELETE_EAN_FAILURE:
      setNotification({
        ...notification,
        show: true,
        type: storeCardsTypes.STORE_CARDS_DELETE_EAN_FAILURE,
      });
      break;
    case storeCardsTypes.STORE_CARDS_EDIT_MULTIPLE_STORE_CARDS_RESPONSE:
      setNotification({
        ...notification,
        show: true,
        type: storeCardsTypes.STORE_CARDS_EDIT_MULTIPLE_STORE_CARDS_RESPONSE,
      });
      break;
    case storeCardsTypes.STORE_CARDS_EDIT_MULTIPLE_STORE_CARDS_FAILURE:
      setNotification({
        ...notification,
        show: true,
        type: storeCardsTypes.STORE_CARDS_EDIT_MULTIPLE_STORE_CARDS_FAILURE,
      });
      break;
    case administrationTypes.ADMINISTRATION_UPDATE_IP_ADDRESS_RESPONSE_NR:
    case administrationTypes.ADMINISTRATION_UPDATE_IP_ADDRESS_RESPONSE:
      setNotification({
        ...notification,
        show: true,
        type: administrationTypes.ADMINISTRATION_UPDATE_IP_ADDRESS_RESPONSE,
      });
      break;
    case administrationTypes.ADMINISTRATION_UPDATE_IP_ADDRESS_FAILURE:
      setNotification({
        ...notification,
        show: true,
        type: administrationTypes.ADMINISTRATION_UPDATE_IP_ADDRESS_FAILURE,
      });
      break;
    case collectionListsTypes.COLLECTION_LISTS_COLLECTION_LIST_STATUS_CHANGE_RESPONSE:
      setNotification({
        ...notification,
        show: true,
        type: collectionListsTypes.COLLECTION_LISTS_COLLECTION_LIST_STATUS_CHANGE_RESPONSE,
      });
      break;
    case collectionListsTypes.COLLECTION_LISTS_COLLECTION_LIST_STATUS_CHANGE_FAILURE:
      setNotification({
        ...notification,
        show: true,
        type: collectionListsTypes.COLLECTION_LISTS_COLLECTION_LIST_STATUS_CHANGE_FAILURE,
      });
      break;
    case collectionListsTypes.COLLECTION_LISTS_LIST_DETAIL_ITEM_DELETE_RESPONSE:
      setNotification({
        ...notification,
        show: true,
        type: collectionListsTypes.COLLECTION_LISTS_LIST_DETAIL_ITEM_DELETE_RESPONSE,
      });
      break;
    case collectionListsTypes.COLLECTION_LISTS_LIST_DETAIL_ITEM_DELETE_FAILURE:
      setNotification({
        ...notification,
        show: true,
        type: collectionListsTypes.COLLECTION_LISTS_LIST_DETAIL_ITEM_DELETE_FAILURE,
      });
      break;
    case ordersTypes.ORDERS_CREATE_CLITEM_FROM_ORDER_RESPONSE:
      setNotification({
        ...notification,
        show: true,
        type: ordersTypes.ORDERS_CREATE_CLITEM_FROM_ORDER_RESPONSE,
      });
      break;
    case ordersTypes.ORDERS_CREATE_CLITEM_FROM_ORDER_FAILURE:
      setNotification({
        ...notification,
        show: true,
        type: ordersTypes.ORDERS_CREATE_CLITEM_FROM_ORDER_FAILURE,
      });
      break;
    case ordersTypes.ORDERS_CREATE_BALIKOBOT_ORDER_ENTRY_RESPONSE:
      setNotification({
        ...notification,
        show: true,
        type: ordersTypes.ORDERS_CREATE_BALIKOBOT_ORDER_ENTRY_RESPONSE,
      });
      break;
    case ordersTypes.ORDERS_CREATE_BALIKOBOT_ORDER_ENTRY_FAILURE:
      setNotification({
        ...notification,
        show: true,
        type: ordersTypes.ORDERS_CREATE_BALIKOBOT_ORDER_ENTRY_FAILURE,
      });
      break;
    case ordersTypes.ORDERS_UPDATE_BALIKOBOT_ORDER_ENTRY_RESPONSE:
      setNotification({
        ...notification,
        show: true,
        type: ordersTypes.ORDERS_UPDATE_BALIKOBOT_ORDER_ENTRY_RESPONSE,
      });
      break;
    case ordersTypes.ORDERS_UPDATE_BALIKOBOT_ORDER_ENTRY_FAILURE:
      setNotification({
        ...notification,
        show: true,
        type: ordersTypes.ORDERS_UPDATE_BALIKOBOT_ORDER_ENTRY_FAILURE,
      });
      break;
    case ordersTypes.ORDERS_GENERATE_BALIKOBOT_TAG_RESPONSE:
      setNotification({
        ...notification,
        show: true,
        type: ordersTypes.ORDERS_GENERATE_BALIKOBOT_TAG_RESPONSE,
      });
      break;
    case ordersTypes.ORDERS_GENERATE_BALIKOBOT_TAG_FAILURE:
      setNotification({
        ...notification,
        show: true,
        type: ordersTypes.ORDERS_GENERATE_BALIKOBOT_TAG_FAILURE,
      });
      break;
    case ordersTypes.ORDERS_CREATE_CLITEMS_FROM_MULTIPLE_ORDERS_RESPONSE:
      setNotification({
        ...notification,
        show: true,
        type: ordersTypes.ORDERS_CREATE_CLITEMS_FROM_MULTIPLE_ORDERS_RESPONSE,
      });
      break;
    case ordersTypes.ORDERS_CREATE_CLITEMS_FROM_MULTIPLE_ORDERS_FAILURE:
      setNotification({
        ...notification,
        show: true,
        type: ordersTypes.ORDERS_CREATE_CLITEMS_FROM_MULTIPLE_ORDERS_FAILURE,
      });
      break;
    case ordersTypes.ORDERS_CREATE_ORDER_SUBSCRIPTION_RESPONSE:
    case ordersTypes.ORDERS_MULTIPLE_CREATE_ORDER_SUBSCRIPTION_RESPONSE:
    case ordersTypes.ORDERS_CREATE_ORDER_SUBSCRIPTION_RESPONSE + '_SUBORDER':
      setNotification({
        ...notification,
        show: true,
        type: ordersTypes.ORDERS_CREATE_ORDER_SUBSCRIPTION_RESPONSE,
      });
      break;
    case ordersTypes.ORDERS_CREATE_ORDER_SUBSCRIPTION_FAILURE:
    case ordersTypes.ORDERS_MULTIPLE_CREATE_ORDER_SUBSCRIPTION_FAILURE:
      setNotification({
        ...notification,
        show: true,
        type: ordersTypes.ORDERS_CREATE_ORDER_SUBSCRIPTION_FAILURE,
      });
      break;
    case ordersTypes.ORDERS_DELETE_ORDER_SUBSCRIPTION_RESPONSE:
      setNotification({
        ...notification,
        show: true,
        type: ordersTypes.ORDERS_DELETE_ORDER_SUBSCRIPTION_RESPONSE,
      });
      break;
    case ordersTypes.ORDERS_DELETE_ORDER_SUBSCRIPTION_FAILURE:
      setNotification({
        ...notification,
        show: true,
        type: ordersTypes.ORDERS_DELETE_ORDER_SUBSCRIPTION_FAILURE,
      });
      break;
    case ordersTypes.ORDERS_EDIT_ORDER_SUBSCRIPTION_RESPONSE:
    setNotification({
      ...notification,
      show: true,
      type: ordersTypes.ORDERS_EDIT_ORDER_SUBSCRIPTION_RESPONSE,
    });
    break;
    case ordersTypes.ORDERS_EDIT_ORDER_SUBSCRIPTION_FAILURE:
    setNotification({
      ...notification,
      show: true,
      type: ordersTypes.ORDERS_EDIT_ORDER_SUBSCRIPTION_FAILURE,
    });
    break;
    case ordersTypes.ORDERS_SET_SELECTED_ORDER_ADDRESS_RESPONSE:
      setNotification({
        ...notification,
        show: true,
        type: ordersTypes.ORDERS_SET_SELECTED_ORDER_ADDRESS_RESPONSE,
      });
      break;
    case ordersTypes.ORDERS_SET_SELECTED_ORDER_ADDRESS_FAILURE:
      setNotification({
        ...notification,
        show: true,
        type: ordersTypes.ORDERS_SET_SELECTED_ORDER_ADDRESS_FAILURE,
      });
      break;
    case ratingTypes.RATING_TOGGLE_ACTIVE_BEER_BY_ID_RESPONSE:
      setNotification({
        ...notification,
        show: true,
        type: ratingTypes.RATING_TOGGLE_ACTIVE_BEER_BY_ID_RESPONSE,
      });
      break;
    case ratingTypes.RATING_TOGGLE_ACTIVE_BEER_BY_ID_FAILURE:
      setNotification({
        ...notification,
        show: true,
        type: ratingTypes.RATING_TOGGLE_ACTIVE_BEER_BY_ID_FAILURE,
      });
      break;
    case ratingTypes.RATING_BEER_WITH_WEBSITES_MULTI_REQUESTS_RESPONSE:
      setNotification({
        ...notification,
        show: true,
        type: ratingTypes.RATING_BEER_WITH_WEBSITES_MULTI_REQUESTS_RESPONSE,
      });
      break;
    case ratingTypes.RATING_BEER_WITH_WEBSITES_MULTI_REQUESTS_FAILURE:
      setNotification({
        ...notification,
        show: true,
        type: ratingTypes.RATING_BEER_WITH_WEBSITES_MULTI_REQUESTS_FAILURE,
      });
      break;
    case ratingTypes.RATING_DELETE_BEER_BY_ID_RESPONSE:
      setNotification({
        ...notification,
        show: true,
        type: ratingTypes.RATING_DELETE_BEER_BY_ID_RESPONSE,
      });
      break;
    case ratingTypes.RATING_DELETE_BEER_BY_ID_FAILURE:
      setNotification({
        ...notification,
        show: true,
        type: ratingTypes.RATING_DELETE_BEER_BY_ID_FAILURE,
      });
      break;
    case targetsActionsTypes.TARGETS_CREATE_MULTIPLE_COMMODITY_TARGETS_RESPONSE:
      setNotification({
        ...notification,
        show: true,
        type: targetsActionsTypes.TARGETS_CREATE_MULTIPLE_COMMODITY_TARGETS_RESPONSE,
      });
      break;
    case targetsActionsTypes.TARGETS_CREATE_MULTIPLE_COMMODITY_TARGETS_FAILURE:
      setNotification({
        ...notification,
        show: true,
        type: targetsActionsTypes.TARGETS_CREATE_MULTIPLE_COMMODITY_TARGETS_FAILURE,
      });
      break;
    case targetsActionsTypes.TARGETS_DELETE_MULTIPLE_COMMODITY_TARGETS_RESPONSE:
      setNotification({
        ...notification,
        show: true,
        type: targetsActionsTypes.TARGETS_DELETE_MULTIPLE_COMMODITY_TARGETS_RESPONSE,
      });
      break;
    case targetsActionsTypes.TARGETS_DELETE_MULTIPLE_COMMODITY_TARGETS_FAILURE:
      setNotification({
        ...notification,
        show: true,
        type: targetsActionsTypes.TARGETS_DELETE_MULTIPLE_COMMODITY_TARGETS_FAILURE,
      });
      break;
    case targetsActionsTypes.TARGETS_CREATE_KEG_PRODUCT_RESPONSE:
      setNotification({
        ...notification,
        show: true,
        type: targetsActionsTypes.TARGETS_CREATE_KEG_PRODUCT_RESPONSE,
      });
      break;
    case targetsActionsTypes.TARGETS_CREATE_KEG_PRODUCT_FAILURE:
      setNotification({
        ...notification,
        show: true,
        type: targetsActionsTypes.TARGETS_CREATE_KEG_PRODUCT_FAILURE,
      });
      break;
    case targetsActionsTypes.TARGETS_EDIT_COMMODITY_TARGET_RESPONSE:
      setNotification({
        ...notification,
        show: true,
        type: targetsActionsTypes.TARGETS_EDIT_COMMODITY_TARGET_RESPONSE,
      });
      break;
    case targetsActionsTypes.TARGETS_EDIT_COMMODITY_TARGET_FAILURE:
      setNotification({
        ...notification,
        show: true,
        type: targetsActionsTypes.TARGETS_EDIT_COMMODITY_TARGET_FAILURE,
      });
      break;
    case targetsActionsTypes.TARGETS_EDIT_MULTIPLE_TARGET_COMMODITY_TARGETS_RESPONSE:
      setNotification({
        ...notification,
        show: true,
        type: targetsActionsTypes.TARGETS_EDIT_MULTIPLE_TARGET_COMMODITY_TARGETS_RESPONSE,
      });
      break;
    case targetsActionsTypes.TARGETS_EDIT_MULTIPLE_TARGET_COMMODITY_TARGETS_FAILURE:
      setNotification({
        ...notification,
        show: true,
        type: targetsActionsTypes.TARGETS_EDIT_MULTIPLE_TARGET_COMMODITY_TARGETS_FAILURE,
      });
      break;
    case targetsActionsTypes.TARGETS_EDIT_TARGET_CATEGORY_RESPONSE:
      setNotification({
        ...notification,
        show: true,
        type: targetsActionsTypes.TARGETS_EDIT_TARGET_CATEGORY_RESPONSE,
      });
      break;
    case targetsActionsTypes.TARGETS_EDIT_TARGET_CATEGORY_FAILURE:
      setNotification({
        ...notification,
        show: true,
        type: targetsActionsTypes.TARGETS_EDIT_TARGET_CATEGORY_FAILURE,
      });
      break;
    case targetsActionsTypes.TARGETS_EDIT_MANUFACTURER_RESPONSE:
      setNotification({
        ...notification,
        show: true,
        type: targetsActionsTypes.TARGETS_EDIT_MANUFACTURER_RESPONSE,
      });
      break;
    case targetsActionsTypes.TARGETS_EDIT_MANUFACTURER_FAILURE:
      setNotification({
        ...notification,
        show: true,
        type: targetsActionsTypes.TARGETS_EDIT_MANUFACTURER_FAILURE,
      });
      break;
    case targetsActionsTypes.TARGETS_CREATE_TARGET_COMMODITY_PROPERTY_RESPONSE:
      setNotification({
        ...notification,
        show: true,
        type: targetsActionsTypes.TARGETS_CREATE_TARGET_COMMODITY_PROPERTY_RESPONSE,
      });
      break;
    case targetsActionsTypes.TARGETS_EDIT_TARGET_COMMODITY_PROPERTY_RESPONSE:
      setNotification({
        ...notification,
        show: true,
        type: targetsActionsTypes.TARGETS_EDIT_TARGET_COMMODITY_PROPERTY_RESPONSE,
      });
      break;
    case targetsActionsTypes.TARGETS_CREATE_TARGET_COMMODITY_PROPERTY_FAILURE:
      setNotification({
        ...notification,
        show: true,
        type: targetsActionsTypes.TARGETS_CREATE_TARGET_COMMODITY_PROPERTY_FAILURE,
      });
      break;
    case targetsActionsTypes.TARGETS_EDIT_TARGET_COMMODITY_PROPERTY_FAILURE:
      setNotification({
        ...notification,
        show: true,
        type: targetsActionsTypes.TARGETS_EDIT_TARGET_COMMODITY_PROPERTY_FAILURE,
      });
      break;
    case rentActionsTypes.RENT_FINALIZE_CONTRACT_RESPONSE:
      setNotification({
        ...notification,
        show: true,
        type: rentActionsTypes.RENT_FINALIZE_CONTRACT_RESPONSE,
      });
      break;
    case rentActionsTypes.RENT_FINALIZE_CONTRACT_FAILURE:
      setNotification({
        ...notification,
        show: true,
        type: rentActionsTypes.RENT_FINALIZE_CONTRACT_FAILURE,
      });
      break;

    case targetsActionsTypes.TARGETS_EDIT_TARGET_COMMODITY_CATEGORY_RESPONSE:
      setNotification({
        ...notification,
        show: true,
        type: targetsActionsTypes.TARGETS_EDIT_TARGET_COMMODITY_CATEGORY_RESPONSE,
      });
      break;
    case targetsActionsTypes.TARGETS_EDIT_TARGET_COMMODITY_CATEGORY_FAILURE:
      setNotification({
        ...notification,
        show: true,
        type: targetsActionsTypes.TARGETS_EDIT_TARGET_COMMODITY_CATEGORY_FAILURE,
      });
      break;
    case rentActionsTypes.RENT_EDIT_CONTRACT_RESPONSE:
      setNotification({
        ...notification,
        show: true,
        type: rentActionsTypes.RENT_EDIT_CONTRACT_RESPONSE,
      });
      break;
    case rentActionsTypes.RENT_EDIT_CONTRACT_FAILURE:
      setNotification({
        ...notification,
        show: true,
        type: rentActionsTypes.RENT_EDIT_CONTRACT_FAILURE,
      });
      break;
    case rentActionsTypes.RENT_EDIT_CUSTOMER_RESPONSE:
      setNotification({
        ...notification,
        show: true,
        type: rentActionsTypes.RENT_EDIT_CUSTOMER_RESPONSE,
      });
      break;
    case rentActionsTypes.RENT_EDIT_CUSTOMER_FAILURE:
      setNotification({
        ...notification,
        show: true,
        type: rentActionsTypes.RENT_EDIT_CUSTOMER_FAILURE,
      });
      break;
    case rentActionsTypes.RENT_CREATE_DEPOSIT_RESPONSE:
      setNotification({
        ...notification,
        show: true,
        type: rentActionsTypes.RENT_CREATE_DEPOSIT_RESPONSE,
      });
      break;
    case rentActionsTypes.RENT_CREATE_DEPOSIT_FAILURE:
      setNotification({
        ...notification,
        show: true,
        type: rentActionsTypes.RENT_CREATE_DEPOSIT_FAILURE,
      });
      break;
    case rentActionsTypes.RENT_EDIT_DEPOSIT_RESPONSE:
      setNotification({
        ...notification,
        show: true,
        type: rentActionsTypes.RENT_EDIT_DEPOSIT_RESPONSE,
      });
      break;
    case rentActionsTypes.RENT_EDIT_DEPOSIT_FAILURE:
      setNotification({
        ...notification,
        show: true,
        type: rentActionsTypes.RENT_EDIT_DEPOSIT_FAILURE,
      });
      break;

    case rentActionsTypes.RENT_DELETE_CONTRACT_RESPONSE:
      setNotification({
        ...notification,
        show: true,
        type: rentActionsTypes.RENT_DELETE_CONTRACT_RESPONSE,
      });
      break;
    case rentActionsTypes.RENT_DELETE_CONTRACT_FAILURE:
      setNotification({
        ...notification,
        show: true,
        type: rentActionsTypes.RENT_DELETE_CONTRACT_FAILURE,
      });
      break;
    case rentActionsTypes.RENT_EDIT_RESERVATION_RESPONSE:
      setNotification({
        ...notification,
        show: true,
        type: rentActionsTypes.RENT_EDIT_RESERVATION_RESPONSE,
      });
      break;
    case rentActionsTypes.RENT_EDIT_RESERVATION_FAILURE:
      setNotification({
        ...notification,
        show: true,
        type: rentActionsTypes.RENT_EDIT_RESERVATION_FAILURE,
      });
      break;
    case rentActionsTypes.RENT_REMIND_RENT_CONTRACT_RESPONSE:
      setNotification({
        ...notification,
        show: true,
        type: rentActionsTypes.RENT_REMIND_RENT_CONTRACT_RESPONSE,
      });
      break;
    case rentActionsTypes.RENT_REMIND_RENT_CONTRACT_FAILURE:
      setNotification({
        ...notification,
        show: true,
        type: rentActionsTypes.RENT_REMIND_RENT_CONTRACT_FAILURE,
      });
      break;
    case administrationTypes.ADMINISTRATION_SEND_TEST_EMAIL_RESPONSE:
      setNotification({
        ...notification,
        show: true,
        type: administrationTypes.ADMINISTRATION_SEND_TEST_EMAIL_RESPONSE,
      });
      break;
    case administrationTypes.ADMINISTRATION_SEND_TEST_EMAIL_FAILURE:
      setNotification({
        ...notification,
        show: true,
        type: administrationTypes.ADMINISTRATION_SEND_TEST_EMAIL_FAILURE,
      });
      break;
    case administrationTypes.ADMINISTRATION_EDIT_TEMPLATE_RESPONSE:
      setNotification({
        ...notification,
        show: true,
        type: administrationTypes.ADMINISTRATION_EDIT_TEMPLATE_RESPONSE,
      });
      break;
    case administrationTypes.ADMINISTRATION_EDIT_TEMPLATE_FAILURE:
      setNotification({
        ...notification,
        show: true,
        type: administrationTypes.ADMINISTRATION_EDIT_TEMPLATE_FAILURE,
      });
      break;
    default:
      // After res message cleared, reset the notification state
      setNotification({
        ...notification,
        show: false,
        type: null,
      });
      break;
  }
};
