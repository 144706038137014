import * as actionsProperties from './auth.actions';

const initialState = {
  isAuthenticated: false,
  token: null,
  sessionId: null,
  idm: null,
  userId: null,
  userUid: null,
  fullName: null,
  nameShortFirst: null,
  resMessage: null,
  error: null,
  fetching: false,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionsProperties.AUTH_KEYCLOACK:
      return {
        ...state,
        isAuthenticated: action.payload.authenticated,
        token: action.payload.token,
        sessionId: action.payload.sessionId,
      };
    case actionsProperties.AUTH_IDM_REQUEST:
      return {
        ...state,
        fetching: true,
      };
    case actionsProperties.AUTH_IDM_RESPONSE:
      return {
        ...state,
        idm: action.payload.idm,
        userId: action.payload.idm.id,
        userUid: action.payload.idm.uid,
        fullName: action.payload.idm.firstname + ' ' + action.payload.idm.lastname,
        nameShortFirst: action.payload.idm.firstname[0] + '. ' + action.payload.idm.lastname,
        resMessage: action.payload.resMessage,
        fetching: false,
      };
    case actionsProperties.AUTH_RESPONSE_MESSAGE_RESET:
      return {
        ...state,
        resMessage: null,
      };
    case actionsProperties.AUTH_IDM_FAILURE:
      return {
        ...state,
        fetching: false,
        error: {...action.payload}
      };
    default:
      return state;
  }
};

export default authReducer;
