import { AirshopApi } from 'CraftApi.axios'
import { endpoints } from './rating.endpoints'

export function formBeerWithWebsitesMultipleRequests(
  requests,
  POST_newWebsites, PUT_editedExistingWebsites, DELETE_existingWebsitesIdsArr, PUT_editedBeer
) {

  // CREATE NEW BEERWEBSITES REQUESTS
  if (POST_newWebsites && POST_newWebsites.length) {
    requests.push(...POST_newWebsites.map(
      newWebsiteObject => AirshopApi.post(endpoints.beerWebsite, newWebsiteObject)
    ));
  }

  // EDIT EXISTING BEERWEBSITES REQUESTS
  if (PUT_editedExistingWebsites && PUT_editedExistingWebsites.length) {
    requests.push(...PUT_editedExistingWebsites.map((editedWebsite, editedWebsiteId) => {
      if (editedWebsiteId) {
        return AirshopApi.put(
          endpoints.beerWebsite,
          editedWebsite,
          { params: { websiteBeersId: editedWebsite.id } }
        )
      }
    }));
  }

  // DELETE EXISTING BEERWEBSITES
  if (DELETE_existingWebsitesIdsArr && DELETE_existingWebsitesIdsArr.length) {
    requests.push(...DELETE_existingWebsitesIdsArr.map(beerWebsiteId =>
        AirshopApi.delete(
          endpoints.beerWebsite,
          { params: { websiteBeersId: beerWebsiteId } }
        )
    ));
  }

  // EDIT BEER (with edited `style` prop)
  if (PUT_editedBeer) {
    requests.push(
      AirshopApi.put(
        'rating/beers',
        PUT_editedBeer,
        { params: { beersId: PUT_editedBeer.id }}
        )
    );
  }
}
