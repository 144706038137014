import { useAuthFacade } from 'redux-store/auth/auth.facade'
import { userRoles } from 'utils/user-roles'

export const useIsAdmin = () => {
  const { admin } = userRoles;
  const { idm } = useAuthFacade();

  let isAdmin = false;
  if (idm) {
    const { roleName } = idm;
    isAdmin = idm && roleName && roleName.split(',').includes(admin);
    // Could be true
    return { isAdmin }
  }
  // Never true
  return { isAdmin }
}
