import { useAuthSelectors } from './auth.selectors';
import * as actionsProperties from './auth.actions';
import { useCallback } from 'react';

import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';

import { setToken } from '../../CraftApi.axios';

export function useAuthFacade() {
  const dispatch = useDispatch();
  const selectors = useAuthSelectors();

  // eslint-disable-next-line no-unused-vars
  const [authFacadeState, setAuthFacadeState] = useState();

  // Getters with selectors
  const {
    isAuthenticated: authenticated,
    token,
    sessionId,
    userId,
    userUid,
    fullName,
    nameShortFirst,
    idm,
    resMessage: authResMessage,
  } = selectors;

  // Setting the token for authorized requests to love.cz backend
  useEffect(() => setToken(token), [token]);

  // Setter with reducer a storing functions into this hook's state
  const storeAuthObject = authObject => {
    dispatch(actionsProperties.storeKeycloakAuth(authObject));

    setAuthFacadeState({
      logout: authObject.logout,
      updateToken: authObject.updateToken
    });
  }

  const fetchAuthIdm = useCallback(
    () =>
      dispatch(actionsProperties.authIdmRequest()),
    [dispatch]
  );

  const resetAuthResponseMessage = useCallback(
    () =>
      dispatch(actionsProperties.authResetResponseMessage()),
    [dispatch]
  );


  return {
    storeAuthObject,
    fetchAuthIdm,
    resetAuthResponseMessage,
    authenticated,
    token,
    sessionId,
    userId,
    userUid,
    fullName,
    nameShortFirst,
    idm,
    authResMessage,
  }

}
