import * as actionTypes from './emails.actionTypes';

const initialState = {
  sentEmailsPageData: null,
  sentEmailsPageDraw: null,
  sentEmailsPagePage: null,
  sentEmailsTotal: null,
  receivedEmailsPageData: null,
  receivedEmailsPageDraw: null,
  receivedEmailsPagePage: null,
  receivedEmailsTotal: null,
  error: null,
  fetching: false,
};

const emailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.EMAILS_SENT_EMAILS_REQUEST:
      return {
        ...state,
        sentEmailsPageDraw: action.payload.draw,
        sentEmailsPagePage: action.payload.page,
        fetching: true,
      };
    case actionTypes.EMAILS_RECEIVED_EMAILS_REQUEST:
      return {
        ...state,
        receivedEmailsPageDraw: action.payload.draw,
        receivedEmailsPagePage: action.payload.page,
        fetching: true,
      };
    case actionTypes.EMAILS_SENT_EMAILS_RESPONSE:
      return {
        ...state,
        sentEmailsPageData: action.payload.items,
        sentEmailsTotal: action.payload.total,
        fetching: false,
      };
    case actionTypes.EMAILS_RECEIVED_EMAILS_RESPONSE:
      return {
        ...state,
        receivedEmailsPageData: action.payload.items,
        receivedEmailsTotal: action.payload.total,
        fetching: false,
      };
    case actionTypes.EMAILS_SENT_EMAILS_FAILURE:
    case actionTypes.EMAILS_RECEIVED_EMAILS_FAILURE:
      return {
        ...state,
        fetching: false,
        error: {...action.payload}
      };
    default:
      return state;
  }
};

export default emailsReducer;
