import * as actionTypes from './orders.actionTypes';

const initialState = {
  ordersPageData: null,
  ordersPageDraw: null,
  ordersPagePage: 0,
  redOrderStateFoldersCounts: null,
  selectedOrder: null,
  selectedOrderSyncStatuses: null,
  selectedOrderFirm: null,
  selectedOrderPartnerMessages: null,
  selectedOrderAddresses: {
    address: null,
    officeAdress: null,
  },
  selectedOrderProducts: {
    productsPageData: null,
    productsPageDraw: null,
    productsPagePage: null,
    total: null,
    relatedStoreCards: null,
  },
  selectedOrderDeliveryList: null,
  selectedOrderBalikobotStatusLogs: null,
  pdfList: {
    ordersProductsPdfList: null,
    ordersFirmsPdfList: null,
    ordersStoreCardsPdfList: null,
  },
  selectedOrderAbraCommands: {},
  selectedOrderAbraCommandsErrors: {},
  selectedOrderSubscriptions: {},
  transportationTypes: null,
  transportationTypesDraftValid: null,
  stavObjednavky: null,
  updatedStavObjednavkyResult: null,
  generatedPdfDocument: null,
  queuedCollectionLists: null,
  balikobotOrderEntry: null,
  balikobotOrderBranch: null,
  branchesByShipperId: [],
  serviceTypesByShipperId: [],
  ordersCountByEmail: {},
  subscriptions: null,
  sourceSubscriptionsByIndex: {},
  // selectedOrderAddresses already exists, new version made for subscriptions
  selectedOrderAddresses_NEW_VERSION: {},
  resMessage: null,
  partners: null,
  total: null,
  error: null,
  fetching: false,
};

const ordersReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ORDERS_LIST_REQUEST:
      return {
        ...state,
        ordersPageDraw: action.payload.draw,
        ordersPagePage: action.payload.page,
        fetching: true,
      };
    case actionTypes.ORDERS_ORDER_DETAIL_PRODUCTS_REQUEST:
      return {
        ...state,
        selectedOrderProducts: {
          ...state.selectedOrderProducts,
          productsPageDraw: action.payload.draw,
          productsPagePage: action.payload.page
        },
        fetching: true,
      };
    case actionTypes.ORDERS_TRANSPORTATION_TYPES_REQUEST:
    case actionTypes.ORDERS_STAV_OBJEDNAVKY_REQUEST:
    case actionTypes.ORDERS_SELECTED_ORDER_REQUEST:
    case actionTypes.ORDERS_UPDATE_STAV_OBJEDNAVKY_REQUEST:
    case actionTypes.ORDERS_ORDER_DETAIL_PRODUCTS_FOR_PDF_REQUEST:
    case actionTypes.ORDERS_FIRM_REQUEST:
    case actionTypes.ORDERS_ORDER_TO_STAV_SEND_SMS_REQUEST:
    case actionTypes.ORDERS_ORDER_TO_STAV_SEND_EMAIL_REQUEST:
    case actionTypes.ORDERS_PARTNER_MESSAGES_REQUEST:
    case actionTypes.ORDERS_ORDER_ITEM_UPDATE_QUANTITY_REQUEST:
    case actionTypes.ORDERS_ADDRESS_REQUEST:
    case actionTypes.ORDERS_PARTNER_REQUEST:
    case actionTypes.ORDERS_ORDER_ITEM_DELETE_REQUEST:
    case actionTypes.ORDERS_NEW_PARTNER_MESSAGE_REQUEST:
    case actionTypes.ORDERS_ORDER_SYNC_ABRA_REQUEST:
    case actionTypes.ORDERS_CREATE_DELIVERY_LIST_REQUEST:
    case actionTypes.ORDERS_RECREATE_DELIVERY_LIST_REQUEST:
    case actionTypes.ORDERS_ORDER_DELIVERY_LIST_REQUEST:
    case actionTypes.ORDERS_ORDER_CREATE_ITEM_REQUEST:
    case actionTypes.ORDERS_ORDER_SYNC_STATUSES_CHECK_REQUEST:
    case actionTypes.ORDERS_SYNC_ABRA_WHILE_IN_ABRA_REQUEST:
    case actionTypes.ORDERS_PRINT_DOCUMENT_REQUEST:
    case actionTypes.ORDERS_PRINT_BALIKOBOT_REQUEST:
    case actionTypes.ORDERS_PRINT_THERMAL80_REQUEST:
    case actionTypes.ORDERS_MASS_PRINT_DOCUMENTS_REQUEST:
    case actionTypes.ORDERS_GENERATE_PDF_REQUEST:
    case actionTypes.ORDERS_MASS_GENERATE_PDF_REQUEST:
    case actionTypes.ORDERS_UPDATE_BULK_ORDERS_STAV_OBJEDNAVKY_REQUEST:
    case actionTypes.ORDERS_CREATE_CLITEM_FROM_ORDER_REQUEST:
    case actionTypes.ORDERS_COLLECTION_LIST_QUEUED_REQUEST:
    case actionTypes.ORDERS_CREATE_BALIKOBOT_ORDER_ENTRY_REQUEST:
    case actionTypes.ORDERS_BALIKOBOT_ORDER_ENTRY_REQUEST:
    case actionTypes.ORDERS_MULTIPLE_BALIKOBOT_ORDER_ENTRY_REQUEST:
    case actionTypes.ORDERS_UPDATE_BALIKOBOT_ORDER_ENTRY_REQUEST:
    case actionTypes.ORDERS_GENERATE_BALIKOBOT_TAG_REQUEST:
    case actionTypes.ORDERS_DROP_ORDER_FROM_BALIKOBOT_REQUEST:
    case actionTypes.ORDERS_CREATE_CLITEMS_FROM_MULTIPLE_ORDERS_REQUEST:
    case actionTypes.ORDERS_ORDER_DETAIL_STATUS_LOGS_REQUEST:
    case actionTypes.ORDERS_BALIKOBOT_ORDER_BRANCH_REQUEST:
    case actionTypes.ORDERS_BRANCHES_BY_SHIPPER_ID_REQUEST:
    case actionTypes.ORDERS_TRANSPORTATION_TYPES_DRAFT_VALID_REQUEST:
    case actionTypes.ORDERS_SERVICE_TYPES_BY_SHIPPER_ID_REQUEST:
    case actionTypes.ORDERS_ORDER_ABRA_COMMAND_REQUEST:
    case actionTypes.ORDERS_CREATE_ORDER_SUBSCRIPTION_REQUEST:
    case actionTypes.ORDERS_SUBSCRIPTIONS_LIST_REQUEST:
    case actionTypes.ORDERS_ORDER_DETAIL_SUBSCRIPTIONS_LIST_REQUEST:
    case actionTypes.ORDERS_CREATE_ORDER_SUBSCRIPTION_WITH_UPDATE_REQUEST:
    case actionTypes.ORDERS_ORDERS_COUNTS_REQUEST:
    case actionTypes.ORDERS_SOURCE_SUBSCRIPTIONS_BY_INDEX_REQUEST:
    case actionTypes.ORDERS_MULTIPLE_CREATE_ORDER_SUBSCRIPTION_REQUEST:
    case actionTypes.ORDERS_DELETE_ORDER_SUBSCRIPTION_REQUEST:
    case actionTypes.ORDERS_EDIT_ORDER_SUBSCRIPTION_REQUEST:
    case actionTypes.ORDERS_GET_SELECTED_ORDER_ADDRESSES_REQUEST:
    case actionTypes.ORDERS_SET_SELECTED_ORDER_ADDRESS_REQUEST:
      return {
        ...state,
        fetching: true,
      };
    case actionTypes.ORDERS_LIST_RESPONSE:
      return {
        ...state,
        ordersPageData: action.payload.items,
        total: action.payload.total,
        fetching: false,
      };
    case actionTypes.ORDERS_ORDERS_COUNTS_RESPONSE:
      return {
        ...state,
        redOrderStateFoldersCounts: {
          '4SQ0000101': action.payload['4SQ0000101'], // Kontaktovat zákazníka
          'N900000101': action.payload['N900000101'], // Problematická objednávka
          'C900000101': action.payload['C900000101'], // Připravena - nekompletní
          'E900000101': action.payload['E900000101'], // Připravena - odložené odeslání
          '3900000101': action.payload['3900000101'], // Brno kompletovat
          '3SO5000101': action.payload['3SO5000101'], // Brno kompletovat prodejna
          '4900000101': action.payload['4900000101'], // Praha kompletovat
          '4SO5000101': action.payload['4SO5000101'], // Kompletovat Brno odloženo
          '5SJ6000101': action.payload['5SJ6000101'], // Kompletovat Brmo S+B
          '3S17000101': action.payload['3S17000101'], // 23 Kompletovat Brno sady
          '7S17000101': action.payload['7S17000101'], // 24 Předplatné dokončeno
        },
        fetching: false,
      };
    case actionTypes.ORDERS_ORDER_DETAIL_PRODUCTS_RESPONSE:
      return {
        ...state,
        selectedOrderProducts: {
          ...state.selectedOrderProducts,
          productsPageData: action.payload.products,
          relatedStoreCards: action.payload.storeCards,
          total: action.payload.total,
        },
        fetching: false,
      };
    case actionTypes.ORDERS_TRANSPORTATION_TYPES_RESPONSE:
      return {
        ...state,
        transportationTypes: action.payload,
        fetching: false,
      };
    case actionTypes.ORDERS_TRANSPORTATION_TYPES_DRAFT_VALID_RESPONSE:
      return {
        ...state,
        transportationTypesDraftValid: action.payload,
        fetching: false,
      };
    case actionTypes.ORDERS_STAV_OBJEDNAVKY_RESPONSE:
      return {
        ...state,
        stavObjednavky: action.payload,
        fetching: false,
      };
    case actionTypes.ORDERS_SELECTED_ORDER_RESPONSE:
      return {
        ...state,
        selectedOrder: action.payload,
        fetching: false,
      };
    case actionTypes.ORDERS_FIRM_RESPONSE:
      return {
        ...state,
        selectedOrderFirm: action.payload,
        fetching: false,
      };
    case actionTypes.ORDERS_ADDRESS_RESPONSE:
      return {
        ...state,
        selectedOrderAddresses: action.payload,
        fetching: false,
      };
    case actionTypes.ORDERS_SUBSCRIPTIONS_LIST_RESPONSE:
      return {
        ...state,
        subscriptions: action.payload,
        fetching: false,
      };
    case actionTypes.ORDERS_FIRM_RESET:
      return {
        ...state,
        selectedOrderFirm: null,
      };
    case actionTypes.ORDERS_UPDATE_STAV_OBJEDNAVKY_RESPONSE:
      return {
        ...state,
        updatedStavObjednavkyResult: action.payload.response,
        resMessage: action.payload.resMessage,
        fetching: false,
      };
    case actionTypes.ORDERS_ORDER_DELIVERY_LIST_RESPONSE:
      return {
        ...state,
        selectedOrderDeliveryList: action.payload,
        fetching: false,
      };
    case actionTypes.ORDERS_ORDER_DETAIL_PRODUCTS_FOR_PDF_RESPONSE:
      return {
        ...state,
        pdfList: {
          ordersProductsPdfList: action.payload.products,
          ordersFirmsPdfList: action.payload.firms,
          ordersStoreCardsPdfList: action.payload.storeCards,
        },
        fetching: false,
      };
    case actionTypes.ORDERS_ORDER_SYNC_STATUSES_CHECK_RESPONSE:
      return {
        ...state,
        selectedOrderSyncStatuses: action.payload,
        fetching: false,
      };
    case actionTypes.ORDERS_COLLECTION_LIST_QUEUED_RESPONSE:
      return {
        ...state,
        queuedCollectionLists: action.payload,
        fetching: false,
      };
    case actionTypes.ORDERS_PARTNER_RESPONSE: {
        const { partnerId, partner } = action.payload;
        let partners;
        if (state.partners) {
          partners = {...state.partners};
        } else {
          partners = {};
        }
        partners[partnerId] = partner;
        return {
          ...state,
          partners,
          fetching: false,
        }
      };
    case actionTypes.ORDERS_ORDER_DETAIL_STATUS_LOGS_RESPONSE:
      return {
        ...state,
        selectedOrderBalikobotStatusLogs: action.payload,
        fetching: false,
      };
    case actionTypes.ORDERS_ORDER_DETAIL_SUBSCRIPTIONS_LIST_RESPONSE:
      return {
        ...state,
        selectedOrderSubscriptions: action.payload,
        fetching: false,
      };
    case actionTypes.ORDERS_ORDER_DETAIL_SUBSCRIPTIONS_LIST_RESET:
      return {
        ...state,
        selectedOrderSubscriptions: {},
        fetching: false,
      };
    case actionTypes.ORDERS_ORDER_ABRA_COMMAND_RESPONSE:
      return {
        ...state,
        selectedOrderAbraCommands: action.payload.allCommands,
        selectedOrderAbraCommandsErrors: action.payload.lastFiveErroredCommands,
        fetching: false,
      };
    case actionTypes.ORDERS_SOURCE_SUBSCRIPTIONS_BY_INDEX_RESPONSE:
      return {
        ...state,
        sourceSubscriptionsByIndex: action.payload,
        fetching: false,
      };
    case actionTypes.ORDERS_GET_SELECTED_ORDER_ADDRESSES_RESPONSE:
      return {
        ...state,
        selectedOrderAddresses_NEW_VERSION: action.payload,
        fetching: false,
      };
    case actionTypes.ORDERS_ORDER_TO_STAV_SEND_EMAIL_RESPONSE:
    case actionTypes.ORDERS_ORDER_TO_STAV_SEND_SMS_RESPONSE:
    case actionTypes.ORDERS_ORDER_ITEM_UPDATE_QUANTITY_RESPONSE:
    case actionTypes.ORDERS_ORDER_ITEM_DELETE_RESPONSE:
    case actionTypes.ORDERS_NEW_PARTNER_MESSAGE_RESPONSE:
    case actionTypes.ORDERS_ORDER_SYNC_ABRA_RESPONSE:
    case actionTypes.ORDERS_CREATE_DELIVERY_LIST_RESPONSE:
    case actionTypes.ORDERS_RECREATE_DELIVERY_LIST_RESPONSE:
    case actionTypes.ORDERS_ORDER_CREATE_ITEM_RESPONSE:
    case actionTypes.ORDERS_SYNC_ABRA_WHILE_IN_ABRA_RESPONSE:
    case actionTypes.ORDERS_PRINT_BALIKOBOT_RESPONSE:
    case actionTypes.ORDERS_MASS_PRINT_DOCUMENTS_RESPONSE:
    case actionTypes.ORDERS_PRINT_DOCUMENT_RESPONSE:
    case actionTypes.ORDERS_UPDATE_BULK_ORDERS_STAV_OBJEDNAVKY_RESPONSE:
    case actionTypes.ORDERS_CREATE_CLITEM_FROM_ORDER_RESPONSE:
    case actionTypes.ORDERS_CREATE_CLITEMS_FROM_MULTIPLE_ORDERS_RESPONSE:
    case actionTypes.ORDERS_CREATE_ORDER_SUBSCRIPTION_RESPONSE:
    case actionTypes.ORDERS_CREATE_ORDER_SUBSCRIPTION_RESPONSE + '_SUBORDER':
    case actionTypes.ORDERS_MULTIPLE_CREATE_ORDER_SUBSCRIPTION_RESPONSE:
    case actionTypes.ORDERS_DELETE_ORDER_SUBSCRIPTION_RESPONSE:
    case actionTypes.ORDERS_EDIT_ORDER_SUBSCRIPTION_RESPONSE:
    case actionTypes.ORDERS_SET_SELECTED_ORDER_ADDRESS_RESPONSE:
      return {
        ...state,
        resMessage: action.payload.resMessage,
        fetching: false,
      };
    case actionTypes.ORDERS_GENERATE_PDF_RESPONSE:
    case actionTypes.ORDERS_MASS_GENERATE_PDF_RESPONSE:
      return {
        ...state,
        generatedPdfDocument: action.payload.response,
        resMessage: action.payload.resMessage,
        fetching: false,
      };
    case actionTypes.ORDERS_CREATE_BALIKOBOT_ORDER_ENTRY_RESPONSE:
    case actionTypes.ORDERS_UPDATE_BALIKOBOT_ORDER_ENTRY_RESPONSE:
    case actionTypes.ORDERS_GENERATE_BALIKOBOT_TAG_RESPONSE:
    case actionTypes.ORDERS_DROP_ORDER_FROM_BALIKOBOT_RESPONSE:
      return {
        ...state,
        balikobotOrderEntry: action.payload.response,
        resMessage: action.payload.resMessage,
        fetching: false,
      };
    case actionTypes.ORDERS_ORDER_DETAIL_PRODUCTS_FOR_PDF_RESET:
      return {
        ...state,
        pdfList: {
          ordersProductsPdfList: null,
          ordersFirmsPdfList: null,
          ordersStoreCardsPdfList: null,
        },
      };
    case actionTypes.ORDERS_BALIKOBOT_ORDER_ENTRY_RESPONSE:
    case actionTypes.ORDERS_MULTIPLE_BALIKOBOT_ORDER_ENTRY_RESPONSE:
      return {
        ...state,
        balikobotOrderEntry: action.payload,
        fetching: false,
      };
    case actionTypes.ORDERS_BALIKOBOT_ORDER_BRANCH_RESPONSE:
      return {
        ...state,
        balikobotOrderBranch: action.payload,
        fetching: false,
      };
    case actionTypes.ORDERS_PARTNER_MESSAGES_RESPONSE:
      return {
        ...state,
        selectedOrderPartnerMessages: action.payload,
        fetching: false,
      };
    case actionTypes.ORDERS_BRANCHES_BY_SHIPPER_ID_RESPONSE:
      return {
        ...state,
        branchesByShipperId: action.payload,
        fetching: false,
      };
    case actionTypes.ORDERS_SERVICE_TYPES_BY_SHIPPER_ID_RESPONSE:
      return {
        ...state,
        serviceTypesByShipperId: action.payload,
        fetching: false,
      };
    case actionTypes.ORDERS_RESET_GENERATED_PDF:
      return {
        ...state,
        generatedPdfDocument: null,
      };
    case actionTypes.ORDERS_ORDERS_COUNT_BY_EMAIL_RESPONSE:
      return {
        ...state,
        ordersCountByEmail: action.payload,
        fetching: false,
      };
    case actionTypes.ORDERS_ORDER_TO_STAV_SEND_EMAIL_FAILURE:
    case actionTypes.ORDERS_ORDER_TO_STAV_SEND_SMS_FAILURE:
    case actionTypes.ORDERS_ORDER_ITEM_UPDATE_QUANTITY_FAILURE:
    case actionTypes.ORDERS_ORDER_ITEM_DELETE_FAILURE:
    case actionTypes.ORDERS_NEW_PARTNER_MESSAGE_FAILURE:
    case actionTypes.ORDERS_ORDER_SYNC_ABRA_FAILURE:
    case actionTypes.ORDERS_CREATE_DELIVERY_LIST_FAILURE:
    case actionTypes.ORDERS_RECREATE_DELIVERY_LIST_FAILURE:
    case actionTypes.ORDERS_UPDATE_STAV_OBJEDNAVKY_FAILURE:
    case actionTypes.ORDERS_ORDER_CREATE_ITEM_FAILURE:
    case actionTypes.ORDERS_SYNC_ABRA_WHILE_IN_ABRA_FAILURE:
    case actionTypes.ORDERS_PRINT_BALIKOBOT_FAILURE:
    case actionTypes.ORDERS_MASS_PRINT_DOCUMENTS_FAILURE:
    case actionTypes.ORDERS_PRINT_DOCUMENT_FAILURE:
    case actionTypes.ORDERS_GENERATE_PDF_FAILURE:
    case actionTypes.ORDERS_MASS_GENERATE_PDF_FAILURE:
    case actionTypes.ORDERS_UPDATE_BULK_ORDERS_STAV_OBJEDNAVKY_FAILURE:
    case actionTypes.ORDERS_CREATE_CLITEM_FROM_ORDER_FAILURE:
    case actionTypes.ORDERS_CREATE_BALIKOBOT_ORDER_ENTRY_FAILURE:
    case actionTypes.ORDERS_UPDATE_BALIKOBOT_ORDER_ENTRY_FAILURE:
    case actionTypes.ORDERS_GENERATE_BALIKOBOT_TAG_FAILURE:
    case actionTypes.ORDERS_DROP_ORDER_FROM_BALIKOBOT_FAILURE:
    case actionTypes.ORDERS_CREATE_CLITEMS_FROM_MULTIPLE_ORDERS_FAILURE:
    case actionTypes.ORDERS_CREATE_ORDER_SUBSCRIPTION_FAILURE:
    case actionTypes.ORDERS_MULTIPLE_CREATE_ORDER_SUBSCRIPTION_FAILURE:
    case actionTypes.ORDERS_DELETE_ORDER_SUBSCRIPTION_FAILURE:
    case actionTypes.ORDERS_EDIT_ORDER_SUBSCRIPTION_FAILURE:
    case actionTypes.ORDERS_SET_SELECTED_ORDER_ADDRESS_FAILURE:
      return {
        ...state,
        resMessage: action.payload.resMessage,
        error: {...action.payload.error},
        fetching: false,
      };
    case actionTypes.ORDERS_LIST_FAILURE:
    case actionTypes.ORDERS_TRANSPORTATION_TYPES_FAILURE:
    case actionTypes.ORDERS_STAV_OBJEDNAVKY_FAILURE:
    case actionTypes.ORDERS_ORDER_DETAIL_PRODUCTS_FAILURE:
    case actionTypes.ORDERS_SELECTED_ORDER_FAILURE:
    case actionTypes.ORDERS_FIRM_FAILURE:
    case actionTypes.ORDERS_ORDER_DETAIL_PRODUCTS_FOR_PDF_FAILURE:
    case actionTypes.ORDERS_PARTNER_MESSAGES_FAILURE:
    case actionTypes.ORDERS_ADDRESS_FAILURE:
    case actionTypes.ORDERS_PARTNER_FAILURE:
    case actionTypes.ORDERS_ORDER_DELIVERY_LIST_FAILURE:
    case actionTypes.ORDERS_ORDER_SYNC_STATUSES_CHECK_FAILURE:
    case actionTypes.ORDERS_COLLECTION_LIST_QUEUED_FAILURE:
    case actionTypes.ORDERS_BALIKOBOT_ORDER_ENTRY_FAILURE:
    case actionTypes.ORDERS_MULTIPLE_BALIKOBOT_ORDER_ENTRY_FAILURE:
    case actionTypes.ORDERS_ORDER_DETAIL_STATUS_LOGS_FAILURE:
    case actionTypes.ORDERS_BALIKOBOT_ORDER_BRANCH_FAILURE:
    case actionTypes.ORDERS_BRANCHES_BY_SHIPPER_ID_FAILURE:
    case actionTypes.ORDERS_TRANSPORTATION_TYPES_DRAFT_VALID_FAILURE:
    case actionTypes.ORDERS_SERVICE_TYPES_BY_SHIPPER_ID_FAILURE:
    case actionTypes.ORDERS_ORDERS_COUNT_BY_EMAIL_FAILURE:
    case actionTypes.ORDERS_ORDER_ABRA_COMMAND_FAILURE:
    case actionTypes.ORDERS_SUBSCRIPTIONS_LIST_FAILURE:
    case actionTypes.ORDERS_ORDER_DETAIL_SUBSCRIPTIONS_LIST_FAILURE:
    case actionTypes.ORDERS_ORDERS_COUNTS_FAILURE:
    case actionTypes.ORDERS_SOURCE_SUBSCRIPTIONS_BY_INDEX_FAILURE:
    case actionTypes.ORDERS_GET_SELECTED_ORDER_ADDRESSES_FAILURE:
      return {
        ...state,
        fetching: false,
        error: {...action.payload}
      };
    case actionTypes.ORDERS_RESPONSE_MESSAGE_RESET:
      return {
        ...state,
        resMessage: null,
      };
    case actionTypes.ORDERS_BALIKOBOT_ORDER_ENTRY_RESET:
      return {
        ...state,
        balikobotOrderEntry: null,
      };
    case actionTypes.ORDERS_BALIKOBOT_ORDER_BRANCH_RESET:
      return {
        ...state,
        balikobotOrderBranch: null,
      };
    case actionTypes.ORDERS_BRANCHES_BY_SHIPPER_ID_RESET:
      return {
        ...state,
        branchesByShipperId: [],
      };
    case actionTypes.ORDERS_SERVICE_TYPES_BY_SHIPPER_ID_RESET:
      return {
        ...state,
        serviceTypesByShipperId: [],
      };
    default:
      return state;
  }
};

export default ordersReducer;
