import * as actionTypes from './auth.actions';
import { AirshopApi } from '../../CraftApi.axios';
import { from, of } from 'rxjs';
import { mergeMap, takeUntil, catchError, map } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { err, errMessages } from 'redux-store/utils/epics.utils'

export const fetchAuthIdmEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.AUTH_IDM_REQUEST),
    mergeMap(action => from(AirshopApi.get('auth/idm'))
      .pipe(
          map(res => res.data.status === 'OK' ? res.data.data : err(errMessages.NOT_OK, res)),
          map(idm => {
            return {
              type: actionTypes.AUTH_IDM_RESPONSE,
              payload: { idm, resMessage: actionTypes.AUTH_IDM_RESPONSE },
            }
          }),
          catchError(error => of({
              type: actionTypes.AUTH_IDM_FAILURE,
              payload: error,
            })
          ),
          takeUntil(action$.ofType(
            actionTypes.AUTH_IDM_RESPONSE ||
              actionTypes.AUTH_IDM_FAILURE
          ))
      )
    )
  );
