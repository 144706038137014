import React, { Suspense, useContext } from 'react'
import {
  Redirect,
  Route,
  Switch
} from 'react-router-dom'
import { CContainer, CFade } from '@coreui/react'
import { prefixes } from '../routes'
import AppContext from 'contexts/context-registrations/AppContext'

// routes config
import routes from '../routes'

import 'primereact/resources/themes/saga-blue/theme.css'
import 'primereact/resources/primereact.min.css'
import 'primeicons/primeicons.css'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

const TheContent = () => {

  const appContext = useContext(AppContext);

  const { userPrintersSelected, userIpWhitelisted } = appContext;

  return (
    <main className="c-main">
      <CContainer fluid>
        <Suspense fallback={loading}>
          <Switch>
            {routes.map((route, idx) => {
              return route.component && (
                <Route
                  key={idx}
                  path={route.path}
                  exact={route.exact}
                  name={route.name}
                  render={props => (
                    <CFade>
                      <route.component
                        userPrintersSelected={userPrintersSelected}
                        userIpWhitelisted={userIpWhitelisted}
                        {...props}
                      />
                    </CFade>
                  )} />
              )
            })}

            { userPrintersSelected ?
              <Redirect from="/" to={prefixes.orders} />
            : <Redirect from="*" to={`${prefixes.administration}/tiskarny`} />
            }

          </Switch>
        </Suspense>
      </CContainer>
    </main>
  )
}

export default React.memo(TheContent)
