import { useDispatch } from 'react-redux'
import { useCallback } from 'react'

import * as actions from './user-checks.actions'
import { useUserChecksSelectors } from './user-checks.selectors'

export function useUserChecksFacade() {
  const dispatch = useDispatch();
  const selectors = useUserChecksSelectors();

  const {
    selectUserIPAddress: userIPAddress,
    selectShopTypeIpAddresses: shopTypeIpAddresses,
    selectGeneralTypeIpAddresses: generalTypeIpAddresses,
  } = selectors;

  const fetchUserIPCheckData = useCallback(
    () =>
      dispatch(actions.userChecksUserIPCheckInitiateRequest()),
    [dispatch]
  );

  return {
    userIPAddress,
    shopTypeIpAddresses,
    generalTypeIpAddresses,

    fetchUserIPCheckData,
  }
}
