export interface IUserProfileSetup {
  darkMode?: string | boolean;
  preselectedOrdersState?: string | null;
  mandatoryPrintersSelected: boolean;
  printersLocation: string | null;
}

export enum EProfileSetupKeys {
  DARK_MODE = 'darkMode',
  PRESELECTED_ORDERS_STATE = 'preselectedOrdersState',
  MANDATORY_PRINTERS_SELECTED = 'mandatoryPrintersSelected',
  PRINTERS_LOCATION = 'printersLocation',
  ORDERS_STATE_AND_DELIVERY_COMBINATION_OPTION = 'ordersStateAndDeliveryCombinationOption',
}

export interface IPSKeyValObject {
  key: EProfileSetupKeys;
  value: any;
}

export interface INameValObject {
  name: string;
  value: string | null;
}

export interface IProfileSetupHandledResponse{
  profileSetupFull: any[];
  profileSetup: any;
  userOrdersStateDeliveryCombinations: any;
}

export interface IUserState {
  profileSetup: IUserProfileSetup | null;
  profileSetupFull: any;
  userOrdersStateDeliveryCombinations: any[];
  resMessage: string | null;
  error: any;
  fetching: boolean;
}

export interface IPSFullProp {
  id: number;
  uid: string;
  partnerId: number;
  key: string;
  value: string | null;
  source?: null;
  func?: null;
  target?: null;
  note?: null;
  dateCreated: number | Date;
}
