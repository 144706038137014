// FILTERS STATE HANDLING THROUGH RELOADS IN ONE PANEL
// expires, for zero expiracy, use local storage

export const setOrdersListFilterToSessionStorage = (type, val=null, zeroNumInclusive=false) => {
  if (isOrdersListFilter(type)) {
    if (val && !zeroNumInclusive) {
      // Filter is being changed
      sessionStorage.setItem(type, val);
    } else if (zeroNumInclusive) {
      // Case for val number and including zero
      sessionStorage.setItem(type, val);
    } else {
      // Filter is being reset
      sessionStorage.removeItem(type);
    }
  }
}

export const getOrdersListFilterFromSessionStorage = (type) => {
  if (isOrdersListFilter(type)) {
    return sessionStorage.getItem(type);
  }
}

export const sessionOrdersFiltersTypes = {
  'DELIVERY_TYPE': 'ORDERS_LIST_FILTERS_delivery',
  'ORDERS_STATE_TYPE': 'ORDERS_LIST_FILTERS_orders_state',
  'SHOP_TYPE': 'ORDERS_LIST_FILTERS_shop_type',
  'SEARCH_TYPE': 'ORDERS_LIST_FILTERS_search_term',
  'TABLE_PAGE': 'ORDERS_LIST_FILTERS_table_page',
  'SELECTED_ORDER': 'ORDERS_selected_order',
  'SUB_ORDERS_COUNT': 'SUB_ORDERS_COUNT',
}

export const isOrdersListFilter = (type) => {
  switch (type) {
    case sessionOrdersFiltersTypes.DELIVERY_TYPE:
    case sessionOrdersFiltersTypes.ORDERS_STATE_TYPE:
    case sessionOrdersFiltersTypes.SHOP_TYPE:
    case sessionOrdersFiltersTypes.SEARCH_TYPE:
    case sessionOrdersFiltersTypes.TABLE_PAGE:
    case sessionOrdersFiltersTypes.SELECTED_ORDER:
    case sessionOrdersFiltersTypes.SUB_ORDERS_COUNT:
      return true;
    default:
      return false;
  }

}
