export const RATING_RESPONSE_MESSAGE_RESET = '[RATING]_RESPONSE_MESSAGE_RESET'

export const RATING_BEERS_WITH_WEBSITES_REQUEST = '[RATING]_BEERS_WITH_WEBSITES_REQUEST'
export const RATING_BEERS_WITH_WEBSITES_RESPONSE = '[RATING]_BEERS_WITH_WEBSITES_RESPONSE'
export const RATING_BEERS_WITH_WEBSITES_FAILURE = '[RATING]_BEERS_WITH_WEBSITES_FAILURE'

export const RATING_WEBSITES_LIST_REQUEST = '[RATING]_WEBSITES_LIST_REQUEST'
export const RATING_WEBSITES_LIST_RESPONSE = '[RATING]_WEBSITES_LIST_RESPONSE'
export const RATING_WEBSITES_LIST_FAILURE = '[RATING]_WEBSITES_LIST_FAILURE'

export const RATING_BEER_BY_ID_REQUEST = '[RATING]_BEER_BY_ID_REQUEST'
export const RATING_BEER_BY_ID_RESPONSE = '[RATING]_BEER_BY_ID_RESPONSE'
export const RATING_BEER_BY_ID_FAILURE = '[RATING]_BEER_BY_ID_FAILURE'

export const RATING_UPDATE_BEER_BY_ID_REQUEST = '[RATING]_UPDATE_BEER_BY_ID_REQUEST'
export const RATING_UPDATE_BEER_BY_ID_RESPONSE = '[RATING]_UPDATE_BEER_BY_ID_RESPONSE'
export const RATING_UPDATE_BEER_BY_ID_FAILURE = '[RATING]_UPDATE_BEER_BY_ID_FAILURE'

export const RATING_DELETE_BEER_BY_ID_REQUEST = '[RATING]_DELETE_BEER_BY_ID_REQUEST'
export const RATING_DELETE_BEER_BY_ID_RESPONSE = '[RATING]_DELETE_BEER_BY_ID_RESPONSE'
export const RATING_DELETE_BEER_BY_ID_FAILURE = '[RATING]_DELETE_BEER_BY_ID_FAILURE'

export const RATING_TOGGLE_ACTIVE_BEER_BY_ID_REQUEST = '[RATING]_TOGGLE_ACTIVE_BEER_BY_ID_REQUEST'
export const RATING_TOGGLE_ACTIVE_BEER_BY_ID_RESPONSE = '[RATING]_TOGGLE_ACTIVE_BEER_BY_ID_RESPONSE'
export const RATING_TOGGLE_ACTIVE_BEER_BY_ID_FAILURE = '[RATING]_TOGGLE_ACTIVE_BEER_BY_ID_FAILURE'

export const RATING_BEER_WITH_WEBSITES_BY_BEER_ID_REQUEST = '[RATING]_BEER_WITH_WEBSITES_BY_BEER_ID_REQUEST'
export const RATING_BEER_WITH_WEBSITES_BY_BEER_ID_RESPONSE = '[RATING]_BEER_WITH_WEBSITES_BY_BEER_ID_RESPONSE'
export const RATING_BEER_WITH_WEBSITES_BY_BEER_ID_FAILURE = '[RATING]_BEER_WITH_WEBSITES_BY_BEER_ID_FAILURE'

export const RATING_CREATE_BEER_WITH_WEBSITES_REQUEST = '[RATING]_CREATE_BEER_WITH_WEBSITES_REQUEST'
export const RATING_CREATE_BEER_WITH_WEBSITES_RESPONSE = '[RATING]_CREATE_BEER_WITH_WEBSITES_RESPONSE'
export const RATING_CREATE_BEER_WITH_WEBSITES_FAILURE = '[RATING]_CREATE_BEER_WITH_WEBSITES_FAILURE'

export const RATING_UPDATE_BEER_WITH_WEBSITES_BY_ID_REQUEST = '[RATING]_UPDATE_BEER_WITH_WEBSITES_BY_ID_REQUEST'
export const RATING_UPDATE_BEER_WITH_WEBSITES_BY_ID_RESPONSE = '[RATING]_UPDATE_BEER_WITH_WEBSITES_BY_ID_RESPONSE'
export const RATING_UPDATE_BEER_WITH_WEBSITES_BY_ID_FAILURE = '[RATING]_UPDATE_BEER_WITH_WEBSITES_BY_ID_FAILURE'

export const RATING_DELETE_BEER_WITH_WEBSITES_BY_ID_REQUEST = '[RATING]_DELETE_BEER_WITH_WEBSITES_BY_ID_REQUEST'
export const RATING_DELETE_BEER_WITH_WEBSITES_BY_ID_RESPONSE = '[RATING]_DELETE_BEER_WITH_WEBSITES_BY_ID_RESPONSE'
export const RATING_DELETE_BEER_WITH_WEBSITES_BY_ID_FAILURE = '[RATING]_DELETE_BEER_WITH_WEBSITES_BY_ID_FAILURE'

export const RATING_BEER_WITH_WEBSITES_MULTI_REQUESTS_REQUEST = '[RATING]_BEER_WITH_WEBSITES_MULTI_REQUESTS_REQUEST'
export const RATING_BEER_WITH_WEBSITES_MULTI_REQUESTS_RESPONSE = '[RATING]_BEER_WITH_WEBSITES_MULTI_REQUESTS_RESPONSE'
export const RATING_BEER_WITH_WEBSITES_MULTI_REQUESTS_FAILURE = '[RATING]_BEER_WITH_WEBSITES_MULTI_REQUESTS_FAILURE'
