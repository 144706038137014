import * as actionTypes from './rating.actionTypes'
import * as actions from './rating.actions'
import { AirshopApi } from '../../CraftApi.axios'
import { from, of, forkJoin } from 'rxjs'
import { mergeMap, takeUntil, catchError, map } from 'rxjs/operators'
import { ofType } from 'redux-observable'
import { err, errMessages } from 'redux-store/utils/epics.utils'
import { endpoints } from './utils/rating.endpoints'
import { formBeerWithWebsitesMultipleRequests } from './utils/rating.functions'

export const fetchBeersWithWebsitesEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.RATING_BEERS_WITH_WEBSITES_REQUEST),
    mergeMap(action => {
      // eslint-disable-next-line no-unused-vars
      const { draw, page, limit, sort, search } = action.payload;
      return from(AirshopApi.get('rating/beers/withWebsites', {
      params: {
        active: 2,
        order: 'date_created',
        sort: sort ? sort : 'DESC',
        // test on beershop_id = 4813-SK, as of now on page with offset: 470
        draw, page: page * limit, limit, search,
       }
    }))
      .pipe(
          map(res => res.data.status === 'OK' ? res.data : err(errMessages.NOT_OK, res)),
          map(response => actions.ratingBeersWithWebsitesPageResponse({
            total: response.total, items: response.items
          })),
          catchError(error => of(actions.ratingBeersWithWebsitesPageFailure(
            error
          ))),
          takeUntil(action$.ofType(
            actionTypes.RATING_BEERS_WITH_WEBSITES_RESPONSE ||
            actionTypes.RATING_BEERS_WITH_WEBSITES_FAILURE
          ))
      )
    })
  );

export const fetchWebsitesListEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.RATING_WEBSITES_LIST_REQUEST),
    mergeMap(action => from(AirshopApi.get('rating/websites'))
      .pipe(
          map(res => res.data.status === 'OK' ? res.data.items : err(errMessages.NOT_OK, res)),
          map(response => actions.ratingWebsitesListResponse(response)),
          catchError(error => of(actions.ratingWebsitesListFailure(
            error
          ))),
          takeUntil(action$.ofType(
            actionTypes.RATING_WEBSITES_LIST_RESPONSE ||
            actionTypes.RATING_WEBSITES_LIST_FAILURE
          ))
      )
    )
  );

export const fetchBeerByBeerIdEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.RATING_BEER_BY_ID_REQUEST),
    mergeMap(action => from(AirshopApi.get(`rating/beers/${action.payload}`))
      .pipe(
          map(res => res.data.status === 'OK' ? res.data.data : err(errMessages.NOT_OK, res)),
          map(response => actions.ratingBeerByIdResponse({
            [action.payload]: response
          })),
          catchError(error => of(actions.ratingBeerByIdFailure(
            error
          ))),
          takeUntil(action$.ofType(
            actionTypes.RATING_BEER_BY_ID_RESPONSE ||
            actionTypes.RATING_BEER_BY_ID_FAILURE
          ))
      )
    )
  );

export const updateBeerByBeerIdEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.RATING_UPDATE_BEER_BY_ID_REQUEST),
    mergeMap(action => from(AirshopApi.put(
      'rating/beers',
      action.payload.updatedBeer,
      { params: { beersId: action.payload.beerId }}
      ))
      .pipe(
          map(res => res.data.status === 'OK' ? res.data : err(errMessages.NOT_OK, res)),
          map(response => actions.ratingUpdateBeerByIdResponse(response)),
          catchError(error => of(actions.ratingUpdateBeerByIdFailure(
            error
          ))),
          takeUntil(action$.ofType(
            actionTypes.RATING_UPDATE_BEER_BY_ID_RESPONSE ||
            actionTypes.RATING_UPDATE_BEER_BY_ID_FAILURE
          ))
      )
    )
  );

export const deleteBeerByBeerIdEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.RATING_DELETE_BEER_BY_ID_REQUEST),
    mergeMap(action => from(AirshopApi.delete('rating/beers', {
      params: { beersId: action.payload }
    }))
      .pipe(
          map(res => res.data.status === 'OK' ? res.data : err(errMessages.NOT_OK, res)),
          map(response => actions.ratingDeleteBeerByIdResponse(response)),
          catchError(error => of(actions.ratingDeleteBeerByIdFailure(
            error
          ))),
          takeUntil(action$.ofType(
            actionTypes.RATING_DELETE_BEER_BY_ID_RESPONSE ||
            actionTypes.RATING_DELETE_BEER_BY_ID_FAILURE
          ))
      )
    )
  );

export const toggleActiveBeerByBeerIdEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.RATING_TOGGLE_ACTIVE_BEER_BY_ID_REQUEST),
    mergeMap(action => from(AirshopApi.get(`rating/beers/${action.payload}`))
      .pipe(
          map(res => {
            if (res && res.data.id) {
              const beer = res.data;
              const toggledBeer = {...beer, active: 1 - beer.active};
              return toggledBeer;
            } else {
              err(errMessages.NOT_OK, res);
            }
          }),
          mergeMap(toggledBeer => from(AirshopApi.put(
              'rating/beers', toggledBeer,
              { params: { beersId: toggledBeer.id }}
            ))
          .pipe(
            map(res => res.data.status === 'OK' ? res.data.data : err(errMessages.NOT_OK, res)),
          )),
          map(response => actions.ratingToggleActiveBeerByIdResponse(
            response,
            actionTypes.RATING_TOGGLE_ACTIVE_BEER_BY_ID_RESPONSE
          )),
          catchError(error => of(actions.ratingToggleActiveBeerByIdFailure(
            error,
            actionTypes.RATING_TOGGLE_ACTIVE_BEER_BY_ID_FAILURE
          ))),
          takeUntil(action$.ofType(
            actionTypes.RATING_TOGGLE_ACTIVE_BEER_BY_ID_RESPONSE ||
            actionTypes.RATING_TOGGLE_ACTIVE_BEER_BY_ID_FAILURE
          ))
      )
    )
  );

export const fetchBeerWithWebsitesByBeerIdEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.RATING_BEER_WITH_WEBSITES_BY_BEER_ID_REQUEST),
    mergeMap(action => from(AirshopApi.get(`rating/websiteBeers/beerId/${action.payload}`))
      .pipe(
          map(res => res.data.status === 'OK' ? res.data.data : err(errMessages.NOT_OK, res)),
          map(response => actions.ratingBeerWithWebsitesByBeerIdResponse({
            [action.payload]: response
          })),
          catchError(error => of(actions.ratingBeerWithWebsitesByBeerIdFailure(
            error
          ))),
          takeUntil(action$.ofType(
            actionTypes.RATING_BEER_WITH_WEBSITES_BY_BEER_ID_RESPONSE ||
            actionTypes.RATING_BEER_WITH_WEBSITES_BY_BEER_ID_REQUEST
          ))
      )
    )
  );

export const createBeerWithWebsitesEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.RATING_CREATE_BEER_WITH_WEBSITES_REQUEST),
    mergeMap(action => from(AirshopApi.post(endpoints.beerWebsite, action.payload))
      .pipe(
          map(res => res.data.status === 'OK' ? res.data : err(errMessages.NOT_OK, res)),
          map(response => actions.ratingCreateBeerWithWebsitesResponse(response)),
          catchError(error => of(actions.ratingCreateBeerWithWebsitesFailure(
            error
          ))),
          takeUntil(action$.ofType(
            actionTypes.RATING_CREATE_BEER_WITH_WEBSITES_RESPONSE ||
            actionTypes.RATING_CREATE_BEER_WITH_WEBSITES_FAILURE
          ))
      )
    )
  );

export const updateBeerWithWebsitesByIdEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.RATING_UPDATE_BEER_WITH_WEBSITES_BY_ID_REQUEST),
    mergeMap(action => from(AirshopApi.put(
      endpoints.beerWebsite,
      action.payload.updatedBeerWithWebsites,
      { params: { websiteBeersId: action.payload.beerWithWebsitesId } }
      ))
      .pipe(
          map(res => res.data.status === 'OK' ? res.data : err(errMessages.NOT_OK, res)),
          map(response => actions.ratingUpdateBeerWithWebsitesByIdResponse(response)),
          catchError(error => of(actions.ratingUpdateBeerWithWebsitesByIdFailure(
            error
          ))),
          takeUntil(action$.ofType(
            actionTypes.RATING_UPDATE_BEER_WITH_WEBSITES_BY_ID_RESPONSE ||
            actionTypes.RATING_UPDATE_BEER_WITH_WEBSITES_BY_ID_FAILURE
          ))
      )
    )
  );

export const deleteBeerWithWebsitesByIdEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.RATING_DELETE_BEER_WITH_WEBSITES_BY_ID_REQUEST),
    mergeMap(action => from(AirshopApi.delete(
      endpoints.beerWebsite,
      { params: { websiteBeersId: action.payload } }
    ))
      .pipe(
          map(res => res.data.status === 'OK' ? res.data : err(errMessages.NOT_OK, res)),
          map(response => actions.ratingDeleteBeerWithWebsitesByIdResponse(response)),
          catchError(error => of(actions.ratingDeleteBeerWithWebsitesByIdFailure(
            error
          ))),
          takeUntil(action$.ofType(
            actionTypes.RATING_DELETE_BEER_WITH_WEBSITES_BY_ID_RESPONSE ||
            actionTypes.RATING_DELETE_BEER_WITH_WEBSITES_BY_ID_FAILURE
          ))
      )
    )
  );

export const multipleBeerWithWebsitesRequestsEpic = action$ =>
action$.pipe(
  ofType(actionTypes.RATING_BEER_WITH_WEBSITES_MULTI_REQUESTS_REQUEST),
  mergeMap(action => {
    const {
      POST_newWebsites, PUT_editedExistingWebsites, DELETE_existingWebsitesIdsArr, PUT_editedBeer
    } = action.payload;
    const requests = [];
    formBeerWithWebsitesMultipleRequests(
      requests,
      POST_newWebsites, PUT_editedExistingWebsites, DELETE_existingWebsitesIdsArr, PUT_editedBeer
    );
    // Some arr values are undefined due to some object having an ID out of sort, remove undefined values then
    return forkJoin(requests.filter(Boolean))
    .pipe(
        map(responses => {
          let hasErrored = false;
          responses.forEach(res => {
            if (res.data.status !== 'OK') { hasErrored = true; }
          });

          if (hasErrored) {
            return err(errMessages.NOT_OK, responses)
          } else {
            return responses;
          }
        }),
        map(response => actions.ratingBeerWithWebsitesMultipleRequestsResponse(
          response, actionTypes.RATING_BEER_WITH_WEBSITES_MULTI_REQUESTS_RESPONSE
        )),
        catchError(error => of(actions.ratingBeerWithWebsitesMultipleRequestsFailure(
          error, actionTypes.RATING_BEER_WITH_WEBSITES_MULTI_REQUESTS_FAILURE
        ))),
        takeUntil(action$.ofType(
          actionTypes.RATING_BEER_WITH_WEBSITES_MULTI_REQUESTS_RESPONSE ||
          actionTypes.RATING_BEER_WITH_WEBSITES_MULTI_REQUESTS_FAILURE
        ))
    )
  })
);
