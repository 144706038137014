import { useSelector } from 'react-redux'

export function useRentSelectors() {
  const selectResMessage = useSelector(state => state.rent.resMessage);
  const selectContractItemsByContractId = useSelector(state => state.rent.contractItemsByContractId);
  const selectScanDocumentsByContractId = useSelector(state => state.rent.scanDocumentsByContractId);
  const selectSingleCustomer = useSelector(state => state.rent.singleCustomer);
  const selectSingleContract = useSelector(state => state.rent.singleContract);
  const selectReservationItemsByReservationId = useSelector(state => state.rent.reservationItemsByReservationId);
  const selectCreatedReservation = useSelector(state => state.rent.createdReservation);
  const selectRelatedCustomers = useSelector(state => state.rent.relatedCustomers);
  const selectGeneratedPdfDocument = useSelector(state => state.rent.generatedPdfDocument);
  const selectRelatedDeposits = useSelector(state => state.rent.relatedDeposits);
  const selectCreatedDeposit = useSelector(state => state.rent.createdDeposit);
  const selectRelatedPdfDocuments = useSelector(state => state.rent.relatedPdfDocuments);
  const selectDepositSum = useSelector(state => state.rent.depositSum);

  const selectTemplatePdfsListPageData = useSelector(state => state.rent.templatePdfsListPageData);
  const selectTemplatePdfsListPageDraw = useSelector(state => state.rent.templatePdfsListPageDraw);
  const selectTemplatePdfsListPagePage = useSelector(state => state.rent.templatePdfsListPagePage);
  const selectTemplatePdfsListTotal = useSelector(state => state.rent.templatePdfsListTotal);
  const selectTemplatePdfsListPageLimit = useSelector(state => state.rent.templatePdfsListPageLimit);

  const selectCustomersListPageData = useSelector(state => state.rent.customersListPageData);
  const selectCustomersListPageDraw = useSelector(state => state.rent.customersListPageDraw);
  const selectCustomersListPagePage = useSelector(state => state.rent.customersListPagePage);
  const selectCustomersListTotal = useSelector(state => state.rent.customersListTotal);
  const selectCustomersListPageLimit = useSelector(state => state.rent.customersListPageLimit);

  const selectContractsListPageData = useSelector(state => state.rent.contractsListPageData);
  const selectContractsListPageDraw = useSelector(state => state.rent.contractsListPageDraw);
  const selectContractsListPagePage = useSelector(state => state.rent.contractsListPagePage);
  const selectContractsListTotal = useSelector(state => state.rent.contractsListTotal);
  const selectContractsListPageLimit = useSelector(state => state.rent.contractsListPageLimit);

  const selectTargetCommodityForRentalListPageData = useSelector(state => state.rent.targetCommodityForRentalListPageData);
  const selectTargetCommodityForRentalListPageDraw = useSelector(state => state.rent.targetCommodityForRentalListPageDraw);
  const selectTargetCommodityForRentalListPagePage = useSelector(state => state.rent.targetCommodityForRentalListPagePage);
  const selectTargetCommodityForRentalListTotal = useSelector(state => state.rent.targetCommodityForRentalListTotal);
  const selectTargetCommodityForRentalListPageLimit = useSelector(state => state.rent.targetCommodityForRentalListPageLimit);

  const selectReservationsListPageData = useSelector(state => state.rent.reservationsListPageData);
  const selectReservationsListPageDraw = useSelector(state => state.rent.reservationsListPageDraw);
  const selectReservationsListPagePage = useSelector(state => state.rent.reservationsListPagePage);
  const selectReservationsListTotal = useSelector(state => state.rent.reservationsListTotal);
  const selectReservationsListPageLimit = useSelector(state => state.rent.reservationsListPageLimit);

  return {
    selectResMessage,
    selectContractItemsByContractId,
    selectScanDocumentsByContractId,
    selectSingleCustomer,
    selectSingleContract,
    selectReservationItemsByReservationId,
    selectCreatedReservation,
    selectRelatedCustomers,
    selectGeneratedPdfDocument,
    selectRelatedDeposits,
    selectCreatedDeposit,
    selectRelatedPdfDocuments,
    selectDepositSum,

    selectTemplatePdfsListPageData,
    selectTemplatePdfsListPageDraw,
    selectTemplatePdfsListPagePage,
    selectTemplatePdfsListTotal,
    selectTemplatePdfsListPageLimit,

    selectCustomersListPageData,
    selectCustomersListPageDraw,
    selectCustomersListPagePage,
    selectCustomersListTotal,
    selectCustomersListPageLimit,

    selectContractsListPageData,
    selectContractsListPageDraw,
    selectContractsListPagePage,
    selectContractsListTotal,
    selectContractsListPageLimit,

    selectReservationsListPageData,
    selectReservationsListPageDraw,
    selectReservationsListPagePage,
    selectReservationsListTotal,
    selectReservationsListPageLimit,

    selectTargetCommodityForRentalListPageData,
    selectTargetCommodityForRentalListPageDraw,
    selectTargetCommodityForRentalListPagePage,
    selectTargetCommodityForRentalListTotal,
    selectTargetCommodityForRentalListPageLimit,
  }
}
