import React from 'react'
import { prefixes } from '../../routes'

const CommoditiesList = React.lazy(() => import('./pages/CommoditiesList/CommoditiesList'));
const KegsList = React.lazy(() => import('./pages/KegsList/KegsList'));
const KegsInOutStockLayout = React.lazy(() => import('./pages/KegsInOutStockLayout/KegsInOutStockLayout'));
const KegsManufacturersList = React.lazy(() => import('./pages/KegsManufacturersList/KegsManufacturersList'));
const TemplatesPdfList = React.lazy(() => import('./pages/TemplatesPdfList/TemplatesPdfList'));
const RentingContract = React.lazy(() => import('./pages/RentingContract/RentingContract'));
const Reservations = React.lazy(() => import('./pages/Reservations/Reservations'));
const RentingContractsList = React.lazy(() => import('./pages/RentingContractsList/RentingContractsList'));
const CustomersList = React.lazy(() => import('./pages/CustomersList/CustomersList'));
const ReturnForm = React.lazy(() => import('./pages/ReturnForm/ReturnForm'));
const DepositsList = React.lazy(() => import('./pages/DepositsList/DepositsList'));
export const { kegs } = prefixes;

const kegsRoutes = [
  { path: `${kegs}/zakladani-produktu`, exact: true, name: 'Zakládání produktu', component: CommoditiesList },
  { path: `${kegs}/editace`, exact: true, name: 'Editace', component: KegsList },
  { path: `${kegs}/produkty-povolene`, exact: true, name: 'Produkty zobrazené', component: KegsInOutStockLayout },
  { path: `${kegs}/vyrobci`, exact: true, name: 'Výrobci', component: KegsManufacturersList },
  { path: `${kegs}/vzory-smluv`, exact: true, name: 'Výrobci', component: TemplatesPdfList },
  { path: `${kegs}/vytvoreni-zapujcky`, exact: true, name: 'Vytvoření zápůjčky', component: RentingContract },
  { path: [`${kegs}/prehled-zapujcek`, `${kegs}/prehled-zapujcek/:id`], exact: true, name: 'Přehled zápůjček', component: RentingContractsList },
  { path: `${kegs}/rezervace`, exact: true, name: 'Rezervace', component: Reservations },
  { path: `${kegs}/zakaznici`, exact: true, name: 'Zákazníci', component: CustomersList },
  { path: `${kegs}/ukonceni-zapujcky`, exact: true, name: 'Ukončení zápůjčky', component: ReturnForm },
  { path: `${kegs}/zalohy`, exact: true, name: 'Zálohy', component: DepositsList },
];

export default kegsRoutes;
