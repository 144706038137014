import * as actionTypes from './collection-lists.actionTypes'

// Collection lists list page fetching
export function collectionListsListPageRequest(
  draw,
  page,
  transportationTypesId,
  limit,
  search,
) {
  return {
    type: actionTypes.COLLECTION_LISTS_LIST_PAGE_REQUEST,
    payload: { draw, page, transportationTypesId, limit, search }
  };
}

export function collectionListsListPageResponse(response) {
  return {
    type: actionTypes.COLLECTION_LISTS_LIST_PAGE_RESPONSE,
    payload: response,
  };
}

export function collectionListsListPageFailure(error) {
  return {
    type: actionTypes.COLLECTION_LISTS_LIST_PAGE_FAILURE,
    payload: error,
  };
}

// Collection lists list detail items page fetching
export function collectionListsListDetailItemsPageRequest(
  collectionListId,
  draw,
  page,
  limit,
  search,
) {
  return {
    type: actionTypes.COLLECTION_LISTS_LIST_DETAIL_ITEMS_PAGE_REQUEST,
    payload: { collectionListId, draw, page, limit, search }
  };
}

export function collectionListsListDetailItemsPageResponse(response) {
  return {
    type: actionTypes.COLLECTION_LISTS_LIST_DETAIL_ITEMS_PAGE_RESPONSE,
    payload: response,
  };
}

export function collectionListsListDetailItemsPageFailure(error) {
  return {
    type: actionTypes.COLLECTION_LISTS_LIST_DETAIL_ITEMS_PAGE_FAILURE,
    payload: error,
  };
}

// Change collection list's status
export function collectionListsCollectionListStatusChangeRequest(
  collectionListId, newStatus, sessionId
) {
  return {
    type: actionTypes.COLLECTION_LISTS_COLLECTION_LIST_STATUS_CHANGE_REQUEST,
    payload: { collectionListId, newStatus, sessionId },
  };
}

export function collectionListsCollectionListStatusChangeResponse(response, resMessage) {
  return {
    type: actionTypes.COLLECTION_LISTS_COLLECTION_LIST_STATUS_CHANGE_RESPONSE,
    payload: { response, resMessage },
  };
}

export function collectionListsCollectionListStatusChangeFailure(error, resMessage) {
  return {
    type: actionTypes.COLLECTION_LISTS_COLLECTION_LIST_STATUS_CHANGE_FAILURE,
    payload: { error, resMessage },
  };
}

// Fetch stores
export function collectionListsStoresListRequest() {
  return {
    type: actionTypes.COLLECTION_LISTS_STORES_LIST_REQUEST,
  };
}

export function collectionListsStoresListResponse(response) {
  return {
    type: actionTypes.COLLECTION_LISTS_STORES_LIST_RESPONSE,
    payload: response,
  };
}

export function collectionListsStoresListFailure(error) {
  return {
    type: actionTypes.COLLECTION_LISTS_STORES_LIST_FAILURE,
    payload: error,
  };
}

// Delete collection list item
export function collectionListsCollectionListItemDeleteRequest(
  collectionListItemId
) {
  return {
    type: actionTypes.COLLECTION_LISTS_LIST_DETAIL_ITEM_DELETE_REQUEST,
    payload: { collectionListItemId },
  };
}

export function collectionListsCollectionListItemDeleteResponse(response, resMessage) {
  return {
    type: actionTypes.COLLECTION_LISTS_LIST_DETAIL_ITEM_DELETE_RESPONSE,
    payload: { response, resMessage },
  };
}

export function collectionListsCollectionListItemDeleteFailure(error, resMessage) {
  return {
    type: actionTypes.COLLECTION_LISTS_LIST_DETAIL_ITEM_DELETE_FAILURE,
    payload: { error, resMessage },
  };
}

// Added balikobot orders
export function collectionListsAddedBalikobotOrdersRequest(
  draw,
  page,
  limit,
  shipperId,
  storeId,
  search,
) {
  return {
    type: actionTypes.COLLECTION_LISTS_ADDED_BALIKOBOT_ORDERS_REQUEST,
    payload: { draw, page, limit, shipperId, storeId, search }
  };
}

export function collectionListsAddedBalikobotOrdersResponse(response) {
  return {
    type: actionTypes.COLLECTION_LISTS_ADDED_BALIKOBOT_ORDERS_RESPONSE,
    payload: response
  };
}

export function collectionListsAddedBalikobotOrdersFailure(error) {
  return {
    type: actionTypes.COLLECTION_LISTS_ADDED_BALIKOBOT_ORDERS_FAILURE,
    payload: error
  };
}

// Shippers list fetch
export function collectionListsShippersListRequest() {
  return {
    type: actionTypes.COLLECTION_LISTS_SHIPPERS_LIST_REQUEST,
  };
}

export function collectionListsShippersListResponse(response) {
  return {
    type: actionTypes.COLLECTION_LISTS_SHIPPERS_LIST_RESPONSE,
    payload: response
  };
}

export function collectionListsShippersListFailure(error) {
  return {
    type: actionTypes.COLLECTION_LISTS_SHIPPERS_LIST_FAILURE,
    payload: error
  };
}

// Reset message
export function collectionListsResMessageReset() {
  return {
    type: actionTypes.COLLECTION_LISTS_RESPONSE_MESSAGE_RESET,
  }
}

// Print document
export function collectionListsPrintDocumentRequest(type, itemId, userUid, ident) {
  return {
    type: actionTypes.COLLECTION_LISTS_PRINT_DOCUMENT_REQUEST,
    payload: { type, itemId, userUid, ident }
  };
}

export function collectionListsPrintDocumentResponse(response, resMessage) {
  return {
    type: actionTypes.COLLECTION_LISTS_PRINT_DOCUMENT_RESPONSE,
    payload: { response, resMessage }
  };
}

export function collectionListsPrintDocumentFailure(error, resMessage) {
  return {
    type: actionTypes.COLLECTION_LISTS_PRINT_DOCUMENT_FAILURE,
    payload: { error, resMessage }
  };
}

// Generate PDF request
export function collectionListsGeneratePdfDocumentRequest(type, itemId, userUid, ident) {
  return {
    type: actionTypes.COLLECTION_LISTS_GENERATE_PDF_REQUEST,
    payload: { type, itemId, userUid, ident }
  };
}

export function collectionListsGeneratePdfDocumentResponse(response, resMessage) {
  return {
    type: actionTypes.COLLECTION_LISTS_GENERATE_PDF_RESPONSE,
    payload: { response, resMessage }
  };
}

export function collectionListsGeneratePdfDocumentFailure(error, resMessage) {
  return {
    type: actionTypes.COLLECTION_LISTS_GENERATE_PDF_FAILURE,
    payload: { error, resMessage }
  };
}

// Reset generated pdf
export function collectionListsResetGeneratedPdf() {
  return {
    type: actionTypes.COLLECTION_LISTS_RESET_GENERATED_PDF,
  };
}
