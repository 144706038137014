import { useDispatch } from 'react-redux'
import { useCallback } from 'react'

import * as actions from './administration.actions'
import { useAdministrationSelectors } from './administration.selectors'

export function useAdministrationFacade() {
  const dispatch = useDispatch();
  const selectors = useAdministrationSelectors();

  const {
    selectResMessage: administrationResMessage,
    selectPrintServers: printServers,
    selectPrinterProfiles: printerProfiles,
    selectAppUsers: appUsers,
    selectSelectedTemplate: selectedTemplate,
    selectTemplateSystems: templateSystems,

    selectPrintersPageData: printersPageData,
    selectPrintersPageDraw: printersPageDraw,
    selectPrintersPagePage: printersPagePage,
    selectPrintersPageLimit: printersPageLimit,
    selectPrintersTotal: printersTotal,

    selectIPsPageData: IPsPageData,
    selectIPsPageDraw: IPsPageDraw,
    selectIPsPagePage: IPsPagePage,
    selectIPsPageLimit: IPsPageLimit,
    selectIPsTotal: IPsTotal,

    selectTemplatesData: templatesData,
    selectTemplatesDraw: templatesDraw,
    selectTemplatesPage: templatesPage,
    selectTemplatesLimit: templatesLimit,
    selectTemplatesTotal: templatesTotal,

    selectAbraCommandsPageData: abraCommandsPageData,
    selectAbraCommandsPageDraw: abraCommandsPageDraw,

    selectAirshopNotifications: airshopNotifications,
    selectAirshopNotificationsBackendErrors: airshopNotificationsBackendErrors,
  } = selectors;

  const administrationResetResponseMessage = useCallback(
    () =>
      dispatch(actions.administrationResponseMessageReset()),
    [dispatch]
  );

  const administrationImportFCFileByShop = useCallback(
    (file, shop) =>
      dispatch(actions.administrationImportFastcentrikByShopRequest(file, shop)),
    [dispatch]
  );

  const administrationSyncFCDaysBackByShop = useCallback(
    (shop, daysBackNumber) =>
      dispatch(actions.administrationSyncFCDaysBackByShopRequest(shop, daysBackNumber)),
    [dispatch]
  );

  const fetchPrintServers = useCallback(
    () =>
      dispatch(actions.administrationPrintServersRequest()),
    [dispatch]
  );

  const fetchPrintersList = useCallback(
    (printServerId, draw=1, page=0, limit=20) =>
      dispatch(actions.administrationPrintersListRequest(printServerId, page, draw, limit)),
    [dispatch]
  );

  // Usually used in forEach to fetch multiple profiles, observable stopped with the method below
  const fetchPrinterProfileById = useCallback(
    (printerId) =>
      dispatch(actions.administrationPrinterProfileRequest(printerId)),
    [dispatch]
  );

  const stopObservingOfFetchingPrintersProfiles = useCallback(
    () =>
      dispatch(actions.administrationPrinterProfileStop()),
    [dispatch]
  );

  const updateUserThermalPrinter = useCallback(
    (printerId, userUid) =>
      dispatch(actions.administrationUpdateUserThermalPrinterRequest(printerId, userUid)),
    [dispatch]
  );

  const updateUserA4Printer = useCallback(
    (printerId, userUid) =>
      dispatch(actions.administrationUpdateUserA4PrinterRequest(printerId, userUid)),
    [dispatch]
  );

  const fetchAirshopNotifications = useCallback(
    (ident) =>
      dispatch(actions.administrationAirshopNotificationsRequest(ident)),
    [dispatch]
  );

  const fetchIPAddressesListPageData = useCallback(
    (
      draw=1,
      page=0,
      limit=20,
      search=null,
    ) =>
      dispatch(actions.administrationIPAddressesListPageRequest(draw, page, limit, search)),
    [dispatch]
  );

  const createNewIPAddress = useCallback(
    newIPAddressObject =>
      dispatch(actions.administrationCreateIPAddressRequest(newIPAddressObject)),
    [dispatch]
  );

  const updateIPAddress = useCallback(
    (ipAddressId, updatedIPAddressObject, refetchList=true) =>
      dispatch(actions.administrationUpdateIPAddresRequest(
        ipAddressId, updatedIPAddressObject, refetchList
      )),
    [dispatch]
  );

  const deleteIPAddress = useCallback(
    (ipAddressId) =>
      dispatch(actions.administrationDeleteIPAddresRequest(ipAddressId)),
    [dispatch]
  );

  const fetchAppUsers = useCallback(
    () =>
      dispatch(actions.administrationPartnersListRequest()),
    [dispatch]
  );

  const fetchAbraCommands = useCallback(
    (draw, page=0, limit=100, search=null, commandType=null) =>
      dispatch(actions.administrationAbraCommandsListRequest(
        draw, page, limit, search, commandType
      )),
    [dispatch]
  );

  const updateUserThermalPrinterByAdmin = useCallback(
    (printerId, userUid) =>
      dispatch(actions.administrationUpdateUserThermalPrinterByAdminRequest(printerId, userUid)),
    [dispatch]
  );

  const updateUserA4PrinterByAdmin = useCallback(
    (printerId, userUid) =>
      dispatch(actions.administrationUpdateUserA4PrinterByAdminRequest(printerId, userUid)),
    [dispatch]
  );

  const fetchAirshopNotificationsBackendErrors = useCallback(
    (ident) =>
      dispatch(actions.administrationAirshopNotificationsBackendErrorRequest(ident)),
    [dispatch]
  );

  const fetchTemplatesList = useCallback(
    (draw, page=0, limit=50, search=null, type=null, system=null) =>
      dispatch(actions.administrationTemplatesPageRequest(
        draw, page, limit, search, type, system
      )),
    [dispatch]
  );

  const fetchTemplate = useCallback(
    (templateId) =>
      dispatch(actions.administrationTemplateRequest(
        templateId
      )),
    [dispatch]
  );

  const createTemplate = useCallback(
    (templateData) =>
      dispatch(actions.administrationCreateTemplateRequest(
        templateData
      )),
    [dispatch]
  );

  const editTemplate = useCallback(
    (templateData) =>
      dispatch(actions.administrationEditTemplateRequest(
        templateData
      )),
    [dispatch]
  );

  const deleteTemplate = useCallback(
    (templateId) =>
      dispatch(actions.administrationDeleteTemplateRequest(
        templateId
      )),
    [dispatch]
  );

  const sendTestEmail = useCallback(
    (code, email) =>
      dispatch(actions.administrationSendTestEmailRequest(
        code, email
      )),
    [dispatch]
  );

  const fetchTemplateSystems = useCallback(
    () =>
      dispatch(actions.administrationTemplateSystemsRequest()),
    [dispatch]
  );


  return {
    administrationResetResponseMessage,
    administrationImportFCFileByShop,
    administrationSyncFCDaysBackByShop,
    fetchPrintServers,
    fetchPrintersList,
    fetchPrinterProfileById,
    updateUserThermalPrinter,
    updateUserA4Printer,
    stopObservingOfFetchingPrintersProfiles,
    fetchAirshopNotifications,
    fetchIPAddressesListPageData,
    createNewIPAddress,
    updateIPAddress,
    deleteIPAddress,
    fetchAppUsers,
    fetchAbraCommands,
    updateUserThermalPrinterByAdmin,
    updateUserA4PrinterByAdmin,
    fetchAirshopNotificationsBackendErrors,
    fetchTemplatesList,
    fetchTemplate,
    createTemplate,
    editTemplate,
    deleteTemplate,
    sendTestEmail,
    fetchTemplateSystems,

    printersPageData,
    printersPageDraw,
    printersPagePage,
    printersPageLimit,
    printersTotal,

    templatesData,
    templatesDraw,
    templatesPage,
    templatesLimit,
    templatesTotal,

    IPsPageData,
    IPsPageDraw,
    IPsPagePage,
    IPsPageLimit,
    IPsTotal,

    abraCommandsPageData,
    abraCommandsPageDraw,

    administrationResMessage,
    printServers,
    printerProfiles,
    airshopNotifications,
    airshopNotificationsBackendErrors,
    appUsers,
    selectedTemplate,
    templateSystems,
  }
}
