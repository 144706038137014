import React from 'react'
import { prefixes } from '../../routes'

const StoreCardsList = React.lazy(() => import('./pages/StoreCardsList/StoreCardsList'));
const ProductInOrdersList = React.lazy(() => import('./pages/ProductInOrdersList/ProductInOrdersList'));
const StoreCardsListEditableConsumptionTax = React.lazy(() => import('./pages/StoreCardsListEditableConsumptionTax/StoreCardsListEditableConsumptionTax'));
const StoreCardsForFBFeed = React.lazy(() => import('./pages/StoreCardsForFBFeed/StoreCardsForFBFeed'));
const OneMenuTargets = React.lazy(() => import('./pages/OneMenuTargets/OneMenuTargets'));
const MallTargets = React.lazy(() => import('./pages/MallTargets/MallTargets'));
const ManufacturersList = React.lazy(() => import('./pages/ManufacturersList/ManufacturersList'));

export const { storeCards } = prefixes;
export const storeCardsShopPrefix = 'shop';
export const storeCardsSimpleVerPrefix = 'plu';

const storeCardsRoutes = [
  { path: `${storeCards}/`, exact: true, name: 'Skladové karty', component: StoreCardsList },
  // { path: `${storeCards}/${storeCardsSimpleVerPrefix}`, exact: true, name: 'Skladové karty', component: StoreCardsList },
  { path: `${storeCards}/${storeCardsShopPrefix}/:shop`, exact: true, name: 'Skladové karty', component: StoreCardsList },
  { path: `${storeCards}/zbozi-v-objednavkach`, exact: true, name: 'Zboží v objednávkách', component: ProductInOrdersList },
  { path: `${storeCards}/spotrebni-dan`, exact: true, name: 'Spotřební daň', component: StoreCardsListEditableConsumptionTax },
  { path: `${storeCards}/fb-feed`, exact: true, name: 'Skladové karty pro FB feed', component: StoreCardsForFBFeed },
  { path: [`${storeCards}/targets-onemenu`, `${storeCards}/targets-onemenu/:id`], exact: true, name: 'OneMenu', component: OneMenuTargets },
  { path: [`${storeCards}/targets-mall`, `${storeCards}/targets-mall/:id`], exact: true, name: 'Mall', component: MallTargets },
  { path: `${storeCards}/pivovary`, exact: true, name: 'Pivovary', component: ManufacturersList },
];

export default storeCardsRoutes;
