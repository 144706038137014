import { useSelector } from 'react-redux'
import { IReduxState } from 'models'

export function useUserSelectors() {
  const selectProfileSetup = useSelector((state: IReduxState) => state.user.profileSetup);
  const selectProfileSetupFull = useSelector((state: IReduxState) => state.user.profileSetupFull);
  const selectResMessage = useSelector((state: IReduxState) => state.user.resMessage);
  const selectUserOrdersStateDeliveryCombinations = useSelector((state: IReduxState) => state.user.userOrdersStateDeliveryCombinations);

  return {
    selectProfileSetup,
    selectProfileSetupFull,
    selectResMessage,
    selectUserOrdersStateDeliveryCombinations,
  }
}
