import * as actionTypes from './transfers.actionTypes'
import * as actions from './transfers.actions'
import { AirshopApi } from '../../CraftApi.axios'
import { from, of, iif, defer, forkJoin } from 'rxjs'
import { mergeMap, takeUntil, catchError, map } from 'rxjs/operators'
import { ofType } from 'redux-observable'
import _ from 'lodash'
import { notificationTypes } from 'libs/crud-notifications'
import { err, errMessages, passOnlySuccessForkJoinResponses, handleForkJoinResponses } from 'redux-store/utils/epics.utils'

export const fetchTransferWaysEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.TRANSFERS_TRANSFER_WAYS_REQUEST),
    mergeMap(action => from(AirshopApi.get('transferWays'))
      .pipe(
          map(res => res.data.status === 'OK' ? res.data.items : err(errMessages.NOT_OK, res)),
          map(transferWays => actions.transfersTransferWaysResponse(transferWays)),
          catchError(error => of(actions.transfersTransferWaysFailure(error))),
          takeUntil(action$.ofType(
            actionTypes.TRANSFERS_TRANSFER_WAYS_RESPONSE ||
              actionTypes.TRANSFERS_TRANSFER_WAYS_FAILURE
          ))
      )
    )
  );

export const fetchTransfersPageEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.TRANSFERS_TRANSFERS_LIST_REQUEST),
    mergeMap(action => {
      const {
        draw,
        page,
      } = action.payload;

      const optionalParams = {};
      let finishedTransfersBadgeCount = 0;

      Object.entries(action.payload).forEach(item => {
        const [key, value] = item;
        if (!(value === draw || value === page)) {
          optionalParams[key] = value;
        }
      });

      // Get sidebar batch count for transferred item
      return from(AirshopApi.get('transferQueue/status', {
        params: {
          draw,
          page: 0,
          status: 'FINISHED,OUT',
        }
      }))
      .pipe(
          map(res => res.data.status === 'OK' ? res.data.total : err(errMessages.NOT_OK, res)),
        // Then fetch transfers themselves
          mergeMap(badgeTotal => {
            finishedTransfersBadgeCount = badgeTotal;

            // All transfers case
            if (!optionalParams.status) {
              optionalParams.status = 'QUEUED,FINISHED,TRANSFERED,IN,OUT,DELETED'
            }
            return from(AirshopApi.get('transferQueue/status', {
              params: {
                draw,
                page: 0,
                ...optionalParams,
              }
            }))
            .pipe(
              map(response => response.data.status === 'OK' ? response.data.total : err(errMessages.NOT_OK, response)),
              $fetchPageOfTransfersItems(draw, page, optionalParams),
            )
          }),
          map(tablePageData => actions.trasnfersListPageResponse(tablePageData, finishedTransfersBadgeCount)),
          catchError(error => of(actions.trasnfersListPageFailure(error))
          ),
          takeUntil(action$.ofType(
            actionTypes.TRANSFERS_TRANSFERS_LIST_RESPONSE ||
              actionTypes.TRANSFERS_TRANSFERS_LIST_FAILURE
          ))
      )
    })
  );

  const $fetchPageOfTransfersItems = (draw, page, optionalParams) => {
    return mergeMap(totalItems => from(AirshopApi.get('transferQueue/status', {
      params: {
        draw,
        limit: 50,
        order: 'date_created',
        page: page * 50,
        sort: 'DESC',
        ...optionalParams,
      }
    }
  ))
    .pipe(
      map(response => response.data.status === 'OK' ? {
        items: response.data.items,
        total: response.data.total,
      } : err(errMessages.NOT_OK, response))
    ))
  }

 export const fetchSelectedTransferItemsPageEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.TRANSFERS_TRANSFER_DETAIL_ITEMS_REQUEST),
    mergeMap(action => {
      const {
        draw,
        page,
      } = action.payload;

      const optionalParams = {};
      Object.entries(action.payload).forEach(item => {
        const [key, value] = item;
        if (!(value === draw || value === page)) {
          optionalParams[key] = value;
        }
      });
      return from(AirshopApi.get('transferQueueItems/transferQueueFilter', {
        params: {
          draw,
          page: 0,
          ...optionalParams,
        }
      }))
      .pipe(
          map(response => response.data.status === 'OK' ? response.data.total :  err(errMessages.NOT_OK, response)),
          $fetchPageOfSelectedTransferItems(draw, page, optionalParams),

          map(tablePageData => actions.transferDetailItemsListPageResponse(tablePageData)),
          catchError(error => of(actions.transferDetailItemsListPageFailure(error))
          ),
          takeUntil(action$.ofType(
            actionTypes.TRANSFERS_TRANSFER_DETAIL_ITEMS_RESPONSE ||
              actionTypes.TRANSFERS_TRANSFER_DETAIL_ITEMS_FAILURE
          ))
      )
    })
  );

const $fetchPageOfSelectedTransferItems = (draw, page, optionalParams) =>
  mergeMap((totalItems) => {
    const limitForPage = optionalParams.limit ? optionalParams.limit : 20;

    return from(
      AirshopApi.get('transferQueueItems/transferQueueFilter', {
        params: {
          draw,
          order: 'date_created',
          page: page * limitForPage,
          sort: 'DESC',
          ...optionalParams,
        },
      })
    ).pipe(
      map((response) => {
        if (response.data.status === 'OK') {
          let items = [];
          if (response.data.items && response.data.items.length) {
            // Sort alphabetically by product name
            items = _.sortBy(response.data.items, item => item.storecard.name);
          }
          return {
            items,
            total: response.data.total,
          }
        } else {
          return err(errMessages.NOT_OK, response);
        }
      })
    );
  });

export const createNewTransferEpic = (action$) =>
  action$.pipe(
    ofType(actionTypes.TRANSFERS_CREATE_TRANSFER_REQUEST),
    mergeMap((action) =>
      from(
        AirshopApi.get('transfers/prepare', {
          params: {
            ...action.payload,
          },
        })
      ).pipe(
        map((res) => (res.data.status === 'OK' ? res.data.data : err(errMessages.NOT_OK, res))),
        map((response) =>
          actions.createNewTransferResponse(
            response,
            notificationTypes.CREATE_TRANSFER_SUCCESS
          )
        ),
        catchError((error) =>
          of(
            actions.createNewTransferFailure(
              error,
              notificationTypes.CREATE_TRANSFER_FAILURE
            )
          )
        ),
        takeUntil(
          action$.ofType(
            actionTypes.TRANSFERS_CREATE_TRANSFER_RESPONSE ||
              actionTypes.TRANSFERS_CREATE_TRANSFER_FAILURE
          )
        )
      )
    )
  );

export const changeTransferStatusEpic = (action$) =>
  action$.pipe(
    ofType(actionTypes.TRANSFERS_TRANSFER_CHANGE_STATUS_REQUEST),
    mergeMap((action) => {
      const { transferId, newStatus, ident, partnerUsername } = action.payload;
      return from(
        AirshopApi.get('transferQueue/changeStatus', {
          params: {
            id: transferId,
            status: newStatus,
            ident,
            partnerUsername: partnerUsername,
          },
        })
      ).pipe(
        map((res) => (res.data.status === 'OK' ? res.data.data : err(errMessages.NOT_OK, res))),
        map((response) =>
          actions.changeTransferStatusResponse(
            response,
            notificationTypes.TRANSFER_STATUS_CHANGE_SUCCESS
          )
        ),
        catchError((error) =>
          of(
            actions.changeTransferStatusFailure(
              error,
              notificationTypes.TRANSFER_STATUS_CHANGE_FAILURE
            )
          )
        ),
        takeUntil(
          action$.ofType(
            actionTypes.TRANSFERS_TRANSFER_CHANGE_STATUS_RESPONSE ||
              actionTypes.TRANSFERS_TRANSFER_CHANGE_STATUS_FAILURE
          )
        )
      )
    })
  );

export const createNewTransferDetailItemEpic = (action$) =>
  action$.pipe(
    ofType(actionTypes.TRANSFERS_TRANSFER_DETAIL_CREATE_ITEM_REQUEST),
    mergeMap((action) => from(
        AirshopApi.get('transferQueueItems/create', {
          params: {
            ...action.payload,
          },
        })
      ).pipe(
        map((res) => (res.data.status === 'OK' ? res.data.data : err(errMessages.NOT_OK, res))),
        map((newCreatedItem) =>
          actions.transferDetailCreateItemResponse(
            newCreatedItem,
            notificationTypes.TRANSFER_DETAIL_CREATE_ITEM_SUCCESS
          )
        ),
        catchError((error) =>
          of(
            actions.transferDetailCreateItemFailure(
              error,
              notificationTypes.TRANSFER_DETAIL_CREATE_ITEM_FAILURE
            )
          )
        ),
        takeUntil(
          action$.ofType(
            actionTypes.TRANSFERS_TRANSFER_DETAIL_CREATE_ITEM_RESPONSE ||
              actionTypes.TRANSFERS_TRANSFER_DETAIL_CREATE_ITEM_FAILURE
          )
        )
      )
    )
  );

export const updateTransferDetailItemQuantityEpic = (action$) =>
  action$.pipe(
    ofType(actionTypes.TRANSFERS_TRANSFER_DETAIL_ITEM_UPDATE_QUANTITY_REQUEST),
    mergeMap((action) => from(
        AirshopApi.get('transferQueueItems/updateGet', {
          params: {
            ...action.payload,
          },
        })
      ).pipe(
        map((res) => (res.data.status === 'OK' ? res.data.data : err(errMessages.NOT_OK, res))),
        map((response) =>
          actions.transferDetailUpdateItemQuantityResponse(
            response,
            notificationTypes.TRANSFER_DETAIL_UPDATE_ITEM_QUANTITY_SUCCESS
          )
        ),
        catchError((error) =>
          of(
            actions.transferDetailUpdateItemQuantityFailure(
              error,
              notificationTypes.TRANSFER_DETAIL_UPDATE_ITEM_QUANTITY_FAILURE
            )
          )
        ),
        takeUntil(
          action$.ofType(
            actionTypes.TRANSFERS_TRANSFER_DETAIL_ITEM_UPDATE_QUANTITY_RESPONSE ||
              actionTypes.TRANSFERS_TRANSFER_DETAIL_ITEM_UPDATE_QUANTITY_FAILURE
          )
        )
      )
    )
  );

export const createTransferDetailItemFromOrderEpic = (action$) =>
  action$.pipe(
    ofType(actionTypes.TRANSFERS_CREATE_TRANSFER_DETAIL_ITEM_FROM_ORDER_REQUEST),
    mergeMap((action) => from(
        AirshopApi.get('transferQueueItems/createFromOrder', {
          params: {
            ...action.payload,
          },
        })
      ).pipe(
        map((res) => (res.data.status === 'OK' ? res.data.data : err(errMessages.NOT_OK, res))),
        map((newCreatedTransferItem) =>
          actions.transferDetailCreateItemFromOrderResponse(
            newCreatedTransferItem,
            notificationTypes.TRANSFER_DETAIL_ITEM_FROM_ORDER_SUCCESS
          )
        ),
        catchError((error) =>
          of(
            actions.transferDetailCreateItemFromOrderFailure(
              error,
              notificationTypes.TRANSFER_DETAIL_ITEM_FROM_ORDER_FAILURE
            )
          )
        ),
        takeUntil(
          action$.ofType(
            actionTypes.TRANSFERS_CREATE_TRANSFER_DETAIL_ITEM_FROM_ORDER_RESPONSE ||
              actionTypes.TRANSFERS_CREATE_TRANSFER_DETAIL_ITEM_FROM_ORDER_FAILURE
          )
        )
      )
    )
  );

export const processTransferDetailItemEpic = (action$) =>
  action$.pipe(
    ofType(actionTypes.TRANSFERS_TRANSFER_DETAIL_ITEM_PROCESSING_REQUEST),
    mergeMap((action) => from(
        AirshopApi.get('transferQueueItems/processing', {
          params: {
            ...action.payload,
          },
        })
      ).pipe(
        map((res) => (res.data.status === 'OK' ? res.data.data : err(errMessages.NOT_OK, res))),
        map((response) =>
          actions.processTransferDetailItemResponse(
            response,
            actionTypes.TRANSFERS_TRANSFER_DETAIL_ITEM_PROCESSING_RESPONSE
          )
        ),
        catchError((error) =>
          of(
            actions.processTransferDetailItemFailure(
              error,
              actionTypes.TRANSFERS_TRANSFER_DETAIL_ITEM_PROCESSING_FAILURE
            )
          )
        ),
        takeUntil(
          action$.ofType(
            actionTypes.TRANSFERS_TRANSFER_DETAIL_ITEM_PROCESSING_RESPONSE ||
              actionTypes.TRANSFERS_TRANSFER_DETAIL_ITEM_PROCESSING_FAILURE
          )
        )
      )
    )
  );

export const deleteTransferDetailItemEpic = (action$) =>
  action$.pipe(
    ofType(actionTypes.TRANSFERS_TRANSFER_DETAIL_ITEM_DELETE_REQUEST),
    mergeMap((action) => from(
        AirshopApi.delete('transferQueueItems', {
          params: {
            transferQueueItemsId: action.payload,
          },
        })
      ).pipe(
        map((res) => (res.data.status === 'OK' ? res.data : err(errMessages.NOT_OK, res))),
        map((response) =>
          actions.transferDetailItemDeleteResponse(
            response,
            actionTypes.TRANSFERS_TRANSFER_DETAIL_ITEM_DELETE_RESPONSE
          )
        ),
        catchError((error) =>
          of(
            actions.transferDetailItemDeleteFailure(
              error,
              actionTypes.TRANSFERS_TRANSFER_DETAIL_ITEM_DELETE_FAILURE
            )
          )
        ),
        takeUntil(
          action$.ofType(
            actionTypes.TRANSFERS_TRANSFER_DETAIL_ITEM_DELETE_RESPONSE ||
              actionTypes.TRANSFERS_TRANSFER_DETAIL_ITEM_DELETE_FAILURE
          )
        )
      )
    )
  );

export const deleteTransferEpic = (action$) =>
  action$.pipe(
    ofType(actionTypes.TRANSFERS_TRANSFER_DELETE_REQUEST),
    mergeMap((action) => from(
        AirshopApi.delete('transferQueue', {
          params: {
            transferQueueId: action.payload,
          },
        })
      ).pipe(
        map((res) => (res.data.status === 'OK' ? res.data : err(errMessages.NOT_OK, res))),
        map((response) =>
          actions.transferDeleteResponse(
            response,
            actionTypes.TRANSFERS_TRANSFER_DELETE_RESPONSE
          )
        ),
        catchError((error) =>
          of(
            actions.transferDeleteFailure(
              error,
              actionTypes.TRANSFERS_TRANSFER_DELETE_FAILURE
            )
          )
        ),
        takeUntil(
          action$.ofType(
            actionTypes.TRANSFERS_TRANSFER_DELETE_RESPONSE ||
              actionTypes.TRANSFERS_TRANSFER_DELETE_FAILURE
          )
        )
      )
    )
  );

export const updateTransferEpic = (action$) =>
  action$.pipe(
    ofType(actionTypes.TRANSFERS_TRANSFER_UPDATE_REQUEST),
    mergeMap((action) => from(
        AirshopApi.put('transferQueue', action.payload, {
          params: {
            transferQueueId: action.payload.id,
          }
        })
      ).pipe(
        map((res) => (res.data.status === 'OK' ? res.data : err(errMessages.NOT_OK, res))),
        map((response) =>
          actions.transferUpdateResponse(
            response,
            actionTypes.TRANSFERS_TRANSFER_UPDATE_RESPONSE
          )
        ),
        catchError((error) =>
          of(
            actions.transferUpdateFailure(
              error,
              actionTypes.TRANSFERS_TRANSFER_UPDATE_FAILURE
            )
          )
        ),
        takeUntil(
          action$.ofType(
            actionTypes.TRANSFERS_TRANSFER_UPDATE_RESPONSE ||
              actionTypes.TRANSFERS_TRANSFER_UPDATE_FAILURE
          )
        )
      )
    )
  );

export const updateTransferNoteEpic = (action$) =>
  action$.pipe(
    ofType(actionTypes.TRANSFERS_TRANSFER_UPDATE_NOTE_REQUEST),
    mergeMap((action) => from(
        AirshopApi.get('transferQueue/addNote', {
          params: {
            transferQueueId: action.payload.transferQueueId,
            note: action.payload.note,
          },
        })
      ).pipe(
        map((res) => (res.data.status === 'OK' ? res.data.data : err(errMessages.NOT_OK, res))),
        map((response) =>
          actions.transferUpdateNoteResponse(
            response,
            actionTypes.TRANSFERS_TRANSFER_UPDATE_NOTE_RESPONSE
          )
        ),
        catchError((error) =>
          of(
            actions.transferUpdateNoteFailure(
              error,
              actionTypes.TRANSFERS_TRANSFER_UPDATE_NOTE_FAILURE
            )
          )
        ),
        takeUntil(
          action$.ofType(
            actionTypes.TRANSFERS_TRANSFER_UPDATE_NOTE_RESPONSE ||
              actionTypes.TRANSFERS_TRANSFER_UPDATE_NOTE_FAILURE
          )
        )
      )
    )
  );

export const updateTransferItemNoteEpic = (action$) =>
  action$.pipe(
    ofType(actionTypes.TRANSFERS_TRANSFER_ITEM_UPDATE_NOTE_REQUEST),
    mergeMap((action) => from(
        AirshopApi.get('transferQueueItems/addNote', {
          params: {
            transferQueueItemsId: action.payload.transferQueueItemsId,
            note: action.payload.note,
          },
        })
      ).pipe(
        map((res) => (res.data.status === 'OK' ? res.data.data : err(errMessages.NOT_OK, res))),
        map((response) =>
          actions.transferItemUpdateNoteResponse(
            response,
            actionTypes.TRANSFERS_TRANSFER_ITEM_UPDATE_NOTE_RESPONSE
          )
        ),
        catchError((error) =>
          of(
            actions.transferItemUpdateNoteFailure(
              error,
              actionTypes.TRANSFERS_TRANSFER_ITEM_UPDATE_NOTE_FAILURE
            )
          )
        ),
        takeUntil(
          action$.ofType(
            actionTypes.TRANSFERS_TRANSFER_ITEM_UPDATE_NOTE_RESPONSE ||
              actionTypes.TRANSFERS_TRANSFER_ITEM_UPDATE_NOTE_FAILURE
          )
        )
      )
    )
  );

export const printDocumentEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.TRANSFERS_PRINT_DOCUMENT_REQUEST),
    mergeMap(action => {
      const { type, itemId, userUid, ident } = action.payload;

      return from(AirshopApi.get('print/p/printA4', {
        params: {
          type,
          id: itemId,
          uid: userUid,
          ident,
        }
      }))
      .pipe(
          map(response => response.data.status === 'OK' ? response : err(errMessages.NOT_OK, response)),
          map(response => actions.transfersPrintDocumentResponse(
            response,
            actionTypes.TRANSFERS_PRINT_DOCUMENT_RESPONSE
          )),
          catchError(error => of(actions.transfersPrintDocumentFailure(
            error,
            actionTypes.TRANSFERS_PRINT_DOCUMENT_FAILURE
          ))
          ),
          takeUntil(action$.ofType(
            actionTypes.TRANSFERS_PRINT_DOCUMENT_RESPONSE ||
              actionTypes.TRANSFERS_PRINT_DOCUMENT_FAILURE
          ))
      )
    })
  );

export const generatePdfDocumentEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.TRANSFERS_GENERATE_PDF_REQUEST),
    mergeMap(action => {
      const { type, itemId, userUid, ident } = action.payload;

      return from(AirshopApi.get(
        `print/p/document?type=${type}&id=${itemId}&uid=${userUid}&ident=${ident}`,
        {responseType: 'blob'}))
      .pipe(
        map(response => response.status === 200 && response.data && response.data.type === 'text/xml' ?
                        response.data :
                        err(errMessages.DATA_NOT_GENERATED, response.data)
        ),
        map(response => actions.transfersGeneratePdfDocumentResponse(
          response,
          actionTypes.TRANSFERS_GENERATE_PDF_RESPONSE
        )),
        catchError(error => of(actions.transfersGeneratePdfDocumentFailure(
          error,
          actionTypes.TRANSFERS_GENERATE_PDF_FAILURE
        ))
        ),
        takeUntil(action$.ofType(
          actionTypes.TRANSFERS_GENERATE_PDF_RESPONSE ||
            actionTypes.TRANSFERS_GENERATE_PDF_FAILURE
        ))
      )
    })
  );

export const fetchSalesRequestsEpic = (action$) =>
  action$.pipe(
    ofType(actionTypes.TRANSFERS_SALES_REQUESTS_REQUEST),
    mergeMap((action) => {
      const { draw, page, limit, search } = action.payload;
      return from(
        AirshopApi.get('salesRequest', {
          params: {
            draw, page, limit, search
          },
        })
      ).pipe(
        map((res) => (res.data.status === 'OK' ? res.data.items : err(errMessages.NOT_OK, res))),
        map((response) =>
          actions.transfersSalesRequestsResponse(response)
        ),
        catchError((error) =>
          of(
            actions.transfersSalesRequestsFailure(error)
          )
        ),
        takeUntil(
          action$.ofType(
            actionTypes.TRANSFERS_SALES_REQUESTS_RESPONSE ||
            actionTypes.TRANSFERS_SALES_REQUESTS_FAILURE
          )
        )
      )
    })
  );

export const fetchSalesRequestsItemsEpic = (action$, state$) =>
  action$.pipe(
    ofType(actionTypes.TRANSFERS_SALES_REQUESTS_ITEMS_REQUEST),
    mergeMap((action) => {
      const { salesRequestId, storeId, draw, page, limit, search } = action.payload;
      const tableData = {};
      return from(
        AirshopApi.get('salesRequestItems/salesRequestId', {
          params: {
            salesRequestId, draw, page, limit, search, storeId
          },
        })
      ).pipe(
        map(response => {
          if (response.data.status === 'OK') {
            tableData.items = response.data.items;
            tableData.total = response.data.total;
            return response.data.items;
          } else {
            err(errMessages.NOT_OK, response);
          }
        }),
        mergeMap(items => iif(
          () => items.length,
          defer(() => forkJoin(items.map(item=> AirshopApi.get(
            'storeCards/id',
            { params: { storeCardsId: item.storeCardsId}}
            // If unusual plu, 404, handle by ignoring it and passing only the successful ones
            ).catch(error => of(error)) ))),
          defer(() => of([]))
        )),
        mergeMap(items => iif(
          () => items.length,
          defer(() => of(passOnlySuccessForkJoinResponses(items, false))),
          defer(() => of([]))
        )),
        map(storeCardsResponses => {
          const storeCardsById = { ...state$.value.transfers.relatedSRIStoreCards };
          storeCardsResponses.length && storeCardsResponses.forEach(
            storeCardRes => (storeCardsById[storeCardRes.data.id] = storeCardRes.data)
          );
          return actions.transfersSalesRequestsItemsResponse({
            tableData,
            storeCards: storeCardsById
        })}),
        catchError((error) =>
          of(
            actions.transfersSalesRequestsItemsFailure(error)
          )
        ),
        takeUntil(
          action$.ofType(
            actionTypes.TRANSFERS_SALES_REQUESTS_ITEMS_RESPONSE ||
            actionTypes.TRANSFERS_SALES_REQUESTS_ITEMS_FAILURE
          )
        )
      )
    })
  );

const prepareQueuedAndCheckedReqs = (
  fetchQueuedList, fetchCheckedList,
  queuedTableParams, checkedTableParams, reqs
) => {
  const path = 'salesRequestItems'
  if (fetchQueuedList) {
    reqs.push(
      AirshopApi.get(path, { params: queuedTableParams}),
    )
  }
  if (fetchCheckedList) {
    reqs.push(
      AirshopApi.get(path, { params: checkedTableParams}),
    )
  }
}

export const fetchSalesRequestsItemsQueuedAndCheckedEpic = (action$, state$) =>
  action$.pipe(
    ofType(actionTypes.TRANSFERS_SALES_REQUESTS_ITEMS_QUEUED_AND_CHECKED_REQUEST),
    mergeMap((action) => {
      const { queuedTableParams, checkedTableParams, fetchOptions } = action.payload;
      const { fetchQueuedList, fetchCheckedList } = fetchOptions;
      const indexes = {
        queuedList: fetchQueuedList ? 0 : -1,
        checkedList: fetchCheckedList ? (fetchQueuedList ? 1 : 0) : -1,
      }
      const reqs = [];
      let queuedListData;
      let checkedListData;
      prepareQueuedAndCheckedReqs(
        fetchQueuedList, fetchCheckedList,
        queuedTableParams, checkedTableParams, reqs
      )
      return forkJoin(reqs)
      .pipe(
        map(responses => handleForkJoinResponses(responses)),
        map(responses => {
          queuedListData = indexes.queuedList >= 0 ? {
            items: responses[indexes.queuedList].data.items,
            total: responses[indexes.queuedList].data.total} : null;

          checkedListData = indexes.checkedList >= 0 ? {
            items: responses[indexes.checkedList].data.items,
            total: responses[indexes.checkedList].data.total} : null;

          const storeCardsIds = []
          queuedListData?.items?.length && queuedListData.items.forEach(
            item => storeCardsIds.push(item.storeCardsId)
          )
          checkedListData?.items?.length && checkedListData.items.forEach(
            item => storeCardsIds.push(item.storeCardsId)
          )
          const uniqueSCIds = storeCardsIds.filter((v, i, a) => a.indexOf(v) === i);
          return uniqueSCIds
        }),
        mergeMap(items => iif(
          () => items.length,
          defer(() => forkJoin(items.map(storeCardId => AirshopApi.get(
            'storeCards/id',
            { params: { storeCardsId: storeCardId}}
            // If unusual plu, 404, handle by ignoring it and passing only the successful ones
            ).catch(error => of(error)) ))),
          defer(() => of([]))
        )),
        mergeMap(items => iif(
          () => items.length,
          defer(() => of(passOnlySuccessForkJoinResponses(items, false))),
          defer(() => of([]))
        )),
        map(storeCardsResponses => {
          const storeCardsById = { ...state$.value.transfers.relatedSRIStoreCards };
          storeCardsResponses.length && storeCardsResponses.forEach(
            storeCardRes => (storeCardsById[storeCardRes.data.id] = storeCardRes.data)
          );
          return actions.transfersSalesRequestsItemsQueuedAndCheckedResponse(
            queuedListData, checkedListData, storeCardsById
          )}),
        catchError((error) =>
          of(
            actions.transfersSalesRequestsItemsQueuedAndCheckedFailure(error)
          )
        ),
        takeUntil(
          action$.ofType(
            actionTypes.TRANSFERS_SALES_REQUESTS_ITEMS_QUEUED_AND_CHECKED_RESPONSE ||
            actionTypes.TRANSFERS_SALES_REQUESTS_ITEMS_QUEUED_AND_CHECKED_FAILURE
          )
        )
      )
    })
  );

export const deleteSalesRequestsItemEpic = (action$) =>
  action$.pipe(
    ofType(actionTypes.TRANSFERS_DELETE_SALES_REQUESTS_ITEM_REQUEST),
    mergeMap((action) => from(
        AirshopApi.delete('salesRequestItems', {
          params: {
            salesRequestItemsId: action.payload.salesRequestsItemId,
          },
        })
      ).pipe(
        map((res) => (res.data.status === 'OK' ? res.data : err(errMessages.NOT_OK, res))),
        map((response) =>
          actions.transfersDeleteSalesRequestsItemResponse(
            response,
            actionTypes.TRANSFERS_DELETE_SALES_REQUESTS_ITEM_RESPONSE
          )
        ),
        catchError((error) =>
          of(
            actions.transfersDeleteSalesRequestsItemFailure(
              error,
              actionTypes.TRANSFERS_DELETE_SALES_REQUESTS_ITEM_FAILURE
            )
          )
        ),
        takeUntil(
          action$.ofType(
            actionTypes.TRANSFERS_DELETE_SALES_REQUESTS_ITEM_RESPONSE ||
            actionTypes.TRANSFERS_DELETE_SALES_REQUESTS_ITEM_FAILURE
          )
        )
      )
    )
  );

export const updateSalesRequestsItemEpic = (action$) =>
  action$.pipe(
    ofType(actionTypes.TRANSFERS_UPDATE_SALES_REQUESTS_ITEM_REQUEST),
    mergeMap((action) => from(
        AirshopApi.put('salesRequestItems', action.payload.updatedSRI, {
          params: {
            salesRequestItemsId: action.payload.salesRequestsItemId,
          },
        })
      ).pipe(
        map((res) => (res.data.status === 'OK' ? res.data : err(errMessages.NOT_OK, res))),
        map((response) =>
          actions.transfersUpdateSalesRequestsItemResponse(
            response,
            actionTypes.TRANSFERS_UPDATE_SALES_REQUESTS_ITEM_RESPONSE
          )
        ),
        catchError((error) =>
          of(
            actions.transfersUpdateSalesRequestsItemFailure(
              error,
              actionTypes.TRANSFERS_UPDATE_SALES_REQUESTS_ITEM_FAILURE
            )
          )
        ),
        takeUntil(
          action$.ofType(
            actionTypes.TRANSFERS_UPDATE_SALES_REQUESTS_ITEM_RESPONSE ||
            actionTypes.TRANSFERS_UPDATE_SALES_REQUESTS_ITEM_FAILURE
          )
        )
      )
    )
  );

export const transferDetailItemChangeTransferQueueEpic = (action$) =>
  action$.pipe(
    ofType(actionTypes.TRANSFERS_TRANSFER_DETAIL_ITEM_CHANGE_TRANSFER_QUEUE_REQUEST),
    mergeMap((action) => from(
        AirshopApi.get('transferQueueItems/moveTo', {
          params: {
            transferQueueItemsId: action.payload.transferQueueItemsId,
            transferQueueId: action.payload.transferQueueId,
          },
        })
      ).pipe(
        map((res) => (res.data.status === 'OK' ? res.data : err(errMessages.NOT_OK, res))),
        map((response) =>
          actions.transferDetailItemChangeTransferQueueResponse(
            response,
            actionTypes.TRANSFERS_TRANSFER_DETAIL_ITEM_CHANGE_TRANSFER_QUEUE_RESPONSE
          )
        ),
        catchError((error) =>
          of(
            actions.transferDetailItemChangeTransferQueueFailure(
              error,
              actionTypes.TRANSFERS_TRANSFER_DETAIL_ITEM_CHANGE_TRANSFER_QUEUE_FAILURE
            )
          )
        ),
        takeUntil(
          action$.ofType(
            actionTypes.TRANSFERS_TRANSFER_DETAIL_ITEM_CHANGE_TRANSFER_QUEUE_RESPONSE ||
            actionTypes.TRANSFERS_TRANSFER_DETAIL_ITEM_CHANGE_TRANSFER_QUEUE_FAILURE
          )
        )
      )
    )
  );
