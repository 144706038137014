import { useEffect, useState } from 'react'
import { IUserProfileSetup, EProfileSetupKeys, IPSFullProp } from 'models'
import { useUserFacade } from 'redux-store/user/user.facade'
import { useAuthFacade } from 'redux-store/auth/auth.facade'
import { administrationPrintersPath } from 'page-containers/administration/administration.routes'
import * as authActionsProps from 'redux-store/auth/auth.actions'
import { useHistory } from 'react-router-dom'
import _ from 'lodash'


export const useProfileSetup = () => {

  const {
    profileSetupFull,

    createUserProfileProp,
    updateUserProfileProp,
  } = useUserFacade();

  const getFullProfileSetupProp = (propName: string): IPSFullProp => {
    return _.find(profileSetupFull, { 'key': propName });
  };

  const toggleProfileSetupDarkTheme = (
    updatedDarkMode: 'true' | 'false',
    userProfileSetup: IUserProfileSetup
  ) => {
    const hasDarkModeProp = userProfileSetup.hasOwnProperty(
      EProfileSetupKeys.DARK_MODE
    );

    if (userProfileSetup && hasDarkModeProp) {
      const currentDarkModeFullProp = getFullProfileSetupProp(
        EProfileSetupKeys.DARK_MODE
      );

      currentDarkModeFullProp.value = updatedDarkMode;
      updateUserProfileProp(currentDarkModeFullProp);
    } else if (userProfileSetup && !hasDarkModeProp) {
      createUserProfileProp({
        key: EProfileSetupKeys.DARK_MODE,
        value: updatedDarkMode,
      });
    }
  };

  const setPreselectedOrdersState = (
    ordersStateId: string | null,
    userProfileSetup: IUserProfileSetup
  ) => {
    const hasPreselectedOrdersStateProp = userProfileSetup.hasOwnProperty(
      EProfileSetupKeys.PRESELECTED_ORDERS_STATE
    );

    const currentPreselectedOrdersStateFullProp = getFullProfileSetupProp(
      EProfileSetupKeys.PRESELECTED_ORDERS_STATE
    );

    // OrdersState 'Všechny' has value of null, if 'Všechny' chosen, do not set
    if (userProfileSetup && hasPreselectedOrdersStateProp && ordersStateId) {
      currentPreselectedOrdersStateFullProp.value = ordersStateId;
      updateUserProfileProp(currentPreselectedOrdersStateFullProp);
    } else if (
      userProfileSetup &&
      !hasPreselectedOrdersStateProp &&
      ordersStateId
    ) {
      createUserProfileProp({
        key: EProfileSetupKeys.PRESELECTED_ORDERS_STATE,
        value: ordersStateId,
      });
    }

    // OrdersState 'Všechny' (which has a value of null) set
    if (ordersStateId === null) {
      currentPreselectedOrdersStateFullProp.value = null;
      updateUserProfileProp(currentPreselectedOrdersStateFullProp);
    }

  };

  return {
    toggleProfileSetupDarkTheme,
    setPreselectedOrdersState,
    getFullProfileSetupProp,
  }
}

// Used in App.js
export const useMandatoryPrintersCheckOnAppLoad = (userIpWhitelisted: boolean | null) => {
  // const userIpWhitelisted
  const { profileSetup, createUserProfileProp } = useUserFacade();

  const [mandatoryPrintersSelected, setMandatoryPrintersSelected] = useState<boolean>();

  // MANDATORY PRINTERS
  const checkIfMandatoryPrintersSelected = (
    userProfileSetup: IUserProfileSetup
  ): boolean => {
    const hasMandatoryPrintersSelected = userProfileSetup.hasOwnProperty(
      EProfileSetupKeys.MANDATORY_PRINTERS_SELECTED
    );

    if (userProfileSetup && hasMandatoryPrintersSelected) {
      return userProfileSetup[EProfileSetupKeys.MANDATORY_PRINTERS_SELECTED];

    } else if (userProfileSetup && !hasMandatoryPrintersSelected) {
      createUserProfileProp({
        key: EProfileSetupKeys.MANDATORY_PRINTERS_SELECTED,
        value: null,
      });
      createUserProfileProp({
        key: EProfileSetupKeys.PRINTERS_LOCATION,
        value: null,
      });
      return false;
    }
    return false;
  }

  useEffect(() => {
    if (profileSetup) {
      setMandatoryPrintersSelected(
        checkIfMandatoryPrintersSelected(profileSetup)
      );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileSetup]);

  // useEffect(() => console.log(userIpWhitelisted), [userIpWhitelisted]);

  return { mandatoryPrintersSelected }
}

// Used in page containers
export const useCheckForUserMandatoryPrinters = (
  location: any, userPrintersSelected: boolean | null, userIpWhitelisted: boolean | null
) => {
  const history = useHistory();
  const { getFullProfileSetupProp } = useProfileSetup();
  const { idm, authResMessage, resetAuthResponseMessage, fetchAuthIdm } = useAuthFacade();
  const { updateUserProfileProp } = useUserFacade();

  // If user hasn't selected mandatory printers and the page is any other route than printers administration, redirect to that page
  useEffect(() => {
    if (location && location.pathname && userIpWhitelisted) {
      if (!userPrintersSelected && !location.pathname.includes(administrationPrintersPath)) {
        history.push(administrationPrintersPath, { breadcrumb: 'Tiskárny'});
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[location]);

  /*
    Po uložení tiskárny -> SUCCESS, epic odchycuje, request IDM refetch,
    fetch idm -> effect, odchytit, pak onIdmRefetchUpdateUserProfilePrinters,
    update uset profile printers props -> epic odchycuje, request refetch user profile a reset,
    fetch user profile -> auto ref se aktualizuje
  */

  const onIdmRefetchUpdateUserProfilePrinters = () => {
    const { a4PrinterId: A4printer,  printerId: thermalPrinter } = idm;

    if (idm) {
      const mandatoryPrintersSelectedFullProp = getFullProfileSetupProp(
        EProfileSetupKeys.MANDATORY_PRINTERS_SELECTED
      );

      if (A4printer && thermalPrinter) {
        // IF PRINTERS SELECTED AND STORED IN THE DB
        if (!mandatoryPrintersSelectedFullProp) {
          // If this prop is not in the already fetched profile setup,
          // it was then later created in the background automatically, only it needs to fetch it,
          fetchAuthIdm();
          return;
        }
        // Only string vals are allowed, on prfile setup refetch, they are auto reformatted
        if (mandatoryPrintersSelectedFullProp.value === 'true') {
          // Already both printers selected, do not update
          return;
        } else {
          mandatoryPrintersSelectedFullProp.value = 'true';
        }
      } else {
        // IF NO PRINTERS SELECTED
        if (!mandatoryPrintersSelectedFullProp) {
          // If this prop is not in the profile setup and no printers were selected, end execution
          return;
        }
        mandatoryPrintersSelectedFullProp.value = 'false';
      }

      updateUserProfileProp(mandatoryPrintersSelectedFullProp);
    }
  }

  const onIdmRefetched = () => {
    if (authResMessage) {
      if (authResMessage === authActionsProps.AUTH_IDM_RESPONSE) {
        onIdmRefetchUpdateUserProfilePrinters();
      }

      // Resets also the initial idm fetch from App.js
      if (
        (authResMessage === authActionsProps.AUTH_IDM_RESPONSE) ||
        (authResMessage === authActionsProps.AUTH_IDM_FAILURE)
      ) {
        resetAuthResponseMessage();
      }

    }
  }

  useEffect(() => {
    onIdmRefetched();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authResMessage]);

}
