import * as actionTypes from './rent.actionTypes'

export function rentResetResponseMesage() {
  return {
    type: actionTypes.RENT_RESET_RES_MESSAGE,
  };
}

// Templates list
export function rentTemplatesPdfListRequest(
  draw,
  page,
  limit,
  search,
) {
  return {
    type: actionTypes.RENT_TEMPLATES_PDF_LIST_REQUEST,
    payload: { draw, page, limit, search }
  };
}

export function rentTemplatesPdfListResponse(response) {
  return {
    type: actionTypes.RENT_TEMPLATES_PDF_LIST_RESPONSE,
    payload: response
  };
}

export function rentTemplatesPdfListFailure(error) {
  return {
    type: actionTypes.RENT_TEMPLATES_PDF_LIST_FAILURE,
    payload: error
  };
}

// Create template
export function rentCreateTemplatePdfRequest(newTemplatePdf) {
  return {
    type: actionTypes.RENT_CREATE_TEMPLATE_PDF_REQUEST,
    payload: { newTemplatePdf }
  };
}

export function rentCreateTemplatePdfResponse(response) {
  return {
    type: actionTypes.RENT_CREATE_TEMPLATE_PDF_RESPONSE,
    payload: response
  };
}

export function rentCreateTemplatePdfFailure(error) {
  return {
    type: actionTypes.RENT_CREATE_TEMPLATE_PDF_FAILURE,
    payload: error
  };
}

// Edit template
export function rentEditTemplatePdfRequest(templatePdfId, editedTemplatePdf) {
  return {
    type: actionTypes.RENT_EDIT_TEMPLATE_PDF_REQUEST,
    payload: { templatePdfId, editedTemplatePdf }
  };
}

export function rentEditTemplatePdfResponse(response, resMessage) {
  return {
    type: actionTypes.RENT_EDIT_TEMPLATE_PDF_RESPONSE,
    payload: { response, resMessage }
  };
}

export function rentEditTemplatePdfFailure(error, resMessage) {
  return {
    type: actionTypes.RENT_EDIT_TEMPLATE_PDF_FAILURE,
    payload: { error, resMessage }
  };
}

// Delete template
export function rentDeleteTemplatePdfRequest(templatePdfId) {
  return {
    type: actionTypes.RENT_DELETE_TEMPLATE_PDF_REQUEST,
    payload: { templatePdfId }
  };
}

export function rentDeleteTemplatePdfResponse(response, resMessage) {
  return {
    type: actionTypes.RENT_DELETE_TEMPLATE_PDF_RESPONSE,
    payload: { response, resMessage }
  };
}

export function rentDeleteTemplatePdfFailure(error, resMessage) {
  return {
    type: actionTypes.RENT_DELETE_TEMPLATE_PDF_FAILURE,
    payload: { error, resMessage }
  };
}

// Customers list
export function rentCustomersListRequest(
  draw,
  page,
  limit,
  search,
) {
  return {
    type: actionTypes.RENT_CUSTOMERS_LIST_REQUEST,
    payload: { draw, page, limit, search }
  };
}

export function rentCustomersListResponse(response) {
  return {
    type: actionTypes.RENT_CUSTOMERS_LIST_RESPONSE,
    payload: response
  };
}

export function rentCustomersListFailure(error) {
  return {
    type: actionTypes.RENT_CUSTOMERS_LIST_FAILURE,
    payload: error
  };
}

// Create customer
export function rentCreateCustomerRequest(newCustomer) {
  return {
    type: actionTypes.RENT_CREATE_CUSTOMER_REQUEST,
    payload: { newCustomer }
  };
}

export function rentCreateCustomerResponse(response, resMessage) {
  return {
    type: actionTypes.RENT_CREATE_CUSTOMER_RESPONSE,
    payload: { response, resMessage }
  };
}

export function rentCreateCustomerFailure(error, resMessage) {
  return {
    type: actionTypes.RENT_CREATE_CUSTOMER_FAILURE,
    payload: { error, resMessage }
  };
}

export function rentCreateCustomerReset() {
  return {
    type: actionTypes.RENT_CREATE_CUSTOMER_RESET,
  };
}

// Edit customer
export function rentEditCustomerRequest(customerId, editedCustomer) {
  return {
    type: actionTypes.RENT_EDIT_CUSTOMER_REQUEST,
    payload: { customerId, editedCustomer }
  };
}

export function rentEditCustomerResponse(response, resMessage) {
  return {
    type: actionTypes.RENT_EDIT_CUSTOMER_RESPONSE,
    payload: { response, resMessage }
  };
}

export function rentEditCustomerFailure(error, resMessage) {
  return {
    type: actionTypes.RENT_EDIT_CUSTOMER_FAILURE,
    payload: { error, resMessage }
  };
}

// Contract items by contract id
export function rentContractItemsByContractIdRequest(contractId) {
  return {
    type: actionTypes.RENT_CONTRACT_ITEMS_BY_CONTRACT_ID_REQUEST,
    payload: { contractId }
  };
}

export function rentContractItemsByContractIdResponse(response) {
  return {
    type: actionTypes.RENT_CONTRACT_ITEMS_BY_CONTRACT_ID_RESPONSE,
    payload: response
  };
}

export function rentContractItemsByContractIdFailure(error) {
  return {
    type: actionTypes.RENT_CONTRACT_ITEMS_BY_CONTRACT_ID_FAILURE,
    payload: error
  };
}

// Create multiple contract items
export function rentCreateMultipleContractItemsRequest(newContractItemsArr) {
  return {
    type: actionTypes.RENT_CREATE_MULTIPLE_CONTRACT_ITEMS_REQUEST,
    payload: { newContractItemsArr }
  };
}

export function rentCreateMultipleContractItemsResponse(response, resMessage) {
  return {
    type: actionTypes.RENT_CREATE_MULTIPLE_CONTRACT_ITEMS_RESPONSE,
    payload: { response, resMessage }
  };
}

export function rentCreateMultipleContractItemsFailure(error, resMessage) {
  return {
    type: actionTypes.RENT_CREATE_MULTIPLE_CONTRACT_ITEMS_FAILURE,
    payload: { error, resMessage }
  };
}

// Delete contract item
export function rentDeleteContractItemRequest(contractItemId) {
  return {
    type: actionTypes.RENT_DELETE_CONTRACT_ITEM_REQUEST,
    payload: { contractItemId }
  };
}

export function rentDeleteContractItemResponse(response, resMessage) {
  return {
    type: actionTypes.RENT_DELETE_CONTRACT_ITEM_RESPONSE,
    payload: { response, resMessage }
  };
}

export function rentDeleteContractItemFailure(error, resMessage) {
  return {
    type: actionTypes.RENT_DELETE_CONTRACT_ITEM_FAILURE,
    payload: { error, resMessage }
  };
}

// Create contract
export function rentCreateContractRequest(newContract) {
  return {
    type: actionTypes.RENT_CREATE_CONTRACT_REQUEST,
    payload: { newContract }
  };
}

export function rentCreateContractResponse(response, resMessage) {
  return {
    type: actionTypes.RENT_CREATE_CONTRACT_RESPONSE,
    payload: { response, resMessage }
  };
}

export function rentCreateContractFailure(error, resMessage) {
  return {
    type: actionTypes.RENT_CREATE_CONTRACT_FAILURE,
    payload: { error, resMessage }
  };
}

export function rentCreateContractReset() {
  return {
    type: actionTypes.RENT_CREATE_CONTRACT_RESET,
  };
}

// Edit contract
export function rentEditContractRequest(contractId, editedContract) {
  return {
    type: actionTypes.RENT_EDIT_CONTRACT_REQUEST,
    payload: { contractId, editedContract }
  };
}

export function rentEditContractResponse(response, resMessage) {
  return {
    type: actionTypes.RENT_EDIT_CONTRACT_RESPONSE,
    payload: { response, resMessage }
  };
}

export function rentEditContractFailure(error, resMessage) {
  return {
    type: actionTypes.RENT_EDIT_CONTRACT_FAILURE,
    payload: { error, resMessage }
  };
}

// Delete contract
export function rentDeleteContractRequest(contractId) {
  return {
    type: actionTypes.RENT_DELETE_CONTRACT_REQUEST,
    payload: { contractId }
  };
}

export function rentDeleteContractResponse(response, resMessage) {
  return {
    type: actionTypes.RENT_DELETE_CONTRACT_RESPONSE,
    payload: { response, resMessage }
  };
}

export function rentDeleteContractFailure(error, resMessage) {
  return {
    type: actionTypes.RENT_DELETE_CONTRACT_FAILURE,
    payload: { error, resMessage }
  };
}

// Contracts list
export function rentContractsListRequest(
  draw,
  page,
  limit,
  search,
  status,
  customerId,
  partnersId,
  fetchDeposits,
  fetchDocuments,
) {
  return {
    type: actionTypes.RENT_CONTRACTS_LIST_REQUEST,
    payload: { draw, page, limit, search, status, customerId, partnersId, fetchDeposits, fetchDocuments }
  };
}

export function rentContractsListResponse(response) {
  return {
    type: actionTypes.RENT_CONTRACTS_LIST_RESPONSE,
    payload: response
  };
}

export function rentContractsListFailure(error) {
  return {
    type: actionTypes.RENT_CONTRACTS_LIST_FAILURE,
    payload: error
  };
}

// Scan documents by contract id
export function rentScanDocumentsListByContractIdRequest(contractId) {
  return {
    type: actionTypes.RENT_SCAN_DOCUMENTS_LIST_BY_CONTRACT_ID_REQUEST,
    payload: { contractId }
  };
}

export function rentScanDocumentsListByContractIdResponse(response) {
  return {
    type: actionTypes.RENT_SCAN_DOCUMENTS_LIST_BY_CONTRACT_ID_RESPONSE,
    payload: response
  };
}

export function rentScanDocumentsListByContractIdFailure(error) {
  return {
    type: actionTypes.RENT_SCAN_DOCUMENTS_LIST_BY_CONTRACT_ID_FAILURE,
    payload: error
  };
}

// Create scan document
export function rentCreateScanDocumentRequest(newScanDocument, contractId, type) {
  return {
    type: actionTypes.RENT_CREATE_SCAN_DOCUMENT_REQUEST,
    payload: { newScanDocument, contractId, type }
  };
}

export function rentCreateScanDocumentResponse(response) {
  return {
    type: actionTypes.RENT_CREATE_SCAN_DOCUMENT_RESPONSE,
    payload: response
  };
}

export function rentCreateScanDocumentFailure(error) {
  return {
    type: actionTypes.RENT_CREATE_SCAN_DOCUMENT_FAILURE,
    payload: error
  };
}

// Delete scan document
export function rentDeleteScanDocumentRequest(scanDocumentId) {
  return {
    type: actionTypes.RENT_DELETE_SCAN_DOCUMENT_REQUEST,
    payload: { scanDocumentId }
  };
}

export function rentDeleteScanDocumentResponse(response) {
  return {
    type: actionTypes.RENT_DELETE_SCAN_DOCUMENT_RESPONSE,
    payload: response
  };
}

export function rentDeleteScanDocumentFailure(error) {
  return {
    type: actionTypes.RENT_DELETE_SCAN_DOCUMENT_FAILURE,
    payload: error
  };
}

// Commodity targets for rental list
export function renttargetCommodityForRentalListRequest(
  draw,
  page,
  limit,
  search,
) {
  return {
    type: actionTypes.RENT_TARGET_COMMODITY_FOR_RENTAL_LIST_REQUEST,
    payload: { draw, page, limit, search }
  };
}

export function renttargetCommodityForRentalListResponse(response) {
  return {
    type: actionTypes.RENT_TARGET_COMMODITY_FOR_RENTAL_LIST_RESPONSE,
    payload: response
  };
}

export function renttargetCommodityForRentalListFailure(error) {
  return {
    type: actionTypes.RENT_TARGET_COMMODITY_FOR_RENTAL_LIST_FAILURE,
    payload: error
  };
}

// Reservations list
export function rentReservationsListRequest(
  draw,
  page,
  limit,
  search,
) {
  return {
    type: actionTypes.RENT_RESERVATIONS_LIST_REQUEST,
    payload: { draw, page, limit, search }
  };
}

export function rentReservationsListResponse(response) {
  return {
    type: actionTypes.RENT_RESERVATIONS_LIST_RESPONSE,
    payload: response
  };
}

export function rentReservationsListFailure(error) {
  return {
    type: actionTypes.RENT_RESERVATIONS_LIST_FAILURE,
    payload: error
  };
}

// Create reservation
export function rentCreateReservationRequest(newReservation) {
  return {
    type: actionTypes.RENT_CREATE_RESERVATION_REQUEST,
    payload: { newReservation }
  };
}

export function rentCreateReservationResponse(response, resMessage) {
  return {
    type: actionTypes.RENT_CREATE_RESERVATION_RESPONSE,
    payload: { response, resMessage }
  };
}

export function rentCreateReservationFailure(error, resMessage) {
  return {
    type: actionTypes.RENT_CREATE_RESERVATION_FAILURE,
    payload: { error, resMessage }
  };
}

export function rentCreateReservationReset() {
  return {
    type: actionTypes.RENT_CREATE_RESERVATION_RESET,
  };
}

// Edit reservation
export function rentEditReservationRequest(reservationId, editedReservation) {
  return {
    type: actionTypes.RENT_EDIT_RESERVATION_REQUEST,
    payload: { reservationId, editedReservation }
  };
}

export function rentEditReservationResponse(response, resMessage) {
  return {
    type: actionTypes.RENT_EDIT_RESERVATION_RESPONSE,
    payload: { response, resMessage }
  };
}

export function rentEditReservationFailure(error, resMessage) {
  return {
    type: actionTypes.RENT_EDIT_RESERVATION_FAILURE,
    payload: { error, resMessage }
  };
}

// Reservation items list
export function rentReservationItemsListByReservationIdRequest(reservationId) {
  return {
    type: actionTypes.RENT_RESERVATION_ITEMS_LIST_BY_RESERVATION_ID_REQUEST,
    payload: { reservationId }
  };
}

export function rentReservationItemsListByReservationIdResponse(response) {
  return {
    type: actionTypes.RENT_RESERVATION_ITEMS_LIST_BY_RESERVATION_ID_RESPONSE,
    payload: response
  };
}

export function rentReservationItemsListByReservationIdFailure(error) {
  return {
    type: actionTypes.RENT_RESERVATION_ITEMS_LIST_BY_RESERVATION_ID_FAILURE,
    payload: error
  };
}

// Create reservation item
export function rentCreateReservationItemRequest(newReservationItem) {
  return {
    type: actionTypes.RENT_CREATE_RESERVATION_ITEM_REQUEST,
    payload: { newReservationItem }
  };
}

export function rentCreateReservationItemResponse(response, resMessage) {
  return {
    type: actionTypes.RENT_CREATE_RESERVATION_ITEM_RESPONSE,
    payload: { response, resMessage }
  };
}

export function rentCreateReservationItemFailure(error, resMessage) {
  return {
    type: actionTypes.RENT_CREATE_RESERVATION_ITEM_FAILURE,
    payload: { error, resMessage }
  };
}

// Delete reservation item
export function rentDeleteReservationItemRequest(reservationItemsId) {
  return {
    type: actionTypes.RENT_DELETE_RESERVATION_ITEM_REQUEST,
    payload: { reservationItemsId }
  };
}

export function rentDeleteReservationItemResponse(response, resMessage) {
  return {
    type: actionTypes.RENT_DELETE_RESERVATION_ITEM_RESPONSE,
    payload: { response, resMessage }
  };
}

export function rentDeleteReservationItemFailure(error, resMessage) {
  return {
    type: actionTypes.RENT_DELETE_RESERVATION_ITEM_FAILURE,
    payload: { error, resMessage }
  };
}

// Finalize contract
export function rentFinalizeContractRequest(contractId) {
  return {
    type: actionTypes.RENT_FINALIZE_CONTRACT_REQUEST,
    payload: { contractId }
  };
}

export function rentFinalizeContractResponse(response, resMessage) {
  return {
    type: actionTypes.RENT_FINALIZE_CONTRACT_RESPONSE,
    payload: { response, resMessage }
  };
}

export function rentFinalizeContractFailure(error, resMessage) {
  return {
    type: actionTypes.RENT_FINALIZE_CONTRACT_FAILURE,
    payload: { error, resMessage }
  };
}

// Delete reservation
export function rentDeleteReservationRequest(reservationId) {
  return {
    type: actionTypes.RENT_DELETE_RESERVATION_REQUEST,
    payload: { reservationId }
  };
}

export function rentDeleteReservationResponse(response, resMessage) {
  return {
    type: actionTypes.RENT_DELETE_RESERVATION_RESPONSE,
    payload: { response, resMessage }
  };
}

export function rentDeleteReservationFailure(error, resMessage) {
  return {
    type: actionTypes.RENT_DELETE_RESERVATION_FAILURE,
    payload: { error, resMessage }
  };
}

// Customer
export function rentCustomerRequest(customerId) {
  return {
    type: actionTypes.RENT_CUSTOMER_REQUEST,
    payload: { customerId }
  };
}

export function rentCustomerResponse(response) {
  return {
    type: actionTypes.RENT_CUSTOMER_RESPONSE,
    payload: response
  };
}

export function rentCustomerFailure(error) {
  return {
    type: actionTypes.RENT_CUSTOMER_FAILURE,
    payload: error
  };
}

// Generate PDF request
export function rentGeneratePdfDocumentRequest(type, itemId) {
  return {
    type: actionTypes.RENT_GENERATE_PDF_REQUEST,
    payload: { type, itemId }
  };
}

export function rentGeneratePdfDocumentResponse(response, resMessage) {
  return {
    type: actionTypes.RENT_GENERATE_PDF_RESPONSE,
    payload: { response, resMessage }
  };
}

export function rentGeneratePdfDocumentFailure(error, resMessage) {
  return {
    type: actionTypes.RENT_GENERATE_PDF_FAILURE,
    payload: { error, resMessage }
  };
}

export function rentGeneratePdfDocumentReset() {
  return {
    type: actionTypes.RENT_GENERATE_PDF_RESET,
  };
}

// Create deposit
export function rentCreateDepositRequest(newDeposit) {
  return {
    type: actionTypes.RENT_CREATE_DEPOSIT_REQUEST,
    payload: { newDeposit }
  };
}

export function rentCreateDepositResponse(response, resMessage) {
  return {
    type: actionTypes.RENT_CREATE_DEPOSIT_RESPONSE,
    payload: { response, resMessage }
  };
}

export function rentCreateDepositFailure(error, resMessage) {
  return {
    type: actionTypes.RENT_CREATE_DEPOSIT_FAILURE,
    payload: { error, resMessage }
  };
}

export function rentCreateDepositReset() {
  return {
    type: actionTypes.RENT_CREATE_DEPOSIT_RESET,
  };
}

// Edit deposit
export function rentEditDepositRequest(depositId, editedDeposit) {
  return {
    type: actionTypes.RENT_EDIT_DEPOSIT_REQUEST,
    payload: { depositId, editedDeposit }
  };
}

export function rentEditDepositResponse(response, resMessage) {
  return {
    type: actionTypes.RENT_EDIT_DEPOSIT_RESPONSE,
    payload: { response, resMessage }
  };
}

export function rentEditDepositFailure(error, resMessage) {
  return {
    type: actionTypes.RENT_EDIT_DEPOSIT_FAILURE,
    payload: { error, resMessage }
  };
}

// Transform reservation to contract
export function rentTransformReservationToContractRequest(
  reservationId, paymentType
) {
  return {
    type: actionTypes.RENT_TRANSFORM_RESERVATION_TO_CONTRACT_REQUEST,
    payload: { reservationId, paymentType }
  };
}

export function rentTransformReservationToContractResponse(
  response, resMessage
) {
  return {
    type: actionTypes.RENT_TRANSFORM_RESERVATION_TO_CONTRACT_RESPONSE,
    payload: { response, resMessage }
  };
}

export function rentTransformReservationToContractFailure(
  error, resMessage
) {
  return {
    type: actionTypes.RENT_TRANSFORM_RESERVATION_TO_CONTRACT_FAILURE,
    payload: { error, resMessage }
  };
}

// Remind
export function rentRemindRentContractRequest(
  contractId
) {
  return {
    type: actionTypes.RENT_REMIND_RENT_CONTRACT_REQUEST,
    payload: { contractId }
  };
}

export function rentRemindRentContractResponse(
  response, resMessage
) {
  return {
    type: actionTypes.RENT_REMIND_RENT_CONTRACT_RESPONSE,
    payload: { response, resMessage }
  };
}

export function rentRemindRentContractFailure(
  error, resMessage
) {
  return {
    type: actionTypes.RENT_REMIND_RENT_CONTRACT_FAILURE,
    payload: { error, resMessage }
  };
}

// Print document
export function rentPrintDocumentRequest(
  type, itemId
) {
  return {
    type: actionTypes.RENT_PRINT_DOCUMENT_REQUEST,
    payload: { type, itemId }
  };
}

export function rentPrintDocumentResponse(
  response, resMessage
) {
  return {
    type: actionTypes.RENT_PRINT_DOCUMENT_RESPONSE,
    payload: { response, resMessage }
  };
}

export function rentPrintDocumentFailure(
  error, resMessage
) {
  return {
    type: actionTypes.RENT_PRINT_DOCUMENT_FAILURE,
    payload: { error, resMessage }
  };
}

// Deposit sum
export function rentDepositSumRequest(
  createdGte, createdLte, paymentType, status
) {
  return {
    type: actionTypes.RENT_DEPOSIT_SUM_REQUEST,
    payload: { createdGte, createdLte, paymentType, status }
  };
}

export function rentDepositSumResponse(
  response
) {
  return {
    type: actionTypes.RENT_DEPOSIT_SUM_RESPONSE,
    payload: response
  };
}

export function rentDepositSumFailure(
  error
) {
  return {
    type: actionTypes.RENT_DEPOSIT_SUM_FAILURE,
    payload: error
  };
}

// Finalize return
export function rentFinalizeReturnRequest(
  contractId
) {
  return {
    type: actionTypes.RENT_FINALIZE_RETURN_REQUEST,
    payload: { contractId }
  };
}

export function rentFinalizeReturnResponse(
  response, resMessage
) {
  return {
    type: actionTypes.RENT_FINALIZE_RETURN_RESPONSE,
    payload: { response, resMessage }
  };
}

export function rentFinalizeReturnFailure(
  error, resMessage
) {
  return {
    type: actionTypes.RENT_FINALIZE_RETURN_FAILURE,
    payload: { error, resMessage }
  };
}

// Contract documents
export function rentContractDocumentsRequest(
  contractId
) {
  return {
    type: actionTypes.RENT_CONTRACT_DOCUMENTS_REQUEST,
    payload: { contractId }
  };
}

export function rentContractDocumentsResponse(
  response
) {
  return {
    type: actionTypes.RENT_CONTRACT_DOCUMENTS_RESPONSE,
    payload: response
  };
}

export function rentContractDocumentsFailure(
  error
) {
  return {
    type: actionTypes.RENT_CONTRACT_DOCUMENTS_FAILURE,
    payload: error
  };
}

// Prepare contract from contract
export function rentPrepareContractFromContractRequest(
  contractId, depositId, paymentType, timestampFrom, timestampTo
) {
  return {
    type: actionTypes.RENT_PREPARE_CONTRACT_FROM_CONTRACT_REQUEST,
    payload: { contractId, depositId, paymentType, timestampFrom, timestampTo }
  };
}

export function rentPrepareContractFromContractResponse(
  response, resMessage
) {
  return {
    type: actionTypes.RENT_PREPARE_CONTRACT_FROM_CONTRACT_RESPONSE,
    payload: { response, resMessage }
  };
}

export function rentPrepareContractFromContractFailure(
  error, resMessage
) {
  return {
    type: actionTypes.RENT_PREPARE_CONTRACT_FROM_CONTRACT_FAILURE,
    payload: { error, resMessage }
  };
}

// Prepare reservation from contract
export function rentPrepareReservationFromContractRequest(
  contractId, depositId, timestampFrom, timestampTo
) {
  return {
    type: actionTypes.RENT_PREPARE_RESERVATION_FROM_CONTRACT_REQUEST,
    payload: { contractId, depositId, timestampFrom, timestampTo }
  };
}

export function rentPrepareReservationFromContractResponse(
  response, resMessage
) {
  return {
    type: actionTypes.RENT_PREPARE_RESERVATION_FROM_CONTRACT_RESPONSE,
    payload: { response, resMessage }
  };
}

export function rentPrepareReservationFromContractFailure(
  error, resMessage
) {
  return {
    type: actionTypes.RENT_PREPARE_RESERVATION_FROM_CONTRACT_FAILURE,
    payload: { error, resMessage }
  };
}
