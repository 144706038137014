import * as actionTypes from './user.actionTypes'
import { IPSKeyValObject, IPSFullProp } from 'models'

// Profile setup
export function userProfileSetupRequest() {
  return {
    type: actionTypes.USER_PROFILE_SETUP_REQUEST,
  };
}

export function userProfileSetupResponse(response: any) {
  return {
    type: actionTypes.USER_PROFILE_SETUP_RESPONSE,
    payload: response
  };
}

export function userProfileSetupFailure(error: any) {
  return {
    type: actionTypes.USER_PROFILE_SETUP_FAILURE,
    payload: error
  };
}

// Profile setup create prop
export function userProfileSetupCreatePropRequest({key, value}: IPSKeyValObject) {
  return {
    type: actionTypes.USER_PROFILE_SETUP_CREATE_PROP_REQUEST,
    payload: {key, value}
  };
}

export function userProfileSetupCreatePropResponse(response: any) {
  return {
    type: actionTypes.USER_PROFILE_SETUP_CREATE_PROP_RESPONSE,
    payload: response
  };
}

export function userProfileSetupCreatePropFailure(error: any) {
  return {
    type: actionTypes.USER_PROFILE_SETUP_CREATE_PROP_FAILURE,
    payload: error
  };
}

// Profile setup edit prop
export function userProfileSetupUpdateRequest(updatedFullProp: IPSFullProp) {
  return {
    type: actionTypes.USER_PROFILE_SETUP_UPDATE_REQUEST,
    payload: updatedFullProp
  };
}

export function userProfileSetupUpdateResponse(response: any, resMessage: string) {
  return {
    type: actionTypes.USER_PROFILE_SETUP_UPDATE_RESPONSE,
    payload: { response, resMessage }
  };
}

export function userProfileSetupUpdateFailure(error: any) {
  return {
    type: actionTypes.USER_PROFILE_SETUP_UPDATE_FAILURE,
    payload: error
  };
}

// Profile setup delete prop
export function userProfileSetupDeletePropRequest(propId: number) {
  return {
    type: actionTypes.USER_PROFILE_SETUP_DELETE_PROP_REQUEST,
    payload: propId
  };
}

export function userProfileSetupDeletePropResponse(response: any) {
  return {
    type: actionTypes.USER_PROFILE_SETUP_DELETE_PROP_RESPONSE,
    payload: response
  };
}

export function userProfileSetupDeletePropFailure(error: any) {
  return {
    type: actionTypes.USER_PROFILE_SETUP_DELETE_PROP_FAILURE,
    payload: error
  };
}

// Reset response messages
export function userResponseMessageReset() {
  return {
    type: actionTypes.USER_RESPONSE_MESSAGE_RESET,
  };
}


