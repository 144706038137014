import {
  transfers,
  transferStatusPrefix,
} from 'page-containers/transfers/transfers.routes'

import {
  collectionLists,
  collectionListTransPrefix,
} from 'page-containers/collection-lists/collectionLists.routes'

import {
  storeCards,
  storeCardsShopPrefix,
  storeCardsSimpleVerPrefix,
} from 'page-containers/store-cards/storeCards.routes'

import {
  administration,
} from 'page-containers/administration/administration.routes'

import {
  mailchimp,
  mailchimpShopPrefix,
} from 'page-containers/mailchimp/mailchimp.routes'

import { rating } from 'page-containers/rating/rating.routes'
import { kegs } from 'page-containers/kegs/kegs.routes'

import { userRoles } from 'utils/user-roles'

export const emailsNavItems = [
  {
    _tag: 'CSidebarNavTitle',
    _children: ['Emaily'],
    mode: 'production',
    master: 'true'
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Příchozí',
    to: {pathname: '/emaily/prichozi', breadcrumb: 'Příchozí emaily'},
    icon: 'cil-envelope-closed',
    mode: 'production',
    master: 'true'
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Odchozí',
    to: {pathname: '/emaily/odchozi', breadcrumb: 'Odchozí emaily'},
    icon: 'cil-envelope-closed',
    mode: 'production',
    master: 'true'
  },
]

export const storeCardsNavItems = [
  {
    _tag: 'CSidebarNavTitle',
    _children: ['Skladové karty'],
    mode: 'production',
    master: 'true'
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Všechny',
    to: {pathname: `${storeCards}`, breadcrumb: 'Všechny'},
    icon: 'cil-notes',
    mode: 'production',
    master: 'true'
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Beershop',
    to: {pathname: `${storeCards}/${storeCardsShopPrefix}/BEERSHOP`, breadcrumb: 'Beershop'},
    icon: 'cil-notes',
    mode: 'production',
    master: 'true'
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Delikatesy',
    to: {pathname: `${storeCards}/${storeCardsShopPrefix}/DELIKATESY`, breadcrumb: 'Delikatesy'},
    icon: 'cil-notes',
    mode: 'production',
    master: 'true'
  },
  // {
  //   _tag: 'CSidebarNavItem',
  //   name: 'PLU',
  //   to: {pathname: `${storeCards}/${storeCardsSimpleVerPrefix}`, breadcrumb: 'PLU'},
  //   icon: 'cil-notes',
  //   mode: 'production',
  //   master: 'true'
  // },
  {
    _tag: 'CSidebarNavItem',
    name: 'Zboží v objednávkách',
    to: {pathname: `${storeCards}/zbozi-v-objednavkach`, breadcrumb: 'Zboží v objednávkách'},
    icon: 'cil-notes',
    mode: 'production',
    master: 'true'
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'EAN + Spotřební daň',
    to: {pathname: `${storeCards}/spotrebni-dan`, breadcrumb: 'Spotřební daň'},
    icon: 'cil-notes',
    mode: 'production',
    master: 'true'
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Feed novinky BS/DELI',
    to: {pathname: `${storeCards}/fb-feed`, breadcrumb: 'Feed novinky'},
    icon: 'cil-notes',
    mode: 'production',
    master: 'true'
  },
  // {
  //   _tag: 'CSidebarNavItem',
  //   name: 'OneMenu',
  //   to: {pathname: `${storeCards}/targets-onemenu`},
  //   icon: 'cil-notes',
  //   // mode: 'production',
  //   // master: 'true'
  // },
  {
    _tag: 'CSidebarNavItem',
    name: 'Mall',
    to: {pathname: `${storeCards}/targets-mall`},
    icon: 'cil-notes',
    mode: 'production',
    master: 'true'
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Pivovary',
    to: {pathname: `${storeCards}/pivovary`},
    icon: 'cil-notes',
    mode: 'production',
    master: 'true'
  }
]

export const administrationNavItems = () => {
  const { admin } = userRoles;
  return [
  {
    _tag: 'CSidebarNavTitle',
    _children: ['Administrace'],
    mode: 'production',
    master: 'true'
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Uživatelské nastavení',
    to: {pathname: `${administration}/uzivatelske-nastaveni`, breadcrumb: 'Uživatelské nastavení'},
    icon: 'cil-settings',
    mode: 'production',
    master: 'true',
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'XML import produktu',
    to: {pathname: `${administration}/import-xml`, breadcrumb: 'XML import produktu'},
    icon: 'cil-settings',
    mode: 'production',
    master: 'true',
    roles: [admin],
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Import zpětně',
    to: {pathname: `${administration}/import-zpetne`, breadcrumb: 'Import zpětně'},
    icon: 'cil-settings',
    mode: 'production',
    master: 'true',
    roles: [admin],
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Nastavit si tiskárny',
    to: {pathname: `${administration}/tiskarny`, breadcrumb: 'Nastavit si tiskárny'},
    icon: 'cil-settings',
    mode: 'production',
    master: 'true'
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Nastavit tiskárny uživatelům',
    to: {pathname: `${administration}/tiskarny-admin`, breadcrumb: 'Nastavit tiskárny uživatelům'},
    icon: 'cil-settings',
    mode: 'production',
    master: 'true'
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'IP adresy',
    to: {pathname: `${administration}/ip-adresy`, breadcrumb: 'IP adresy'},
    icon: 'cil-settings',
    mode: 'production',
    master: 'true',
    roles: [admin],
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Uživatelé',
    to: {pathname: `${administration}/uzivatele`, breadcrumb: 'Uživatelé'},
    icon: 'cil-settings',
    mode: 'production',
    master: 'true',
    roles: [admin],
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Příkazy Abry',
    to: {pathname: `${administration}/prikazy-abry`, breadcrumb: 'Příkazy Abry'},
    icon: 'cil-settings',
    mode: 'production',
    master: 'true',
    roles: [admin],
  },
  // {
  //   _tag: 'CSidebarNavItem',
  //   name: 'Email šablony',
  //   to: {pathname: `${administration}/email-sablony`, breadcrumb: 'Email šablony'},
  //   icon: 'cil-settings',
  //   mode: 'production',
  //   master: 'true',
  //   roles: [admin],
  // },
  // {
  //   _tag: 'CSidebarNavItem',
  //   name: 'SMS šablony',
  //   to: {pathname: `${administration}/sms-sablony`, breadcrumb: 'SMS šablony'},
  //   icon: 'cil-settings',
  //   mode: 'production',
  //   master: 'true',
  //   roles: [admin],
  // },
]}

// STATUS set in onTranferSelected function on TransfersList.js and passed in TheSidebar.js
export const transfersNavItems = (status=null, badgeCount) => {
  return [
  {
    _tag: 'CSidebarNavTitle',
    _children: ['Převodky'],
    mode: 'production',
    master: 'true'
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Všechny',
    to: {pathname: `${transfers}`, breadcrumb: 'Všechny'},
    icon: 'cil-car-alt',
    mode: 'production',
    master: 'true',
    class: status === 'ALL' ? 'c-sidebar-nav-link c-active' : 'c-sidebar-nav-link'
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Otevřené',
    to: {pathname: `${transfers}/${transferStatusPrefix}/QUEUED`, breadcrumb: 'Převodky otevřené'},
    icon: 'cil-car-alt',
    mode: 'production',
    master: 'true',
    class: status === 'QUEUED' ? 'c-sidebar-nav-link c-active' : 'c-sidebar-nav-link'
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Na cestě',
    to: {pathname: `${transfers}/${transferStatusPrefix}/FINISHED,OUT`, breadcrumb: 'Převodky na cestě'},
    icon: 'cil-car-alt',
    mode: 'production',
    master: 'true',
    class: status === 'FINISHED' ? 'c-sidebar-nav-link c-active' : 'c-sidebar-nav-link',
    badge: { text: badgeCount, color: 'info'}
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Převedená',
    to: {pathname: `${transfers}/${transferStatusPrefix}/TRANSFERED`, breadcrumb: 'Převodky převedené'},
    icon: 'cil-car-alt',
    mode: 'production',
    master: 'true',
    class: status === 'TRANSFERED' ? 'c-sidebar-nav-link c-active' : 'c-sidebar-nav-link'
  },
  {
    _tag: 'CSidebarNavTitle',
    _children: ['Seznamy'],
    mode: 'production',
    master: 'true'
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Nenalezené zboží',
    to: {pathname: `${transfers}/nenalazene-zbozi`, breadcrumb: 'Nenalezené zboží'},
    icon: 'cil-notes',
    mode: 'production',
    master: 'true',
  },
]}


// STATUS set in onTranferSelected function on TransfersList.js and passed in TheSidebar.js
export const collectionListsNavItems = (transType=null) => {
  return [
  {
    _tag: 'CSidebarNavTitle',
    _children: ['Balíkobot objednávky'],
    mode: 'production',
    master: 'true'
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Přidané',
    to: {pathname: `${collectionLists}/balikobot-orders`, breadcrumb: 'Přidané objednávky'},
    icon: 'cil-folder',
    mode: 'production',
    master: 'true',
    class: !transType ? 'c-sidebar-nav-link c-active' : 'c-sidebar-nav-link'
  },
  {
    _tag: 'CSidebarNavTitle',
    _children: ['Svozové listy'],
    mode: 'production',
    master: 'true'
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Intime Praha',
    to: {pathname: `${collectionLists}/${collectionListTransPrefix}/7`, breadcrumb: 'Intime Praha'},
    icon: 'cil-car-alt',
    mode: 'production',
    master: 'true',
    class: +transType === 7 ? 'c-sidebar-nav-link c-active' : 'c-sidebar-nav-link'
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Intime Brno',
    to: {pathname: `${collectionLists}/${collectionListTransPrefix}/8`, breadcrumb: 'Intime Brno'},
    icon: 'cil-car-alt',
    mode: 'production',
    master: 'true',
    class: +transType === 8 ? 'c-sidebar-nav-link c-active' : 'c-sidebar-nav-link'
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Zásilkovna Praha',
    to: {pathname: `${collectionLists}/${collectionListTransPrefix}/19`, breadcrumb: 'Zásilkovna Praha'},
    icon: 'cil-car-alt',
    mode: 'production',
    master: 'true',
    class: +transType === 19 ? 'c-sidebar-nav-link c-active' : 'c-sidebar-nav-link'
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Zásilkovna Brno',
    to: {pathname: `${collectionLists}/${collectionListTransPrefix}/11`, breadcrumb: 'Zásilkovna Brno'},
    icon: 'cil-car-alt',
    mode: 'production',
    master: 'true',
    class: +transType === 11 ? 'c-sidebar-nav-link c-active' : 'c-sidebar-nav-link'
  },
  {
    _tag: 'CSidebarNavItem',
    name: '123 Slovensko',
    to: {pathname: `${collectionLists}/${collectionListTransPrefix}/17`, breadcrumb: '123 Slovensko'},
    icon: 'cil-car-alt',
    mode: 'production',
    master: 'true',
    class: +transType === 17 ? 'c-sidebar-nav-link c-active' : 'c-sidebar-nav-link'
  },
]}

export const mailchimpNavItems = [
  {
    _tag: 'CSidebarNavTitle',
    _children: ['Mailchimp'],
    mode: 'production',
    master: 'true'
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Beershop',
    to: {pathname: `${mailchimp}/${mailchimpShopPrefix}/beershop`},
    icon: 'cil-settings',
    mode: 'production',
    master: 'true'
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Delikatesy',
    to: {pathname: `${mailchimp}/${mailchimpShopPrefix}/delikatesy`},
    icon: 'cil-settings',
    mode: 'production',
    master: 'true'
  },
]

export const ratingNavItems = [
  {
    _tag: 'CSidebarNavTitle',
    _children: ['Rating'],
    mode: 'production',
    master: 'true'
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Piva s www',
    to: {pathname: `${rating}/beer-list`},
    icon: 'cil-star',
    mode: 'production',
    master: 'true'
  },
]

export const kegsNavItems = [
  {
    _tag: 'CSidebarNavTitle',
    _children: ['Sudy'],
    mode: 'production',
    master: 'true'
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Zakládání produktu',
    to: {pathname: `${kegs}/zakladani-produktu`, breadcrumb: 'Vytváření produktů'},
    icon: 'cil-notes',
    mode: 'production',
    master: 'true'
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Editace produktu',
    to: {pathname: `${kegs}/editace`, breadcrumb: 'Editace produktů'},
    icon: 'cil-notes',
    mode: 'production',
    master: 'true'
  },
  // {
  //   _tag: 'CSidebarNavItem',
  //   name: 'Zobrazování produktů',
  //   to: {pathname: `${kegs}/produkty-povolene`, breadcrumb: 'Zobrazování produktů'},
  //   icon: 'cil-notes',
  //   mode: 'production',
  //   master: 'true'
  // },
  {
    _tag: 'CSidebarNavItem',
    name: 'Pivovary',
    to: {pathname: `${kegs}/vyrobci`, breadcrumb: 'Pivovary'},
    icon: 'cil-notes',
    mode: 'production',
    master: 'true'
  },
  {
    _tag: 'CSidebarNavTitle',
    _children: ['Půjčování'],
    mode: 'production',
    master: 'true'
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Rezervace',
    to: {pathname: `${kegs}/rezervace`, breadcrumb: 'Rezervace'},
    icon: 'cil-notes',
    mode: 'production',
    master: 'true'
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Vytvoření zápůjčky',
    to: {pathname: `${kegs}/vytvoreni-zapujcky`, breadcrumb: 'Vytvoření zápůjčky'},
    icon: 'cil-notes',
    mode: 'production',
    master: 'true'
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Přehled zápůjček',
    to: {pathname: `${kegs}/prehled-zapujcek`, breadcrumb: 'Přehled zápůjček'},
    icon: 'cil-notes',
    mode: 'production',
    master: 'true'
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Ukončení zápůjčky',
    to: {pathname: `${kegs}/ukonceni-zapujcky`, breadcrumb: 'Ukončení zápůjčky'},
    icon: 'cil-notes',
    mode: 'production',
    master: 'true'
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Zákazníci',
    to: {pathname: `${kegs}/zakaznici`, breadcrumb: 'Zákazníci'},
    icon: 'cil-notes',
    mode: 'production',
    master: 'true'
  },
  // {
  //   _tag: 'CSidebarNavItem',
  //   name: 'Vzory smluv',
  //   to: {pathname: `${kegs}/vzory-smluv`, breadcrumb: 'Vzory smluv'},
  //   icon: 'cil-notes',
  //   mode: 'production',
  //   master: 'true'
  // },
  {
    _tag: 'CSidebarNavItem',
    name: 'Zálohy',
    to: {pathname: `${kegs}/zalohy`, breadcrumb: 'Zálohy'},
    icon: 'cil-notes',
    mode: 'production',
    master: 'true'
  },
]

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  // Orders states items defined in AppWideOrdersContextContainer.js
  {
    _tag: 'CSidebarNavTitle',
    _children: ['UI Overview'],
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Dashboard',
    to: '/#',
    icon: 'cil-speedometer',
    badge: {
      color: 'info',
      text: 'NEW',
    }
  },
  {
    _tag: 'CSidebarNavTitle',
    _children: ['Theme']
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Colors',
    to: '/theme/colors',
    icon: 'cil-drop',
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Typography',
    to: '/theme/typography',
    icon: 'cil-pencil',
  },
  {
    _tag: 'CSidebarNavTitle',
    _children: ['Components']
  },
  {
    _tag: 'CSidebarNavDropdown',
    name: 'Base',
    route: '/base',
    icon: 'cil-puzzle',
    _children: [
      {
        _tag: 'CSidebarNavItem',
        name: 'Breadcrumb',
        to: '/base/breadcrumbs',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Cards',
        to: '/base/cards',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Carousel',
        to: '/base/carousels',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Collapse',
        to: '/base/collapses',
      },
      /*
      {
        name: 'Dropdowns',
        to: '/base/dropdowns',
      },*/
      {
        _tag: 'CSidebarNavItem',
        name: 'Jumbotron',
        to: '/base/jumbotrons',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'List group',
        to: '/base/list-groups',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Navs',
        to: '/base/navs',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Navbars',
        to: '/base/navbars',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Pagination',
        to: '/base/paginations',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Popovers',
        to: '/base/popovers',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Progress',
        to: '/base/progress-bar',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Switches',
        to: '/base/switches',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Tabs',
        to: '/base/tabs',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Tooltips',
        to: '/base/tooltips',
      },
    ],
  },
  {
    _tag: 'CSidebarNavDropdown',
    name: 'Buttons',
    route: '/buttons',
    icon: 'cil-cursor',
    _children: [
      {
        _tag: 'CSidebarNavItem',
        name: 'Buttons',
        to: '/buttons/buttons',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Brand buttons',
        to: '/buttons/brand-buttons',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Buttons groups',
        to: '/buttons/button-groups',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Dropdowns',
        to: '/buttons/button-dropdowns',
      }
    ],
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Charts',
    to: '/charts',
    icon: 'cil-chart-pie'
  },
  {
    _tag: 'CSidebarNavDropdown',
    name: 'Editors',
    route: '/editors',
    icon: 'cil-code',
    _children: [
      {
        _tag: 'CSidebarNavItem',
        name: 'Code Editors',
        to: '/editors/code-editors',
        badge: {
          color: 'danger',
          text: 'PRO',
        },
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Rich Text Editor',
        to: '/editors/text-editors',
        badge: {
          color: 'danger',
          text: 'PRO',
        },
      }
    ]
  },
  {
    _tag: 'CSidebarNavDropdown',
    name: 'Forms',
    route: '/forms',
    icon: 'cil-notes',
    _children: [
      {
        _tag: 'CSidebarNavItem',
        name: 'Basic Forms',
        to: '/forms/basic-forms',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Advanced Forms',
        to: '/forms/advanced-forms',
        badge: {
          color: 'danger',
          text: 'PRO'
        }
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Validation',
        to: '/forms/validation-forms',
        badge: {
          color: 'danger',
          text: 'PRO'
        }
      }
    ]
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Google Maps',
    to: '/google-maps',
    icon: 'cil-map',
    badge: {
      color: 'danger',
      text: 'PRO'
    }
  },
  {
    _tag: 'CSidebarNavDropdown',
    name: 'Icons',
    route: '/icons',
    icon: 'cil-star',
    _children: [
      {
        _tag: 'CSidebarNavItem',
        name: 'CoreUI Free',
        to: '/icons/coreui-icons',
        badge: {
          color: 'success',
          text: 'NEW',
        },
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'CoreUI Flags',
        to: '/icons/flags',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'CoreUI Brands',
        to: '/icons/brands',
      },
    ],
  },
  {
    _tag: 'CSidebarNavDropdown',
    name: 'Notifications',
    route: '/notifications',
    icon: 'cil-bell',
    _children: [
      {
        _tag: 'CSidebarNavItem',
        name: 'Alerts',
        to: '/notifications/alerts',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Badges',
        to: '/notifications/badges',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Modal',
        to: '/notifications/modals',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Toaster',
        to: '/notifications/toaster'
      }
    ]
  },
  {
    _tag: 'CSidebarNavDropdown',
    name: 'Plugins',
    route: '/plugins',
    icon: 'cil-input-power',
    _children: [
      {
        _tag: 'CSidebarNavItem',
        name: 'Calendar',
        to: '/plugins/calendar',
        badge: {
          color: 'danger',
          text: 'PRO'
        }
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Draggable',
        to: '/plugins/draggable',
        badge: {
          color: 'danger',
          text: 'PRO'
        }
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Spinners',
        to: '/plugins/spinners',
        badge: {
          color: 'danger',
          text: 'PRO'
        }
      }
    ]
  },
  {
    _tag: 'CSidebarNavDropdown',
    name: 'Tables',
    route: '/tables',
    icon: 'cil-list',
    _children: [
      {
        _tag: 'CSidebarNavItem',
        name: 'Standard Tables',
        to: '/tables/tables',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'DataTables',
        to: '/tables/data-table'
      }
    ]
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Widgets',
    to: '/widgets',
    icon: 'cil-calculator',
    badge: {
      color: 'info',
      text: 'NEW',
    },
  },
  {
    _tag: 'CSidebarNavDivider'
  },
  {
    _tag: 'CSidebarNavTitle',
    _children: ['Extras'],
  },
  {
    _tag: 'CSidebarNavDropdown',
    name: 'Pages',
    route: '/pages',
    icon: 'cil-star',
    _children: [
      {
        _tag: 'CSidebarNavItem',
        name: 'Login',
        to: '/login',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Register',
        to: '/register',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Error 404',
        to: '/404',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Error 500',
        to: '/500',
      },
    ],
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Disabled',
    icon: 'cil-ban',
    badge: {
      color: 'secondary',
      text: 'NEW',
    },
    addLinkClass: 'c-disabled',
    'disabled': true
  },
  {
    _tag: 'CSidebarNavDropdown',
    name: 'Apps',
    route: '/apps',
    icon: 'cil-layers',
    _children: [
      {
        _tag: 'CSidebarNavDropdown',
        name: 'Invoicing',
        route: '/apps/invoicing',
        icon: 'cil-spreadsheet',
        _children: [
          {
            _tag: 'CSidebarNavItem',
            name: 'Invoice',
            to: '/apps/invoicing/invoice',
            badge: {
              color: 'danger',
              text: 'PRO'
            }
          }
        ]
      },
      {
        _tag: 'CSidebarNavDropdown',
        name: 'Email',
        route: '/apps/email',
        icon: 'cil-envelope-open',
        _children: [
          {
            _tag: 'CSidebarNavItem',
            name: 'Inbox',
            to: '/apps/email/inbox',
            badge: {
              color: 'danger',
              text: 'PRO',
            },
          },
          {
            _tag: 'CSidebarNavItem',
            name: 'Message',
            to: '/apps/email/message',
            badge: {
              color: 'danger',
              text: 'PRO',
            },
          },
          {
            _tag: 'CSidebarNavItem',
            name: 'Compose',
            to: '/apps/email/compose',
            badge: {
              color: 'danger',
              text: 'PRO',
            },
          },
        ],
      },
    ]
  },
  {
    _tag: 'CSidebarNavDivider',
    className: 'm-2'
  },
  {
    _tag: 'CSidebarNavTitle',
    _children: ['Labels']
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Label danger',
    to: '',
    icon: {
      name: 'cil-star',
      className: 'text-danger'
    },
    label: true
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Label info',
    to: '',
    icon: {
      name: 'cil-star',
      className: 'text-info'
    },
    label: true
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Label warning',
    to: '',
    icon: {
      name: 'cil-star',
      className: 'text-warning'
    },
    label: true
  },
  {
    _tag: 'CSidebarNavDivider',
    className: 'm-2'
  }
];

