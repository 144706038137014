import React, { useEffect, useState, useRef } from 'react';
import { Route, Switch, BrowserRouter } from 'react-router-dom';
import 'scss/style.scss';
import Keycloak from 'keycloak-js';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import { imgUrl } from 'AppConfig';
import PageSpinner from 'generic/notifications/PageSpinner'

// Facades imports
import { useAuthFacade } from './redux-store/auth/auth.facade';
import { useUserFacade } from './redux-store/user/user.facade';
import { useUserChecksFacade } from './redux-store/user-cheks/user-checks.facade'
// Hooks
import { useMandatoryPrintersCheckOnAppLoad } from 'hooks/useProfileSetup'
import { useIPCheck } from 'hooks/useIPCheck'
import { useLogoutIdleUser } from 'hooks/useLogoutIdleUser'

// Contexts imports
import AppContext from './contexts/context-registrations/AppContext'

// Containers
import TheLayout from './containers/TheLayout'

const App = props => {

  // // Containers
  // const TheLayout = React.lazy(() => import('./containers/TheLayout'));

  // Pages
  const PageIPRejected = React.lazy(() => import('pages/PageIPRejected'));
  const PageRestrictedToAdmin = React.lazy(() => import('pages/PageRestrictedToAdmin'));

  // Facades
  const authFacade = useAuthFacade();
  const userFacade = useUserFacade();
  const userChecksFacade = useUserChecksFacade();

  const [authFunctionsState, setAuthFunctionsState] = useState({
    logout: null,
    updateToken: null
  });

  const allowFetchesOnAuthenticated = useRef(true);

  const {
    authenticated,
    fetchAuthIdm,
    storeAuthObject,
  } = authFacade;

  const { userIPAddress, fetchUserIPCheckData } = userChecksFacade;

  const fetchProfileSetup = userFacade.fetchUserProfileSetup;

  // Hooks
  const { userIpWhitelisted } = useIPCheck();
  const { mandatoryPrintersSelected } = useMandatoryPrintersCheckOnAppLoad();
  // Auto logout after 2hrs of inactivity
  useLogoutIdleUser(120 * 60 * 1000, authFunctionsState?.logout)

  useEffect(() => {
    // Authentication
    const keycloak = Keycloak('/keycloak.json');

    keycloak.init({onLoad: 'login-required'})
    .then(() => storeAuthObject(keycloak));

    /* KEYCLOACK FUNCTIONS logout & updateToken
    *
    * Functions cannot be stored to redux store,
    * they are provided with AppContext
    */
    setAuthFunctionsState({
      logout: keycloak.logout,
      updateToken: keycloak.updateToken
    });

    // After expiration logout, notice also inactivity logout in hooks above
    keycloak.onTokenExpired = () => {
      keycloak.logout()
    };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (allowFetchesOnAuthenticated.current && authenticated) {
      fetchProfileSetup();
      fetchAuthIdm();
      fetchUserIPCheckData();
      // authenticated updated on every idm refetch, therefore, from here, allowing only the first
      allowFetchesOnAuthenticated.current = false;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticated]);

  const loading = (
    <div className="pt-3 text-center">
      <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
  )

  return (
    <BrowserRouter>
      <React.Suspense fallback={loading}>
        { authenticated ?
        <Switch>
          { authFunctionsState.logout && (
            <Route
              exact
              path="/ip-nepovolena"
              name="Nepovolená IP"
              render={props => (
                <PageIPRejected
                  {...props}
                  ipAddress={userIPAddress}
                  logout={authFunctionsState.logout}
                />
                )}
            />,
            <Route
              exact
              path="/nepovoleny-pristup"
              name="Uživatel není admin"
              render={props => (
                <PageRestrictedToAdmin
                  {...props}
                  logout={authFunctionsState.logout}
                />
                )}
            />
          )}
          <Route path="/" name="Home" render={
            props =>
              <I18nextProvider i18n={i18next}>
                <AppContext.Provider
                  value={{
                    authFunctions: authFunctionsState,
                    userIpWhitelisted,
                    userPrintersSelected: mandatoryPrintersSelected,
                    imgUrl
                  }}
                >
                  <TheLayout {...props} />
                </AppContext.Provider>
              </I18nextProvider>
            }
          />
        </Switch>
        : <PageSpinner /> }
      </React.Suspense>
    </BrowserRouter>
  );

}

export default App;
