import { AirshopApi } from 'CraftApi.axios'

// Not used anymore, handled with redux
export const getPdfFromServer = (type, id, userUid, ident) => {
  AirshopApi.get(
    `print/p/document?type=${type}&id=${id}&uid=${userUid}&ident=${ident}`,
    {responseType: 'arraybuffer'}
  ).then(res => {
    const file = new Blob([res.data], {type: 'application/pdf'});
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL, '_blank');
  });
}

// Not used anymore, handled with redux
export const getMassPdfFromServer = (type, ids, userUid, ident) => {
  AirshopApi.get(
    `print/p/massDocument?type=${type}&id=${ids}&uid=${userUid}&ident=${ident}`,
    {responseType: 'arraybuffer'}
  ).then(res => {
    const file = new Blob([res.data], {type: 'application/pdf'});
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL, '_blank');
  });
}

export const openBlobPdf = (pdfData) => {
  let newWindow = window.open('/');
  const file = new Blob([pdfData], {type: 'application/pdf'});
  const fileURL = URL.createObjectURL(file);
  newWindow.location = fileURL;
  // window.open(fileURL, '_blank');
}

