import { useAuthFacade } from 'redux-store/auth/auth.facade'
import { useIdleTimer } from 'react-idle-timer'

export const useLogoutIdleUser = (idleTimeInMiliseconds, logout) => {
  const { idm } = useAuthFacade();

  const onIdle = () => idm && logout && logout()
  
  useIdleTimer({ onIdle, timeout: idleTimeInMiliseconds })
}
